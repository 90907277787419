import React, { useState, useEffect, useContext } from "react";
import {
  ProgramIndexContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/audit/ProgramIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { Field, Form } from "react-final-form";
import Select, { components } from "react-select";
import RichTextEditor from "react-rte";
import { useHistory } from "react-router-dom";
import "../program/Index.css";
import "./CreateProgram.css";
import DateTimePicker from "react-datetime-picker";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    name: "",
    objetives: RichTextEditor.createEmptyValue(),
    scope: RichTextEditor.createEmptyValue(),
    resources: RichTextEditor.createEmptyValue(),
    init_date: "",
    end_date: "",
    risk: RichTextEditor.createEmptyValue(),
    criterial: RichTextEditor.createEmptyValue(),
    company_id: "",
  },

  originalItem: {},
};
// The toolbarConfig object allows you to specify custom buttons, reorder buttons and to add custom css classes.
// Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
// Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    // "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const CreateProgram = ({ state, setState, program_id }) => {
  const [
    { programs },
    { createProgram, updateProgram, setPrograms },
  ] = useContext(ProgramIndexContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  const [{},{alertWarning}] = useContext(AlertContext);
  let [
    { user },
    { refreshCompanies, haveCompanySubscriptionAudit },
  ] = useContext(SecurityContext);
  let history = useHistory();
  useEffect(() => {
    if (program_id && programs.status === STATUS_LOADED) {
      let el = programs.elements.filter((e) => e.id === program_id)[0];
      setStateCreate({
        ...stateCreate,
        item: {
          id: el.id,
          name: el.name,
          objetives: setHtml(el.objetives),
          scope: setHtml(el.scope),
          resources: setHtml(el.resources),
          init_date: new Date(el.init_date),
          end_date: new Date(el.end_date),
          risk: setHtml(el.risk),
          criterial: setHtml(el.criterial),
          company_id: el.company,
        },
        status: STATUS_EDIT,
      });
    }
  }, [program_id, programs]);
  useEffect(() => {
    refreshCompanies();
  }, []);
  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      formObj.init_date=new Date(formObj.init_date);
      formObj.end_date=new Date(formObj.end_date);

      updateProgram(formObj);
      setState({ ...state, toggle_modal: false });
    } else {
      createProgram(formObj);
      setState({ ...state, toggle_modal: false });
    }
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  const setHtml = (value) => {
    value = value == null ? "" : value;
    return RichTextEditor.createValueFromString(value, "html");
  };

  return (
    <Form
      onSubmit={(formObj) => {
        formObj.objetives = formObj.objetives.toString("html");
        formObj.scope = formObj.scope.toString("html");
        formObj.resources = formObj.resources.toString("html");
        formObj.risk = formObj.risk.toString("html");
        formObj.criterial = formObj.criterial.toString("html");
        if (formObj.init_date > formObj.end_date) {
          alertWarning("la fecha fin debe ser mayor al fecha de inicio");
          return false;
        }
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="form-program">
          <div className="form-group txtarea-form-program">
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Nombre:</label>
                  <input
                    {...input}
                    type="text"
                    className="form-control tipo-description"
                    placeholder="Nombre del programa"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="company_id" validate={required}>
              {({ input, meta }) => (
                <>
                  <div className="justify-betwen flex">
                    <label className="tipo-title">Empresa:</label>

                    <div
                      onClick={() => history.push(`/app/companies`)}
                      className="bg-orange white btn-add-company-program"
                    >
                      Añadir empresa
                    </div>
                  </div>

                  <Select
                    {...input}
                    className="tipo-description"
                    placeholder={"Seleccione una opción"}
                   /*  isOptionDisabled={(option) =>
                      !haveCompanySubscriptionAudit(option)
                    } */
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionValue={(option) => option.id}
                    options={user.contact_companies}
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="objetives">
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Objetivos:</label>
                  <RichTextEditor
                    value={stateCreate.item.objetives}
                    toolbarConfig={toolbarConfig}
                    {...input}
                    className=" tipo-description"
                    placeholder="Objetivos"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="scope">
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Alcance:</label>
                  <RichTextEditor
                    value={stateCreate.item.scope}
                    toolbarConfig={toolbarConfig}
                    {...input}
                    className="tipo-description"
                    placeholder="Alcance"
                    rows="3"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="resources">
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Recursos:</label>
                  <RichTextEditor
                    value={stateCreate.item.resources}
                    toolbarConfig={toolbarConfig}
                    {...input}
                    className="tipo-description"
                    placeholder="Recursos"
                    rows="3"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="risk">
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Riesgos:</label>
                  <RichTextEditor
                    value={stateCreate.item.risk}
                    toolbarConfig={toolbarConfig}
                    {...input}
                    className=" tipo-description"
                    placeholder="Riesgos"
                    rows="3"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="criterial">
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Criterios:</label>
                  <RichTextEditor
                    value={stateCreate.item.criterial}
                    toolbarConfig={toolbarConfig}
                    {...input}
                    className=" tipo-description"
                    placeholder="Criterios"
                    rows="3"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-program">
            <Field name="init_date" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Fecha Inicio:</label>
                  <DateTimePicker
                    className="form-control tipo-description"
                    format="y-MM-dd"
                    {...input}
                  />

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
            <Field name="end_date" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Fecha Fin:</label>
                  <DateTimePicker
                    className="form-control tipo-description"
                    format="y-MM-dd"
                    minDate={new Date(values.init_date)}
                    {...input}
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="btn-actions-form">
         
                <input
                  type="submit"
                  value="Guardar"
                  className="btn-orange-default"
                />
            
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateProgram;
