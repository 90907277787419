import React, { useContext, useState, useEffect } from "react";
import "./Notification.css";
import moment from "moment";
import firebase from "../../../firebase";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { formatDate } from "@fullcalendar/core";
const MODULE_AUDIT = "audit";
const MODULE_LIBRARY = "library";
const MODULE_FORUM = "forum";

const Notification = ({ settoggle }) => {
  const [{ user }, { touchedNotification }] = useContext(SecurityContext);
  const [state, setstate] = useState(user);
  useEffect(() => {
    setstate(user);
  }, [user]);
  const touchedNotificationCheck = (notification, index) => {
    let notificationsCopy = [...state.notifications];
    notificationsCopy[index].touched = true;
    setstate({ ...state, notifications: notificationsCopy });
    touchedNotification(notification);
  };
  const createMarkup = (body) => {
    return { __html: body };
  };

  return (
    <div
      onMouseLeave={() => settoggle(false)}
      className="content-general-notification"
    >
      {state.notifications &&
        state.notifications.map((notification, index) => {
          return (
            <div
              onClick={() => {
                touchedNotificationCheck(notification, index);
              }}
              key={notification.id}
              className={`flex cursor-action ${
                !notification.touched ? "bg-white" : "bg-notification-touched"
              }`}
            >
              <div className="content-icon-notification-module flex">
                <div className="icon-notification-user flex margin-auto ">
                  <div
                    className={`icon-module-notification background-simple ${
                      notification.module == MODULE_AUDIT
                        ? "notification-icon-audit"
                        : notification.module == MODULE_LIBRARY
                        ? "notification-icon-library"
                        : notification.module == MODULE_FORUM
                        ? "notification-icon-general"
                        : "notification-icon-general"
                    }`}
                  ></div>
                </div>
              </div>
              <div className="flex flex-1 flex-column">
                <div className="flex tipo-cursiva title-date-notifications">
                  <div className="flex-1 flex">
                    {notification.owner_person_name}
                  </div>

                  <div className="flex flex-end">
                    {notification.created_at &&
                      moment(notification.created_at.toDate()).format(
                        "YYYY-MM-DD hh:mm a"
                      )}
                  </div>
                </div>

                {notification.body && (
                  <div
                    className="tipo-description text-notification"
                    dangerouslySetInnerHTML={createMarkup(notification.body)}
                  />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default Notification;
