import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import Document from "./Document";
import ImageUploader from "react-images-upload";
import moment from "moment";
import DatePicker from "react-datepicker";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import {
  ActionPlanFormContext,
  STATUS_CREATED,
  STATUS_EXECUTED,
  STATUS_INPROGRESS,
  STATUS_PENDING,
  STATUS_REJECTED,
  BUTTON_SI,
  BUTTON_NO,
  BUTTON_POSPONER,
} from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const Effective = () => {
  const [
    { filesAnalyzeEffective, analyze, modalEffective, newDateAnalyze },
    {
      setmodalEffective,
      addNoteAnalyzeEffective,
      deleteEvidenceEffective,
      setfilesAnalyzeEffective,
      setnewDateAnalyze,
    },
  ] = useContext(ActionPlanFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const validateFinisImplement = () => {
    let open = analyze.element.actions.filter((a) => a.is_open === true);
    let openCorrections = analyze.element.corrections.filter(
      (a) => !a.end_date
    );
    if (open.length > 0 || openCorrections.length > 0) {
      if (open.length > 0) {
        alertWarning(`Faltan ${open.length} actividades por cerrar`);
      }
      if (openCorrections.length > 0) {
        alertWarning(
          `Faltan ${openCorrections.length} correcciones por cerrar`
        );
      }

      return false;
    }
    setmodalEffective({
      button: BUTTON_SI,
      open: !modalEffective.open,
    });
  };

  return (
    <>
      {modalEffective.open && (
        <div className="back-document">
          <div className="general-modal-correction">
            <div className="header-modal-correction">
              <div
                onClick={() => {
                  setmodalEffective({
                    ...modalEffective,
                    open: !modalEffective.open,
                  });
                  setfilesAnalyzeEffective([]);
                }}
                className="salir-audit"
              ></div>
              <h3 className="title-form-topic tipo-description">evidencia</h3>
            </div>
            <div className="content-corretion-analyze flex flex-column">
              <Form
                onSubmit={(formObj) => {
                  addNoteAnalyzeEffective(formObj);
                }}
              >
                {({ handleSubmit, hasValidationErrors }) => (
                  <form onSubmit={handleSubmit}>
                    {modalEffective.button === BUTTON_POSPONER && (
                      <>
                        <label className="tipo-title">
                          Fecha de aplazamiento
                        </label>
                        <br />
                        <DatePicker
                          selected={newDateAnalyze}
                          onChange={(date) => setnewDateAnalyze(date)}
                          /* showTimeSelect */
                          className="form-control tipo-description border-bottom-orange-zeus select-min-analyze"
                          /* timeFormat="HH:mm" */
                          locale="es"
                          /* timeIntervals={30} */
                          dateFormat="y-MM-dd"
                        />
                      </>
                    )}

                    <div className="form-group div-form-activity">
                      <Field name="note">
                        {({ input, meta }) => (
                          <>
                            <label className="tipo-title">Nota</label>
                            <textarea
                              {...input}
                              className="form-control tipo-description"
                              placeholder="Nota"
                            />
                          </>
                        )}
                      </Field>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText="Seleccione imagenes"
                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        withIcon={false}
                        onChange={(e) => setfilesAnalyzeEffective(e)}
                        fileSizeError="Excede el peso permitido"
                        fileTypeError="Extensión no permitida"
                        label="Peso máximo 5mb,acepta:jpg,gif,png"
                      />
                      <div className="flex">
                        <div>
                          <input
                            type="submit"
                            className=" btn-orange-default"
                            value="Guardar"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      )}
      <div>
        <p>
          Las acciones implementadas han eliminado las causas de la no
          conformidad?
        </p>
        <div className="flex">
          <div className="flex-1 flex">
            <div
              className="cursor-action bg-orange btn-analyze-effective "
              onClick={() => validateFinisImplement()}
            >
              SI
            </div>
            &nbsp;
            <div
              className="cursor-action bg-black btn-analyze-effective"
              onClick={() =>
                setmodalEffective({
                  button: BUTTON_NO,
                  open: !modalEffective.open,
                })
              }
            >
              NO
            </div>
          </div>
          <div className="flex-1 flex">
            <div
              className="cursor-action bg-orange btn-analyze-effective"
              onClick={() =>
                setmodalEffective({
                  button: BUTTON_POSPONER,
                  open: !modalEffective.open,
                })
              }
            >
              POSPONER
            </div>
          </div>
        </div>
        <br />
        {analyze.element.status !== STATUS_INPROGRESS &&
          analyze.element.status !== STATUS_CREATED && (
            <table className="table">
              <thead>
                <tr>
                  <th className="tipo-titulo">fecha de creacion</th>
                  <th scope="col">responable</th>
                  <th scope="col">nota</th>
                  <th scope="col">evidencias</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {analyze.element.notes_effective.map((note) => {
                  return (
                    <tr key={note.id}>
                      <th scope="row">
                        {moment(note.created_at).format("YYYY-MM-DD")}
                      </th>
                      <td>{note.person.first_name}</td>
                      <td className="tipo-description">{note.note}</td>
                      <td>
                        <div className="flex">
                          {note.documents.map((document) => (
                            <Document key={document.id} document={document} />
                          ))}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <img
                          title="eliminar"
                          className="cursor-action"
                          width="23"
                          onClick={() => deleteEvidenceEffective(note.id)}
                          src={deleteIcon}
                          alt="eliminar"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
      </div>
    </>
  );
};

export default Effective;
