import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import DataTable from "react-data-table-component";
import ReactGantt from "gantt-for-react";
import "moment/locale/es";
import "./Form.css";
import { useHistory } from "react-router-dom";
import {
  ProgramFormContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/audit/ProgramFormContext";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_show from "../../../img_zeus/icons_audit/ICONO-VER.png";
import CreateAudit from "../modals/CreateAudits";
import btn_create from "../../../img_zeus/icons_audit/BOTON_CREAR_AUDITORIA.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;

const initialState = {

  audit_id: null,
  toogle_edit: false,
  toggle: {
    init: false,
    end: false,
    standard: false,
  },
  columns: { status: STATUS_NOT_LOADED, columns: [] },
  item: {},
  gantt: {
    dataFrappe: [],
  },
};

const Form = ({ computedMatch: { params } }) => {
  const [
    { program,modalAauditProgram },
    { setProgramId, deleteAudit, filterInit, filterEnd, filterStandard,setmodalAauditProgram },
  ] = useContext(ProgramFormContext);
  let [{ user }, { haveBillAuditCompany }] = useContext(SecurityContext);
  let [state, setState] = useState(initialState);
  const [gantt, setgantt] = useState(initialState.gantt);
  const [modeGantt, setmodeGantt] = useState("Month");
  const [toogleGantt, settoogleGantt] = useState(false);
  const [columns, setcolumns] = useState(initialState.columns);
  let history = useHistory();
  let { program_id } = params;
  program_id = parseInt(program_id);
  let { company, audits } = program.element;

  useEffect(() => {
  
    setProgramId(program_id);
  }, [program_id]);

  useEffect(() => {
    if (state.audit_id && program.status === STATUS_LOADED) {
      let el = program.element.audits.filter((e) => e.id === state.audit_id)[0];
      setState({
        ...state,
        item: el,
      });
    }
  }, [state.audit_id, program]);
  useEffect(() => {
    if (program.status === STATUS_LOADED) {
      let gantt = [];
      addColumnsTable(program);

      for (let a = 0; a < program.element.audits.length; a++) {
        let audit = program.element.audits[a];
        let start = audit.init_date;
        let end = audit.end_date;
        if (audit.real_init_date != null && audit.real_end_date == null) {
          start = audit.real_init_date;
          if (audit.real_init_date > audit.end_date) {
            let days = moment
              .duration(moment(audit.end_date).diff(moment(audit.init_date)))
              .asDays();
            end = moment(audit.real_init_date)
              .add(days, "days")
              .format("YYYY-MM-DD hh:mm:ss");
          }
        }
        if (audit.real_init_date != null && audit.real_end_date != null) {
          start = audit.real_init_date;
          end = audit.real_end_date;
        }

        gantt.push({
          start: start,
          end: end,
          audit_id: audit.id,
          standard: audit.standard,
          name: audit.title,
          id: `${audit.id}-audit`,
          progress: calculateProgress(audit).percentage,
          participants: audit.participants,
          status_id: audit.status_id,
        });
      }
      setgantt({
        ...gantt,
        dataFrappe: gantt,
      });
    }
  }, [program]);
  const calculateProgress = (audit) => {
    let dataItems = {
      itemsCalificables: 0,
      itemsCalificados: 0,
      percentage: 0,
    };
    for (let e = 0; e < audit.evaluations.length; e++) {
      const evaluation = audit.evaluations[e];
      recursiveItems(dataItems, evaluation.items);
    }
    dataItems.percentage =
      dataItems.itemsCalificados == 0
        ? 0
        : (dataItems.itemsCalificados * 100) / dataItems.itemsCalificables;
    return dataItems;
  };

  const recursiveItems = (dataItems, items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.auditable_item) {
        if (item.auditable_item.required == true) {
          dataItems.itemsCalificables = dataItems.itemsCalificables + 1;

          if (item.results.length > 0) {
            dataItems.itemsCalificados = dataItems.itemsCalificados + 1;
          }
        }
      }
      if (item.items.length > 0) {
        dataItems = recursiveItems(dataItems, item.items);
      }
    }
    return dataItems;
  };
  const addColumnsTable = (program) => {
    let columns = [
      {
        center: true,
        name: "ID",
        width: "60px",
        selector: "id",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              history.push(`/app/audit/${row.id}`);
            }}
          >
            {row.number?row.number:"#"}
          </div>
        ),
        sortable: true,
      },
      {
        name: "NOMBRE",
        grow: 3,
        center: true,
        selector: "title",
        sortable: false,
      },
      {
        name: "NORMA",
        center: true,
        selector: "standard.short_name",
        sortable: true,
      },
      {
        name: "FECHA INICIO",
        center: true,
        selector: "init_date",
        cell: (row) => moment(row.init_date).format("YYYY-MM-DD"),
        sortable: true,
      },
      {
        name: "FECHA FIN",
        selector: "end_date",
        center: true,
        cell: (row) => moment(row.end_date).format("YYYY-MM-DD"),
        sortable: true,
      },
      {
        name: "LIDER",
        center: true,
        selector: "lider_person.first_name",
        sortable: false,
      },

      {
        name: "ESTADO",
        center: true,
        width: "60px",
        cell: (row) => (
          <div
            onClick={() => {
              history.push(`/app/audit/${row.id}`);
            }}
            className=" cursor-action btn-action-program"
          >
            {statusAudit(row.status_id)}
          </div>
        ),
        sortable: false,
      },
      {
        name: "ACCIONES",
        center: false,
        cell: (row) => (
          <>
            <img
              style={{ width: "20px" }}
              className="cursor-action"
              onClick={() => {
                history.push(`/app/audit/${row.id}`);
              }}
              title="Ingresar a la auditoría"
              src={btn_show}
            />
            &nbsp;
            <img
              style={{ width: "20px" }}
              className="cursor-action"
              onClick={() => {
                setState({
                  ...state,
                  audit_id: row.id,
                });
                setmodalAauditProgram(true)
              }}
              title="editar auditoría"
              src={btn_edit}
            />
            &nbsp;
            {(row.status_id === STATUS_CREATED ||
              row.status_id === STATUS_PLANNED) && (
              <img
                style={{ width: "20px" }}
                className="cursor-action"
                onClick={() => {
                  checkDelete(
                    "¿Realmente deseas eliminar a esta auditoría?",
                    `deleteAudit(${row.id})`
                  );
                }}
                title="eliminar auditoría"
                src={btn_delete}
              />
            )}
          </>
        ),

        sortable: false,
      },
    ];

    setcolumns({
      status: STATUS_LOADED,
      columns: columns,
    });
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  const filterInitDate = () => {
    filterInit();
  };

  const filterEndDate = () => {
    filterEnd();
  };

  const filterStandards = () => {
    filterStandard();
  };

  const statusAudit = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return <img src={btn_created} title="Creada" />;
        break;
      case 2:
        return <img src={btn_planned} title="Planeado" />;
        break;
      case 3:
        return <img src={btn_inprogress} title="En progreso" />;
        break;
      case 4:
        return <img src={btn_executed} title="Finalizada" />;
        break;
      case 7:
        return <img src={btn_approved} title="Aprobada" />;
        break;
      default:
        return <img src={btn_created} title="Creada" />;
    }
  };
  const statusAuditGantt = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return `<img src=${btn_created} title="Creada" /> Creada`;
        break;
      case 2:
        return `<img src=${btn_planned} title="Planeado" /> Planeada`;
        break;
      case 3:
        return `<img src=${btn_inprogress} title="En progreso" /> En progreso`;
        break;
      case 4:
        return `<img src=${btn_executed} title="Finalizada" /> Finalizada`;
        break;
      case 7:
        return `<img src=${btn_approved} title="Aprobada" /> Aprobada`;
        break;
      default:
        return `<img src=${btn_created} title="Creada" /> Creada`;
    }
  };

  const getDaysDiff = (init_date, end_date) => {
    let days = Math.round(
      moment.duration(moment(end_date).diff(moment(init_date))).asDays()
    );
    if (days == 1) {
      return days + " día";
    }
    return days + " días";
  };

  const getParticipants = (participants) => {
    let list = "";
    for (let p = 0; p < participants.length; p++) {
      const participant = participants[p];
      list =
        list +
        `<li>${participant.person.first_name} ${participant.person.last_name} (${participant.participant_type.name})</li>`;
    }
    return `<ol>${list}</ol>`;
  };

  const customPopupHtml = (task) => {
    return `
    <div class="details-container-gantt">
        <p class="tipo-title">Auditoría ${task.audit_id} ${
      task.standard.short_name
    } : ${task.name}</p>
        <p class="p-text-gantt tipo-description">Duración: ${getDaysDiff(
          task.start,
          task.end
        )}</p>
        <p class="p-text-gantt tipo-description">Progreso: ${Math.round(
          task.progress
        )}%</p>
        <p class="p-text-gantt tipo-description">Participantes: </p>
       ${getParticipants(task.participants)}
        <p class="p-text-gantt tipo-description">Estado: ${statusAuditGantt(
          task.status_id
        )}</p>
        <a  class="link-gantt" href="/app/audit/${
          task.audit_id
        }">ver auditoría</a>
      
		</div>
    
    `;
  };
  const ondateGanttChange = (task, start, end) => {
    console.log(task);
  };
  const createMarkup = (body) => {
    return { __html: body };
  };
  return (
    <div>
      {modalAauditProgram == true && (
        <div className="back-document">
          <div className="modal-audit">
            <div className="header-modal-audit">
              <div
                className="salir-audit"
                onClick={() => {
                  setState({ ...state, audit_id: null });
                  setmodalAauditProgram(false)
                }}
              ></div>
              {state.audit_id !== null && (
                <h3 className="title-form-topic tipo-description">
                  Editar Auditoría
                </h3>
              )}{" "}
              {state.audit_id === null && (
                <h3 className="title-form-topic tipo-description">
                  Nueva Auditoría
                </h3>
              )}
            </div>
            <div>
              <CreateAudit
                state={state}
                setState={setState}
                audit_id={state.audit_id}
              ></CreateAudit>
            </div>
          </div>
        </div>
      )}
      <nav aria-label="breadcrumb" className="flex-1 cursor-action">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/app/audit/programs">Programas</Link>
          </li>
          <li className="breadcrumb-item ">
            <Link
              className="orange"
              to={`/app/audit/program/${program.element.id}`}
            >
              {program.element.name}
            </Link>
          </li>
        </ol>
      </nav>
      <div className="flex content-title-programs">
        <h2 className="tipo-title title-program">{program.element.name}</h2>
      </div>
      <div className="content-general-info-program flex tipo-description">
        <h3 className="title-company-program tipo-title">
          Empresa {company && company.name}
        </h3>
        <div className="form-group input-program">
          <label className="tipo-title">Objetivo</label>
          <div
            dangerouslySetInnerHTML={createMarkup(program.element.objetives)}
            className="div-info-program"
          ></div>
        </div>
        <div className="form-group input-program">
          <label className="tipo-title">Alcance</label>
          <div
            className="div-info-program"
            dangerouslySetInnerHTML={createMarkup(program.element.scope)}
          ></div>
        </div>
        <div className="form-group input-program">
          <label className="tipo-title">Recursos</label>
          <div
            className="div-info-program"
            dangerouslySetInnerHTML={createMarkup(program.element.resources)}
          ></div>
        </div>
        <div className="form-group input-program">
          <label className="tipo-title">Riesgos</label>
          <div
            className="div-info-program"
            dangerouslySetInnerHTML={createMarkup(program.element.risk)}
          ></div>
        </div>
        <div className="form-group input-program">
          <label className="tipo-title">Criterios</label>
          <div
            className="div-info-program"
            dangerouslySetInnerHTML={createMarkup(program.element.criterial)}
          ></div>
        </div>
        <div className="form-group input-program">
          <label className="tipo-title">Responsable</label>
          <div className="div-info-program">
            {company && company.contact && company.contact.first_name}{" "}
            {company && company.contact && company.contact.last_name}
          </div>
        </div>
        <div className="table-program">
          <div className="flex justify-end">
            <h3 className="tipo-title flex-1">Auditorías de este programa</h3>
          
              <img
                onClick={() => {
                  setmodalAauditProgram(true);
                }}
                className="btn-create-audit-program cursor-action"
                title="Crear Auditoria"
                src={btn_create}
              />
          
          </div>

          <div className="flex container-header-program-filter">
            <div>
              {" "}
              <FontAwesomeIcon
                title={`${
                  toogleGantt
                    ? "Cambiar visualización a tabla"
                    : "Cambiar la visualización a diagrama de Gantt"
                }`}
                onClick={() => settoogleGantt(!toogleGantt)}
                className={`cursor-action ${toogleGantt ? "orange" : ""}`}
                icon={faProjectDiagram}
              />
            </div>

            {toogleGantt && program.status === STATUS_LOADED && (
              <div className="flex filter-gantt-program">
                <div
                  className={`${
                    modeGantt == "Month" ? "mode-active-gantt" : ""
                  }`}
                  onClick={() => setmodeGantt("Month")}
                >
                  mes
                </div>
                <div
                  className={`${
                    modeGantt == "Week" ? "mode-active-gantt" : ""
                  }`}
                  onClick={() => setmodeGantt("Week")}
                >
                  semana
                </div>
                <div
                  className={`${modeGantt == "Day" ? "mode-active-gantt" : ""}`}
                  onClick={() => setmodeGantt("Day")}
                >
                  día
                </div>
              </div>
            )}
          </div>
          {(!toogleGantt && program.status === STATUS_LOADED && columns.status === STATUS_LOADED) && (
            <DataTable
              sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              noDataComponent={"no hay datos"}
              striped={true}
              columns={columns.columns}
              responsive={true}
              className=" tipo-description"
              data={audits}
              pagination={true}
              paginationComponentOptions={{
                rowsPerPageText: "Lineas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Todas",
              }}
            />
          )}

          {/* diagram gantt */}
          {toogleGantt && program.status === STATUS_LOADED && (
            <div>
              {gantt.dataFrappe.length > 0 && (
                <div>
                  <ReactGantt
                    tasks={gantt.dataFrappe}
                    language="es"
                    viewMode={modeGantt}
                    onDrag={() => alert("Dragged")}
                    customPopupHtml={customPopupHtml}
                    onDateChange={ondateGanttChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
