import React, { useContext, useState, useEffect } from "react";
import { AdminIndexContext } from "../../../contexts/security/AdminIndexContext";
import DataTable , { defaultThemes } from "react-data-table-component";

const PackageAssing = () => {
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      }
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        "&:hover":{
          backgroundColor: '#df8c37',
          color:"white"
        }
      },
    },
  };
  const NUMBER_CARACTER = 5;

  const [
    { packageAsing, assingSelect, assignablesFind, textSearchAssing },
    {
      setpackageAsing,
      setassingSelect,
      settextSearchAssing,
      assingSearch,
      setassignablesFind,
      addToCart,
      giftPackage,
    },
  ] = useContext(AdminIndexContext);
  const [columnsTable, setcolumnsTable] = useState([]);
  useEffect(() => {
    if (textSearchAssing.length >= NUMBER_CARACTER) {
      assingSearch(textSearchAssing);
    } else {
      setassignablesFind([]);
    }
  }, [textSearchAssing]);
  useEffect(() => {
    setcolumnsTable([
      {
        name: "EMAIL",
        center: true,
        selector: "email",
        sortable: true,
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setassingSelect(row);
            }}
          >
            {row.email}
          </div>
        ),
      },
      {
        name: "NOMBRE",
        center: true,
        selector: "first_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setassingSelect(row);
            }}
          >
            {row.first_name}
            {row.name}
          </div>
        ),
      },
    ]);
  }, [assingSearch]);

  return (
    <div className="back-document">
      <div className="flex-column modal-content-assing-package">
        <div className="header-modal-assing-package">
          <div
            onClick={() => {
              setpackageAsing(null);
              setassignablesFind([]);
              setassingSelect(null);
              settextSearchAssing("");
            }}
            className="salir-program"
          ></div>
          <div className="flex flex-1 justify-center">
          <h3 className="title-form-topic tipo-description">Asignar paquete</h3>

          </div>
        </div>

        <div className="content-modal-assing-package">
          <div className="form-group margin-control  flex-column flex">
            <input
              type="text"
              className="form-control inp-email-participant"
              onChange={(e) => settextSearchAssing(e.target.value)}
              value={textSearchAssing}
              placeholder={`Correo Electronico ${packageAsing.is_persona?"":"o Nombre de empresa"}`}
            />

            <label className="tipo-cursiva" htmlFor="">
              Búsqueda de {packageAsing.is_persona ? "usuarios" : "empresas"}{" "}
              apartir de {NUMBER_CARACTER} caracteres
            </label>

            <DataTable
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10]}
              noDataComponent={"no hay datos"}
              striped={true}
              columns={columnsTable}
              responsive={true}
              className="table-assing-package tipo-description"
              data={assignablesFind}
              pagination={true}
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Lineas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Todas",
              }}
            />
            {assingSelect && (<div className="tipo-description select-assignable-package">
              <div>  {assingSelect.email}</div>
              <div>  {assingSelect.first_name} {assingSelect.last_name} {assingSelect.name}</div>
              <div>  </div>


            
              </div>)}
            {assingSelect && (
              <div className="flex justify-betwen">
                <div
                  className="btn-orange-default"
                  onClick={() => giftPackage()}
                >
                  Regalar
                </div>
                <div className="btn-orange-default" onClick={() => addToCart()}>
                  Ponerlo en carrito de compras
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default PackageAssing;
