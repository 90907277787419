import React, { useState, useEffect, useContext } from "react";
import "./Documents.css";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  AuditPlanFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanFormContext";
import Swal from "sweetalert2";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import Loading from "../../app/Loading";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const initialState = {
  documentCreate: null,
  documents: [],
  folders: [],
};
const Document = () => {
  const [
    { audit, folder, uploadMasive },
    { addDocumentAudit, deleteDocument,deleteFolder, masiveUploadDocument, setfolder },
  ] = useContext(AuditPlanFormContext);
  const [{ folderAudit }, { setfolderAudit }] = useContext(AlertContext);
  const [{ user }, {}] = useContext(SecurityContext);
  const [adddocument, setadddocument] = useState(initialState.documentCreate);
  const [documents, setdocuments] = useState(initialState.documents);
  const [folders, setFolders] = useState(initialState.folders);
  const [search, setsearch] = useState("");
  const uploadDocument = (e) => {
    setadddocument(e.target.files[0]);
    addDocumentAudit(e.target.files[0]);
  };
  useEffect(() => {
  setfolder({ ...folder, folder_id: folderAudit });
  }, []);
  useEffect(() => {
    if (folder.folder_id != null && folder.status == STATUS_LOADED) {
      setdocuments(folder.elements.documents);
      setFolders(folder.elements.folders);
    }
  }, [folder]);
  useEffect(() => {
    if (audit.status == STATUS_LOADED && folder.folder_id == null) {
      setdocuments(audit.originalItem.documents);
      setFolders(audit.originalItem.folders);
    }
  }, [audit, folder]);
  const searchDocument = (text) => {
    setsearch(text);
    if (text != "") {
      let newDocuments = audit.originalItem.documents.filter(function (el) {
        return el.title.toLowerCase().indexOf(text.toLowerCase()) > -1;
      });
      setdocuments(newDocuments);
    } else {
      setdocuments(audit.originalItem.documents);
    }
  };

  const checkDeleteDocument = (document_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este documento?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteDocument(document_id);
      } else {
      }
    });
  };
  const checkDeleteFolder=(folder_id)=>{
    Swal.fire({
      title: "¿Realmente deseas eliminar esta carpeta?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteFolder(folder_id);
      } else {
      }
    });
  }
  let shortText = (text, range) => {
    if (text && text.length > range) {
      return text.substr(0, range) + "...";
    }
    return text;
  };
  const changeFolder = (folder_id) => {
    if(folder_id!=folder.folder_id){
      setfolder({
        ...folder,
        status:STATUS_NOT_LOADED,
        folder_id: folder_id,
      });
      setfolderAudit(folder_id);
    }
    
  };

  const validateFilesFolders = (files) => {
    masiveUploadDocument(files);
  };
  return (
    <div>
      <div className="flex ">
        <div className="flex-1">
          <input
            onChange={uploadDocument}
            type="file"
            name="file"
            id="file"
            className="inputfile-final-report"
          />
          <label
            title="Adjuntar documentos, PDF e Imágenes, Max 80 Mb"
            className="background-simple label-upload-image-audit"
            htmlFor="file"
          ></label>
        </div>
        <nav
          aria-label="breadcrumb"
          className="container-bread-document-audit cursor-action"
        >
          <ol className="breadcrumb">
            <li onClick={() => changeFolder(null)} className="breadcrumb-item">
             // Principal
            </li>

            {folder.status == STATUS_LOADED && folder.folder_id && (
              <>
                {folder.recursive &&
                  folder.recursive.map((fol, index) => {
                    return (
                      <li
                        onClick={() => changeFolder(fol.id)}
                        key={index}
                        className="breadcrumb-item flex"
                        aria-current="page"
                      >
                        <div
                          className={`${
                            folder.recursive.length == index + 1 ? "orange" : ""
                          } tipo-description`}
                        >
                          {fol.name}
                        </div>
                      </li>
                    );
                  })}
              </>
            )}
          </ol>
        </nav>
        <div className="flex-1 flex justify-end">
          <input
            type="text"
            onChange={(e) => searchDocument(e.target.value)}
            className="tipo-description inp-search-document-audit"
            placeholder="Buscar"
            value={search}
          />
        </div>
        {}
      </div>

      <div className="flex">
        <div className="flex-1">
          {folders.map((fol) => {
            return (
              <div
                key={fol.id}
              
                className="container-folder-audit"
              >
                <div   onClick={() => changeFolder(fol.id)} className="background-simple cursor-action folder-audit"></div>
                <p title={fol.name} className="tipo-cursiva">{shortText(fol.name,20)}</p>
                <div  onClick={() => checkDeleteFolder(fol.id)} className="icon-btn-delete-document-audit background-simple cursor-action"></div>
              </div>
            );
          })}
          <br />
          {documents.map((doc) => {
            return (
              <div
                key={doc.id}
                className="flex-column container-document-general-audit"
              >
                <Link
                title={doc.title}
                  to={`${`/app/library/document/${doc.id}`}`}
                  className="conatiner-document-audit-tarjet"
                >
                  <div
                    title="Ver Documento"
                    className={`background-simple btn-document-general-audit-icon ${
                      doc.type == "pdf"
                        ? "ext-docs-2"
                        : "ext-img-2"
                    }`}
                  ></div>
                  {shortText(doc.title, 15)}
                  <br />
                  <span className="tipo-cursiva date-document-audit">
                    {doc.created_at}
                  </span>
                </Link>
                {audit.status == STATUS_LOADED && (
                  <>
                    {user.id == audit.originalItem.company.contact.id && (
                      <div className="container-btn-delete-document-audit">
                        <div
                          onClick={() => checkDeleteDocument(doc.id)}
                          className="background-simple cursor-action icon-btn-delete-document-audit"
                        ></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
        {uploadMasive == STATUS_LOADING && (
          <div className="flex container-loading-masive">
            <div className="background-simple loading-masive-folder"></div>
          </div>
        )}
        {uploadMasive == STATUS_LOADED && (
          <>

          <Dropzone
        
            onDrop={(acceptedFiles) => validateFilesFolders(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="section-drag-folder">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="margin-auto tipo-cursiva">
                    Suelte aqui documentos o carpetas
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
          </>
        )}
      </div>
    </div>
  );
};

export default Document;
