// @flow

import React, { Component, useEffect } from "react";
import "../login/Login.css";
import icon from "../../../img_zeus/header/ICONO-ZEUS.png";
import logo from "../../../img_zeus/login/LOGO-CRHONOS-REGISTRO.png";
import imgb from "../../../login.png";
import Header from "../../app/layout/Header";

import mintic from "../../../img_zeus/partners/mintic.png";
import destapa from "../../../img_zeus/partners/destapa.jpg";
import manizales from "../../../img_zeus/partners/manizales.png";
import fonade from "../../../img_zeus/partners/fonade.png";
import emprender from "../../../img_zeus/partners/fondo.png";
import sena from "../../../img_zeus/partners/sena.png";
import gobierno from "../../../img_zeus/partners/gobierno.png";
import apps from "../../../img_zeus/partners/apps.png";
import territorio from "../../../img_zeus/partners/territorio.png";
import tecnoparque from "../../../img_zeus/partners/tecnoparque.png";

import { SecurityContext } from "../../../contexts/security/SecurityContext";

import { Link } from "react-router-dom";
const Login = ({ match }) => {
  let { params, url, path } = match;
  let { token } = params;
  let [securityState, securityActions] = React.useContext(SecurityContext);
  let [state, setState] = React.useState({ email: "", password: "" });

  useEffect(() => {
    if (token) {
      securityActions.validateAccount(token);
    }
  }, [token]);
  let setField = (field) => (e) => {
    setState({ ...state, [field]: e.target.value });
  };

  const userLogin = (e) => {
    e.preventDefault();
    securityActions.login(state.email, state.password);
  };

  return (
    <div className="background-login">
      <div className="w-100" className="flex header-login">
        <div className="flex">
          <div className="flex margin-auto">
            <img src={icon} className="img-header-icon-login" />
            <h2 className="tipo-title title-header">Plataforma Zeus</h2>
          </div>
        </div>
      </div>
      <div className="content-general-login">
        <img className="logo-crhonos-login" src={logo} />
        <form
          className="form-auth form-login form-login-mobile"
          onSubmit={userLogin}
        >
          <div className="conten-form-login">
            <h1 className="h1-login tipo-title">INICIAR SESIÓN</h1>
            <em>{securityState.formErrors.general}</em>
           
              <input
                onChange={setField("email")}
                value={state.email}
                type="email"
                className="inpt-login tipo-description flex-1"
              />
         
          
              <input
                onChange={setField("password")}
                value={state.password}
                type="password"
                className="inpt-login tipo-description flex-1"
              />
            
           
      
            <p className="p-recover-password txt-center tipo-description">
              <Link to={`/mailResetPassword`}>¿Olvidaste la contraseña?</Link>
            </p>
            <div className="btn-group-login">
              <Link to="/register">
                <input
                  className="btn-login btn-register cursor-action tipo-boton"
                  value="REGISTRO"
                  type="button"
                ></input>
              </Link>
              <input
                className="btn-login btn-up cursor-action tipo-boton"
                value="ENTRAR"
                type="submit"
              ></input>
            </div>
          </div>
        </form>
        <div
          id="carouselPartners"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active container-images-slide-login">
              <img title="DESTAPA" src={destapa} alt="destapa" />
              <img title="MANIZALES MAS" src={manizales} alt="manizales" />
              <img title="FONADE" src={fonade} alt="fonade" />
              <img title="FONDO EMPRENDER" src={emprender} alt="emprender" />
              <img title="SENA" src={sena} alt="sena" />
              <img title="GOBIERNO" src={gobierno} alt="gobierno" />
              <img title="APPS" src={apps} alt="apps" />
              <img title="TERRITORIO" src={territorio} alt="territorio" />
              <img title="MINTIC" src={mintic} alt="mintic" />
              <img title="TECNOPARQUE" src={tecnoparque} alt="tecnoparque" />
            </div>
          </div>
        </div>
        <div
          id="carouselPartnersMobile"
          className="carousel slide hidden"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active container-images-slide-login ">
              <img title="DESTAPA" src={destapa} alt="destapa" />
              <img title="MANIZALES MAS" src={manizales} alt="manizales" />
              <img title="FONADE" src={fonade} alt="fonade" />
            </div>
            <div className="carousel-item container-images-slide-login ">
              <img title="FONDO EMPRENDER" src={emprender} alt="emprender" />
              <img title="SENA" src={sena} alt="sena" />
              <img title="GOBIERNO" src={gobierno} alt="gobierno" />
            </div>
            <div className="carousel-item container-images-slide-login ">
              <img title="APPS" src={apps} alt="apps" />
              <img title="TERRITORIO" src={territorio} alt="territorio" />
            </div>
            <div className="carousel-item container-images-slide-login ">
              <img title="TECNOPARQUE" src={tecnoparque} alt="tecnoparque" />

              <img title="MINTIC" src={mintic} alt="mintic" />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselPartnersMobile"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon orange"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselPartnersMobile"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
