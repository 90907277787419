import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import "moment/locale/es";
import "../audit/index.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_show from "../../../img_zeus/icons_audit/ICONO-VER.png";
import btn_create from "../../../img_zeus/icons_audit/BOTON_CREAR_AUDITORIA.png";
import btn_desplegar from "../../../img_zeus/icons_audit/BOTON-DESPLEGAR-AUDITORIA.png";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_ejecutar from "../../../img_zeus/icons_audit/BOTON-EJECUTAR.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import { useHistory } from "react-router-dom";
import CreateAudit from "../modals/CreateAuditAudit";
import Loading from "./../../app/Loading";
import {
  AuditPlanIndexContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanIndexContext";
import { STATUS_NOT_LOADED } from "../../../contexts/audit/ProgramFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCaretDown,
  faUser,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;

const initialState = {
  columns: { status: STATUS_NOT_LOADED, columns: [] },
  toggle: {
    init: false,
    end: false,
    standard: false,
    company: false,
    init2: false,
    end2: false,
    standard2: false,
    company2: false,
  },
  audit_id: null,
  searchAudit: "",
  mobile: {
    showAuditOwn: "owned",
    searchAuditMobile: "",
    orginalAudits: [],
    audits: [],
  },
};

const Index = ({ computedMatch: { path } }) => {
  const [
    { audit, suscriptionsAudit, modalAuditAudit },
    {
      filterInit,
      filterEnd,
      filterStandard,
      deleteAudit,
      filterCompany,
      setmodalAuditAudit,
    },
  ] = useContext(AuditPlanIndexContext);
  let [{ user }, { haveBillAuditCompany, haveBillAuditPerson, viewGeneral }] =
    useContext(SecurityContext);
  const [columns, setcolumns] = useState(initialState.columns);
  const [columnsOwns, setcolumnsOwns] = useState(initialState.columns);

  let [state, setState] = useState(initialState);
  let history = useHistory();
  useEffect(() => {
    viewGeneral("audit");
  }, []);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  const filterInitDate = () => {
    filterInit();
  };

  const filterEndDate = () => {
    filterEnd();
  };

  const filterCompanies = () => {
    filterCompany();
  };

  const executedAudit = (audit) => {
    setState({ ...state, audit_id: audit });
  };

  const statusAudit = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return <img src={btn_created} title="Creada" />;
        break;
      case 2:
        return <img src={btn_planned} title="Planeado" />;
        break;
      case 3:
        return <img src={btn_inprogress} title="En progreso" />;
        break;
      case 4:
        return <img src={btn_executed} title="Finalizada" />;
        break;
      case 6:
        return <img src={btn_created} title="Pendiente" />;
        break;
      case 7:
        return <img src={btn_approved} title="Aprobada" />;
        break;
      default:
        return <img src={btn_created} title="Creada" />;
    }
  };
  const imParticipant = (audit) => {
    let isParticipant = false;

    for (let p = 0; p < audit.participants.length; p++) {
      const participant = audit.participants[p];
      if (
        participant.person_id != audit.lider_person_id &&
        participant.person_id == user.id
      ) {
        isParticipant = true;
      }
    }

    if (audit.company) {
      if (audit.company.contact) {
        if (audit.company.contact.id == user.id) {
          isParticipant = true;
        }
      }
    }
    return isParticipant;
  };
  const validateSuscriptionPersonalAudit = () => {
    if (suscriptionsAudit.status === STATUS_LOADED) {
      let susbscriptions = suscriptionsAudit.elements.filter(
        (a) =>
          a.is_personal &&
          moment(a.end_date) > moment() &&
          a.consumers.length < a.quantity
      );
      if (susbscriptions.length > 0) {
        return true;
      }
    }
  };
  //////////////////////////////* FUNCTIONS MOBILE *//////////////////////
  const mobileSetOwn = (own) => {
    setState({ ...state, mobile: { ...state.mobile, showAuditOwn: own } });
  };
  useEffect(() => {
    if (audit.status == STATUS_LOADED) {
      setState({
        ...state,
        mobile: {
          ...state.mobile,
          orginalAudits: audit.elements,
          audits: audit.elements,
        },
      });

      let columnsCommon = [
        {
          center: true,
          name: "ID",
          maxWidth: "60px",
          selector: "number",
          cell: (row) => (
            <div
              className="cursor-action link-audit-index"
              onClick={() => {
                history.push(`/app/audit/${row.id}`);
              }}
            >
              {row.number ? row.number : "#"}
            </div>
          ),
          sortable: true,
        },
        {
          name: "EMPRESA",
          center: true,
          maxWidth: "150px",
          selector: "company.name",
          grow: 3,
          cell: (row) => row.company && row.company.name,
          sortable: true,
        },
        {
          name: "NOMBRE",
          grow: 3,
          maxWidth: "400px",
          center: true,
          selector: "title",
          cell: (row) => (
            <div
              title={row.program ? "Empresarial" : "Personal"}
              className="flex w-100 "
            >
              <div className="flex">
                <div className="flex margin-auto flex-0">
                  <FontAwesomeIcon
                    className="icon-personal-company-cart "
                    icon={!row.program ? faUser : faBuilding}
                  />
                  &nbsp;
                </div>

                <div className="flex-1 flex margin-auto">
                  <span>{row.title}</span>
                </div>
              </div>
            </div>
          ),
          sortable: true,
        },
        {
          name: "NORMA",
          center: true,
          selector: "standard.short_name",
          sortable: true,
        },
        {
          name: "FECHA INICIO",
          center: true,
          selector: "init_date",
          cell: (row) => moment(row.init_date).format("YYYY-MM-DD"),
          sortable: true,
        },
        {
          name: "FECHA FIN",
          selector: "end_date",
          center: true,
          cell: (row) => moment(row.end_date).format("YYYY-MM-DD"),
          sortable: true,
        },

        {
          name: "ESTADO",
          center: true,
          width: "60px",
          cell: (row) => (
            <div
              onClick={() => {
                history.push(`/app/audit/${row.id}`);
              }}
              className=" cursor-action btn-action-program"
            >
              {statusAudit(row.status_id)}
            </div>
          ),
          sortable: false,
        },
      ];

      setcolumns({
        status: STATUS_LOADED,
        columns: [
          ...columnsCommon,
          {
            maxWidth: "80px",
            name: "ACCIONES",
            center: true,
            cell: (row) => (
              <img
                style={{ width: "20px" }}
                className="cursor-action"
                onClick={() => {
                  history.push(`/app/audit/${row.id}`);
                }}
                title="Ingresar a la auditoría"
                src={btn_show}
              />
            ),

            sortable: false,
          },
        ],
      });
      setcolumnsOwns({
        status: STATUS_LOADED,
        columns: [
          ...columnsCommon,
          {
            maxWidth: "80px",
            name: "ACCIONES",
            center: false,
            cell: (row) => (
              <>
                <img
                  style={{ width: "20px" }}
                  className="cursor-action"
                  onClick={() => {
                    history.push(`/app/audit/${row.id}`);
                  }}
                  title="Ingresar a la auditoría"
                  src={btn_show}
                />
                &nbsp;
                <img
                  style={{ width: "20px" }}
                  className="cursor-action"
                  onClick={() => {
                    setState({
                      ...state,
                      audit_id: row.id,
                    });
                    setmodalAuditAudit(true);
                  }}
                  title="editar auditoría"
                  src={btn_edit}
                />
                &nbsp;
                {(row.status_id === STATUS_CREATED ||
                  row.status_id === STATUS_PLANNED) && (
                  <img
                    style={{ width: "20px" }}
                    className="cursor-action"
                    onClick={() => {
                      checkDelete(
                        "¿Realmente deseas eliminar a esta auditoría?",
                        `deleteAudit(${row.id})`
                      );
                    }}
                    title="eliminar auditoría"
                    src={btn_delete}
                  />
                )}
              </>
            ),

            sortable: false,
          },
        ],
      });
    }
  }, [audit]);
  const setSearchAuditMobile = () => (e) => {
    let body = e.target.value;
    let newAudits = [...state.mobile.orginalAudits];
    if (body != "") {
      newAudits = newAudits.filter(function (el) {
        return el.title.toLowerCase().indexOf(body.toLowerCase()) > -1;
      });
    }
    setState({
      ...state,
      mobile: { ...state.mobile, searchAuditMobile: body, audits: newAudits },
    });
  };
  //////////////////////////////*END  FUNCTIONS MOBILE *//////////////////////

  return (
    <>
      {audit.status != STATUS_LOADED && <Loading />}
      <div
        className={`${
          audit.status != STATUS_LOADED ? "hidden" : ""
        } container-index-audits`}
      >
        {modalAuditAudit === true && (
          <div className="back-document">
            <div className="modal-audit">
              <div className="header-modal-audit">
                <div
                  className="salir-audit"
                  onClick={() => {
                    setState({ ...state, audit_id: null });
                    setmodalAuditAudit(false);
                  }}
                ></div>
                <h3 className="title-form-topic tipo-description">
                  {state.audit_id !== null
                    ? "Editar Auditoría"
                    : "Nueva Auditoría"}
                </h3>
              </div>
              <div>
                <CreateAudit
                  state={state}
                  setState={setState}
                  audit_id={state.audit_id}
                ></CreateAudit>
              </div>
            </div>
          </div>
        )}
        <div className="flex content-title-programs">
          <div className="tipo-title title-audits">Mis Auditorías</div>
        </div>
        <div className="table-audits">
          <div className="flex justify-betwen">
            <h3>Auditorías asignadas por empresas según programa</h3>

            <Link
              className="bg-orange white btn-audit-program-index"
              title="Crear una auditoría perteneciente a un
 programa de auditorías previamente existente de una empresa"
              to={`../../app/audit/programs`}
            >
              Crear auditoría de Programa
            </Link>
          </div>
          {columns.status === STATUS_LOADED && (
            <DataTable
              sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              noDataComponent={"no hay datos"}
              striped={true}
              columns={columns.columns}
              responsive={true}
              className=" tipo-description"
              data={audit.elements.filter(
                (aud) =>
                  aud.lider_person_id === user.id &&
                  aud.responsable_person_id !== user.id
              )}
              pagination={true}
              paginationComponentOptions={{
                rowsPerPageText: "Lineas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Todas",
              }}
            />
          )}
        </div>
        <div className="table-audits">
          <div className="flex justify-betwen">
            <div className="flex">
              {" "}
              <h3>Auditorías propias</h3> &nbsp;
              <div style={{ paddingTop: "4px" }}>
                <span className="tipo-description">
                  (no obedecen a un programa)
                </span>
              </div>
            </div>

            <img
              className="cursor-action btn-create-audit-index"
              onClick={() => {
                setmodalAuditAudit(true);
              }}
              src={btn_create}
              title="crea una auditoría sin pertenecer a programa de auditorías"
            />
          </div>

          {columnsOwns.status === STATUS_LOADED && (
            <DataTable
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              noDataComponent={"no hay datos"}
              striped={true}
              columns={columnsOwns.columns}
              responsive={true}
              className=" tipo-description"
              data={audit.elements.filter(
                (aud) => aud.responsable_person_id === user.id
              )}
              pagination={true}
              paginationComponentOptions={{
                rowsPerPageText: "Lineas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Todas",
              }}
            />
          )}
        </div>
        <div className="table-audits">
          <div className="flex">
            <h3>Auditorías en las que participo</h3>
            &nbsp;
            <div style={{ paddingTop: "3px" }} className="tipo-description">
              <span> (Auditor experto, apoyo y observador)</span>
            </div>
          </div>

          {columns.status === STATUS_LOADED && (
            <DataTable
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              noDataComponent={"no hay datos"}
              striped={true}
              columns={columns.columns}
              responsive={true}
              className=" tipo-description"
              data={audit.elements.filter(
                (aud) =>
                  imParticipant(aud) &&
                  aud.responsable_person_id !== user.id &&
                  aud.lider_person_id !== user.id
              )}
              pagination={true}
              paginationComponentOptions={{
                rowsPerPageText: "Lineas:",
                rangeSeparatorText: "de",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "Todas",
              }}
            />
          )}
        </div>
      </div>
      {/* //////////////////////////////////// DEVICE MOBILE ////////////////////////////////////// */}

      <div className="hidden container-index-audits-mobile">
        <div className="flex bg-white container-headers-audit-mobile">
          <div
            onClick={() => mobileSetOwn("owned")}
            className="flex-1 txt-center"
          >
            <p
              className={`${
                state.mobile.showAuditOwn == "owned"
                  ? "p-header-mobile-audits-active"
                  : ""
              }`}
            >
              Propias
            </p>
          </div>
          <div
            onClick={() => mobileSetOwn("assigned")}
            className="flex-1 txt-center"
          >
            <p
              className={`${
                state.mobile.showAuditOwn == "assigned"
                  ? "p-header-mobile-audits-active"
                  : ""
              }`}
            >
              Asignadas
            </p>
          </div>
          <div
            onClick={() => mobileSetOwn("participant")}
            className="flex-1 txt-center"
          >
            <p
              className={`${
                state.mobile.showAuditOwn == "participant"
                  ? "p-header-mobile-audits-active"
                  : ""
              }`}
            >
              Soy Participante
            </p>
          </div>
        </div>
        <div className="container-audits-mobile">
          {state.mobile.audits.length == 0 && (
            <p className="tipo-description txt-center">
              No se encontraron auditorías
            </p>
          )}

          {state.mobile.audits.map((aud, index) => (
            <div key={index}>
              {state.mobile.showAuditOwn == "owned" &&
                aud.program_id == null &&
                aud.responsable_person_id == user.id && (
                  <div
                    onClick={() => {
                      history.push(`/app/audit/${aud.id}`);
                    }}
                    className="tipo-description flex"
                    key={aud.id}
                  >
                    <p className="flex-1 container-title-number-audit-mobile">
                      <strong>ID {aud.number} </strong> {aud.title}
                    </p>
                    <span className="icon-status-audit-mobile">
                      {statusAudit(aud.status_id)}
                    </span>
                  </div>
                )}
            </div>
          ))}
          {state.mobile.audits.map((aud, index) => (
            <div key={index}>
              {state.mobile.showAuditOwn == "assigned" &&
                aud.program_id != null &&
                aud.lider_person_id == user.id && (
                  <div
                    onClick={() => {
                      history.push(`/app/audit/${aud.id}`);
                    }}
                    className="tipo-description flex"
                    key={aud.id}
                  >
                    <p className="flex-1 container-title-number-audit-mobile">
                      {aud.number} {aud.title}
                    </p>
                    <span className="icon-status-audit-mobile">
                      {statusAudit(aud.status_id)}
                    </span>
                  </div>
                )}
            </div>
          ))}
          {state.mobile.audits.map((aud, index) => (
            <div key={index}>
              {state.mobile.showAuditOwn == "participant" &&
                imParticipant(aud) && (
                  <div
                    onClick={() => {
                      history.push(`/app/audit/${aud.id}`);
                    }}
                    className="tipo-description flex"
                    key={aud.id}
                  >
                    <p className="flex-1 container-title-number-audit-mobile">
                      {aud.number} {aud.title}
                    </p>
                    <span className="icon-status-audit-mobile">
                      {statusAudit(aud.status_id)}
                    </span>
                  </div>
                )}
            </div>
          ))}
        </div>
        <div className="flex container-search-audit-mobile">
          <input
            placeholder="Escribe aquí"
            onChange={setSearchAuditMobile()}
            value={state.mobile.searchAuditMobile}
            className="flex-1 tipo-cursiva"
            type="text"
          />
          <div className="btn-search-report background-simple"></div>
        </div>
      </div>
    </>
  );
};

export default Index;
