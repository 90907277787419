import React from "react";
const CreateProgram = () => {
  return (
    <div className="flex flex-column">
   

      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Responsable del S.G.</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            En programas de auditorias, “Crear Programa”
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Ingrese Nombre al programa de auditoria y la información general.
            Asigne el programa a la empresa
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Adicione auditorías al programa de auditorias
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Visualice las auditorias programadas en: opción tabla o diagrama de
            Gantt
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            En vista de tabla: Ingrese a auditoría, utilizando los iconos de
            acciones o estado
          </div>
        </div>
        <div className="margin-help-bottom border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            En vista diagrama de Gantt: Ingrese a auditoría, con clic sobre la
            barra y utilice la opción “ver auditoria”
          </div>
        </div>
     
      </div>
    </div>
  );
};

export default CreateProgram;
