import React from "react";
import "./GenerateDoc.css";
const GenerateDoc = () => {
  return (
    <div className="general-container-loading-docs">
      <div class="preview_animation-pdf flex-column flex">
        <div class="loadPDF">
          <p>
            <u></u>
            <u></u>
            <u></u>
            <u></u>
          </p>
          <p>
            <u></u>
            <u></u>
            <u></u>
            <u></u>
          </p>
        </div>
        <span className="text-animation-generate-doc tipo-cursiva white">Generando documento...</span>
      </div>
    </div>
  );
};

export default GenerateDoc;
