import React, { useEffect, useContext,useState } from "react";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import icon from "../../../img_zeus/header/ICONO-ZEUS.png";
import logo from "../../../img_zeus/login/LOGO-CRHONOS-REGISTRO.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const Invited = ({ match }) => {
  let { params, url, path } = match;
  let { token } = params;
  const [securityState , securityActions] = useContext(SecurityContext);
  const [inputPassword, setinputPassword] = useState(true);
  const [inputRepeatPassword, setinputRepeatPassword] = useState(true);
  useEffect(() => {
    if (token) {
      securityActions.validateAccountInvited(token);
    }
  }, [token]);
  const userInvitedActived = (e) => {
    e.preventDefault();
    securityActions.activedInvited();
  };
  let setField = (field) => (e) => {
    securityActions.setState({
      ...securityState,
      userIvited: {
        ...securityState.userIvited,
        [field]: e.target.value
      },
    });
  };
  return (
    <div className="background-register">
      <div className="w-100" className="flex header-login">
        <div className="flex">
          <div className="flex margin-auto">
            <img src={icon} className="img-header-icon-login" />
            <h2 className="tipo-title title-header">Plataforma Zeus</h2>
          </div>
        </div>
      </div>
      <div className="content-general-register">
        <img className="logo-crhonos-register" src={logo} />
        <form onSubmit={userInvitedActived} className="form-auth form-register">
          <div className="conten-form-login">
            <h2 className="h1-register tipo-title">
              BIENVENIDO A LA PLATAFORMA
              <br/>
              ACTIVA TU CUENTA
            </h2>
            
            <input
              className="inpt-login  tipo-description"
              onChange={setField("first_name")}
              value={securityState.userIvited.first_name}
              type="text"
              name="first_name"
              required
              placeholder="nombre"
            ></input>
            <input
              className="inpt-login  tipo-description"
              onChange={setField("last_name")}
              value={securityState.userIvited.last_name}
              type="text"
              required
              name="last_name"
              placeholder="apellido"
            ></input>
          
            <div className="inpt-login flex bg-white">
              <input
                className="input-password-register  tipo-description flex-1"
                onChange={setField("password")}
                value={securityState.userIvited.password}
                type={inputPassword ? "password" : "text"}
                required
                placeholder="CREA UNA CONTRASEÑA"
                minLength="7"
                autoComplete="off"
              ></input>
              <FontAwesomeIcon
                onClick={() => setinputPassword(!inputPassword)}
                className="cursor-action eye-register margin-auto"
                icon={faEye}
              />
            </div>
            <div className="inpt-login flex bg-white">
              <input
                className=" input-password-register tipo-description flex-1"
                onChange={setField("password_confirmation")}
                value={securityState.userIvited.password_confirmation}
                type={inputRepeatPassword ? "password" : "text"}
                required
                placeholder="CONFIRMA TU CONTRASEÑA"
                minLength="7"
                autoComplete="off"
              ></input>
              <FontAwesomeIcon
                onClick={() => setinputRepeatPassword(!inputRepeatPassword)}
                className="cursor-action eye-register margin-auto"
                icon={faEye}
              />
            </div>

            <div className=" margin-auto">
           <input
                className="btn-login btn-top-register btn-up cursor-action tipo-boton"
                value="ACTIVAR"
                type="submit"
              ></input>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Invited;
