import React, { useState } from "react";
import "./CreateProgram.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import pdfExec from "../../../img_zeus/help/exec.pdf";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CreateProgram = () => {
  let history = useHistory();
  const [state, setstate] = useState({
    numPages: null,
    pageNumber: 1,
    scale: 1.5,
  });
  let onDocumentLoadSuccess = ({ numPages }) => {
    setstate({ ...state, numPages: numPages });
  };
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div onClick={() => history.push(`/app/help`)} className="icon-help-audit background-simple"></div>
        </div>
        <div className="flex-1 title-help-audit">Ejecución de auditoría</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Requisitos previos:</p>
            <p className="text-long-required-help tipo-description">
              {" "}
              Tener empresa creada.La empresa debe tener suscripción para gestionar
              auditorías
            </p>
          </div>
        </div>
      </div>
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Responsable del S.G.</p>
      </div>
      <div className="flex-column">
        <Document
          noData="No se ha encontrado un pdf"
          /*  onContextMenu={() => {
                   return false;
                 }} */

          // style="pointer-events: none;"
          className="pdf-document"
          file={pdfExec}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            noData="No se ha especificado una página"
            scale={state.scale}
            onLoadProgress="Cargando página"
            onLoadSuccess="Página cargada"
            loading="Cargando Página..."
            onContextMenu={() => {
              return false;
            }}
            pageNumber={state.pageNumber}
          />
        </Document>
      </div>
    </div>
  );
};

export default CreateProgram;
