import React, { useContext, useEffect, useState } from "react";
import client from "../axios/axios-client";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import { SecurityContext } from "../security/SecurityContext";
import { SubscriptionContext } from "../library/SubscriptionContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  subscription: [],
  billDetail: {
    id: null
  },
  person: {},
  topic: {},
  status: STATUS_NOT_LOADED,
  errors: {
    error: [],
    status: false
  },
  users: {
    isSearch: false,
    element: null
  },
  newSubscription: {
    status: STATUS_NOT_LOADED,
    elements: [],
    originalItem: []
  }
};

export const SubscriptionFormContext = React.createContext([]);

export const SubscriptionFormContextProvider = ({ children }) => {
  let [errors, setErrors] = useState(initialState.errors);
  const [{ user }, { logout, refreshUser }] = useContext(SecurityContext);
  const [{}, { refreshDontSuscribed, refreshSubscriptions }] = useContext(SubscriptionContext);
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  let [users, setUsers] = useState(initialState.users);
  let [newSubscription, setNewSubscription] = useState(initialState.newSubscription);
  let [subscription, setSubscription] = useState(initialState);
  let history = useHistory();
//control de errores
useEffect(() => {
  if (errors.status) {
    showErrors(errors.error);
    setErrors({ ...errors, status: false });
  }
}, [errors]);

  useEffect(() => {
    if (subscription.status === STATUS_NOT_LOADED && subscription.billDetail.id != null) {
      setSubscription({ ...subscription, status: STATUS_LOADING });
      client()
        .get(`billing/effective/${subscription.billDetail.id}`, {
          params: {
            with: ["service","assignable.partners","package.order","product", "consumers.consumable"]
          }
        })
        .then(({ data }) => {
          if (data) {
            setSubscription({
              subscription: data.consumers,
              billDetail: data,
              topic: data.product,
              status: STATUS_LOADED
            });
          }
        })
        .catch(e => {
       console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la suscripción");
          }
        });
    }
  }, [subscription]);

  const createSubscription = (person_id, bill_detail_id) => {
    client()
      .post(`security/person/${person_id}/consume`, {
        effective_id: bill_detail_id
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Suscripción asignada satisfactoriamente");
          setSubscription({ ...subscription, status: STATUS_NOT_LOADED });
          refreshUser();
        }
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear la suscripción");
        }
      });
  };

  const sendInvitationEmail = email => {
    client()
      .post("library/send_invitation", {
        email: email,
        effective_id: subscription.billDetail.id
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Invitación enviada satisfactoriamente");
          setSubscription({ ...subscription, status: STATUS_NOT_LOADED });
        }
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar la invitación");
        }
      });
  };

  const filterUser = param => {
    client()
    .get(`security/searchUsers/${param}`)
    .then(({ data }) => {
      if (data) {
        if (data.length == 0) {
          
          setUsers({
            ...users,
            isSearch: true
          });
        } else {
          setUsers({
            ...users,
            element: data[0],
            isSearch: false
          });
        }
      } 
    })
    .catch((e) => {
      if (e.request.status === 401) {
        logout();
      } else if (e.request.status === 422) {
        setErrors({ error: e.response.data, status: true });
      } else if (e.request.status === 403) {
        history.push("/app/unauthorized");
      } else {
        alertError("Error al buscar los usuarios");
      }
    });

  };

  let setSubscriptionId = billDetail_id => {
    if (billDetail_id !== subscription.billDetail.id) {
      setSubscription({
        ...subscription,
        billDetail: { id: billDetail_id },
        status: STATUS_NOT_LOADED
      });
    }
  };

  const number_format = (amount, decimals) => {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

    return amount_parts.join(".");
  };

  return (
    <SubscriptionFormContext.Provider
      value={[
        { subscription, users, errors },
        { setSubscriptionId, filterUser, createSubscription, sendInvitationEmail, number_format }
      ]}
    >
      {children}
    </SubscriptionFormContext.Provider>
  );
};
