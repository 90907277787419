import React, { useContext, useState } from "react";
import "../layout/Header.css";
import icon from "../../../img_zeus/header/ICONO-ZEUS.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "./Notification";
import {
  faSignOutAlt,
  faCaretDown,
  faChevronLeft,
  faShoppingCart,
  faChalkboardTeacher,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import img_subscription from "../../../img_zeus/icons_menu/ICONO-SUSCRIPCION.png";
import img_company from "../../../img_zeus/icons_menu/ICONO-EMPRESA.png";
import img_audit from "../../../img_zeus/icons_menu/ICONO-AUDITORIA.png";
import img_library from "../../../img_zeus/icons_menu/ICONO-BIBLIOTECA.png";
import img_admin from "../../../img_zeus/header/Administrador.png";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import img_plan from "../../../img_zeus/icons_audit/plan.png";
const shortText = (description, range) => {
  if (description && description.length > range) {
    return description.substr(0, range) + "...";
  }
  return description;
};
const Header = () => {
  let history = useHistory();
  let [{ user, cart }, { logout, viewedNotifications }] = useContext(
    SecurityContext
  );
  const [toggleNotification, settoggleNotification] = useState(false);
  const checkSetToogleNotification = () => {
    viewedNotifications();
    settoggleNotification(!toggleNotification);
  };
  return (
    <>
      <div className="header-zeus">
        <div className="header-container">
          <div className="flex content-first">
            <img
              onClick={() => history.push("../../app/library/topics")}
              src={icon}
              className="cursor-action img-header-icon"
            />
            <h2 className="tipo-title title-header">Plataforma Zeus</h2>
          </div>
          <div className="flex content-second">
            {user.id != "" && (
              <>
                <div
                  title="Notificaciones"
                  onClick={() => checkSetToogleNotification()}
                  className="img-header-notification cursor-action"
                >
                  {user.notifications &&
                    user.notifications.filter((n) => n.viewed == false)
                      .length != 0 && (
                      <div className=" txt-center number-notification">
                        {
                          user.notifications.filter((n) => n.viewed == false)
                            .length
                        }
                      </div>
                    )}
                </div>

                {toggleNotification && (
                  <Notification
                    valueToggle={toggleNotification}
                    settoggle={settoggleNotification}
                  />
                )}
                {/*  <Link to="/app">
                  <div className="header-back cursor-action"></div>
                </Link> */}
              </>
            )}
            {user.id != "" && cart.elements.length > 0&&(
              <Link to="/app/billing/cart">
                <div className="cart-header-orange">
                  <FontAwesomeIcon className="" icon={faShoppingCart}>
                    {" "}
                  </FontAwesomeIcon>
                  {cart.elements.length > 0 && (
                    <div className=" txt-center number-cart-header">
                      {cart.elements.length}
                    </div>
                  )}
                </div>
              </Link>
            )}

            {user.id == "" && (
              <Link to="/login" className="d-none">
                <div className="header-back cursor-action"></div>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* //////////////////////////////////// DEVICE MOBILE ////////////////////////////////////// */}

      <nav className="hidden header-zeus-mobile navbar  navbar-dark">
        <div className="h-100 flex">
          <FontAwesomeIcon
            onClick={() => history.goBack()}
            className="history-left-mobile"
            icon={faChevronLeft}
          />
         
          <div className="flex flex-1 container-image-profile-mobile">
            <img
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              className="img-profile-mobile-header-home"
              src={
                process.env.REACT_APP_API_HOST +
                "/documents/" +
                (user.image && user.image.name)
              }
              onError={(e) => {
                e.target.src = imgError;
              }}
            ></img>
          </div>
          <div className="image-zeus-header background-simple"></div>
          <div className="flex flex-0">
            <div
              onClick={() => checkSetToogleNotification()}
              className="background-simple btn-notification-mobile cursor-action"
            >
              {user.notifications &&
                user.notifications.filter((n) => n.viewed == false).length !=
                  0 && (
                  <div className="white txt-center number-notification-mobile">
                    {user.notifications.filter((n) => n.viewed == false).length}
                  </div>
                )}
            </div>
          </div>
          {user.id != "" && cart.elements.length > 0&&(
              <Link to="/app/billing/cart">
                <div className="cart-header-mobile">
                  <FontAwesomeIcon className="" icon={faShoppingCart}>
                    {" "}
                  </FontAwesomeIcon>
                  {cart.elements.length > 0 && (
                    <div className=" txt-center number-cart-header-mobile">
                      {cart.elements.length}
                    </div>
                  )}
                </div>
              </Link>
            )}
          {toggleNotification && (
            <Notification
              valueToggle={toggleNotification}
              settoggle={settoggleNotification}
            />
          )}
          <div className=" flex flex-0">
            <div
              className="background-simple btn-collapse-header"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            ></div>
          </div>
        </div>

        <div
          className="collapse navbar-collapse bg-white div-general-header-mobile"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto general-ul-header-mobile">
            <li className="nav-item ">
              <Link to={`/app/profile`}>
                <img
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  className="img-profile-mobile"
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/documents/" +
                    (user.image && user.image.name)
                  }
                  onError={(e) => {
                    e.target.src = imgError;
                  }}
                ></img>
              </Link>
              <span className="user-name-mobile-header">
                {user.first_name} {user.last_name}
              </span>
             <br/>
              <span className="user-name-mobile-header">
                {user.email} 
              </span>
            </li>
            <li className="nav-item">
              <span data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link to="/app/library/topics">
                  <img className="img-standard-icon" src={img_library} />{" "}
                  <strong className="color-label-li-header-mobile">
                    Actualidad
                  </strong>
                </Link>
              </span>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="/app/library/topics"
                className="nav-link dropdown-toggle link-paddin-header-mobile"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img className="img-standard-icon" src={img_subscription} />{" "}
                <strong className="color-label-li-header-mobile">
                  Suscripción
                </strong>
                <FontAwesomeIcon
                  className="icon-down-mobile-header"
                  icon={faCaretDown}
                />
              </Link>

              <div
                className="dropdown-menu dropdown-header-mobile"
                aria-labelledby="navbarDropdown"
              >
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    className="dropdown-item"
                    to="/app/billing/suscriptions"
                  >
                    Adquirir suscripción
                  </Link>
                </span>
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    className="dropdown-item"
                    to="/app/library/topics_subscribed"
                  >
                    {" "}
                    Mis suscripciones
                  </Link>
                </span>

                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link className="dropdown-item" to="/app/billing/bills">
                    Mis compras
                  </Link>
                </span>
              </div>
            </li>
            <li className="nav-item">
              <span data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link to="/app/companies">
                  <img className="img-standard-icon" src={img_company} />{" "}
                  <strong className="color-label-li-header-mobile">
                    Empresas
                  </strong>
                </Link>
              </span>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="/app/library/topics"
                className="nav-link dropdown-toggle link-paddin-header-mobile"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img className="img-standard-icon" src={img_audit} />{" "}
                <strong className="color-label-li-header-mobile">
                  Auditorías
                </strong>
                <FontAwesomeIcon
                  className="icon-down-mobile-header"
                  icon={faCaretDown}
                />
              </Link>

              <div
                className="dropdown-menu dropdown-header-mobile"
                aria-labelledby="navbarDropdown"
              >
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link className="dropdown-item" to="/app/audit/programs">
                    {" "}
                    Programa
                  </Link>
                </span>
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link className="dropdown-item" to="/app/audit/audits">
                    Auditorías
                  </Link>
                </span>
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link
                    className="dropdown-item flex"
                    to="/app/audit_express/audits"
                  >
                    Auditoría express &nbsp;
                    <div className="background-simple icon-audit-express"></div>
                  </Link>
                </span>
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link className="dropdown-item" to="/app/audit/standards">
                    Normas
                  </Link>
                </span>
                {user.is_admin && (
                  <span
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <Link className="dropdown-item" to="/app/audit/templates">
                      Mis plantillas
                    </Link>
                  </span>
                )}
              </div>
            </li>
            <li className="nav-item">
              <span data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link to="/app/audit/actions_plans">
                  <img className="img-standard-icon" src={img_plan} />{" "}
                  <strong className="color-label-li-header-mobile">
                    Plan de acción
                  </strong>
                </Link>
              </span>
            </li>
            <li className="nav-item">
              <span data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link to="/app/inspection/evaluations">
                <FontAwesomeIcon  className="orange" icon={faList}/>
                &nbsp;
                  <strong className="color-label-li-header-mobile">
                    Inspecciones
                  </strong>
                </Link>
              </span>
            </li>
            <li className="nav-item">
              <span data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link to="/app/course/courses">
                <FontAwesomeIcon className="orange" icon={faChalkboardTeacher}/>
                &nbsp;
                  <strong className="color-label-li-header-mobile">
                   Capacitaciones
                  </strong>
                </Link>
              </span>
            </li>
            {user.is_admin && (
              <li className="nav-item li-admin-header-mobile">
                <span
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  <Link to="/app/security/admin/roles">
                    <img className="img-standard-icon" src={img_admin} />{" "}
                    <strong className="white">Administrador</strong>
                  </Link>
                </span>
              </li>
            )}

            <li className="nav-item white li-btn-close">
              <div className="flex ">
                <div className="flex-0">
                  <div
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={() => history.push(`/app/help`)}
                    className="btn-help-header-mobile background-simple"
                  ></div>
                </div>
                <div className="flex-1">
                  <div
                    onClick={logout}
                    className="tipo-cursiva bg-orange btn-close-sesion-mobile"
                  >
                    <p>
                      <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar sesión
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
