import React, { useState, useEffect, useContext } from "react";
import {
  CompanyIndexContext,
  STATUS_LOADED,
} from "../../../contexts/general/CompanyIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { Field, Form } from "react-final-form";
import Select, { components } from "react-select";
import "../company/index.css";
import { GeneralContext } from "../../../contexts/general/GeneralContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import AutoCompleteText from "react-here-autocomplete";
import { AlertContext } from "../../../contexts/alerts/AlertContext";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  company_id: null,
  item: {
    name: "",
    nit: "",
    email: "",
    representative: "",

    contact_person_id: "",
    billing_plan: "",
  },
  locationCreate: {
    country_code: "",
    locality: "",
    address: "",
    latitude: null,
    longitude: null,
    place_id: null,
  },

  originalItem: {},
};

const CreateCompany = ({ state, setState, company_id }) => {
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [address, setAddress] = React.useState("");
  const [
    { company, cities, countries },
    { createCompany, updateCompany, getCities, setcities },
  ] = useContext(CompanyIndexContext);
  let [stateCreate, setStateCreate] = useState("");
  const [citySelected, setcitySelected] = useState(null);
  const [locationCreate, setlocationCreate] = useState(
    initialState.locationCreate
  );
  const getCountryRegion = (places) => {
    let place = {
      locality: "",
      country_code: "",
      region: "",
      street: "",
      route: "",
    };

    let country = places.filter((e) => e.types.includes("country"));
    let region = places.filter((e) =>
      e.types.includes("administrative_area_level_1")
    );
    let locality = places.filter((e) => e.types.includes("locality"));
    let street = places.filter((e) => e.types.includes("street_number"));
    let route = places.filter((e) => e.types.includes("route"));

    if (country.length > 0) {
      place.country_code = country[0].short_name;
    }
    if (locality.length > 0) {
      place.locality = locality[0].short_name;
    }
    if (region.length > 0) {
      place.region = region[0].short_name;
    }
    if (street.length > 0) {
      place.street = street[0].short_name;
    }
    if (route.length > 0) {
      place.route = route[0].short_name;
    }
    return place;
  };

  const handleSelect = async (value) => {
    try {
      setAddress(value);
      let results = await geocodeByAddress(value);
      let latLng = await getLatLng(results[0]);
      let place = getCountryRegion(results[0].address_components);
      setlocationCreate({
        ...locationCreate,
        country_code: place.country_code,
        locality: place.locality,
        address: value + " (" + place.route + "," + place.street + ")",
        region: place.region,
        latitude: latLng.lat,
        longitude: latLng.lng,
        place_id: results[0].place_id,
      });
    } catch (error) {
      alertWarning("No se encontraron coordenadas,digíte la dirección exacta");
      setAddress("");
    }
  };

  useEffect(() => {
    if (company_id && company.status === STATUS_LOADED) {
      let el = company.elements.filter((e) => e.id === company_id)[0];
      setlocationCreate({
        ...locationCreate,
        country_code: el.location ? el.location.country_code : "",
        locality: el.location ? el.location.locality : "",
        address: el.location ? el.location.address : "",
        region: el.location ? el.location.locality : "",
        latitude: el.location ? el.location.latitude : "",
        longitude: el.location ? el.location.longitude : "",
        place_id: el.location ? el.location.place_id : "",
      });
      setAddress(el.location ? el.location.address : "");
      if (el.city) {
        setcities({
          ...cities,
          status: STATUS_LOADED,
          iso: el.city.iso,
          elements: [el.city],
        });
        setcitySelected(el.city.id);
      }

      setStateCreate({
        ...stateCreate,
        item: {
          name: el.name,
          nit: el.nit,
          address: el.location ? el.location.address : "",
          email: el.email,
          phone: el.phone,
          representative: el.representative,
          contact_person_id: el.contact_person_id,
          billing_plan: el.billing_plan,
          city: el.city,
          city_id: el.city_id,
        },
        company_id: company_id,
        status: STATUS_EDIT,
      });
    }
  }, [company_id, company]);

  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      if (locationCreate.locality == "" && locationCreate.place_id == null) {
        locationCreate.address = address;
      }

      updateCompany(locationCreate, formObj, stateCreate.company_id);
      setState({ ...state, toggle_modal: false, company_id: null });
    } else {
      if (locationCreate.address == "") {
        locationCreate.address = address;
      }
      createCompany(locationCreate, formObj);
      setState({ ...state, toggle_modal: false });
    }
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#ECAE70" : "white",
      cursor: state.isDisabled ? "not-allowed" : "default",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  const getCitiesCheck = (city) => {
    if (city.length > 2 && cities) {
      getCities(city);
    }
  };
  return (
    <Form
      onSubmit={(formObj) => {
        formObj.city_id = citySelected;
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form-company">
          <div className="form-group txtarea-form-company">
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Nombre:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Nombre de la empresa
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="txtarea-form-company">
            <div className="form-group flex-1 ">
              <label className="control-label">Pais</label>

              {countries.status === STATUS_LOADED && (
                <Select
                  value={
                    countries.elements.filter((c) => c.cc_iso == cities.iso)[0]
                  }
                  onChange={(e) =>
                    setcities({
                      ...cities,
                      elements: [],
                      iso: e ? e.cc_iso : null,
                    })
                  }
                  className="tipo-description select-zeus-analyze"
                  placeholder={"Seleccione un pais"}
                  getOptionLabel={(option) => `${option.country_name}`}
                  getOptionValue={(option) => option.cc_iso}
                  options={countries.elements}
                  isClearable={true}
                />
              )}
            </div>
            &nbsp;
            <div className="form-group flex-1 ">
              <label className="control-label">Ciudad</label>

              <Select
                value={
                  cities.status === STATUS_LOADED
                    ? citySelected
                      ? cities.elements.filter((c) => c.id === citySelected)[0]
                      : null
                    : null
                }
                onChange={(e) =>
                  e ? setcitySelected(e.id) : setcitySelected(null)
                }
                onInputChange={(e) => getCitiesCheck(e)}
                className="tipo-description select-zeus-analyze"
                placeholder={"Escriba su ciudad "}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.iso}
                options={cities.status === STATUS_LOADED ? cities.elements : []}
                isClearable={true}
              />
            </div>
          </div>
          <div className="form-group txtarea-form-company">
            <Field name="address" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Dirección:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Dirección de la empresa
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>

            {/*  <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="form-group-zeus">
                  <input
                    type="text"
                    name="text"
                    autoComplete="off"
                    required
                    {...getInputProps({
                      placeholder: "",
                      className: "",
                    })}
                  />
                  <label htmlFor="text" className="label-name-zeus">
                    <span className="content-name-zeus">
                      Dirección de la empresa
                    </span>
                  </label>
                  <div>
                    {loading ? <div>...Buscando</div> : null}

                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#df8c37" : "#fff",
                        color: suggestion.active ? "#fff" : "#000",
                      };

                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete> */}
          </div>
          <div className="form-group txtarea-form-company">
            <Field name="nit" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Nit:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Nit de la empresa
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-company">
            <Field name="representative" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Representante:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Nombre del representante legal
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-company">
            <Field name="email" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Email:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Email de la empresa
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group txtarea-form-company">
            <Field name="phone" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Teléfono:</label>
                  <div className="form-group-zeus">
                    <input
                      type="text"
                      name="text"
                      autoComplete="off"
                      {...input}
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus">
                        Teléfono de la empresa
                      </span>
                    </label>
                  </div>

                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange required-alert-form">
                      {meta.error}
                    </span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="btn-actions-form">
            <input
              type="submit"
              value="Guardar"
              className="btn-orange-default"
            />
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateCompany;
