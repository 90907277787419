import React, { useEffect, useContext, useState } from "react";
import {
  Approved,
  BillingContext,
} from "../../contexts/billing/BillingContext";
import "../billing/Payment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AlertContext } from "../../contexts/alerts/AlertContext";
import imgAudit from "../../../src/img_zeus/Auditorias.png";
import { Link, useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import TooltipModule from "./TooltipModule";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

const MODULE_LIBRARY = "library";
const SERVICE_LIBRARY = 4;
const MODULE_AUDIT = "audit";
const SERVICE_AUDIT = 1;
const MODULE_AUDIT_EXPRESS = "audit_express";
const SERVICE_AUDIT_EXPRESS = 7;
const MODULE_ACTION_PLAN = "plan";
const MODULE_CONSULTING = "consulting";
const MODULE_CAPACITY = "capacity";
const SERVICE_CAPACITY = 3;
const MODULE_INSPECTION = "inspection";
const SERVICE_INSPECTION = 6;

const initialState = {
  module: MODULE_LIBRARY,
};
const Payment = ({ computedMatch }) => {
  const [
    { services, topics, packageOnSale, companyPackage },
    {
      number_format,
      addToCart,
      filterConditions,
      filterPolicies,
      setcompanyPackage,
    },
  ] = useContext(BillingContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [{ user }] = useContext(SecurityContext);
  const { params, url, path } = computedMatch;
  const { moduleSelect, personal } = params;
  const [module, setModule] = useState(initialState.module);
  const [checkTopic, setcheckTopic] = useState(true);
  useEffect(() => {
    filterConditions();
    filterPolicies();
    let newModule = SERVICE_LIBRARY;
    if (moduleSelect != "") {
      switch (moduleSelect) {
        case MODULE_AUDIT:
          newModule = SERVICE_AUDIT;
          break;
        case MODULE_CAPACITY:
          newModule = SERVICE_CAPACITY;
          break;
        case MODULE_AUDIT_EXPRESS:
          newModule = SERVICE_AUDIT_EXPRESS;
          break;
          case MODULE_INSPECTION:
            newModule = SERVICE_INSPECTION;
            break;
      }
    }
    setModule(newModule);
  }, [moduleSelect]);

  const checkAddToCart = (packageOnSale) => {
    if (!packageOnSale.is_persona) {
      setcompanyPackage({ ...companyPackage, open: true, pack: packageOnSale });
    } else {
      addToCart(packageOnSale, null);
    }
  };
  const getNameModule = () => {
    let mod = services.elements.filter((s) => s.id == module);
    if (mod.length > 0) {
      return "MODULO " + mod[0].name;
    } else {
      return "SELECCIONE UN MODULO ";
    }
  };
  return (
    <div className="flex flex-wrap">
      {companyPackage.open && (
        <div className="back-document">
          <div className="modal-select-compnay-suscription">
            <div className="header-select-compnay-suscription">
              <div
                className="salir-company"
                onClick={() => {
                  setcompanyPackage({ ...companyPackage, open: false });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description"></h3>
            </div>
            <div className="content-select-compnay-suscription">
              <div className="form-group">
                <label>
                  Seleccione una empresa a la que va asignar las suscripcion
                </label>
                <Select
                  value={user.contact_companies.filter(
                    (c) => c.id === companyPackage.id
                  )}
                  onChange={(c) => {
                    addToCart(companyPackage.pack, c.id);
                    setcompanyPackage({ ...companyPackage, open: false });
                  }}
                  className="tipo-description select-company-payment filter-select-table"
                  placeholder={"Empresa"}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={user.contact_companies}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="banner-payment background-simple flex">
        <h3 className="txt-center white margin-auto">
          Selecciona el plan que más te convenga
        </h3>
      </div>

      <div className="flex justify-center w-100 general-container-desktop-payment">
        <div className="flex flex-column">
          <div className="flex container-tarjets-paymnet">
            <div>
              <div className={`w-100 title-suscription-module txt-upper`}>
                {getNameModule()}
              </div>

              <div className="">
                {packageOnSale.elements
                  .filter(
                    (pa) =>
                      pa.module == module &&
                      (personal == undefined
                        ? true
                        : JSON.stringify(pa.is_persona) == personal)
                  )
                  .map((pack) => {
                    return (
                      <div
                        key={pack.id}
                        className="bg-white  container-tarjet-service"
                      >
                        <div
                          className={`bg-module-library   tipo-title txt-center service-title flex`}
                        >
                          {pack.is_promotion && (
                            <span className="most-popular-payment">
                              Mas popular
                            </span>
                          )}

                          <div
                            className={
                              pack.is_persona
                                ? "icon-paymnet-individual"
                                : "icon-paymnet-company"
                            }
                          ></div>
                          <h5>
                            {pack.is_persona
                              ? "PLAN INDIVIDUAL"
                              : "PLAN EMPRESARIAL"}
                          </h5>
                        </div>
                        <div
                          className={`flex-wrap content-check-subscription ${
                            module == MODULE_AUDIT
                              ? "content-texts-payment-audit"
                              : ""
                          }`}
                        >
                          <div className="txt-center tipo-titulo">
                            {pack.timeMonth} meses
                          </div>
                          {pack.details &&
                            pack.details.map((detail) => {
                              return (
                                <div className="flex" key={detail.id}>
                                  <FontAwesomeIcon
                                    className="color-module-audit"
                                    icon={faCheckCircle}
                                  />
                                  &nbsp;
                                  <span className="span-paymnet-details color-gray tipo-description">
                                    {detail.quantity}{" "}
                                    {detail.service.id == SERVICE_CAPACITY &&
                                      "MB de "}
                                    {detail.service.id == SERVICE_LIBRARY &&
                                      parseInt(detail.quantity) == 1 &&
                                      "Suscripción al módulo "}
                                    {detail.service.id == SERVICE_LIBRARY &&
                                      parseInt(detail.quantity) != 1 &&
                                      "Suscripciones al módulo "}
                                    {parseInt(detail.quantity) == 1
                                      ? detail.service.singular_name
                                      : detail.service.name}{" "}
                                    &nbsp;
                                    {detail.product && detail.product.name}
                                  </span>
                                </div>
                              );
                            })}
                          <hr />
                          {pack.benefits &&
                            pack.benefits.map((benefit, index) => {
                              return (
                                <div key={index} className="flex">
                                  <FontAwesomeIcon
                                    className="color-module-audit"
                                    icon={faCertificate}
                                  />
                                  &nbsp;
                                  <span className="span-paymnet-details color-gray tipo-description">
                                    {benefit.note}
                                  </span>
                                </div>
                              );
                            })}
                          <div className="tipo-title txt-center total-payment-desktop flex-column">
                            {module == MODULE_AUDIT && (
                              <img
                                className="img-payment-select-module"
                                src={imgAudit}
                              ></img>
                            )}
                            <br />
                            <strong>
                              ${" "}
                              {number_format(
                                parseFloat(pack.value) +
                                  parseFloat((pack.value * pack.tax) / 100),
                                0
                              )}
                            </strong>{" "}
                            COP
                          </div>
                          <div> <div
                            onClick={() => {
                              checkAddToCart(pack);
                            }}
                            className={`tipo-title cursor-action txt-center  justify-center btn-payment-susbscription bg-module-library`}
                          >
                            AGREGAR AL CARRITO
                          </div></div>
                         
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <TooltipModule
              services={services}
              module={module}
              setModule={setModule}
            />
          </div>
        </div>
      </div>
      {/*/////////////////////////// MOBILE ///////////////////////// */}
      <div className="general-container-mobile-payment hidden">
        <div div="flex flex-column">
        
          <div className="">
            <div className={`w-100 title-suscription-module txt-upper`}>
              {getNameModule()}
            </div>
            <div className="">
              {packageOnSale.elements
                .filter(
                  (pa) =>
                    pa.module == module &&
                    (personal == undefined
                      ? true
                      : JSON.stringify(pa.is_persona) == personal)
                )
                .map((pack) => {
                  return (
                    <div
                      key={pack.id}
                      className="bg-white   container-tarjet-service-mobile"
                    >
                      <div
                        className={`bg-module-library   tipo-title txt-center service-title flex`}
                      >
                        {pack.is_promotion && (
                          <span className="most-popular-payment">
                            Mas popular
                          </span>
                        )}

                        <div
                          className={
                            pack.is_persona
                              ? "icon-paymnet-individual"
                              : "icon-paymnet-company"
                          }
                        ></div>
                        <h5>
                          {pack.is_persona
                            ? "PLAN INDIVIDUAL"
                            : "PLAN EMPRESARIAL"}
                        </h5>
                      </div>
                      <div
                        className={`flex-wrap content-check-subscription ${
                          module == MODULE_AUDIT
                            ? "content-texts-payment-audit"
                            : ""
                        }`}
                      >
                        <div className="txt-center tipo-titulo">
                          {pack.timeMonth} meses
                        </div>
                        {pack.details &&
                          pack.details.map((detail) => {
                            return (
                              <div className="flex" key={detail.id}>
                                <FontAwesomeIcon
                                  className="color-module-audit"
                                  icon={faCheckCircle}
                                />
                                &nbsp;
                                <span className="span-paymnet-details color-gray tipo-description">
                                  {detail.quantity}{" "}
                                  {detail.service.id == SERVICE_CAPACITY &&
                                    "MB de "}
                                  {detail.service.id == SERVICE_LIBRARY &&
                                    parseInt(detail.quantity) == 1 &&
                                    "Suscripción al módulo "}
                                  {detail.service.id == SERVICE_LIBRARY &&
                                    parseInt(detail.quantity) != 1 &&
                                    "Suscripciones al módulo "}
                                  {parseInt(detail.quantity) == 1
                                    ? detail.service.singular_name
                                    : detail.service.name}{" "}
                                  &nbsp;
                                  {detail.product && detail.product.name}
                                </span>
                              </div>
                            );
                          })}
                        <hr />
                        {pack.benefits &&
                          pack.benefits.map((benefit, index) => {
                            return (
                              <div key={index} className="flex">
                                <FontAwesomeIcon
                                  className="color-module-audit"
                                  icon={faCertificate}
                                />
                                &nbsp;
                                <span className="span-paymnet-details color-gray tipo-description">
                                  {benefit.note}
                                </span>
                              </div>
                            );
                          })}
                        <div className="tipo-title txt-center total-payment-desktop flex-column">
                          {module == MODULE_AUDIT && (
                            <img
                              className="img-payment-select-module"
                              src={imgAudit}
                            ></img>
                          )}
                          <br />
                          <strong>
                            ${" "}
                            {number_format(
                              parseFloat(pack.value) +
                                parseFloat((pack.value * pack.tax) / 100),
                              0
                            )}
                          </strong>{" "}
                          COP
                        </div>
                        <div
                          onClick={() => {
                            checkAddToCart(pack);
                          }}
                          className={`tipo-title cursor-action txt-center  justify-center btn-payment-susbscription bg-module-library`}
                        >
                          AGREGAR AL CARRITO
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
