import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
const db = firebase.firestore();
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const NOTIFICATION_CREATE_AUDIT = "create-audit";
const NOTIFICATION_MODULE_AUDIT = "audit";
const STATUS_APPROVED = 7;
let initialState = {
  status: STATUS_NOT_LOADED,
  program: {
    status: STATUS_NOT_LOADED,
    program_id: null,
    element: {},
  },
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  suscriptionsAudit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    init_date: false,
    end_date: false,
    standard_filter: false,
  },
  errors: {
    error: [],
    status: false,
  },
};

export const ProgramFormContext = React.createContext([]);

export const ProgramFormContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [program, setProgram] = useState(initialState.program);
  let [standard, setStandard] = useState(initialState.standard);
  const [suscriptionsAudit, setsuscriptionsAudit] = useState(initialState.suscriptionsAudit);
  let [filter, setFilter] = useState(initialState.filter);
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [effectiveSelected, seteffectiveSelected] = useState(null);
  const [modalAauditProgram, setmodalAauditProgram] = useState(false);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (program.program_id != null && program.status === STATUS_NOT_LOADED) {
      setProgram({ ...program, status: STATUS_LOADING });
      client()
        .get(`audit/program/${program.program_id}`, {
          params: {
            with: [
              "audits.participants.person",
              "audits.participants.participantType",
              "audits.liderPerson",
              "audits.evaluations.items.results",
              "audits.evaluations.items.auditable_item",
              "company.contact",
              "audits.standard",
              "company.partners",
              "audits.risks",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setProgram({ ...program, status: STATUS_LOADED, element: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else {
            alertError("Error al buscar el programa");
          }
        });
    }
  }, [program]);

  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      setStandard({ ...standard, status: STATUS_LOADING });
      client()
        .get("audit/standard", {
          params: {
            filters: {
              status_id: STATUS_APPROVED,
            },
          },
        })

        .then(({ data }) => {
          if (data) {
            setStandard({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [standard]);

  let setProgramId = (program_id) => {
    if (program_id !== program.program_id) {
      setProgram({ ...program, program_id: program_id });
    }
  };
  useEffect(() => {
    if (suscriptionsAudit.status === STATUS_NOT_LOADED) {
      setsuscriptionsAudit({ ...suscriptionsAudit, status: STATUS_LOADING });
      client()
        .get("audit/subscriptionAudit")

        .then(({ data }) => {
          if (data) {
            setsuscriptionsAudit({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else {
            alertError("Error al buscar las suscripcions");
          }
        });
    }
  }, [suscriptionsAudit]);
  const deleteAudit = (id) => {
    client()
      .delete(`audit/audit/${id}`)
      .then(({ data }) => {
        alertSuccess("Auditoria eliminado satisfactoriamente");
        let el = program.element.audits.filter((e) => e.id !== id);

        setProgram({
          ...program,
          element: { ...program.element, audits: el },
        });
      });
  };

  const filterInit = () => {
    let filterInit = program.element.audits.sort(function (a, b) {
      var c = new Date(a.init_date);
      var d = new Date(b.init_date);
      if (filter.init_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setProgram({ ...program, element: { ...program.element, audits: filterInit } });
    setFilter({ ...filter, init_date: !filter.init_date });
  };

  const filterEnd = () => {
    let filterEnd = program.element.audits.sort(function (a, b) {
      var c = new Date(a.end_date);
      var d = new Date(b.end_date);
      if (filter.end_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setProgram({ ...program, element: { ...program.element, audits: filterEnd } });
    setFilter({ ...filter, end_date: !filter.end_date });
  };

  const filterStandard = () => {
    let filterStandard = universalSortString(
      filter.standard_filter,
      program.element.audits,
      "standard.short_name"
    );

    setProgram({ ...program, element: { ...program.element, audits: filterStandard } });
    setFilter({ ...filter, standard_filter: !filter.standard_filter });
  };
  const universalSortString = (toggle, sortArray, sortAttributeString) => {
    let filter = sortArray.sort(function (a, b) {
      let compare = toggle ? ">" : "<";
      let condition = eval(
        "a." + sortAttributeString + " " + compare + " b." + sortAttributeString
      );
      return condition ? -1 : 1;
    });
    return filter;
  };
  const sendNotificationAddAuditLider = (person, auditSend) => {
    db.collection(`users/${person.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `has sido vinculado como auditor lider en la auditoría  <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${auditSend.id}"> ${auditSend.title}</a>`,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_CREATE_AUDIT,
      module: NOTIFICATION_MODULE_AUDIT,
    });
  };
  const createAudit = (
    { title, standard_id, init_date, end_date, lider_person_id },
    company_id,
    responsable_id
  ) => {
    client()
      .post("audit/audit", {
        title: title,
        standard_id: standard_id.id,
        init_date: init_date,
        end_date: end_date,
        lider_person_id: lider_person_id.id,
        company_id: company_id,
        program_id: program.program_id,
        responsable_person_id: responsable_id,
        effective_id: effectiveSelected,
      })
      .then(({ data }) => {
        setmodalAauditProgram(false);
        sendNotificationAddAuditLider(lider_person_id, data);
        alertSuccess("Auditoría creada satisfactoriamente");
        setProgram({ ...program, status: STATUS_NOT_LOADED });
        setsuscriptionsAudit({ ...suscriptionsAudit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/library/topic/billing/suscription/audit");
          //history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la auditoria");
        }
      });
  };
  const sendInvitationLider = ({ email }) => {
    console.log(email);
  };
  const updateAudit = (
    { title, standard_id, init_date, end_date, lider_person_id },
    company_id,
    id
  ) => {
    let editAudit = program.element.audits.filter((a) => a.id == id)[0];

    if (editAudit.lider_person_id != lider_person_id.id) {
      sendNotificationAddAuditLider(lider_person_id, editAudit);
    }
    client()
      .post(`audit/audit/${id}`, {
        title: title,
        standard_id: standard_id.id,
        init_date: init_date,
        end_date: end_date,
        lider_person_id: lider_person_id.id,
        company_id: company_id,
        program_id: program.program_id,
      })
      .then(() => {
        setmodalAauditProgram(false);
        alertSuccess("Auditoria actualizada satisfactoriamente");
        setProgram({ ...program, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la auditoria");
        }
      });
  };

  return (
    <ProgramFormContext.Provider
      value={[
        { program, standard, suscriptionsAudit, effectiveSelected, modalAauditProgram },
        {
          setProgramId,
          deleteAudit,
          createAudit,
          updateAudit,
          filterInit,
          filterEnd,
          filterStandard,
          sendInvitationLider,
          seteffectiveSelected,
          setmodalAauditProgram,
        },
      ]}
    >
      {children}
    </ProgramFormContext.Provider>
  );
};
