import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Field, Form } from "react-final-form";
import ImageUploader from "react-images-upload";
import Select, { components } from "react-select";
import cloneDeep from "lodash/cloneDeep";
import {
  LibraryContext,
  STATUS_LOADED,
  STATUS_APPROVED,
  STATUS_ERASER,
} from "../../../contexts/library/LibraryContext";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  statusDocument: [
    { name: "Publicado", id: STATUS_APPROVED },

    { name: "Borrador", id: STATUS_ERASER },
  ],
  status: STATUS_NEW,
  item: {
    id: "",
    title: "",
    description: "",
    document: "",
    is_free: false,
    references: "",
  },
  originalItem: {},
};

const Index = ({ documentState, setModalState, modalState }) => {
  let [{ documents }, actions] = useContext(LibraryContext);
  let [state, setState] = useState(initialState);
  const [document, setdocument] = useState();

  useEffect(() => {
    if (documentState && documents.status === STATUS_LOADED) {
      let el = cloneDeep(documents.elements.filter((e) => e.id == documentState)[0]);
      el.status_id = initialState.statusDocument.filter(
        (s) => s.id == el.status_id
      )[0]; 
      setState({
        ...state,
        item: {
          id: el.id,
          title: el.title,
          description: el.description,
          document: el.document,
          is_free: el.is_free,
          references: el.references,
          status_id: el.status_id,
        },
        originalItem: el,
        status: STATUS_EDIT,
      });
    }
  }, [documents]);

  let setField = (field) => (e) => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };

  let setDocument = (field) => (e) => {
    setdocument(e.target.files[0]);
  };

  let functionSubmit = (formObj) => {
    if (state.status === STATUS_EDIT) {
      actions.updateDocument(formObj, document, state.item.is_free);
      setModalState(false);
    } else {
      actions.createDocument(formObj, document, state.item.is_free);
      setModalState(false);
    }
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  return (
    <div className="component-form-document">
      <Form
        onSubmit={(formObj) => {
          formObj.status_id = formObj.status_id.id;
          functionSubmit(formObj);
        }}
        initialValues={state.originalItem}
      >
        {({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="form-document"
          >
            <div className="container-form-document">
              <div className="form-group">
                <Field name="title" validate={required}>
                  {({ input, meta }) => (
                    <>
                     <strong className="tipo-title">
                            Nombre
                          </strong>
                      <input
                        {...input}
                        className="form-control tipo-description"
                        type="text"
                        placeholder="Nombre del documento"
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <Field name="description" validate={required}>
                  {({ input, meta }) => (
                    <>
                         <strong className="tipo-title">
                            Descripción
                          </strong>

                      <textarea
                        {...input}
                        className="form-control tipo-description"
                        type="text"
                        placeholder="Descripción del Documento"
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="base-form-document">
                <div className="form-group">
                  <input
                    type="file"
                    accept=".doc, .docx, .xls,.xlsx, .pdf, .mp4, .ppt, .pptx, .jpg, .jpeg, .png, .mov"
                    className="form-control input-document tipo-description"
                    onChange={setDocument("document")}
                  />
                </div>
                <div className="references-group">
                  <div className="form-group">
                    <Field name="references" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <strong className="tipo-title">
                            Referencias del Documento
                          </strong>
                          <textarea
                            {...input}
                            rows="4"
                            className="form-control txt-references tipo-description"
                            type="text"
                            placeholder="Referencias del Documento"
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="form-group">
                    <strong className="tipo-title">Licencia de uso</strong>

                    <br />
                    <div className="content-input-document">
                      {state.status === STATUS_EDIT && (
                        <>
                          <input
                            id="premium"
                            name="free"
                            className="input-premium"
                            value={false}
                            defaultChecked={
                              state.item.is_free == false ? true : false
                            }
                            type="radio"
                            onChange={setField("is_free")}
                          />
                          <label htmlFor="premium" className="tipo-description">
                            Premium
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            id="free"
                            className="input-free"
                            name="free"
                            defaultChecked={
                              state.item.is_free == true ? true : false
                            }
                            value={true}
                            type="radio"
                            onChange={setField("is_free")}
                          />
                          <label htmlFor="free" className="tipo-description">
                            Gratuito
                          </label>
                        </>
                      )}
                      {state.status === STATUS_NEW && (
                        <>
                          <input
                            id="premium"
                            name="free"
                            className="input-premium"
                            value={false}
                            defaultChecked
                            type="radio"
                            onChange={setField("is_free")}
                          />
                          <label htmlFor="premium" className="tipo-description">
                            Premium
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <input
                            id="free"
                            className="input-free"
                            name="free"
                            value={true}
                            type="radio"
                            onChange={setField("is_free")}
                          />
                          <label htmlFor="free" className="tipo-description">
                            Gratuito
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div>
                      <strong className="tipo-title">Estado:</strong>
                    </div>
                    <Field name="status_id" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <Select
                            {...input}
                            className="tipo-description"
                            placeholder={"Seleccione una opción"}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={initialState.statusDocument}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="btn-actions-form">
                {state.status === STATUS_EDIT ? (
                  <>
                    <input
                      type="submit"
                      className="btn-orange-default"
                      value="Guardar"
                    />
                  </>
                ) : (
                  <>
                    <div className="form-group">
                      <input
                        type="submit"
                        className="btn-orange-default"
                        value="Crear"
                      />
                    </div>
                  </>
                )}
              </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default Index;
