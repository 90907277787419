import React, { useContext, useEffect, useState } from "react";
import { EffectiveIndexContext } from "../../../contexts/billing/EffectiveIndexContext";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
const MODEL_AUDIT = "App\\Models\\Audit";
const MODEL_AUDIT_FOLDER = "App\\Models\\AuditFolder";
const MODEL_ITEM = "App\\Models\\Item";
const MODEL_NOTE = "App\\Models\\Note";
const MODEL_EVALUATION = "App\\Models\\Evaluation";

const Form = ({ computedMatch: { params, url } }) => {
  let history = useHistory();
  let { model, model_id } = params;
  const [ascSize, setascSize] = useState(true);
  const [
    { effectives, metaRow, rows },
    { seteffectives, setmetaRow },
  ] = useContext(EffectiveIndexContext);
  useEffect(() => {
    seteffectives({ ...effectives, model: model, model_id: model_id });
  }, [model, model_id]);
  useEffect(() => {
    setFieldOrderBy(`document.size_mb:${ascSize ? "asc" : "desc"}`);
  }, [ascSize]);
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldOrderBy = (value) => {
    setmetaRow({ ...metaRow, orderBy: [value] });
  };
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const getRoute = (document) => {
    switch (document.documentable_type) {
      case MODEL_AUDIT:
        return (
          <Link to={`/app/audit/${document.documentable_id}`}>
            <span className="cursor-action link-audit-index">
              Auditoria: {document.documentable && document.documentable.title}/
              {document.title}
            </span>
          </Link>
        );
        break;
      case MODEL_AUDIT_FOLDER:
        return (
          <Link
            to={`/app/audit/${
              document.documentable && document.documentable.audit_id
            }`}
          >
            <span className="cursor-action link-audit-index">
              Carpeta: {document.documentable && document.documentable.name}
              /Auditoria/
              {document.documentable && document.documentable.audit_id}/
              {document.title}
            </span>
          </Link>
        );
        break;
      case MODEL_ITEM:
        return (
          <Link
            to={`/app/audit/evaluation/item/${
              document.documentable && document.documentable_id
            }`}
          >
            <span className="cursor-action link-audit-index">
              Item: {document.documentable && document.documentable.number}/
              {document.title}
            </span>
          </Link>
        );
        break;
      case MODEL_NOTE:
        
        if (
          document.documentable &&
          document.documentable.notifiable_type === MODEL_EVALUATION
        ) {
          return (
            <Link
              to={`/app/audit/evaluation/${
                document.documentable && document.documentable.notifiable_id
              }`}
            >
              <span className="cursor-action link-audit-index">
                Nota de evaluacion: {document.documentable &&  document.documentable.notifiable_id}/
                {document.title}
              </span>
            </Link>
          );
        }

        break;
    }
  };
  return (
    <div>
      <div className="container-index-analyzes-audit">
        <div className="flex content-title-programs">
          <div className="tipo-title title-audits">Consumos</div>
        </div>
        <div className="table-audits">
          <div className="flex justify-betwen">
            <h3>Consumos de documentos</h3>
          </div>
          <div className="table-analyze-audits">
            {" "}
            <table className=" table table-light">
              <thead className="thead-dark">
                <tr className="txt-center">
                  <th title="Titulo" scope="col">
                    Titulo
                  </th>
                  <th title="Fecha de subida" scope="col">
                    Fecha de subida
                  </th>
                  <th style={{ width: "68px" }} title="Extension" scope="col">
                    Extension
                  </th>
                  <th style={{ width: "90px" }} title="Peso" scope="col">
                    Peso{" "}
                    <FontAwesomeIcon
                      className="cursor-action"
                      onClick={() => setascSize(!ascSize)}
                      icon={ascSize ? faCaretUp : faCaretDown}
                    />
                  </th>
                  <th title="Peso" scope="col">
                    Ubicacion
                  </th>
                </tr>
              </thead>
              <tbody className="tipo-description">
                <tr>
                  <th>
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("title", e)}
                        value={metaRow.filtersLike["title"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {/* <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("created_at", e)}
                        value={metaRow.filtersLike["created_at"]}
                        type="text"
                      />
                    </div> */}
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("type", e)}
                        value={metaRow.filtersLike["type"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("size_mb", e)}
                        value={metaRow.filtersLike["size_mb"]}
                        type="text"
                      />
                    </div>
                  </th>
                </tr>

                {effectives.elements.map((document) => {
                  return (
                    <tr className="txt-center" key={document.id}>
                      <th className="txt-left flex">
                        <div
                          className={`background-simple btn-extension-effective-form-document ${
                            document.type == "pdf" ? "ext-docs-2" : "ext-img-2"
                          }`}
                        ></div>
                        <Link to={`/app/library/document/${document.id}`}>
                          <span className="cursor-action link-audit-index">
                            {document.title}
                          </span>
                        </Link>
                      </th>
                      <td>{document.created_at}</td>
                      <td>{document.type}</td>
                      <td>{parseFloat(document.size_mb).toFixed(2)} MB</td>
                      <td>{getRoute(document)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ margin: "10PX" }} className="justify-end flex">
            <Pagination
              activePage={metaRow.page}
              itemsCountPerPage={metaRow.limit}
              totalItemsCount={rows}
              pageRangeDisplayed={5}
              itemClass="page-item item-page-zeus"
              linkClass="page-link page-link-zeus"
              onChange={(e) => setFieldMeta(e, "page")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
