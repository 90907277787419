import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { AlertContext } from "../../contexts/alerts/AlertContext";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  countries: {
    status: STATUS_NOT_LOADED,
    elemments: [],
  },
  formErrors: {},
};

export const GeneralContext = React.createContext([]);

export const GeneralContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, showErrors, alertError }] = useContext(AlertContext);
  const [countries, setcountries] = useState(initialState.countries);
  const [cities, setcities] = useState(initialState.cities);

  let [errors, setErrors] = useState(initialState.errors);
  useEffect(() => {
    if (countries.status === STATUS_NOT_LOADED) {
      setcountries({ ...countries, status: STATUS_LOADING });
      client()
        .get(`general/country`)
        .then(({ data }) => {
          if (data) {
            setcountries({ ...countries, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los paises");
          }
        });
    }
  }, [countries]);

  return (
    <GeneralContext.Provider value={[{ countries }, {  }]}>
      {children}
    </GeneralContext.Provider>
  );
};
