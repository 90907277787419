import React, { useContext, useEffect } from "react";
import { BillingContext } from "../../contexts/billing/BillingContext";
import { AlertContext } from "../../contexts/alerts/AlertContext";
import "../billing/ListBill.css";
import iconBill from "../../img_zeus/icons_bill/ICONO-FACTURA-2.png";
import iconBill2 from "../../img_zeus/icons_bill/ICONO-FACTURA-1.png";
import moment from "moment";
import { SecurityContext } from "../../contexts/security/SecurityContext";

const Bills = ({ computedMatch }) => {
  const [{ bills, metaRow }, { number_format, setmetaRow }] = useContext(
    BillingContext
  );
  const [{user}, { viewGeneral }] = useContext(SecurityContext);
  useEffect(() => {
    viewGeneral("myPayments");
  }, []);
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  return (
    <div className="content-general-bill">
      <div className="banner-payment background-simple flex">
        <h3 className="txt-center white margin-auto tipo-title">
          Aquí puedes encontrar tus datos de facturación y compras
        </h3>
      </div>
      <div className="content-bill shadow">
        <div className="img-content-bill tipo-description">
          <img src={iconBill} className="icon-bill" />
          <div>Compras</div>
        </div>
        <table className="table table-bills tipo-description txt-center">
          <thead className="thead-bills">
            <tr>
              <th scope="col">FECHA</th>
              {user.is_admin && <th scope="col">CLIENTE</th>}

              <th scope="col"># COMPRA</th>
              <th scope="col">VALOR</th>
              <th scope="col">COMPROBANTE</th>
            </tr>
          </thead>

          <tbody className="tbody-bills">
            <tr>
              <th>
                <div>
                  <input
                    className="input-filter-table"
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("created_at", e)}
                    value={metaRow.filtersLike["created_at"]}
                    type="text"
                  />
                </div>
              </th>
              {user.is_admin && (
                <th>
                  <div>
                    <input
                      className="input-filter-table"
                      placeholder="filtrar"
                      onChange={(e) =>
                        setFieldFilter("consumablePerson:first_name", e)
                      }
                      value={metaRow.filtersLike["consumablePerson:first_name"]}
                      type="text"
                    />
                  </div>
                </th>
              )}
              <th>
                <div>
                  <input
                    className="input-filter-table"
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("bill_number", e)}
                    value={metaRow.filtersLike["bill_number"]}
                    type="text"
                  />
                </div>
              </th>
            </tr>

            {bills.elements &&
              bills.elements.map((bill) => (
                <tr key={bill.id}>
                  <td>{moment(bill.created_at).format("DD MMMM YYYY")}</td>
                  {user.is_admin && (
                    <td>{bill.consumable && bill.consumable.first_name}</td>
                  )}

                  <td>{bill.bill_number}</td>
                  <td>${number_format(bill.value)}</td>
                  <td>
                    <img
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_API_HOST +
                            `billing/showBill/${bill.bill_number}`
                        )
                      }
                      src={iconBill2}
                      className="icon-bill-2 cursor-action"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Bills;
