import React, { useContext, useState, useEffect } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { InspectionIndexContext } from "../../../contexts/evaluation/InspectionIndexContext";
import IconShare from "../../../img_zeus/default/share-Icono-naranaja.png";
import moment from "moment";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import userDefault from "../../../img_zeus/default/PROFILE.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const NUMBER_CARACTER = 5;
const ShareInspection = () => {
  const [
    { modalshare, inspection, shareSelect, textSearchUserShare },
    {
      setmodalshare,
      setshareSelect,
      settextSearchUserShare,
      getUidEvaluation,
      deleteEvaluationOwner,
    },
  ] = useContext(InspectionIndexContext);
  const [evaluation, setevaluation] = useState(null);
  const [{}, { searchUserOrCompany, getSrcDocumentWithBearer }] = useContext(
    SecurityContext
  );
  const [{}, { alertSuccess }] = useContext(AlertContext);
  const [columnsTable, setcolumnsTable] = useState([]);
  const [assignablesFind, setassignablesFind] = useState([]);
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    rows: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
        "&:hover": {
          backgroundColor: "#df8c37",
          color: "white",
        },
      },
    },
  };
  useEffect(() => {
    setcolumnsTable([
      {
        name: "EMAIL",
        center: true,
        selector: "email",
        sortable: true,
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setshareSelect(row);
            }}
          >
            {row.email}
          </div>
        ),
      },
      {
        name: "NOMBRE",
        center: true,
        selector: "first_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setshareSelect(row);
            }}
          >
            {row.first_name}
            {row.name}
          </div>
        ),
      },
    ]);
  }, [assignablesFind]);
  useEffect(() => {
    async function searchUsersShare(text) {
      let users = await searchUserOrCompany(text, false);
      setassignablesFind(users);
    }
    if (textSearchUserShare.length >= NUMBER_CARACTER) {
      searchUsersShare(textSearchUserShare);
    } else {
      setassignablesFind([]);
    }
  }, [textSearchUserShare]);
  useEffect(() => {
    if (modalshare.evaluation_id) {
      let filter = inspection.elements.filter(
        (e) => e.id == modalshare.evaluation_id
      )[0];
      setevaluation(filter);
    }
  }, [modalshare, inspection]);
  return (
    <form>
      <div
        style={{ marginTop: "80px" }}
        className="modal-create-standard shadow-tarjet"
      >
        <div className="w-100 header-modal-create-evaluation">
          <div className="w-100 flex">
            <div
              onClick={() => {
                setmodalshare({
                  ...modalshare,
                  evaluation_id: null,
                  open: false,
                });
              }}
              className="background-simple icon-close-modal-create-folder cursor-action"
            ></div>
            <p className="txt-center txt-title-new-folder tipo-description">
              Compartir
            </p>
          </div>
        </div>
        <div className="bg-white content-modal-create-standard">
          <div className="">
            <div className="flex flex-column">
              <div className="flex">
                <div className="flex flex-column flex-1">
                  <div className="flex margin-control flex">
                    <img
                      style={{ width: "38px", height: "38px" }}
                      src={IconShare}
                      alt="Compartir"
                    />{" "}
                    &nbsp;
                    <h3 className="tipo-title">Compartir con personas</h3>
                  </div>
                  <div className="flex flex-column margin-control">
                    {evaluation &&
                      evaluation.owners.map((owner) => {
                        return (
                          <div key={owner.id} className="flex">
                            <img
                              style={{
                                width: "38px",
                                height: "38px",
                                borderRadius: "100%",
                              }}
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (owner.owner.image && owner.owner.image.name)
                              }
                              onError={(e) => {
                                e.target.src = userDefault;
                              }}
                              alt=""
                            />
                            &nbsp;
                            <div className="flex flex-column">
                              <div className="tipo-description">
                                {" "}
                                {owner.owner.first_name}{" "}
                                {owner.owner.lastt_name}
                              </div>
                              <div className="tipo-cursiva">
                                {" "}
                                {owner.owner.email}{" "}
                              </div>
                            </div>
                            <span
                              className="cursor-action"
                              onClick={() => deleteEvaluationOwner(owner)}
                            >
                              x
                            </span>
                            <hr />
                          </div>
                        );
                      })}
                  </div>
                  <div className="">
                    <div className="form-group margin-control  flex-column flex">
                      <div className="form-group-zeus">
                        <input
                          type="text"
                          className="form-control inp-email-participant"
                          onChange={(e) =>
                            settextSearchUserShare(e.target.value)
                          }
                          value={textSearchUserShare}
                          type="text"
                          name="text"
                          autoComplete="off"
                          required
                        />
                        <label className="label-name-zeus">
                          <span className="content-name-zeus">
                            Correo Electrónico
                          </span>
                        </label>
                      </div>
                      <label className="tipo-cursiva" htmlFor="">
                        Búsqueda de usuarios apartir de {NUMBER_CARACTER}{" "}
                        caracteres
                      </label>

                      <DataTable
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10]}
                        noDataComponent={""}
                        striped={true}
                        columns={columnsTable}
                        responsive={true}
                        className="table-assing-package tipo-description"
                        data={assignablesFind}
                        pagination={true}
                        customStyles={customStyles}
                        paginationComponentOptions={{
                          rowsPerPageText: "Lineas:",
                          rangeSeparatorText: "de",
                          noRowsPerPage: false,
                          selectAllRowsItem: false,
                          selectAllRowsItemText: "Todas",
                        }}
                      />
                    </div>
                    <hr />
                    <div className="flex margin-control flex flex-column">
                      <span
                        onClick={() =>
                          getUidEvaluation(modalshare.evaluation_id)
                        }
                        className="flex cursor-action"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faLink} />
                        &nbsp;
                        <h3 className="tipo-title">Obtener enlace</h3>
                      </span>
                      <div className="flex flex-column">
                        <input value={evaluation &&process.env.REACT_APP_GUI_HOST +
                            "app/inspection/evaluation/generate/" +
                             evaluation.long_id}  className="tipo-description" />
                          
                        <label className="tipo-description">
                          Solo las personas añadidas pueden ingresar con este enlace
                        </label>
                        {evaluation && evaluation.long_id && (
                          <CopyToClipboard
                            onCopy={() => alertSuccess("Copiado")}
                            style={{ width: "82px" }}
                            className="btn-orange-default"
                            text={
                              process.env.REACT_APP_GUI_HOST +
                              "app/inspection/evaluation/generate/" +
                              evaluation.long_id
                            }
                          >
                            <span>Copiar</span>
                          </CopyToClipboard>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end w-100"></div>
      </div>
    </form>
  );
};

export default ShareInspection;
