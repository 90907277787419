import axios from "axios";

export let createClient = () => {
  let token = "";
  if (localStorage) {
    token = localStorage.getItem("token") || "";
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Authorization: token
    }
  });
};

export default createClient;
