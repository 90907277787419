import React from "react";

import Score from "../folder/Score";
import moment from "moment";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_PNG = "png";
const EXT_JPEG = "jpeg";
const EXT_DOCX = "docx";
const EXT_DOC= "doc";

const EXT_XLSX = "xlsx";
const EXT_XLS = "xls";

const Show = ({ docs }) => {
  const shortText = (description, range) => {
    if (description && description.length > range) {
      return description.substr(0, range) + "...";
    }
    return description;
  };
  const formatDate = dateTime => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    var date = new Date(dateTime);
    var year = date.getFullYear();
    var day = date.getDate();
    var shorMonth = monthNames[date.getMonth()].substr(0, 3) + ".";

    return shorMonth + " " + day + " / " + year;
  };
  const defineExtension = type => {
    switch (type) {
      case EXT_MP4:
        return "ext-mp4-2";
        break;
      case EXT_JPG:
      case EXT_PNG:
      case EXT_JPEG:
        return "ext-img-2";
        break;
      case EXT_XLSX:
      case EXT_DOCX:
        case EXT_XLS:
      case EXT_DOC:
        return "ext-download-2";
        break;
      default:
        return "ext-docs-2";
        break;
    }
  };
  return (
    <>
      <div
        className={`
          icon-img-extension ${defineExtension(docs.type)}`}
      ></div>

      <div className="w-100 h-100 bg-white  content-tarjet-form">

        
        <div className="container-header-document-tarjet">
          <p className="status-document-tarjet flex-1 margin-letf-tarjet-document">
            {moment().diff(docs.created_at, "days") < 6 && (
              <strong> Nuevo</strong>
            )}
          </p>
          {docs.my_favorite != null && (
            <div className="icon-favorite-document"></div>
          )}
          {!docs.is_free && <div className="icon-premium-document"></div>}
        </div>

        <div className="title-document-tarjet margin-letf-tarjet-document">
          <h6 className="title-tarjet-library-document">{shortText(docs.title, 50)}</h6>
        </div>
        <p className="tipo-cursiva date-document-tarjet margin-letf-tarjet-document">
          {formatDate(docs.created_at)}
        </p>
        <div className="description-document-tarjet margin-letf-tarjet-document">
          <p className="tipo-description">{shortText(docs.description, 110)}</p>
        </div>
        {<Score score={docs.valorable && docs.valorable} />}
      </div>
    </>
  );
};

export default Show;
