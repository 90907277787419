import React, { useContext, useState, useEffect } from "react";
import Select, { components } from "react-select";
import { TemplateBillContext } from "../../../../contexts/billing/TemplateBillContext";
import {
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../../contexts/security/SecurityContext";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Score from "../../../library/topic/folder/Score"
import {
  faClock,
  faCaretDown,
  faShoppingCart,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
const initialState = {
  columns: { status: STATUS_NOT_LOADED, columns: [] },
};
const Index = () => {
  const [stateTemplate, setstateTemplate] = useState([]);
  const [standardSelect, setstandardSelect] = useState({});
  const [specialitySelect, setspecialitySelect] = useState([]);
  const [columns, setcolumns] = useState(initialState.columns);

  const [
    { standard, speciality, template },
    { assingUserToTemplate, addToCart },
  ] = useContext(TemplateBillContext);
  useEffect(() => {
    if (template.status === STATUS_LOADED) {
      setstateTemplate(template.elements);
      let columns = [
        {
          center: true,
          name: "ID",
          width: "60px",
          selector: "id",
          cell: (row) => (
            <div
              className="cursor-action"
              onClick={() => {
                //history.push(`/app/audit/${row.id}`);
              }}
            >
              {row.id}
            </div>
          ),
          sortable: true,
        },
        {
          name: "NOMBRE",
          grow: 3,
          center: true,
          selector: "title",
          sortable: true,
        },
        {
          name: "DESCRIPCION",
          center: true,
          selector: "description",
          sortable: true,
        },
        {
          name: "PUNTUACION %",
          center: true,
          cell: (row) => (
            <>{<Score score={row.valorable && row.valorable} />}</>
          ),
          sortable: false,
        },
        {
          name: "PRECIO",
          center: true,
          selector: "cost",
          sortable: true,
        },

        {
          name: "ACCIONES",
          center: true,
          cell: (row) => (
            <>
              {parseInt(row.cost) == 0 ? (
                <FontAwesomeIcon
                  onClick={() => assingUserToTemplate(row.id)}
                  className="cursor-action"
                  icon={faGift}
                  title="Obtener gratis"
                />
              ) : (
                <FontAwesomeIcon
                  onClick={() => addToCart(row)}
                  className="cursor-action"
                  title="Agregar al carrito"
                  icon={faShoppingCart}
                />
              )}
            </>
          ),

          sortable: false,
        },
      ];
      setcolumns({
        status: STATUS_LOADED,
        columns,
      });
    }
  }, [template]);
  useEffect(() => {
    if (template.status === STATUS_LOADED && standardSelect.id) {
      let temFilter = template.elements.filter(
        (t) => t.standard_id === standardSelect.id
      );
      setstateTemplate(temFilter);
    }
  }, [standardSelect, specialitySelect]);
  return (
    <div className="container">
      <div className="flex">
        <div className="flex-1 margin-control">
          <Select
            value={specialitySelect}
            className="tipo-description"
            onChange={(e) => setspecialitySelect(e)}
            isClearable={true}
            isMulti={true}
            options={speciality.elements && speciality.elements}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option._id}`}
            placeholder="Todas las especialidades"
          />
        </div>
        <div className="flex-1 margin-control">
          <Select
            onChange={(e) => setstandardSelect(e)}
            className="tipo-description"
            isClearable={true}
            options={standard.elements && standard.elements}
            getOptionLabel={(option) => `${option.short_name}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder="Seleccione una norma"
          />
        </div>
      </div>

      {columns.status === STATUS_LOADED && (
        <DataTable
          sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          noDataComponent={"no hay datos"}
          striped={true}
          columns={columns.columns}
          responsive={true}
          className=" tipo-description"
          data={stateTemplate}
          pagination={true}
          paginationComponentOptions={{
            rowsPerPageText: "Lineas:",
            rangeSeparatorText: "de",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Todas",
          }}
        />
      )}
    </div>
  );
};

export default Index;
