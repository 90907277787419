import React, { useContext, useState, useEffect } from "react";
import { ActionPlanFormContext } from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import { Field, Form } from "react-final-form";
import "./Corrections.css";
import ImageUploader from "react-images-upload";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";

import Document from "./Document";
import moment from "moment";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const Corrections = () => {
  const [
    { analyze, newCorrection, modalCorrection, filesCorrectionsModal },
    {
      addNewCorrection,
      deleteCorrection,
      setFieldCorrections,
      setnewCorrection,
      setmodalCorrection,
      addNoteCorrection,
      setfilesCorrectionsModal,
      deleteEvidenceCorrection,
      canViewCorrection,
      canDeleteCorrection,
      canDeleteEvidence,
    },
  ] = useContext(ActionPlanFormContext);
  const [{ user }] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  useEffect(() => {
    setnewCorrection({ ...newCorrection, analyze_id: analyze.analyze_id });
  }, [analyze.analyze_id]);
  const checkEditCorrection = (
    field,
    value,
    correction_id,
    index,
    is_update
  ) => {
    let find = analyze.element.corrections.filter(
      (c) => c.id === correction_id
    )[0];
    if (find.end_date !== null) {
      return false;
    }
    if (field === "end_date") {
      if (
        find.description === "" ||
        find.description === null ||
        find.responsable === null
      ) {
        alertWarning("Los campos no pueden estar vacios");
        return false;
      }
    }
    setFieldCorrections(field, value, correction_id, index, is_update);
  };
  return (
    <div>
      {modalCorrection != null && (
        <div className="back-document">
          <div className="general-modal-correction">
            <div className="header-modal-correction">
              <div
                onClick={() => {
                  setmodalCorrection(null);
                  setfilesCorrectionsModal([]);
                }}
                s
                className="salir-audit"
              ></div>
              <h3 className="title-form-topic tipo-description">evidencia</h3>
            </div>
            <div className="content-corretion-analyze flex flex-column">
              <div className="tipo-description">
                {
                  analyze.element.corrections.filter(
                    (e) => e.id == modalCorrection
                  )[0].description
                }
              </div>
              <Form
                onSubmit={(formObj) => {
                  addNoteCorrection(formObj);
                }}
              >
                {({ handleSubmit, hasValidationErrors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group div-form-activity">
                      <Field name="note">
                        {({ input, meta }) => (
                          <>
                            <label className="tipo-title">Nota</label>
                            <textarea
                              {...input}
                              className="form-control tipo-description"
                              placeholder="Nota"
                            />
                          </>
                        )}
                      </Field>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText="Seleccione imagenes"
                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        withIcon={false}
                        onChange={(e) => setfilesCorrectionsModal(e)}
                        fileSizeError="Excede el peso permitido"
                        fileTypeError="Extensión no permitida"
                        label="Peso máximo 5mb,acepta:jpg,gif,png"
                      />
                      <div className="flex">
                        <div>
                          <input
                            type="submit"
                            className=" btn-orange-default"
                            value="Guardar"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Form>

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">fecha de creacion</th>
                    <th scope="col">responsable</th>
                    <th scope="col">nota</th>
                    <th scope="col">evidencias</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {analyze.element.corrections
                    .filter((e) => e.id == modalCorrection)[0]
                    .notes.map((note) => {
                      return (
                        <tr key={note.id}>
                          <th scope="row">{note.created_at}</th>
                          <td>{note.person.first_name}</td>
                          <td>{note.note}</td>
                          <td>
                            <div className="flex">
                              {note.documents.map((document) => (
                                <Document document={document} />
                              ))}
                            </div>
                          </td>
                          <td>
                            {canDeleteEvidence() && (
                              <img
                                title="eliminar"
                                className="cursor-action"
                                width="23"
                                onClick={() =>
                                  deleteEvidenceCorrection(note.id)
                                }
                                src={deleteIcon}
                                alt="eliminar"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {analyze.element.responsable_id === user.id && (
        <div className="flex justify-end">
          <div
            onClick={() =>
              addNewCorrection({
                analyze_id: analyze.analyze_id,
                date: moment().format("YYYY-MM-DD hh:mm:ss"),
              })
            }
            className="btn-orange-default-short"
          >
            Crear correccion
          </div>
        </div>
      )}

      <br />
      <div className="container-tables-analyze container-scroll-h">
        <table className="table table-correction-analyze">
          <thead>
            <tr>
              <th scope="col">Que hacer?</th>
              <th scope="col">Responsable</th>
              <th scope="col">Cuando?</th>
              <th scope="col">Fecha de finalizacion</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {analyze.element.corrections.map((corr, index) => {
              return (
                < >
                  {canViewCorrection(corr) && (
                    <tr key={index}>
                      <td>
                        {" "}
                        <div className="form-group-zeus">
                          <input
                            style={{ minWidth: "200px" }}
                            onBlur={(e) =>
                              checkEditCorrection(
                                "description",
                                e.target.value,
                                corr.id,
                                index,
                                true
                              )
                            }
                            onChange={(e) =>
                              checkEditCorrection(
                                "description",
                                e.target.value,
                                corr.id,
                                index,
                                false
                              )
                            }
                            type="text"
                            name="text"
                            autoComplete="off"
                            value={corr.description}
                            required
                          />
                          <label className="label-name-zeus">
                            <span className="content-name-zeus"></span>
                          </label>
                        </div>
                      </td>
                      <td width="100px">
                        {" "}
                        <Select
                          onChange={(e) =>
                            checkEditCorrection(
                              "responsable",
                              e ? e : null,
                              corr.id,
                              index,
                              true
                            )
                          }
                          value={corr.responsable}
                          className="tipo-description select-zeus-analyze select-responsable-analyze"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => (
                            <div className="flex">
                              <img
                                className={` btn-auditor-activity-filter  cursor-action background-simple `}
                                src={
                                  process.env.REACT_APP_API_HOST +
                                  "/documents/" +
                                  (option.image && option.image.name)
                                }
                                onError={(e) => {
                                  e.target.src = imgAduitorCheck;
                                }}
                              ></img>
                              &nbsp; {option.first_name} {option.last_name}
                            </div>
                          )}
                          getOptionValue={(option) => option.id}
                          options={
                            analyze.element.company &&
                            analyze.element.company.partners
                          }
                          isClearable={true}
                          isMulti={false}
                        />
                      </td>
                      <td style={{ minWidth: "120px" }} width="130px">
                        {" "}
                        <DatePicker
                          onChange={(e) =>
                            checkEditCorrection(
                              "date",
                              new Date(e),
                              corr.id,
                              index,
                              true
                            )
                          }
                          selected={new Date(corr.date)}
                          /*    showTimeSelect */
                          className="form-control tipo-description border-bottom-orange-zeus"
                          /*   timeFormat="HH:mm" */
                          locale="es"
                          /*  timeIntervals={30} */
                          dateFormat="y-MM-dd"
                        />
                      </td>
                      <td width="100px">
                        {!corr.end_date ? (
                          <div
                            onClick={() =>
                              checkEditCorrection(
                                "end_date",
                                moment(new Date()).format("YYYY-MM-DD"),
                                corr.id,
                                index,
                                true
                              )
                            }
                            className="btn-close-action-plan tipo-title white"
                          >
                            Finalizar
                          </div>
                        ) : (
                          <p className="tipo-description">
                            {moment(corr.end_date).format("YYYY-MM-DD")}
                          </p>
                        )}
                      </td>
                      <td width="100px">
                        <div className="flex">
                          <div
                            title="evidencias"
                            className="cursor-action evidence-correction background-simple"
                            width="23"
                            onClick={() => setmodalCorrection(corr.id)}
                          >
                            {" "}
                            <div className="orange container-number-documents-correction tipo-cursiva">
                              {corr.notes.length}
                            </div>
                          </div>
                          &nbsp;
                          {canDeleteCorrection(corr) && (
                            <img
                              title="eliminar"
                              className="cursor-action"
                              width="23"
                              onClick={() => deleteCorrection(corr.id)}
                              src={deleteIcon}
                              alt="eliminar"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Corrections;
