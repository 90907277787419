import React, { useState, useEffect, useContext } from "react";
import Select, { components } from "react-select";
import {
  ProgramFormContext,
  STATUS_LOADED,
  AuditPlanFormContext,
} from "../../../contexts/audit/AuditPlanFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import "../audit/form.css";
import moment from "moment";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";

import { Field, Form } from "react-final-form";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    audit_id: "",
    person_id: "",
    participant_type_id: "",
    company_process_id: "",
  },
  searchUser: {
    email: "",
    name: "",
  },
};

const CreateParticipantAudited = ({
  participants,
  state,
  setState,
  participant,
  procces,
  staff,
}) => {
  const [
    { userAuditor, participantType },
    { addAuditor, updateAuditor },
  ] = useContext(AuditPlanFormContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);
  const [person, setperson] = useState(initialState.searchUser);

  useEffect(() => {
    if (participant && participantType.status === STATUS_LOADED) {
      let el = participantType.elements.filter(
        (e) => e.id === participant.participant_type_id
      )[0];
      setStateCreate({
        ...stateCreate,
        item: el,
        originalItem: el,
        status: STATUS_EDIT,
      });
    }
  }, [participant, participantType]);
  useEffect(() => {
    if (participantType.status === STATUS_LOADED) {
      let el = participantType.elements.filter((e) => !e.is_audit)[0];
      setStateCreate({
        ...stateCreate,
        item: { ...stateCreate.item, participant_type_id: el },
      });
    }
  }, [participantType]);
  const setFieldPerson = (field) => (e) => {
    setperson({ ...person, [field]: e.target.value });
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  const addParticipantAuditor = (
    participant_type_id,
    person_id,
    company_process_id
  ) => {
    addAuditor(participant_type_id, person_id, company_process_id);
    setState({ ...state, toggle_modal2: false });
  };

  const updateParticipantAuditor = (
    participant_type_id,
    company_process_id
  ) => {
    updateAuditor(participant.id, participant_type_id, company_process_id);
    setState({ ...state, toggle_modal2: false, participant: null });
  };

  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      updateParticipantAuditor(
        formObj.participant_type_id.id,
        formObj.company_process_id
      );
    } else {
      addParticipantAuditor(
        formObj.participant_type_id.id,
        formObj.person_id.id,
        formObj.company_process_id
      );
    }
  };
  const existParticipant = (person) => {
    let el = participants.filter(
      (e) => e.person_id == person.id && e.participant_type.is_audit == false
    );
    if (el.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Form
      onSubmit={(formObj) => {
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form-participants-audited">
          {stateCreate.status == STATUS_NEW && (
            <div className="content-participant-audited">
              <div className="form-group div-form-participant">
                <Field name="person_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Participante</label>
                      {console.log(staff)}
                      <Select
                        {...input}
                        className="select-auditor"
                        placeholder={"Seleccione una opción"}
                        isOptionDisabled={(option) => existParticipant(option)}
                        getOptionLabel={(option) => (
                          <div className="flex">
                            <img
                              className={` btn-auditor-activity-filter  cursor-action background-simple `}
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (option.image &&
                                  option.image.name)
                              }
                              onError={(e) => {
                                e.target.src = imgAduitorCheck;
                              }}
                            ></img>
                            &nbsp; {option.first_name}{" "}
                            {option.email}
                          </div>
                        )}
                        getOptionValue={(option) => option.id}
                        options={staff}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              {/*    <div className="form-group div-form-participant">
                <Field name="participant_type_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Tipo de participante</label>
                      <Select
                        {...input}
                        className="select-auditor"
                        placeholder={"Seleccione una opción"}
                        isOptionDisabled={(option) => option.is_audit == true}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={participantType.elements}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div> */}
              <div className="form-group div-form-participant">
                <Field name="company_process_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Proceso</label>
                      <Select
                        {...input}
                        className="select-auditor"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={procces}
                        isMulti
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="form-control btn bg-orange white tipo-boton"
                  value="Añadir"
                />
              </div>
            </div>
          )}

          {stateCreate.status === STATUS_EDIT && (
            <div className="content-edit-participant margin-control">
              <div className="form-group div-form-participant">
                <label className="tipo-title">Participante</label>
                <div>
                  {participant.person.first_name +
                    " " +
                    participant.person.last_name}
                </div>
              </div>
              <div className="form-group div-form-participant">
                <Field name="participant_type_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Tipo de participante</label>
                      <Select
                        {...input}
                        className="select-auditor"
                        placeholder={"Seleccione una opción"}
                        isOptionDisabled={(option) => option.is_audit == true}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={participantType.elements}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group div-form-participant">
                <Field name="company_process_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Proceso</label>
                      <Select
                        {...input}
                        className="select-auditor"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={procces}
                        isMulti
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="form-control btn bg-orange white tipo-boton"
                  value="Guardar"
                />
              </div>
            </div>
          )}
        </form>
      )}
    </Form>
  );
};

export default CreateParticipantAudited;
