import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { AlertContext } from "../../contexts/alerts/AlertContext";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_ERASER = 5;
export const STATUS_APPROVED = 7;
let initalState = {
  status: STATUS_NOT_LOADED,
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  itemsTypeStandard: [],
  auditableItem: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modal: {
    standard: {
      isOpen: false,
      standard_id: null,
    },
  },
  errors: {
    error: [],
    status: false,
  },
  formErrors: {},
};

export const AuditContext = React.createContext([]);

export const AuditContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{ user }, { logout, isCollaboratorStandard }] = useContext(SecurityContext);
  const [{}, { alertSuccess, showErrors, alertError, alertWarning }] = useContext(AlertContext);
  let [standard, setStandard] = React.useState(initalState.standard);
  let [auditableItem, setAuditableItem] = React.useState(initalState.auditableItem);
  const [modalStandard, setModalStandard] = useState(initalState.modal.standard);
  const [userSearch, setuserSearch] = useState({});
  const [itemsTypeStandard, setitemsTypeStandard] = useState(initalState.itemsTypeStandard);
  let [errors, setErrors] = useState(initalState.errors);
  const [toogleItemType, settoogleItemType] = useState(false);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  // componteDidMointed & componentDidUpdated

  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      setStandard({ ...standard, status: STATUS_LOADING });
      client()
        .get("audit/standard", {
          params: {
            with: ["auditable_items.item_type", "collaborators", "item_types.options"],
          },
        })
        .then(({ data }) => {
          let standardFilter = data.filter(
            (s) => isCollaboratorStandard(s.id) || s.status_id === STATUS_APPROVED
          );
          if (data) {
            setStandard({
              status: STATUS_LOADED,
              elements: standardFilter,
            });
          }
        });
    }
  }, [standard]);

  /////////////////////   <AUDITABLE ITEM> //////////
  useEffect(() => {
    if (auditableItem.status === STATUS_NOT_LOADED) {
      setAuditableItem({ ...auditableItem, status: STATUS_LOADING });
      client()
        .get("audit/auditable_item", { params: { filters: { auditable_item_id: null } } })
        .then(({ data }) => {
          if (data) {
            setAuditableItem({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [auditableItem]);

  ///////////////////// <AUDITABLE ITEM/>  //////////

  const createStandard = ({ short_name, long_name, description, max_range, min_range }) => {
    let itemsType = toogleItemType ? itemsTypeStandard : [];

    client()
      .post("audit/standard", {
        short_name,
        long_name,
        description,
        itemsType,
        max_range,
        min_range,
      })
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        alertSuccess("Norma creada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear la norma");
        }
      });
  };

  const deleteStandard = (id) => {
    client()
      .delete(`audit/standard/${id}`)
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        alertSuccess("Norma eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar la norma");
        }
      });
  };

  const deleteItemType = (id) => {
    client()
      .delete(`evaluation/item_type/${id}`)
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el tipo de item");
        }
      });
  };
  const updateFormStandard = (standard_id, form) => {
    client()
      .put(`audit/standard/${standard_id}`, form)
      .then(() => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la norma");
        }
      });
  };
  const updateStandard = ({ description, long_name, short_name, max_range, min_range }) => {
    let itemsType = toogleItemType ? itemsTypeStandard : [];
    client()
      .put(`audit/standard/${modalStandard.standard_id}`, {
        itemsType,
        short_name,
        long_name,
        description,
        max_range,
        min_range,
      })
      .then(() => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        alertSuccess("Norma editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la norma");
        }
      });
  };

  const inEditModalStandard = (standard_id) => {
    setModalStandard({ ...modalStandard, standard_id: standard_id, isOpen: !modalStandard.isOpen });
  };

  const getUser = (email) => {
    client()
      .get(`security/person`, {
        params: {
          filters: { email: email },
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setuserSearch(data[0]);
        } else {
          alertWarning("Correo no encontrado");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar el email");
        }
      });
  };

  return (
    <AuditContext.Provider
      value={[
        { userSearch, standard, auditableItem, modalStandard, itemsTypeStandard, toogleItemType },
        {
          createStandard,
          updateStandard,
          deleteStandard,
          inEditModalStandard,
          getUser,
          setStandard,
          updateFormStandard,
          setitemsTypeStandard,
          settoogleItemType,
          deleteItemType,
        },
      ]}
    >
      {children}
    </AuditContext.Provider>
  );
};
