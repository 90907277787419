import React from "react";
import "./CreateProgram.css";
import PlanAuditGeneral from "./general/PlanAudit";
import { Link, useLocation, useHistory } from "react-router-dom";
const PlanAudit = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">Planeación de Auditoría</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Gestione y administre la auditoría incluyendo generalidades,
              equipo de trabajo, actividades, evaluados, procesos, numerales a
              auditar y genere su plan de auditoria.<br></br>
              Requisito: La empresa debe tener definidos sus procesos,
              responsables y participantes.
            </p>
          </div>
        </div>
      </div>
    
 <PlanAuditGeneral/>


    </div>
  );
};

export default PlanAudit;
