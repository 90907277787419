import React from "react";
const CreateProgram = () => {
  return (
    <div className="flex flex-column">
 

      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Responsable del S.G.</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-orange white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Genere Auditoría y Asigne Líder
          </div>
        </div>

        <div className="flex bg-white border-orange-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Cargue la documentación para la auditoría y planifique las
            actividades
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Reciba y Apruebe Plan de Auditoría
          </div>
        </div>
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice seguimiento de avance en tiempo real.
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Reciba y Apruebe el Informe Final de Auditoría
          </div>
        </div>
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Obtenga lista de verificación, con todas las notas registradas por
            numeral
          </div>
        </div>
      </div>
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditor líder</p>
      </div>
      <div className="flex-column">
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Asigne su equipo Auditor
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice planeación detallada de auditoría y solicite aprobación del
            plan de auditoría
          </div>
        </div>
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Genere lista de chequeo por auditor.
          </div>
        </div>
        <div className="flex bg-orange white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Coordine y apoye ejecución de auditoría en tiempo real con el equipo
            auditor
          </div>
        </div>
        <div className="border-orange-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice seguimiento detallado del avance de la auditoría
          </div>
        </div>
        <div className="margin-help-bottom flex bg-orange white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Revise y ajuste el informe final. Envíe plan y solicite aprobación.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProgram;
