import React from "react";
const Loading = () => {
  return (
    <div className="flex container-loading">
      <div className="margin-auto">
        Cargando...
        <div className="background-simple gif-loading"></div>
      </div>
    </div>
  );
};

export default Loading;
