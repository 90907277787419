exports = module.exports = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../../img_zeus/icons_library/icons_filter/ICONO-FILTRO-ORDENAR-POR.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../../img_zeus/icons_library/icons_filter/ICONO-FILTRO-OPCIONES-VISUALIZACIÓN.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../../img_zeus/icons_library/icons_filter/ICONO-FILTRO-TIPO-DE-DOC.png"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../../../img_zeus/icons_library/icons_filter/ICONO-FILTRO-LICENCIA.png"));

// Module
exports.push([module.id, ".container-type-filter {\n  border-top: black solid 1px;\n  padding: 6px;\n}\n.icon-order-by {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n.icon-filter-type {\n  background-size: 100% 100%;\n  width: 17px;\n  height: 13px;\n  margin-top: 2px;\n  margin-right: 8px;\n}\n.icon-filter-type-document {\n  background-size: 100% 100%;\n  width: 13px;\n  height: 17px;\n  margin-top: 2px;\n  margin-right: 8px;\n}\n.icon-filter-view {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n.icon-filter-type-document {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.icon-filter-license {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n.title-filter-text {\n  font-size: 11px;\n  margin-bottom: 2px;\n}\n.icon-down-filter {\n  font-size: 11px;\n\n  margin-top: 3px;\n}\n.check-filter[type=\"checkbox\"] {\n  background-position: 0 -21px;\n  width: 16px;\n  height: 16px;\n  margin-right: 10px;\n  margin-top: 3px;\n  background-color: white;\n}\n.check-filter[type=\"radio\"] {\n  background-position: 0 -21px;\n  width: 16px;\n  height: 16px;\n  margin-right: 10px;\n  margin-top: 3px;\n  background-color: white;\n}\n.option-text-filter {\n  font-size: 13px;\n  color: gray;\n  margin-bottom: 2px;\n}\n.title-filter {\n  margin-bottom: 9px;\n}\n", ""]);

