import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const NOTIFICATION_CREATE_AUDIT = "create-audit";
const NOTIFICATION_MODULE_AUDIT = "audit";
const STATUS_APPROVED = 7;
let initialState = {
  item: {
    new_item: {
      title: "",
      number: "",
      item_type_id: null,
      standard_id: null,
      auditable_item_id: null,
    },
    item_edit: {
      id: null,
      title: "",
      number: "",
      required: false,
      auditable_item_id: null,
    },
    new_subItem: {
      id: null,
      title: "",
      number: "",
      required: false,
      auditable_item_id: null,
    }
  },
  standard: {
    status: STATUS_NOT_LOADED,
    standard_id: null,
    element: {},
  },
  errors: {
    error: [],
    status: false,
  },
};

export const StandardFormContext = React.createContext([]);

export const StandardFormContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();
  const [stateNewItem, setstateNewItem] = useState(initialState.item.new_item);
  const [stateNewSubItem, setstateNewSubItem] = useState(
    initialState.item.new_subItem
  );
  const [stateItemEdit, setstateItemEdit] = useState(
    initialState.item.item_edit
  );
  const [standard, setStandard] = useState(initialState.standard);
  const [userSearch, setuserSearch] = useState({});
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (standard.standard_id !== null && standard.status === STATUS_NOT_LOADED) {
      setStandard({ ...standard, status: STATUS_LOADING });
      client()
        .get(`audit/standard/${standard.standard_id}`, {
          params: {
            with: ["auditable_items.item_type", "collaborators", "item_types.options"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setStandard({
              ...standard,
              status: STATUS_LOADED,
              element: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la norma");
          }
        });
    }
  }, [standard]);

  const getUser = (email) => {
    client()
      .get(`security/person`, {
        params: {
          filters: { email: email },
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setuserSearch(data[0]);
        } else {
          alertWarning("Correo no encontrado");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar el email");
        }
      });
  };
  const addCollaborator = (person_id, standard_id) => {
    client()
      .post(`audit/standardAttachPerson`, {
        person_id: person_id,
        standard_id: standard_id,
      })
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        setuserSearch({});
        alertSuccess("Colaborador añadido satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el colaborador");
        }
      });
  };
  const removeCollaborator = (person_id, standard_id) => {
    client()
      .post(`audit/standardDetachPerson`, {
        person_id: person_id,
        standard_id: standard_id,
      })
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        alertSuccess("Colaborador removido satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al remover el colaborador");
        }
      });
  };
  const createAuditableItem = ({ standard_id, auditable_item_id, title, number, item_type_id }) => {
    client()
      .post("audit/auditable_item", { standard_id, auditable_item_id, title, number, item_type_id })
      .then(({ data }) => {
        setstateNewItem({
          ...stateNewItem,
          title: "",
          number: "",
        });
        newSubItem(null, "");
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el item");
        }
      });
  };
  const deleteAuditableItem = (id) => {
    client()
      .delete(`audit/auditable_item/${id}`)
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          setStandard({
            ...standard,
            status: STATUS_NOT_LOGGED_IN,
            formErrors: { errorStandard: "Norma Requerida" },
          });
        } else {
          setStandard({
            ...standard,
            status: STATUS_NOT_LOGGED_IN,
            formErrors: { errorStandard: "Internal error." },
          });
        }
      });
  };

  const updateAuditableItem = ({ id, title, number, item_type_id }) => {
    let ItemId = item_type_id ? item_type_id.id : null;
    client()
      .put(`audit/auditable_item/${id}`, { title, number, item_type_id: ItemId })
      .then(({ data }) => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        newEdit(null, "");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el item");
        }
      });
  };
  const setOrderItem = (items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      client()
        .put(`audit/auditable_item/${item.id}`, { order: item.order,number: item.number })
        .then(({ data }) => {
          setStandard({ ...standard, status: STATUS_NOT_LOADED });
        });
    }
  };
  let newSubItem = (item_id, title) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      id: item_id,
      title: title,
      required: false,
      number: title,
      auditable_item_id: item_id,
    });
  };
  const updateStandard = ({ long_name, description, short_name }) => {

    client()
      .put(`audit/standard/${standard.standard_id}`, {
        long_name,
        description,
        short_name,
      })
      .then(() => {
        setStandard({ ...standard, status: STATUS_NOT_LOADED });
        alertSuccess("Norma editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al editar la norma");
        }
      });
  };

  let newEdit = (item_id, title, number, item_type) => {
    setstateItemEdit({
      ...stateItemEdit,
      id: item_id,
      title: title,
      number: number,
      item_type_id: item_type,
    });
  };

  return (
    <StandardFormContext.Provider
      value={[
        { standard, userSearch, stateNewItem,stateNewSubItem,stateItemEdit },
        {
          addCollaborator,
          createAuditableItem,
          setStandard,
          deleteAuditableItem,
          updateAuditableItem,
          setOrderItem,
          removeCollaborator,
          getUser,
          setstateNewItem,
          updateStandard,
          setstateNewSubItem,
          setstateItemEdit,
          newSubItem,
          newEdit
        },
      ]}
    >
      {children}
    </StandardFormContext.Provider>
  );
};
