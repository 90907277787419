import React, { useContext, useState, useEffect } from "react";
import "../modals/CreateEvaluation.css";
import { Field, Form } from "react-final-form";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import {
  EvaluationContext,
  EvaluationFormContext
} from "../../../contexts/audit/evaluation/EvaluationFormContext";
const STATUS_LOADING = "LOADING";
const STATUS_NOT_LOADED = "NOT_LOADED";
const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
const STATUS_LOGGED_IN = "LOGGED_IN";
const STATUS_LOADED = "STATUS_LOADED";
const initialState = {
  newEvaluation: {
    title: "",
    description: "",
    templates: [],
    application_date: new Date()
  }
};
const CreateEvaluation = () => {
  const [{ audit, template, modalEvaluation }, { createEvaluation, editEvaluation, inEditModalEvaluation }] = useContext(EvaluationFormContext);
  const [newEvaluation, setNewEvaluation] = useState(initialState.newEvaluation);

  const required = value => (value ? undefined : "Requerido");
  useEffect(() => {
    if (
      audit.status === STATUS_LOADED &&
      modalEvaluation.evaluation_id !== null
    ) {

      //cuando se va editar una evalaución
      let filter = audit.element.evaluations.filter(
        e => e.id === modalEvaluation.evaluation_id
      )[0];

      setNewEvaluation({
        ...newEvaluation,
        title: filter.title,
        description: filter.description,
        application_date: new Date(filter.pivot.application_date)
      });
    }
  }, [audit, modalEvaluation]);
  return (
    <Form
      onSubmit={formObject => {
        if (modalEvaluation.evaluation_id !== null) {
          editEvaluation(formObject);

        } else {
          createEvaluation(formObject);

        }
        inEditModalEvaluation(null);
      }}
      initialValues={newEvaluation}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex">
          <div className=" modal-create-evaluation shadow-tarjet">
            <div className="w-100 header-modal-create-evaluation">
              <div className="w-100 flex">
                <div
                  onClick={() => {
                    inEditModalEvaluation(null);
                  }}
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="txt-center txt-title-new-folder">
                  {modalEvaluation.evaluation_id !== null
                    ? "Editar Evaluación"
                    : "Nueva Evaluación"}
                </p>
              </div>
            </div>
            <div className="bg-white content-modal-create-evaluation">
              <div className="flex">
                <div className="flex-1 margin-control ">
                  <Field name="title" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <label className="control-label">Título</label>
                        <input
                          type="text"
                          {...input}
                          placeholder="título de la evaluación"
                          className="form-control"
                        />
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="flex-1 margin-control ">
                  <Field name="description" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <label className="control-label">Descripción</label>
                        <textarea
                          {...input}
                          className="form-control"
                          placeholder="descripción de la evaluación"
                        />
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              </div>
              <div className="flex">
                {modalEvaluation.evaluation_id === null && (
                  <div className="flex-1 margin-control ">
                    <label value className="control-label">
                      Mis plantillas
                  </label>
                    <Field validate={required} name="evaluation">
                      {({ input, meta }) => (
                        <>
                          <Select
                            {...input}
                            noOptionsMessage={() => "No hay plantillas creadas"}
                            placeholder={"Seleccione una plantilla"}
                            getOptionLabel={option => `${option.title}`}
                            getOptionValue={option => option.id}
                            // defaultValue={{ label: "Россия", value: "RU" }}
                            options={template.elements}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                )}
                <div className="flex-1 margin-control ">
                  <label>Fecha de aplicación</label>
                  <Field name="application_date" validate={required}>
                    {({ input, meta }) => (
                      <>

                        <DateTimePicker
                          minDate={new Date()}
                          clockClassName="class2"
                          format="dd-MM-y h:mm:ss a"
                          {...input}
                        />
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
              </div>
              {modalEvaluation.evaluation_id === null && (
                <div className="flex">
                  <div className="cursor-action margin-control w-100 btn-buy-template white bg-orange txt-center">
                    Comprar plantillas
                </div>
                </div>
              )}
            </div>
            <div className="flex justify-end w-100">
              {modalEvaluation.evaluation_id === null && (
                <button className="no-border cursor-action tipo-boton btn-edit-evaluation bg-orange white" type="submit">Crear</button>
              )}
              {modalEvaluation.evaluation_id !== null && (

                <button className="no-border cursor-action tipo-boton btn-edit-evaluation bg-orange white" type="submit">Editar</button>

              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateEvaluation;
