import React, { useContext, useState } from "react";
import Select, { components } from "react-select";
import DateTimePicker from "react-datetime-picker";
import { Field, Form } from "react-final-form";
import Document from "./Document";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {
  ActionPlanFormContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";

import "./Details.css";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { STATUS_ERASER } from "../../../contexts/evaluation/InspectionFormContext";
registerLocale("es", es);
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  picker: {
    init_date: new Date(),
    end_date: new Date(),
  },
  stateCreate: {},
};
const Details = () => {
  const [
    { analyze },
    {
      uploadDocument,
      updateAnalyze,
      syncProcess,
      setNote,
      updateOrCreateNote,
      setanalyze,
      setErrors,
      sendEmailAnalyze,
    },
  ] = useContext(ActionPlanFormContext);
  const [{}, { logout }] = useContext(SecurityContext);
  const [{}, { alertError }] = useContext(AlertContext);
  const [picker, setpicker] = useState(initialState.picker);
  let history = useHistory();
  const updateAnalyzeLoad = (form, load = false) => {
    let clone = cloneDeep(analyze);
    for (let k = 0; k < Object.keys(form).length; k++) {
      const key = Object.keys(form)[k];
      switch (key) {
        case "responsable":
          clone.element.responsable_id = form[key] ? form[key].id : null;
          break;
      }

      clone.element[key] = form[key];
    }
    setanalyze(clone);
    if (form.responsable) {
      form.responsable_id = form.responsable.id;
    }
    updateAnalyze(form)
      .then(({ data }) => {
        if (load) {
          // setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar los analisis");
        }
      });
  };
  return (
    <div className="tab-detail-analyze">
      <div className="panel-detail-analyze">
        {analyze.element.item && (
          <div className="flex">
            <div className=" flex flex-1">
              <div className="flex-column">
                Notas Origen
                {analyze.element.item.notes.map((note) => {
                  return (
                    <div key={note.id}>
                      <span className="tipo-description">-{note.note}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-column">
              <div className="flex">
                {analyze.element.item.documents.map((doc) => {
                  return <Document key={doc.id} document={doc} />;
                })}
              </div>
            </div>
          </div>
        )}
        {analyze.element.evaluation && (
          <div className="flex">
            <div className="flex flex-1">
              <div className="flex-column">
                Notas Origen
                {analyze.element.evaluation.notes.map((note) => {
                  return (
                    <div key={note.id}>
                      <span className="tipo-description">-{note.note}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="flex">
                {analyze.element.evaluation.documents.map((doc) => {
                  return <Document key={doc.id} document={doc} />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="panel-detail-analyze">
        <div
          style={{ flexFlow: "wrap", justifyContent: "space-between" }}
          className="flex"
        >
          <div className="form-group flex flex-column">
            <label className="tipo-title">Responsable del plan</label>

            <Select
              value={analyze.element.responsable}
              onChange={(e) => updateAnalyzeLoad({ responsable: e })}
              className="tipo-description select-zeus-analyze select-responsable-analyze"
              placeholder={"Seleccione una opción"}
              getOptionLabel={(option) => (
                <div className="flex">
                  <img
                    className={` btn-auditor-activity-filter  cursor-action background-simple `}
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (option.image && option.image.name)
                    }
                    onError={(e) => {
                      e.target.src = imgAduitorCheck;
                    }}
                  ></img>
                  &nbsp;{" "}
                  <span className="color-black-gray">
                    {option.first_name} {option.last_name}
                  </span>
                </div>
              )}
              getOptionValue={(option) => option.id}
              options={
                analyze.element.company && analyze.element.company.partners
              }
              isClearable={true}
              isMulti={false}
            />
          </div>

          <div className="form-group flex flex-column">
            <label className="tipo-title">Proceso</label>

            <Select
              value={analyze.element.process_select}
              onChange={(e) => syncProcess(e ? e : [])}
              className="color-black-gray tipo-description select-zeus-analyze select-responsable-analyze"
              placeholder={"Seleccione una opción"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={
                analyze.element.company && analyze.element.company.process
              }
              isClearable={true}
              isMulti={true}
            />
          </div>
          <div className="form-group flex flex-column">
            <label className="tipo-title">Fecha Cumplimiento</label>
          
          <DatePicker
              className="form-control tipo-description border-bottom-orange-zeus react-datepicker-ignore-onclickoutside"
              selected={analyze.element.end_date?new Date(moment(analyze.element.end_date)):null}
              dateFormat="y-MM-dd"
              locale="es"
              onChange={(date) =>
                updateAnalyzeLoad({
                  end_date: moment(date).format("YYYY-MM-DD"),
                })
              }
            /> 
          </div>
          <div className="form-group flex flex-column">
            <label className="tipo-title">identifica riesgo</label>

            <Select
              value={{ status: analyze.element.is_risk }}
              style={{ width: "23px" }}
              onChange={(e) => updateAnalyzeLoad({ is_risk: e.status })}
              className="color-black-gray tipo-description color-black-gray select-zeus-analyze select-risk-analyze"
              placeholder={"Seleccione una opción"}
              getOptionLabel={(option) => `${option.status ? "SI" : "NO"}`}
              getOptionValue={(option) => option.status}
              options={[{ status: true }, { status: false }]}
              isClearable={false}
              isMulti={false}
            />
          </div>
        </div>
        <br />
        <label className="tipo-title">Notas</label>

        <textarea
          onBlur={(e) => updateOrCreateNote(e.target.value)}
          onChange={(e) => setNote(e.target.value)}
          value={
            analyze.element.notes.length > 0
              ? analyze.element.notes[0].note
              : ""
          }
          className="w-100 input-color-analyze"
        ></textarea>
        <div className="form-group flex">
          <label className="tipo-title">Adjuntos</label>
          &nbsp;
          <div className="btn-evidence-analyze">
            <label
              title="Cargar evidencia"
              className="label-input-file-evaluation-report-desktop"
              htmlFor="upload"
            ></label>
            <input
              onChange={(e) => uploadDocument(e)}
              type="file"
              name="file"
              id="file"
              title="Cargar evidencia"
              className="input-file-analyze-details cursor-action"
            />
          </div>
        </div>
        <div>
          <div className="flex">
            {analyze.element.documents &&
              analyze.element.documents.map((doc) => {
                return <Document key={doc.id} document={doc} />;
              })}
          </div>
        </div>
      </div>
      {analyze.element.status_id === STATUS_ERASER && (
        <div className="flex justify-end">
          <div
            onClick={() => sendEmailAnalyze(analyze.element)}
            className="btn-orange-default"
          >
            Aprobar
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
