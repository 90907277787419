import React, { useContext, useState } from "react";
import "../layout/Menu.css";
import Profile from "../layout/Profile";
import ListDropDown from "../layout/ListDropDown";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import img_logout from "../../../img_zeus/icons_menu/ICONO-SING-OUT.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

const Menu = () => {
  let [{}, { logout }] = useContext(SecurityContext);
  const [fullContent, setFullContent] = useState(false);
  let toggleWrapper = () => {
    setFullContent(!fullContent);
  };

  return (
    <>
      <div className="flex container-menu">
        {!fullContent && (
          <div className="menu-zeus">
            <Profile />
       
            <ListDropDown />
            <div className="logout-menu flex">
              <span
                className="logout-text cursor-action tipo-cursiva margin-auto"
                onClick={logout}
              >
                <img className="icon-logout" src={img_logout} />
                &nbsp;Cerrar Sesión
              </span>
            </div>
          </div>
        )}
        <div className="toggle-div cursor-action">
          <FontAwesomeIcon
            onClick={() => {
              toggleWrapper();
            }}
            className="menu-collapse-left"
            icon={fullContent ? faChevronRight : faChevronLeft}
          />
        </div>
      </div>
    </>
  );
};

export default Menu;
