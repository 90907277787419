import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  template: {
    status: STATUS_NOT_LOADED,
    element: {},
    score: null,
  },
  meta: {
    template_id: null,
  },
  itemsDeploy: {},
  errors: {
    error: [],
    status: false,
  },
};

export const TemplateFormContext = React.createContext([]);

export const TemplateFormContextProvider = ({ children }) => {
  let history = useHistory();

  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess, showErrors }] = useContext(AlertContext);
  const [template, setTemplate] = useState(initialState.template);
  const [meta, setMeta] = useState(initialState.meta);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [errors, setErrors] = useState(initialState.errors);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  // componteDidMointed & componentDidUpdated
  useEffect(() => {
    if (meta.template_id !== null && template.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/evaluation/${meta.template_id}`, {
          params: {
            with: ["items.auditable_item", "specialities", "valorable"],
            filters: {
              is_template: true,
              on_sale: false,
              is_self_audit: false,
              person_id: user.id,
            },
          },
        })
        .then(({ data }) => {
          if (data) {
            let valorable = null;
            if (data.valorable.length > 0) {
              let filter = data.valorable.filter((e) => e.person_id == user.id)[0];
              if (filter) {
                valorable = filter.value;
              }
            }
            setTemplate({
              ...template,
              status: STATUS_LOADED,
              element: data,
              score: valorable,
            });
          }
        });
    }
  }, [meta, template]);

  //functions
  const changeTemplate = (template_id) => {
    setMeta({ ...meta, template_id: template_id });
  };

  const deleteItem = (id) => {
    client()
      .delete(`evaluation/item/${id}`)
      .then(({ data }) => {
        setTemplate({
          ...template,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("Item eliminado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el item");
        }
      });
  };
  const createItem = ({ template_id, id, title }) => {
    client()
      .post("evaluation/item", {
        item_type_id: 1,
        auditable_item_id: null,
        evaluation_id: template_id,
        item_id: id,
        title,
      })
      .then(({ data }) => {
        setTemplate({
          ...template,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("Item añadido satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear el item");
        }
      });
  };
  const updateItem = ({ id, title }) => {
    client()
      .put(`evaluation/item/${id}`, { title })
      .then(({ data }) => {
        setTemplate({
          ...template,
          status: STATUS_NOT_LOADED,
        });
        alertSuccess("Item editado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el item");
        }
      });
  };
  let addScore = (score) => {
    let dataForm = {
      value: score,
      person_id: user.id,
    };
    client()
      .post(`audit/evaluation/${meta.template_id}/dataValorable`, dataForm)
      .then(({ data }) => {
        if (data) {
          setTemplate({
            ...template,
            status: STATUS_NOT_LOADED,
          });
        }
      });
  };
  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };
  return (
    <TemplateFormContext.Provider
      value={[
        { template, itemDeploy },
        { changeTemplate, deleteItem, createItem, updateItem, addScore, setItemDeploy, setDeploy },
      ]}
    >
      {children}
    </TemplateFormContext.Provider>
  );
};
