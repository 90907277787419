import React, { useState, useEffect, useContext } from "react";
import {
  ProgramFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/ProgramFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import "../program/Index.css";
import moment from "moment";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreateAudit.css";
import DatePicker from "react-datepicker";
import DateTimePicker from "react-datetime-picker";
import { Link } from "react-router-dom";
registerLocale("es", es);
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    title: "",
    standard_id: "",
    company_id: "",
    init_date: new Date(),
    end_date: new Date(),
    lider_person_id: "",
  },
  invitationLider: {
    email: "",
  },
  originalItem: {},
};

const CreateAudit = ({ state, setState, audit_id }) => {
  const [
    { program, standard, suscriptionsAudit, effectiveSelected },
    { createAudit, updateAudit, sendInvitationLider, seteffectiveSelected },
  ] = useContext(ProgramFormContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  const [invitation, setinvitation] = useState(initialState.invitationLider);
  const [picker, setpicker] = useState(initialState.picker);
  const [haveSuscriptions, sethaveSuscriptions] = useState(false);
  let [{ user }, {}] = useContext(SecurityContext);
  let { company } = program.element;
  let history = useHistory();
  useEffect(() => {
    if (audit_id && program.status === STATUS_LOADED) {
      let el = program.element.audits.filter((e) => e.id === audit_id)[0];
      setStateCreate({
        ...stateCreate,
        item: {
          title: el.title,
          standard_id: el.standard,
          company_id: el.company_id,
          init_date: new Date(el.init_date),
          end_date: new Date(el.end_date),
          lider_person_id: el.lider_person,
        },
        originalItem: el,
        status: STATUS_EDIT,
      });
    }
  }, [audit_id, program]);
  useEffect(() => {
    if (
      stateCreate.status !== STATUS_EDIT &&
      suscriptionsAudit.elements.filter(
        (e) => !e.is_personal && e.assignable_id === program.element.company_id
      ).length === 1
    ) {
      seteffectiveSelected(
        suscriptionsAudit.elements.filter(
          (e) =>
            !e.is_personal && e.assignable_id === program.element.company_id
        )[0].id
      );
    }
    if (
      suscriptionsAudit.elements.filter(
        (e) => !e.is_personal && e.assignable_id === program.element.company_id
      ).length > 0
    ) {
      sethaveSuscriptions(true);
    }
  }, [stateCreate]);
  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      updateAudit(
        formObj,
        program.element.company_id,
        stateCreate.originalItem.id
      );
    } else {
      createAudit(
        formObj,
        program.element.company_id,
        program.element.company.contact.id
      );
    }
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  const searchLider = (data) => {
    let newPartners = company.partners.filter(function (el) {
      return (
        el.email.toLowerCase().indexOf(data.target.value.toLowerCase()) > -1
      );
    });

    if (newPartners.length == 0) {
      if (validateEmail(data.target.value)) {
        setinvitation({ ...invitation, email: data.target.value });
      } else {
        setinvitation({ ...invitation, email: "" });
      }
    } else {
      setinvitation({ ...invitation, email: "" });
    }
  };
  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Form
      onSubmit={(formObj) => {
        if (formObj.init_date == "" || formObj.end_date == "") {
          alert("Fecha de inicio y fin son requeridos");
          return false;
        }
        if (formObj.init_date >= formObj.end_date) {
          alert("la fecha fin debe ser mayor a la fecha de inicio");
          return false;
        }
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="form-audit flex-column">
          <div className="flex">
            <div className=" flex-1">
              {suscriptionsAudit.elements.filter(
                (e) =>
                  !e.is_personal &&
                  e.assignable_id === program.element.company_id
              ).length == 0 && (
                <div
                  class="alert alert-warning form-group txtarea-form-audit"
                  role="alert"
                >
                  Sin suscripciones activas
                  <br />
                  <Link
                    className="link-default"
                    title="Comprar suscripción empresarial para auditorías"
                    to={`/app/library/topic/billing/suscription/audit/false`}
                  >
                    Adquirir suscripción empresarial
                  </Link>
                </div>
              )}

              <div className="form-group txtarea-form-audit">
                <Field name="title" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Nombre:</label>
                      <input
                        {...input}
                        disabled={
                          stateCreate.status !== STATUS_EDIT &&
                          !haveSuscriptions
                        }
                        type="text"
                        className="form-control tipo-description"
                        placeholder="Nombre de la auditoría"
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="standard_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Norma:</label>
                      {stateCreate.status === STATUS_NEW && (
                        <Select
                          {...input}
                          isDisabled={!haveSuscriptions}
                          className="tipo-description"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.short_name}`}
                          getOptionValue={(option) => option.id}
                          options={standard.elements}
                        />
                      )}
                      {stateCreate.status === STATUS_EDIT && (
                        <Select
                          {...input}
                          className="tipo-description"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.short_name}`}
                          getOptionValue={(option) => option.id}
                          options={standard.elements}
                          isDisabled
                        />
                      )}
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="lider_person_id">
                  {({ input, meta }) => (
                    <>
                      <div className="flex justify-betwen">
                        <label className="tipo-title">Lider:</label>
                        {invitation.email != "" && (
                          <div
                            onClick={() => sendInvitationLider(invitation)}
                            className="cursor-action white btn-add-lider-invitation"
                          >
                            enviar invitacion
                          </div>
                        )}

                        <div
                          onClick={() =>
                            history.push(`/app/companies/${company.id}`)
                          }
                          className="bg-black white btn-add-employee-on-audit"
                        >
                          Añadir integrante
                        </div>
                      </div>

                      <Select
                        {...input}
                        isDisabled={stateCreate.status !== STATUS_EDIT&&!haveSuscriptions}
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        onKeyDown={searchLider}
                        getOptionLabel={(option) =>
                          `${option.first_name} - ${option.email}`
                        }
                        getOptionValue={(option) => option.id}
                        options={company.partners}
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="init_date" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Fecha Inicio:</label>
                      <DateTimePicker
                        className="form-control tipo-description"
                        format="y-MM-dd"
                        disabled={stateCreate.status !== STATUS_EDIT&&!haveSuscriptions}
                        {...input}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="end_date" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Fecha Fin:</label>
                      <DateTimePicker
                        className="form-control tipo-description"
                        format="y-MM-dd"
                        disabled={stateCreate.status !== STATUS_EDIT&&!haveSuscriptions}
                        minDate={new Date(values.init_date)}
                        {...input}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
            </div>
            {stateCreate.status !== STATUS_EDIT &&
              suscriptionsAudit.elements.filter(
                (e) =>
                  !e.is_personal &&
                  e.assignable_id === program.element.company_id
              ).length > 0 && (
                <div className="flex-1">
                  <label className="tipo-title">Selecccione un paquete</label>
                  {suscriptionsAudit.elements
                    .filter(
                      (e) =>
                        !e.is_personal &&
                        e.assignable_id === program.element.company_id
                    )
                    .map((effective) => {
                      return (
                        <div
                          className={`flex cursor-action g-module-audit form-group txtarea-form-audit effective-modal-audit ${
                            effective.id == effectiveSelected
                              ? "effective-active"
                              : ""
                          }`}
                          key={effective.id}
                          onClick={() => seteffectiveSelected(effective.id)}
                        >
                          <div className="flex-1">
                            {" "}
                            <div className="flex">
                              <label className="tipo-title txt-capitalize">
                                {effective.service.name}
                              </label>
                              &nbsp;
                              <label htmlFor="">
                                {effective.quantity}/
                                {effective.consumers.length}
                              </label>
                            </div>
                            <div className="flex">
                              {effective.childs_effectives.map((child) => {
                                return (
                                  <>
                                    <label className="tipo-title txt-capitalize">
                                      {child.service.name}
                                    </label>
                                    &nbsp;
                                    <label htmlFor="">{child.quantity}</label>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="flex-1">
                            <label>Fch. Venc.</label>
                            &nbsp;
                            <label className="tipo-cursiva">
                              {moment(effective.end_date).format("YYYY-MM-DD")}
                            </label>{" "}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
          <div className="">
            <div className="btn-actions-form">
              {stateCreate.status !== STATUS_EDIT ? (
                <>
                  {suscriptionsAudit.elements.filter(
                    (e) =>
                      !e.is_personal &&
                      e.assignable_id === program.element.company_id
                  ).length > 0 && (
                    <input
                      type="submit"
                      value="Guardar"
                      className="btn-orange-default"
                    />
                  )}
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    value="Guardar"
                    className="btn-orange-default"
                  />
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateAudit;
