import React, { useEffect, useState, useContext } from "react";
import client from "../../axios/axios-client";

import { useHistory } from "react-router-dom";
import {
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  STATUS_LOADING,
  SecurityContext,
} from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";

const NOTIFICATION_CREATE_AUDIT = "create-audit";
const NOTIFICATION_MODULE_AUDIT = "audit";
const STATUS_APPROVED = 7;
let initialState = {
  analyze: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  statusAudit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  statusAnalyze: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  metaRow: {
    limit: 7,
    offset: 0,
    page: 1,
    filters: { audit_id: "not null" },
    filtersLike: { id: "", "company:name": "" },
    orderBy: ["id:desc"],
  },
  errors: {
    error: [],
    status: false,
  },
};

export const ActionPlanIndexContext = React.createContext([]);

export const ActionPlanIndexContextProvider = ({ children }) => {
  let [analyze, setanalyze] = useState(initialState.analyze);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  let [errors, setErrors] = useState(initialState.errors);
  const [rows, setrows] = useState(0);
  const [statusAudit, setstatusAudit] = useState(initialState.statusAudit);
  const [statusAnalyze, setstatusAnalyze] = useState(initialState.statusAnalyze);
  const [tabAudit, settabAudit] = useState(true);
  let history = useHistory();
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError }] = useContext(AlertContext);
  useEffect(() => {
    if (statusAudit.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/analyze/allStatus`, {})
        .then(({ data }) => {
          if (data) {
            setstatusAnalyze({ ...statusAnalyze, elements: data, status: STATUS_LOADED });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los estados del analisis");
          }
        });
    }
  }, []); 
  useEffect(() => {
    if (statusAudit.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/audit/allStatus`, {})
        .then(({ data }) => {
          if (data) {
            setstatusAudit({ ...statusAudit, elements: data, status: STATUS_LOADED });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los estados de la auditoria");
          }
        });
    }
  }, []);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountAnalyzesAudit();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);
      
      if ((metaRow.page > newPages)&&newPages!==0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`audit/indexAudit`, {
          params: {
            with: [
              "actions",
              "status",
              "responsable",
              "analyzable.auditable_item",
              "company",
              "audit.standard",
            ],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          if (data) {
            setanalyze({ ...analyze, elements: data, status: STATUS_LOADED });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los planes de accion");
          }
        });
    }
    fetchData();
  }, [metaRow]);

  const getCountAnalyzesAudit = async () => {
    return client()
      .get(`audit/countAnalyzeAudit`, {
        params: {
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  return (
    <ActionPlanIndexContext.Provider
      value={[
        { analyze, metaRow, tabAudit, rows, statusAnalyze, statusAudit },
        { setanalyze, setmetaRow, settabAudit, setrows },
      ]}
    >
      {children}
    </ActionPlanIndexContext.Provider>
  );
};
