import React, { useContext } from "react";
import { BillingContext } from "../../contexts/billing/BillingContext";
import { AlertContext } from "../../contexts/alerts/AlertContext";
import "../billing/ListBill.css";

const Show = ({ computedMatch }) => {
  const [{ bill }, {}] = useContext(BillingContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const { params, url, path } = computedMatch;
  return (
    <div>
      <h1>Hell</h1>
    </div>
  );
};

export default Show;
