import React, { useContext } from "react";
import { CourseIndexContext } from "../../../contexts/general/CourseIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import imageCourse from "../../../img_zeus/course/course1.jpeg";
import "./Index.css";
const Index = () => {
  const [{ courses }, { addCourseToCart, changeImage }] =
    useContext(CourseIndexContext);
  const [{ user }] = useContext(SecurityContext);
  return (
    <div className="container-courses flex  ">
      <div className="margin-auto flex ">
        {courses.elements.map((course) => {
          return (
            <div className="flex flex-column container-course-index">
              {user.is_admin && (
                <input
                  onChange={(e) => changeImage(e, course.id)}
                  type="file"
                />
              )}
              {course.documents.map((doc) => {
                return (
                  <img
                    className="img-course-index"
                    src={
                      process.env.REACT_APP_API_HOST + "documents/" + doc.name
                    }
                  />
                );
              })}

              <input
                onClick={() => addCourseToCart(course.id)}
                type="button"
                className="btn-orange-default"
                value="Pagar"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
