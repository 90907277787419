import React from "react";
const ExecutedAudit = () => {
  return (
    <div className="flex flex-column">
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">
          Auditor Líder, de apoyo o experto técnico.
        </p>
      </div>
      <div className="flex-column">
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Utilice la agenda de la auditoría para tener el detalle de cada
            actividad
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Ingrese a la actividad y ejecute su lista de verificación
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Califique cada ítem y capture evidencias en formato PDF, imágenes,
            videos etc. Tome notas directamente sobre el requisito
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Describa las observaciones y cargue evidencias
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice seguimiento, con estadísticas e informes de avances
            individuales o consolidados.
          </div>
        </div>
        <div className="margin-help-bottom border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice reuniones de seguimiento con información y evidencias en
            tiempo real.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutedAudit;
