import React, { useContext,useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { TemplateIndexContext } from "../../../contexts/audit/TemplateContext/TemplateIndexContext";
import CreateTemplate from "../modals/CreateTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconShow from "../../../../src/img_zeus/icons_audit/ICONO-VER.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import {
  STATUS_LOADED,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "./Index.css";
import moment from "moment";
import Loading from "./../../app/Loading";
const initialState = {
  inEdit: {
    template_id: null,
  },
};
const Index = () => {
  let history = useHistory();
  const [
    { template, modalTemplate },
    {
      cloneTemplate,
      deleteTemplate,
      inEditModalTemplate,
      filterGeneralTemplate,
    },
  ] = useContext(TemplateIndexContext);
  let [{ user }, {viewGeneral}] = useContext(SecurityContext);
  const [inEdit, setInEdit] = useState(initialState.inEdit);
  const [search, setSearch] = useState("");
  useEffect(() => {
    viewGeneral("template");
  }, []);

  const validateOriginTemplate = (template) => {
    if (template.cost === null) {
      return "Propio";
    }
    if (template.cost == 0) {
      return "Gratuito";
    }
    if (template.cost > 0) {
      return "Comprado";
    }
    return "Desconocido";
  };

  const changeSearch = (field) => (e) => {
    let body = e.target.value;
    setSearch(body);
    filterGeneralTemplate(body);
  };
  const checkCloneTemplate = (template_id) => {
    Swal.fire({
      title: "¿Realmente quieres crear una copia de esta plantilla?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, crear copia!",
    }).then((result) => {
      if (result.value) {
        cloneTemplate(template_id);
      } else {
      }
    });
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  return (
    <>
      {template.status != STATUS_LOADED && <Loading />}
      <div className={`${template.status != STATUS_LOADED ? "hidden" : ""}`}>
        {modalTemplate.isOpen && (
          <div className="back-document">
            <CreateTemplate />
          </div>
        )}

        <div className="flex">
          <div className="flex-1">
            <h2 className="title-evaluation tipo-title">PLANTILLAS</h2>
          </div>
          <div className="flex-1 flex justify-end">
            <div
              onClick={() => {
                inEditModalTemplate(null);
              }}
              className="cursor-action bg-orange img-create-template"
            ></div>
          </div>
        </div>
        <div className="flex w-100">
          <div className="flex-1">
            <input
              className="form-control tipo-description"
              onChange={changeSearch()}
              value={search}
              placeholder="Buscar"
              type="text"
            />
          </div>
          <div className="flex justify-end flex-1 ">
            <Link
              to={`/app/audit/paymentTemplate`}
              className="flex btn-orange-default"
            >
              {" "}
              Comprar plantillas
            </Link>
          </div>
        </div>
        <div className="general-content-index-evaluation back-gray-content">
          <table className="table">
            <thead>
              <tr className="header-table-index-evaluation txt-center">
                <th scope="col">ORIGEN</th>
                <th scope="col">TITULO</th>
                <th scope="col">DESCRIPCIÓN</th>
                <th scope="col">NORMA</th>
                <th scope="col">ESPECIALIDAD</th>

                <th scope="col">FECHA DE CREACIÓN</th>
                <th scope="col">ACCIONES</th>
              </tr>
            </thead>
            <tbody className="tipo-description table-template-index bg-white">
              {template.elements && template.elements.length == 0 && (
                <tr>
                  <td colSpan="6" className="txt-center">
                    No hay plantillas{" "}
                  </td>
                </tr>
              )}
              {template.elements &&
                template.elements.map((eva) => (
                  <tr key={eva.id} className="txt-center">
                    <td scope="row">{validateOriginTemplate(eva)}</td>
                    <td>{eva.title}</td>
                    <td>{eva.description}</td>
                    <td>
                      {eva.evaluation_standard &&
                        eva.evaluation_standard.short_name}
                    </td>
                    <td>
                      {eva.specialities.map((spe) => spe.speciality.name)}
                    </td>
                    <td>{moment(eva.created_at).format("YYYY-MM-DD")}</td>
                    <td>
                      <FontAwesomeIcon
                        className="cursor-action"
                        onClick={() => checkCloneTemplate(eva.id)}
                        title="clonar plantilla"
                        icon={faCopy}
                      />
                      &nbsp; &nbsp;
                      <Link to={`/app/audit/template/${eva.id}`}>
                        <img
                          src={iconShow}
                          className="icon-actions"
                          title="ver plantilla"
                        />
                      </Link>
                      &nbsp; &nbsp;
                      {eva.cost ===
                        null&&(
                          <>
                            <img
                              src={iconEdit}
                              className="icon-actions cursor-action"
                              onClick={() => {
                                inEditModalTemplate(eva.id);
                              }}
                              title="editar plantilla"
                            />
                            &nbsp; &nbsp;
                            <img
                              src={iconDelete}
                              className="cursor-action icon-actions"
                              onClick={() =>
                                checkDelete(
                                  "¿Realmente deseas eliminar esta plantilla?",
                                  `deleteTemplate(${eva.id})`
                                )
                              }
                              title="eliminar plantilla"
                            />
                          </>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Index;
