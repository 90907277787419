import React, { useContext, useEffect } from "react";
import { EffectiveFormContext } from "../../../contexts/billing/EffectiveFormContext";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import "./Form.css";
const Form = ({ computedMatch: { params, url } }) => {
  let history = useHistory();
  let { effective_id } = params;
  const [
    { effective, metaRow, rows },
    { seteffective, setmetaRow },
  ] = useContext(EffectiveFormContext);
  useEffect(() => {
    seteffective({ ...effective, effective_id: effective_id });
  }, [effective_id]);

  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  return (
    <div>
      <div className="container-index-analyzes-audit">
        <div className="flex content-title-programs">
          <div className="tipo-title title-audits">
            Consumos 
          </div>
        </div>
        <div className="table-audits">
          <div className="flex justify-betwen">
            <h3>Consumos de documentos</h3>
           
        
          </div>
          <div className="table-analyze-audits">
            {" "}
            <table className=" table table-light">
              <thead className="thead-dark">
                <tr className="txt-center">
                  <th title="ID auditoria" scope="col">
                    Titulo
                  </th>
                  <th title="Empresa" scope="col">
                    Extension
                  </th>
                  <th title="Peso" scope="col">
                    Peso
                  </th>
                </tr>
              </thead>
              <tbody className="tipo-description">
                <tr>
                  <th>
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                          onChange={(e) => setFieldFilter("consumableDocument:title", e)}
                        value={metaRow.filtersLike["consumable:title"]} 
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                         onChange={(e) => setFieldFilter("consumableDocument:type", e)}
                        value={metaRow.filtersLike["consumable:type"]} 
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                         onChange={(e) => setFieldFilter("consumableDocument:size_mb", e)}
                        value={metaRow.filtersLike["consumable:size_mb"]} 
                        type="text"
                      />
                    </div>
                  </th>
                </tr>
                {effective.consumers.map((consumer) => (
                  <tr className="txt-center" key={consumer.id}>
                    <th className="flex txt-left">
                      <div className={`background-simple btn-extension-effective-form-document ${consumer.consumable&&consumer.consumable.type=="pdf"?"ext-docs-2":"ext-img-2"}`}></div>
                      <span
                        className="cursor-action link-audit-index"
                        onClick={() => {
                          history.push(`/app/library/document/${consumer.consumable_id}`);
                        }}
                      >
                        {consumer.consumable && consumer.consumable.title}
                      </span>
                    </th>
                    <td>{consumer.consumable && consumer.consumable.type}</td>
                    <td>{consumer.consumable && parseFloat(consumer.consumable.size_mb).toFixed(2)} MB</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ margin: "10PX" }} className="justify-end flex">
            <Pagination
              activePage={metaRow.page}
              itemsCountPerPage={metaRow.limit}
              totalItemsCount={rows}
              pageRangeDisplayed={5}
              itemClass="page-item item-page-zeus"
              linkClass="page-link page-link-zeus"
              onChange={(e) => setFieldMeta(e, "page")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
