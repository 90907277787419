import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { AlertContext } from "../alerts/AlertContext";
import { SecurityContext } from "../security/SecurityContext";
import $ from "jquery";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const Approved = 7;
export const CART_STATUS = 12;
export const PACKAGE_STATUS = 13;
export const SERVICE_LIBRARY = 4;
export const SERVICE_CAPACITY = 3;
export const MODEL_COURSE = "App\\Models\\Course";

let initalState = {
  order: {
    status: STATUS_NOT_LOADED,
    element: {},
  },
  packageOnSale: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  metaRow: {
    limit: 7,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  meta: {
    status: STATUS_NOT_LOADED,
    path: {
      bill_id: null,
      bill_resolution_id: null,
      bill_detail_id: null,
    },
  },
  bills: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  bill: {},
  policies: {},
  conditions: {},
  services: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  topics: {
    status: STATUS_NOT_LOADED,
    elements: {},
  },
  audit: {
    status: STATUS_NOT_LOADED,
    config: {},
  },
  action_plan: {
    status: STATUS_NOT_LOADED,
    config: {},
  },
  consulting: {
    status: STATUS_NOT_LOADED,
    config: {},
  },
  errors: {
    error: [],
    status: false,
  },
};

export const BillingContext = React.createContext({});

export const BillingContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  const [{ user }, { logout, removeOrAddPackageCart }] = useContext(SecurityContext);
  let [meta, setMeta] = useState(initalState.meta);
  let [bills, setBills] = useState(initalState.bills);
  let [bill, setBill] = useState(initalState.bill);
  let [policies, setPolicies] = useState(initalState.policies);
  let [conditions, setConditions] = useState(initalState.conditions);
  let [services, setServices] = useState(initalState.services);
  let [topics, setTopics] = useState(initalState.topics);
  const [order, setorder] = useState(initalState.order);
  const [metaRow, setmetaRow] = useState(initalState.metaRow);
  const [rows, setrows] = useState(0);
  const [companyPackage, setcompanyPackage] = useState({ open: false, pack: {} });
  const [packageOnSale, setpackageOnSale] = useState(initalState.packageOnSale);

  let [errors, setErrors] = useState(initalState.errors);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (order.status === STATUS_NOT_LOADED) {
      setorder({ ...order, status: STATUS_LOADING });
      client()
        .get(`billing/getOrder`)
        .then(({ data }) => {
          if (data) {
            setorder({
              status: STATUS_LOADED,
              element: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el carrito de compras.");
          }
        });
    }
  });
  useEffect(() => {
    if (packageOnSale.status === STATUS_NOT_LOADED) {
      setpackageOnSale({ ...packageOnSale, status: STATUS_LOADING });
      client()
        .get(`billing/package`, {
          params: {
            with: ["details.service", "details.product", "benefits"],
            filters: { status_id: PACKAGE_STATUS, active: true, parent_id: null },
          },
        })
        .then(({ data }) => {
          if (data) {
            setpackageOnSale({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el carrito de compras.");
          }
        });
    }
  });
  useEffect(() => {
    if (services.status === STATUS_NOT_LOADED) {
      setServices({ ...services, status: STATUS_LOADING });
      client()
        .get(`billing/service`, {
          params: {
            filters: { active: true },
            orderBy: ["order:ASC"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setServices({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los servicios.");
          }
        });
    }
  }, [services]);


  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountOrders();
      setrows(newRows);
      let newPages = Math.ceil(newRows / metaRow.limit);
      
      if ((metaRow.page > newPages)&&newPages!==0) {
        setmetaRow({ ...metaRow, page: 1 });
        return false;
      }
      client()
        .get(`billing/getOrders`, {
          params: {
            with: ['consumable'],
            orderBy: metaRow.orderBy,
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
            limit: metaRow.limit,
            offset: metaRow.limit * (metaRow.page - 1),
          },
        })
        .then(({ data }) => {
          if (data) {
            setBills({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las compras");
          }
        });
    }
    fetchData();
  }, [metaRow]);
  const getCountOrders = async () => {
    return client()
      .get(`billing/countOrders`, {
        params: {
      
          filters: metaRow.filters,
          filtersLike: metaRow.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  useEffect(() => {
    if (topics.status === STATUS_NOT_LOADED) {
      setTopics({ ...topics, status: STATUS_LOADING });
      client()
        .get(`library/topic`)
        .then(({ data }) => {
          if (data) {
            setTopics({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los temas.");
          }
        });
    }
  });
  const addPackagesOnPayment = () => {
    alertSuccess("Ingresando a la pasarela");
    client()
      .post(`billing/processPayment`, order.element)
      .then(({ data }) => {
        $("#formPaymentSuscription").submit();
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al ingresar los paquetes a la pasarela");
        }
      });
  };
  const filterConditions = () => {
    client()
      .get(`library/document`, {
        params: { filters: { role: "terms_conditions" } },
      })
      .then(({ data }) => {
        if (data) {
          setConditions({ ...conditions, elements: data[0] });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el documento");
        }
      });
  };
  const deletePackage = (package_id) => {
    client()
      .delete(`billing/package/${package_id}`)
      .then(({ data }) => {
        if (data) {
          alertSuccess("Paquete removido");
          removeOrAddPackageCart({ id: package_id }, true);
          /*    setState({...state,cart:{...state.cart,{status:STATUS_NOT_LOADED}}}) */
          setorder({
            ...order,
            status: STATUS_NOT_LOADED,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el carrito de compras.");
        }
      });
  };
  const filterPolicies = () => {
    client()
      .get(`library/document`, {
        params: { filters: { role: "privacy_policies" } },
      })
      .then(({ data }) => {
        if (data) {
          setPolicies({ ...policies, elements: data[0] });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el documento");
        }
      });
  };

  useEffect(() => {
    if (meta.status === STATUS_NOT_LOADED && meta.path.bill_id != null) {
      setMeta({ ...meta, status: STATUS_LOADING });
      client()
        .get(`billing/bill/${meta.path.bill_id}`)
        .then(({ data }) => {
          if (data) {
            setBill(data);
            history.push(`/app/library/topic/paymentConfirm`);
          }
        });
    }
  }, [meta]);

  const addToCart = (form, company_id = null) => {
    form.buyer_id = user.id;
    let route = `security/person/${user.id}/addToCart`;
    if (company_id != null) {
      route = `general/company/${company_id}/addToCart`;
    }
    client()
      .post(route, form)
      .then(({ data }) => {
        removeOrAddPackageCart(data, false);
        alertSuccess("Paquete añadido al carrito");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir al carrito");
        }
      });
  };

  const number_format = (amount, decimals) => {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "." + "$2");

    return amount_parts.join(".");
  };
  const calculateDiscount = (priceBase, discountByUser) => {
    return priceBase - (priceBase * discountByUser) / 100;
  };
  const searchTransactionId = ({ id_bill, signature }) => {
    client()
      .post(`billing/searchTransaction`, { transaction_id: signature, order_id: id_bill })
      .then(({ data }) => {
        if (data) {
          setBill(data);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        }
      });
  };

  return (
    <BillingContext.Provider
      value={[
        {
          packageOnSale,

          bill,
          services,
          meta,
          conditions,
          policies,
          bills,
          topics,
          order,
          companyPackage,
          metaRow
        },
        {
          addToCart,
          number_format,
          filterConditions,
          filterPolicies,
          searchTransactionId,
          deletePackage,
          addPackagesOnPayment,
          setcompanyPackage,
          setmetaRow
        },
      ]}
    >
      {children}
    </BillingContext.Provider>
  );
};
