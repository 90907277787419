import React from "react";
import "./CreateProgram.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import CreateProgramGeneral from "./general/CreateProgram";
const CreateProgram = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">
          Crear programa de auditorías
        </div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Realice seguimiento a todas las auditorias programadas en una
              vista:<br></br>
              Estados, Avance, diagramas de Gantt, Auditores Líderes.<br></br>
              Requisito: la empresa debe contar con suscripción para auditorias.
            </p>
          </div>
        </div>
      </div>

      <CreateProgramGeneral />
    </div>
  );
};

export default CreateProgram;
