import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import "./Index.css";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { ActionPlanIndexContext } from "../../../contexts/audit/action_plan/ActionPlanIndexContext";
import {
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import Pagination from "react-js-pagination";
const initialState = {};
const Index = () => {
  let history = useHistory();
  const [
    { analyze, metaRow, rows, tabAudit, statusAnalyze, statusAudit },
    { setmetaRow, settabAudit },
  ] = useContext(ActionPlanIndexContext);
  const [{}, {}] = useContext(SecurityContext);
  const setFieldMeta = (value, field) => {
    setmetaRow({ ...metaRow, [field]: value });
  };
  const statusAnalyzeIcon = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <img
              src={btn_created}
              title="Creada"
              className="img-status-analyze"
            />
          </>
        );
        break;

      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizada"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 8:
        return (
          <>
            <img
              src={deleteIcon}
              title="Rechazada"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      default:
        return (
          <img
            src={btn_created}
            title="Creada"
            className="img-status-analyze"
          />
        );
    }
  };
  const getAdvance = (actions) => {
    let closed = actions.filter((a) => a.is_open === false);
    let percentage = 0;
    if (actions.length > 0) {
      percentage = (closed.length * 100) / actions.length;
    }
    return (
      <div className="container-percentage-company-analyze">
        <div style={{ width: `${percentage}%` }}></div>
        <div>{percentage.toFixed(2)}%</div>
      </div>
    );
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRow.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRow({ ...metaRow, filtersLike: meta });
  };
  return (
    <div>
      <div className="container-index-analyzes-audit">
        <div className="flex content-title-programs">
          <div className="tipo-title title-audits">Planes de accion</div>
        </div>
        <div className="table-audits">
          <div className="flex justify-betwen">
            <h3>Planes de Acción de Auditorías</h3>
            {/* <a
            className="bg-orange white btn-audit-program-index"
            title="Crear una auditoría perteneciente a un programa de auditorías previamente existente de una empresa"
            href="/app/audit/programs"
          >
            Crear auditoría de Programa
          </a> */}
          </div>
          <div className="table-analyze-audits">
            {" "}
            <table className=" table table-light">
              <thead className="thead-dark">
                <tr className="txt-center">
                  <th title="ID Plan de acción" scope="col">
                    Id. Acc.
                  </th>
                  <th title="ID auditoria" scope="col">
                    ID.Aud
                  </th>
                  <th title="Empresa" scope="col">
                    Empresa
                  </th>
                  <th title="Auditoria" scope="col">
                    Nombre Aud
                  </th>
                  <th title="Norma" scope="col">
                    Norma
                  </th>
                  <th title="Fecha fin" scope="col">
                    F. Compromiso
                  </th>
                  <th title="Estado auditoria" scope="col">
                    Estado. Aud.
                  </th>

                  <th title="Criterio" scope="col">
                    Criterio
                  </th>
                  <th title="Responsable del plan" scope="col">
                    Responsable
                  </th>
                  <th title="Estado del Plan de acción" scope="col">
                    Estado Acc.
                  </th>
                  <th title="Avance del Plan de acción" scope="col">
                    Avance
                  </th>
                  <th title="Fecha de compromiso" scope="col">
                    F.Fin
                  </th>
                </tr>
              </thead>
              <tbody className="tipo-description">
                <tr>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar "
                        onChange={(e) => setFieldFilter("number", e)}
                        value={metaRow.filtersLike["number"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("audit:number", e)}
                        value={metaRow.filtersLike["audit:number"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("company:name", e)}
                        value={metaRow.filtersLike["company:name"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("audit:title", e)}
                        value={metaRow.filtersLike["audit:title"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) =>
                          setFieldFilter("audit.standard:short_name", e)
                        }
                        value={metaRow.filtersLike["audit.standard:short_name"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("init_date", e)}
                        value={metaRow.filtersLike["init_date"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    <Select
                      value={statusAudit.elements.filter(
                        (s) => s.id === metaRow.filtersLike["audit:status_id"]
                      )}
                      onChange={(e) =>
                        setFieldFilter(
                          "audit:status_id",
                          e ? e.id : null,
                          false
                        )
                      }
                      className="tipo-description filter-select-table"
                      placeholder={"Estado"}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      options={statusAudit.elements}
                      isClearable={true}
                    />
                  </th>

                  <th>
                    {" "}
                    {/* {" "}
                    <div>
                      <input
                        className="input-filter-table "
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("analyzable.auditable_item:number", e)}
                        value={metaRow.filtersLike["analyzable.auditable_item:number"]}
                        type="text"
                      />
                    </div> */}
                  </th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) =>
                          setFieldFilter("responsable:first_name", e)
                        }
                        value={metaRow.filtersLike["responsable:first_name"]}
                        type="text"
                      />
                    </div>
                  </th>
                  <th>
                    {" "}
                    <Select
                      value={statusAnalyze.elements.filter(
                        (s) => s.id === metaRow.filtersLike["status_id"]
                      )}
                      onChange={(e) =>
                        setFieldFilter("status_id", e ? e.id : null, false)
                      }
                      className="tipo-description filter-select-table"
                      placeholder={"Estado"}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      options={statusAnalyze.elements}
                      isClearable={true}
                    />
                  </th>
                  <th></th>
                  <th>
                    {" "}
                    <div>
                      <input
                        className="input-filter-table"
                        placeholder="filtrar"
                        onChange={(e) => setFieldFilter("end_date", e)}
                        value={metaRow.filtersLike["end_date"]}
                        type="text"
                      />
                    </div>
                  </th>
                </tr>
                {analyze.elements.map((an) => (
                  <tr className="txt-center" key={an.id}>
                    <th>
                      {" "}
                      <span
                        className="cursor-action link-audit-index"
                        onClick={() => {
                          history.push(`/app/audit/analyze/${an.id}`);
                        }}
                      >
                        {an.number}
                      </span>
                    </th>
                    <td>
                      <span
                        className="cursor-action link-audit-index"
                        onClick={() => {
                          history.push(`/app/audit/${an.audit_id}`);
                        }}
                      >
                        {an.audit && an.audit.number}
                      </span>
                    </td>
                    <td>{an.company && an.company.name}</td>
                    <td>{an.audit && an.audit.title}</td>
                    <td>{an.audit.standard && an.audit.standard.short_name}</td>
                    <td>{an.end_date}</td>
                    <td>{statusAnalyzeIcon(an.audit && an.audit.status_id)}</td>

                    <td>
                      {an.analyzable.auditable_item &&
                        an.analyzable.auditable_item.number}
                    </td>
                    <td>
                      {an.responsable &&
                        an.responsable.first_name +
                          " " +
                          an.responsable.last_name}
                    </td>
                    <td>{statusAnalyzeIcon(an.status_id)}</td>
                    <td>{getAdvance(an.actions)}</td>
                    <td>{an.real_end_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ margin: "10PX" }} className="justify-end flex">
            <Pagination
              activePage={metaRow.page}
              itemsCountPerPage={metaRow.limit}
              totalItemsCount={rows}
              pageRangeDisplayed={5}
              itemClass="page-item item-page-zeus"
              linkClass="page-link page-link-zeus"
              onChange={(e) => setFieldMeta(e, "page")}
            />
          </div>
        </div>
      </div>

      {/*   /////////////////////////////////////////MOBILE/////////////////////////// */}
      <div className="hidden container-index-analyzes-audit-mobile ">
        <div className="flex bg-white container-headers-audit-mobile">
          <div onClick={() => settabAudit(true)} className="flex-1 txt-center">
            <p className={`${tabAudit ? "p-header-mobile-audits-active" : ""}`}>
              Auditorias
            </p>
          </div>
          <div onClick={() => settabAudit(false)} className="flex-1 txt-center">
            <p
              className={`${!tabAudit ? "p-header-mobile-audits-active" : ""}`}
            >
              PQRS
            </p>
          </div>
        </div>
        <br />
        {tabAudit && (
          <>
            {analyze.elements.map((an) => (
              <div
                key={an.id}
                onClick={() => {
                  history.push(`/app/audit/analyze/${an.id}`);
                }}
                className="tarjet-analyze-audit-mobile"
              >
                <div className="tipo-description flex">
                  <p className="flex-1 container-title-number-audit-mobile">
                    <strong>
                      {an.number}:
                      {an.analyzable.auditable_item &&
                        an.analyzable.auditable_item.number}{" "}
                    </strong>{" "}
                    {an.description}
                  </p>
                  <span className="icon-status-audit-mobile">
                    {statusAnalyzeIcon(an.status_id)}
                  </span>
                </div>
              </div>
            ))}
            <div style={{ margin: "10PX" }} className="justify-end flex">
              <Pagination
                activePage={metaRow.page}
                itemsCountPerPage={metaRow.limit}
                totalItemsCount={rows}
                pageRangeDisplayed={5}
                itemClass="page-item item-page-zeus"
                linkClass="page-link page-link-zeus"
                onChange={(e) => setFieldMeta(e, "page")}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
