exports = module.exports = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../img_zeus/default/PROFILE.jpeg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../img_zeus/icons_general/Notifica_audito.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../img_zeus/icons_general/Notifica_biblioteca.png"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../../img_zeus/icons_general/Notifica_gralpng.png"));

// Module
exports.push([module.id, ".content-general-notification {\n  position: absolute;\n  top: 0;\n  margin-top: 64px;\n  right: 0;\n  z-index: -1;\n  max-height: 401px;\n  max-width: 404px;\n  overflow: auto;\n  width: -webkit-fill-available;\n  background-color: #ceccca;\n  border-left: #ceccca 5px solid;\n}\n.content-general-notification > div {\n  margin-top: 2px;\n}\n\n.content-icon-notification-module {\n  width: 55px;\n  height: 55px;\n  margin-right: 3px;\n}\n.icon-notification-user {\n  width: 45px;\n  height: 45px;\n  position: relative;\n  border-radius: 22px;\n  background-size: 100% 100%;\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n.icon-module-notification {\n  position: absolute;\n  width: 31px;\n  height: 31px;\n  right: -6px;\n  bottom: -6px;\n}\n.notification-icon-audit {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n.notification-icon-library {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.notification-icon-general {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n.title-date-notifications {\n  font-size: 14px;\n}\n.bg-notification-touched {\n  background-color: #e2e2e4;\n}\n.title-date-notifications > div:nth-child(2) {\n  margin-right: 4px;\n}\n.text-notification {\n  font-size: 15px;\n  line-height: 18px;\n  margin-bottom: 3px;\n}\n", ""]);

