import React, { useEffect, useState, useContext } from "react";
import axios from "../axios/axios-client";
import axiosOrigin from "axios";
import firebase from "../../firebase";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import Swal from "sweetalert2";
import cloneDeep from "lodash/cloneDeep";
import client from "../axios/axios-client";
import moment from "moment";
import $ from "jquery";
import AES from "crypto-js/aes";
import ENC from "crypto-js/enc-utf8";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const ROLE_PERSON_INVITED = "ROLE_PERSON_INVITED";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const BILL_AUDIT_COMPANY = "audit_company";
export const BILL_AUDIT_PERSON = "audit_person";
export const BILL_APPROVED = 7;
export const CART_STATUS = 12;
let otherStateInitial = {
  companyStaffValidate: {
    status: STATUS_NOT_LOADED,
    staff: null,
  },
};
let initalState = {
  status: STATUS_NOT_LOADED,
  loadingRegister: false,
  loginWorker: false,
  user: {
    id: "",
    first_name: "",
    last_name: "",
    profiles: [],
    companies: [],
    permisions: [],
    subscriptions: [],
    notifications: [],
  },
  userIvited: {
    first_name: "",
    last_name: "",
    password: "",
    password_confirmation: "",
    token: "",
  },

  cities: {
    iso: null,
    status: STATUS_LOADED,
    cities: [],
  },
  company: {
    id: "",
    name: "",
    nit: "",
  },
  cart: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },

  tokenReset: null,
  formErrors: {},
};
const db = firebase.firestore();

export const SecurityContext = React.createContext([]);

export const SecurityContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{}, { alertSuccess, alertWarning, alertError, showErrors }] = useContext(AlertContext);
  let [state, setState] = React.useState(initalState);
  let [errors, setErrors] = useState(initalState.errors);
  const [loginWorker, setloginWorker] = useState(initalState.loginWorker);
  const [tokenInvited, settokenInvited] = useState("");
  const [companyStaffValidate, setcompanyStaffValidate] = useState(
    otherStateInitial.companyStaffValidate
  );
  const [initBtnLogin, setinitBtnLogin] = useState(false);
  const [detailsPersonAudit, setdetailsPersonAudit] = useState({
    status: STATUS_NOT_LOADED,
    elements: [],
  });
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    const id = setInterval(() => {
      if (state.status === STATUS_LOGGED_IN) {
        registerInWork();
      }
    }, 50000);
    return () => {
      clearInterval(id);
    };
  }, [state.status]);
  /*   useEffect(() => {
    if (detailsPersonAudit.status === STATUS_NOT_LOADED && state.status === STATUS_LOGGED_IN) {
      axios()
        .get(`security/detailsPersonAudit`)
        .then(({ data }) => {
          //setdetailsPersonAudit({ ...detailsPersonAudit, elements: data, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al añadir la accion");
          }
        });
    }
  }, [detailsPersonAudit]); */
  useEffect(() => {
    if (state.status === STATUS_LOGGED_IN && state.cart.status === STATUS_NOT_LOADED) {
      client()
        .get(`billing/package`, {
          params: {
            filters: { status_id: CART_STATUS, active: true, buyer_id: state.user.id },
            with: ["product"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setState({
              ...state,
              cart: {
                ...state.cart,
                status: STATUS_LOADED,
                elements: data.filter((p) => p.package || p.product),
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el carrito de compras.");
          }
        });
    }
  }, [state]);
  useEffect(() => {
    if (state.status === STATUS_LOGGED_IN) {
      const db = firebase.firestore();
      db.collection(`users/${state.user.firebase_id}/notifications`)
        .orderBy("created_at", "desc")
        .onSnapshot(function (data) {
          let cloneState = cloneDeep(state);
          cloneState.user.notifications = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setState(cloneState);
        });

      db.collection(`users`)
        .doc(`${state.user.firebase_id}`)
        .onSnapshot(function (doc) {
          let userFire = doc.data();
          if (userFire.token) {
            if (state.user.token_session !== userFire.token) {
              Swal.fire({
                title: "Doble Ingreso",
                text: "Otro dispositivo esta ingresando con esta cuenta",
                icon: "warning",
                confirmButtonColor: "#df8c37",
                confirmButtonText: "¡Entendido!",
              }).then((result) => {
                logout();
              });
            }
          }
        });
    }
  }, [state.status]);

  useEffect(() => {
    if (state.status === STATUS_NOT_LOADED) {
      setState({ ...state, status: STATUS_LOADING });
      axios()
        .get("security/user")
        .then(({ data }) => {
          if (!data) {
            setState({ ...state, status: STATUS_NOT_LOGGED_IN });
          } else {
            if (!data.firebase_id) {
              //si un usuario no tiene identificador de firebase por algún motivo
              addFirebaseIdToUser(data);
            } else {
              db.collection(`users`)
                .doc(`${data.firebase_id}`)
                .update({
                  token: data.token_session,
                })
                .then(function () {
                  setState({
                    ...state,
                    user: data,
                    companies: data.companies,
                    subscriptions: data.subscriptions,
                    status: STATUS_LOGGED_IN,
                    cities: {
                      ...state.cities,
                      elements: data.city ? [data.city] : [],
                      iso: data.city ? data.city.iso : null,
                    },
                  });
                })
                .catch(function (error) {
                  addFirebaseIdToUser(data);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (state.status === STATUS_LOGGED_IN && /^\/login/.test(location.pathname)) {
      if (location.state && location.state.referer && !/^\/login/.test(location.state.referer)) {
        //si viene con parametros en url y le dio click al boton login

        if (initBtnLogin) {
          if (location.state.referer.split("/").includes("document")) {
            //si se autentica y debe ir a un documento
            history.replace(location.state.referer);
          } else {
            if (state.user.haveAuditsActive) {
              history.replace("/app/audit/audits");
            } else {
              history.replace("/app/library/topics");
            }
          }
        } else {
          history.replace(location.state.referer);
        }
      } else {
        history.replace("/app/library/topics");
      }
      setinitBtnLogin(false);
    }
    setloginWorker(false);
  }, [state]);

  const refreshUser = () => {
    setState({ ...state, status: STATUS_NOT_LOADED });
  };

  const refreshCompanies = () => {
    axios()
      .get("security/user")
      .then(({ data }) => {
        setState({
          ...state,
          user: data,
        });
      });
  };

  // componteDidMointed & componentDidUpdated
  useEffect(() => {
    if (state.status !== STATUS_LOGGED_IN && /^\/app/.test(location.pathname)) {
      history.replace("/login", { referer: location.pathname });
    }
  }, [location.pathname, state.status]);

  const login = (email, password) => {
    if (loginWorker) {
      return false;
    }
    setloginWorker(true);
    registerLogin(email, password, "{}");
    /*  fetch(`https://api.ipdata.co?api-key=${process.env.REACT_APP_API_IPDATA}`)
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        if (!res.ip) {
          $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function (json) {
            registerLogin(email, password, json);
          });
          return false;
        }
        registerLogin(email, password, res);
      })
      .catch((err) => {
        $.getJSON("https://api.ipify.org?format=jsonp&callback=?", function (json) {
          registerLogin(email, password, json);
        });
      }); */
  };
  const getCities = (cityText) => {
    if (state.cities.iso === null) {
      alertWarning("seleccione Pais");
      return false;
    }
    client()
      .get(`general/getCities/${state.cities.iso}/${cityText}`)
      .then(({ data }) => {
        if (data) {
          setState({
            ...state,
            cities: { ...state.cities, elements: data },
          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar las ciudades");
        }
      });
  };
  const setcities = (field, value) => {
    setState({
      ...state,
      cities: { ...state.cities, [field]: value },
    });
  };
  const registerLogin = (email, password, data) => {
    axios()
      .get("security/user")
      .then(({ data }) => {
        if (!data.id) {
          //inicio de sesion sin token de usuario vigente
          setState({ ...state, status: STATUS_LOADING });
          axios()
            .post("security/login", { email, password, data: data })
            .then(({ data }) => {
              if (localStorage) {
                localStorage.setItem("token", `Bearer ${data.token}`);
              }
              setinitBtnLogin(true);
              setState({ ...state, status: STATUS_NOT_LOADED });
            })
            .catch((e) => {
              if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 401) {
                setErrors({ error: e.response.data, status: true });
              }
              setState({
                ...state,
                status: STATUS_NOT_LOGGED_IN,
              });
              setloginWorker(false);
            });
        } else {
          //el usuario inicia sesion teniendo ya una  activa
          window.location.reload(false);
        }
      });
  };

  const getSrcDocumentWithBearer = async (document) => {
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }

    return axiosOrigin
      .create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          Authorization: token,
        },
        responseType: "arraybuffer",
      })
      .get("documents/" + document.name)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "*",
        });
        var objectURL = URL.createObjectURL(blob);
        return objectURL;
      })
      .catch((e) => {});
  };
  const touchedNotification = async (notification) => {
    const userFirebase = await db
      .collection(`users/${state.user.firebase_id}/notifications`)
      .doc(notification.id)
      .set({ ...notification, touched: true });
  };

  const viewedNotifications = () => {
    for (let n = 0; n < state.user.notifications.length; n++) {
      const notification = state.user.notifications[n];

      db.collection(`users/${state.user.firebase_id}/notifications`)
        .doc(notification.id)
        .set({ ...notification, viewed: true });
    }
  };
  const fetchUser = (email) => {
    return db.collection("users").add({ email });
  };
  const addFirebaseIdToUser = async (user) => {
    const userFirebase = await fetchUser(user.email);
    axios()
      .post("security/person/" + user.id, { firebase_id: userFirebase.id })
      .then(() => {
        setState({ ...state, status: STATUS_NOT_LOADED });
      });
  };
  const register = async (first_name, last_name, email, password, repeat_password, token) => {
    const taken = token;
    if (password != repeat_password) {
      alertWarning("Las contraseñas no coinciden");
      return false;
    }
    setState({ ...state, loadingRegister: true });
    const userFirebase = await fetchUser(email);
    axios()
      .post("security/person", {
        first_name,
        last_name,
        email,
        password,
        taken,
        firebase_id: userFirebase.id,
      })
      .then(({ data }) => {
        setState({ ...state, loadingRegister: false });
        history.push("/login");
        Swal.fire({
          title: "Activa tu cuenta",
          text: "Se ha enviado un enlace a tu correo para activar tu cuenta ",
          icon: "success",
          confirmButtonColor: "#df8c37",
          confirmButtonText: "¡Entendido!",
        }).then((result) => {});
      })
      .catch((e) => {
        setState({ ...state, loadingRegister: false });
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else {
          alertError("Error al registrar");
        }
      });
  };
  const updateImagePerson = (document) => {
    var formData = new FormData();

    formData.append("document", document);

    axios()
      .post("security/person/" + state.user.id, formData)
      .then(({ data }) => {
        setState({ ...state, status: STATUS_NOT_LOADED });
        alertSuccess("Datos actualizados satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el usuario");
        }
      });
  };
  const updatePerson = ({
    first_name,
    last_name,
    document,
    id_number,
    birth_date,
    phone,
    country_code,
    locality,
    place_id,
    region,
    address,
    city_id,
  }) => {
    var formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("id_number", id_number);
    formData.append("birth_date", moment(new Date(birth_date)).format("YYYY-MM-DD"));
    formData.append("phone", phone);
    formData.append("country_code", country_code);
    formData.append("region", region);
    formData.append("locality", locality);
    formData.append("place_id", place_id);
    formData.append("city_id", city_id);
    formData.append("address", address);

    if (document != null) {
      formData.append("document", document);
    }

    axios()
      .post("security/person/" + state.user.id, formData)
      .then(({ data }) => {
        setState({ ...state, status: STATUS_NOT_LOADED });
        alertSuccess("Datos actualizados satisfactoriamente");
      })
      .catch((e) => {
        console.log("errorrr");
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el usuario");
        }
      });
  };
  const changeStateUser = (status, user_id) => {
    var formData = new FormData();
    formData.append("status", status);

    axios()
      .post("security/person/" + user_id, formData)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al cambiar el estado del usuario");
        }
      });
  };
  const can = (permission) => {
    if (state.user.is_admin) {
      return true;
    }
    let permissions = [];
    state.user.profiles.map((profile) => {
      profile.permissions.map((per) => {
        permissions.push(per);
      });
    });
    let el = permissions.filter((e) => e.name === permission);
    return el.length > 0 ? true : false;
  };
  const logout = () => {
    let token = localStorage.getItem("token");
    if (token) {
      addEndSession(token);
    } else {
      localStorage.removeItem("token");
      setState({ ...initalState, status: STATUS_NOT_LOADED });
      window.location.reload(false);
    }
  };
  const changeCompany = (company) => {
    setState({ ...state, company: company });
  };
  const changeToken = (token) => {
    setState({ ...state, tokenReset: token });
  };
  const resetPassword = ({ email, password, password_confirmation }) => {
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("token", state.tokenReset);

    formData.append("password_confirmation", password_confirmation);

    axios()
      .post("security/password/reset/" + state.tokenReset, formData)
      .then(({ data }) => {
        alertSuccess("Contraseña restablecida");
        history.replace("/login");
        logout();
      })
      .catch((e) => {
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else {
          alertError("Error al modificar la contraseña");
        }
      });
  };
  const validateAccount = async (token) => {
    axios()
      .get("security/activeAccount/" + token)
      .then(({ data }) => {
        history.push("/login");
        Swal.fire({
          title: "Bienvenido " + data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1),
          text: "Tu cuenta ha sido activada",
          icon: "success",
          confirmButtonColor: "#df8c37",
          confirmButtonText: "Iniciar sesión",
        }).then((result) => {});
      })
      .catch((e) => {
        history.push("/login");
        Swal.fire({
          title: "!Upps¡",
          text: "Algo ha salido mal,te entendemos",
          icon: "error",
          confirmButtonColor: "#df8c37",
          confirmButtonText: "Entendido",
        }).then((result) => {});
      });
  };
  const validateAccountInvited = async (token) => {
    let responseToken = await findToken(token);
    if (responseToken) {
      if (responseToken.active === false) {
        history.push("/login");
        Swal.fire({
          title: "!Upps¡",
          text: "Algo ha salido mal,te entendemos",
          icon: "error",
          confirmButtonColor: "#df8c37",
          confirmButtonText: "Entendido",
        }).then((result) => {});
      }
      settokenInvited(responseToken.token);
    } else {
      history.push("/login");
      Swal.fire({
        title: "!Upps¡",
        text: "Algo ha salido mal,te entendemos",
        icon: "error",
        confirmButtonColor: "#df8c37",
        confirmButtonText: "Entendido",
      }).then((result) => {});
    }
  };
  const findToken = async (token) => {
    return axios()
      .get("security/findToken/" + token)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        alertError("Error al buscar el token");
      });
  };
  const activedInvited = () => {
    axios()
      .post(`security/activeInvited/${tokenInvited}`, state.userIvited)
      .then(({ data }) => {
        history.push("/login");
        Swal.fire({
          title: "Bienvenido " + data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1),
          text: "Tu cuenta ha sido activada",
          icon: "success",
          confirmButtonColor: "#df8c37",
          confirmButtonText: "Iniciar sesión",
        }).then((result) => {});
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al activar el usuario");
        }
      });
  };
  const addEndSession = (token) => {
    axios()
      .get(`security/closeSessionTime/${token}`)
      .then(({ data }) => {
        localStorage.removeItem("token");
        setState({ ...initalState, status: STATUS_NOT_LOADED });
        window.location.reload(false);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        }
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al registrar el cierre de sesión");
        }
      });
  };
  //registra la fecha actual en la session por si cierra el navegador saber cuanto tiempo estuvo conectado
  const registerInWork = () => {
    let token = localStorage.getItem("token");
    if (token) {
      axios()
        .get(`security/registerWorkTime/${token}`)
        .then(({ data }) => {})
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          }
          if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al registrar el tiempo de trabajo");
          }
        });
    } else {
      logout();
    }
  };
  const sendEmailPassword = (email) => {
    axios()
      .post(`security/password/email`, { email: email })
      .then(({ data }) => {
        Swal.fire({
          title: "Se ha enviado un link al correo",
          text: "Puede llegar a la lista de spam o notificaciones",
          icon: "success",
          confirmButtonColor: "#df8c37",
          cancelButtonColor: "#171e27",
          confirmButtonText: "Entendido",
        }).then((result) => {});
      })
      .catch((e) => {
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al enviar el correo");
        }
      });
  };
  const isAdminTopic = (topic_id) => {
    let { user } = state;
    if (user.topics_admin.length > 0) {
      let el = user.topics_admin.filter((e) => e.id == topic_id);

      if (el.length > 0) {
        return true;
      }
    }
    return false;
  };
  const isAdminForum = (topic_id) => {
    let { user } = state;
    if (user.topics_admin_forum.length > 0) {
      let el = user.topics_admin_forum.filter((e) => e.id == topic_id);
      if (el.length > 0) {
        return true;
      }
    }
    return false;
  };
  const removeOrAddPackageCart = (packageAction, isRemove) => {
    let clonePackages = cloneDeep(state.cart.elements);
    if (isRemove) {
      setState({
        ...state,
        cart: {
          ...state.cart,
          elements: clonePackages.filter((p) => p.id != packageAction.id),
        },
      });
    } else {
      clonePackages.push(packageAction);
      setState({
        ...state,
        cart: {
          ...state.cart,
          elements: clonePackages,
        },
      });
    }
  };
  const isCollaboratorStandard = (standard_id) => {
    let isCollaborator = state.user.is_admin ? true : false;
    for (let c = 0; c < state.user.standards_collaborator.length; c++) {
      const standard = state.user.standards_collaborator[c];
      if (standard_id == standard.id) {
        isCollaborator = true;
      }
    }
    return isCollaborator;
  };
  const searchUserOrCompany = async (text, isCompany = false) => {
    let route = `security/searchUsers/${text}`;
    let filtersLike = {};
    let withRelations = [];
    if (isCompany) {
      route = `general/company`;
      filtersLike = {
        email: text,
        name: text,
      };
      withRelations = ["contact"];
    }
    return client()
      .get(route, {
        params: {
          filtersLike: filtersLike,
          with: withRelations,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar los usuarios");
        }
      });
  };

  const downloadDocument = (ref) => {
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    var params = {
      Authorization: token,
    };

    var url = [ref, $.param(params)].join("?");

    window.open(url, "_blank");
  };

  const searchStaff = (aes) => {
    //var ciphertext = AES.encrypt("1053780662|901229923-6", "ChR@N0s").toString();

    var bytes = AES.decrypt(aes, "ChR@N0s");
    var originalText = bytes.toString(ENC);
    var data = originalText.split("|");
    let document = null;
    let nit = null;
    if (data.length > 0) {
      document = data[0];
      nit = data[1];
    }
    let form=new FormData();
    form.append('id_number',document);
    form.append('nit',nit);

    axios()
      .post(`general/getSttaffValidate`,form )
      .then(({ data }) => {
        let staffs = data.filter((c) => c.company && c.person);
        staffs = staffs.filter((c) => c.person.id_number == document && c.company.nit == nit);
        let staff = null;
        if (staffs.length > 0) {
          staff = staffs[0];
        }
        setcompanyStaffValidate({
          ...companyStaffValidate,
          status: STATUS_LOADED,
          staff: staff,
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al registrar la visita");
        }
      });
  };
  const viewGeneral = (module) => {
    axios()
      .get(`security/viewGeneral/${module}`)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al registrar la visita");
        }
      });
  };
  const number_format = (amount, decimals) => {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

    return amount_parts.join(".");
  };
  const pluck = (array, key) => {
    return array.map((o) => o[key]);
  };
  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }
  return (
    <SecurityContext.Provider
      value={[
        state,

        {
          updatePerson,
          updateImagePerson,
          login,
          logout,
          register,
          changeCompany,
          can,
          changeToken,
          resetPassword,
          validateAccount,
          sendEmailPassword,
          isAdminForum,
          isAdminTopic,
          changeStateUser,
          refreshUser,
          touchedNotification,
          viewedNotifications,
          fetchUser,
          refreshCompanies,
          isCollaboratorStandard,
          viewGeneral,
          pluck,
          number_format,
          removeOrAddPackageCart,
          capitalize,
          getSrcDocumentWithBearer,
          downloadDocument,
          searchUserOrCompany,
          validateAccountInvited,
          setState,
          activedInvited,
          getCities,
          setcities,
          searchStaff,
        },
        { companyStaffValidate },
      ]}
    >
      {children}
    </SecurityContext.Provider>
  );
};
