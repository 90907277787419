exports = module.exports = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../img_zeus/BOTON-EDITAR-FOTO-PERFIL.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../img_zeus/icons_library/BOTON-CLOSE1.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../img_zeus/icons_library/BOTON-CLOSE2.png"));

// Module
exports.push([module.id, ".content-edit-profile {\n  padding: 25px;\n  margin-top: 17px;\n  margin-bottom: 25px;\n}\n\n.container-image-profile > div {\n  width: 137px;\n  border-radius: 92px;\n  height: 137px;\n  display: flex;\n  background-size: 100% 100%;\n}\n.button-image-profile {\n  width: 27px;\n  position: absolute;\n  left: 105px;\n  top: 93px;\n  padding: 0 !important;\n  background: white !important;\n  height: 27px;\n  background-size: 100% 100% !important;\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ") !important;\n}\n.profile-user-name {\n  font-size: 32px;\n}\n.btn-update-profile {\n  border: none;\n  border-radius: 16px;\n  margin-top: 17px;\n  padding-left: 14px;\n  padding-right: 14px;\n}\n.input-email-profile {\n  background: #eee;\n}\n.btn-close-profile{\n  width: 23px;\n  cursor: pointer;\n  height: 23px;\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n.btn-close-profile:hover{\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}", ""]);

