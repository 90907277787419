import React from "react";
import "./Home.css";
import { useLocation, useHistory } from "react-router-dom";
import banner from "../../img_zeus/Banner.png";
import actualidad from "../../img_zeus/Actualidad.png";
import auditorias from "../../img_zeus/Auditorias.png";
import accion from "../../img_zeus/Plan_de_accion.png";
import consultores from "../../img_zeus/Consultores.png";

const Home = () => {
  let history = useHistory();
  return (
    <>
      <div className="flex flex-column content-mobile-home hidden">
        <img className="banner-home-img" src={banner} />
        <div className="flex flex-column container-imgs-home margin-auto">
          <div className="flex flex-column margin-auto">
            <div className="flex-1 flex">
              <img
                onClick={() => history.push(`/app/library/topics`)}
                src={actualidad}
                alt=""
              />
              <img
                onClick={() => history.push(`/app/audit/audits`)}
                src={auditorias}
                alt=""
              />
            </div>
            <div className="flex-1 flex">
              <img   onClick={() => history.push(`/app/audit/actions_plans`)} className="" src={accion} alt="" />
              <img className="gray-scale" src={consultores} alt="" />
            </div>
          </div>
        </div>
        <div className="footer-home-mobile">
          <div className="flex flex-column white tipo-description">
            <div className="margin-auto">
              {" "}
              <div>Zeus comprometido con la mejora de procesos.</div>
              <div>
                <strong> CONTACTÉNOS:</strong> contacto@crhonosiso.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
