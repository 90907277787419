import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  status: STATUS_NOT_LOADED,
  companies: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  companiesStaff: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },

  cities: {
    status: STATUS_NOT_LOADED,
    iso: null,
    elements: [],
  },
  countries: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const CompanyIndexContext = createContext([]);

export const CompanyIndexContextProvider = ({ children }) => {
  const [company, setcompany] = useState(initialState);
  const [companyStaff, setcompanyStaff] = useState(initialState.companiesStaff);
  const [cities, setcities] = useState(initialState.cities);
  const [countries, setcountries] = useState(initialState.countries);

  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  const [{}, { refreshCompanies }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [{ user }, { logout }] = useContext(SecurityContext);

  let history = useHistory();

  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (countries.status === STATUS_NOT_LOADED) {
      setcountries({ ...countries, status: STATUS_LOADING });
      client()
        .get(`general/country`)
        .then(({ data }) => {
          if (data) {
            setcountries({ ...countries, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los paises");
          }
        });
    }
  }, [countries]);
  const getCities = (cityText) => {
    if (cities.iso === null) {
      alertWarning("seleccione Pais");
      return false;
    }
    client()
      .get(`general/getCities/${cities.iso}/${cityText}`)
      .then(({ data }) => {
        if (data) {
          setcities({
            ...cities,
            status: STATUS_LOADED,
            elements: data,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar las ciudades");
        }
      });
  };
  useEffect(() => {
    if (company.status === STATUS_NOT_LOADED) {
      setcompany({ ...company, status: STATUS_LOADING });
      client()
        .get("general/company", {
          params: {
            filters: { contact_person_id: user.id },
            with: ["contact", "location", "audits", "city"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setcompany({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [company]);
  useEffect(() => {
    if (companyStaff.status === STATUS_NOT_LOADED) {
      setcompanyStaff({ ...companyStaff, status: STATUS_LOADING });
      client()
        .get("general/staff", {
          params: {
            filters: { person_id: user.id },
            with: ["company.contact", "company.location"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setcompanyStaff({ ...companyStaff, elements: data, status: STATUS_LOADED });
          }
        });
    }
  }, [companyStaff]);
  //functions
  const createCompany = (
    { country_code, locality, latitude, longitude, place_id, region },
    { representative, name, nit, email, phone, address,city_id }
  ) => {
    client()
      .post("general/company", {
        name: name,
        nit: nit,
        address: address,
        region: region,
        country_code: country_code,
        locality: locality,
        latitude: latitude,
        longitude: longitude,
        place_id: place_id,
        contact_person_id: user.id,
        email: email,
        phone: phone,
        representative: representative,
        city_id:city_id
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Compañia creada satisfactoriamente");
          refreshCompanies();
          setcompany({ ...company, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la empresa");
        }
      });
  };
  const updateCompany = (
    { country_code, locality, latitude, longitude, place_id, region },
    { address, representative, nit, name, phone, email,city_id },
    id
  ) => {
   
    client()
      .put(`general/company/${id}`, {
        nit: nit,
        name: name,
        address: address,
        region: region,
        country_code: country_code,
        locality: locality,
        latitude: latitude,
        longitude: longitude,
        place_id: place_id,
        phone: phone,
        email: email,
        representative: representative,
        city_id:city_id
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Compañia actualizada satisfactoriamente");
          setcompany({ ...company, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al actualizar la empresa");
        }
      });
  };

  const deleteCompany = (id) => {
    client()
      .delete(`general/company/${id}`)
      .then(({ data }) => {
        alertSuccess("Compañia eliminada satisfactoriamente");
        setcompany({ ...company, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la empresa");
        }
      });
  };

  return (
    <CompanyIndexContext.Provider
      value={[
        { company, companyStaff, cities, countries },
        { createCompany, updateCompany, deleteCompany, getCities, setcities },
      ]}
    >
      {children}
    </CompanyIndexContext.Provider>
  );
};
