import React, { useEffect, useState, useContext } from "react";
import { SubscriptionContext } from "../../../contexts/library/SubscriptionContext";
import { SubscriptionFormContext } from "../../../contexts/library/SubscriptionFormContext";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import "../topic/Subscription_description.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import imgError from "../../../default.jpg";
import moment from "moment";
import imgError2 from "../../../img_zeus/default/PROFILE.jpeg";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";
import Select, { components } from "react-select";

const STATUS_SHOW_USER = "SHOW_USER";
const NUMBER_CARACTER = 5;
const STATUS_APPROVED_PAYMENT = 7;
let initialState = {
  item: {
    searchUser: "",
    person_staff: "",
  },
};

const SubDescription = ({ computedMatch }) => {
  const [
    { subscription, billDetail, users, errors },
    {
      setSubscriptionId,
      filterUser,
      createSubscription,
      sendInvitationEmail,
      number_format,
    },
  ] = useContext(SubscriptionFormContext);
  const [{ user }] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [state, setState] = useState(initialState);
  let { params, url } = computedMatch;
  let { billDetail_id } = params;

  let history = useHistory();
  useEffect(() => {
    if (state.item.searchUser.length >= NUMBER_CARACTER) {
      filterUser(state.item.searchUser);
    }
  }, [state.item.searchUser]);
  let setField = (field) => (e) => {
    setState({
      ...state,
      item: { ...state.item, [field]: e.target.value },
    });
  };
  const daysValid = (valid) => {
    return Math.round(moment.duration(moment(valid).diff(moment())).asDays());
  };

  useEffect(() => {
    setSubscriptionId(billDetail_id);
  }, [billDetail_id]);

  useEffect(() => {
    if (
      subscription.billDetail &&
      subscription.billDetail.bills &&
      subscription.billDetail.bills.status != STATUS_APPROVED_PAYMENT
    ) {
      history.push("/app/unauthorized");
    }
  });

  const getButtonsSubscription = (staff) => {
    {
      let exist = false;
      for (let i = 0; i < subscription.billDetail.subscriptions.length; i++) {
        if (subscription.billDetail.subscriptions[i].person_id === staff.id) {
          exist = true;
        }
      }
      return exist;
    }
  };

  const sendInvitation = (email) => {
    sendInvitationEmail(email);
  };

  const checkSubscription = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, asignar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  const validSubscription = (billDetail_id) => {
    {
      let isActive =
        subscription.billDetail.end_date >
        moment().format("YYYY-MM-DD h:mm:ss");

      return isActive;
    }
  };

  const onStaff = (sub) => {
    let staff = false;
    if (user.contact_companies) {
      for (let index = 0; index < user.contact_companies.length; index++) {
        for (
          let i = 0;
          i < user.contact_companies[index].partners.length;
          i++
        ) {
          if (
            user.contact_companies[index].partners[i].id == sub.consumable.id
          ) {
            staff = true;
          }
        }
      }
    }
    return staff;
  };
  const existPersonSubscription = (person) => {
    let exist = false;
    subscription.subscription.map((sub) => {
      if (person.id == sub.person_id) {
        exist = true;
      }
    });
    return exist;
  };

  return (
    <div className="tipo-description main-content-bill-detail">
      <h2 className="tipo-title title-detail-subscription">
        Administrador de suscripciones
      </h2>
      {subscription.status === STATUS_LOADED && (
        <div className="content-general-bill-detail">
          <div className="info-topic-bill-detail">
            <div className="first-content-info">
              <img
                className="img-topic-subscription-bill-detail"
                src={
                  subscription.topic.image &&
                  process.env.REACT_APP_API_HOST +
                    "documents/" +
                    subscription.topic.image.name
                }
                onError={(e) => {
                  e.target.src = imgError;
                }}
              />
              <div className="flex flex-column content-title-bill-detail">
                <div className="subscription-topic">
                  {validSubscription(subscription.billDetail.id) == false && (
                    <div className="subscription-tarjet-topic-false-bill-detail tipo-boton">
                      Vencido
                    </div>
                  )}
                  {validSubscription(subscription.billDetail.id) == true && (
                    <div className="subscription-tarjet-topic-true2-bill-detail tipo-boton">
                      Activo
                    </div>
                  )}
                </div>
                <h5 className="tipo-description title-topic-bil-detail">
                  Categoria
                </h5>
                <h2 className="tipo-title title-name-bil-detail">
                  {subscription.topic.name}
                </h2>
              </div>
            </div>
          </div>
          <br />
          <div className="dropdown-divider divider-bill-detail"></div>
          <div>
            <div className="flex">
              <div className="flex flex-column">
                <h2 className="orange tipo-title">
                  {subscription.billDetail &&
                    subscription.billDetail.service &&
                    subscription.billDetail.service.name}
                </h2>
                <strong className="tipo-title age-subscription">
                  a{" "}
                  {Math.round(
                    moment(subscription.billDetail.end_date).diff(
                      subscription.billDetail.init_date,
                      "months",
                      true
                    )
                  )}{" "}
                  meses
                </strong>
              </div>
              <div className="flex date-subscription-bill-detail">
                <div className="init-date-subscription color-gray">
                  Inicio:{" "}
                  {moment(subscription.billDetail.init_date).format(
                    "YYYY-MM-DD"
                  )}
                </div>
                <div className="end-date-subscription color-gray">
                  Vence:{" "}
                  {moment(subscription.billDetail.end_date).format(
                    "YYYY-MM-DD"
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="flex">
              <div className="value-bill-detail flex flex-column flex-1">
                <div className="color-gray">Valor pagado</div>
                <div className="flex">
                  <h4 className="tipo-title color-black-gray">
                    {subscription.billDetail.package &&
                      number_format(
                        subscription.billDetail.package.order
                          ? subscription.billDetail.package.order.value
                          : 0,
                        0
                      )}
                  </h4>
                  &nbsp;
                  <div className="cop-bill-detail tipo-title color-black-gray">
                    COP
                  </div>
                </div>
                {subscription.billDetail &&
                  subscription.billDetail.package &&
                  subscription.billDetail.package.order &&subscription.billDetail.package.order.bill_number&& (
                    <div
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_API_HOST +
                            `billing/showBill/${subscription.billDetail.package.order.bill_number}`
                        )
                      }
                      className="id-factura-bill-detail orange tipo-title cursor-action"
                    >
                      ID de compra:{" "}
                      {subscription.billDetail.package.order.bill_number}
                    </div>
                  )}
              </div>
              <div className="flex flex-1 txt-center">
                <div className="container-date-valid_until_suscription margin-auto">
                  <div
                    onClick={() =>
                      history.push(
                        `/app/library/topic/${subscription.topic.id}/payment`
                      )
                    }
                    className="renovate-image-suscription"
                  ></div>
                  Suscripción de{" "}
                  {moment(subscription.billDetail.end_date).diff(
                    subscription.billDetail.init_date,
                    "months"
                  )}{" "}
                  meses.<br></br>
                  {daysValid(subscription.billDetail.end_date) > 0 && (
                    <div>
                      RESTAN {daysValid(subscription.billDetail.end_date)} DÍAS.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown-divider divider-bill-detail"></div>
          <div>
            <h6 className="tipo-title">
              {" "}
              {subscription.billDetail &&
                subscription.billDetail.consumers.length}
              /{subscription.billDetail.quantity} Plan usado por
            </h6>
            <div>
              {subscription.subscription.map((sub, key) => {
                return (
                  <div key={sub.id} className="flex flex-column">
                    <div className="flex">
                      <img
                        className="img-profile-bill-detail"
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          (sub.consumable &&
                            sub.consumable.image &&
                            sub.consumable.image.name)
                        }
                        onError={(e) => {
                          e.target.src = imgError2;
                        }}
                      />
                      <div className="flex flex-column profile-person-bill-detail">
                        <div className="tipo-title color-black-gray flex">
                          <strong>
                            {sub.consumable.first_name}{" "}
                            {sub.consumable.last_name}
                          </strong>{" "}
                          &nbsp;
                          {onStaff(sub) && (
                            <div className="staff-bill-detail tipo-description">
                              Staff
                            </div>
                          )}
                        </div>
                        <div>{sub.consumable.email}</div>
                      </div>
                    </div>
                    {subscription.subscription.length != key + 1 && (
                      <div className="dropdown-divider"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="dropdown-divider divider-bill-detail"></div>
          <div>
            <div>
              {subscription.billDetail.assignable && (
                <div>
                  <h5>
                    Miembros de la compañia :{" "}
                    <strong>{subscription.billDetail.assignable.name}</strong>
                  </h5>

                  <Select
                    className="tipo-description"
                    placeholder={"Seleccione un miembro"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        item: {
                          ...state.item,
                          person_staff: e ? e.id : null,
                          company_select: subscription.billDetail.assignable_id,
                        },
                      })
                    }
                    getOptionLabel={(option) => (
                      <div className="flex">
                        <img
                          className={` btn-auditor-activity-filter  cursor-action background-simple `}
                          src={
                            process.env.REACT_APP_API_HOST +
                            "/documents/" +
                            (option.image && option.image.name)
                          }
                          onError={(e) => {
                            e.target.src = imgAduitorCheck;
                          }}
                        ></img>
                        &nbsp;{option.first_name} {option.last_name}
                      </div>
                    )}
                    getOptionValue={(option) => option.id}
                    options={subscription.billDetail.assignable.partners.filter(
                      (staff) => !existPersonSubscription(staff)
                    )}
                    isClearable={true}
                    isMulti={false}
                  />

                  {state.item.person_staff != "" &&
                    state.item.company_select ==
                      subscription.billDetail.assignable.id && (
                      <button
                        onClick={() => {
                          checkSubscription(
                            "¿Realmente deseas asignar esta suscripción?",
                            `createSubscription(${state.item.person_staff}, ${
                              subscription.billDetail &&
                              subscription.billDetail.id
                            })`
                          );
                        }}
                        className="btn bg-orange"
                      >
                        Suscribir
                      </button>
                    )}
                  <br />
                </div>
              )}
            </div>
            <br />
          </div>
          <div className="dropdown-divider divider-bill-detail"></div>
          <div>
            <h6 className="tipo-title">Asignar nuevo desde correo</h6>
            <div>
              <div className="flex flex-column">
                <input
                  onChange={setField("searchUser")}
                  type="email"
                  required
                  value={state.searchUser}
                  className="txt-search-admin w-50 tipo-description form-control"
                  placeholder="Buscar usuario"
                ></input>
                <label className="tipo-cursiva" htmlFor="">
                  Búsqueda apartir de {NUMBER_CARACTER} caracteres
                </label>
              </div>

              {users.isSearch == true && (
                <div>
                  <p>Usuario no encontrado</p>
                  {state.item.searchUser}{" "}
                  <button
                    onClick={() => sendInvitation(state.item.searchUser)}
                    className="btn bg-orange tipo-boton btn-send-invitation"
                  >
                    Enviar Invitación
                  </button>
                </div>
              )}
              {users.element != null && users.isSearch == false && (
                <div className="tipo-description">
                  <strong className="tipo-title">Nombre de usuario: </strong>
                  {users.element && users.element.first_name}{" "}
                  {users.element && users.element.last_name}
                  <br />
                  <strong className="tipo-title">Correo electronico: </strong>
                  {users.element && users.element.email}
                  <br />
                  <button
                    onClick={() => {
                      checkSubscription(
                        "¿Realmente deseas asignar esta suscripción?",
                        `createSubscription(${
                          users.element && users.element.id
                        }, ${
                          subscription.billDetail && subscription.billDetail.id
                        })`
                      );
                    }}
                    className="btn bg-orange tipo-boton"
                  >
                    Añadir suscripción
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default SubDescription;
