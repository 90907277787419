import React, { useState, useEffect, useContext } from "react";
import "./FinalReport.css";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import moment from "moment";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import {
  AuditPlanFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { STATUS_EXECUTED } from "./Activities";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";

const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const MAX_FILE_SIZE = 80;
const EXT_XLSX = "xlsx";

const ROLE_FINAL_REPORT_NOTE = "final_report";
let initialState = {
  message: {
    document: null,
    note: "",
    role: ROLE_FINAL_REPORT_NOTE,
    is_private: false,
  },
};

const FinalReport = () => {
  let [
    {
      audit,
      toogleModalProcessReport,
      processReport,
      processVerification,
      toogleModalVerificationProcess,
    },
    {
      sendMessageFinalReport,
      closeApproveddPlan,
      addDocumentFinalReport,
      validateAuditFreeCompany,
      settoogleModalProcessReport,
      settoogleModalVerificationProcess,
      setprocessReport,
      setprocessVerification,
      generateReportProcess,
      generateVerificationProcess,
      getViewDownload,
    },
  ] = useContext(AuditPlanFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [{ user }, { downloadDocument }] = useContext(SecurityContext);
  let history = useHistory();
  let notes = audit.originalItem.final_notes;
  const [message, setMessage] = useState(initialState.message);
  const uploadDocument = (e) => {
    setMessage({ ...message, document: e.target.files[0] });
  };
  const sendMessage = () => {
    sendMessageFinalReport(message);
    setMessage(initialState.message);
  };

  const canDownLoadDocuments = () => {
    return true;
  };

  const checkCloseApproveddPlan = () => {
    Swal.fire({
      title: "¿Realmente deseas aprobar el reporte final?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, aprobar!",
    }).then((result) => {
      if (result.value) {
        closeApproveddPlan();
        sendMessage();
      } else {
      }
    });
  };
  const uploadDocumentReport = () => (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_FILE_SIZE) {
        alertWarning("El tamaño excede la capacidad");
        return false;
      } else {
        addDocumentFinalReport(file);
      }
    }
  };

  return (
    <div className="container-chat-final-report flex flex-wrap">
      {toogleModalVerificationProcess && (
        <div className="back-document">
          <div style={{minWidth:"270px"}} className="modal-audit flex-column flex">
            <div className="header-modal-audit flex">
              <div
                className="salir-audit"
                onClick={() =>
                  settoogleModalVerificationProcess(
                    !toogleModalVerificationProcess
                  )
                }
              ></div>
              <h3 className="title-form-topic tipo-description">
                Seleccione procesos
              </h3>
            </div>
            <div className="container-process-report-modal flex-column">
              <div className="form-group">
                <Select
                  value={processVerification}
                  onChange={(e) => setprocessVerification(e)}
                  className="tipo-description"
                  placeholder={"Todos los procesos"}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={audit.originalItem.company.process}
                  isClearable={true}
                  isMulti={true}
                />
              </div>
              <div className="flex">
                <div
                  onClick={() => generateVerificationProcess()}
                  className="btn-generate-report-process bg-orange white"
                >
                  Generar reportes
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
      {toogleModalProcessReport && (
        <div className="back-document">
          <div className="modal-audit flex-column flex">
            <div style={{minWidth:"270px"}} className="header-modal-audit flex">
              <div
                className="salir-audit"
                onClick={() =>
                  settoogleModalProcessReport(!toogleModalProcessReport)
                }
              ></div>
              <h3 className="title-form-topic tipo-description">
                Seleccione procesos
              </h3>
            </div>
            <div className="container-process-report-modal flex-column">
              <div className="form-group">
                <Select
                  value={processReport}
                  onChange={(e) => setprocessReport(e)}
                  className="tipo-description"
                  placeholder={"Todos los procesos"}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={audit.originalItem.company.process}
                  isClearable={true}
                  isMulti={true}
                />
              </div>
              <div className="flex">
                <div
                  onClick={() => generateReportProcess()}
                  className="btn-generate-report-process bg-orange white"
                >
                  Generar reportes
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}

      <div className="flex-1 flex container-messages-final-report">
        <div className="flex container-buttons-final-report">
          <div className="flex-column flex-1">
            <div className="txt-center tipo-title margin-bottom-9">
              Listas de verificación
            </div>

            {audit.originalItem.lider_person.id == user.id && (
              <>
                <div className="flex margin-bottom-6">
                  <div
                    title="Descargar"
                    className="btn-orange-default-short  margin-auto flex btn-download-document-word-report"
                    onClick={() =>
                      canDownLoadDocuments()
                        ? getViewDownload(
                            `${process.env.REACT_APP_API_HOST}audit/listCheckView/${audit.originalItem.id}`,
                            `${process.env.REACT_APP_API_HOST}audit/listCheck/${audit.originalItem.id}`
                          )
                        : ""
                    }
                  >
                    <span className="background-simple icon-word-span-report"></span>{" "}
                    Lista de verificación
                  </div>
                </div>
                <div className="flex">
                  <div
                    title="Descargar"
                    className="btn-orange-default-short  margin-auto flex"
                    onClick={() =>
                      canDownLoadDocuments()
                        ? settoogleModalVerificationProcess(
                            !toogleModalVerificationProcess
                          )
                        : ""
                    }
                  >
                    <span className="background-simple icon-word-span-report"></span>{" "}
                    Lista de verificación por proceso
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex-column flex-1">
            <div className="txt-center  margin-bottom-9">Reporte final</div>
            {audit.originalItem.lider_person.id == user.id && (
              <>
                <div className="flex  margin-bottom-6">
                  <div
                    className="btn-orange-default-short  margin-auto flex"
                    onClick={() =>
                      canDownLoadDocuments()
                        ? history.push(
                            `../audit/${audit.originalItem.id}/pdf/showReport`
                          )
                        : ""
                    }
                  >
                    <span className="background-simple icon-word-span-report"></span>{" "}
                    <span className="background-simple icon-pdf-span-report"></span>{" "}
                    Descargar información reporte final
                  </div>
                </div>
                <div className="flex margin-bottom-4">
                  <div
                    id="btn-report-process-report"
                    className="btn-orange-default-short  margin-auto flex"
                    onClick={() =>
                      canDownLoadDocuments()
                        ? settoogleModalProcessReport(!toogleModalProcessReport)
                        : ""
                    }
                  >
                    <span className="background-simple icon-word-span-report"></span>{" "}
                    Descargar reporte por proceso
                  </div>
                </div>
              </>
            )}
            {audit.originalItem.lider_person.id == user.id && (
              <>
                {audit.originalItem.standard.item_types.length > 0 && (
                  <div className="flex margin-bottom-4">
                    <div
                      id="btn-report-objective"
                      title="Descargar"
                      className="btn-orange-default-short  margin-auto flex"
                      onClick={() =>
                        canDownLoadDocuments()
                          ? getViewDownload(
                              `${process.env.REACT_APP_API_HOST}audit/showAuditExpressView/${audit.originalItem.id}`,
                              `${process.env.REACT_APP_API_HOST}audit/showAuditExpressDownload/${audit.originalItem.id}`
                            )
                          : ""
                      }
                    >
                      <span className="background-simple icon-pdf-span-report"></span>{" "}
                      Reporte con objetivo
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex-column flex-1">
            <div className="txt-center tipo-title  margin-bottom-9">
              Reporte final (Proceso Auditor)
            </div>
            {audit.originalItem.lider_person.id == user.id && (
              <div className="flex  margin-bottom-6">
                <div className="btn-orange-default-short  margin-auto flex">
                  <label
                    className="label-input-file-upload-final-report white btn-lable-upload-final"
                    htmlFor="file"
                  >
                    Cargar Reporte Final
                  </label>
                  <input
                    onChange={uploadDocumentReport()}
                    type="file"
                    name="file"
                    id="file"
                    className="input-file-upload-final-report"
                  />
                </div>
              </div>
            )}
            {(audit.originalItem.lider_person.id == user.id ||
              audit.originalItem.company.contact.id == user.id) &&
              audit.originalItem.documents_final_report.length > 0 && (
                <div className="flex margin-bottom-4">
                  <div
                    id={`${
                      audit.originalItem.documents_final_report[
                        audit.originalItem.documents_final_report.length - 1
                      ].type === EXT_PDF
                        ? "btn-download-icon-btn-final-report-pdf"
                        : "btn-download-icon-btn-final-report-word"
                    }`}
                    className={` btn-orange-default-short  margin-auto flex`}
                    title="Ver"
                    onClick={() =>
                      canDownLoadDocuments()
                        ? history.push(
                            `../audit/${audit.originalItem.id}/pdf/finalReport`
                          )
                        : ""
                    }
                  >
                    <span
                      id="icon-btn-final-report"
                      className="background-simple "
                    ></span>
                    Reporte final
                  </div>
                </div>
              )}
          </div>
        </div>

        {notes.map((note, index) => {
          return (
            <div
              className={`flex container-flex-message-final-report ${
                note.person_id == user.id ? "justify-end" : ""
              }`}
              key={index}
            >
              {note.person_id != user.id && (
                <img
                  className="img-user-final-report img-left-final-report"
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/documents/" +
                    (note.person.image && note.person.image.name)
                  }
                  onError={(e) => {
                    e.target.src = imgError;
                  }}
                />
              )}

              <div className="tipo-description container-message-final-report">
                <p>{note.note}</p>
                <div>
                  {note.documents.map((document) => {
                    return <Document key={document.id} document={document} />;
                  })}
                </div>
                <span>{moment(note.created_at).format("YYYY-M-D h:mm")}</span>
              </div>
              {(note.person_id == user.id &&
                index != 0 &&
                notes[index - 1].person_id != user.id) ||
                (index == 0 && (
                  <img
                    className="img-user-final-report img-right-final-report"
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (user.image && user.image.name)
                    }
                    onError={(e) => {
                      e.target.src = imgError;
                    }}
                  />
                ))}
            </div>
          );
        })}
      </div>
      <div className="flex container-add-text-final-report">
        <div className="flex flex-1 container-input-final-report">
          <div className="flex flex-1 input-message-final-reeport">
            {" "}
            <input
              value={message.note}
              onChange={(e) => setMessage({ ...message, note: e.target.value })}
              type="text"
              placeholder="Escribe un comentario"
              className="tipo-description w-100 "
            />{" "}
            {/*       <input
              onChange={uploadDocument}
              type="file"
              name="file"
              id="file"
              className="inputfile-final-report"
            />
            <label className="label-file-final-report" htmlFor="file"></label> */}
          </div>
          {audit.originalItem.responsable_person_id == user.id &&
            audit.originalItem.status_id != STATUS_EXECUTED && (
              <div className="flex container-btn-final-report">
                <div
                  className="tipo-boton cursor-action"
                  onClick={() => checkCloseApproveddPlan()}
                >
                  Aprobar Reporte
                </div>
              </div>
            )}
        </div>
        <div className="container-send-message-final-report flex justify-end">
          <div
            onClick={() => sendMessage()}
            className="cursor-action send-message-final-report white tipo-boton"
          >
            {" "}
            Enviar comentario
          </div>
        </div>
      </div>
    </div>
  );
};
const Document = ({ document }) => {
  const [{}, { downloadDocument }] = useContext(SecurityContext);
  return (
    <>
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <img
          className="cursor-action img-attached-final-report"
          src={
            process.env.REACT_APP_API_HOST +
            "/documents/" +
            (document && document.name)
          }
          onError={(e) => {
            e.target.src = imgError;
          }}
        />
      )}
      {document.type == EXT_PDF && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-pdf-observation btn-files-observations"
        ></div>
      )}
      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-word-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP3 && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-mp3-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP4 && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-mp4-observation btn-files-observations"
        ></div>
      )}
    </>
  );
};
export default FinalReport;
