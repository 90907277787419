import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "lodash/cloneDeep";
import Swal from "sweetalert2";
import { STATUS_EXECUTED } from "../../constant/contant";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const ITEM_SUR = "sur";
export const ITEM_SMMR = "smmr";
export const ITEM_QS = "qs";
export const ITEM_QL = "ql";
export const ITEM_DEBE = "debe";
export const ITEM_UFILE = "ufile";
export const ITEM_MFILE = "mfile";
export const ITEM_NUMBER = "number";
export const ITEM_INTEGER = "integer";
export const ITEM_DATE = "date";
export const ITEM_DATETIME = "datetime";

export const STATUS_ERASER = 5;
export const STATUS_APPROVED = 7;

const MAX_FILE_SIZE = 80;
const MAX_FILE_SIZE_FREE = 2;
const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLSX = "xlsx";

let initialState = {
  noteEdit: { id: null, note: "" },
  item: {
    status: STATUS_NOT_LOADED,
    item_id: null,
    element: {},
  },
  inspection: {
    status: STATUS_NOT_LOADED,
    evaluation_id: null,
    element: {},
  },
  childsEvaluation: {
    status: STATUS_NOT_LOADED,
    evaluation_id: null,
    elements: [],
  },
  statusInspection: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  analytic: {
    status: STATUS_NOT_LOADED,
    evaluation_id: null,
    element: {},
  },
  itemTypes: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  newItem: {
    id: null,
    title: "",
    evaluation_id: null,
    description: "",
    item_id: null,
    order: null,
    item_type_id: null,
    auditable_item_id: null,
    results: [],
    items: [],
  },
  newResponse: {
    response: {
      item_id: null,
      option_id: "",
    },
    picture: {
      image: [],
      item_id: null,
    },
    note: {
      note: "",
      item_id: null,
    },
    commentEdit: {
      note_id: null,
      note: "",
      item_id: null,
    },
  },
  newOption: {
    order: null,
    item_id: null,
    description: "Aqui añada una descripción",
    is_valid: null,
    value: "",
    item_type_id: null,
  },
  upload: {
    item_id: null,
    status: STATUS_LOADED,
  },
  metaRowChilds: {
    limit: 7,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  uploadOption: {
    option_id: null,
    status: STATUS_LOADED,
  },
  itemsDeploy: {},
  formats: ["png", "jpg", "jpeg", "doc", "docx", "pdf", "xlsx", "xls", "mp3", "mp4"],
  errors: {
    error: [],
    status: false,
  },
};
export const InspectionFormContext = React.createContext({});

export const InspectionFormContextProvider = ({ children }) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertWarning, showErrors, alertError }] = useContext(AlertContext);
  let location = useLocation();
  let history = useHistory();
  let [errors, setErrors] = useState(initialState.errors);
  const [inspection, setinspection] = useState(initialState.inspection);
  const [childsEvaluation, setchildsEvaluation] = useState(initialState.childsEvaluation);
  const [rowsChilds, setrowsChilds] = useState(0);
  const [analytic, setanalytic] = useState(initialState.analytic);
  const [metaRowChilds, setmetaRowChilds] = useState(initialState.metaRowChilds);
  const [itemTypes, setitemTypes] = useState(initialState.itemTypes);
  const [itemSelected, setitemSelected] = useState([]);
  const [inEvaluation, setinEvaluation] = useState(true);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [loadDocument, setloadDocument] = useState(initialState.upload);
  const [loadDocumentOption, setloadDocumentOption] = useState(initialState.uploadOption);
  const [withErrors, setwithErrors] = useState(false);
  const [note, setNote] = useState(initialState.newResponse.note);
  const [showNote, setShowNote] = useState("");
  const [noteEdit, setnoteEdit] = useState(initialState.noteEdit);
  let [item, setItem] = useState(initialState.item);
  const [notes, setNotes] = useState([]);
  const [codeItemTypeNew, setcodeItemTypeNew] = useState(ITEM_SMMR);
  const [textFirstDebe, settextFirstDebe] = useState("");
  const [picture, setPicture] = useState(initialState.newResponse.picture);
  const [statusInspection, setstatusInspection] = useState(initialState.statusInspection);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    //childsEvaluation.evaluation_id
    async function fetchData() {
      let newRows = await getCountChilds();
      setrowsChilds(newRows);
      let newPages = Math.ceil(newRows / metaRowChilds.limit);

      if (metaRowChilds.page > newPages && newPages !== 0) {
        setmetaRowChilds({ ...metaRowChilds, page: 1 });
        return false;
      }
      client()
        .get(`audit/indexEvaluationsChilds`, {
          params: {
            with: ["status", "owners.owner.image"],
            orderBy: metaRowChilds.orderBy,
            filters: metaRowChilds.filters,
            filtersLike: metaRowChilds.filtersLike,
            limit: metaRowChilds.limit,
            offset: metaRowChilds.limit * (metaRowChilds.page - 1),
          },
        })
        .then(({ data }) => {
          if (data) {
            setchildsEvaluation({ ...childsEvaluation, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los planes de accion");
          }
        });
    }
    if (metaRowChilds.filters.parent_evaluation_id) {
      fetchData();
    }
  }, [metaRowChilds]);
  useEffect(() => {
    if (statusInspection.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/audit/allStatus`, {})
        .then(({ data }) => {
          if (data) {
            setstatusInspection({ ...statusInspection, elements: data, status: STATUS_LOADED });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los estados de la inspeccion");
          }
        });
    }
  }, []);
  const getCountChilds = async () => {
    return client()
      .get(`audit/countChildsEvaluation`, {
        params: {
          filters: metaRowChilds.filters,
          filtersLike: metaRowChilds.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };
  useEffect(() => {
    if (inspection.status === STATUS_NOT_LOADED && inspection.evaluation_id !== null) {
      setinspection({ ...inspection, status: STATUS_LOADING });
      client()
        .get(`audit/evaluation/${inspection.evaluation_id}`, {
          params: {
            filters: { is_self_audit: true },
            with: [
              "items.itemType.options",
              "items.itemType.itemType",
              "items.options.documents",
              "items.notes",
              "items.documents",
              "items.results",
              "items.resultsItem.documents",
              "childsEvaluation.status",
              "childsEvaluation.owners.owner.image",
              "owners",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setRecursiveOptionsItem(data.items);

            setinspection({ ...inspection, status: STATUS_LOADED, element: data });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las listas de inspección");
          }
        });
    }
  }, [inspection]);
  const getInspectionWithLong = (long_id) => {
    client()
      .get(`audit/evaluation`, {
        params: {
          filters: { is_self_audit: true, long_id: long_id },
          with: [
            "items.itemType.options",
            "items.itemType.itemType",
            "items.options.documents",
            "items.notes",
            "items.documents",
            "items.results",
            "items.resultsItem.documents",
          ],
        },
      })
      .then(({ data }) => {
        if (data) {
          if (data.length > 0) {
            alertWarning("Generando lista de inspección ...");
            client()
              .get(`audit/cloneInspectionChild/${data[0].id}`)
              .then(({ data }) => {
                alertSuccess("Lista de inspeccion generada satisfactoriamente");
                history.push(`/app/inspection/evaluation/${data.id}`);
              })
              .catch((e) => {
                if (e.request.status === 401) {
                  logout();
                } else if (e.request.status === 422) {
                  setErrors({ error: e.response.data, status: true });
                } else if (e.request.status === 403) {
                  history.push("/app/unauthorized");
                } else {
                  alertError("Error al generar inspección");
                }
              });
          } else {
            alertWarning("No se encontro ninguna lista de inspección");
          }
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar las listas de inspección");
        }
      });
  };
  useEffect(() => {
    if (analytic.status === STATUS_NOT_LOADED && analytic.evaluation_id !== null) {
      setanalytic({ ...analytic, status: STATUS_LOADING });
      client()
        .get(`audit/inspectionAnalitic/${analytic.evaluation_id}`, {
          params: {
            filters: { is_self_audit: true },
            with: [
              "items.itemType.options",
              "items.itemType.itemType",
              "items.options.documents",
              "items.notes",
              "items.documents",
              "items.results",
              "items.childsItemsEvaluation",
              "items.resultsItem.documents",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setanalytic({ ...analytic, element: data, status: STATUS_LOADED });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("app/unauthorized");
          } else {
            alertError("Error al buscar las listas de inspección");
          }
        });
    }
  }, [analytic]);
  useEffect(() => {
    if (itemTypes.status === STATUS_NOT_LOADED) {
      setitemTypes({ ...itemTypes, status: STATUS_LOADING });
      client()
        .get(`evaluation/item_type`, {
          params: {
            filters: { item_type_id: null, standard_id: null },
            with: ["options", "itemType"],
          },
        })
        .then(({ data }) => {
          if (data) {
            for (let d = 0; d < data.length; d++) {
              const da = data[d];
              da.optionsItem = da.options;
              delete da.options;
            }
            setitemTypes({ ...itemTypes, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("app/unauthorized");
          } else {
            alertError("Error al buscar los tipos de items");
          }
        });
    }
  }, [itemTypes]);
  useEffect(() => {
    if (picture.item_id != null && picture.image.length > 0) {
      addDocumentItem(picture);
      setPicture(initialState.newResponse.picture);
    }
  }, [picture]);
  const setRecursiveOptionsItem = (items) => {
    for (let d = 0; d < items.length; d++) {
      const item = items[d];
      item.optionsItem = item.options;
      delete item.options;
      item.item_type.optionsItem = item.item_type.options;
      delete item.item_type.options;
      if (item.items.length > 0) {
        setRecursiveOptionsItem(item.items);
      }
    }
  };
  const addNewItem = (parent_id, code_item_type, title = null) => {
    if (itemTypes.status === STATUS_LOADED && inspection.status === STATUS_LOADED) {
      let cloneInspection = cloneDeep(inspection);
      let newItem = initialState.newItem;
      let itemType = itemTypes.elements.filter((it) => it.code === code_item_type)[0];
      newItem.evaluation_id = inspection.element.id;
      newItem.title = title;
      newItem.item_id = parent_id;
      newItem.item_type_id = itemType.id;
      newItem.evaluation_id = inspection.evaluation_id;
      newItem.optionsItem = itemType.optionsItem;

      client()
        .post(`audit/addItemToInspection`, newItem)
        .then(({ data }) => {
          data.optionsItem = data.options;
          if (parent_id) {
            let itemfind = itemFind(cloneInspection, parent_id);
            itemfind.items.push(data);
          } else {
            console.log(data);
            cloneInspection.element.items.push(data);
            settextFirstDebe("");
          }

          setinspection(cloneInspection);

          alertSuccess("Item añadido satisfactoriamente");
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el item");
          }
        });
    }
  };

  const updateItem = (form, item_id) => {
    client()
      .post(`evaluation/item/${item_id}`, form)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el item");
        }
      });
  };
  const updateOption = (form, option_id) => {
    client()
      .post(`evaluation/option/${option_id}`, form)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la opcion");
        }
      });
  };
  const addNewItemType = async (itemType) => {
    return client()
      .post(`evaluation/item_type`, {
        with: ["options", "itemType"],
        item_type_id: itemType.id,
        name: itemType.name,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear el tipo de item");
        }
      });
  };
  const changeItemType = async (item, itemType) => {
    return client()
      .post(`evaluation/changeItemType/${item.id}`, {
        with: [
          "itemType.options",
          "itemType.itemType",
          "options.documents",
          "notes",
          "documents",
          "results",
        ],
        item_type_id: itemType.id,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear el tipo de item");
        }
      });
  };
  const setItemTypeItem = (item_type, item) => {
    //function
    async function addItemNew(itemType, item) {
      let cloneInspection = cloneDeep(inspection);
      let itemSearch = itemFind(cloneInspection, item.id);
      let itemNew = await changeItemType(item, item_type);
      itemSearch.item_type = itemNew.item_type;
      itemSearch.optionsItem = itemNew.optionsItem;
      setinspection(cloneInspection);
    }
    addItemNew(item_type, item);
  };
  const removeOption = (item, option, indexOp) => {
    let cloneInspection = cloneDeep(inspection);
    let itemfind = itemFind(cloneInspection, item.id);
    itemfind.optionsItem.splice(indexOp, 1);
    setinspection(cloneInspection);
    async function removeOptionGet(item_id, form) {
      removeOptionFetch(item_id, form);
    }
    removeOptionGet(item.id, { option_id: option.id });
  };

  const itemFind = (cloneInspection, item_id) => {
    return recursiveItemFind(cloneInspection.element.items, item_id);
  };
  const recursiveItemFind = (items, item_id) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id == item_id) {
        return item;
      } else {
        if (item.items.length > 0) {
          let itemFind = recursiveItemFind(item.items, item_id);
          if (itemFind) {
            return itemFind;
          }
        }
      }
    }
  };
  const setFieldOption = (field, value, option, item, indexOp) => {
    let cloneInspection = cloneDeep(inspection);
    let itemfind = itemFind(cloneInspection, item.id);
    itemfind.optionsItem[indexOp][field] = value;
    setinspection(cloneInspection);
  };
  const setFieldItem = (field, value, item) => {
    let cloneInspection = cloneDeep(inspection);
    let itemfind = itemFind(cloneInspection, item.id);
    itemfind[field] = value;
    setinspection(cloneInspection);
  };
  const setFieldInspection = (field, value) => {
    let cloneInspection = cloneDeep(inspection);
    cloneInspection.element[field] = value;
    setinspection(cloneInspection);
  };
  const setTextOptionQuestion = (value, item, option, indexOp) => {
    option.pivot.response = value;
    let cloneInspection = cloneDeep(inspection);
    let itemfind = itemFind(cloneInspection, item.id);
    itemfind.results = [option];

    setinspection(cloneInspection);
  };
  const updateResponseTextQS = (item_id, form) => {
    async function fetchUpdate(item_id, form) {
      let data = await updateResponseText(item_id, form);
      let cloneInspection = cloneDeep(inspection);
      let itemfind = itemFind(cloneInspection, item_id);
      itemfind.results_item = [data[0].pivot];
      itemfind.results = [data[0]];
      setinspection(cloneInspection);
    }

    fetchUpdate(item_id, form);
  };
  const updateInspection = async (evaluation_id, form) => {
    return client()
      .put(`audit/evaluation/${evaluation_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la lista");
        }
      });
  };
  const updateResponseText = async (item_id, form) => {
    return client()
      .post(`evaluation/optionAttachResult/${item_id}`, form)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addNewOption = async (item_id, form) => {
    return client()
      .post(`evaluation/option`, form)
      .then(({ data }) => {
        client()
          .post(`evaluation/optionAttachItem/${item_id}`, { option_id: data.id })
          .then(({ data }) => {})
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al añadir la respuesta");
            }
          });
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear el tipo de item");
        }
      });
  };
  const addOptionItem = (item) => {
    let cloneInspection = cloneDeep(inspection);
    let itemFindSearch = itemFind(cloneInspection, item.id);
    async function fetchDataOption() {
      let initialOPtion = initialState.newOption;
      initialOPtion.item_id = item.id;
      let option = await addNewOption(item.id, initialOPtion);

      //itemFindSearch.optionsItem.push(option);
      if (itemFindSearch.options) {
        itemFindSearch.options.push(option);
      }
      console.log(itemFindSearch);
      setinspection(cloneInspection);
    }
    fetchDataOption();
  };
  const removeOptionFetch = (item_id, formData) => {
    return client()
      .post(`evaluation/optionDetachItem/${item_id}`, formData)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addResponseSUR = (item, parentItem_indexs, index, option) => {
    let cloneInspection = cloneDeep(inspection);
    let finditem = itemFind(cloneInspection, item.id);
    finditem.results = [option];
    setinspection(cloneInspection);
    var formData = new FormData();
    formData.append("option_id", option.id);
    client()
      .post(`evaluation/optionAttachResult/${item.id}`, formData)
      .then(({ data }) => {})
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addResponseSMMR = (item, indexOp, option) => {
    let cloneInspection = cloneDeep(inspection);
    let finditem = itemFind(cloneInspection, item.id);
    let optionResult = finditem.results.filter((op) => op.id === option.id);
    let route = `evaluation/optionAtachNoSyncItem/${item.id}`;
    if (optionResult.length > 0) {
      route = `evaluation/optionDetachResult/${item.id}`;
      finditem.results = finditem.results.filter((op) => op.pivot.option_id != option.id);
    } else {
      finditem.results = [...finditem.results, option];
    }
    setinspection(cloneInspection);

    var formData = new FormData();
    formData.append("option_id", option.id);

    client()
      .post(route, formData)
      .then(({ data }) => {})
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addResponseText = ({ item_id, index, option }) => {
    let cloneInspection = cloneDeep(inspection);
    setinspection(cloneInspection);

    var formData = new FormData();
    formData.append("option_id", option.id);

    client()
      .post(`evaluation/optionAtachNoSyncItem/${item_id}`, formData)
      .then(({ data }) => {})
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const checkDeleteItem = (parentItem_indexs, index) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este item?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        setitemSelected([]);
        let cloneInspection = cloneDeep(inspection);
        let itemFind = {};
        if (parentItem_indexs.length === 0) {
          itemFind = cloneInspection.element.items[index];
          cloneInspection.element.items = cloneInspection.element.items.filter(
            (i, ind) => ind !== index
          );
        } else {
          let items = cloneInspection.element.items;
          let item = {};
          for (let p = 0; p < parentItem_indexs.length; p++) {
            const position = parentItem_indexs[p];
            if (p + 1 === parentItem_indexs.length) {
              item = items[position];
            } else {
              items = items[position].items;
            }
          }
          itemFind = item.items[index];
          item.items = item.items.filter((i, ind) => ind !== index);
        }

        setinspection(cloneInspection);
        if (itemFind.id) {
          client()
            .delete(`evaluation/item/${itemFind.id}`)
            .then(({ data }) => {})
            .catch((e) => {
              if (e.request.status === 401) {
                logout();
              } else if (e.request.status === 422) {
                setErrors({ error: e.response.data, status: true });
              } else if (e.request.status === 403) {
                history.push("../../app/unauthorized");
              } else {
                alertError("Error al eliminar el item");
              }
            });
        }
      }
    });
  };
  const resetNewNote = () => {
    setNote(initialState.newResponse.note);
  };
  const validateDelete = (note_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta nota?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteNote(note_id, note.item_id);
      } else {
      }
    });
  };
  const updateNoteItem = (item_id) => {
    var formData = new FormData();
    formData.append("note", noteEdit.note);
    client()
      .post(`evaluation/item/${item_id}/noteUpdate/${noteEdit.id}`, formData)
      .then(({ data }) => {
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
        setnoteEdit({ ...noteEdit, id: null });

        alertSuccess("Nota editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la nota");
        }
      });
  };
  const checkAddNote = () => {
    addNoteItem(note);
  };
  const addNoteItem = ({ item_id, note }) => {
    var formData = new FormData();
    formData.append("note", note);
    formData.append("person_id", user.id);

    client()
      .post(`evaluation/item/${item_id}/notificated`, formData)
      .then(({ data }) => {
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });

        alertSuccess("Nota añadida satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el comentario");
        }
      });
  };
  const deleteNote = (note_id, item_id) => {
    client()
      .delete(`evaluation/item/${item_id}/noteDelete/${note_id}`)
      .then(({ data }) => {
        alertSuccess("Comentario eliminado satisfactoriamente");
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el item");
        }
      });
  };
  const uploadImage = (item_id) => (e) => {
    let file = e.target.files[0];
    setPicture({
      ...picture,
      image: [...picture.image, file],
      item_id: item_id,
    });
  };
  const uploadImageOption = (item, option_id) => (e) => {
    let file = e.target.files[0];
    var formData = new FormData();
    formData.append("document", file);
    let ext = file.name.split(".").pop();
    if (!validateFormat(ext)) {
      alertWarning("Formato inválido");
      return false;
    }
    if (ext === EXT_DOCX || ext === EXT_XLSX || ext === EXT_DOC) {
      formData.append("is_private", false);
    }
    setloadDocumentOption({ option_id: option_id, status: STATUS_LOADING });
    async function uploadDoc(item, option_id, formData) {
      let response = await updateResponseText(item.id, { option_id: option_id });
      let result_id = response[0].pivot.id;

      client()
        .post(`evaluation/item_result/${result_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          let cloneInspection = cloneDeep(inspection);
          let itemsearch = itemFind(cloneInspection, item.id);
          let responsePivot = response[0].pivot;
          responsePivot.documents = [];
          responsePivot.documents.push(data);
          itemsearch.results_item.push(responsePivot);
          setinspection(cloneInspection);
          setloadDocumentOption({ option_id: null, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setloadDocumentOption({ option_id: null, status: STATUS_NOT_LOADED });
        });
    }
    uploadDoc(item, option_id, formData);
  };
  const addDocumentItem = ({ item_id, image }) => {
    for (let d = 0; d < image.length; d++) {
      const document = image[d];
      var formData = new FormData();
      formData.append("document", document);
      let ext = document.name.split(".").pop();
      if (!validateFormat(ext)) {
        alertWarning("Formato inválido");
        return false;
      }
      if (ext === EXT_DOCX || ext === EXT_XLSX || ext === EXT_DOC) {
        formData.append("is_private", false);
      }

      setloadDocument({ item_id: item_id, status: STATUS_LOADING });
      client()
        .post(`evaluation/item/${item_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          setinspection({ ...inspection, status: STATUS_NOT_LOADED });
          setItem({ ...item, status: STATUS_NOT_LOADED });
          setloadDocument({ item_id: item_id, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setloadDocument({ item_id: item_id, status: STATUS_NOT_LOADED });
        });
    }
  };
  const validateFormat = (format) => {
    let isValid = false;

    for (let f = 0; f < initialState.formats.length; f++) {
      const ext = initialState.formats[f];
      if (ext == format.toLowerCase()) {
        isValid = true;
      }
    }
    return isValid;
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const deleteDocument = (document_id, item_id) => {
    client()
      .delete(`evaluation/item/${item_id}/documentDelete/${document_id}`)
      .then(({ data }) => {
        alertSuccess("Archivo eliminado satisfactoriamente");
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el archivo");
        }
      });
  };
  const validateStatusIspection = () => {
    Swal.fire({
      title: "¿Realmente deseas finalizar la lista?",
      text: "¡No podrás modificar los datos!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, enviar!",
    }).then(async (result) => {
      if (result.value) {
        let statusInspection = await finishInpection(inspection.element.id);
        if (statusInspection) {
          //si finzalizó la lista
          console.log(statusInspection);
          inspection.element.status_id = STATUS_EXECUTED;
          setinspection({
            ...inspection,
            element: { ...inspection.element, status_id: STATUS_EXECUTED },
          });
        }
      }
    });
  };
  const finishInpection = (inspection_id) => {
    if (inspection_id) {
      return client()
        .get(`audit/validateFormInspection/${inspection_id}`)
        .then(({ data }) => {
          setwithErrors(false);
          alertSuccess("Finalizado con exito");
          return data;
        })
        .catch((e) => {
          if (e.request.status === 400) {
            setErrors({ error: e.response.data, status: true });
            setwithErrors(true);
          } else if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al finalizar la evaluación");
          }
        });
    }
  };
  const deleteInspection = (id) => {
    client()
      .delete(`audit/evaluation/${id}`)
      .then(({ data }) => {
        let newChilds = childsEvaluation.elements.filter((i) => i.id != parseInt(id));
        setchildsEvaluation({
          ...childsEvaluation,
          elements: newChilds,
        });
        alertSuccess("Lista eliminada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar la lista");
        }
      });
  };
  const deleteDocumentResult = (
    indexDocument,
    indexDocumentable,
    item_id,
    document_id,
    item_result_id
  ) => {
    client()
      .delete(`evaluation/item_result/${item_result_id}/documentDelete/${document_id}`)
      .then(({ data }) => {
        alertSuccess("Archivo eliminado satisfactoriamente");
        let cloneInspection = cloneDeep(inspection);
        let itemsearch = itemFind(cloneInspection, item_id);
        let documentsFilter = itemsearch.results_item[indexDocumentable].documents.filter(
          (d) => d.id != document_id
        );
        itemsearch.results_item[indexDocumentable].documents = documentsFilter;
        setinspection(cloneInspection);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el archivo");
        }
      });
  };
  return (
    <InspectionFormContext.Provider
      value={[
        {
          inspection,
          itemTypes,
          itemSelected,
          inEvaluation,
          itemDeploy,
          loadDocument,
          note,
          showNote,
          noteEdit,
          notes,
          textFirstDebe,
          loadDocumentOption,
          analytic,
          withErrors,
          codeItemTypeNew,
          childsEvaluation,
          metaRowChilds,
          rowsChilds,
          statusInspection,
        },
        {
          setinspection,
          addNewItem,
          setitemSelected,
          setItemTypeItem,
          removeOption,
          setFieldOption,
          setFieldItem,
          addOptionItem,
          setinEvaluation,
          addResponseSUR,
          addResponseSMMR,
          addResponseText,
          setTextOptionQuestion,
          checkDeleteItem,
          setItemDeploy,
          setNote,
          setNotes,
          settextFirstDebe,
          setShowNote,
          setnoteEdit,
          resetNewNote,
          validateDelete,
          updateNoteItem,
          checkAddNote,
          uploadImage,
          checkDelete,
          updateItem,
          updateOption,
          setloadDocumentOption,
          uploadImageOption,
          updateResponseText,
          setanalytic,
          validateStatusIspection,
          updateResponseTextQS,
          getInspectionWithLong,
          deleteInspection,
          setFieldInspection,
          updateInspection,
          setcodeItemTypeNew,
          setchildsEvaluation,
          setmetaRowChilds,
        },
      ]}
    >
      {children}
    </InspectionFormContext.Provider>
  );
};
