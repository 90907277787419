import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "lodash/cloneDeep";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const ROLE_SHARE = "share";
export const STATUS_ERASER = 5;
export const STATUS_APPROVED = 7;
let initialState = {
  inspection: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  suscriptionsInspection: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modalInspection: {
    open: false,
    evaluation_id: null,
  },
  modalShareInspection: {
    open: false,
    evaluation_id: null,
    long_id: null,
  },
  errors: {
    error: [],
    status: false,
  },
};
export const InspectionIndexContext = React.createContext({});

export const InspectionIndexContextProvider = ({ children }) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertWarning, showErrors, alertError }] = useContext(AlertContext);
  let location = useLocation();
  let history = useHistory();
  let [errors, setErrors] = useState(initialState.errors);
  const [inspection, setinspection] = useState(initialState.inspection);
  const [suscriptionsInspection, setsuscriptionsInspection] = useState(
    initialState.suscriptionsInspection
  );
  const [textSearchUserShare, settextSearchUserShare] = useState("");
  const [modalshare, setmodalshare] = useState(initialState.modalShareInspection);
  const [shareSelect, setshareSelect] = useState(null);
  const [modalInspection, setmodalInspection] = useState(initialState.modalInspection);
  const [effectiveSelected, seteffectiveSelected] = useState(null);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (inspection.status === STATUS_NOT_LOADED) {
      setinspection({ ...inspection, status: STATUS_LOADING });
      client()
        .get(`audit/getInspectionList`, {})
        .then(({ data }) => {
          if (data) {
            setinspection({ ...inspection, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("app/unauthorized");
          } else {
            alertError("Error al buscar la lista de inspección");
          }
        });
    }
  }, [inspection]);
  useEffect(() => {
    if (shareSelect && modalshare.evaluation_id) {
      client()
        .post(`security/person/${shareSelect.id}/ownered`, {
          role: ROLE_SHARE,
          evaluation_id: modalshare.evaluation_id,
        })
        .then(({ data }) => {
          setinspection({ ...inspection, status: STATUS_NOT_LOADED });
          settextSearchUserShare("");
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("app/unauthorized");
          } else {
            alertError("Error al compartir la lista de inspección");
          }
        });
    }
  }, [shareSelect]);
  useEffect(() => {
    if (suscriptionsInspection.status === STATUS_NOT_LOADED) {
      setsuscriptionsInspection({ ...suscriptionsInspection, status: STATUS_LOADING });
      client()
        .get("audit/subscriptionInspection")

        .then(({ data }) => {
          if (data) {
            setsuscriptionsInspection({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [suscriptionsInspection]);
  const getUidEvaluation = (evaluation_id) => {
    client()
      .get(`audit/generateUID/${evaluation_id}`)
      .then(({ data }) => {
        let cloneInspection = cloneDeep(inspection);
        cloneInspection.elements.find((e) => e.id == data.id).long_id = data.long_id;
        setinspection(cloneInspection);
        alertSuccess("Url creada satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear la url");
        }
      });
  };
  const createInspection = ({
    is_public,
    is_template,
    is_self_audit,
    on_sale,
    title,
    description,
    status_id,
    response_for_user,
    max_use
  }) => {
    client()
      .post("audit/evaluation", {
        is_public,
        is_template,
        is_self_audit,
        on_sale,
        title,
        description,
        status_id,
        person_id: user.id,
        effective_id: effectiveSelected,
        response_for_user,
        max_use
      })
      .then(({ data }) => {
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setsuscriptionsInspection({ ...suscriptionsInspection, status: STATUS_NOT_LOADED });
        seteffectiveSelected(null);
        setmodalInspection({ ...modalInspection, open: false });
        alertSuccess("Lista creada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          e.response.data.suscription =
            "<a href='/app/library/topic/billing/suscription/inspection'><button onclick='redirectPaymentInspection()' class='btn-orange-default'>Adquirir suscripción</button></a>";
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear la lista");
        }
      });
  };
  const updateInspection = (evaluation_id, form) => {
    client()
      .put(`audit/evaluation/${evaluation_id}`, form)
      .then(() => {
        setinspection({ ...inspection, status: STATUS_NOT_LOADED });
        setmodalInspection({ ...modalInspection, open: false });
        alertSuccess("Lista editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la lista");
        }
      });
  };
  const deleteInspection = (id) => {
    client()
      .delete(`audit/evaluation/${id}`)
      .then(({ data }) => {
        let newInspections = inspection.elements.filter((i) => i.id != parseInt(id));
        setinspection({ ...inspection, elements: newInspections });
        alertSuccess("Lista eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar la lista");
        }
      });
  };
  const generateChildInspection = (evaluation_id) => {
    alertWarning("Generando lista de inspección ...");
    client()
      .get(`audit/cloneInspectionChild/${evaluation_id}`)
      .then(({ data }) => {
        alertSuccess("Lista de inspeccion generada satisfactoriamente");
        history.push(`/app/inspection/evaluation/${data.id}`);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al generar inspección");
        }
      });
  };
  const deleteEvaluationOwner = (evaluationOwner) => {
    client()
      .post(
        `security/person/${evaluationOwner.owner.id}/evaluationOwnerDelete/${evaluationOwner.evaluation_id}`,
        { role: ROLE_SHARE }
      )
      .then(({ data }) => {
        let inspectionFilter = inspection.elements.filter(
          (e) => e.id != evaluationOwner.evaluation_id
        );
        setinspection({ ...inspection, elements: cloneDeep(inspectionFilter) });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al generar inspección");
        }
      });
  };
  return (
    <InspectionIndexContext.Provider
      value={[
        {
          inspection,
          modalInspection,
          suscriptionsInspection,
          effectiveSelected,
          modalshare,
          shareSelect,
          textSearchUserShare,
        },
        {
          setmodalInspection,
          setinspection,
          createInspection,
          updateInspection,
          deleteInspection,
          generateChildInspection,
          seteffectiveSelected,
          setmodalshare,
          setshareSelect,
          settextSearchUserShare,
          getUidEvaluation,
          deleteEvaluationOwner,
        },
      ]}
    >
      {children}
    </InspectionIndexContext.Provider>
  );
};
