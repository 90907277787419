import React from "react";
import "./CreateProgram.css";
import { Link, useLocation, useHistory } from "react-router-dom";
const CreateCompany = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div  onClick={() => history.push(`/app/help`)} className="icon-help-audit background-simple"></div>
        </div>
        <div className="flex-1 title-help-audit">Crear empresa</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Requisitos previos:</p>
            <p className="text-long-required-help tipo-description">
              {" "}
              Tener empresa creada.La empresa debe tener suscripción para gestionar
              auditorías
            </p>
          </div>
        </div>
      </div>
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Responsable de la empresa</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-green white container-bubble-help">
          <div className="help-min-conatiner tipo-description">1</div>
          <div className="help-max-conatiner tipo-description">
            Seleccionar "Empresas" en el menú del lado izquierdo.
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Seleccionar "Crear empresa".
          </div>
          <div className="help-min-conatiner green tipo-description">2</div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-min-conatiner tipo-description">3</div>
          <div className="help-max-conatiner tipo-description">
            Diligenciar la información general solicitada.
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Seleccionar "Crear".
          </div>
          <div className="help-min-conatiner green tipo-description">4</div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-min-conatiner tipo-description">5</div>
          <div className="help-max-conatiner tipo-description">
            Ingresar a la empresa
          </div>
        </div>
        <div className="margin-help-bottom border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            AÑADIR
            <br />
            -Grupo de Trabajo
            <br />
            -Procesos de la organización.
          </div>
          <div className="help-min-conatiner green tipo-description">6</div>
        </div>
    
      </div>
    </div>
  );
};

export default CreateCompany;
