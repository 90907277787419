import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
const CreateAuditCompany = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">

  
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditor Líder</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-orange white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Crea Auditoria y carga documentación
          </div>
        </div>

        <div className="flex bg-white border-orange-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Adicione auditores de apoyo y participantes
evaluados
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Realice planeación detallada de auditoría

y descargue el plan.
          </div>
        </div>
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Coordine y apoye ejecución de auditoría
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Realice seguimiento detallado del avance de
auditoría en tiempo real
          </div>
        </div>
        <div className="margin-help-bottom border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Revise, ajuste y descargue el informe final
y lista de verificación.
          </div>
        </div>
      </div>
    
      
    </div>
  );
};

export default CreateAuditCompany;
