import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  filters: {
    generalTemplate: null,
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  speciality: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  modal: {
    template: {
      isOpen: false,
      template_id: null,
    },
  },
  errors: {
    error: [],
    status: false,
  },
};

export const TemplateIndexContext = React.createContext([]);

export const TemplateIndexContextProvider = ({ children }) => {
  let history = useHistory();

  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess, showErrors }] = useContext(AlertContext);
  const [template, setTemplate] = useState(initialState.template);
  const [speciality, setSpeciality] = useState(initialState.speciality);
  const [standard, setStandard] = useState(initialState.standard);
  const [errors, setErrors] = useState(initialState.errors);
  const [modalTemplate, setModalTemplate] = useState(initialState.modal.template);
  const [filter, setFilter] = useState(initialState.filters);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  // componteDidMointed & componentDidUpdated
  useEffect(() => {
    if (template.status === STATUS_NOT_LOADED) {
      client()
        .get(`security/person/${user.id}/templates`)
        .then(({ data }) => {
          if (data) {
            setTemplate({
              ...template,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantillas");
          }
        });
    }
  }, [template]);

  useEffect(() => {
    if (speciality.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/speciality`, {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setSpeciality({
              ...speciality,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [speciality]);

  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/standard`, {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setStandard({
              ...standard,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [standard]);
  //functions
  const createTemplate = ({ title, description, standard, specialities }) => {
    setTemplate({
      ...template,
      status: STATUS_LOADING,
    });
    let speciality = [];
    if (Array.isArray(specialities)) {
      speciality = specialities;
    } else {
      speciality.push(specialities);
    }

    client()
      .post(`audit/addTemplate`, {
        title: title,
        description: description,
        standard_id: standard.id,
        specialities: speciality,
        person_id: user.id,
        is_template: true,
        is_public: false,
        is_self_audit: false,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Plantilla creada satisfactoriamente");
          setTemplate({
            ...template,
            status: STATUS_NOT_LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear la plantilla");
        }
        setTemplate({
          ...template,
          status: STATUS_NOT_LOADED,
        });
      });
  };
  const editTemplate = ({ title, description, standard, specialities }) => {
    let speciality = [];
    if (Array.isArray(specialities)) {
      speciality = specialities;
    } else {
      speciality.push(specialities);
    }
    client()
      .post(`audit/updateTemplate/${modalTemplate.template_id}`, {
        title: title,
        description: description,
        standard_id: standard.id,
        specialities: speciality,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Plantilla editada satisfactoriamente");
          setTemplate({
            ...template,
            status: STATUS_NOT_LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la plantilla");
        }
      });
  };
  const cloneTemplate = (template_id) => {
    setTemplate({
      ...template,
      status: STATUS_LOADING,
    });
    client()
      .get(`audit/cloneTemplateToTemplate/${template_id}`)
      .then(({ data }) => {
        if (data) {
          alertSuccess("Copia creada satisfactoriamente");
          setTemplate({
            ...template,
            status: STATUS_NOT_LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al copiar la plantilla");
        }
      });
  };
  const deleteTemplate = (template_id) => {
    client()
      .delete(`audit/evaluation/${template_id}`)
      .then(({ data }) => {
        alertSuccess("Plantilla eliminada satisfactoriamente");
        setTemplate({
          ...template,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar la plantilla");
        }
      });
  };
  const filterGeneralTemplate = (param) => {
    let body = param;
    if (body == "") {
      body = null;
    }
    setFilter({ ...filter, generalTemplate: body });
  };
  const inEditModalTemplate = (template_id) => {
    setModalTemplate({ ...modalTemplate, template_id: template_id, isOpen: !modalTemplate.isOpen });
  };
  return (
    <TemplateIndexContext.Provider
      value={[
        { standard, template, speciality, modalTemplate },
        {
          createTemplate,
          editTemplate,
          cloneTemplate,
          deleteTemplate,
          inEditModalTemplate,
          filterGeneralTemplate,
        },
      ]}
    >
      {children}
    </TemplateIndexContext.Provider>
  );
};
