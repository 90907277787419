import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import clientImage from "../axios/axios-client-image";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const MODEL_AUDIT_FOLDER = `App\\Models\\AuditFolder`;
const MODEL_AUDIT = `App\\Models\\Audit`;
let initalState = {
  breadCrumbFolder: [],
  document: {
    status: STATUS_NOT_LOADED,
    fixed: false,
    score: null,
    element: {
      id: null,
    },
  },
  evaluations: [],
  modalNoteItem: {
    items_id: [],
    note_id: null,
    note: "",
  },
  notes: {
    elements: [],
    status: STATUS_NOT_LOADED,
  },
};

export const DocumentFormContext = React.createContext([]);

export const DocumentFormContextProvider = (params) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess, setErrors }] = useContext(AlertContext);
  let { children } = params;
  let location = useLocation();
  let history = useHistory();
  let [document, setdocument] = useState(initalState.document);
  let [evaluations, setevaluations] = useState(initalState.evaluations);
  let [notes, setnotes] = useState(initalState.notes);
  const [noteItem, setnoteItem] = useState(initalState.modalNoteItem);
  const [breadCrumbDocument, setbreadCrumbDocument] = useState(initalState.breadCrumbFolder);
  //manejo de errores

  useEffect(() => {
    async  function getDocument(params) {
    if (document.status === STATUS_NOT_LOADED && document.element.id !== null) {
      setdocument({ ...document, status: STATUS_LOADING });
     const pages=await getPagesDocument(document.element.id);
      client()
        .get(`library/document/${document.element.id}`, {
          params: {
            with: [
              "myNotes",
              "valorable",
              "myFavorite",
              "auditFolder.audit.evaluations.items.auditable_item",
              "audit.evaluations.items.auditable_item",
              "auditFolder.audit.evaluations.activity.auditParticipants.participantType",
              "audit.evaluations.activity.auditParticipants.participantType",
            ],
          },
        })
        .then(({ data }) => {
          if (
            data.documentable_type == MODEL_AUDIT_FOLDER ||
            data.documentable_type == MODEL_AUDIT
          ) {
          } else {
            data.audit = null;
            data.audit_folder = null;
          }
          if (data.id) {
            if (data.audit == null && data.audit_folder == null) {
              getBreadCrumb(data.id);
            }
            let valorable = null;
            if (data.valorable.length > 0) {
              let filter = data.valorable.filter((e) => e.person_id == user.id)[0];
              if (filter) {
                valorable = filter.value;
              }
            }

            setdocument({
              ...document,
              status: STATUS_LOADED,
              element: data,
              score: valorable,
              pages:pages,
              fixed: data.my_favorite != null ? data.my_favorite.favorite : false,
            });
            setnotes({
              status: STATUS_LOADED,
              elements: data.my_notes,
            });
          } else {
            history.push("/app/notfound");
          }
        });
    }
  }
  getDocument()
  }, [document]);
  let changeDocument = (document_id) => {
    setdocument({
      element: { id: document_id },
      status: STATUS_NOT_LOADED,
    });
  };
  let addScore = (score) => {
    let dataForm = {
      value: score,
      person_id: user.id,
    };
    client()
      .post(`library/document/${document.element.id}/dataValorable`, dataForm)
      .then(({ data }) => {
        if (data) {
          setdocument({
            ...document,
            status: STATUS_NOT_LOADED,
          });
        }
      });
  };
  let createNote = ({ note }, percentage) => {
    let dataForm = {
      note: note,
      percentage: percentage,
      person_id: user.id,
    };

    client()
      .post(`library/document/${document.element.id}/notificated`, dataForm)
      .then(({ data }) => {
        if (data) {
          setdocument({
            ...document,
            status: STATUS_NOT_LOADED,
          });
        }
      });
  };
  let updateNote = ({ note, id }, percentage) => {
    let dataForm = {
      note: note,
      percentage: percentage,
    };

    client()
      .post(`library/document/${document.element.id}/noteUpdate/${id}`, dataForm)
      .then(({ data }) => {
        if (data) {
          setdocument({
            ...document,
            status: STATUS_NOT_LOADED,
          });
        }
      });
  };
const getPagesDocument=async(document_id)=>{
  
  return client()
  .get(`getPagesDocument/${document_id}`)
  .then(({ data }) => {
    if (data) {
    return data
    }
  });

}
  let fixedDocument = () => {
    let dataForm = {
      favorite: !document.fixed,
      person_id: user.id,
    };

    client()
      .post(`library/document/${document.element.id}/updateView`, dataForm)
      .then(({ data }) => {
        if (data) {
          setdocument({
            ...document,
            status: STATUS_NOT_LOADED,
          });
        }
      });
  };
  const deleteNote = (id) => {
    client()
      .delete(`library/document/${document.element.id}/noteDelete/${id}`)
      .then(({ data }) => {
        setdocument({
          ...document,
          status: STATUS_NOT_LOADED,
        });
      });
  };
  const getBreadCrumb = (document_id) => {
    if (document_id != null) {
      client()
        .get(`library/breadCrumbDocument/${document_id}`)
        .then(({ data }) => {
          setbreadCrumbDocument(data);
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el breadcrumb");
          }
        });
    } else {
      setbreadCrumbDocument([]);
    }
  };
  const addNoteToItems = () => {
    let textNote=noteItem.note+" (Enviado a los siguientes items:";
    for (let i = 0; i < noteItem.items_id.length; i++) {
      const item = noteItem.items_id[i];
      textNote=textNote+","+item.auditable_item.number;
      var formData = new FormData();
      formData.append("note", noteItem.note);
      formData.append("person_id", user.id);

     client()
        .post(`evaluation/item/${item.id}/notificated`, formData)
        .then(({ data }) => {})
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el comentario");
          }
        }); 
    }
    
    client()
    .post(`library/document/${document.element.id}/noteUpdate/${noteItem.note_id}`, {note:textNote+")"})
    .then(({ data }) => {
      if (data) {
        setdocument({
          ...document,
          status: STATUS_NOT_LOADED,
        });
      }
    });
    alertSuccess("Notas añadida satisfactoriamente");
    setnoteItem({ items_id: [], note_id: null, note: "" });
  };
  return (
    <DocumentFormContext.Provider
      value={[
        { breadCrumbDocument, document, notes, noteItem, evaluations },
        {
          changeDocument,
          deleteNote,
          updateNote,
          addScore,
          fixedDocument,
          createNote,
          setnoteItem,
          setevaluations,
          addNoteToItems,
        },
      ]}
    >
      {children}
    </DocumentFormContext.Provider>
  );
};
