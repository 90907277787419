import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
export const TemplateBillContext = React.createContext({});
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
let initialState = {
  meta: {
    standard_id: null,
  },
  speciality: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const TemplateBillContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{ user }, { logout, removeOrAddPackageCart }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess }] = useContext(AlertContext);
  const [meta, setmeta] = useState(initialState.meta);
  const [template, settemplate] = useState(initialState.template);
  const [speciality, setspeciality] = useState(initialState.speciality);
  const [standard, setstandard] = useState(initialState.standard);

  let [errors, setErrors] = useState(initialState.errors);
  useEffect(() => {
    if (template.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/evaluation`, {
          params: {
            filters: { is_template: true, on_sale: true, is_self_audit: false },
            with: ["evaluationStandard", "specialities","valorable"],
          },
        })
        .then(({ data }) => {
          if (data) {
            settemplate({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el documento");
          }
        });
    }
  }, [template]);

  useEffect(() => {
    if (speciality.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/speciality`)
        .then(({ data }) => {
          if (data) {
            setspeciality({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las especialidades");
          }
        });
    }
  }, [speciality]);
  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/standard`)
        .then(({ data }) => {
          if (data) {
            setstandard({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las normas");
          }
        });
    }
  }, [standard]);
  const assingUserToTemplate = (template_id) => {
    var formData = new FormData();
    formData.append("evaluation_id", template_id);

    client()
      .post(`security/personAttachEvaluation/${user.id}`, formData)
      .then(({ data }) => {
        alertSuccess("Plantilla asignada en mis plantillas");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addToCart = (template) => {
    let route = `security/person/${template.id}/addToCartTemplate`;
    client()
      .post(route, {})
      .then(({ data }) => {
        removeOrAddPackageCart(data, false);
        alertSuccess("Paquete añadido al carrito");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir al carrito");
        }
      });
  };
  return (
    <TemplateBillContext.Provider
      value={[
        { template, speciality, standard },
        { assingUserToTemplate, addToCart },
      ]}
    >
      {children}
    </TemplateBillContext.Provider>
  );
};
