import React, { useState, useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import "../template/Form.css";
import iconPlus from "../../../img_zeus/icons_audit/ICONO-CREAR.png";
import iconDelete from "../../../img_zeus/icons_library/BOTON-ELIMINAR.png";
import iconEdit from "../../../img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import iconAcept from "../../../img_zeus/ICONO-ACEPTAR.png";
import iconCancel from "../../../img_zeus/ICONO-CANCELAR.png";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEdit,
  faWindowClose,
  faCheckSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { TemplateFormContext } from "../../../contexts/audit/TemplateContext/TemplateFormContext";
import { STATUS_LOADED } from "../../../contexts/audit/AuditContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const initialState = {
  stair: 0,
  item: {
    new_item: {
      title: "",
      standard_id: null,
      auditable_item_id: null,
    },
    item_edit: {
      id: null,
      title: "",
      auditable_item_id: null,
    },
    new_subItem: {
      id: null,
      title: "",
      auditable_item_id: null,
    },
  },
};
const Form = ({ computedMatch }) => {
  const [{ template }, { changeTemplate, addScore }] = useContext(
    TemplateFormContext
  );
  const { params } = computedMatch;
  const { template_id } = params;
  const { specialities, items } = template.element;

  const [newItem, setNewItem] = useState(initialState.newItem);
  const [stair, setstair] = useState(initialState.stair);

  useEffect(() => {
    changeTemplate(template_id);
  }, [template_id]);
  useEffect(() => {
    setstair(template.score);
  }, [template]);
  let mouseEnterStair = (stair) => {
    setstair(stair);
  };
  let mouseLeaveStair = () => {
    setstair(0);
  };
  return (
    <div>
      <nav
        aria-label="breadcrumb"
        className="flex-1 breadcrumb-show-standard cursor-action"
      >
        <ol className="breadcrumb black">
          <li className="breadcrumb-item">
            <Link to="/app/audit/templates">Plantillas</Link>
          </li>
          <li className="breadcrumb-item orange tipo-description">
            {template.element.title}
          </li>
        </ol>
      </nav>
      <div className=" back-gray-content">
        <div className="flex">
          <h3 className="tipo-title title-template-form flex-1">
            Plantilla {template.element.title}
          </h3>

          <div className="content-stair-template flex">
            {Array(5)
              .fill(1)
              .map((value, key) => {
                return (
                  <span
                    key={key}
                    onMouseEnter={() => {
                      template.score === null && mouseEnterStair(key + 1);
                    }}
                    onMouseLeave={() => {
                      template.score === null && mouseLeaveStair();
                    }}
                    onClick={() => addScore(key + 1)}
                    className={`flex-1 note-stair cursor-action  ${
                      key + 1 < stair || key + 1 == stair
                        ? "valorated"
                        : "no-valorated"
                    }`}
                  ></span>
                );
              })}
          </div>
        </div>
        <div className="bg-white content-white-items-template">
          <div className="flex">
            <div className="margin-control tipo-title flex-1">
              <label className="tipo-title">Nombre</label>
              <div className="input-basic-white">{template.element.title}</div>
            </div>
            <div className="margin-control tipo-title flex-1">
              <label className="">Descripción</label>
              <div className="input-basic-white">
                {template.element.description}
              </div>
            </div>
          </div>
          {template.status === STATUS_LOADED && (
            <div className="content-items-template">
              <Items
                template_id={template.element.id}
                items={template.element.items}
                first={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Items = ({ template_id, items, first }) => {
  const [
    { itemDeploy, template },
    { deleteItem, createItem, updateItem, setItemDeploy, setDeploy },
  ] = useContext(TemplateFormContext);
  const [{ user }, {}] = useContext(SecurityContext);
  const [stateNewItem, setstateNewItem] = useState(initialState.item.new_item);
  const [stateItemEdit, setstateItemEdit] = useState(
    initialState.item.item_edit
  );
  const [stateNewSubItem, setstateNewSubItem] = useState(
    initialState.item.new_subItem
  );

  useEffect(() => {
    setstateNewItem({
      ...stateNewItem,
      template_id: template_id,
    });
    setstateNewSubItem({
      ...stateNewSubItem,
      template_id: template_id,
    });
  }, [items]);
  const checkAddItem = () => {
    createItem(stateNewItem);
    setstateNewItem({
      ...stateNewItem,
      title: "",
    });
  };
  let setFieldNew_item = (field) => (e) => {
    setstateNewItem({
      ...stateNewItem,
      [field]: e.target.value,
    });
  };
  let setFieldItemEdit = (field) => (e) => {
    setstateItemEdit({
      ...stateItemEdit,
      [field]: e.target.value,
    });
  };
  let setFieldSubItemEdit = (field) => (e) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      [field]: e.target.value,
    });
  };

  let newEdit = (item_id, title, number) => {
    setstateItemEdit({
      ...stateItemEdit,
      id: item_id,
      title: title,
    });
  };
  let newSubItem = (item_id, title) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      id: item_id,
      title: title,
      auditable_item_id: item_id,
    });
  };
  useEffect(() => {
    if (stateNewSubItem.id !== null) {
      document.getElementById(stateNewSubItem.id).focus();
    }
  }, [stateNewSubItem]);

  useEffect(() => {
    if (stateItemEdit.id !== null) {
      document.getElementById(stateItemEdit.id).focus();
    }
  }, [stateItemEdit]);

  const confirmDelete = (item_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este item?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteItem(item_id);
      } else {
      }
    });
  };
  const keyEnterItem = () => (e) => {
    if (e.key === "Enter") {
      updateItem(stateItemEdit);
      newEdit(null, "");
    }
  };
  const keyEnterNewItem = () => (e) => {
    if (e.key === "Enter") {
      createItem(stateNewSubItem);
      newSubItem(null, "");
    }
  };
  return (
    items &&
    items.map((item) => {
      return (
        <ol key={item.id}>
          <li className={`${itemDeploy["item" + item.id] ? "dotted-li" : ""}`}>
            <div
              className={`flex content-item-column ${
                first ? `content-item-orange white` : `bg-gray-2`
              } `}
            >
              <div className="container-circle-item flex">
                {item.items.length > 0 && (
                  <div
                    onClick={() => setDeploy(item.id)}
                    className={`${
                      itemDeploy["item" + item.id]
                        ? "circle-item-down-gray"
                        : "circle-item-up-gray"
                    } circle-number-items`}
                  >
                    {" "}
                    {item.items.length}
                  </div>
                )}
              </div>

              <div className="flex-1">
                <div className="flex">
                  {stateItemEdit.id !== item.id && (
                    <>
                      {item.auditable_item_id !== null && (
                        <div className="number-item-content">
                          {item.auditable_item !== null && (
                            <p className="">{item.auditable_item.number}</p>
                          )}
                        </div>
                      )}

                      <div className="flex-1">
                        <p className="p-item-description">{item.title}</p>
                      </div>
                    </>
                  )}
                  {/* edit item */}
                  {stateItemEdit.id === item.id && (
                    <div className="flex-1 container-new-input-item">
                      <input
                        onChange={setFieldItemEdit("title")}
                        onKeyDown={keyEnterItem()}
                        id={item.id}
                        value={stateItemEdit.title}
                        className="input-basci-white form-control"
                        type="text"
                      ></input>
                    </div>
                  )}
                  {/* end edit item */}
                </div>
              </div>
              <div className="container-actions-item flex">
                <div className="container-margin-actions-item flex">
                  {/* buttons edit */}
                  {stateItemEdit.id === item.id && (
                    <>
                      &nbsp; &nbsp;
                      <div
                        onClick={() => {
                          newEdit(null, "");
                        }}
                        className="cursor-action background-simple btn-cancel-black"
                      />
                      &nbsp;
                    </>
                  )}
                  {/* end buttons edit */}
                  {stateItemEdit.id !== item.id && template.element.person_id === user.id &&(
                    <>
                      {item.auditable_item_id !== null && (
                        <>
                          {item.auditable_item !== null && (
                            <>
                              {item.auditable_item.required && (
                                <div
                                  onClick={() => {
                                    newSubItem(item.id, "");
                                  }}
                                  className="cursor-action background-simple btn-more-black"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                      {template.element.person_id === user.id && (
                        <>
                          <div
                            onClick={() => {
                              newEdit(item.id, item.title, item.number);
                            }}
                            className="cursor-action background-simple btn-edit-black"
                          />

                          <div
                            className="cursor-action background-simple btn-delete-black"
                            onClick={() => confirmDelete(item.id)}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*    new item */}
            {stateNewSubItem.id === item.id && (
              <ol>
                <li>
                  <div
                    className={`flex content-item-column  content-item-new-column`}
                  >
                    <div className="flex-1">
                      <div className="flex">
                        <div className="flex-1 container-new-input-item">
                          <input
                            onKeyDown={keyEnterNewItem()}
                            onChange={setFieldSubItemEdit("title")}
                            value={stateNewSubItem.title}
                            id={item.id}
                            className="input-basci-white form-control"
                            placeholder="Titulo"
                            type="text"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="container-actions-item flex">
                      <div className="container-margin-actions-item-standard flex flex-1">
                        <div
                          className="cursor-action flex icon-cancel-item"
                          onClick={() => {
                            newSubItem(null, "");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            )}
            {/* end new item */}

            {itemDeploy["item" + item.id] && (
              <Items
                template_id={template_id}
                items={item.items && item.items}
                first={false}
              />
            )}
          </li>
        </ol>
      );
    })
  );
};
export default Form;
