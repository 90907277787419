import React, { createContext, useContext, useState, useEffect } from "react";
import client from "../axios/axios-client";
import { SecurityContext, STATUS_LOADED, STATUS_LOADING } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
let initialState = {
  errors: {
    error: [],
    status: false,
  },
  contact: {
    status: STATUS_LOADED,
  },
};

export const HelpContext = React.createContext([]);

export const HelpContextProvider = ({ children }) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, showErrors }] = useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [contact, setcontact] = useState(initialState.contact);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  const setNumber = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  const sendContact = (formData) => {
    setcontact({ ...contact, status: STATUS_LOADING });
    client()
      .post(`security/person/${user.id}/contacted`, formData)
      .then(({ data }) => {
        if (data) {
          setcontact({ ...contact, status: STATUS_LOADED });
          let number = setNumber(data.id, 5) + moment(data.created_at).format("YYYYMMDD");
          Swal.fire({
            title: "¡Gracias por ponerte en contacto! ",
            text: "¡Uno de nuestros consultores se pondrá en contacto pronto! ID:" + number,
            icon: "success",
            confirmButtonColor: "#df8c37",
            confirmButtonText: "¡Entendido!",
          }).then((result) => {
            history.goBack();
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar el mensaje");
        }
      });
  };

  return (
    <HelpContext.Provider value={[{ contact }, { sendContact }]}>{children}</HelpContext.Provider>
  );
};
