import React, { useState, useEffect, useContext } from "react";
import {
  STATUS_LOADED,
  AuditPlanFormContext,
} from "../../../contexts/audit/AuditPlanFormContext";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import "./ViewActivity.css";
const initialState = {
  item: {
    audit_id: "",
    name: "",
    description: "",
    init_date: new Date(),
    end_date: new Date(),
    location: "",
    audit_participants: [],
  },
};

const ViewActivity = ({ activity_id }) => {
  let [stateView, setStateView] = useState(initialState);
  const [{ audit }, {}] = useContext(AuditPlanFormContext);

  useEffect(() => {
    if (activity_id && audit.status === STATUS_LOADED) {
      let el = audit.originalItem.activities.filter(
        (e) => e.id === activity_id
      )[0];
      let itemsOrigin = [...el.activity_items];
      let arrayDelete = [];
      for (let i = 0; i < itemsOrigin.length; i++) {
        const item = itemsOrigin[i];
        if (item.items.length > 0) {
          recursiveItems(arrayDelete, item.items);
        }
      }

      for (let d = 0; d < arrayDelete.length; d++) {
        const itemDelete = arrayDelete[d];
        for (let i = 0; i < itemsOrigin.length; i++) {
          const item = itemsOrigin[i];
          if (item.id == itemDelete.id) {
            itemsOrigin.splice(i, 1);
          }
        }
      }

      setStateView({
        ...stateView,
        item: {
          audit_id: el.audit_id,
          name: el.name,
          is_evaluable: el.is_evaluable,
          description: el.description,
          init_date: new Date(el.init_date),
          end_date: new Date(el.end_date),
          audit_participants: el.audit_participants,
          location: el.location,
          process: el.process,
          activity_items: itemsOrigin,
        },
      });
    }
  }, [activity_id, audit]);
  const recursiveItems = (arrayDelete, items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      arrayDelete.push(item);
      if (item.items.length > 0) {
        arrayDelete = recursiveItems(arrayDelete, item.items);
      }
    }
    return arrayDelete;
  };
  const getDuration = (activity) => {
    let hours = moment
      .duration(moment(activity.end_date).diff(moment(activity.init_date)))
      .asHours();
    if (hours < 1) {
      return (
        Math.round(
          moment
            .duration(
              moment(activity.end_date).diff(moment(activity.init_date))
            )
            .asMinutes()
        ) + " minutos"
      );
    } else {
      return Math.round(hours) + " horas";
    }
  };
  return (
    <div className="view-activity">
      <div className="content-activity">
        <div className="flex w-100">
          <div
            className={`${
              stateView.item.is_evaluable
                ? "icon-evaluable-activity-show"
                : "icon-not-evaluable-activity-show"
            }`}
          ></div>
        </div>
        <div className="form-group div-form-activity-date">
          <label className="tipo-title">Actividad:</label>
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={stateView.item.name}
            className="form-control tipo-description"
          >
            {stateView.item.name}
          </div>
        </div>
        <div className="form-group div-form-activity-date">
          <label>Duración:</label>
          <div className="form-control tipo-description">
            {getDuration(stateView.item)}
          </div>
        </div>
        <div className="form-group div-form-activity">
          <label className="tipo-title">Descripción:</label>
          <textarea
            defaultValue={stateView.item.description}
            className="form-control tipo-description"
            rows="3"
            disabled
          ></textarea>
        </div>
        <div className="form-group div-form-activity-date">
          <label className="tipo-title">Fecha de ejecución:</label>
          <DateTimePicker
            value={stateView.item.init_date}
            className="form-control tipo-description"
            format="yy-MM-dd HH:mm"
            disabled
          />
        </div>
        <div className="form-group div-form-activity-date">
          <label className="tipo-title">Fecha de finalización:</label>
          <DateTimePicker
            value={stateView.item.end_date}
            className="form-control tipo-description"
            format="yy-MM-dd HH:mm"
            disabled
          />
        </div>
        <div className="form-group div-form-activity">
          <label className="tipo-title">Ubicación:</label>
          <div className="form-control tipo-description">
            {stateView.item.location}
          </div>
        </div>
        <div className="form-group div-form-activity">
          <label className="tipo-title">Proceso:</label>
          <div className="form-control tipo-description">
            {stateView.item.process && stateView.item.process.name}
          </div>
        </div>
        <div className="form-group div-form-activity">
          <label className="tipo-title">Evaluadores:</label>
          <table className="table txt-center table-hover table-bordered">
            <thead className="thead-activities tipo-title">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Rol</th>
              </tr>
            </thead>
            <tbody>
              {stateView.item.audit_participants
                .filter(
                  (p) => p.participant_type.is_audit && p.pivot.is_evaluator
                )
                .map((par) => (
                  <tr key={par.id}>
                    <td className="tipo-description">
                      {par.person.first_name} {par.person.last_name}
                    </td>
                    <td className="tipo-description">
                      {par.participant_type.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="form-group div-form-activity">
          <label className="tipo-title">Evaluados:</label>
          <table className="table txt-center table-hover table-bordered">
            <thead className="thead-activities tipo-title">
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Rol</th>
              </tr>
            </thead>
            <tbody>
              {stateView.item.audit_participants
                .filter((p) => !p.participant_type.is_audit)
                .map((par) => (
                  <tr key={par.id}>
                    <td className="tipo-description">
                      {par.person.first_name} {par.person.last_name}
                    </td>
                    <td className="tipo-description">
                      {par.participant_type.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {stateView.item.is_evaluable && (
          <div className="form-group div-form-activity">
            <label className="tipo-title">Items a evaluar:</label>
            &nbsp;
            {stateView.item.activity_items
              .sort(
                (a, b) =>  parseInt(a.number) - parseInt(b.number)
              )
              .map((item, index) => (
                <span key={item.id} className="tipo-description">
                  <>
                    {index != 0 && <span>| &nbsp;</span>}
                    {item.number}&nbsp;
                  </>
                </span>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewActivity;
