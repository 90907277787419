import React, { useContext, useState, useEffect } from "react";
import Select, { components } from "react-select";
import { TemplateIndexContext } from "../../../contexts/audit/TemplateContext/TemplateIndexContext";
import { Form, Field } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const STATUS_LOADING = "LOADING";
const STATUS_NOT_LOADED = "NOT_LOADED";
const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
const STATUS_LOGGED_IN = "LOGGED_IN";
const STATUS_LOADED = "STATUS_LOADED";
const initialState = {
  newTemplate: {
    title: "",
    description: "",
    standard: [],
    specialities: [],
  },
};

const CreateTemplate = () => {
  const [
    { template, standard, speciality, modalTemplate },
    { createTemplate, editTemplate, inEditModalTemplate },
  ] = useContext(TemplateIndexContext);

  const [newTemplate, setNewTemplate] = useState(initialState.newTemplate);

  useEffect(() => {
    if (
      template.status === STATUS_LOADED &&
      modalTemplate.template_id !== null
    ) {
      //cuando se va editar un template
      let filter = template.elements.filter(
        (e) => e.id === modalTemplate.template_id
      )[0];
      let specialities = [];
      for (let i = 0; i < filter.specialities.length; i++) {
        specialities.push(filter.specialities[i].speciality);
      }
      setNewTemplate({
        ...newTemplate,
        description: filter.description,
        standard: filter.evaluation_standard,
        title: filter.title,
        specialities: specialities,
      });
    }
  }, [template, modalTemplate]);

  const required = (value) => (value ? undefined : "Requerido");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#ECAE70" : "white",
      cursor: state.isDisabled ? "not-allowed" : "default",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <Form
      onSubmit={(formObject) => {
        if (modalTemplate.template_id !== null) {
          editTemplate(formObject);
        } else {
          createTemplate(formObject);
        }
        inEditModalTemplate(null);
      }}
      initialValues={newTemplate}
    >
      {({ handleSubmit }) => (
        <div className=" modal-create-template shadow-tarjet">
          <form onSubmit={handleSubmit}>
            <div className="w-100 header-modal-create-evaluation">
              <div className="w-100 flex">
                <div
                  onClick={() => {
                    inEditModalTemplate(null);
                  }}
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="txt-center txt-title-new-folder tipo-description">
                  {modalTemplate.template_id !== null
                    ? "Editar Plantilla"
                    : "Nueva plantilla"}
                </p>
              </div>
            </div>
            <div className="bg-white content-modal-create-template">
              <div className="">
                <div className="flex">
                  <div className="flex-1 margin-control">
                    <Field name="title" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Título</label>

                          <input
                            placeholder="título de la plantilla"
                            className="form-control tipo-description"
                            type="text"
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="flex-1 margin-control">
                    <Field name="description" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Descripción</label>
                          <textarea
                            placeholder="descripción de la plantilla"
                            className="form-control tipo-description"
                            {...input}
                            type="text"
                          />
                          {meta.touched && meta.error && (
                            <span className="orange tipo-cursiva">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 margin-control">
                    <Field name="standard" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Norma</label>

                          <Select
                            {...input}
                            placeholder={"Seleccione una norma"}
                            getOptionLabel={(option) =>
                              `${option.short_name}  `
                            }
                            getOptionValue={(option) => option.id}
                            isOptionDisabled={(option) => option.cost != 0}
                            options={standard.elements}
                            styles={customStyles}
                            className="tipo-description"
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 margin-control">
                    <Field name="specialities" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">
                            Especialidades
                          </label>
                          <Select
                            className="tipo-description"
                            styles={customStyles}
                            options={speciality.elements}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => `${option.id}`}
                            placeholder="Seleccione las especialidades"
                            {...input}
                          />

                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginBottom: "10px" }}
              className="flex justify-end w-100"
            >
              <button
                className="btn-orange-default"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
      )}
    </Form>
  );
};

export default CreateTemplate;
