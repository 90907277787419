import React, { useEffect, useState, useContext } from "react";
import "./Form.css";
import {
  ITEM_SUR,
  ITEM_SMMR,
  ITEM_QS,
  ITEM_QL,
  ITEM_DEBE,
  STATUS_LOADING,
  InspectionFormContext,
  ITEM_UFILE,
  ITEM_MFILE,
  ITEM_NUMBER,
  ITEM_INTEGER,
  ITEM_DATE,
  ITEM_DATETIME,
  STATUS_NOT_LOADED,
} from "../../../contexts/evaluation/InspectionFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import Toggle from "react-toggle";
import {
  faChevronUp,
  faTimes,
  faTimesCircle,
  faChevronDown,
  faMinusCircle,
  faEdit,
  faBook,
  faCheck,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import Select, { components } from "react-select";
import loadingImage from "../../../img_zeus/loading.gif";
import Draggable from "react-draggable";
import moment from "moment";
import DatePicker from "react-datepicker";
import { STATUS_EXECUTED } from "../../../constant/contant";
registerLocale("es", es);
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";

const EXT_DOCX = "docx";
const EXT_DOC = "doc";

const EXT_XLSX = "xlsx";
const EXT_XLS = "xls";
const ROLE_PQRS_COMPANY = "PQRS_COMPANY";
const ROLE_PQRS_USER = "PQRS_USER";

const initialState = {
  newResponse: {
    response: {
      item_id: null,
      option_id: "",
    },
    picture: {
      image: [],
      item_id: null,
    },
    note: {
      note: "",
      item_id: null,
    },
    commentEdit: {
      note_id: null,
      note: "",
      item_id: null,
    },
  },
};
const validateCalificable = (results, option) => {
  if (results.length > 0) {
    if (results[0].is_valid == true && option.is_valid == true) {
      return "icon-cumple-evaluation";
    } else if (results[0].is_valid == false && option.is_valid == false) {
      return "icon-no-cumple-evaluation";
    } else if (results[0].is_valid == null && option.is_valid == null) {
      return "icon-no-aplica-evaluation";
    } else {
      if (option.is_valid == true) {
        return "icon-cumple-evaluation-gray";
      } else if (option.is_valid == false) {
        return "icon-no-cumple-evaluation-gray";
      } else if (option.is_valid == null) {
        return "icon-no-aplica-evaluation-gray";
      } else {
        return "icon-no-calificable-evaluation-dark";
      }
    }
  } else {
    if (option.is_valid == true) {
      return "icon-cumple-evaluation-gray";
    } else if (option.is_valid == false) {
      return "icon-no-cumple-evaluation-gray";
    } else if (option.is_valid == null) {
      return "icon-no-aplica-evaluation-gray";
    } else {
      return "icon-no-calificable-evaluation-dark";
    }
  }
};
const Form = ({ computedMatch: { params, url } }) => {
  let { evaluation_id, long_id } = params;
  let history = useHistory();
  const [
    {
      inspection,
      itemSelected,
      inEvaluation,
      note,
      textFirstDebe,
      codeItemTypeNew,
      itemTypes,
    },
    {
      setinspection,
      addNewItem,
      setitemSelected,
      setinEvaluation,
      checkDeleteItem,
      saveList,
      setNote,
      settextFirstDebe,
      validateStatusIspection,
      getInspectionWithLong,
      setFieldInspection,
      updateInspection,
      setcodeItemTypeNew,
    },
  ] = useContext(InspectionFormContext);
  useEffect(() => {
    if (inspection.evaluation_id != evaluation_id) {
      setinspection({
        ...inspection,
        evaluation_id: evaluation_id,
        status: STATUS_NOT_LOADED,
      });
    }
  }, [evaluation_id]);
  useEffect(() => {
    if (long_id != null) {
      getInspectionWithLong(long_id);
    }
  }, [long_id]);
  const canEditEvaluation = () => {
    if (inspection.element.parent_evaluation_id === null) {
      if (inspection.element.owners.length > 0) {
        if (inspection.element.owners[0].role !== ROLE_PQRS_USER) {
          return true;
        }
      } else {
        return true;
      }
    }
  };
  return (
    <div className="container">
      <br />
      <nav aria-label="breadcrumb" className="flex-1 cursor-action">
        <ol className="breadcrumb">
          <li
            className="breadcrumb-item"
            onClick={() =>
              inspection.element.owners.length > 0 &&
              (inspection.element.owners[0].role === ROLE_PQRS_COMPANY ||
                inspection.element.owners[0].role === ROLE_PQRS_USER)
                ? history.goBack()
                : history.push("/app/inspection/evaluations")
            }
          >
            {inspection.status == STATUS_LOADED &&
            inspection.element.owners.length > 0 &&
            (inspection.element.owners[0].role === ROLE_PQRS_COMPANY ||
              inspection.element.owners[0].role === ROLE_PQRS_USER)
              ? "Atrás"
              : "Inspeccciones"}
          </li>

          <li className="breadcrumb-item ">
            <Link
              className="orange"
              to={`/app/inspection/evaluation/${inspection.element.id}`}
            >
              {inspection.element.title}
            </Link>
          </li>
        </ol>
      </nav>
      <div className="w-100 general-content-inspection-form">
        <div className="content-inspection flex">
          <div className="container-tarjets-items flex-1">
            <div className="flex justify-betwen  ">
              {inEvaluation && canEditEvaluation() && (
                <div
                  onClick={() => setinEvaluation(!inEvaluation)}
                  className="btn-orange-default"
                >
                  Editar
                </div>
              )}
              {!inEvaluation &&
                inspection.element.parent_evaluation_id === null && (
                  <>
                    <div
                      onClick={() => {
                        setinEvaluation(!inEvaluation);
                        setitemSelected([]);
                      }}
                      className="btn-orange-default"
                    >
                      Evaluación
                    </div>
                  </>
                )}
              {!inspection.element.parent_evaluation_id &&
                inspection.element.is_self_audit && (
                  <Link
                    className="btn-orange-default"
                    to={`/app/inspection/evaluation/${inspection.element.id}/analytic`}
                  >
                    Analitica
                  </Link>
                )}
            </div>

            <div className="title-tarjet-inspection flex-column">
              <div className="border-top-tarjet-inspection bg-orange"></div>
              <div className="content-title-inspect">
                {!inEvaluation && (
                  <div className="form-group-zeus">
                    <input
                      onBlur={(e) => {
                        updateInspection(inspection.element.id, {
                          title: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          updateInspection(inspection.element.id, {
                            title: e.target.value,
                          });
                        }
                      }}
                      value={inspection.element.title}
                      onChange={(e) =>
                        setFieldInspection("title", e.target.value)
                      }
                      type="text"
                      name="text"
                      autoComplete="off"
                      required
                    />
                    <label className="label-name-zeus">
                      <span className="content-name-zeus">Título</span>
                    </label>
                  </div>
                )}
                {inEvaluation && (
                  <p className="tipo-title">{inspection.element.title}</p>
                )}
                {inEvaluation && (
                  <p className="tipo-description">
                    {inspection.element.description}
                  </p>
                )}

                {!inEvaluation && (
                  <div className="form-group-zeus">
                    <input
                      onBlur={(e) => {
                        updateInspection(inspection.element.id, {
                          description: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          updateInspection(inspection.element.id, {
                            description: e.target.value,
                          });
                        }
                      }}
                      value={inspection.element.description}
                      onChange={(e) =>
                        setFieldInspection("description", e.target.value)
                      }
                      type="text"
                      name="text"
                      autoComplete="off"
                      required
                    />
                    <label className="label-name-zeus">
                      <span className="content-name-zeus">Descripción</span>
                    </label>
                  </div>
                )}
                {!inEvaluation && (
                  <div className="flex justify-betwen">
                    <div>
                      {" "}
                      <label htmlFor="">Respuestas por usuario:</label>
                      <div
                        className="form-group-zeus"
                        style={{ width: "auto" }}
                      >
                        <input
                          onBlur={(e) => {
                            updateInspection(inspection.element.id, {
                              response_for_user: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateInspection(inspection.element.id, {
                                response_for_user: e.target.value,
                              });
                            }
                          }}
                          value={inspection.element.response_for_user}
                          onChange={(e) =>
                            setFieldInspection(
                              "response_for_user",
                              e.target.value
                            )
                          }
                          type="text"
                          name="text"
                          autoComplete="off"
                          required
                        />
                        <label className="label-name-zeus">
                          <span className="content-name-zeus">
                            Respuestas por usuario
                          </span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <label htmlFor="">Límite de uso:</label>
                      <div
                        className="form-group-zeus"
                        style={{ width: "auto" }}
                      >
                        <input
                          onBlur={(e) => {
                            updateInspection(inspection.element.id, {
                              description: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateInspection(inspection.element.id, {
                                max_use: e.target.value,
                              });
                            }
                          }}
                          value={inspection.element.max_use}
                          onChange={(e) =>
                            setFieldInspection("max_use", e.target.value)
                          }
                          type="text"
                          name="text"
                          autoComplete="off"
                          required
                        />
                        <label className="label-name-zeus">
                          <span className="content-name-zeus">
                            Límite de uso:
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {inspection.status === STATUS_LOADED && (
              <>
                {!inEvaluation && (
                  <>
                    <hr />
                    <br />
                    <div className="flex">
                      <div className="form-group-zeus">
                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              addNewItem(null, codeItemTypeNew, e.target.value);
                            }
                          }}
                          value={textFirstDebe}
                          onChange={(e) => settextFirstDebe(e.target.value)}
                          type="text"
                          name="text"
                          autoComplete="off"
                          required
                        />
                        <label className="label-name-zeus">
                          <span className="content-name-zeus">
                            Nueva pregunta
                          </span>
                        </label>
                      </div>
                      &nbsp;
                      <Select
                        value={
                          itemTypes.elements.filter(
                            (t) => t.code == codeItemTypeNew
                          )[0]
                        }
                        onChange={(e) => setcodeItemTypeNew(e.code)}
                        className="tipo-description select-zeus-analyze select-inspecion-form select-responsable-analyze"
                        placeholder={"Todos los tipo"}
                        getOptionLabel={(optio) => `${optio.name}`}
                        getOptionValue={(optio) => optio.id}
                        options={itemTypes.elements}
                      />
                    </div>
                    <br />
                  </>
                )}

                <Items
                  first={true}
                  parentItem_indexs={[]}
                  items={inspection.element.items}
                />
                <br />
                {inspection.element.parent_evaluation_id &&
                  inspection.element.status_id === STATUS_EXECUTED && (
                    <span>Finalizado</span>
                  )}
                {inspection.element.parent_evaluation_id &&
                  inspection.element.status_id !== STATUS_EXECUTED && (
                    <div
                      onClick={() => validateStatusIspection()}
                      style={{ maxWidth: "100px" }}
                      className="btn-orange-default"
                    >
                      Finalizar
                    </div>
                  )}
              </>
            )}
          </div>

          <div className="container-add-question-general flex">
            {!inEvaluation && (
              <div className="container-add-question-inspection flex-column margin-auto">
                <FontAwesomeIcon
                  className="cursor-action"
                  onClick={() => addNewItem(null, ITEM_DEBE, "")}
                  icon={faPlusCircle}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const Items = ({ items, first, parentItem_indexs }) => {
  const [
    {
      inspection,
      inEvaluation,
      itemSelected,
      itemDeploy,
      note,
      notes,
      showNote,
      noteEdit,
    },
    {
      setitemSelected,
      checkDeleteItem,
      addNewItem,
      setFieldItem,
      setItemDeploy,
      setNotes,
      setNote,
      setShowNote,
      setnoteEdit,
      resetNewNote,
      validateDelete,
      updateNoteItem,
      checkAddNote,
      checkDelete,
      updateItem,
    },
  ] = useContext(InspectionFormContext);

  let history = useHistory();
  useEffect(() => {
    if (note.item_id !== null) {
      let filter = items.filter((e) => e.id == note.item_id)[0];

      if (filter) {
        setNotes(filter.notes);
      }
    }
  }, [note.item_id, items]);
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  const setDeploy = (parentsWithIndex) => {
    let code = JSON.stringify(parentsWithIndex);
    if (itemDeploy["item" + code]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + code]: !itemDeploy["item" + code],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + code]: true });
    }
  };
  const checkEditItem = (parentItem_indexs, index) => {
    if (
      JSON.stringify(itemSelected) ===
      JSON.stringify([...parentItem_indexs, index])
    ) {
      setitemSelected([]);
    } else {
      setitemSelected([...parentItem_indexs, index]);
    }
  };
  return (
    <>
      <div>
        {items.map((item, index) => (
          <ol
            key={index}
            style={{ cursor: "auto" }}
            className={` ${first ? "panel-cause-analyze" : ""}`}
          >
            {!first && <hr className="hr-inspection-form" />}
            <li>
              <div className="flex">
                <div className="container-circle-item flex">
                  {item.items.length > 0 && (
                    <div
                      onClick={() => setDeploy([...parentItem_indexs, index])}
                      className={`${
                        itemDeploy[
                          "item" + JSON.stringify([...parentItem_indexs, index])
                        ]
                          ? "circle-item-down-gray"
                          : "circle-item-up-gray"
                      } circle-number-items`}
                    >
                      {" "}
                      {item.items.length}
                    </div>
                  )}
                </div>

                <div className="content-into-item-inspection flex-1 flex-column">
                  {!inEvaluation && (
                    <div className="flex justify-betwen">
                      <div className="fflex">
                        {item.can_edit && (
                          <div className="tipo-description">
                            <ITEMSELECT
                              parentItem_indexs={parentItem_indexs}
                              index={index}
                              item={item}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex form-group">
                        <div>
                          <label htmlFor="">Obligatorio</label>
                          &nbsp;
                        </div>
                        <Toggle
                          checked={item.is_required}
                          name="is_required"
                          className="margin-auto mobile-hidden"
                          value="yes"
                          title={"Requerido"}
                          aria-label={"Requerido"}
                          onChange={(e) => {
                            setFieldItem(
                              "is_required",
                              !item.is_required,
                              item
                            );
                            updateItem(
                              { is_required: !item.is_required },
                              item.id
                            );
                          }}
                        />
                      </div>
                      {(item.item_type.code === ITEM_NUMBER ||
                        item.item_type.code === ITEM_INTEGER) && (
                        <>
                          <div
                            style={{ maxWidth: "103px" }}
                            className="form-group-zeus"
                          >
                            <input
                              onBlur={(e) =>
                                updateItem({ min: item.min }, item.id)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  updateItem({ min: item.min }, item.id);
                                }
                              }}
                              onChange={(e) =>
                                setFieldItem("min", e.target.value, item)
                              }
                              type="text"
                              name="text"
                              value={item.min}
                              autoComplete="off"
                              required
                            />
                            <label className="label-name-zeus">
                              <span className="content-name-zeus">
                                valor minimo
                              </span>
                            </label>
                          </div>
                          <div
                            style={{ maxWidth: "103px" }}
                            className="form-group-zeus"
                          >
                            <input
                              onBlur={(e) =>
                                updateItem({ max: item.max }, item.id)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  updateItem({ max: item.max }, item.id);
                                }
                              }}
                              onChange={(e) =>
                                setFieldItem("max", e.target.value, item)
                              }
                              type="text"
                              name="text"
                              value={item.max}
                              autoComplete="off"
                              required
                            />
                            <label className="label-name-zeus">
                              <span className="content-name-zeus">
                                valor máximo
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="flex">
                    {inEvaluation && (
                      <div
                        style={{ marginRight: "8px" }}
                        className="form-group-zeus"
                      >
                        <p style={{ marginBottom: "2px" }}>{item.title}</p>
                      </div>
                    )}
                    {!inEvaluation && (
                      <div
                        style={{ marginRight: "8px" }}
                        className="form-group-zeus"
                      >
                        <input
                          onBlur={(e) =>
                            updateItem({ title: item.title }, item.id)
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateItem({ title: item.title }, item.id);
                            }
                          }}
                          onChange={(e) =>
                            setFieldItem("title", e.target.value, item)
                          }
                          value={item.title}
                          type="text"
                          name="text"
                          autoComplete="off"
                          required
                        />
                        <label className="label-name-zeus">
                          <span className="content-name-zeus">
                            Nueva pregunta
                          </span>
                        </label>
                      </div>
                    )}

                    {(item.item_type.code != null
                      ? item.item_type.code
                      : item.item_type.item_type.code) === ITEM_DEBE && (
                      <DEBE
                        index={index}
                        parentItem_indexs={[...parentItem_indexs]}
                        first={first}
                        item={item}
                      />
                    )}
                    {!inEvaluation && (
                      <div className="container-actions-item flex">
                        <div className="container-margin-actions-item flex">
                          {JSON.stringify(itemSelected) !==
                            JSON.stringify([...parentItem_indexs, index]) && (
                            <div
                              className="btn-more-black background-simple"
                              onClick={() => {
                                addNewItem(item.id, ITEM_DEBE);
                              }}
                            />
                          )}
                          {item.can_edit && (
                            <div
                              onClick={() =>
                                checkDeleteItem(parentItem_indexs, index)
                              }
                              className="background-simple btn-delete-black"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_SMMR && (
                    <SMMR
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}

                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_SUR && (
                    <SUR
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_QS && (
                    <QS
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_NUMBER && (
                    <NUMBER
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_DATE && (
                    <ITEMDATE
                      time={false}
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_DATETIME && (
                    <ITEMDATE
                      time={true}
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_INTEGER && (
                    <INTEGER
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_QL && (
                    <QL
                      index={index}
                      parentItem_indexs={[...parentItem_indexs]}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_MFILE && (
                    <ITEMFILE
                      checkDelete={checkDelete}
                      index={index}
                      isMulti={true}
                      first={first}
                      item={item}
                    />
                  )}
                  {(item.item_type.code
                    ? item.item_type.code
                    : item.item_type.item_type.code) === ITEM_UFILE && (
                    <ITEMFILE
                      checkDelete={checkDelete}
                      index={index}
                      isMulti={false}
                      first={first}
                      item={item}
                    />
                  )}
                  {inEvaluation && (
                    <div className="flex">
                      {item.documents.map((document, indexDoc) => {
                        return (
                          <Document
                            indexDocumentable={index}
                            indexDocument={indexDoc}
                            isOption={false}
                            item={item}
                            document={document}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="general-content-evaluation-show">
                {note.item_id !== null && (
                  <Draggable handle=".handle">
                    <div
                      className={`shadow-tarjet ${
                        showNote !== "" ? "bg-gray-note-show" : ""
                      }container-observer-evaluation-desktop container-observer-evaluation flex`}
                    >
                      <div className="flex-wrap w-100">
                        {/* ver nota */}
                        {showNote !== "" && (
                          <div className="flex-1 flex">
                            <div
                              onClick={() => setShowNote("")}
                              className="icon-back-note"
                            ></div>
                            <p
                              onClick={() => setShowNote("")}
                              className="tipo-description cursor-action"
                            >
                              {" "}
                              Atrás
                            </p>
                          </div>
                        )}

                        <div className="show-note-text-item flex-1 tipo-description">
                          {showNote}
                        </div>
                        {/* ver notas */}
                        {showNote === "" && (
                          <>
                            <div className="w-100 container-notes-observer">
                              <div className="handle container-close-note flex justify-end">
                                <span
                                  onClick={() => resetNewNote()}
                                  className="cursor-action"
                                >
                                  x
                                </span>{" "}
                              </div>
                              <div className="content-notes-evaluation">
                                {notes && (
                                  <>
                                    {notes &&
                                      notes.map((note) => {
                                        return (
                                          <div
                                            /*  onClick={() => setShowNote(note.note)} */
                                            className="bg-white  tipo-description note-evaluation-item flex flex-column"
                                            key={note.id}
                                          >
                                            <div className="flex tipo-cursiva date-note-desktop-item">
                                              <span>
                                                {moment(note.created_at).format(
                                                  "YYYY-MM-DD HH:mm a"
                                                )}
                                              </span>

                                              {inspection.status ==
                                                STATUS_LOADED && (
                                                <div className="flex">
                                                  {noteEdit.id != note.id && (
                                                    <FontAwesomeIcon
                                                      onClick={() =>
                                                        validateDelete(note.id)
                                                      }
                                                      className="flex"
                                                      title="Eliminar"
                                                      icon={faMinusCircle}
                                                    />
                                                  )}
                                                  &nbsp;&nbsp;
                                                  {noteEdit.id != note.id && (
                                                    <FontAwesomeIcon
                                                      onClick={() =>
                                                        setnoteEdit({
                                                          note: note.note,
                                                          id: note.id,
                                                        })
                                                      }
                                                      className="flex"
                                                      title="Editar"
                                                      icon={faEdit}
                                                    />
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                            {noteEdit.id == note.id && (
                                              <textarea
                                                value={noteEdit.note}
                                                onChange={(e) =>
                                                  setnoteEdit({
                                                    ...noteEdit,
                                                    note: e.target.value,
                                                  })
                                                }
                                              ></textarea>
                                            )}
                                            {noteEdit.id == note.id && (
                                              <div
                                                onClick={() =>
                                                  updateNoteItem(note.item_id)
                                                }
                                                className="btn-add-note-evaluation cursor-action background-simple"
                                              ></div>
                                            )}
                                            {noteEdit.id != note.id &&
                                              note.note}
                                          </div>
                                        );
                                      })}
                                  </>
                                )}
                              </div>

                              {!notes && (
                                <div className="txt-center">No hay notas</div>
                              )}
                            </div>
                            <div className="flex-1  container-border-note-evaluation">
                              <div className="flex ">
                                <label htmlFor="">Notas</label>
                                <div className="flex">
                                  <div
                                    onClick={() => checkAddNote()}
                                    className="btn-add-note-evaluation cursor-action background-simple"
                                  ></div>
                                </div>
                              </div>

                              <textarea
                                onChange={(e) =>
                                  setNote({ ...note, note: e.target.value })
                                }
                                className="w-100 input-new-note-evaluation tipo-cursiva"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Draggable>
                )}

                {(itemDeploy[
                  "item" + JSON.stringify([...parentItem_indexs, index])
                ] ||
                  item.deploy) && (
                  <>
                    {item.items.length > 0 && (
                      <Items
                        items={item.items}
                        parentItem_indexs={[...parentItem_indexs, index]}
                        first={false}
                      />
                    )}
                  </>
                )}
              </div>
            </li>
          </ol>
        ))}
      </div>
    </>
  );
};
const SMMR = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation },
    {
      removeOption,
      setFieldOption,
      setFieldItem,
      addOptionItem,
      addResponseSMMR,
      updateOption,
    },
  ] = useContext(InspectionFormContext);
  return (
    <>
      <div style={{ marginTop: "5px" }} className=" flex-column">
        {!inEvaluation && (
          <div className="flex-column">
            {item.optionsItem.map((option, indexOp) => (
              <div
                key={indexOp}
                className="flex container-option-item-inspection"
              >
                <div className="flex flex-1">
                  <div className="box-option-inspection margin-auto"></div>

                  <div
                    style={{ marginLeft: "2px", marginRight: "8px" }}
                    className="form-group-zeus"
                  >
                    <input
                      value={option.description}
                      onBlur={(e) =>
                        updateOption({ description: e.target.value }, option.id)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          updateOption(
                            { description: e.target.value },
                            option.id
                          );
                        }
                      }}
                      onChange={(e) =>
                        setFieldOption(
                          "description",
                          e.target.value,
                          option,
                          item,
                          indexOp
                        )
                      }
                      type="text"
                      name="text"
                      autoComplete="off"
                      required
                    />
                    <label className="label-name-zeus">
                      <span className="content-name-zeus">Nueva pregunta</span>
                    </label>
                  </div>
                </div>

                <div
                  className="inspection-option-delete tipo-description margin-auto"
                  onClick={() => removeOption(item, option, indexOp)}
                >
                  <span className="margin-auto">x</span>
                </div>
              </div>
            ))}
            <br />
            <div
              onClick={() => addOptionItem(item)}
              className="btn-plus-inspection"
            ></div>
          </div>
        )}

        {inEvaluation && (
          <div className="flex-column">
            {item.optionsItem.map((option, indexOp) => (
              <div key={indexOp} className="flex">
                <div
                  onClick={() => addResponseSMMR(item, indexOp, option)}
                  className="box-option-inspection"
                >
                  {item.results.length > 0 && (
                    <>
                      {item.results.filter(
                        (op) => op.pivot.option_id == option.id
                      ).length > 0 && <FontAwesomeIcon icon={faCheck} />}
                    </>
                  )}
                </div>
                <div className="tipo-description">
                  &nbsp; {option.description}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const SUR = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation, withErrors },
    {
      removeOption,
      setFieldOption,
      setFieldItem,
      addOptionItem,
      addResponseSUR,
      updateOption,
    },
  ] = useContext(InspectionFormContext);
  const haveError = (item) => {
    return withErrors && item.is_required && item.results_item.length === 0;
  };
  return (
    <div style={{ marginTop: "5px" }} className=" flex-column">
      {!inEvaluation && (
        <div className="flex-column">
          {item.optionsItem.map((option, indexOp) => (
            <div
              key={indexOp}
              className="flex container-option-item-inspection"
            >
              <div className="flex flex-1">
                <div className="circle-option-inspection margin-auto"></div>
                <div
                  style={{ marginLeft: "2px", marginRight: "8px" }}
                  className="form-group-zeus"
                >
                  <input
                    value={option.description}
                    onBlur={(e) =>
                      updateOption({ description: e.target.value }, option.id)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        updateOption(
                          { description: e.target.value },
                          option.id
                        );
                      }
                    }}
                    onChange={(e) =>
                      setFieldOption(
                        "description",
                        e.target.value,
                        option,
                        item,
                        indexOp
                      )
                    }
                    type="text"
                    name="text"
                    autoComplete="off"
                    required
                  />
                  <label className="label-name-zeus">
                    <span className="content-name-zeus">Nueva pregunta</span>
                  </label>
                </div>
              </div>

              <div
                className="inspection-option-delete tipo-description margin-auto"
                onClick={() => removeOption(item, option, indexOp)}
              >
                <span className="margin-auto">x</span>
              </div>
            </div>
          ))}
          <br />
          <div
            onClick={() => addOptionItem(item)}
            className="btn-plus-inspection"
          ></div>
        </div>
      )}

      {inEvaluation && (
        <div className="flex-column">
          {item.optionsItem.map((option, indexOp) => (
            <div key={indexOp} className="flex">
              <div
                onClick={() =>
                  addResponseSUR(item, parentItem_indexs, index, option)
                }
                className="circle-option-inspection cursor-pointer"
              >
                {item.results.length > 0 && (
                  <>
                    {item.results[0].pivot.option_id == option.id && (
                      <div className="circle-check-select-inspection"></div>
                    )}
                  </>
                )}
              </div>
              <div className="tipo-description">
                &nbsp; {option.description}
              </div>
            </div>
          ))}
          {haveError(item) && (
            <label className="red">* Seleccione una de las opciones</label>
          )}
        </div>
      )}
    </div>
  );
};

const DEBE = ({ item, index, first, parentItem_indexs }) => {
  const [
    { withErrors, inEvaluation, loadDocument, note },
    { setFieldItem, addNewItem, addResponseSUR, setNote, uploadImage },
  ] = useContext(InspectionFormContext);
  const haveError = (item) => {
    return item.results.length === 0 && withErrors;
  };
  return (
    <div className=" flex">
      {!inEvaluation && (
        <div className="container-icons-circles  flex justify-end">
          <div className="flex-1 flex">
            <div className="icon-calificables-title icon-cumple-evaluation background-simple">
              {/*   <div className="circle-number-evaluation border-color-apply">4</div> */}
            </div>
          </div>
          <div className="flex-1 flex">
            <div className="icon-calificables-title icon-no-cumple-evaluation background-simple">
              {/*  <div className="circle-number-evaluation border-color-not-cumple">
                0
              </div> */}
            </div>
          </div>
          <div className="flex-1 flex">
            <div className="icon-calificables-title icon-no-aplica-evaluation  background-simple">
              {/*    <div className="circle-number-evaluation border-color-not-apply">
                4
              </div> */}
            </div>
          </div>
        </div>
      )}
      {inEvaluation && item.id && (
        <div>
          <div className="container-calificable  flex justify-end">
            <>
              {item.optionsItem.map((opt) => {
                return (
                  <div key={opt.id} className="flex-1 flex">
                    <div
                      onClick={() =>
                        addResponseSUR(item, parentItem_indexs, index, opt)
                      }
                      className={`icon-calificables ${validateCalificable(
                        item.results,
                        opt
                      )} `}
                    ></div>
                  </div>
                );
              })}
            </>
            <div className="flex-2 flex">
              <div
                onClick={() => setNote({ ...note, item_id: item.id })}
                className="icon-note-evaluation"
              >
                <div className="orange container-number-notes-evaluation tipo-cursiva">
                  {item.notes.length}
                </div>
              </div>
            </div>

            <div className="flex-document-column-evaluation container-calificable-item-evaluation-desktop">
              <div className="">
                <label
                  className="label-input-file-evaluation-report-desktop"
                  htmlFor="upload"
                >
                  <div className="orange container-number-documents-evaluation tipo-cursiva">
                    {item.documents.length}
                  </div>
                </label>
                <input
                  onChange={uploadImage(item.id)}
                  type="file"
                  name="file"
                  id="file"
                  title={item.id}
                  className="input-file-evaluation-report-desktop"
                />
              </div>
              {loadDocument.item_id == item.id &&
                loadDocument.status == STATUS_LOADING && (
                  <img
                    src={loadingImage}
                    className="image-loading-item-desktop"
                  />
                )}
            </div>
          </div>
          {haveError(item) && (
            <div>
              <label className="red">* Seleccione una de las opciones</label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const INTEGER = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation, withErrors },
    { updateResponseText, setTextOptionQuestion, updateResponseTextQS },
  ] = useContext(InspectionFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const validateSetFieldInteger = (value, item, option, indexOp) => {
    let valueResponse = value;
    if (value !== "") {
      valueResponse = parseInt(value);
      if (!Number.isInteger(valueResponse)) {
        alertWarning("El valor debe ser un numero entero");
        return false;
      }

      if (item.max != "" && item.max) {
        if (valueResponse > item.max) {
          let valueString = valueResponse.toString();
          valueResponse = parseInt(
            valueString.substring(0, valueString.length - 1)
          );
          alertWarning("El valor maximo permitido es " + item.max);
        }
      }
    }
    setTextOptionQuestion(valueResponse, item, option, indexOp);
  };

  const validateInteger = (item, form, option, indexOp) => {
    let { response } = form;
    if (item.min != "" && item.min) {
      if (parseInt(response) < parseInt(item.min)) {
        form.response = "";
        alertWarning("El valor mínimo permitido es " + item.min);
      }
    }
    updateResponseTextQS(item.id, form);
  };
  const haveError = (item) => {
    if (withErrors && item.is_required && item.results_item.length == 0) {
      return true;
    } else if (withErrors && item.is_required && item.results_item.length > 0) {
      if (item.results_item[0].response === "") {
        return true;
      }
    }
  };
  const moreValue = (more, item, option, indexOp) => {
    let value = 0;
    if (item.results.length != 0) {
      if (item.results_item[0].response != "") {
        value = parseInt(item.results_item[0].response);
      }
    }

    if (more) {
      value = value + 1;
    } else {
      value = value - 1;
    }
    if (item.min != "") {
      if (parseInt(value) < parseInt(item.min)) {
        if (more) {
          value = value - 1;
        } else {
          value = value + 1;
        }
        alertWarning("El valor mínimo permitido es " + item.min);
      }
    }
    updateResponseTextQS(item.id, { response: value, option_id: option.id });
  };
  return (
    <div className=" flex-column">
      {JSON.stringify(itemSelected) !==
        JSON.stringify([...parentItem_indexs, index]) && (
        <>
          <div>
            {inEvaluation &&
              item.optionsItem.map((option, indexOp) => (
                <div key={indexOp} className="flex">
                  <div style={{ height: "12px" }} className="flex-column">
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      onClick={(e) => moreValue(true, item, option, indexOp)}
                    />
                    <FontAwesomeIcon
                      onClick={(e) => moreValue(false, item, option, indexOp)}
                      icon={faChevronDown}
                    />
                  </div>
                  &nbsp;
                  <div className="form-group-zeus ">
                    <input
                      key={indexOp}
                      onBlur={(e) =>
                        validateInteger(
                          item,
                          {
                            response: e.target.value,
                            option_id: option.id,
                          },
                          option,
                          indexOp
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          validateInteger(
                            item,
                            {
                              response: e.target.value,
                              option_id: option.id,
                            },
                            option,
                            indexOp
                          );
                        }
                      }}
                      onChange={(e) =>
                        validateSetFieldInteger(
                          e.target.value,
                          item,
                          option,
                          indexOp
                        )
                      }
                      value={
                        item.results.length > 0
                          ? item.results[0].pivot.response
                          : ""
                      }
                      min={item.min}
                      max={item.max}
                      type="text"
                      className={`form-control tipo-description ${
                        haveError(item) ? "is-invalid" : ""
                      }`}
                      name="number"
                      autoComplete="off"
                      required
                    />
                    <label
                      className={`label-name-zeus ${
                        haveError(item) ? "error-label-inspectio-form" : ""
                      } `}
                    >
                      <span className={`content-name-zeus `}>
                        Escriba aquí{" "}
                      </span>
                    </label>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
const NUMBER = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation, withErrors },
    { updateResponseText, setTextOptionQuestion, updateResponseTextQS },
  ] = useContext(InspectionFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const validateSetFieldNumber = (value, item, option, indexOp) => {
    let valueResponse = value;

    if (item.max != "") {
      if (parseFloat(valueResponse) > parseFloat(item.max)) {
        valueResponse = valueResponse.substring(0, valueResponse.length - 1);
        alertWarning("El valor maximo permitido es " + item.max);
      }
    }
    setTextOptionQuestion(valueResponse, item, option, indexOp);
  };
  const validateNumber = (item, form, option, indexOp) => {
    let { response } = form;
    if (item.min != "") {
      if (parseFloat(response) < parseFloat(item.min)) {
        form.response = "";
        alertWarning("El valor mínimo permitido es " + item.min);
      }
    }
    updateResponseTextQS(item.id, form);
  };
  const haveError = (item) => {
    if (withErrors && item.is_required && item.results_item.length == 0) {
      return true;
    } else if (withErrors && item.is_required && item.results_item.length > 0) {
      if (item.results_item[0].response === "") {
        return true;
      }
    }
  };
  return (
    <div className=" flex-column">
      {JSON.stringify(itemSelected) !==
        JSON.stringify([...parentItem_indexs, index]) && (
        <>
          <div>
            {inEvaluation &&
              item.optionsItem.map((option, indexOp) => (
                <div key={indexOp} className="form-group-zeus ">
                  <input
                    key={indexOp}
                    onBlur={(e) =>
                      validateNumber(
                        item,
                        {
                          response: e.target.value,
                          option_id: option.id,
                        },
                        option,
                        indexOp
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        validateNumber(
                          item,
                          {
                            response: e.target.value,
                            option_id: option.id,
                          },
                          option,
                          indexOp
                        );
                      }
                    }}
                    onChange={(e) =>
                      validateSetFieldNumber(
                        e.target.value,
                        item,
                        option,
                        indexOp
                      )
                    }
                    value={
                      item.results.length > 0
                        ? item.results[0].pivot.response
                        : ""
                    }
                    min={item.min}
                    max={item.max}
                    type="number"
                    className={`form-control tipo-description ${
                      haveError(item) ? "is-invalid" : ""
                    }`}
                    name="number"
                    autoComplete="off"
                    required
                  />
                  <label
                    className={`label-name-zeus ${
                      haveError(item) ? "error-label-inspectio-form" : ""
                    } `}
                  >
                    <span className={`content-name-zeus `}>Escriba aquí </span>
                  </label>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
const ITEMDATE = ({ time, item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation, withErrors },
    { updateResponseText, setTextOptionQuestion, updateResponseTextQS },
  ] = useContext(InspectionFormContext);

  const haveError = (item) => {
    if (withErrors && item.is_required && item.results_item.length == 0) {
      return true;
    } else if (withErrors && item.is_required && item.results_item.length > 0) {
      if (item.results_item[0].response === "") {
        return true;
      }
    }
  };
  const getDate = (withTime, item) => {
    let format = "YYYY-MM-DD";
    if (withTime) {
      format = format + " hh:mm:ss";
    }
    if (item.results.length > 0) {
      if (item.results[0].pivot.response != "") {
        return moment(
          new Date(item.results[0].pivot.response),
          format
        ).toDate();
      }
    }
  };
  return (
    <div className=" flex-column">
      {JSON.stringify(itemSelected) !==
        JSON.stringify([...parentItem_indexs, index]) && (
        <>
          <div>
            {inEvaluation &&
              item.optionsItem.map((option, indexOp) => (
                <div key={indexOp} className="form-group ">
                  <DatePicker
                    placeholderText="seleccione una fecha"
                    showTimeSelect={time}
                    selected={getDate(time, item)}
                    onSelect={(date) => {
                      updateResponseTextQS(item.id, {
                        response: date,
                        option_id: option.id,
                      });
                    }}
                    onChange={(date) =>
                      setTextOptionQuestion(date, item, option, indexOp)
                    }
                    onCalendarClose={(e) =>
                      updateResponseTextQS(item.id, {
                        response: getDate(time, item),
                        option_id: option.id,
                      })
                    }
                    className={`form-control tipo-description ${
                      haveError(item) && "is-invalid"
                    }`}
                    locale="es"
                    dateFormat={time ? "y-MM-dd h:mm aa" : "y-MM-dd"}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
const QS = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation, withErrors },
    { updateResponseText, setTextOptionQuestion, updateResponseTextQS },
  ] = useContext(InspectionFormContext);

  const haveError = (item) => {
    if (withErrors && item.is_required && item.results_item.length == 0) {
      return true;
    } else if (withErrors && item.is_required && item.results_item.length > 0) {
      if (item.results_item[0].response === "") {
        return true;
      }
    }
  };
  return (
    <div className=" flex-column">
      {JSON.stringify(itemSelected) !==
        JSON.stringify([...parentItem_indexs, index]) && (
        <>
          <div>
            {inEvaluation &&
              item.optionsItem.map((option, indexOp) => (
                <div key={indexOp} className="form-group-zeus ">
                  <input
                    key={indexOp}
                    onBlur={(e) =>
                      updateResponseTextQS(item.id, {
                        response: e.target.value,
                        option_id: option.id,
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        updateResponseTextQS(item.id, {
                          response: e.target.value,
                          option_id: option.id,
                        });
                      }
                    }}
                    onChange={(e) =>
                      setTextOptionQuestion(
                        e.target.value,
                        item,
                        option,
                        indexOp
                      )
                    }
                    value={
                      item.results.length > 0
                        ? item.results[0].pivot.response
                        : ""
                    }
                    minLength={item.min}
                    maxLength={item.max}
                    type="text"
                    className={`form-control tipo-description ${
                      haveError(item) ? "is-invalid" : ""
                    }`}
                    name="text"
                    autoComplete="off"
                    required
                  />
                  <label
                    className={`label-name-zeus ${
                      haveError(item) ? "error-label-inspectio-form" : ""
                    } `}
                  >
                    <span className={`content-name-zeus `}>Escriba aquí </span>
                  </label>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
const QL = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation },
    { updateResponseText, setTextOptionQuestion },
  ] = useContext(InspectionFormContext);
  return (
    <div className=" flex-column">
      {JSON.stringify(itemSelected) !==
        JSON.stringify([...parentItem_indexs, index]) && (
        <>
          <div>
            {inEvaluation &&
              item.optionsItem.map((option, indexOp) => (
                <textarea
                  key={indexOp}
                  onBlur={(e) =>
                    updateResponseText(item.id, {
                      response: e.target.value,
                      option_id: option.id,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      updateResponseText(item.id, {
                        response: e.target.value,
                        option_id: option.id,
                      });
                    }
                  }}
                  onChange={(e) =>
                    setTextOptionQuestion(e.target.value, item, option, indexOp)
                  }
                  value={
                    item.results.length > 0
                      ? item.results[0].pivot.response
                      : ""
                  }
                  className="form-control"
                ></textarea>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const ITEMFILE = ({ isMulti, checkDelete, item, index, first }) => {
  const [{ inEvaluation, loadDocumentOption }, { uploadImageOption }] =
    useContext(InspectionFormContext);
  return (
    <div className=" flex-column">
      <div className="flex">
        {inEvaluation &&
          item.results_item.map((result, indexResult) => (
            <div key={indexResult} className="">
              <div className="flex">
                {result.documents.map((documentRe, indexDoc) => {
                  return (
                    <Document
                      indexDocumentable={indexResult}
                      indexDocument={indexDoc}
                      isOption={true}
                      key={indexDoc}
                      item={item}
                      document={documentRe}
                    />
                  );
                })}
              </div>
            </div>
          ))}

        {item.optionsItem.map((option) => {
          return (
            <div key={option.id}>
              {!(
                isMulti === false &&
                item.results_item.filter((res) => res.documents.length > 0)
                  .length > 0
              ) && (
                <>
                  {loadDocumentOption.option_id != option.id && (
                    <div className="flex-document-column-evaluation container-calificable-item-evaluation-desktop">
                      <div className="">
                        <label
                          className="label-input-file-evaluation-report-desktop"
                          htmlFor="upload"
                        ></label>
                        <input
                          onChange={uploadImageOption(item, option.id)}
                          type="file"
                          name="file"
                          id="file"
                          title={item.id}
                          className="input-file-evaluation-report-desktop"
                        />
                      </div>
                    </div>
                  )}
                  {loadDocumentOption.option_id == option.id &&
                    loadDocumentOption.status == STATUS_LOADING && (
                      <img
                        src={loadingImage}
                        className="image-loading-item-desktop"
                      />
                    )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const ITEMSELECT = ({ item, parentItem_indexs, index }) => {
  const [{ itemTypes }, { setItemTypeItem }] = useContext(
    InspectionFormContext
  );

  return (
    <>
      <Select
        value={item.item_type}
        onChange={(e) => setItemTypeItem(e, item)}
        className="tipo-description select-zeus-analyze select-inspecion-form select-responsable-analyze"
        placeholder={"Todos los procesos"}
        getOptionLabel={(optio) => `${optio.name}`}
        getOptionValue={(optio) => optio.id}
        options={itemTypes.elements}
      />
    </>
  );
};

const Document = ({
  indexDocument,
  indexDocumentable,
  item,
  document,
  isOption,
  index,
}) => {
  let history = useHistory();
  const [{ inEvaluation }, { checkDelete }] = useContext(InspectionFormContext);
  const [{}, { downloadDocument, getSrcDocumentWithBearer }] =
    useContext(SecurityContext);
  const [srcImage, setsrcImage] = useState("");
  useEffect(() => {
    async function fetchDocument(document) {
      let src = await getSrcDocumentWithBearer(document);
      setsrcImage(src);
    }
    fetchDocument(document);
  }, [document]);
  return (
    <div key={document.id}>
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <div className="container-img-item-desktop flex " key={document.id}>
          <FontAwesomeIcon
            onClick={() =>
              isOption
                ? checkDelete(
                    "¿Realmente deseas eliminar este archivo?",
                    `deleteDocumentResult(${indexDocument},${indexDocumentable},${item.id},${document.id},${document.documentable_id})`
                  )
                : checkDelete(
                    "¿Realmente deseas eliminar este archivo?",
                    `deleteDocument(${document.id},${document.documentable_id})`
                  )
            }
            className="cursor-action"
            icon={faTimesCircle}
          />

          <img
            className="cursor-action"
            onClick={() =>
              history.push(`../../../app/library/document/${document.id}`)
            }
            src={srcImage}
            onError={(e) => {
              e.target.src = imgError;
            }}
            alt=""
          />
        </div>
      )}
      {document.type == EXT_PDF && (
        <div className="container-img-item-desktop flex " key={document.id}>
          <FontAwesomeIcon
            onClick={() =>
              isOption
                ? checkDelete(
                    "¿Realmente deseas eliminar este archivo?",
                    `deleteDocumentResult(${indexDocument},${indexDocumentable},${item.id},${document.id},${document.documentable_id})`
                  )
                : checkDelete(
                    "¿Realmente deseas eliminar este archivo?",
                    `deleteDocument(${document.id},${document.documentable_id})`
                  )
            }
            className="cursor-action"
            icon={faTimesCircle}
          />

          <div
            className="cursor-action btn-pdf-observation btn-files-observations"
            onClick={() =>
              history.push(`../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}

      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div className="container-img-item-desktop flex " key={document.id}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="cursor-action"
            icon={faTimesCircle}
          />

          <div
            className="cursor-action btn-word-observation btn-files-observations"
            onClick={() =>
              downloadDocument(
                process.env.REACT_APP_API_HOST + "documents/" + document.name
              )
            }
          ></div>
        </div>
      )}
      {(document.type == EXT_XLSX || document.type == EXT_XLS) && (
        <div className="container-img-item-desktop flex " key={document.id}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="cursor-action"
            icon={faTimesCircle}
          />

          <div
            className="cursor-action btn-excell-observation btn-files-observations"
            onClick={() =>
              downloadDocument(
                process.env.REACT_APP_API_HOST + "documents/" + document.name
              )
            }
          ></div>
        </div>
      )}
      {document.type == EXT_MP3 && (
        <div className="container-img-item-desktop flex " key={document.id}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="cursor-action"
            icon={faTimesCircle}
          />

          <div
            className="cursor-action btn-mp3-observation btn-files-observations"
            onClick={() =>
              history.push(`../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}
      {document.type == EXT_MP4 && (
        <div className="container-img-item-desktop   flex " key={document.id}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="cursor-action"
            icon={faTimesCircle}
          />

          <div
            className="cursor-action btn-mp4-observation btn-files-observations"
            onClick={() =>
              history.push(`../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}
    </div>
  );
};
export default Form;
