import React, { useEffect, useState, useContext } from "react";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import es from "date-fns/locale/es";
import moment from "moment";
import Swal from "sweetalert2";
import cloneDeep from "lodash/cloneDeep";
import { faCapsules } from "@fortawesome/free-solid-svg-icons";
import firebase from "../../../firebase";
const db = firebase.firestore();
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const MODEL_EVALUATION = "App\\Models\\Evaluation";
const NOTIFICATION_MODULE_ACTION_PLAN = "action_plan";
const NOTIFICATION_ACTION_APPROVED = "action_approved";
export const STATUS_CREATED = 1;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;
export const STATUS_REJECTED = 8;
export const BUTTON_SI = "SI";
export const BUTTON_NO = "NO";
export const BUTTON_POSPONER = "POSPONER";

const NOTIFICATION_CREATE_AUDIT = "create-audit";
const NOTIFICATION_MODULE_AUDIT = "audit";
const NOTE_EFFECTIVE = "effective";
const STATUS_APPROVED = 7;
let initialState = {
  analyze: {
    status: STATUS_NOT_LOADED,
    analyze_id: null,
    element: {},
  },
  newCorrection: {
    analyze_id: null,
    description: "",
    responsable_id: null,
    date: moment().format("YYYY-MM-DD HH:mm:ss"),
  },
  itemsDeploy: {},
  errors: {
    error: [],
    status: false,
  },
};

export const ActionPlanFormContext = React.createContext([]);

export const ActionPlanFormContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  let [analyze, setanalyze] = useState(initialState.analyze);
  const [{ user }, { logout, pluck }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [newCorrection, setnewCorrection] = useState(initialState.newCorrection);
  const [details, setdetails] = useState({});
  const [modalCorrection, setmodalCorrection] = useState(null);
  const [modalAction, setmodalAction] = useState(null);
  const [notesAction, setnotesAction] = useState([]);
  const [modalEffective, setmodalEffective] = useState({ button: "", open: false });
  const [filesCorrectionsModal, setfilesCorrectionsModal] = useState([]);
  const [filesActionsModal, setfilesActionsModal] = useState([]);
  const [filesAnalyzeEffective, setfilesAnalyzeEffective] = useState([]);
  const [newDateAnalyze, setnewDateAnalyze] = useState(new Date());
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [actionDeploy, setActionDeploy] = useState({});
  const [causeDrag, setcauseDrag] = useState({ id: null });
  const [scroll, setscroll] = useState(0);
  const [textFirstCause, settextFirstCause] = useState("");
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (analyze.analyze_id != null && analyze.status === STATUS_NOT_LOADED) {
      setanalyze({ ...analyze, status: STATUS_LOADING });
      client()
        .get(`audit/analyze/${analyze.analyze_id}`, {
          //todas las relaciones de corrections se deben llamar tambien en addNewCorrection
          //todas las relaciones de causas se deben llamar tambien en addCause
          //todas las relaciones de actions se deben llamar tambien en addAction
          params: {
            with: [
              "actions.responsable",
              "actions.notes.documents",
              "actions.notes.person",
              "actions.cause",
              "company.process",
              "company.partners",
              "responsable",
              "analyzable",
              "documents",
              "corrections.responsable",
              "corrections.notes.documents",
              "corrections.notes.person",
              "corrections.documents",
              "processSelect",
              "notes",
              "responsable",
              "causes",
              "notesEffective.person",
              "notesEffective.documents",
            ],
          },
        })
        .then(({ data }) => {
          if (data.id) {
            setnewDateAnalyze(new Date(data.end_date));
            if (data.audit_id != null) {
              client()
                .get(`evaluation/item/${data.analyzable_id}`, {
                  params: {
                    with: ["auditable_item", "documents", "notes", "evaluation.audit"],
                  },
                })
                .then((item) => {
                  if (item.data) {
                    data.item = item.data;
                    setanalyze({ ...analyze, status: STATUS_LOADED, element: data });
                  }
                })
                .catch((e) => {
                  console.log(e);
                  if (e.request.status === 401) {
                    logout();
                  } else if (e.request.status === 422) {
                    setErrors({ error: e.response.data, status: true });
                  } else {
                    alertError("Error al buscar el item");
                  }
                });
            } else if (data.analyzable_type === MODEL_EVALUATION) {
              client()
                .get(`audit/evaluation/${data.analyzable_id}`, {
                  params: {
                    with: ["items.results", "notes", "documents"],
                  },
                })
                .then((evaluation) => {
                  if (evaluation.data) {
                    data.evaluation = evaluation.data;
                    setanalyze({ ...analyze, status: STATUS_LOADED, element: data });
                  }
                })
                .catch((e) => {
                  if (e.request.status === 401) {
                    logout();
                  } else if (e.request.status === 422) {
                    setErrors({ error: e.response.data, status: true });
                  } else {
                    alertError("Error al buscar la evaluacion");
                  }
                });
            } else {
              setanalyze({ ...analyze, status: STATUS_LOADED, element: data });
            }
          } else {
            history.push("../../../app/unauthorized");
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al generar los analisis");
          }
        });
    }
  }, [analyze]);

  useEffect(() => {
    if (modalAction) {
      client()
        .get(`audit/action_plan/${modalAction}/notes`, {
          params: {
            with: ["documents", "person"],
          },
        })
        .then(({ data }) => {
          setnotesAction(data);
        });
    }
  }, [modalAction, filesActionsModal]);
  const uploadDocument = (e) => {
    let document = e.target.files[0];
    const formData = new FormData();
    formData.append("document", document);
    formData.append("is_private", true);

    client()
      .post(`audit/analyze/${analyze.analyze_id}/documented`, formData)
      .then(({ data }) => {
        if (data) {
          setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
          alertSuccess("Documento adjuntado");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al adjuntar el documento");
        }
      });
  };
  const addNewCorrection = (form) => {
    form.with = ["responsable", "notes.documents", "notes.person", "documents"];

    client()
      .post(`audit/correction`, form)
      .then(({ data }) => {
        if (data) {
          let clone = cloneDeep(analyze);
          clone.element.corrections.push(data);
          setanalyze(clone);
          //alertSuccess("Correccion creada satisfactoriamente");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al adjuntar el documento");
        }
      });
  };
  const deleteCorrection = (id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta correccion?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        let clone = cloneDeep(analyze);
        client()
          .delete(`audit/correction/${id}`)
          .then(({ data }) => {
            if (data) {
              let newCorrections = clone.element.corrections.filter((e) => e.id !== id);
              clone.element.corrections = newCorrections;
              setanalyze(clone);
            }
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Problemas al eliminar la correccion");
            }
          });
      } else {
      }
    });
  };
  const deleteCauseRecursive = (id, causes) => {
    for (let c = 0; c < causes.length; c++) {
      const cause = causes[c];
      if (cause.id === id) {
        var i = causes.indexOf(cause);
        causes.splice(i, 1);
      } else {
        if (cause.causes.length > 0) {
          deleteCauseRecursive(id, cause.causes);
        }
      }
    }
  };
  const deleteCause = (id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta causa?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        let clone = cloneDeep(analyze);
        deleteCauseRecursive(id, clone.element.causes);
        setanalyze(clone);
        client()
          .delete(`audit/cause_method/${id}`)
          .then(({ data }) => {
            if (data) {
            }
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Problemas al eliminar la causa");
            }
          });
      } else {
      }
    });
  };
  const deleteActionRecursive = (id, actions) => {
    for (let c = 0; c < actions.length; c++) {
      const action = actions[c];
      if (action.id === id) {
        var i = actions.indexOf(action);
        actions.splice(i, 1);
      } else {
        if (action.actions.length > 0) {
          deleteActionRecursive(id, action.actions);
        }
      }
    }
  };

  const deleteAction = (id, cause_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta actividad?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        let clone = cloneDeep(analyze);
        deleteActionRecursive(id, clone.element.actions);
        setanalyze(clone);
        client()
          .delete(`audit/action_plan/${id}`)
          .then(({ data }) => {
            if (data) {
            }
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Problemas al eliminar la actividad");
            }
          });
      } else {
      }
    });
  };
  const setFieldCorrections = (field, value, id, index, isUpdate) => {
    let clone = cloneDeep(analyze);
    let correction = clone.element.corrections[index];
    if (correction.id === id) {
      correction[field] = value;
      setanalyze(clone);
      switch (field) {
        case "responsable":
          field = "responsable_id";
          value = value ? value.id : null;
          break;
        case "date":
          value = moment(value).format("YYYY-MM-DD HH:mm:ss");
          break;
      }
      if (isUpdate) {
        client()
          .put(`audit/correction/${id}`, { [field]: value })
          .then(({ data }) => {
            if (data) {
            }
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Problemas al editar la correccion");
            }
          });
      }
    }
  };
  const updateAnalyze = (form) => {
    return client().put(`audit/analyze/${analyze.analyze_id}`, form);
  };

  const syncProcess = (processSelect) => {
    let clone = cloneDeep(analyze);
    clone.element.process_select = processSelect;
    setanalyze(clone);
    client()
      .post(`audit/processSyncAnalyze/${analyze.analyze_id}`, {
        processSelect: pluck(processSelect, "id"),
      })
      .then(({ data }) => {
        if (data) {
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al editar la correccion");
        }
      });
  };
  const setNote = (note) => {
    let clone = cloneDeep(analyze);
    if (clone.element.notes.length > 0) {
      clone.element.notes[0].note = note;
    } else {
      clone.element.notes = [{ id: null, note }];
    }

    setanalyze(clone);
  };
  const updateOrCreateNote = (value) => {
    if (value != "") {
      var formData = new FormData();
      formData.append("note", value);
      formData.append("person_id", user.id);
      let route = `audit/analyze/${analyze.analyze_id}/notificated`;

      if (analyze.element.notes.length > 0) {
        if (analyze.element.notes[0].id) {
          route = `general/note/${analyze.element.notes[0].id}`;
        }
      }
      client()
        .post(route, formData)
        .then(({ data }) => {
          let clone = cloneDeep(analyze);
          clone.element.notes = [data];
          setanalyze(clone);
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al añadir el comentario");
          }
        });
    }
  };

  const addActionRecursive = (action, actions) => {
    if (!action.action_plan_id) {
      actions.push(action);
      /* settextFirstCause(""); */
    } else {
      for (let c = 0; c < actions.length; c++) {
        const cloneAction = actions[c];
        if (cloneAction.id === action.action_plan_id) {
          cloneAction.actions.push(action);
        } else {
          if (cloneAction.actions.length > 0) {
            addActionRecursive(action, cloneAction.actions);
          }
        }
      }
    }
  };

  const addAction = (form, cause_id) => {
    form.with = ["responsable", "notes.documents", "notes.person", "cause"];
    client()
      .post(`audit/action_plan`, form)
      .then(({ data }) => {
        let clone = cloneDeep(analyze);
        addActionRecursive(data, clone.element.actions);
        if (clone.element.status_id !== STATUS_INPROGRESS) {
          client().post(`audit/analyze/${analyze.analyze_id}`, { status_id: STATUS_INPROGRESS });
          clone.element.status_id = STATUS_INPROGRESS;
        }

        setanalyze(clone);

        if (data.action_plan_id) {
          setActionDeploy({ ...actionDeploy, ["action" + data.action_plan_id]: true });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir la accion");
        }
      });
  };
  const addNoteCorrection = async (form) => {
    client()
      .post(`audit/correction/${modalCorrection}/notificated`, {
        note: form.note,
        person_id: user.id,
      })
      .then(async ({ data }) => {
        if (filesCorrectionsModal.length > 0) {
          let route = `general/note/${data.id}/documented`;
          await addDocuments(filesCorrectionsModal, route);
        }
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
        setfilesCorrectionsModal([]);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir la accion");
        }
      });
  };
  const addNoteAnalyzeEffective = async (form) => {
    if (modalEffective.button === BUTTON_POSPONER) {
      console.log(form.note);
      if (!form.note) {
        alertWarning("El campo nota es obligatorio");
        return false;
      }
      form.note =
        "Fecha compomiso:    anterior : " +
        analyze.element.end_date +
        "  nueva: " +
        moment(newDateAnalyze).format("YYYY-MM-DD") +
        " | " +
        form.note;
    }
    client()
      .post(`audit/analyze/${analyze.analyze_id}/notificated`, {
        note: form.note,
        person_id: user.id,
        role: NOTE_EFFECTIVE,
      })
      .then(async ({ data }) => {
        if (filesAnalyzeEffective.length > 0) {
          let route = `general/note/${data.id}/documented`;
          await addDocuments(filesAnalyzeEffective, route);
        }
        switch (modalEffective.button) {
          case BUTTON_POSPONER:
            await updateAnalyze({ status_id: STATUS_INPROGRESS, end_date: newDateAnalyze });
            break;
          case BUTTON_SI:
            await updateAnalyze({ status_id: STATUS_EXECUTED, real_end_date: new Date() });
            break;
          case BUTTON_NO:
            await updateAnalyze({ status_id: STATUS_REJECTED });
            break;
        }

        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
        setfilesAnalyzeEffective([]);
        setmodalEffective({ button: "", open: false });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir la accion");
        }
      });
  };
  const addNoteAction = async (form) => {
    client()
      .post(`audit/action_plan/${modalAction}/notificated`, {
        note: form.note,
        person_id: user.id,
      })
      .then(async ({ data }) => {
        if (filesActionsModal.length > 0) {
          let route = `general/note/${data.id}/documented`;
          await addDocuments(filesActionsModal, route);
        }

        setfilesActionsModal([]);
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir la accion");
        }
      });
  };
  const deleteEvidenceCorrection = (note_id) => {
    client()
      .delete(`audit/correction/${modalCorrection}/noteDelete/${note_id}`)
      .then(({ data }) => {
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la evidencia");
        }
      });
  };
  const deleteEvidenceAction = (note_id) => {
    client()
      .delete(`audit/action_plan/${modalAction}/noteDelete/${note_id}`)
      .then(({ data }) => {
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
        alertSuccess("Evidencia eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la evidencia");
        }
      });
  };
  const deleteEvidenceEffective = (note_id) => {
    client()
      .delete(`audit/analyze/${analyze.analyze_id}/noteDelete/${note_id}`)
      .then(({ data }) => {
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
        alertSuccess("Evidencia eliminada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar la evidencia");
        }
      });
  };
  const updateCause = async (form) => {
    return client().post(`audit/cause_method/${form.id}`, form);
  };
  const checkUpdateStatusCause = async (cause) => {
    await updateCause({
      id: cause.id,
      status_id: STATUS_APPROVED,
    });
    setFieldCause(cause.id, STATUS_APPROVED, "status_id");
    alertSuccess("Causa raiz aprobada");
  };
  const addCause = ({ name, cause_root, cause_method_id, analyze_id }) => {
    client()
      .post(`audit/cause_method`, {
        name,
        cause_method_id,
        analyze_id,
        cause_root,
      })
      .then(({ data }) => {
        let clone = analyze;
        addCauseRecursive(data, clone.element.causes);
        if (analyze.element.status_id !== STATUS_INPROGRESS) {
          client().post(`audit/analyze/${analyze.analyze_id}`, { status_id: STATUS_INPROGRESS });
          analyze.element.status_id = STATUS_INPROGRESS;
        }

        setanalyze(clone);

        if (data.cause_method_id) {
          setItemDeploy({ ...itemDeploy, ["cause" + data.cause_method_id]: true });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la causa");
        }
      });
  };

  const addCauseRecursive = (cause, causes) => {
    if (!cause.cause_method_id) {
      causes.push(cause);
      settextFirstCause("");
    } else {
      for (let c = 0; c < causes.length; c++) {
        const cloneCause = causes[c];
        if (cloneCause.id === cause.cause_method_id) {
          cloneCause.causes.push(cause);
        } else {
          if (cloneCause.causes.length > 0) {
            addCauseRecursive(cause, cloneCause.causes);
          }
        }
      }
    }
  };
  const setFieldCause = (cause_id, value, field) => {
    let clone = cloneDeep(analyze);
    for (let c = 0; c < clone.element.causes.length; c++) {
      const cause = clone.element.causes[c];
      recursiveCuauses(cause, value, cause_id, field);
    }
    setanalyze(clone);
  };
  const recursiveCuauses = (cause, value, cause_id, field) => {
    if (cause.id === cause_id) {
      cause[field] = value;
    }
    for (let c = 0; c < cause.causes.length; c++) {
      const subcause = cause.causes[c];
      recursiveCuauses(subcause, value, cause_id, field);
    }
  };

  const addDocuments = async (documents, route) => {
    for (let d = 0; d < documents.length; d++) {
      const document = documents[d];
      const formData = new FormData();
      formData.append("document", document);
      formData.append("is_private", true);
      await client()
        .post(route, formData)
        .then(({ data }) => {});
    }
  };
  const setFieldActions = (cause_id, action_id, field, value, updateField) => {
    if (!updateField) {
      client()
        .post(`audit/action_plan/${action_id}`, { [field]: value })
        .then(({ data }) => {})
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al editar la accion");
          }
        });
    } else {
      let clone = cloneDeep(analyze);
      recursiveFieldActions(clone.element.actions, field, value, action_id);

      setanalyze(clone);
    }
  };

  const updatFormAction = (action_id, form) => {
    let clone = cloneDeep(analyze);

    for (let f = 0; f < Object.keys(form).length; f++) {
      const fieldName = Object.keys(form)[f];
      recursiveFieldActions(clone.element.actions, fieldName, form[fieldName], action_id);
    }
    setanalyze(clone);
    client()
      .post(`audit/action_plan/${action_id}`, form)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la accion");
        }
      });
  };
  const recursiveFieldActions = (actions, field, value, action_id) => {
    for (let a = 0; a < actions.length; a++) {
      const action = actions[a];
      if (action.id === action_id) {
        action[field] = value;
      } else {
        if (action.actions.length > 0) {
          recursiveFieldActions(action.actions, field, value, action_id);
        }
      }
    }
  };
  const setDeploy = (cause_id) => {
    if (itemDeploy["cause" + cause_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["cause" + cause_id]: !itemDeploy["cause" + cause_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["cause" + cause_id]: true });
    }
  };
  const setDeployAction = (action_id) => {
    if (actionDeploy["action" + action_id]) {
      setActionDeploy({
        ...actionDeploy,
        ["action" + action_id]: !actionDeploy["action" + action_id],
      });
    } else {
      setActionDeploy({ ...actionDeploy, ["action" + action_id]: true });
    }
  };

  const isResponsable = () => {
    if (
      analyze.element.responsable_id === user.id ||
      analyze.element.company.contact_person_id === user.id ||
      user.is_admin
    ) {
      return true;
    }
    if (analyze.element.evaluation) {
      if (isResponsablePqrs(analyze.element.company)) {
        return true;
      }
    }
  };
  const isResponsablePqrs = (company) => {
    let count = company.partners.filter(
      (p) => p.pivot.person_id == user.id && p.pivot.isPqrsResponsable == true
    ).length;
    if (count > 0) {
      return true;
    }
  };
  const haveResponsablePqrs = (company) => {
    let count = company.partners.filter(
      (p) => p.pivot.isPqrsResponsable == true
    ).length;
    if (count > 0) {
      return true;
    }
  };
  const haveActions = () => {
    if (isResponsable()) {
      return true;
    }
    for (let c = 0; c < analyze.element.actions.length; c++) {
      const action = analyze.element.actions[c];
      if (action.responsable_id === user.id) {
        return true;
      }
    }
  };
  const haveCorrections = () => {
    if (isResponsable()) {
      return true;
    }
    for (let c = 0; c < analyze.element.corrections.length; c++) {
      const correction = analyze.element.corrections[c];
      if (correction.responsable_id === user.id) {
        return true;
      }
    }
  };
  const canViewCorrection = (corr) => {
    let iCan = false;
    iCan = user.is_admin;
    if (isResponsable() === true) {
      iCan = true;
    }

    if (corr.responsable_id === user.id) {
      iCan = true;
    }
    return iCan;
  };
  const canDeleteCorrection = () => {
    let iCan = false;
    iCan = user.is_admin;
    if (isResponsable() === true) {
      iCan = true;
    }

    return iCan;
  };
  const canDeleteEvidence = () => {
    let iCan = false;
    if (isResponsable() === true) {
      iCan = true;
    }

    return iCan;
  };
  const canViewAction = (act) => {
    let iCan = false;
    if (isResponsable() === true) {
      iCan = true;
    }
    if (act.responsable_id === user.id) {
      iCan = true;
    }
    return iCan;
  };
  const sendEmailAnalyze = (analyzeForm) => {
    if (!analyzeForm.responsable) {
      alertWarning("Debe asignar un responsable");
      return false;
    }
    let analyze_id = analyzeForm.id;
    console.log(analyzeForm);
    db.collection(`users/${analyzeForm.responsable.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `te ha asignado como responsable de un plan de acción <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/analyze/${analyze_id}"> Plan de acción</a>`,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_ACTION_APPROVED,
      module: NOTIFICATION_MODULE_ACTION_PLAN,
    });
    client()
      .get(`audit/sendEmailAnalyze/${analyze_id}`)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al notificar al responsable");
        }
      });
    async function updateStatus() {
      await updateAnalyze({ status_id: STATUS_CREATED });
      setanalyze({ ...analyze, element: { ...analyze.element, status_id: STATUS_CREATED } });
      alertSuccess("Análisis aprobado");
    }
    updateStatus();
  };
  const inviertOrderCause = (dragCause, dropCause, percentageDrop) => {
    let cloneAnalyze = cloneDeep(analyze);
    let cloneCauses = cloneAnalyze.element.causes;
    let up = false;

    let actionPosition = dropCause.index + 1;
    if (percentageDrop < 51) {
      up = true;
      actionPosition = dropCause.index - 1;
      //drop encima
      if (dropCause.index - 1 === dragCause.index) {
        //drop en el mismo sitio
        return false;
      } else {
      }
    }

    for (let i = 0; i < cloneCauses.length; i++) {
      if (dragCause.id === cloneCauses[i].id) {
        cloneCauses[i].index = actionPosition;
      } else {
        if (actionPosition === i) {
          if (up) {
            cloneCauses[i].index = i - 1;
          } else {
            cloneCauses[i].index = i + 1;
          }
        } else {
          cloneCauses[i].index = i;
        }
      }
    }

    let newCausesSort = cloneCauses.sort(orderSort);
    updateOrderCauses(newCausesSort);
    setanalyze(cloneAnalyze);
  };
  const updateOrderCauses = (causes) => {
    for (let c = 0; c < causes.length; c++) {
      const cause = causes[c];
      client()
        .post(`audit/cause_method/${cause.id}`, { order: c })
        .then(({ data }) => {})
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al reorganizar las causas");
          }
        });
    }
  };

  const orderSort = (a, b) => {
    if (a.index > b.index) return 1;
    if (b.index > a.index) return -1;

    return 0;
  };

  return (
    <ActionPlanFormContext.Provider
      value={[
        {
          analyze,
          details,
          newCorrection,
          modalCorrection,
          filesCorrectionsModal,
          filesActionsModal,
          itemDeploy,
          actionDeploy,
          modalAction,
          notesAction,
          modalEffective,
          filesAnalyzeEffective,
          newDateAnalyze,
          scroll,
          textFirstCause,
          causeDrag,
        },
        {
          setanalyze,
          uploadDocument,
          addNewCorrection,
          deleteCorrection,
          setFieldCorrections,
          updateAnalyze,
          syncProcess,
          setNote,
          updateOrCreateNote,
          addAction,
          setnewCorrection,
          setmodalCorrection,
          addNoteCorrection,
          setfilesCorrectionsModal,
          setfilesActionsModal,
          deleteEvidenceCorrection,
          addCause,
          setFieldCause,
          setFieldActions,
          updatFormAction,
          setDeploy,
          setDeployAction,
          deleteAction,
          setmodalAction,
          addNoteAction,
          deleteEvidenceAction,
          deleteEvidenceEffective,
          sendEmailAnalyze,
          deleteCause,
          setmodalEffective,
          addNoteAnalyzeEffective,
          setfilesAnalyzeEffective,
          setErrors,
          isResponsable,
          haveActions,
          haveCorrections,
          canViewCorrection,
          canDeleteCorrection,
          canDeleteEvidence,
          canViewAction,
          setnewDateAnalyze,
          setscroll,
          updateCause,
          settextFirstCause,
          setcauseDrag,
          inviertOrderCause,
          isResponsablePqrs,
          checkUpdateStatusCause,
          haveResponsablePqrs
        },
      ]}
    >
      {children}
    </ActionPlanFormContext.Provider>
  );
};
