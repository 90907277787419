import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import "moment/locale/es";
import "../specialties/index.css";
import { useHistory } from "react-router-dom";
import { SpecialtiesIndexContext } from "../../../contexts/audit/specialties/SpecialtiesIndexContext";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import CreateSpecialty from "./Modal";

const initialState = {
  toggle_modal: false,
  specialties_id: null
};

const Index = ({ computedMatch: { path } }) => {
  const [{ specialties }, { deleteSpecialty }] = useContext(
    SpecialtiesIndexContext
  );
  let [{ user }, {}] = useContext(SecurityContext);
  let [state, setState] = useState(initialState);
  let history = useHistory();

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!"
    }).then(result => {
      if (result.value) {
        eval(functionYes);
        setState({ ...state, specialties_id: null });
      } else {
        eval(functionNot);
      }
    });
  };

  return (
    <div>
      {state.toggle_modal && (
        <div className="back-document">
          <div className="modal-specialty">
            <div className="header-modal-specialty">
              <div
                className="salir-audit"
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal: false,
                    specialties_id: null
                  });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                {state.specialties_id !== null
                  ? "Editar Especialidad"
                  : "Nueva Especialidad"}
              </h3>
            </div>
            <div>
              <CreateSpecialty
                state={state}
                setState={setState}
                specialties_id={state.specialties_id}
              ></CreateSpecialty>
            </div>
          </div>
        </div>
      )}
      <div className="flex content-title-specialties">
        <h2 className="tipo-title title-programs">Especialidades</h2>
        <div className="flex">
          <div
            className="cursor-action create-specialty-index bg-orange"
            onClick={() => {
              setState({ ...state, toggle_modal: true });
            }}
            title="Crear Especialidad"
          >
            Crear Especialidad
          </div>
        </div>
      </div>
      <div className="table-programs">
        <table className="table table-hover">
          <thead className="thead-programs">
            <tr className="txt-center">
              <th>NOMBRE</th>
              <th>DESCRIPCIÓN</th>
              <th>ACCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {specialties.elements.map(spe => (
              <tr className="txt-center tr-table-programs" key={spe.id}>
                <td>{spe.name}</td>
                <td>{spe.description}</td>
                <td className="btn-action-program">
                  <img
                    className="cursor-action"
                    onClick={() => {
                      setState({
                        ...state,
                        toggle_modal: true,
                        specialties_id: spe.id
                      });
                    }}
                    title="Editar especialidad"
                    src={btn_edit}
                  />
                  &nbsp;
                  <img
                    className="cursor-action"
                    onClick={() => {
                      checkDelete(
                        "¿Realmente deseas eliminar a esta especialidad?",
                        `deleteSpecialty("${spe.id}")`
                      );
                    }}
                    title="Eliminar especialidad"
                    src={btn_delete}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
