import React from "react";

import "./ListMobile.css";

import List from "./List.jsx";
const ListMobile = () => {
  return (
    <div className="bg-white">
      <div>
       
        <List  mobile={true}  />
      </div>
    </div>
  );
};

export default ListMobile;
