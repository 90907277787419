import React, { useContext, useState, useEffect } from "react";
import {
  TopicPointIndexContext,
  TopicPointIndexContextProvider,
} from "../../../contexts/library/TopicPointIndexContext";
import "./Index.css";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import imgError from "../../../default.jpg";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import Select, { components } from "react-select";
const initialState = {
  months: [
    { value: 1, name: "Enero" },
    { value: 2, name: "Febrero" },
    { value: 3, name: "Marzo" },
    { value: 4, name: "Abril" },
    { value: 5, name: "Mayo" },
    { value: 6, name: "Junio" },
    { value: 7, name: "Julio" },
    { value: 8, name: "Agosto" },
    { value: 9, name: "Septiembre" },
    { value: 10, name: "Octubre" },
    { value: 11, name: "Noviembre" },
    { value: 12, name: "Diciembre" },
  ],
  year: [
    { value: 2020, name: "2020" },
    { value: 2019, name: "2019" },
    { value: 2018, name: "2018" },
  ],
};
const Index = () => {
  const [
    { topics, topicselected, points, dateFilter },
    { settopicselected, setdateFilter, setpoints },
  ] = useContext(TopicPointIndexContext);
  const [{ user }, {}] = useContext(SecurityContext);
  const [totalModule, settotalModule] = useState(0);
  const [montOption, setmontOption] = useState({
    value: moment().format("MM"),
    name: moment().format("MMMM"),
  });
  const [yearOption, setyearOption] = useState({
    value: moment().format("YYYY"),
    name: moment().format("YYYY"),
  });
  useEffect(() => {
    if (topics.status === STATUS_LOADED && !topicselected.id) {
      if (topics.elements.length > 0) {
        checkTopicSelected(topics.elements[0]);
      }
    }
  }, [topics]);
  useEffect(() => {
    if (topicselected.id) {
      checkTopicSelected(topicselected);
    } else {
    }
  }, [points]);
  const checkTopicSelected = (topic) => {
    let findTopic = user.topics_admin.filter((t) => t.id === topic.id);

    if (user.is_admin || findTopic) {
      let point = 0;

      let topicClone = cloneDeep(topic);
      for (let i = 0; i < topicClone.admins_topic.length; i++) {
        const admin = topicClone.admins_topic[i];
        admin.points = points.elements.filter(
          (p) => p.person_id === admin.id && p.topic_id == topic.id
        );

        console.log(admin.points);
        for (let p = 0; p < admin.points.length; p++) {
          const head = admin.points[p];
          const config = JSON.parse(head.status_config);
          for (
            let t = 0;
            t <
            head.topic_points_details.filter((h) => h.topic_id === topic.id)
              .length;
            t++
          ) {
            const detail = head.topic_points_details[t];
            let percentage = config.percentage.filter(
              (p) => p.code == head.code_month
            )[0].percentage;

            point = point + (percentage * detail.points) / 100;
          }
        }
      }

      settopicselected(topicClone);
      settotalModule(point);
    }
  };
  const getpoints = (points) => {
    let point = 0;
    for (let p = 0; p < points.length; p++) {
      const head = points[p];
      const config = JSON.parse(head.status_config);
      for (
        let t = 0;
        t <
        head.topic_points_details.filter((h) => h.topic_id === topicselected.id)
          .length;
        t++
      ) {
        const detail = head.topic_points_details[t];
        let percentage = config.percentage.filter(
          (p) => p.code == head.code_month
        )[0].percentage;

        point = point + (percentage * detail.points) / 100;
      }
    }
    return point;
  };
  const getpointsMonth = (month) => {
    let object = {
      withOutPercentage: 0,
      withPercentage: 0,
    };
    if(month){
      const config = JSON.parse(month.status_config);
     
      for (
        let t = 0;
        t <
        month.topic_points_details.filter((h) => h.topic_id === topicselected.id)
          .length;
        t++
      ) {
        const detail = month.topic_points_details[t];
        let percentage = config.percentage.filter(
          (p) => p.code == month.code_month
        )[0].percentage;
  
        object.withPercentage =
          object.withPercentage + (percentage * detail.points) / 100;
        object.withOutPercentage = object.withOutPercentage + detail.points;
      }
      
    }
    return object;
   
  };
  const getpointsAllAdmin = (points) => {
    let object = {
      withOutPercentage: 0,
      withPercentage: 0,
    };
    for (let p = 0; p < points.length; p++) {
      const month = points[p];
      const config = JSON.parse(month.status_config);

      for (
        let t = 0;
        t <
        month.topic_points_details.filter(
          (h) => h.topic_id === topicselected.id
        ).length;
        t++
      ) {
        const detail = month.topic_points_details[t];
        let percentage = config.percentage.filter(
          (p) => p.code == month.code_month
        )[0].percentage;

        object.withPercentage =
          object.withPercentage + (percentage * detail.points) / 100;
        object.withOutPercentage = object.withOutPercentage + detail.points;
      }
    }
    return object;
  };
  const getNumberByMonthByActivity = (admin, keyActivity, keyMonth) => {
    let object = {
      number: 0,
      points: 0,
    };
    let codeActivity = keyActivity === -1 ? "new" : 5 - keyActivity + "star";
    if (topicselected.id) {
      if (admin.points.length > 0) {
        let month = admin.points.filter(
          (p) => p.code_month == "m" + keyMonth
        )[0];
        if (month) {
          let documents = month.topic_points_details.filter(
            (p) =>
              p.topic_id === topicselected.id && p.code_activity == codeActivity
          );
          for (let d = 0; d < documents.length; d++) {
            const document = documents[d];
            let config = JSON.parse(month.status_config);
            let percentage = config.percentage.filter(
              (p) => p.code === "m" + keyMonth
            )[0].percentage;
            object.points = object.points + document.points;
          }

          object.number = documents.length;
        }
      }
    }
    return object;
  };

  return (
    <div className="general-container-topic-point">
      <div className="container-points-topic flex-column">
        <div className="">
          <div className="flex justify-end">
            <div className="background-simple img-rank-topic"></div>
            <h3 className="title-ranking-topic">
              {" "}
              RANKING POR CONSULTOR Y MÓDULO
            </h3>
          </div>
        </div>

        <div className="flex container-general-selelect-points-topic ">
          <div className="flex-1 form-group ">
            <Select
              onChange={(e) => {
                setyearOption(e);
                setdateFilter({ ...dateFilter, year: e.value });
                setpoints({ ...points, status: STATUS_NOT_LOADED });
              }}
              defaultValue={yearOption}
              className="tipo-description flex-1 select-topic-point"
              placeholder={"Seleccione un año"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.value}
              options={initialState.year}
            />
          </div>

          <div className="flex-1 form-group ">
            <Select
              onChange={(e) => {
                setmontOption(e);
                setdateFilter({ ...dateFilter, month: e.value });
                setpoints({ ...points, status: STATUS_NOT_LOADED });
              }}
              defaultValue={montOption}
              className="tipo-description flex-1 select-topic-point"
              placeholder={"Seleccione un mes"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.value}
              options={initialState.months}
            />
          </div>
          <div className="flex-1 white bg-black button-select-topic-points form-group">
            SELECCIONE AÑO Y MES A EVALUAR
          </div>
        </div>

        <div className="flex-column">
          <div className="flex container-head-consultor-topic-point">
            <div className="flex-1 txt-center">MÓDULO</div>
            <div className="flex-1 txt-center">CONSULTOR</div>
            <div className="flex-1 txt-center">PUNTAJE</div>
            <div className="flex-1 txt-center">PARTICIPACION</div>
          </div>
        </div>

        <div className="flex">
          <div className="flex-column flex-1 container-topics-general-points-topic">
            {topics.elements.map((topic) => {
              return (
                <div
                  onClick={() => checkTopicSelected(topic)}
                  className={`flex container-topic-tarjet-points ${
                    topicselected.id !== topic.id ? "gray-scale" : ""
                  }`}
                  key={topic.id}
                >
                  <img
                    className="img-topic-point"
                    src={
                      topic.image &&
                      process.env.REACT_APP_API_HOST +
                        "documents/" +
                        topic.image.name
                    }
                    onError={(e) => {
                      e.target.src = imgError;
                    }}
                  />
                  <div className="margin-auto txt-topic-name-points">
                    {topic.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex-column flex-3">
            {topicselected.id &&
              topicselected.admins_topic.map((admin) => {
                return (
                  <div className="line-points-topic" key={admin.id}>
                    <div className="flex">
                      <div className="flex flex-1 radius-orange-topic-points">
                        {" "}
                        <img
                          className="img-admin-point"
                          src={
                            admin.image &&
                            process.env.REACT_APP_API_HOST +
                              "documents/" +
                              admin.image.name
                          }
                          onError={(e) => {
                            e.target.src = imgError;
                          }}
                        />
                        <span>
                          {admin.first_name} {admin.last_name}
                        </span>
                      </div>
                      <div className="flex flex-1 radius-orange-topic-points justify-center">
                        {getpoints(admin.points)}
                      </div>
                      <div className="flex flex-1 radius-orange-topic-points justify-center">
                        {getpoints(admin.points) == 0
                          ? (0).toFixed(2)
                          : (
                              (100 * getpoints(admin.points)) /
                              totalModule
                            ).toFixed(2)}
                        %
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="flex line-points-topic">
              <div className="flex-1 txt-center radius-orange-topic-points">
                TOTAL PUNTOS
              </div>
              <div className="flex-1 txt-center radius-orange-topic-points">
                {totalModule}
              </div>
              <div className="flex-1 radius-orange-topic-points"></div>
            </div>
          </div>
        </div>
        <div className="flex-column">
          <div className="bg-black white txt-center hearder-consolidado-points-topic">
            CONSOLIDADO POR ASESOR
          </div>

          <div className="flex ">
            <div>
              <div className="flex-1 flex txt-center radius-orange-topic-points justify-center">
                MES Y AÑO
              </div>
              <div className="flex-1 flex ">&nbsp;</div>
              <table className="table-dates-points-topic">
                <tbody>
                  {Array(8)
                    .fill(1)
                    .map((value, key) => {
                      return (
                        <tr>
                          <th>
                            {" "}
                            {moment(
                              dateFilter.year + "-" + dateFilter.month + "-01"
                            )
                              .subtract(key, "months")
                              .format("MMMM")}{" "}
                            de{" "}
                            {moment(
                              dateFilter.year + "-" + dateFilter.month + "-01"
                            )
                              .subtract(key, "months")
                              .format("YYYY")}
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className="flex-1 flex txt-center">Total</div>
            </div>
            {topicselected.id &&
              topicselected.admins_topic.map((admin, index) => {
                return (
                  <div className="flex flex-1 flex-column" key={admin.id}>
                    <div className="flex radius-orange-topic-points justify-center">
                      <div className="flex">
                        {" "}
                        <img
                          className="img-admin-point"
                          src={
                            admin.image &&
                            process.env.REACT_APP_API_HOST +
                              "documents/" +
                              admin.image.name
                          }
                          onError={(e) => {
                            e.target.src = imgError;
                          }}
                        />
                        <span>
                          {admin.first_name} {admin.last_name}
                        </span>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="flex-1 txt-center bg-black white hearder-consolidado-points-topic">
                        DEL MES
                      </div>
                      <div className="flex-1 txt-center  bg-black white hearder-consolidado-points-topic">
                        TRASFERIDOS
                      </div>
                    </div>
                    {admin.points.map((month, indexM) => {
                      return (
                        <div
                          key={month}
                          className={`flex txt-center ${
                            indexM % 2 ? "style-points-1" : "style-points-2"
                          }`}
                        >
                          <div className="flex-1 txt-center">
                            {getpointsMonth(month).withOutPercentage}
                          </div>
                          <div className="flex-1 txt-center">
                            {getpointsMonth(month).withPercentage}
                          </div>
                        </div>
                      );
                    })}
                    <br />
                    <div className="flex txt-center">
                      <div className="flex-1 txt-center">
                        {getpointsAllAdmin(admin.points).withOutPercentage}
                      </div>
                      <div className="flex-1 txt-center">
                        {getpointsAllAdmin(admin.points).withPercentage}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="txt-center bg-black white hearder-consolidado-points-topic">
          DETALLE POR CONSULTOR Y PUBLICACIONES
        </div>
        <div className="container-topics-general-points-topic">
          {Array(8)
            .fill(1)
            .map((value, keyM) => {
              return (
                <div key={keyM}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">
                          {" "}
                          {moment(
                            dateFilter.year + "-" + dateFilter.month + "-01"
                          )
                            .subtract(keyM, "months")
                            .format("MMMM")}
                        </th>
                        <th scope="col">#Publi</th>
                        <th scope="col">Punt.publi</th>

                        {Array(5)
                          .fill(1)
                          .map((value, key) => {
                            return (
                              <>
                                <th scope="col">
                                  <div className="flex">
                                    {5 - key}
                                    <span className="star-points-topic"></span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="flex">
                                    Punt.{5 - key}{" "}
                                    <span className="star-points-topic"></span>
                                  </div>
                                </th>
                              </>
                            );
                          })}
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topicselected.id &&
                        topicselected.admins_topic.map((admin, index) => {
                          return (
                            <tr
                              className={`${
                                index % 2 ? "style-points-1" : "style-points-2"
                              }`}
                            >
                              <th>
                                {" "}
                                <img
                                  className="img-admin-point"
                                  src={
                                    admin.image &&
                                    process.env.REACT_APP_API_HOST +
                                      "documents/" +
                                      admin.image.name
                                  }
                                  onError={(e) => {
                                    e.target.src = imgError;
                                  }}
                                />
                                <span>
                                  {admin.first_name} {admin.last_name}
                                </span>
                              </th>
                              <td scope="col">
                                {
                                  getNumberByMonthByActivity(
                                    admin,
                                    -1,
                                    keyM + 1
                                  ).number
                                }
                              </td>
                              <td scope="col">
                                {
                                  getNumberByMonthByActivity(
                                    admin,
                                    -1,
                                    keyM + 1
                                  ).points
                                }
                              </td>
                              {Array(5)
                                .fill(1)
                                .map((value, key) => {
                                  return (
                                    <>
                                      <td scope="col">
                                        {
                                          getNumberByMonthByActivity(
                                            admin,
                                            key,
                                            keyM + 1
                                          ).number
                                        }
                                      </td>
                                      <td scope="col">
                                        {
                                          getNumberByMonthByActivity(
                                            admin,
                                            key,
                                            keyM + 1
                                          ).points
                                        }
                                      </td>
                                    </>
                                  );
                                })}
                              <td scope="col">
                                {
                                  getpointsMonth(
                                    admin.points.filter(
                                      (p) => p.code_month == "m" + (keyM + 1)
                                    )[0]
                                  ).withOutPercentage
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Index;
