import React, { useEffect, useContext, useState } from "react";
import {
  EvaluationFormContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/audit/evaluation/EvaluationFormContext";
import "../../audit/evaluation/Item.css";
import Swal from "sweetalert2";
import moment from "moment";
import loadingImage from "../../../img_zeus/loading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faTimesCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import imgErrorDefault from "../../../img_zeus/circle-image.png";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_MOV = "mov";

const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";

const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLS = "xls";
const EXT_XLSX = "xlsx";
const MAX_FILE_SIZE = 80;
export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;
const initialState = {
  newResponse: {
    picture: {
      image: [],
      item_id: null,
    },
    note: "",
  },
};
const downloadDocument = (ref) => {
  window.open(ref, "_blank");
};
const Item = ({ computedMatch }) => {
  const { params, url, path } = computedMatch;
  let { item_id } = params;
  let history = useHistory();
  const [{ user }, {}] = useContext(SecurityContext);
  const [isEvaluator, setisEvaluator] = useState(false);
  const [
    { item, loadDocument, noteEdit },
    {
      changeItem,
      addResponseItem,
      addDocumentItem,
      addNoteItem,
      deleteNote,
      deleteDocument,
      setnoteEdit,
      updateNoteItem,
    },
  ] = useContext(EvaluationFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [picture, setPicture] = useState(initialState.newResponse.picture);
  const [newNote, setNewNote] = useState(initialState.newResponse.note);
  const [navigateNext, setnavigateNext] = useState(null);
  const [navigatePrevious, setnavigatePrevious] = useState(null);

  useEffect(() => {
    changeItem(item_id);
  }, [item_id]);
  useEffect(() => {
    if (item.status == STATUS_LOADED && item.item_id != null) {
      if (!item.element.evaluation.audit[0].is_express) {
        for (
          let p = 0;
          p < item.element.evaluation.activity.audit_participants.length;
          p++
        ) {
          const participant =
            item.element.evaluation.activity.audit_participants[p];
          if (
            participant.person_id == user.id &&
            participant.pivot.is_evaluator == true
          ) {
            setisEvaluator(true);
          }
        }
      }

      let nextItem = false;
      let previousItem = false;
      let items = item.element.evaluation.items;
      for (let i = 0; i < items.length; i++) {
        const itemFor = items[i];
        nextItem = recursiveItemsNextNavigate(itemFor, nextItem);
      }
      for (let i = items.length - 1; i >= 0; i--) {
        const itemFor = items[i];
        previousItem = recursiveItemsPreviousNavigate(itemFor, previousItem);
      }
    }
  }, [item]);

  const recursiveItemsPreviousNavigate = (itemRecursive, isPreviousItem) => {
    if (isPreviousItem && itemRecursive.auditable_item) {
      if (itemRecursive.items.length > 0 && !itemRecursive.auditable_item.required) {
        for (let i = itemRecursive.items.length - 1; i >= 0; i--) {
          const itemFor = itemRecursive.items[i];
         

          if (isPreviousItem && itemFor.auditable_item) {
            if (itemFor.auditable_item.required) {
              setnavigatePrevious(itemFor.id);
              isPreviousItem = false;
            }
          }
        }
      } else if (itemRecursive.auditable_item.required) {
        setnavigatePrevious(itemRecursive.id);
        isPreviousItem = false;
      }
      
    }
    if (item.item_id == itemRecursive.id) {
     isPreviousItem = true;
    }
    if (itemRecursive.items.length > 0) {
      for (let i = itemRecursive.items.length - 1; i >= 0; i--) {
        const childItem = itemRecursive.items[i];
        isPreviousItem = recursiveItemsPreviousNavigate(
          childItem,
          isPreviousItem
        );
      }
    }
    return isPreviousItem;
  };
  const recursiveItemsNextNavigate = (itemRecursive, isNextItem) => {
    if (isNextItem && itemRecursive.auditable_item) {
      if (itemRecursive.auditable_item.required) {
        setnavigateNext(itemRecursive.id);
        isNextItem = false;
      }
    }
    if (item.item_id == itemRecursive.id) {
      isNextItem = true;
    }
    if (itemRecursive.items.length > 0) {
      for (let i = 0; i < itemRecursive.items.length; i++) {
        const childItem = itemRecursive.items[i];
        isNextItem = recursiveItemsNextNavigate(childItem, isNextItem);
      }
    }
    return isNextItem;
  };
  useEffect(() => {
    if (picture.item_id != null && picture.image.length > 0) {
      addDocumentItem(picture);
      setPicture(initialState.newResponse.picture);
    }
  }, [picture]);
  const uploadImage = (item_id) => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_FILE_SIZE) {
      alertWarning("El tamaño excede la capacidad");
      return false;
    }
    setPicture({
      ...picture,
      image: [...picture.image, file],
      item_id: item_id,
    });
  };
  const valdiateNote = () => {
    if (newNote == "") {
      return false;
    }
    addNoteItem({ item_id: item.element.id, note: newNote });
    setNewNote("");
  };
  const validateDelete = (note_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta nota?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteNote(note_id, item.element.id);
      } else {
      }
    });
  };
  const validateClassOption = (option, item) => {
    if (item.results.length > 0) {
      if (option.is_valid == true) {
        if (item.results[0].is_valid == true) {
          return "icon-like-green-item-mobile";
        } else {
          return "icon-like";
        }
      } else if (option.is_valid == false) {
        if (item.results[0].is_valid == false) {
          return "icon-dislike-green-item-mobile";
        } else {
          return "icon-dislike";
        }
      } else if (option.is_valid == null) {
        if (item.results[0].is_valid == null) {
          return "icon-notApply-green-item-mobile";
        } else {
          return "icon-notApply";
        }
      }
    } else {
      return option.is_valid == true
        ? "icon-like"
        : option.is_valid == false
        ? "icon-dislike"
        : "icon-notApply";
    }
  };
  return (
    <>
    <h6 className="  mobile-hidden">Disponible en vista mobile</h6>
      <div className="container-show-item-mobile hidden">
        <div className="flex bg-orange container-show-item-title-mobile white">
          <span>
            {item.element.auditable_item && item.element.auditable_item.number}
          </span>
          &nbsp;
          <p className="tipo-description">{item.element.title}</p>
        </div>
        <div className="flex-column container-personalized-evaluation-item-mobile">
          {item.element.items &&
            item.element.items.map((subitem, index) => {
              return (
                <div
                  key={subitem.id}
                  className="flex item-personalized-mobile bg-white"
                >
                  <div className="flex white bg-orange">
                    <p className="margin-auto">{index + 1}</p>
                  </div>
                  <div className="flex-1 flex">
                    <p className="color-gray flex tipo-description">
                      {subitem.title}
                    </p>
                  </div>
                  {item.element.evaluation.audit[0].standard.item_types
                    .length === 0 && (
                    <div className="flex container-personalized-item-mobile">
                      {subitem.options &&
                        subitem.options.map((option) => {
                          return (
                            <div
                              onClick={() =>
                                addResponseItem({
                                  item_id: subitem.id,
                                  option_id: option.id,
                                })
                              }
                              key={option.id}
                              className={`${validateClassOption(
                                option,
                                subitem
                              )}`}
                            ></div>
                          );
                        })}

                      {/*  <div className="flex icon-images">
   <ImageUploader
     withIcon={true}
     className="upload-image-evaluation-item-mobile"
     buttonClassName="btn-upload-evaluation-personalized-item-mobile"
     buttonText=""
     imgExtension={[
       ".jpg",
       ".jpeg",
       ".gif",
       ".png",
       ".gif",
       ".pdf"
     ]}
     maxFileSize={5242880}
     withIcon={false}
     withPreview={false}
     onChange={uploadImage(subitem.id)}
     fileSizeError="Excede el peso permitido"
     fileTypeError="Extensión no permitida"
     singleImage={true}
     label="Peso máximo 5mb,acepta:jpg,gif,png"
   />
 </div> */}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="flex flex-1">
          {item.element &&
            item.element.documents &&
            item.element.documents.map((document) => {
              return (
                <>
                  {(document.type == EXT_JPG ||
                    document.type == EXT_JPEG ||
                    document.type == EXT_PNG) && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      {!item.element.evaluation.audit[0].is_express &&
                        !item.element.evaluation.activity.is_closed && (
                          <FontAwesomeIcon
                            onClick={() =>
                              deleteDocument(document.id, item.element.id)
                            }
                            icon={faTimesCircle}
                          />
                        )}
                      <img
                        onClick={() =>
                          history.push(
                            `../../../../app/library/document/${document.id}`
                          )
                        }
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          document.name
                        }
                        onError={(e) => {
                          e.target.src = imgErrorDefault;
                        }}
                        alt=""
                      />
                    </div>
                  )}

                  {document.type == EXT_PDF && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {!item.element.evaluation.audit[0].is_express &&
                        item.element.evaluation.activity.is_closed && (
                          <FontAwesomeIcon
                            onClick={() =>
                              deleteDocument(document.id, item.element.id)
                            }
                            icon={faTimesCircle}
                          />
                        )}
                      {item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      <div
                        className="cursor-action btn-pdf-observation btn-files-observations"
                        onClick={() =>
                          history.push(
                            `../../../../app/library/document/${document.id}`
                          )
                        }
                      ></div>
                    </div>
                  )}
                  {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {!item.element.evaluation.audit[0].is_express &&
                        !item.element.evaluation.activity.is_closed && (
                          <FontAwesomeIcon
                            onClick={() =>
                              deleteDocument(document.id, item.element.id)
                            }
                            icon={faTimesCircle}
                          />
                        )}
                      {item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      <div
                        className="cursor-action btn-word-observation btn-files-observations"
                        onClick={() =>
                          downloadDocument(
                            process.env.REACT_APP_API_HOST +
                              "documents/" +
                              document.name
                          )
                        }
                      ></div>
                    </div>
                  )}
                  {(document.type == EXT_XLSX || document.type == EXT_XLS) && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {!item.element.evaluation.audit[0].is_express &&
                        !item.element.evaluation.activity.is_closed && (
                          <FontAwesomeIcon
                            onClick={() =>
                              deleteDocument(document.id, item.element.id)
                            }
                            icon={faTimesCircle}
                          />
                        )}
                      {item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      <div
                        className="cursor-action btn-excell-observation btn-files-observations"
                        onClick={() =>
                          downloadDocument(
                            process.env.REACT_APP_API_HOST +
                              "documents/" +
                              document.name
                          )
                        }
                      ></div>
                    </div>
                  )}
                  {document.type == EXT_MP3 && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {!item.element.evaluation.activity.is_closed && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      <div
                        className="cursor-action btn-mp3-observation btn-files-observations"
                        onClick={() =>
                          history.push(
                            `../../../../app/library/document/${document.id}`
                          )
                        }
                      ></div>
                    </div>
                  )}
                  {(document.type == EXT_MP4 || document.type == EXT_MOV) && (
                    <div
                      className="container-img-item-mobile flex "
                      key={document.id}
                    >
                      {item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() =>
                            deleteDocument(document.id, item.element.id)
                          }
                          icon={faTimesCircle}
                        />
                      )}
                      {!item.element.evaluation.audit[0].is_express &&
                        !item.element.evaluation.activity.is_closed && (
                          <FontAwesomeIcon
                            onClick={() =>
                              deleteDocument(document.id, item.element.id)
                            }
                            icon={faTimesCircle}
                          />
                        )}
                      <div
                        className="cursor-action btn-mp4-observation btn-files-observations"
                        onClick={() =>
                          history.push(
                            `../../../../app/library/document/${document.id}`
                          )
                        }
                      ></div>
                    </div>
                  )}
                </>
              );
            })}
        </div>

        {item.status == STATUS_LOADED &&
          !item.element.evaluation.audit[0].is_express &&
          !item.element.evaluation.activity.is_closed && (
            <div className="flex bg-white container-add-note-item-mobile flex-column">
              <div className="flex justify-end">
                <div
                  className="btn-save-note-item background-simple"
                  onClick={() => valdiateNote()}
                ></div>
              </div>
              <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Nueva nota"
                className="tipo-cursiva flex"
              ></textarea>
            </div>
          )}
        {item.status == STATUS_LOADED &&
          item.element.evaluation.audit[0].is_express && (
            <div className="flex bg-white container-add-note-item-mobile flex-column">
              <div className="flex justify-end">
                <div
                  className="btn-save-note-item background-simple"
                  onClick={() => valdiateNote()}
                ></div>
              </div>
              <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Nueva nota"
                className="tipo-cursiva flex"
              ></textarea>
            </div>
          )}

        {item.element.notes &&
          item.element.notes.map((note) => {
            return (
              <div
                key={note.id}
                className="container-notes-item-mobile flex flex-column"
              >
                <div className="flex tipo-cursiva date-note-mobile-item">
                  <span>
                    {moment(note.created_at).format("YYYY-MM-DD HH:mm a")}
                  </span>
                  <div className="flex">
                    {item.status == STATUS_LOADED &&
                      !item.element.evaluation.audit[0].is_express &&
                      !item.element.evaluation.activity.is_closed && (
                        <FontAwesomeIcon
                          onClick={() => validateDelete(note.id)}
                          className="flex"
                          icon={faMinusCircle}
                        />
                      )}
                    {item.status == STATUS_LOADED &&
                      item.element.evaluation.audit[0].is_express && (
                        <FontAwesomeIcon
                          onClick={() => validateDelete(note.id)}
                          className="flex"
                          icon={faMinusCircle}
                        />
                      )}
                    &nbsp;&nbsp;
                    {noteEdit.id != note.id && (
                      <FontAwesomeIcon
                        onClick={() =>
                          setnoteEdit({
                            note: note.note,
                            id: note.id,
                          })
                        }
                        className="flex"
                        title="Editar"
                        icon={faEdit}
                      />
                    )}
                  </div>
                </div>

                <span className="text-note-mobile-item tipo-description">
                  {noteEdit.id == note.id && (
                    <textarea
                      className="w-100"
                      value={noteEdit.note}
                      onChange={(e) =>
                        setnoteEdit({
                          ...noteEdit,
                          note: e.target.value,
                        })
                      }
                    ></textarea>
                  )}
                  {noteEdit.id == note.id && (
                    <div
                      onClick={() => updateNoteItem(note.item_id)}
                      className="btn-add-note-evaluation cursor-action background-simple"
                    ></div>
                  )}

                  {noteEdit.id != note.id && note.note}
                </span>
              </div>
            );
          })}
        <div className="control-end-margin-item-evaluation-mobile"></div>
      </div>

      <div className="hidden flex container-calificable-item-evaluation-mobile">
        
        {item.status === STATUS_LOADED &&
          !item.element.evaluation.audit[0].is_express &&
          navigatePrevious &&
          navigatePrevious != item.element.id &&
          isEvaluator && (
            <div
              onClick={() => changeItem(navigatePrevious)}
              className="flex arround-left-item-mobile background-simple"
            ></div>
          )}
        {item.status === STATUS_LOADED &&
          item.element.evaluation.audit[0].is_express &&
          navigatePrevious &&
          navigatePrevious != item.element.id && (
            <div
              onClick={() => changeItem(navigatePrevious)}
              className="flex arround-left-item-mobile background-simple"
            ></div>
          )}

        {item.status === STATUS_LOADED && (
          <>
            {!item.element.evaluation.audit[0].is_express &&
              item.element.evaluation.audit[0].status_id ===
                STATUS_INPROGRESS &&
              isEvaluator &&
              item.element.options &&
              item.element.options.map((option) => {
                return (
                  <div
                    onClick={() =>
                      addResponseItem({
                        item_id: item.element.id,
                        option_id: option.id,
                      })
                    }
                    key={option.id}
                    className={`${validateClassOption(option, item.element)}`}
                  ></div>
                );
              })}
            {item.element.evaluation.audit[0].is_express &&
              item.element.options &&
              item.element.options.map((option) => {
                return (
                  <div
                    onClick={() =>
                      addResponseItem({
                        item_id: item.element.id,
                        option_id: option.id,
                      })
                    }
                    key={option.id}
                    className={`${validateClassOption(option, item.element)}`}
                  ></div>
                );
              })}
          </>
        )}
        {item.status === STATUS_LOADED &&
          (item.element.evaluation.audit[0].status_id === STATUS_INPROGRESS ||
            item.element.evaluation.audit[0].is_express) && (
            <div
              className={`flex icon-images ${
                item.element.documents && item.element.documents.length > 0
                  ? "image-documents-item-mobile-active"
                  : ""
              }`}
            >
              {loadDocument.status == STATUS_LOADED && (
                <>
                  <input
                    onChange={uploadImage(item.element.id)}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile-final-report"
                  />
                  <label
                    className="background-simple label-file-item-mobile"
                    htmlFor="file"
                  ></label>

                  <div className="flex number-images-item-mobile">
                    <span className="margin-auto">
                      {item.element.documents && item.element.documents.length}
                    </span>
                  </div>
                </>
              )}

              {loadDocument.item_id == item.element.id &&
                loadDocument.status == STATUS_LOADING && (
                  <img
                    src={loadingImage}
                    className="image-loading-item-desktop"
                  />
                )}
            </div>
          )}

        {item.status === STATUS_LOADED &&
          !item.element.evaluation.audit[0].is_express &&
          navigateNext &&
          navigateNext != item.element.id &&
          isEvaluator && (
            <div
              onClick={() => changeItem(navigateNext)}
              className="flex arround-right-item-mobile background-simple"
            ></div>
          )}
        {item.status === STATUS_LOADED &&
          item.element.evaluation.audit[0].is_express &&
          navigateNext &&
          navigateNext != item.element.id && (
            <div
              onClick={() => changeItem(navigateNext)}
              className="flex arround-right-item-mobile background-simple"
            ></div>
          )}
      </div>
    </>
  );
};

export default Item;
