import React, { useEffect, useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import CreateTemplate from "../modals/CreateTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconShow from "../../../../src/img_zeus/icons_audit/ICONO-VER.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Score from "../../library/topic/folder/Score";
import moment from "moment";
import {
  TemplateOnSaleContext,
  STATUS_LOADED
} from "../../../contexts/audit/TemplateContext/TemplateOnSaleIndex";
const TemplatesOnSale = () => {
  let history = useHistory();
  const [{ templates }] = useContext(TemplateOnSaleContext);
  let [{ user }, {}] = useContext(SecurityContext);
  const [search, setSearch] = useState("");
  const [state, setstate] = useState([]);
  const changeSearch = field => e => {
    let body = e.target.value;
    setSearch(body);
    let newTemplates = [...templates.elements];
    if (search != "") {
      newTemplates = newTemplates.filter(function(el) {
        return el.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }
    setstate(newTemplates);
  };
  useEffect(() => {
    if (templates.status === STATUS_LOADED) {
      setstate(templates.elements);
    }
  }, [templates]);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!"
    }).then(result => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          <h2 className="title-evaluation tipo-title">PLANTILLAS EN VENTA</h2>
        </div>
      </div>
      <div className="flex">
        <div>
          <input
            className="form-control tipo-description"
            onChange={changeSearch()}
            value={search}
            placeholder="Buscar"
            type="text"
          />
        </div>
      </div>
      <div className="general-content-index-evaluation back-gray-content">
        <table className="table">
          <thead>
            <tr className="header-table-index-evaluation txt-center">
              <th scope="col">TITULO</th>
              <th scope="col">PRECIO</th>
              <th scope="col">DESCRIPCIÓN</th>
              <th scope="col">NORMA</th>
              <th scope="col">CALIFICACIÓN</th>

              <th scope="col">FECHA DE CREACIÓN</th>
             {/*  <th scope="col">ACCIONES</th> */}
            </tr>
          </thead>
          <tbody className="tipo-description table-template-index bg-white">
            {state.length == 0 && (
              <tr>
                <td colSpan="6" className="txt-center">
                  No hay plantillas{" "}
                </td>
              </tr>
            )}
            {state.map(eva => (
              <tr key={eva.id} className="txt-center">
                <td>{eva.title}</td>
                <td>$ {eva.cost}</td>

                <td>{eva.description}</td>
                <td>
                  {eva.evaluation_standard &&
                    eva.evaluation_standard.short_name}
                </td>
                <td> {<Score score={eva.valorable && eva.valorable} />}</td>
                <td>{moment(eva.created_at).format("YYYY-MM-DD")}</td>
               {/*  <td>
                  &nbsp; &nbsp;
                  <Link to={`/app/audit/template/${eva.id}`}>
                    <img
                      src={iconShow}
                      className="icon-actions"
                      title="ver plantilla"
                    />
                  </Link>
                  &nbsp; &nbsp;
                  <img
                    src={iconDelete}
                    className="cursor-action icon-actions"
                    onClick={() =>
                      checkDelete(
                        "¿Realmente deseas eliminar esta plantilla?",
                        `deleteTemplate(${eva.id})`
                      )
                    }
                    title="eliminar plantilla"
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TemplatesOnSale;
