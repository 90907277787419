import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
const FinishAudit = () => {
  
  return (
    <div className="flex flex-column">
   
    <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditor Líder</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Evidencie el cumplimiento de todas las actividades y ejecución de
            lista de verificación.
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Reciba y analice los resultados y evidencias de los auditores de
            apoyo
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Obtenga el informe final generado en automático por el sistema.
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Informe final, con links a evidencias y videos almacenados con
            seguridad en la plataforma.
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Realice ajustes el informe final y envíelo con trazabilidad y
            confidencialidad de la información.
          </div>
        </div>
      </div>
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Responsable del S.G.</p>
      </div>
      <div className="flex-column">

      <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Reciba el informe final, con trazabilidad y
confidencialidad
          </div>
        </div>
        <div className="border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Realice observaciones al informe final y
solicite ajustes si los requiere
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Apruebe el informe final y cierre auditoría.
          </div>
        </div>
        <div className="margin-help-bottom border-green-help flex bg-white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Conserve toda su auditoría, con seguridad y
confidencialidad, según roles de usuario.
          </div>
        </div>

      </div>
    </div>
  );
};

export default FinishAudit;
