import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  status: STATUS_NOT_LOADED,
  programs: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    init_date: false,
    end_date: false,
    filterGeneral: null,
  },
  errors: {
    error: [],
    status: false,
  },
};

export const ProgramIndexContext = React.createContext([]);

export const ProgramIndexContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [programs, setPrograms] = useState(initialState.programs);
  let [filter, setFilter] = useState(initialState.filter);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();

  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  // componteDidMointed & componentDidUpdated

  useEffect(() => {
    if (programs.status === STATUS_NOT_LOADED) {
      setPrograms({ ...programs, status: STATUS_LOADING });
      client()
        .get("audit/program", {
          params: {
            with: ["company.contact"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setPrograms({ ...programs, status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los programas");
          }
        });
    }
  }, [programs]);

  const filterInit = () => {
    let filterInit = programs.elements.sort(function (a, b) {
      var c = new Date(a.init_date);
      var d = new Date(b.init_date);
      if (filter.init_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setPrograms({ ...programs, elements: filterInit });
    setFilter({ ...filter, init_date: !filter.init_date });
  };

  const filterEnd = () => {
    let filterEnd = programs.elements.sort(function (a, b) {
      var c = new Date(a.end_date);
      var d = new Date(b.end_date);
      if (filter.end_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setPrograms({ ...programs, elements: filterEnd });
    setFilter({ ...filter, end_date: !filter.end_date });
  };

  const createProgram = ({
    name,
    objetives,
    scope,
    resources,
    risk,
    criterial,
    init_date,
    end_date,
    company_id,
  }) => {
    client()
      .post("audit/program", {
        name: name,
        objetives: objetives,
        scope: scope,
        resources: resources,
        risk: risk,
        criterial: criterial,
        init_date: init_date,
        end_date: end_date,
        company_id: company_id.id,
      })
      .then(({ data }) => {
        alertSuccess("Programa creado satisfactoriamente");
        setPrograms({ ...programs, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el programa");
        }
      });
  };

  const updateProgram = ({
    id,
    name,
    objetives,
    scope,
    resources,
    risk,
    criterial,
    init_date,
    end_date,
    company_id,
  }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("objetives", objetives);
    formData.append("scope", scope);
    formData.append("resources", resources);
    formData.append("risk", risk);
    formData.append("criterial", criterial);
    formData.append("init_date", moment(init_date).format("YYYY-MM-DD HH:mm"));
    formData.append("end_date", moment(end_date).format("YYYY-MM-DD HH:mm"));
    formData.append("company_id", company_id.id);

    client()
      .post(`audit/program/${id}`, formData)
      .then(() => {
        alertSuccess("Programa actualizado satisfactoriamente");
        setPrograms({ ...programs, status: STATUS_NOT_LOADED });
        history.push(`/app/audit/programs`);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el programa");
        }
      });
  };

  const deleteProgram = (id) => {
    client()
      .delete(`audit/program/${id}`)
      .then(({ data }) => {
        alertSuccess("Programa eliminado satisfactoriamente");
        let el = programs.elements.filter((e) => e.id !== id);
        setPrograms({
          ...programs,
          elements: el,
        });
      });
  };

  return (
    <ProgramIndexContext.Provider
      value={[
        { programs },
        { createProgram, updateProgram, deleteProgram, filterInit, filterEnd, setPrograms },
      ]}
    >
      {children}
    </ProgramIndexContext.Provider>
  );
};
