import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import { SecurityContext } from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import firebase from "../../firebase";
const db = firebase.firestore();

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const ITEM_DEBE = "debe";
export const ITEM_QS = "qs";
export const ITEM_QL = "ql";
export const ITEM_UFILE = "ufile";
export const ITEM_MFILE = "mfile";
export const ITEM_SUR = "sur";
export const ITEM_SMMR = "smmr";
export const STATUS_IN_PROGRESS = 3;
export const STATUS_CREATED = 1;
export const STATUS_EXECUTED = 4;
export const STATUS_ERASER = 5;
export const STATUS_REJECTED = 8;
export const PQRS_COMPANY = "PQRS_COMPANY";
export const ROLE_PERSON_INVITED = "ROLE_PERSON_INVITED";
export const NOTES_REJECTED_PQRS = "rejected_pqrs";
const NOTIFICATION_MODULE_ACTION_PLAN = "action_plan";
const NOTIFICATION_ACTION_APPROVED = "action_approved";

let initialState = {
  status: STATUS_NOT_LOADED,
  company: {},
  evaluations: {
    status: STATUS_NOT_LOADED,
    pqrs: [],
  },
  statusAnalyze: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  evaluationPQRS: {
    status: STATUS_NOT_LOADED,
    evaluation: {},
  },
  newAnalize: {
    files: [],
    note: "",
    responsable_id: null,
    process_id: null,
    evaluation_id: null,
    authorized: false,
    is_new: true,
  },
  metaRowPqrs: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: [],
  },
  company_id: null,
  person: {},
  staff: [],
  errors: {
    error: [],
    status: false,
  },
};
export const CompanyFormContext = createContext([]);

export const CompanyFormContextProvider = (props) => {
  let { children } = props;
  let [company, setcompany] = useState(initialState);
  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [itemDeploy, setItemDeploy] = useState({});
  const [usersSearch, setusersSearch] = useState([]);
  const [filesPqrs, setfilesPqrs] = useState({});
  const [filesItemsPqrs, setfilesItemsPqrs] = useState({});
  const [newAnalize, setnewAnalize] = useState(initialState.newAnalize);
  const [evaluations, setevaluations] = useState(initialState.evaluations);
  const [evaluationPQRS, setevaluationPQRS] = useState(initialState.evaluationPQRS);
  const [withErrorsPqrs, setwithErrorsPqrs] = useState(false);
  const [evaluationsMonths, setevaluationsMonths] = useState([]);
  const [statusAnalyze, setstatusAnalyze] = useState(initialState.statusAnalyze);
  const [metaRowPqrs, setmetaRowPqrs] = useState(initialState.metaRowPqrs);
  const [rowsPqrs, setrowsPqrs] = useState(0);
  const [{ user }, { logout, fetchUser, refreshUser, downloadDocument }] =
    useContext(SecurityContext);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (company.status === STATUS_LOADED) {
      getEvaluations(company.company_id);
      getStatusAnalyze();
    }
  }, [company.status]);
  useEffect(() => {
    if (company.company_id != null && company.status === STATUS_NOT_LOADED) {
      setcompany({ ...company, status: STATUS_LOADING });
      client()
        .get("general/company/" + company.company_id, {
          params: {
            with: ["evaluationPqrs.evaluation", "partners", "contact", "process", "city.country"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setcompany({
              ...company,
              status: STATUS_LOADED,
              company: data,
              staff: data.partners,
            });

            if (data.evaluation_pqrs.length === 0) {
              async function fectCreatePqrs(form) {
                let dataPqrs = await createPqrsCompany(form);
              }
              fectCreatePqrs({
                title: "Ingrese aquí un título",
                description: "Ingrese aquí una descripción",
                company_id: data.id,
              });
            }
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar la compañia");
          }
        });
    }
  }, [company.company_id, company.status]);
  useEffect(() => {
    async function fetchData() {
      let newRows = await getCountPqrsEvaluations();
      setrowsPqrs(newRows);
      let newPages = Math.ceil(newRows / metaRowPqrs.limit);

      if (metaRowPqrs.page > newPages && newPages !== 0) {
        setmetaRowPqrs({ ...metaRowPqrs, page: 1 });
        return false;
      }
      getEvaluations(company.company_id);
    }
    if (company.company_id != null) {
      fetchData();
    }
  }, [metaRowPqrs, company.company_id]);
  //functions

  const getCountPqrsEvaluations = async () => {
    return client()
      .get(`audit/countPqrsCompany/${company.company_id}`, {
        params: {
          filters: metaRowPqrs.filters,
          filtersLike: metaRowPqrs.filtersLike,
        },
      })
      .then(({ data }) => {
        return data;
      });
  };

  const transformPqrsDates = (evaluations) => {
    for (let e = 0; e < evaluations.length; e++) {
      const evaluation = evaluations[e];
      evaluation.sendDate =
        moment(evaluation.updated_at).year() +
        "-" +
        moment(evaluation.updated_at).format("M") +
        "-01";
    }
    //Creamos un nuevo objeto donde vamos a almacenar por ciudades.
    let nuevoObjeto = {};
    //Recorremos el arreglo
    evaluations.forEach((x) => {
      //Si la ciudad no existe en nuevoObjeto entonces
      //la creamos e inicializamos el arreglo de evalauciones.
      if (!nuevoObjeto.hasOwnProperty(x.sendDate)) {
        nuevoObjeto[x.sendDate] = {
          evaluations: [],
        };
      }

      //Agregamos los datos de evaluaciones.
      nuevoObjeto[x.sendDate].evaluations.push(x);
    });
    setevaluationsMonths(nuevoObjeto);
  };
  const getEvaluations = (company_id) => {
    setevaluations({ ...evaluations, status: STATUS_LOADING });
    client()
      .get("audit/pqrsCompany/" + company_id, {
        params: {
          with: [
            "items.results",
            "status",
            "analyzes.responsable",
            "analyzes.actions",
            "notesPqrsRejected",
          ],
          filters: metaRowPqrs.filters,
          filtersLike: metaRowPqrs.filtersLike,
          limit: metaRowPqrs.limit,
          offset: metaRowPqrs.limit * (metaRowPqrs.page - 1),
          orderBy: metaRowPqrs.orderBy,
        },
      })
      .then(({ data }) => {
        if (data) {
          setevaluations({ status: STATUS_LOADED, pqrs: data });
          transformPqrsDates(data);
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar los pqrs");
        }
      });
  };
  const getStatusAnalyze = () => {
    setstatusAnalyze({ ...statusAnalyze, status: STATUS_LOADING });
    client()
      .get("audit/analyze/allStatus")
      .then(({ data }) => {
        if (data) {
          setstatusAnalyze({ status: STATUS_LOADED, elements: data });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar los estados del plan de acción");
        }
      });
  };
  const updateStaff = (form, staff_id) => {
    client()
      .post(`general/staff/${staff_id}`, form)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al modificar el integrante");
        }
      });
  };
  const changeResponsablePerson = (person_id) => {
    client()
      .post(`general/company/${company.company_id}`, { contact_person_id: person_id })
      .then(({ data }) => {
        alertSuccess("Propiedad transferida exitosamente");
        setcompany({ ...company, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar al transferir la propiedad");
        }
      });
  };
  const createPqrsCompany = async ({ title, description, company_id }) => {
    return client()
      .post(`audit/updatePqrsCompany/${company_id}`, {
        title,
        description,
      })
      .then(async ({ data }) => {
        setcompany({
          ...company,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear la lista pqrs");
        }
      });
  };
  const searchUsers = (text) => {
    client()
      .get(`security/searchUsers/${text}`)
      .then(({ data }) => {
        if (data) {
          setusersSearch(data);
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar los usuarios");
        }
      });
  };
  const getUserResponsable = ({ email }) => {
    client()
      .get("security/person", { params: { filters: { email } } })
      .then(({ data }) => {
        if (data.length > 0) {
          changeResponsablePerson(data[0].id);
        } else {
          alertWarning("Usuario no encontrado");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar la persona");
        }
      });
  };
  const savePqrs = async () => {
    // let keys = Object.keys(filesPqrs);
    let keysItems = Object.keys(filesItemsPqrs);
    /*     for (let k = 0; k < keys.length; k++) {
      const key = keys[k];
      let filesArray = filesPqrs[key];
      for (let d = 0; d < filesArray.length; d++) {
        const document = filesArray[d];
        const formData = new FormData();
        formData.append("document", document);
        formData.append("is_private", true);

        await client().post(
          `audit/evaluation/${evaluationPQRS.evaluation.id}/documented`,
          formData
        );
      }
    } */
    for (let k = 0; k < keysItems.length; k++) {
      const key = keysItems[k];
      let filesArray = filesItemsPqrs[key];
      for (let d = 0; d < filesArray.length; d++) {
        const document = filesArray[d];
        const formData = new FormData();
        formData.append("document", document);
        formData.append("is_private", true);
        const item_id = key.split("files")[1];
        await client()
          .post(`evaluation/item/${item_id}/documented`, formData)
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al guardar el documento");
            }
            return false;
          });
      }
    }
    let withErrorTransaction = false;
    await client()
      .get(`audit/createdPQRSStatus/${evaluationPQRS.evaluation.id}`)
      .catch((e) => {
        withErrorTransaction = true;
        if (e.request.status === 400) {
          setErrors({ error: e.response.data, status: true });
          setwithErrorsPqrs(true);
        } else if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al finalizar");
        }
      });
    if (withErrorTransaction == true) {
      return false;
    }
    Swal.fire({
      title: "Su peticion ha sido enviada",
      text: "Gracias",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Volver",
    }).then((result) => {
      setwithErrorsPqrs(false);
      window.location.reload(false);
    });
  };
  const updateImageCompany = (document) => {
    var formData = new FormData();

    formData.append("document", document);
    formData.append("is_private", false);

    client()
      .post("general/company/" + company.company_id, formData)
      .then(({ data }) => {
        setcompany({ ...company, status: STATUS_NOT_LOADED });
        alertSuccess("Datos actualizados satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la empresa");
        }
      });
  };
  const getUser = (email) => {
    client()
      .get("security/person", { params: { filters: { email } } })
      .then(({ data }) => {
        if (data.length == 0) {
          Swal.fire({
            title: "este correo no tiene usuario registrado en la plataforma",
            text: "Desea enviar invitación al usuario para que se una y adicionarlo al equipo de trabajo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#df8c37",
            cancelButtonColor: "#171e27",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, enviar!",
          }).then((result) => {
            if (result.value) {
              addStaffRegister(email);
            } else {
            }
          });
        } else {
          setcompany({ ...company, person: data[0], status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el usuario");
        }
      });
  };

  //functions

  const addStaffRegister = async (email) => {
    const userFirebase = await fetchUser(email);
    let name = email.split("@")[0];
    client()
      .post("security/person", {
        email,
        first_name: name,
        last_name: "invitado",
        password: Math.random(),
        role: ROLE_PERSON_INVITED,
        firebase_id: userFirebase.id,
      })
      .then(({ data }) => {
        setcompany({ ...company, person: data, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al registrar el usuario");
        }
      });
  };
  const addStaff = ({
    position,
    valid_date,
    person_id,
    first_name,
    last_name,
    company_id,
    isPqrsResponsable,
  }) => {
    if (valid_date == "") {
      valid_date = moment();
    }
    client()
      .post("general/staff", {
        position,
        valid_date,
        person_id,
        first_name,
        last_name,
        company_id,
        isPqrsResponsable,
      })
      .then(({ data }) => {
        alertSuccess("Integrante agregado satisfactoriamente");
        setcompany({
          ...company,
          status: STATUS_NOT_LOADED,
        });
        refreshUser();
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al agregar integrante al staff");
        }
      });
  };

  const createProcces = ({ name }) => {
    client()
      .post("general/company_procces", {
        name: name,
        company_id: company.company_id,
      })
      .then(({ data }) => {
        alertSuccess("Proceso creado satisfactoriamente");
        setcompany({ ...company, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el programa");
        }
      });
  };

  const updateProcces = ({ id, name }) => {
    client()
      .post(`general/company_procces/${id}`, {
        name: name,
      })
      .then(() => {
        alertSuccess("Proceso actualizado satisfactoriamente");
        setcompany({ ...company, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el programa");
        }
      });
  };

  const deleteProcces = (id) => {
    client()
      .delete(`general/company_procces/${id}`)
      .then(({ data }) => {
        alertSuccess("Proceso eliminado satisfactoriamente");
        let el = company.company.process.filter((e) => e.id != id);
        setcompany({
          ...company,
          company: el,
          status: STATUS_NOT_LOADED,
        });
      });
  };

  const deleteStaff = (id) => {
    client()
      .delete(`general/staff/${id}`)
      .then(({ data }) => {
        let el = company.staff.filter((e) => e.pivot.id != id);
        alertSuccess("Integrante eliminado satisfactoriamente");

        setcompany({
          ...company,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al eliminar el integrante");
        }
      });
  };

  let changeCompany = (company_id) => {
    setcompany({
      ...company,
      company_id: company_id,
    });
  };
  const searchTokenCompany = (token) => {
    setevaluationPQRS({ ...evaluationPQRS, staus: STATUS_LOADING });
    client()
      .get(`audit/searchCompanyToken/${token}`)
      .then(({ data }) => {
        if (data) {
          setevaluationPQRS({ ...evaluationPQRS, status: STATUS_LOADED, evaluation: data });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar la empresa");
        }
      });
  };
  const itemFind = (cloneEvaluationPQRS, parentItem_indexs, index) => {
    let itemFind = {};
    if (parentItem_indexs.length === 0) {
      itemFind = cloneEvaluationPQRS.evaluation.items[index];
    } else {
      let items = cloneEvaluationPQRS.evaluation.items;
      let item = {};
      for (let p = 0; p < parentItem_indexs.length; p++) {
        const position = parentItem_indexs[p];
        if (p + 1 === parentItem_indexs.length) {
          item = items[position];
        } else {
          items = items[position].items;
        }
      }

      itemFind = item.items[index];
    }
    return itemFind;
  };
  const addResponseSUR = (item, parentItem_indexs, index, option) => {
    let cloneEvaluationPQRS = cloneDeep(evaluationPQRS);
    let finditem = itemFind(cloneEvaluationPQRS, parentItem_indexs, index);
    finditem.results = [option];
    setevaluationPQRS(cloneEvaluationPQRS);
    var formData = new FormData();
    formData.append("option_id", option.id);
    client()
      .post(`evaluation/optionAttachResult/${item.id}`, formData)
      .then(({ data }) => {})
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const setTextOptionQuestion = (value, parentItem_indexs, index, option) => {
    option.pivot.response = value;
    let cloneevaluationPQRS = cloneDeep(evaluationPQRS);
    let itemfind = itemFind(cloneevaluationPQRS, parentItem_indexs, index);
    itemfind.results = [option];
    setevaluationPQRS(cloneevaluationPQRS);
    var formData = new FormData();
    formData.append("option_id", option.id);
    formData.append("response", value);
    client()
      .post(`evaluation/optionAttachResult/${itemfind.id}`, formData)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addDocumentsEvaluation = (role = null) => {
    addDocuments(
      { documents: newAnalize.files, role, is_private: true },
      `audit/evaluation/${newAnalize.evaluation_id}/documented`
    );
  };
  const addDocuments = ({ documents, role, is_private }, route) => {
    for (let d = 0; d < documents.length; d++) {
      const document = documents[d];
      const formDataDocument = new FormData();
      formDataDocument.append("document", document);
      formDataDocument.append("is_private", is_private);
      formDataDocument.append("role", role);

      client().post(route, formDataDocument);
    }
  };
  const addDocumentsNote = (role = null, note_id) => {
    addDocuments(
      { documents: newAnalize.files, role, is_private: true },
      `general/note/${note_id}/documented`
    );
  };
  const addNotesEvaluation = (role = null) => {
    if (newAnalize.note != "") {
      var formDataNote = new FormData();
      formDataNote.append("note", newAnalize.note);
      formDataNote.append("person_id", user.id);
      if (role) {
        formDataNote.append("role", role);
      }

      return client().post(
        `audit/evaluation/${newAnalize.evaluation_id}/notificated`,
        formDataNote
      );
    }
  };
  const searchField = (owner, field) => {
    console.log(owner);
    let items = owner.items.filter((i) => i.description == field);
    if (items.length > 0) {
      if (items[0].results.length > 0) {
        return items[0].results[0].pivot.response;
      }
    }
  };
  const generateAnlyze = async () => {
    let evaluation = evaluations.pqrs.filter(
      (ow) => ow.id == newAnalize.evaluation_id
    )[0];

    const formData = new FormData();
    formData.append("company_id", company.company_id);
    formData.append("responsable_id", newAnalize.responsable_id);
    formData.append("authorized", newAnalize.authorized);
    formData.append("init_date", moment(new Date()).format("YYYY-MM-DD"));
    formData.append("description", searchField(evaluation, "detail"));

    await addNotesEvaluation();
    await addDocumentsEvaluation();
    client()
      .post(`audit/evaluation/${newAnalize.evaluation_id}/addAnalize`, formData)
      .then(({ data }) => {
        if (newAnalize.process_id) {
          client().post(`evaluation/processAttachAnalyze/${data.id}`, {
            process_id: newAnalize.process_id,
          });
        }
        sendEmailAnalyze(data);
        alertSuccess("Analisis generado");
        setnewAnalize(initialState.newAnalize);
        getEvaluations(company.company_id);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al generar el analisis");
        }
      });
  };
  const sendEmailAnalyze = (analyzeForm) => {
    if (!analyzeForm.responsable_id) {
      return false;
    }
    let analyze_id = analyzeForm.id;
    db.collection(`users/${analyzeForm.responsable.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `te ha asignado como responsable de un plan de acción <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/analyze/${analyze_id}"> Plan de acción</a>`,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_ACTION_APPROVED,
      module: NOTIFICATION_MODULE_ACTION_PLAN,
    });
    client()
      .get(`audit/sendEmailAnalyze/${analyze_id}`)
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al notificar al responsable");
        }
      });
  };
  const rejectedPqrs = async ({ status_id }) => {
    let { data } = await addNotesEvaluation(NOTES_REJECTED_PQRS);
    if (data) {
      await addDocumentsNote(null, data.id);
    }

    client()
      .post(`audit/evaluation/${newAnalize.evaluation_id}`, { status_id })
      .then(({ data }) => {
        if (newAnalize.process_id) {
          client().post(`evaluation/processAttachAnalyze/${data.id}`, {
            process_id: newAnalize.process_id,
          });
        }

        setnewAnalize(initialState.newAnalize);
        getEvaluations(company.company_id);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al editar el pqrs");
        }
      });
  };

  const exportListPqrs = () => {
    downloadDocument(
      `${process.env.REACT_APP_API_HOST}general/company/${company.company_id}/downloadListPqrs`
    );
  };
  const haveError = (item) => {
    if (withErrorsPqrs && item.is_required && item.results.length == 0) {
      return true;
    } else if (withErrorsPqrs && item.is_required && item.results.length > 0) {
      if (item.results[0].response === "") {
        return true;
      }
    }
  };
  return (
    <CompanyFormContext.Provider
      value={[
        {
          newAnalize,
          company,
          usersSearch,
          evaluationPQRS,
          itemDeploy,
          filesPqrs,
          filesItemsPqrs,
          evaluations,
          evaluationsMonths,
          metaRowPqrs,
          rowsPqrs,
          statusAnalyze
        },
        {
          getUser,
          changeCompany,
          addStaff,
          deleteStaff,
          setcompany,
          deleteProcces,
          createProcces,
          updateProcces,
          getUserResponsable,
          searchUsers,
          setusersSearch,
          searchTokenCompany,
          setItemDeploy,
          updateImageCompany,
          setTextOptionQuestion,
          addResponseSUR,
          setfilesPqrs,
          setfilesItemsPqrs,
          savePqrs,
          setnewAnalize,
          generateAnlyze,
          rejectedPqrs,
          updateStaff,
          exportListPqrs,
          haveError,
          setmetaRowPqrs,
        },
      ]}
    >
      {children}
    </CompanyFormContext.Provider>
  );
};
