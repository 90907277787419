import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";

import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_CREATED = 1;

let initialState = {
  dataItem: {
    numberResult: 0,
    numberValue: 0,
    objective: 0,
    percentage: 0,
  },
  audit: {
    status: STATUS_NOT_LOADED,
    element: {},
    audit_id: null,
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};

export const AuditExpressFormContext = React.createContext([]);

export const AuditExpressFormContextProvider = ({ children }) => {
  const [audit, setAudit] = useState(initialState.audit);
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [itemsSelectedActivity, setitemsSelectedActivity] = useState([]);
  const [itemsIntemplate, setitemsIntemplate] = useState([]);
  const [dataItem, setdataItem] = useState(initialState.dataItem);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (audit.audit_id != null && audit.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/audit/${audit.audit_id}`, {
          params: {
            filters: {
              is_express: true,
              lider_person_id: user.id,
            },
            with: [
              "standard.auditable_items",
              "standard.item_types",
              "evaluations.items.auditable_item",
              "liderPerson",
              "notes.myNote",
              "documents",
              "evaluations.items.results",
              "evaluations.items.documents",
              "evaluations.items.notes",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            if (data.template_id && data.status_id === STATUS_CREATED) {
              selectItemsOnTemplate(data.template_id);
            }
            setAudit({
              ...audit,
              status: STATUS_LOADED,
              element: data,
            });
          }
        })
        .catch((e) => {

          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la auditoria");
          }
        });

      client()
        .get(`audit/getResultNumberAudit/${audit.audit_id}`)
        .then(({ data }) => {
          setdataItem(data);
        });
    }
  }, [audit]);
  const selectItemsOnTemplate = (template_id) => {
    client()
      .get(`audit/evaluation/${template_id}`, {
        params: {
          with: ["items.auditable_item"],
        },
      })
      .then(({ data }) => {
        if (data) {
          let itemsIdOnTemplate = recursiveSelectItemTemplate(data.items, []);
          setitemsIntemplate(itemsIdOnTemplate);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar la plantilla seleccionada");
        }
      });
  };
  const recursiveSelectItemTemplate = (itemsFor, arraySelect) => {
    for (let i = 0; i < itemsFor.length; i++) {
      const item = itemsFor[i];
      if (item.auditable_item_id) {
        arraySelect.push(item.auditable_item_id);
      }
      if (item.items.length > 0) {
        arraySelect = recursiveSelectItemTemplate(item.items, arraySelect);
      }
    }
    return arraySelect;
  };
  let getAudit = (audit_id) => {
    setAudit({
      ...audit,
      audit_id: audit_id,
    });
  };
  const syncItemsSelectedActivity = (itemsCheck) => {
    setitemsSelectedActivity(itemsCheck);
  };
  const addItemsExpress = (object) => {
    setAudit({ ...audit, status: STATUS_LOADING });
    client()
      .post(`audit/evaluationExpress`, object)
      .then(() => {
        alertSuccess("Evaluación generada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar la evaluación");
        }
      });
  };
  const sendEmailExpress = () => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false
    });

    Toast.fire({
      icon: "info",
      title: "Enviando documento...",
    });
    client()
      .get(`audit/showAuditExpress/${audit.audit_id}`)
      .then(() => {
        alertSuccess("Adjunto enviado al correo");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al enviar el documento");
        }
      });
  };
  const changeStatusAudit = (status_id) => {
    let object = {
      status_id: status_id,
    };
    client()
      .post(`audit/audit/${audit.audit_id}`, object)
      .then(() => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error cambiar el estado");
        }
      });
  };
  const getViewDownload = (routeView, routeDownload) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando descarga...",
    });

    client()
      .get(routeView)
      .then(({ data }) => {
        let token = "";
        let name = "NewFile";

        if (localStorage) {
          token = localStorage.getItem("token") || "";
        }

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", routeDownload);
       // form.setAttribute("target", name);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "view";
        input.value = data;
        form.appendChild(input);
        var input2 = document.createElement("input");
        input2.type = "hidden";
        input2.name = "Authorization";
        input2.value = token;
        form.appendChild(input2);

        document.body.appendChild(form);

        //note I am using a post.htm page since I did not want to make double request to the page
        //it might have some Page_Load call which might screw things up.
       // window.open("post.htm", name);

        form.submit();

        document.body.removeChild(form);
        Toast.close();
        setTimeout(() => {
          const Toast2 = Swal.mixin({
            toast: true,
            background: "#ffffff",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });

          Toast2.fire({
            icon: "success",
            title: "Descarga terminada",
          });
        }, 3000);

      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar el documento");
        }
      });
  };
  return (
    <AuditExpressFormContext.Provider
      value={[
        {
          audit,
          itemsSelectedActivity,
          itemsIntemplate,
          dataItem,
        },
        {
          getAudit,
          addItemsExpress,
          sendEmailExpress,
          changeStatusAudit,
          getViewDownload
        },
      ]}
    >
      {children}
    </AuditExpressFormContext.Provider>
  );
};
