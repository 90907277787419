import React, { useContext, useState, useEffect } from "react";
import { ActionPlanFormContext } from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import ImageUploader from "react-images-upload";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import { Field, Form } from "react-final-form";
import Document from "./Document";

import { STATUS_LOADED } from "../../../contexts/security/SecurityContext";
import btn_generate from "../../../img_zeus/icons_audit/generate.png";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
const initialState = {
  picker: {
    init_date: new Date(),
    end_date: new Date(),
  },

  stateCreate: {},
};
const Implement = () => {
  const [
    { analyze, modalAction, filesActionsModal, notesAction },
    {
      addAction,
      updatFormAction,
      setmodalAction,
      setfilesActionsModal,
      addNoteAction,
      deleteEvidenceAction,
      canViewAction,
      canDeleteEvidence,
    },
  ] = useContext(ActionPlanFormContext);
  const [picker, setpicker] = useState(initialState.picker);
  const [stateCreate, setstateCreate] = useState(initialState.stateCreate);
  const [percentageFinish, setpercentageFinish] = useState(0);
  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  useEffect(() => {
    if (analyze.status === STATUS_LOADED) {
      let totalActions = analyze.element.actions.filter(
        (a) => a.is_trasing === false
      );
      let totalFinish = totalActions.filter((a) => a.is_open == false);
      if (totalActions.length === 0) {
        setpercentageFinish(0);
      } else {
        setpercentageFinish(
          Math.round((totalFinish.length * 100) / totalActions.length)
        );
      }
    }
  }, [analyze]);
  return (
    <div>
      {modalAction != null && (
        <div className="back-document">
          <div className="general-modal-correction">
            <div className="header-modal-correction">
              <div
                onClick={() => {
                  setmodalAction(null);
                  setfilesActionsModal([]);
                }}
                s
                className="salir-audit"
              ></div>
              <h3 className="title-form-topic tipo-description">evidencia</h3>
            </div>
            <div className="content-corretion-analyze flex flex-column">
              <Form
                onSubmit={(formObj) => {
                  addNoteAction(formObj);
                }}
              >
                {({ handleSubmit, hasValidationErrors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group div-form-activity">
                      <Field name="note">
                        {({ input, meta }) => (
                          <>
                            <label className="tipo-title">Nota</label>
                            <textarea
                              {...input}
                              className="form-control tipo-description"
                              placeholder="Nota"
                            />
                          </>
                        )}
                      </Field>
                      <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText="Seleccione imagenes"
                        imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        withIcon={false}
                        onChange={(e) => setfilesActionsModal(e)}
                        fileSizeError="Excede el peso permitido"
                        fileTypeError="Extensión no permitida"
                        label="Peso máximo 5mb,acepta:jpg,gif,png"
                      />
                      <div className="flex">
                        <div>
                          <input
                            type="submit"
                            className=" btn-orange-default"
                            value="Guardar"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">fecha de creacion</th>
                    <th scope="col">responable</th>
                    <th scope="col">nota</th>
                    <th scope="col">evidencias</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {notesAction.map((note) => {
                    return (
                      <tr>
                        <th scope="row">{note.created_at}</th>
                        <td>{note.person.first_name}</td>
                        <td>{note.note}</td>
                        <td>
                          <div className="flex">
                            {note.documents.map((document) => (
                              <Document document={document} />
                            ))}
                          </div>
                        </td>
                        <td>
                          {" "}
                          {canDeleteEvidence() && (
                            <img
                              title="eliminar"
                              className="cursor-action"
                              width="23"
                              onClick={() => deleteEvidenceAction(note.id)}
                              src={deleteIcon}
                              alt="eliminar"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="flex">
        <div className="bar-progress-analyze progress">
          <div
            className="progress-bar progress-bar-striped bg-warning"
            role="progressbar"
            style={{ width: percentageFinish + "%" }}
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        &nbsp;
        <span className="tipo-description">
          {percentageFinish.toFixed(2)}% completado
        </span>
      </div>

      <br />

      {analyze.element.actions.length > 0 && (
        <>
          {analyze.element.actions.map((action, index) => {
            return (
              canViewAction(action) && (
                <div className="panel-detail-analyze">
                  <h5 className="orange">{action.cause&&action.cause.name}</h5>
                <div className="container-tables-analyze container-scroll-h ">
                <table className="table table-correction-analyze">
                  <tbody>
                   
                      <ActionRender
                        index={index}
                        key={action.id}
                        first={true}
                        action={action}
                      />
                  </tbody>
                </table>
                </div>
                </div>
              )
            );
          })}
        </>
      )}
    </div>
  );
};

const ActionRender = ({ index, first, action }) => {
  const [
    { analyze, actionDeploy, stateNewSubAction },
    { setmodalAction, updatFormAction, setDeployAction },
  ] = useContext(ActionPlanFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const checkFinishAction = (action) => {
    let childClose = true;
    for (let a = 0; a < action.actions.length; a++) {
      const ac = action.actions[a];
      if (ac.is_open === true) {
        childClose = false;
      }
    }
    if (childClose === false) {
      alertWarning("Todas las subactividades debe estar cerradas");
      return false;
    }
    if (!action.responsable) {
      alertWarning("El campo responsable no puede estar vacio");
    } else {
      updatFormAction(action.id, {
        is_open: false,
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    }
  };
  return (
    <div className="container-tables-analyze container-scroll-h">
      <ol className="ol-analyze">
        <div className="flex justify-end">
          <div className="container-circle-item flex">
            {action.actions.length > 0 && (
              <div
                onClick={() => setDeployAction(action.id)}
                className={`${
                  actionDeploy["action" + action.id]
                    ? "circle-item-down-gray"
                    : "circle-item-up-gray"
                } circle-number-items`}
              >
                {" "}
                {action.actions.length}
              </div>
            )}
            {action.actions.length === 0 && (
              <div style={{ width: "28px" }}></div>
            )}
          </div>
          <th style={{ width: "-webkit-fill-available" }}>
            <div className="flex-column">
              {first && <div>Actividad</div>}

              <div className="form-group-zeus">
                <input
                  value={action.description}
                  type="text"
                  name="text"
                  autocomplete="off"
                  required
                />
                <label htmlFor="text" className="label-name-zeus">
                  <span className="content-name-zeus"></span>
                </label>
              </div>
            </div>
          </th>

          <td style={{ minWidth: "107px" }}>
            <div className="flex-column">
              {first && <div>Cuando</div>}
              <div className="form-group-zeus">
                <input
                  value={moment(action.init_date).format("YYYY-MM-DD")}
                  type="text"
                  name="text"
                  autocomplete="off"
                  required
                />
                <label htmlFor="text" className="label-name-zeus">
                  <span className="content-name-zeus"></span>
                </label>
              </div>
            </div>
          </td>

          <td style={{ minWidth: "154px" }}>
            <div className="flex-column">
              {first && <div>Responsable</div>}
              <div className="form-group-zeus">
                <input
                  value={
                    action.responsable
                      ? action.responsable.first_name +
                        " " +
                        action.responsable.last_name
                      : ""
                  }
                  type="text"
                  name="text"
                  autocomplete="off"
                  required
                />
                <label htmlFor="text" className="label-name-zeus">
                  <span className="content-name-zeus"></span>
                </label>
              </div>
            </div>
          </td>
          <td style={{ width: "200px" }}>
            <div className="flex-column">
              {first && <div>Finalizado</div>}
              {action.is_open && (
                <div
                  onClick={() => {
                    checkFinishAction(action);
                  }}
                  className="btn-close-action-plan tipo-title white"
                >
                  Cierre
                </div>
              )}
              {!action.is_open && (
                <>
                  <div className="form-group-zeus">
                    <input
                      value={moment(action.end_date).format("YYYY-MM-DD")}
                      type="text"
                      name="text"
                      autocomplete="off"
                      required
                    />
                    <label htmlFor="text" className="label-name-zeus">
                      <span className="content-name-zeus"></span>
                    </label>
                  </div>
                </>
              )}
            </div>
          </td>
          <td style={{ width: "100px" }}>
            {first && <div>&nbsp;</div>}

            <div
              title="evidencias"
              className="cursor-action evidence-correction background-simple"
              width="23"
              onClick={() => setmodalAction(action.id)}
            >
              {" "}
              <div className="orange container-number-documents-correction tipo-cursiva">
                {action.notes.length}
              </div>
            </div>
          </td>
        </div>
        {actionDeploy["action" + action.id] && (
          <div className="form-group">
            {action.actions.map((SubAction) => {
              return (
                <ActionRender
                  key={SubAction.id}
                  first={false}
                  index={index}
                  action={SubAction}
                />
              );
            })}
          </div>
        )}
      </ol>
    </div>
  );
};
export default Implement;
