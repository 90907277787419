import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const STATUS_APPROVED = 7;
let initialState = {
  audit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  suscriptionsAuditExpress: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
  modalCreate: false,
};

export const AuditExpressIndexContext = React.createContext([]);

export const AuditExpressIndexContextProvider = ({ children }) => {
  let [audit, setAudit] = useState(initialState.audit);
  let [standard, setStandard] = useState(initialState.standard);
  const [template, setTemplate] = useState(initialState.template);
  const [toggleModal, settoggleModal] = useState(initialState.modalCreate);
  const [suscriptionsAuditExpress, setsuscriptionsAuditExpress] = useState(
    initialState.suscriptionsAuditExpress
  );
  const [effectiveSelected, seteffectiveSelected] = useState(null);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let history = useHistory();

  // componteDidMointed & componentDidUpdated

  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (audit.status === STATUS_NOT_LOADED) {
      setAudit({ ...audit, status: STATUS_LOADING });
      client()
        .get("audit/audit", {
          params: {
            filters: {
              is_express: true,
              lider_person_id: user.id,
            },
            with: ["consumer.effective","standard", "liderPerson", "responsablePerson", "evaluations"],
          },
        })

        .then(({ data }) => {
          if (data) {
            setAudit({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [audit]);
  useEffect(() => {
    if (suscriptionsAuditExpress.status === STATUS_NOT_LOADED) {
      setsuscriptionsAuditExpress({ ...suscriptionsAuditExpress, status: STATUS_LOADING });
      client()
        .get("audit/subscriptionAuditExpress")
        .then(({ data }) => {
          if (data) {
            setsuscriptionsAuditExpress({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [suscriptionsAuditExpress]);
  useEffect(() => {
    if (template.status === STATUS_NOT_LOADED) {
      client()
        .get(`security/person/${user.id}/templates`)
        .then(({ data }) => {
          if (data) {
            setTemplate({
              ...template,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantillas");
          }
        });
    }
  }, [template]);
  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      setStandard({ ...standard, status: STATUS_LOADING });
      client()
        .get("audit/standard", {
          params: {
            filters: {
              status_id: STATUS_APPROVED,
            },
          },
        })

        .then(({ data }) => {
          if (data) {
            setStandard({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [standard]);

  const createAudit = ({ title, standard_id, template_id, company_id }) => {
    client()
      .post("audit/audit", {
        title: title,
        standard_id: standard_id.id,
        company_id: company_id.id,
        lider_person_id: user.id,
        responsable_person_id: user.id,
        template_id: template_id,
        is_express: true,
        effective_id: effectiveSelected,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Auditoría creada satisfactoriamente");
          settoggleModal(!toggleModal);
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
          setsuscriptionsAuditExpress({ ...suscriptionsAuditExpress, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la auditoria");
        }
      });
  };

  const updateAudit = ({ title, standard_id }, id) => {
    client()
      .post(`audit/audit/${id}`, {
        title: title,
        standard_id: standard_id.id,
      })
      .then(() => {
        alertSuccess("Auditoria actualizada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la auditoria");
        }
      });
  };

  const deleteAudit = (id) => {
    client()
      .delete(`audit/audit/${id}`)
      .then(({ data }) => {
        alertSuccess("Auditoria eliminado satisfactoriamente");
        let el = audit.elements.filter((e) => e.id !== id);
        setAudit({ ...audit, elements: el, status: STATUS_NOT_LOADED });
      });
  };
  const getViewDownload = (routeView, routeDownload) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando descarga...",
    });

    client()
      .get(routeView)
      .then(({ data }) => {
        let token = "";
        let name = "NewFile";

        if (localStorage) {
          token = localStorage.getItem("token") || "";
        }

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", routeDownload);
        // form.setAttribute("target", name);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "view";
        input.value = data;
        form.appendChild(input);
        var input2 = document.createElement("input");
        input2.type = "hidden";
        input2.name = "Authorization";
        input2.value = token;
        form.appendChild(input2);

        document.body.appendChild(form);

        //note I am using a post.htm page since I did not want to make double request to the page
        //it might have some Page_Load call which might screw things up.
        // window.open("post.htm", name);

        form.submit();

        document.body.removeChild(form);
        Toast.close();
        setTimeout(() => {
          const Toast2 = Swal.mixin({
            toast: true,
            background: "#ffffff",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });

          Toast2.fire({
            icon: "success",
            title: "Descarga terminada",
          });
        }, 3000);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar el documento");
        }
      });
  };
  const sendEmailExpress = (audit_id) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Enviando documento...",
    });
    client()
      .get(`audit/showAuditExpress/${audit_id}`)
      .then(() => {
        alertSuccess("Adjunto enviado al correo");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al enviar el documento");
        }
      });
  };
  return (
    <AuditExpressIndexContext.Provider
      value={[
        { audit, standard, toggleModal, template, effectiveSelected, suscriptionsAuditExpress },
        {
          createAudit,
          updateAudit,
          deleteAudit,
          settoggleModal,
          getViewDownload,
          sendEmailExpress,
          seteffectiveSelected,
        },
      ]}
    >
      {children}
    </AuditExpressIndexContext.Provider>
  );
};
