import React, { useEffect, useContext, useState } from "react";
import { AuditExpressFormContext } from "../../../contexts/audit/AuditExpressFormContext";
import { STATUS_LOADED } from "../../../contexts/security/SecurityContext";
import imgSearchButton from "../../../img_zeus/icons_audit/Lupa.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./Form.css";
import $ from "jquery";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import Loading from "./../../app/Loading";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import ReactSpeedometer from "react-d3-speedometer";
import {
  STATUS_APPROVED,
  STATUS_PLANNED,
  STATUS_INPROGRESS,
  STATUS_EXECUTED,
} from "../evaluation/Item";
const initialState = {
  result: {
    numberCalificables: 0,
    numberResult: 0,
    percentage: 0,
    fulfill: 0,
    fails: 0,
    notApply: 0,
    documents: 0,
    notes: 0,
  },
};
const Form = ({ computedMatch: { params, url } }) => {
  let { audit_id } = params;
  const [
    { audit, itemsIntemplate, dataItem },
    { getAudit, addItemsExpress, sendEmailExpress, changeStatusAudit,getViewDownload },
  ] = useContext(AuditExpressFormContext);
  const [originalItems, setoriginalItems] = useState([]);
  const [items, setitems] = useState([]);
  const [itemDeploy, setItemDeploy] = useState({});
  const [searchItem, setsearchItem] = useState("");
  const [checkItems, setcheckItems] = useState({
    isSync: true,
    items: [],
  });
  const [result, setresult] = useState(initialState.result);
  useEffect(() => {
    getAudit(audit_id);
  }, [audit_id]);

  useEffect(() => {
    if (audit.status == STATUS_LOADED) {
      setoriginalItems(audit.element.standard.auditable_items);
      setitems(audit.element.standard.auditable_items);

      let data = {
        numberCalificables: 0,
        numberResult: 0,
        percentage: 0,
        fulfill: 0,
        fails: 0,
        notApply: 0,
        documents: 0,
        notes: 0,
      };
      if (audit.element.evaluations.length > 0) {
        recursiveAllEvaluations(
          data,
          audit.element.evaluations[
            parseInt(audit.element.evaluations.length) - 1
          ].items
        );
      }

      data.percentage =
        data.numberResult == 0 && data.numberCalificables == 0
          ? 0
          : Math.round((data.numberResult * 100) / data.numberCalificables);
      setresult(data);
      if (
        data.percentage > 0 &&
        data.percentage < 100 &&
        audit.element.status_id == STATUS_PLANNED
      ) {
        changeStatusAudit(STATUS_INPROGRESS);
      }
      if (
        data.percentage == 100 &&
        (audit.element.status_id == STATUS_INPROGRESS ||
          audit.element.status_id == STATUS_PLANNED)
      ) {
        changeStatusAudit(STATUS_EXECUTED);
      }
    }
  }, [audit]);
  useEffect(() => {
    if (items.length > 0) {
      let newItemsCopy = [...items];
      let newItems = recursiveRefreshItems(newItemsCopy, checkItems.items);
      setitems(newItems);
    }
  }, [checkItems]);
  const generateEvaluationExpress = () => {
    var newObject = new Object();
    newObject.audit_id = audit_id;
    newObject.items = checkItems;
    addItemsExpress(newObject);
  };
  const recursiveAllEvaluations = (object, items) => {
    for (let i = 0; i < items.length; i++) {
      object = getDataItem(object, items[i]);
    }

    return object;
  };
  const getDataItem = (object, item) => {
    if (item.auditable_item != null) {
      if (item.auditable_item.required) {
        object.numberCalificables = object.numberCalificables + 1;
        if (item.results.length > 0) {
          object.numberResult = object.numberResult + 1;
          if (item.results[0].is_valid == true) {
            object.fulfill = object.fulfill + 1;
          } else if (item.results[0].is_valid == false) {
            object.fails = object.fails + 1;
          } else {
            object.notApply = object.notApply + 1;
          }
        }
        object.documents = object.documents + item.documents.length;
        object.notes = object.notes + item.notes.length;
      }
    }
    if (item.items != "") {
      for (let i = 0; i < item.items.length; i++) {
        object = getDataItem(object, item.items[i]);
      }
    }

    return object;
  };
  const recursiveRefreshItems = (itemsCopy, checks) => {
    for (let i = 0; i < itemsCopy.length; i++) {
      var newObject = new Object(itemsCopy[i]);
      newObject.isActive = false;
      newObject.hidden = false;
      for (let c = 0; c < checks.length; c++) {
        if (checks[c] == itemsCopy[i].id) {
          newObject.isActive = true;
        }
      }
      if (itemsCopy[i].items != "") {
        newObject.items = recursiveRefreshItems(itemsCopy[i].items, checks);
      }
      itemsCopy[i] = newObject;
    }

    return itemsCopy;
  };
  const searchItemRecursive = () => (e) => {
    let body = e.target.value;
    setsearchItem(body);
    if (body == "") {
      setitems(originalItems);
    } else {
      let item = searchNumberItem(originalItems, body);
      if (item) {
        setitems([item]);
      } else {
        setitems([]);
      }
    }
  };
  const removeItem = (item_id) => {
    let arrayParents = [];
    let arrayChilds = [];

    arrayParents = getParents(arrayParents, item_id);
    arrayChilds = getChilds(arrayChilds, item_id);
    for (let i = 0; i < arrayChilds.length; i++) {
      arrayParents.push(arrayChilds[i]);
    }
    arrayParents.push(item_id);

    let items = checkItems.items.filter(function (item) {
      ///remueve padres
      return !arrayParents.includes(item);
    });
    items = items.filter(function (item) {
      //remueve hijos
      return !arrayChilds.includes(item);
    });

    setcheckItems({ ...checkItems, items: items, isSync: true });
  };
  const findItem = (items, item_id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == item_id) {
        return items[i];
      } else {
        if (items[i].items != "") {
          let item = findItem(items[i].items, item_id);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  const getParents = (arrayParents, item_id) => {
    let item = findItem(items, item_id);
    if (item.auditable_item_id != null) {
      let parent = findItem(items, item.auditable_item_id);
      arrayParents.push(parent.id);
      arrayParents = getParents(arrayParents, parent.id);
    }
    return arrayParents;
  };
  const getRecursiveNumberCalificables = (first, number, item) => {
    item.items.map((item) => {
      number = number + 1;
      number = getRecursiveNumberCalificables(false, number, item);
    });
    return number;
  };
  const getChilds = (arrayChilds, item_id) => {
    let item = findItem(items, item_id);
    if (item.items != "") {
      for (let i = 0; i < item.items.length; i++) {
        arrayChilds.push(item.items[i].id);
        arrayChilds = getChilds(arrayChilds, item.items[i].id);
        arrayChilds = getParents(arrayChilds, item.items[i].id);
      }
    }
    return arrayChilds;
  };
  const searchNumberItem = (items, number) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].number == number) {
        return items[i];
      } else {
        if (items[i].items != "") {
          let item = searchNumberItem(items[i].items, number);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  const getSelectedNumber = (first, item) => {
    return isChecked(item.id)
      ? getRecursiveNumberCalificables(first, 0, item)
      : "";
  };
  const getChildsChecked = (item, number) => {
    if (isChecked(item.id)) {
      number = getRecursiveNumberCalificables(false, number + 1, item);
    } else {
      if (recursiveBackTrackingParent(item)) {
        number = number + 1;
      }
      if (item.items != "") {
        for (let index = 0; index < item.items.length; index++) {
          number = getChildsChecked(item.items[index], number);
        }
      }
    }

    return number;
  };
  const backTrackingParent = (item) => {
    return recursiveBackTrackingParent(item) ? true : false;
  };
  const recursiveBackTrackingParent = (item) => {
    /*   if (item.auditable_item_id != null) {
      let itemParent = findItem(originalItems, item.auditable_item_id);
      if (itemParent) {
        if (isChecked(itemParent.id)) {
          return true;
        } else {
          return recursiveBackTrackingParent(itemParent);
        }
      }
    } */
  };
  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };
  const isChecked = (item_id) => {
    let item = checkItems.items.filter((e) => e == item_id)[0];
    if (item) {
      return true;
    } else {
      return false;
    }
  };
  //valida si los hijos estan checkeados para checkerase èl automaticamente
  const validateParents = (arrayParents, item_id) => {
    let item = findItem(items, item_id);
    let itemParent = findItem(items, item.auditable_item_id);
    let allSelect = true;
    if (itemParent) {
      for (let i = 0; i < itemParent.items.length; i++) {
        if (itemParent.items[i].id != item_id) {
          if (!itemParent.items[i].isActive) {
            allSelect = false;
          }
        }
      }
      if (allSelect) {
        arrayParents.push(itemParent.id);
        //si todos los hijos estan checkeados seguimos validando hacia atrás
        let arrayNewParents = validateParents(arrayParents, itemParent.id);
        if (arrayNewParents) {
          arrayParents = arrayNewParents;
        }
      }

      return arrayParents;
    }
  };
  const addItemsChilds = (items, newItems) => {
    for (let i = 0; i < items.length; i++) {
      newItems.push(items[i].id);
      if (items[i].items != "") {
        newItems = addItemsChilds(items[i].items, newItems);
      }
    }
    return newItems;
  };
  const addItems = (item_id) => {
    let item = findItem(items, item_id);
    let itemParent = findItem(items, item.auditable_item_id);

    let itemsParentChecked = [];
    if (itemParent) {
      itemsParentChecked = validateParents(itemsParentChecked, item_id);
    }
    let newItems = [item_id];

    if (item.items != "") {
      newItems = addItemsChilds(item.items, newItems);
    }
    for (let i = 0; i < checkItems.items.length; i++) {
      newItems.push(checkItems.items[i]);
    }

    for (let i = 0; i < itemsParentChecked.length; i++) {
      newItems.push(itemsParentChecked[i]);
    }
    setcheckItems({
      ...checkItems,
      items: newItems,
      isSync: true,
    });
  };

  const getValueObjective = (percentage) => {
    let ratio = 50;
    if (percentage < audit.element.standard.min_range) {
      ratio = 16;
    } else if (percentage > audit.element.standard.max_range) {
      ratio = 84;
    }
    return ratio;
  };
  const statusAudit = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <p className="tipo-description">Creada</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_created}
              title="Creada"
            />
          </>
        );
        break;
      case 2:
        return (
          <>
            <p className="tipo-description">Planeada</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_planned}
              title="Planeado"
            />
          </>
        );
        break;
      case 3:
        return (
          <>
            <p className="tipo-description">En progreso</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_inprogress}
              title="En progreso"
            />
          </>
        );
        break;
      case 4:
        return (
          <>
            <p className="tipo-description">Finalizada</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_executed}
              title="Finalizada"
            />
          </>
        );
        break;
      case 6:
        return (
          <>
            <p className="tipo-description">Pendiente</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_created}
              title="Pendiente"
            />
          </>
        );
        break;
      case 7:
        return (
          <>
            <p className="tipo-description">Aprobada</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_approved}
              title="Aprobada"
            />
          </>
        );
        break;
      default:
        return (
          <>
            <p className="tipo-description">Creada</p>
            &nbsp;&nbsp;
            <img
              className="img-status-express"
              src={btn_created}
              title="Creada"
            />
          </>
        );
    }
  };
  return (
    <div className="container-audit-express-back">
      {audit.status != STATUS_LOADED && <Loading />}
      {audit.status == STATUS_LOADED && audit.element.evaluations.length == 0 && (
        <div className="container-intro-items-express">
          Seleccione los numerales a evaluar
          <div className="w-100 flex container-search-item tipo-cursiva">
            <input
              placeholder="Buscar numerales"
              value={searchItem}
              onChange={searchItemRecursive()}
              type="text"
            ></input>
            <img
              className="btn-search-item-activity"
              src={imgSearchButton}
            ></img>
          </div>
          <RecursiveItems
            removeItem={removeItem}
            isChecked={isChecked}
            checkItems={checkItems}
            audit={audit.element}
            items={items}
            addItems={addItems}
            backTrackingParent={backTrackingParent}
            setDeploy={setDeploy}
            itemDeploy={itemDeploy}
            first={true}
            itemsIntemplate={itemsIntemplate}
            getChildsChecked={getChildsChecked}
            getSelectedNumber={getSelectedNumber}
            getRecursiveNumberCalificables={getRecursiveNumberCalificables}
          />
          {audit.status == STATUS_LOADED && (
            <div className="flex">
              <div
                onClick={() => generateEvaluationExpress()}
                className="btn-generate-evaluation-express cursor-action bg-orange white margin-auto"
              >
                GENERAR EVALUACIÓN
              </div>
            </div>
          )}
        </div>
      )}
      <div className="general-container-mobile hidden">
        <div className="container-title-audit-mobile">
          <div>
            <strong>ID {audit.element.id}</strong> {audit.element.title}
          </div>
          <div>
            {audit.status === STATUS_LOADED &&
              audit.element.standard.short_name}
          </div>
        </div>
        {audit.status === STATUS_LOADED &&
          audit.element.evaluations.length > 0 && (
            <>
              <div className="flex container-progress-audit-mobile">
                <div className="flex-1  tipo-description">
                  <div className="flex container-percentage-express">
                    Items <strong>{result.percentage} %</strong>
                  </div>
                  <div style={{ width: "140px", height: "80px" }}>
                    <ReactSpeedometer
                      ringWidth={25}
                      needleHeightRatio={0.7}
                      needleColor={"black"}
                      forceRender={true}
                      paddingHorizontal={0}
                      paddingVertical={0}
                      maxSegmentLabels={0}
                      fluidWidth={true}
                      minValue={0}
                      maxValue={100}
                      value={result.percentage}
                    />
                  </div>
                </div>
                {audit.element.standard.item_types.length > 0 && (
                  <div className="flex-1  tipo-description">
                    <div className="flex container-percentage-express">
                      Objetivo <strong>{dataItem.percentage} %</strong>
                    </div>

                    <div style={{ width: "140px", height: "80px" }}>
                      <ReactSpeedometer
                        ringWidth={25}
                        needleHeightRatio={0.7}
                        needleColor={"black"}
                        segments={3}
                        forceRender={true}
                        paddingHorizontal={0}
                        paddingVertical={0}
                        maxSegmentLabels={0}
                        fluidWidth={true}
                        minValue={0}
                        maxValue={100}
                        value={getValueObjective(dataItem.percentage)}
                      />
                    </div>
                  </div>
                )}
                {audit.element.standard.item_types.length == 0 && (
                  <div className="flex-column flex-1 container-buttons-download-express">
                    <div
                      onClick={() =>
                        getViewDownload(
                          `${process.env.REACT_APP_API_HOST}audit/showAuditExpressView/${audit_id}`,
                          `${process.env.REACT_APP_API_HOST}audit/showAuditExpressDownload/${audit_id}`
                        )
                      }
                      className="white bg-orange txt-center"
                    >
                      Descargar
                    </div>
                    <div
                      onClick={() => sendEmailExpress()}
                      className="white bg-orange txt-center"
                    >
                      Enviar al correo
                    </div>
                  </div>
                )}
              </div>
              {audit.element.standard.item_types.length > 0 && (
                <div className="flex-column flex-1 container-buttons-download-express">
                  <div
                    onClick={() =>
                      getViewDownload(
                        `${process.env.REACT_APP_API_HOST}audit/showAuditExpressView/${audit_id}`,
                        `${process.env.REACT_APP_API_HOST}audit/showAuditExpressDownload/${audit_id}`
                      )
                    }
                    className="white bg-orange txt-center"
                  >
                    Descargar
                  </div>
                  <div
                    onClick={() => sendEmailExpress()}
                    className="white bg-orange txt-center"
                  >
                    Enviar al correo
                  </div>
                </div>
              )}

              <div className="flex flex-column container-report-view-activity-mobile">
                <div className="flex">
                  <div>
                    <div className="icon-advance"></div>
                  </div>
                  <div>
                    <div className="icon-percentage"></div>
                  </div>
                  <div>
                    <div className="icon-like"></div>
                  </div>
                  <div>
                    <div className="icon-dislike"></div>
                  </div>
                  <div>
                    <div className="icon-notApply"></div>
                  </div>
                  <div>
                    <div className="icon-images"></div>
                  </div>
                  <div>
                    <div className="icon-notes"></div>
                  </div>
                </div>
                <div className="flex result-show-mobile-container">
                  <div>
                    {result.numberResult}/{result.numberCalificables}
                  </div>
                  <div>{result.percentage}%</div>
                  <div>{result.fulfill}</div>
                  <div>{result.fails}</div>
                  <div>{result.notApply}</div>
                  <div>{result.documents}</div>
                  <div>{result.notes}</div>
                </div>
              </div>
            </>
          )}
      </div>

      <div className="flex fixed-activity-mobile-evaluation">
        <div className="flex-1 flex container-status-activity-mobile justify-center">
          {statusAudit(audit.element.status_id)}
        </div>
        {audit.status === STATUS_LOADED && (
          <div className="flex flex-1">
            {audit.element.evaluations.length > 0 && (
              <Link
                to={`/app/audit/evaluation/${audit.element.evaluations[0].id}`}
                className="flex  margin-auto background-simple btn-evaluation-activity-mobile"
              ></Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
const RecursiveItems = ({
  isChecked,
  checkItems,
  items,
  addItems,
  audit,
  removeItem,
  getSelectedNumber,
  setDeploy,
  getChildsChecked,
  itemDeploy,
  backTrackingParent,
  first,
  getRecursiveNumberCalificables,
  itemsIntemplate,
}) => {
  return items
    .filter((i) => (audit.template_id ? itemsIntemplate.includes(i.id) : true))
    .map((item) => {
      return (
        <ol key={item.id}>
          <li className={`${itemDeploy["item" + item.id] ? "dotted-li" : ""}`}>
            <div
              title={`${
                item.isActive
                  ? "Quitar selección de numeral"
                  : "Seleccione numeral"
              }`}
              className={` ${
                item.isActive ? "item-select-activity" : ""
              } ol-items-activity  flex content-item-column cursor-action`}
            >
              <div className="container-circle-item flex">
                {item.items.length > 0 && (
                  <div
                    onClick={() => setDeploy(item.id)}
                    className={`${
                      itemDeploy["item" + item.id]
                        ? "circle-item-down-gray"
                        : "circle-item-up-gray"
                    } circle-number-items`}
                  >
                    {" "}
                    {item.items.length}
                  </div>
                )}
              </div>
              <div
                onClick={() => {
                  isChecked(item.id) ? removeItem(item.id) : addItems(item.id);
                }}
                className="flex-1"
              >
                <div>
                  <p
                    className={`${
                      item.required
                        ? "tipo-description"
                        : "evaluable-text-activity-item"
                    } text-item-activity`}
                  >
                    <strong>{item.number}</strong> {item.title}
                  </p>
                </div>
              </div>
              <div className="container-number-items-activity">
                <div className="flex container-intro-item-number">
                  {getChildsChecked(item, 0)}/
                  {getRecursiveNumberCalificables(first, 0, item) + 1}
                </div>
              </div>
            </div>
            {itemDeploy["item" + item.id] && (
              <>
                {item.items != "" && (
                  <RecursiveItems
                    removeItem={removeItem}
                    isChecked={isChecked}
                    checkItems={checkItems}
                    addItems={addItems}
                    items={item.items}
                    setDeploy={setDeploy}
                    itemDeploy={itemDeploy}
                    first={false}
                    audit={audit}
                    itemsIntemplate={itemsIntemplate}
                    getChildsChecked={getChildsChecked}
                    backTrackingParent={backTrackingParent}
                    getSelectedNumber={getSelectedNumber}
                    getRecursiveNumberCalificables={
                      getRecursiveNumberCalificables
                    }
                  />
                )}
              </>
            )}
          </li>
        </ol>
      );
    });
};
export default Form;
