import React, { useContext, useEffect, useState } from "react";
import client from "../axios/axios-client";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import { SecurityContext } from "../security/SecurityContext";
import moment from "moment";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const STATUS_APPROVED = 7;
let initialState = {
  points: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  dateFilter: {
    year: moment().format("YYYY"),
    month: moment().format("MM"),
  },
  topics: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
};

export const TopicPointIndexContext = React.createContext([]);

export const TopicPointIndexContextProvider = ({ children }) => {
  let [errors, setErrors] = useState(initialState.errors);
  const [{ user }, { logout, refreshUser }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  const [points, setpoints] = useState(initialState.points);
  const [topics, settopics] = useState(initialState.topics);
  const [topicselected, settopicselected] = useState({ id: null });
  let history = useHistory();
  const [dateFilter, setdateFilter] = useState(initialState.dateFilter);

  useEffect(() => {
    if (points.status === STATUS_NOT_LOADED) {
      setpoints({ ...points, status: STATUS_LOADING });

      client()
        .get(`library/getTopicPointFilter`, {
          params: {
            year: dateFilter.year,
            month: dateFilter.month,
            with: ["topicPointsDetails.document"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setpoints({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los puntajes");
          }
        });
    }
  }, [points, dateFilter]);
  useEffect(() => {
    if (topics.status === STATUS_NOT_LOADED) {
      settopics({ ...topics, status: STATUS_LOADING });
      client()
        .get(`library/topic`, {
          params: {with: ["adminsTopic"] },
        })
        .then(({ data }) => {
          if (data) {
            let notIncludes = [];
            if (!user.is_admin) {
              for (let t = 0; t < data.length; t++) {
                const topic = data[t];
                let person = topic.admins_topic.filter((admin) => admin.id === user.id);
                if (person.length === 0) {
                  notIncludes.push(topic.id);
                }
              }
            }
            settopics({
              status: STATUS_LOADED,
              elements: data.filter((t) => !notIncludes.includes(t.id)),
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la suscripción");
          }
        });
    }
  }, [topics]);

  return (
    <TopicPointIndexContext.Provider
      value={[
        { topics, topicselected, points, dateFilter },
        { settopicselected, setdateFilter, setpoints },
      ]}
    >
      {children}
    </TopicPointIndexContext.Provider>
  );
};
