import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

let initialState = {
  effective: {
    effective_id: null,
    status: STATUS_NOT_LOADED,
    consumers: [],
  },
  package: {
    effective_id: null,
    status: STATUS_NOT_LOADED,
    element: {},
  },
  metaRow: {
    limit: 20,
    offset: 0,
    page: 1,
    filters: {},
    filtersLike: {},
    orderBy: ["id:desc"],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EffectiveFormContext = createContext([]);

export const EffectiveFormContextProvider = (props) => {
  let { children } = props;

  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [effective, seteffective] = useState(initialState.effective);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [packageEffective, setpackageEffective] = useState(initialState.package);
  const [rows, setrows] = useState(0);
  const [{ user }, { logout, fetchUser, refreshUser }] = useContext(SecurityContext);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    setmetaRow({
      ...metaRow,
      filters: { ...metaRow.filters, effective_id: effective.effective_id },
    });
  }, [effective.effective_id]);
  useEffect(() => {
    if (packageEffective.status === STATUS_NOT_LOADED && packageEffective.effective_id !== null) {
      setpackageEffective({ ...packageEffective, status: STATUS_LOADING });
      client()
        .get(`billing/effective/${packageEffective.effective_id}`, {
          params: {
            with: ["package.effectives.service","package.package.package.benefits","package.order"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setpackageEffective({
              ...effective,
              status: STATUS_LOADED,
              element: data.package,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar el paquete");
          }
        });
    }
  }, [packageEffective]);
  useEffect(() => {
    async function fetchData() {
      if (metaRow.filters.effective_id !== null && effective.effective_id !== null) {
        let newRows = await getCountConsumers();

        setrows(newRows);
        let newPages = Math.ceil(newRows / metaRow.limit);

        if (metaRow.page > newPages && newPages !== 0) {
          setmetaRow({ ...metaRow, page: 1 });

          return false;
        }

        seteffective({ ...effective, status: STATUS_LOADING });
        client()
          .get("billing/effective_consumer", {
            params: {
              with: ["consumable"],
              orderBy: metaRow.orderBy,
              filters: metaRow.filters,
              filtersLike: metaRow.filtersLike,
              limit: metaRow.limit,
              offset: metaRow.limit * (metaRow.page - 1),
            },
          })
          .then(({ data }) => {
            if (data) {
              seteffective({
                ...effective,
                status: STATUS_LOADED,
                consumers: data,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al buscar el producto");
            }
          });
      }
    }
    fetchData();
  }, [metaRow]);
  const getCountConsumers = async () => {
    if (metaRow.filters.effective_id !== null) {
      return client()
        .get(`billing/countEffectiveConsumer`, {
          params: {
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
          },
        })
        .then(({ data }) => {
          return data;
        });
    }
  };
  return (
    <EffectiveFormContext.Provider
      value={[
        { effective, metaRow, rows, packageEffective },
        { seteffective, setmetaRow, setpackageEffective },
      ]}
    >
      {children}
    </EffectiveFormContext.Provider>
  );
};
