import React, { useContext, useState } from "react";
import "./AdminTopic.css";
import moment from "moment";
import { LibraryContext,STATUS_LOADED } from "../../../../../contexts/library/LibraryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import DateTimePicker from "react-datetime-picker";
import imgError2 from "../../../../../img_zeus/default/PROFILE.jpeg";
import { faTimes, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { STATUS_NOT_LOADED } from "../../../../../contexts/security/SecurityContext";

const AdminTopic = ({ topic, toggleModalAdminTopic }) => {
  const [
    { userAdmin },
    { deleteAdminTopic, searchUserAdmin, addAdminTopic },
  ] = useContext(LibraryContext);
  const ROLE_ADMIN_TOPIC = "Admin_topic";
  const ROLE_ADMIN_FORUM = "Admin_forum";
  const initialState = {
    searchUser: {
      email: "",
      name: "",
      date: new Date(),
    },
    item: {
      adminTopic: "",
      adminForum: "",
    },
  };
  const [person, setperson] = useState(initialState.searchUser);

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const setFieldPerson = (field) => (e) => {
    setperson({ ...person, [field]: e.target.value });
  };
  let setField = (field) => (e) => {
    setperson({ ...person, item: { ...person.item, [field]: e.target.value } });
  };
  const setDate = (date) => {
    setperson({ ...person, date: date });
  };
  const addAuditorTopic = (role) => {
    addAdminTopic(role, person.date);
  };
  const existUser = (isTopic) => {
    if (topic.status === STATUS_LOADED) {
      if (isTopic) {
        let person = topic.admins_topic.filter((p) => p.id == userAdmin.id);
        return person.length > 0 ? true : false;
      } else {
        let person = topic.admins_forum.filter((p) => p.id == userAdmin.id);
        return person.length > 0 ? true : false;
      }
    }
  };
  return (
    <div className="back-document tipo-description">
      <div className="shadow-tarjet flex-wrap flex flex-start content-modal-topic-admin">
        <div className="header-modal-create-folder">
          <div className="w-100 flex">
            <div
              onClick={() => {
                toggleModalAdminTopic();
              }}
              className="background-simple icon-close-modal-create-folder cursor-action"
            ></div>
            <p className="txt-center txt-title-new-folder">
              Administración {topic.name}
            </p>
          </div>
        </div>
        <div className="admin-topic-body">
          <div className="form-group flex flex-wrap">
            <label className="tipo-title">Busqueda por correo</label>
            <div className="flex">
              <input
                className="form-control search-email-admin-topic"
                type="email"
                onChange={setFieldPerson("email")}
                value={person.email}
                placeholder="Correo Electrónico"
              />
              <input
                type="button"
                onClick={() => {
                  searchUserAdmin(person.email);
                }}
                className="btn bg-orange white tipo-boton"
                value="Buscar"
              />
            </div>
          </div>
          {userAdmin.id && (
            <div className="flex">
              <div className="form-group flex flex-column">
                <label className="control-label tipo-title">Nombre</label>
                <div className="flex">
                  <img
                    style={{ marginRight: "10px" }}
                    className="img-profile-admin-topic"
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (userAdmin.image && userAdmin.image.name)
                    }
                    onError={(e) => {
                      e.target.src = imgError2;
                    }}
                  />
                  <input
                    type="text"
                    style={{ marginRight: "10px" }}
                    className="form-control"
                    disabled
                    value={userAdmin.first_name + " " + userAdmin.last_name}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="tipo-title">Vencimiento</label>
                <DateTimePicker
                  className="form-control timer-admin-topic"
                  minDate={new Date()}
                  format="dd-MM-y h:mm:ss a"
                  onChange={setDate}
                  value={person.date}
                />
              </div>
              <div className="form-group txt-center">
                <label className="control-label tipo-title">
                  Administrador de {topic.name}
                </label>
                <input
                  type="button"
                  disabled={existUser(true)}
                  style={{ width: "100px" }}
                  className={`form-control btn bg-orange  ${
                    existUser(true) ? "black cursor-disable" : "white"
                  }`}
                  value="Añadir"
                  onClick={() => {
                    addAuditorTopic(ROLE_ADMIN_TOPIC);
                  }}
                />
              </div>
              <div className="form-group txt-center">
                <label className="control-label tipo-title">
                  Administrador del foro
                </label>
                <input
                  type="button"
                  style={{ width: "100px" }}
                  disabled={existUser(false)}
                  className={`form-control btn bg-orange  ${
                    existUser(false) ? "black cursor-disable" : "white"
                  }`}
                  value="Añadir"
                  onClick={() => {
                    addAuditorTopic(ROLE_ADMIN_FORUM);
                  }}
                />
              </div>
            </div>
          )}
          <div className="tipo-title">Administradores del tema</div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">nombre</th>
                <th scope="col">correo</th>
                <th scope="col">vencimiento</th>
                <th scope="col">accion</th>
              </tr>
            </thead>
            <tbody>
              {topic.admins_topic &&
                topic.admins_topic.map((admin, index) => {
                  return (
                    <tr key={index}>
                      <td>{admin.first_name}</td>
                      <td>{admin.email}</td>
                      <td>
                        {moment(admin.pivot.audit_time).format("DD-MM-Y HH:mm")}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() =>
                            checkDelete(
                              "¿Realmente deseas eliminar este permiso?",
                              `deleteAdminTopic(${admin.pivot.person_id},'${ROLE_ADMIN_TOPIC}')`
                            )
                          }
                          className="cursor-action"
                          icon={faTrashAlt}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {topic.admins_topic && topic.admins_topic.length == 0 && (
            <div className="w-100 txt-center">Sin registros</div>
          )}
          <div className="tipo-title">Administradores del foro</div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">nombre</th>
                <th scope="col">correo</th>
                <th scope="col">vencimiento</th>
                <th scope="col">accion</th>
              </tr>
            </thead>
            <tbody>
              {topic.admins_forum &&
                topic.admins_forum.map((admin, index) => {
                  return (
                    <tr key={index}>
                      <td>{admin.first_name}</td>
                      <td>{admin.email}</td>
                      <td>
                        {moment(admin.pivot.audit_time).format("DD-MM-Y HH:mm")}
                      </td>
                      <td>
                        {" "}
                        <FontAwesomeIcon
                          onClick={() =>
                            checkDelete(
                              "¿Realmente deseas eliminar este permiso?",
                              `deleteAdminTopic(${admin.pivot.person_id},'${ROLE_ADMIN_FORUM}')`
                            )
                          }
                          className="cursor-action"
                          icon={faTrashAlt}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {topic.admins_forum && topic.admins_forum.length == 0 && (
            <div className="w-100 txt-center">Sin registros</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminTopic;
