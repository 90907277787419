import React, { useEffect, useState, useContext } from "react";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { SubscriptionContext } from "../../../contexts/library/SubscriptionContext";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import imgError from "../../../default.jpg";
import imgAudit from "../../../../src/img_zeus/Auditorias.png";
import imgCapacity from "../../../../src/img_zeus/Almacenamiento.png";
import imgPlan from "../../../../src/img_zeus/Plan_de_accion.png";

import "../topic/topic_subscribed.css";
import moment from "moment";
import Select, { components } from "react-select";
import { Field, Form } from "react-final-form";
import TooltipModule from "../../billing/TooltipModule";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";

const LIBRARY_SERVICE = 4;
const AUDIT_SERVICE = 1;
const AUDIT_EXPRESS_SERVICE = 7;
const AUDITOR_SERVICE = 2;
const MODULE_ACTION_PLAN = 5;
const CAPACITY_SERVICE = 3;
const INSPECTION_SERVICE = 6;
const ACTION_PLAN_SERVICE = 5;

const LIBRARY_COMPANY = "library_company";

const initialState = {
  toggle_users: false,
  subscription_id: null,
  module: LIBRARY_SERVICE,
};

const Subscribed = ({ computedMatch: { path, params } }) => {
  let { module_suscription } = params;
  const [{ user }, { viewGeneral, capitalize }] = useContext(SecurityContext);
  const [
    { dontSuscribed, allSusbcription, services },
    { addCompanyDetail },
  ] = useContext(SubscriptionContext);
  const [module, setModule] = useState(initialState.module);
  let [state, setState] = useState(initialState);
  const [companySelectAudit, setcompanySelectAudit] = useState(2);
  let history = useHistory();
  const [modalSuscriptionAudit, setModalSuscriptionAudit] = useState(null);
  const [modalSuscriptionLibrary, setModalSuscriptionLibrary] = useState(null);

  useEffect(() => {
    viewGeneral("mySuscription");
  }, []);
  useEffect(() => {
    if (module_suscription) {
      let module = LIBRARY_SERVICE;
      switch (module_suscription) {
        case "audit":
          module = AUDIT_SERVICE;
          break;
        case "capacity":
          module = CAPACITY_SERVICE;
          break;
        case "plan":
          module = ACTION_PLAN_SERVICE;
          break;
        case "library":
          module = LIBRARY_SERVICE;
          break;
      }
      setModule(module);
    }
  }, [module_suscription]);

  const validSubscription = (subscription_id) => {
    {
      let el = user.subscriptions.filter(
        (e) =>
          e.id == subscription_id &&
          e.bill_detail.valid_until > moment().format("YYYY-MM-DD h:mm:ss")
      );
      return el.length > 0 ? true : false;
    }
  };
  const diff = (effective) => {
    return Math.round(
      moment(effective.end_date).diff(effective.init_date, "months", true)
    );
  };
  const isActive = (sub) => {
    return moment(sub.end_date) > moment();
  };
  const shortText = (text, range) => {
    if (text && text.length > range) {
      return text.substr(0, range) + "...";
    }
    return text;
  };
  let fontSize = (nameTopic) => {
    if (nameTopic && nameTopic.length <= 13) {
      return "font-size-short";
    } else if (nameTopic && nameTopic.length <= 26) {
      return "font-size-medium";
    } else if (nameTopic && nameTopic.length <= 39) {
      return "font-size-long";
    } else {
      return "font-size-more-long";
    }
  };
  const getSrc = (sub) => {
    switch (module) {
      case LIBRARY_SERVICE:
        return (
          sub.product &&
          sub.product.image &&
          process.env.REACT_APP_API_HOST +
            "documents/" +
            (sub.product.image && sub.product.image.name)
        );
        break;
      case AUDIT_SERVICE:
        return imgAudit;
      case AUDIT_EXPRESS_SERVICE:
        return imgAudit;
      case CAPACITY_SERVICE:
        return imgCapacity;
        break;
      case MODULE_ACTION_PLAN:
        return imgPlan;
        break;
    }
  };
  const personSubscribed = (sub) => {
    /*   if (sub.bill_detail.subscriptions.length == 0) {
      return "Ningun usuario asignado";
    } else if (sub.bill_detail.service_number == 1) {
      return sub.bill_detail.subscriptions[0].person.first_name;
    } else {
      return (
        sub.bill_detail.subscriptions[0].person.first_name +
        " y " +
        (sub.bill_detail.subscriptions.length - 1) +
        " más / " +
        sub.bill_detail.service_number
      );
    } */
  };
  const getCapacity = (effective) => {
    let mb = 0;
    for (let c = 0; c < effective.consumers.length; c++) {
      const consumer = effective.consumers[c];
      if (consumer.consumable) {
        mb = mb + parseFloat(consumer.consumable.size_mb);
      }
    }
    return effective.quantity + " / " + mb.toFixed(2);
  };
  const getSumAuditors = (effectives) => {
    let sum = 0;
    let consumers = 0;
    for (let e = 0; e < effectives.length; e++) {
      const effective = effectives[e];

      sum = sum + parseInt(effective.quantity);
      consumers = consumers + effective.consumers.length;
    }
    return sum + " / " + consumers;
  };
  return (
    <div className="flex justify-center w-100">
      {modalSuscriptionLibrary != null && (
        <div className="back-document">
          <div className="flex margin-auto  container-suscription-audit flex-column">
            <div className="header-modal-suscription-audit">
              <div
                onClick={() => setModalSuscriptionLibrary(null)}
                className="salir-topic"
              ></div>{" "}
              <h3 className="title-form-topic tipo-description">
                Seleccione Empresa
              </h3>
            </div>
            <Form
              onSubmit={(formObj) => {
                formObj.bill_detail_id = modalSuscriptionLibrary;
                formObj.company_id = formObj.company_id.id;
                addCompanyDetail(formObj);
                setModalSuscriptionLibrary(null);
              }}
            >
              {({ handleSubmit, values }) => (
                <form className="form-topic" onSubmit={handleSubmit}>
                  <div className="div-form-topic">
                    <Field name="company_id">
                      {({ input, meta }) => (
                        <>
                          <label className="tipo-title">Empresa</label>

                          <Select
                            {...input}
                            className="tipo-description"
                            placeholder={"Seleccione una empresa"}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={user.contact_companies}
                            isClearable={true}
                            isMulti={false}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="btn-actions-form">
                    <input
                      type="submit"
                      className="btn-orange-default"
                      value="Guardar"
                    />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      )}
      {/*  {modalSuscriptionAudit != null && (
        <div className="back-document">
          <div className="flex margin-auto  container-suscription-audit flex-column">
            <div className="header-modal-suscription-audit">
              <div
                onClick={() => setModalSuscriptionAudit(null)}
                className="salir-topic"
              ></div>{" "}
              <h3 className="title-form-topic tipo-description">
                Seleccione Empresa
              </h3>
            </div>
            <Form
              onSubmit={(formObj) => {
                formObj.bill_detail_id = modalSuscriptionAudit;
                formObj.company_id = formObj.company_id.id;
                addSuscription(formObj);
                setModalSuscriptionAudit(null);
              }}
            >
              {({ handleSubmit, values }) => (
                <form className="form-topic" onSubmit={handleSubmit}>
                  <div className="div-form-topic">
                    <Field name="company_id">
                      {({ input, meta }) => (
                        <>
                          <label className="tipo-title">Empresa</label>

                          <Select
                            {...input}
                            className="tipo-description"
                            placeholder={"Seleccione una empresa"}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={user.contact_companies}
                            isClearable={true}
                            isMulti={false}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="btn-actions-form">
                    <input
                      type="submit"
                      className="btn-orange-default"
                      value="Guardar"
                    />
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      )} */}
      <div className="content-subscribed flex-v">
        {module == LIBRARY_SERVICE && (
          <div className="content-library-suscribe">
            {dontSuscribed.elements.filter(
              (t) => t.quantity > t.consumers.length
            ).length > 0 ? (
              <h2 className="title-subscription tipo-title">
                Compras con usuarios pendientes por asignar{" "}
              </h2>
            ) : (
              <h2 className="title-subscription tipo-title"></h2>
            )}

            <div className="temporalDiv">
              {dontSuscribed.elements
                .filter((t) => t.quantity > t.consumers.length)
                .map((not) => (
                  <div key={not.id}>
                    <div
                      onClick={() => {
                        not.service_id == LIBRARY_COMPANY && !not.company_id
                          ? setModalSuscriptionLibrary(not.id)
                          : history.replace(
                              `/app/library/topics_subscribed/${not.id}`
                            );
                      }}
                      className="part-tarjet-topic cursor-action"
                    >
                      <div className="tarjet-general-topic-subscription">
                        <img
                          className="img-topic-subscription"
                          src={
                            not.product &&
                            not.product.image &&
                            process.env.REACT_APP_API_HOST +
                              "documents/" +
                              (not.product.image && not.product.image.name)
                          }
                          onError={(e) => {
                            e.target.src = imgError;
                          }}
                        />
                        <div className="content-general-topic-unsigned">
                          <h6 className="tipo-description txt-categoria-subscription">
                            <p
                              title={
                                not.is_personal
                                  ? "Personal"
                                  : not.assignable && not.assignable.name
                              }
                            >
                              <FontAwesomeIcon
                                className="icon-personal-company-cart"
                                icon={not.is_personal ? faUser : faBuilding}
                              />

                              {not.is_personal
                                ? "Personal"
                                : not.assignable &&
                                  shortText(not.assignable.name, 15)}
                            </p>
                          </h6>
                          <h2
                            className={`tipo-title ${fontSize(
                              not.product && not.product.name
                            )}`}
                          >
                            {" "}
                            {not.product && not.product.name}
                          </h2>

                          <h6 className="orange tipo-description">
                            {not.service && not.service.name}
                          </h6>
                          <div className="time-subscription tipo-title">
                            <strong>
                              a{" "}
                              {Math.round(
                                moment(not.end_date).diff(
                                  not.init_date,
                                  "months",
                                  true
                                )
                              )}{" "}
                              meses
                            </strong>
                          </div>
                          <div className="tipo-description">
                            <small>
                              Vence: {moment(not.end_date).format("YYYY-MM-DD")}
                            </small>
                          </div>
                          <div
                            className="content-main-service-number tipo-description"
                            onMouseOver={() => {
                              setState({
                                ...state,
                                toggle_users: true,
                                subscription_id: not.id,
                              });
                            }}
                            onMouseLeave={() => {
                              setState({
                                ...state,
                                toggle_users: false,
                                subscription_id: null,
                              });
                            }}
                          ></div>
                          <div className="dropdown-divider"></div>
                          <div className="orange id-factura tipo-title">
                            <strong>
                              ID de compra:{" "}
                              {/* {not.package.order?not.package.order.bill_number:0} */}
                            </strong>
                          </div>

                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <h2 className="title-subscription tipo-title">
          Suscripciones asignadas{" "}
        </h2>
        <div className="temporalDiv">
          {allSusbcription.elements
            .filter((effec) => effec.service_id === module)
            .map((sub) => (
              <div key={sub.id}>
                <div className="part-tarjet-topic">
                  <Link
                    to={`${
                      sub.service_id === CAPACITY_SERVICE
                        ? "/app/effective/documents/" + sub.id
                        : sub.service_id === LIBRARY_SERVICE && !sub.is_personal
                        ? `/app/library/topics_subscribed/${sub.id}`
                        : sub.service_id == AUDIT_SERVICE
                        ? `/app/effective/${sub.id}/description`
                        : `/app/effective/${sub.id}/description`
                    }`}
                  >
                    <div
                      className={`tarjet-general-topic-subscription ${
                        isActive(sub) ? "" : "tarjet-subscription-inactive"
                      } `}
                    >
                      <img
                        className="img-topic-subscription"
                        src={getSrc(sub)}
                        onError={(e) => {
                          e.target.src = imgError;
                        }}
                      />
                      <div className="content-general-topic">
                        <div className="subscription-topic">
                          <div
                            className={`${
                              isActive(sub)
                                ? "subscription-tarjet-topic-true2"
                                : "subscription-tarjet-topic-false"
                            } tipo-boton`}
                          >
                            {isActive(sub) ? "Activo" : "Vencido"}
                          </div>
                        </div>
                        <h6 className="tipo-description txt-categoria-subscription">
                          <p
                            title={
                              sub.is_personal
                                ? "Personal"
                                : sub.assignable && sub.assignable.name
                            }
                          >
                            <FontAwesomeIcon
                              className="icon-personal-company-cart"
                              icon={sub.is_personal ? faUser : faBuilding}
                            />
                            {sub.is_personal
                              ? "Personal"
                              : sub.assignable &&
                                shortText(sub.assignable.name, 15)}
                          </p>
                        </h6>
                        {sub.product && (
                          <h2
                            className={`tipo-title ${fontSize(
                              sub.product.name
                            )}`}
                          >
                            {" "}
                            {sub.product.name}
                          </h2>
                        )}

                        {/*   <h6 className="orange tipo-description">
                          {sub.service.name}
                        </h6> */}
                        <div className="time-subscription tipo-title">
                          <strong>
                            a {diff(sub)}
                            {diff(sub) > 1 ? " meses" : " mes"}{" "}
                          </strong>
                        </div>
                        <div className="tipo-description">
                          <small>
                            Vence: {moment(sub.end_date).format("YYYY-MM-DD")}
                          </small>
                        </div>
                        <div
                          className="content-main-service-number tipo-description"
                          onMouseOver={() => {
                            setState({
                              ...state,
                              toggle_users: true,
                              subscription_id: sub.id,
                            });
                          }}
                          onMouseLeave={() => {
                            setState({
                              ...state,
                              toggle_users: false,
                              subscription_id: null,
                            });
                          }}
                        ></div>
                        <div className="dropdown-divider"></div>
                        {module !== LIBRARY_SERVICE && (
                          <>
                            <small className="">
                              {module !== AUDIT_SERVICE
                                ? "Disponible"
                                : "Auditorias"}{" "}
                              :{" "}
                              {module === CAPACITY_SERVICE
                                ? getCapacity(sub)
                                : sub.quantity + " / " + sub.consumers.length}
                            </small>
                            {module === AUDIT_SERVICE && (
                              <small>
                                Auditores :{" "}
                                {getSumAuditors(sub.childs_effectives)}
                              </small>
                            )}
                            <div className="dropdown-divider"></div>
                          </>
                        )}

                        <div className="orange id-factura tipo-title">
                          <strong>
                            ID de compra:{" "}
                            {sub.package &&
                              sub.package.order &&
                              sub.package.order.bill_number}
                          </strong>
                        </div>

                        <br />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>

      <TooltipModule
        services={services}
        module={module}
        setModule={setModule}
      />
    </div>
  );
};

export default Subscribed;
