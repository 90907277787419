import React, { useState, useEffect, useContext } from "react";
import {
  SpecialtiesIndexContext,
  STATUS_LOADED,
} from "../../../contexts/audit/specialties/SpecialtiesIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { Field, Form } from "react-final-form";
import "../specialties/index.css";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    name: "",
    description: "",
  },
  originalItem: {},
};

const CreateSpecialty = ({ state, setState, specialties_id }) => {
  const [{ specialties }, { updateSpecialty, createSpecialty }] = useContext(
    SpecialtiesIndexContext
  );
  let [stateCreate, setStateCreate] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);

  useEffect(() => {
    if (specialties_id && specialties.status === STATUS_LOADED) {
      let el = specialties.elements.filter((e) => e.id === specialties_id)[0];
      setStateCreate({
        ...stateCreate,
        item: {
          name: el.name,
          description: el.description,
        },
        status: STATUS_EDIT,
      });
    }
  }, [specialties_id, specialties]);

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      setState({ ...state, toggle_modal: false, specialties_id: null });
      updateSpecialty(formObj, specialties_id);
    } else {
      createSpecialty(formObj);
      setState({ ...state, toggle_modal: false });
    }
  };

  return (
    <Form
      onSubmit={(formObj) => {
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form-specialty">
          <div className="container-gray-create-speciality">
          <div className="form-group div-form-specialty">
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Nombre:</label>
                  <input
                    {...input}
                    type="text"
                    className="form-control tipo-description"
                    placeholder="Nombre de la especialidad"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group div-form-specialty">
            <Field name="description" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Descripción:</label>
                  <textarea
                    {...input}
                    type="text"
                    className="form-control tipo-description"
                    placeholder="Descripción de la especialidad"
                    rows="3"
                  ></textarea>
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          
          </div>
          <div className="flex justify-end w-100">
            <input
              type="submit"
              value="Guardar"
              className="btn-orange-default"
            />
          </div>
        </form>
      )}
      
    </Form>
  );
};

export default CreateSpecialty;
