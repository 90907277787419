import {
  faCertificate,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { EffectiveFormContext } from "../../../contexts/billing/EffectiveFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const LIBRARY_SERVICE = 4;
const AUDIT_SERVICE = 1;
const CAPACITY_SERVICE = 3;
const Description = ({ computedMatch: { params, url } }) => {
  let { effective_id } = params;
  const [{ packageEffective }, { setpackageEffective }] = useContext(
    EffectiveFormContext
  );
  const [{}, { number_format }] = useContext(SecurityContext);

  useEffect(() => {
    setpackageEffective({ ...packageEffective, effective_id: effective_id });
  }, [effective_id]);
  return (
    <div className="margin-auto">
      <div
        key={packageEffective.element.id}
        className={`bg-white  container-tarjet-service`}
      >
        <div
          className={`bg-module-library   tipo-title txt-center service-title flex`}
        >
          <div
            className={
              packageEffective.element.is_persona
                ? "icon-paymnet-individual"
                : "icon-paymnet-company"
            }
          ></div>
          <h5>
            {packageEffective.element.is_persona
              ? "PLAN INDIVIDUAL"
              : "PLAN EMPRESARIAL"}
          </h5>
          {packageEffective.element.is_free && (
            <span className="style-free-package">Al registrarse</span>
          )}
        </div>
        <div
          className={`flex-wrap content-check-subscription ${
            module == AUDIT_SERVICE ? "content-texts-payment-audit" : ""
          }`}
        >
          <div className="txt-center tipo-titulo">
            {packageEffective.element.timeMonth} meses
          </div>
          <div className="txt-center tipo-titulo">
            {packageEffective.element.description}
          </div>
          {packageEffective.element.effectives &&
            packageEffective.element.effectives.map((detail) => {
              return (
                <div>
                  <FontAwesomeIcon
                    className="color-module-audit"
                    icon={faCheckCircle}
                  />
                  <span className="color-gray tipo-description">
                    {detail.quantity}{" "}
                    {detail.service.id == CAPACITY_SERVICE && "MB de "}
                    {detail.service.id == LIBRARY_SERVICE &&
                      parseInt(detail.quantity) == 1 &&
                      "Suscripción al módulo "}
                    {detail.service.id == LIBRARY_SERVICE &&
                      parseInt(detail.quantity) != 1 &&
                      "Suscripciones al módulo "}
                    {parseInt(detail.quantity) == 1
                      ? detail.service.singular_name
                      : detail.service.name}{" "}
                    &nbsp;
                    {detail.product && detail.product.name}
                  </span>
                </div>
              );
            })}
          <hr />
          {packageEffective.element.package &&
            packageEffective.element.package.package &&
            packageEffective.element.package.package.benefits.map((benefit) => {
              return (
                <div className="flex">
                  <FontAwesomeIcon
                    className="color-module-audit"
                    icon={faCertificate}
                  />
                  &nbsp;
                  <span className="span-paymnet-details color-gray tipo-description">
                    {benefit.note}
                  </span>
                </div>
              );
            })}
          <div className="tipo-title txt-center total-payment-desktop flex-column">
            <br />
            <strong>
              $ {number_format(packageEffective.element.value, 0)}
            </strong>{" "}
            COP
          </div>
          <Link to={`/app/billing/bills`}>
            <div className="flex">
              <div
                className={`flex-1 tipo-title cursor-action txt-center  justify-center btn-cart-payment-package`}
              >
                Ver Compra
                <br/>
                {packageEffective.element.order&&packageEffective.element.order.bill_number}
              </div>{" "}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Description;
