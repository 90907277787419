import React, { useEffect, useContext } from "react";
import {
  CompanyFormContext,
  ITEM_DEBE,
  ITEM_SUR,
  ITEM_SMMR,
  ITEM_QL,
  ITEM_QS,
  ITEM_UFILE,
  ITEM_MFILE,
} from "../../../contexts/general/CompanyFormContext";
import Inspection from "../../evaluation/inspection/Form";
import Select, { components } from "react-select";
import ImageUploader from "react-images-upload";
import { STATUS_LOADED } from "../../../contexts/security/SecurityContext";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";
const Pqrs = ({ match: { params } }) => {
  let { token } = params;
  const [{ evaluationPQRS }, { searchTokenCompany, haveError }] =
    useContext(CompanyFormContext);
  useEffect(() => {
    searchTokenCompany(token);
  }, [token]);
  return (
    <div className="container">
      <div className="title-tarjet-inspection flex-column">
        <div className="border-top-tarjet-inspection bg-orange"></div>
        <div className="content-title-inspect flex">
          <div className="flex-1">
            <p className="tipo-title">{evaluationPQRS.evaluation.title}</p>
            <p className="tipo-description">
              {evaluationPQRS.evaluation.description}
            </p>
          </div>
          <div>
            <img
              className={`img-company-pqrs`}
              src={
                process.env.REACT_APP_API_HOST +
                "/documents/" +
                (evaluationPQRS.evaluation.owners &&
                  evaluationPQRS.evaluation.owners[0].owner.image &&
                  evaluationPQRS.evaluation.owners[0].owner.image.name)
              }
              onError={(e) => {
                e.target.src = imgAduitorCheck;
              }}
            ></img>
          </div>
        </div>
      </div>
      <br />

      <Items
        first={true}
        parentItem_indexs={[]}
        items={
          evaluationPQRS.evaluation.items ? evaluationPQRS.evaluation.items : []
        }
      />
    </div>
  );
};
const Items = ({ items, first, parentItem_indexs }) => {
  const [{ itemDeploy }, { setItemDeploy, savePqrs }] =
    useContext(CompanyFormContext);
  const setDeploy = (parentsWithIndex) => {
    let code = JSON.stringify(parentsWithIndex);
    if (itemDeploy["item" + code]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + code]: !itemDeploy["item" + code],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + code]: true });
    }
  };
  return (
    <div className="form-row">
      {items.map((item, index) => (
        <>
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_DEBE && (
            <DEBE
              index={index}
              parentItem_indexs={[...parentItem_indexs]}
              first={first}
              item={item}
            />
          )}
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_SUR && (
            <>
              <SUR
                index={index}
                parentItem_indexs={[...parentItem_indexs]}
                first={first}
                item={item}
              />
            </>
          )}
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_QS && (
            <>
              <QS
                index={index}
                parentItem_indexs={[...parentItem_indexs]}
                first={first}
                item={item}
              />
            </>
          )}
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_QL && (
            <>
              <QL
                index={index}
                parentItem_indexs={[...parentItem_indexs]}
                first={first}
                item={item}
              />
            </>
          )}
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_UFILE && (
            <>
              <File
                index={index}
                parentItem_indexs={[...parentItem_indexs]}
                first={first}
                item={item}
                multi={false}
              />
            </>
          )}
          {(item.item_type.code
            ? item.item_type.code
            : item.item_type.item_type.code) === ITEM_MFILE && (
            <>
              <File
                index={index}
                parentItem_indexs={[...parentItem_indexs]}
                first={first}
                item={item}
                multi={true}
              />
            </>
          )}
          {item.items.length > 0 && (
            <Items
              items={item.items}
              parentItem_indexs={[...parentItem_indexs, index]}
              first={false}
            />
          )}
        </>
      ))}

      <div onClick={() => savePqrs()} className="flex-end">
        <button className="btn-orange-default">Guardar</button>
      </div>
    </div>
  );
};
const DEBE = ({ item, index, first, parentItem_indexs }) => {
  return (
    <div className=" flex">
      <div className="container-calificable  flex justify-end">
        <>
          {item.options_item.map((opt) => {
            return (
              <div key={opt.id} className="flex-1 flex">
                <div
                /*  onClick={() =>
                    addResponseSUR(item, parentItem_indexs, index, opt)
                  }
                  className={`icon-calificables ${validateCalificable(
                    item.results,
                    opt
                  )} `} */
                ></div>
              </div>
            );
          })}
        </>

        <div className="flex-2 flex">
          <div
            /*  onClick={() => setNote({ ...note, item_id: item.id })} */
            className="icon-note-evaluation"
          >
            <div className="orange container-number-notes-evaluation tipo-cursiva">
              {/*  {item.notes.length} */}
            </div>
          </div>
        </div>

        <div className="flex-document-column-evaluation container-calificable-item-evaluation-desktop">
          <div className="">
            <label
              className="label-input-file-evaluation-report-desktop"
              htmlFor="upload"
            >
              <div className="orange container-number-documents-evaluation tipo-cursiva">
                {/*    {item.documents.length} */}
              </div>
            </label>
            <input
              /*   onChange={uploadImage(item.id)} */
              type="file"
              name="file"
              id="file"
              title={item.id}
              className="input-file-evaluation-report-desktop"
            />
          </div>
          {/*     {loadDocument.item_id == item.id &&
            loadDocument.status == STATUS_LOADING && (
              <img src={loadingImage} className="image-loading-item-desktop" />
            )} */}
        </div>
      </div>
    </div>
  );
};
const File = ({ item, index, first, parentItem_indexs }) => {
  const [{ filesPqrs, filesItemsPqrs }, { setfilesPqrs, setfilesItemsPqrs }] =
    useContext(CompanyFormContext);
  const validatefile = (e) => {
/*     if (item.description === "evidence") {
      if (filesPqrs["files" + item.id]) {
        setfilesPqrs({
          ...filesPqrs,
          ["files" + item.id]: e,
        });
      } else {
        setfilesPqrs({ ...filesPqrs, ["files" + item.id]: e });
      }
    }  */
      if (filesPqrs["files" + item.id]) {
        setfilesItemsPqrs({
          ...filesItemsPqrs,
          ["files" + item.id]: e,
        });
      } else {
        setfilesItemsPqrs({ ...filesItemsPqrs, ["files" + item.id]: e});
      }
    
  };
  return (
    <div className="form-group w-100">
      <label className="control-label">
        {item.is_required ? "* " : ""}
        {item.title}
      </label>
      <div>
        {item.options_item.map((option, indexOp) => (
          <ImageUploader
            withIcon={true}
            withPreview={true}
            buttonText="Seleccione imagenes"
            className="image-pqrs"
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            maxFileSize={5242880}
            withIcon={false}
            onChange={(e) => validatefile(e)}
            fileSizeError="Excede el peso permitido"
            fileTypeError="Extensión no permitida"
            label="Peso máximo 5mb,acepta:jpg,gif,png"
          />
        ))}
      </div>
    </div>
  );
};
const SUR = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation },
    { setFieldItem, addResponseSUR, haveError },
  ] = useContext(CompanyFormContext);

  const customStylesError = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : false ?
        '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : false ?
          '#ddd' : 'red'
      }
    })
  }
  return (
    <div className="form-group form-group col-md-6">
      <label className="control-label">
        {item.is_required ? "* " : ""}
        {item.title}
      </label>
      <Select
        onChange={(e) => addResponseSUR(item, parentItem_indexs, index, e)}
        className={`tipo-description `}
        placeholder={"Seleccione una opción"}
        getOptionLabel={(option) => `${option.description}`}
        getOptionValue={(option) => option}
        options={item.options_item}
        isClearable={false}
        styles={haveError(item)? customStylesError:"" }
        isMulti={false}
      />
    </div>
  );
};
const QL = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation },
    { setFieldItem, setTextOptionQuestion, haveError },
  ] = useContext(CompanyFormContext);
  return (
    <div className="form-group w-100">
      <label className="control-label">
        {item.is_required ? "* " : ""}
        {item.title}
      </label>
      <div>
        {item.options_item.map((option, indexOp) => (
          <textarea
            key={indexOp}
            onChange={(e) =>
              setTextOptionQuestion(
                e.target.value,
                parentItem_indexs,
                index,
                option
              )
            }
            value={
              item.results.length > 0 ? item.results[0].pivot.response : ""
            }
            type="text"
            className={`form-control ${haveError(item) ? "is-invalid" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};
const QS = ({ item, index, first, parentItem_indexs }) => {
  const [
    { itemSelected, inEvaluation },
    { setFieldItem, setTextOptionQuestion, haveError },
  ] = useContext(CompanyFormContext);
  return (
    <div className="form-group  col-md-6">
      <label className="control-label">
        {item.is_required ? "* " : ""}
        {item.title}
      </label>
      <div>
        {item.options_item.map((option, indexOp) => (
          <input
            key={indexOp}
            onChange={(e) =>
              setTextOptionQuestion(
                e.target.value,
                parentItem_indexs,
                index,
                option
              )
            }
            value={
              item.results.length > 0 ? item.results[0].pivot.response : ""
            }
            type="text"
            className={`form-control ${haveError(item) ? "is-invalid" : ""}`}
          />
        ))}
      </div>
    </div>
  );
};
export default Pqrs;
