import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "../topic/forum.css";
import imgError from "../../../IMG-ERROR-FORUM.png";
import imgError2 from "../../../img_zeus/default/PROFILE.jpeg";
import btn_edit from "../../../img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import btn_delete from "../../../img_zeus/icons_library/BOTON-ELIMINAR.png";
import { EntryIndexContext } from "../../../contexts/forum/EntryIndexContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import CrearEntry from "../topic/entryForm";
import Responses from "../topic/functionResponses";
import { useHistory } from "react-router-dom";

const initialState = {
  entries: {},
  modal: false,
  topic: {},
  image: "",
  toogle_create: false,
  toogle_edit: false,
  show_response: false,
  show_responses: false,
  show_newResponse: false,
  show_newResponses: false,
  entry_id: null,
  response_id: null,
  entryEdit: {
    id: null,
    title: "",
    body: ""
  },
  entryResponseEdit: {
    id: null,
    body: ""
  },
  item: {
    title: "",
    person_id: "",
    topic_id: "",
    body: "",
    status: "",
    entry_id: "",
    newEntryResponse: ""
  },
  newResponse: {
    id: null,
    body: ""
  },
  responses: [],
  originalItem: {}
};
const STATE_USER_BANNED = "Banned";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const Index = ({ computedMatch }) => {
  let [{ entries, entryResponse, flag, topic }, actions] = useContext(
    EntryIndexContext
  );

  let {
    addScore,
    valorable,
    classLike,
    refreshEntries,
    addScore2,
    valorable2,
    createEntryResponse
  } = actions;
  let [state, setState] = useState(initialState);
  let [{ user }, { can, isAdminForum, changeStateUser }] = useContext(
    SecurityContext
  );
  let { params } = computedMatch;
  let { topic_id } = params;
  let history = useHistory();

  useEffect(() => {
    authorized();
  }, [topic_id]);

  const authorized = () => {
    let iCanView = false;

    console.log(user);
    let checkSub = user.topicsActive.filter(
      t => t.id== topic_id 
    ); 
    if (isAdminForum(topic_id) || user.is_admin || checkSub.length > 0) {
      iCanView = true;
    }
    iCanView
      ? actions.setTopicId(topic_id)
      : history.push(`/app/library/topic/${topic_id}/payment`);
  };

  const formatDate = dateTime => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Augosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    var date = new Date(dateTime);
    var year = date.getFullYear();
    var day = date.getDate();
    var shorMonth = monthNames[date.getMonth()].substr(0, 3);

    return day + " / " + shorMonth + " / " + year;
  };

  let setImage = field => e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      actions.createImgForum(file);
    };
    reader.readAsDataURL(file);
  };

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!"
    }).then(result => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const setStatusUser = (status, person_id) => {
    Swal.fire({
      title: "¿Realmente deseas Bloquear a esta persona?",
      text: "¡El usuario no podrá ingresar al sistema!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Bloquear!"
    }).then(result => {
      if (result.value) {
        changeStateUser(status, person_id);
        refreshEntries();
      }
    });
  };
  const showResponse = entry_id => {
    if (entry_id == state.entry_id) {
      setState({
        ...state,
        show_response: !state.show_response
      });
    } else {
      setState({
        ...state,
        entry_id: entry_id,
        show_response: true
      });
    }
  };

  const showResponse2 = response_id => {
    if (response_id == state.response_id) {
      setState({
        ...state,
        show_responses: !state.show_responses
      });
    } else {
      setState({
        ...state,
        response_id: response_id,
        show_responses: true
      });
    }
  };

  const showResponseInput = entry_id => {
    if (entry_id === state.entry_id) {
      setState({
        ...state,
        show_newResponse: !state.show_newResponse
      });
    } else {
      setState({
        ...state,
        entry_id: entry_id,
        show_newResponse: true
      });
    }
  };

  const showResponseInput2 = response_id => {
    if (response_id == state.response_id) {
      setState({
        ...state,
        show_newResponses: !state.show_newResponses
      });
    } else {
      setState({
        ...state,
        response_id: response_id,
        show_newResponses: true
      });
    }
  };

  let setFieldNewResponse = field => e => {
    setState({
      ...state,
      newResponse: { ...state.newResponse, [field]: e.target.value }
    });
  };

  let setFieldResponseEdit = field => e => {
    setState({
      ...state,
      entryResponseEdit: { ...state.entryResponseEdit, [field]: e.target.value }
    });
  };

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      actions.updateEntry(state.entryEdit);
      setState({ ...state, entryEdit: { id: null } });
    }
  };

  const _handleKeyDown2 = e => {
    if (e.key === "Enter") {
      actions.updateEntryResponse(state.entryResponseEdit);
      setState({ ...state, entryResponseEdit: { id: null } });
    }
  };

  const _handleKeyDown3 = e => {
    if (e.key === "Enter") {
      setState({
        ...state,
        show_newResponse: false,
        item: { newEntryResponse: "" }
      });
      actions.createEntryResponse(state.item.newEntryResponse, state.entry_id);
    }
  };

  let functionSubmit = e => {
    e.preventDefault();
    setState({
      ...state,
      show_newResponse: false,
      item: { newEntryResponse: "" }
    });
    actions.createEntryResponse(state.item.newEntryResponse, state.entry_id);
  };

  const _handleKeyDown4 = e => {
    if (e.key === "Enter") {
      setState({
        ...state,
        show_newResponses: false,
        newResponse: { body: "" }
      });
      actions.createEntryResponse(
        state.newResponse.body,
        state.entry_id,
        state.newResponse.id
      );
    }
  };

  let functionSubmit2 = e => {
    e.preventDefault();
    setState({ ...state, show_newResponses: false, newResponse: { body: "" } });
    actions.createEntryResponse(
      state.newResponse.body,
      state.entry_id,
      state.newResponse.id
    );
  };

  let setFieldEdit = field => e => {
    setState({
      ...state,
      entryEdit: { ...state.entryEdit, [field]: e.target.value }
    });
  };

  let setField = field => e => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };

  let fontSize = nameTopic => {
    if (nameTopic && nameTopic.length <= 13) {
      return "font-short";
    } else if (nameTopic && nameTopic.length <= 26) {
      return "font-medium";
    } else if (nameTopic && nameTopic.length <= 39) {
      return "font-long";
    } else {
      return "font-more-long";
    }
  };

  return (
    <div className="forum-general">
      <div className="forum">
        {state.modal && (
          <div className="back-document">
            <div className="modal">
              <div className="header-modal">
                <div
                  className="salir-topic cursor-action"
                  onClick={() => {
                    setState({
                      ...state,
                      modal: !state.modal
                    });
                  }}
                ></div>
                <h3 className="title-form-topic tipo-description">
                  Nueva Pregunta en Foro
                </h3>
              </div>
              <div className="create-entry-component">
                <CrearEntry
                  modalState={state}
                  setModalState={setState}
                  topic_id={params}
                ></CrearEntry>
              </div>
            </div>
          </div>
        )}
        <br />
        <nav aria-label="breadcrumb" className="flex-1 cursor-action">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/app/library/topics" className="tipo-title">
                Temas
              </Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link
                to={`/app/library/topic/${topic_id}`}
                className="tipo-description"
              >
                Contenido
              </Link>
            </li>
            <li
              className="breadcrumb-item orange tipo-description"
              aria-current="page"
            >
              Foro
            </li>
          </ol>
        </nav>
        <div
          className="link-create-entry create-entry bg-orange tipo-boton cursor-action"
          onClick={() => {
            setState({ ...state, modal: true });
          }}
        >
          Nueva pregunta
        </div>
        <div className="title-forum">
          <h1 className={`tipo-title ${fontSize(topic.name)}`}>{topic.name}</h1>
          &nbsp;&nbsp;
          <h2 className="orange tipo-description">Foro</h2>
        </div>
        <br />

        <section>
          {entries.elements.map(entry => (
            <div key={entry.id} className="entry">
              <img
                className="img-profile-foro"
                src={
                  process.env.REACT_APP_API_HOST +
                  "/documents/" +
                  (entry.person_entry &&
                    entry.person_entry.image &&
                    entry.person_entry.image.name)
                }
                onError={e => {
                  e.target.src = imgError2;
                }}
              ></img>
              <div className="entry-body tipo-description">
                {state.entryEdit.id != entry.id && (
                  <div className="">
                    <strong className="tipo-title">{entry.title} </strong>
                    <small className="color-gray">
                      {formatDate(entry.created_at)}
                    </small>
                    &nbsp;&nbsp;&nbsp;
                    {(isAdminForum(topic_id) || user.is_admin) && (
                      <img
                        className="cursor-action btn-edit-action-entry"
                        onClick={() => {
                          checkDelete(
                            "¿Realmente deseas eliminar esta pregunta?",
                            `actions.deleteEntry(${entry.id})`
                          );
                        }}
                        title="eliminar pregunta"
                        src={btn_delete}
                      />
                    )}
                    &nbsp;
                    {(isAdminForum(topic_id) ||
                      user.is_admin ||
                      user.id == entry.person_id) && (
                      <img
                        className="cursor-action btn-edit-action-entry"
                        onClick={() =>
                          setState({
                            ...state,
                            entryEdit: { id: entry.id }
                          })
                        }
                        title="editar pregunta"
                        src={btn_edit}
                      />
                    )}
                    &nbsp;
                    {(isAdminForum(topic_id) || user.is_admin) &&
                      entry.person_id != user.id &&
                      entry.person_entry.status != STATE_USER_BANNED && (
                        <FontAwesomeIcon
                          className="cursor-action "
                          title="bloquear usuario"
                          icon={faUserLock}
                          onClick={() => {
                            setStatusUser(STATE_USER_BANNED, entry.person_id);
                          }}
                        />
                      )}
                    &nbsp;
                    {(isAdminForum(topic_id) || user.is_admin) &&
                      entry.person_entry.status == STATE_USER_BANNED && (
                        <span className="tipo-cursiva orange">Baneado</span>
                      )}
                    <div className="tipo-description">
                      <small>
                        de {entry.person_entry && entry.person_entry.first_name}
                      </small>
                    </div>
                    <div className="tipo-description">{entry.body}</div>
                  </div>
                )}
                {state.entryEdit.id === entry.id && (
                  <div className="">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Titulo"
                      defaultValue={entry.title}
                      onChange={setFieldEdit("title")}
                      onKeyDown={_handleKeyDown}
                    />
                    <small className="color-gray">
                      {formatDate(entry.created_at)}
                    </small>
                    &nbsp;&nbsp;&nbsp; &nbsp;
                    <div className="tipo-description">
                      de {entry.person_entry && entry.person_entry.first_name}
                    </div>
                    <textarea
                      className="form-control"
                      type="text"
                      cols="75"
                      rows="4"
                      defaultValue={entry.body}
                      onChange={setFieldEdit("body")}
                      placeholder="Cuerpo"
                      onKeyDown={_handleKeyDown}
                    />
                    <div className="flex">
                      <div
                        className="cancel-edit-entry tipo-boton"
                        onClick={() =>
                          setState({ ...state, entryEdit: { id: null } })
                        }
                      >
                        Cancelar
                      </div>
                      <div
                        onClick={() => {
                          actions.updateEntry(state.entryEdit);
                          setState({ ...state, entryEdit: { id: null } });
                        }}
                        className="cancel-edit-entry tipo-boton"
                      >
                        Editar
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="responder tipo-description">
                <div
                  className={`like ${classLike(1, entry) ? "activeLike" : ""}`}
                  onClick={() => {
                    flag.like === true && addScore(1, entry);
                  }}
                ></div>
                &nbsp;&nbsp;
                {valorable(1, entry)}
                <div
                  className={`dislike ${
                    classLike(0, entry) ? "activeDislike" : ""
                  }`}
                  onClick={() => {
                    flag.like === true && addScore(0, entry);
                  }}
                ></div>
                &nbsp;&nbsp;
                {valorable(0, entry)}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  className="cursor-action"
                  onClick={() => {
                    showResponseInput(entry.id);
                  }}
                >
                  <strong>Responder</strong>
                </div>
                &nbsp;&nbsp;&nbsp;
                <div
                  className="cursor-action"
                  onClick={() => {
                    showResponse(entry.id);
                  }}
                >
                  {entry.entry_responses.length} Comentarios
                </div>
                {entry.id == state.entry_id && state.show_newResponse == true && (
                  <form
                    className="form-entryResponse"
                    onSubmit={functionSubmit}
                  >
                    <input type="text" hidden defaultValue={state.entry_id} />
                    <textarea
                      className="form-control txt-response"
                      onChange={setField("newEntryResponse")}
                      value={state.item.newEntryResponse}
                      type="text"
                      required
                      placeholder="Respuesta"
                      onKeyDown={_handleKeyDown3}
                    />
                    <div className="flex btn-new-response">
                      <div
                        onClick={() => {
                          setState({
                            ...state,
                            show_newResponse: false,
                            item: { newEntryResponse: "" }
                          });
                        }}
                        className="cancel-edit-entry tipo-boton"
                      >
                        Cancelar
                      </div>
                      <input
                        className="create-entry-div tipo-boton"
                        type="submit"
                        value="Responder"
                      />
                    </div>
                    <br />
                  </form>
                )}
              </div>
              {/* respuestas */}
              {entry.id == state.entry_id &&
                state.show_response == true &&
                entry.entry_responses.map(resp => {
                  return (
                    <div className="main-entry-response" key={resp.id}>
                      <img
                        className="img-profile-foro-response"
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          (resp.person_entry_response &&
                            resp.person_entry_response.image &&
                            resp.person_entry_response.image.name)
                        }
                        onError={e => {
                          e.target.src = imgError2;
                        }}
                      ></img>
                      <div className="entry-response">
                        {state.entryResponseEdit.id != resp.id && (
                          <>
                            <h6 className="tipo-title">
                              <small>
                                {resp.person_entry_response.first_name}
                              </small>{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <small className="color-gray tipo-description">
                                {formatDate(resp.created_at)}
                              </small>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {(isAdminForum(topic_id) || user.is_admin) && (
                                <img
                                  className="cursor-action btn-edit-action-entry"
                                  onClick={() => {
                                    checkDelete(
                                      "¿Realmente deseas eliminar esta respuesta?",
                                      `actions.deleteEntryResponse(${resp.id})`
                                    );
                                  }}
                                  title="eliminar respuesta"
                                  src={btn_delete}
                                />
                              )}
                              &nbsp;
                              <img
                                className="cursor-action btn-edit-action-entry"
                                onClick={() =>
                                  setState({
                                    ...state,
                                    entryResponseEdit: { id: resp.id }
                                  })
                                }
                                title="editar respuesta"
                                src={btn_edit}
                              />
                            </h6>
                            <div
                              style={{ marginTop: "-8px" }}
                              className="tipo-description"
                            >
                              {resp.body}
                            </div>
                          </>
                        )}
                        {state.entryResponseEdit.id === resp.id && (
                          <>
                            {/* style={{ marginTop: "-8px" }} */}
                            <h6 className="tipo-title flex">
                              <small>
                                {resp.person_entry_response.first_name}
                              </small>{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <small className="color-gray tipo-description">
                                {formatDate(resp.created_at)}
                              </small>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </h6>
                            <textarea
                              className="form-control"
                              type="text"
                              cols="100"
                              rows="3"
                              placeholder="Cuerpo"
                              defaultValue={resp.body}
                              onChange={setFieldResponseEdit("body")}
                              onKeyDown={_handleKeyDown2}
                            />
                            <div
                              style={{ marginTop: "-5px", marginBottom: "2px" }}
                              className="flex"
                            >
                              <div
                                className="cursor-action cancel-edit-response tipo-boton"
                                onClick={() =>
                                  setState({
                                    ...state,
                                    entryResponseEdit: { id: null }
                                  })
                                }
                              >
                                Cancelar
                              </div>
                              &nbsp;
                              <div
                                className="cursor-action cancel-edit-response tipo-boton"
                                onClick={() => {
                                  actions.updateEntryResponse(
                                    state.entryResponseEdit
                                  );
                                  setState({
                                    ...state,
                                    entryResponseEdit: { id: null }
                                  });
                                }}
                              >
                                Aceptar
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="content-like color-gray tipo-description">
                        <div
                          className={`like_response ${
                            classLike(1, resp) ? "activeLikeResponse" : ""
                          }`}
                          onClick={() => {
                            flag.like === true && addScore2(1, resp);
                          }}
                        ></div>
                        &nbsp;&nbsp;
                        {valorable2(1, resp)}
                        &nbsp;&nbsp;&nbsp;
                        <div
                          className={`dislike_response ${
                            classLike(0, resp) ? "activeDisLikeResponse" : ""
                          }`}
                          onClick={() => {
                            flag.like === true && addScore2(0, resp);
                          }}
                        ></div>
                        &nbsp;&nbsp;
                        {valorable2(0, resp)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          className="cursor-action"
                          onClick={() => {
                            showResponseInput2(resp.id);
                          }}
                        >
                          <strong>Responder</strong>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div
                          className="cursor-action"
                          onClick={() => {
                            showResponse2(resp.id);
                          }}
                        >
                          {resp.responses.length} Comentarios
                        </div>
                      </div>
                      {resp.id == state.response_id &&
                        state.show_newResponses == true && (
                          <form
                            className="form-entryResponses"
                            onSubmit={functionSubmit2}
                          >
                            <input
                              type="text"
                              hidden
                              defaultValue={state.entry_id}
                            />
                            <textarea
                              className="form-control txt-response"
                              onChange={setFieldNewResponse("body")}
                              value={
                                (state.newResponse.id === resp.id &&
                                  state.newResponse.body) ||
                                ""
                              }
                              onFocus={e => {
                                setState({
                                  ...state,
                                  newResponse: {
                                    ...state.newResponse,
                                    id: resp.id,
                                    body: ""
                                  }
                                });
                              }}
                              onKeyDown={_handleKeyDown4}
                              type="text"
                              required
                              placeholder="Respuesta"
                            />
                            <div className="flex btn-new-response">
                              <div
                                onClick={() => {
                                  setState({
                                    ...state,
                                    show_newResponses: false,
                                    newResponse: { body: "" }
                                  });
                                }}
                                className="cancel-edit-entry tipo-boton"
                              >
                                Cancelar
                              </div>
                              <input
                                className="create-entry-div tipo-boton"
                                type="submit"
                                value="Responder"
                              />
                            </div>
                          </form>
                        )}
                      {resp.id == state.response_id &&
                        state.show_responses == true && (
                          <Responses
                            data={resp.responses && resp.responses}
                            topic_id={topic_id}
                          />
                        )}
                    </div>
                  );
                })}
              {/* Fin respuestas */}

              <br />
            </div>
          ))}
        </section>
      </div>
      {/* Imagen foto */}
      <div className="content-general-img-forum">
        <div className="content-img-forum">
          {(isAdminForum(topic_id) || user.is_admin) && (
            <form className="form-create-img" encType="multipart/form-data">
              <label
                htmlFor="upload-img"
                className="cargar-img cursor-action"
              ></label>
              <input
                id="upload-img"
                type="file"
                accept="image/*"
                hidden
                onChange={setImage("image")}
              />
            </form>
          )}

          <img
            className="img-forum"
            src={
              process.env.REACT_APP_API_HOST +
              "documents/" +
              (topic.image_forum && topic.image_forum.name)
            }
            onError={e => {
              e.target.src = imgError;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
