exports = module.exports = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../img_zeus/ICONO-CANCELAR.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../img_zeus/ICONO-ACEPTAR.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../img_zeus/ICONO-ACEPTAR-2.png"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../../img_zeus/ICONO-CANCELAR-2.png"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../../../img_zeus/icons_audit/Añadir_item_off.png"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../../../img_zeus/icons_audit/añadir_titulo_off.png"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("../../../img_zeus/icons_audit/Añadir_item_on.png"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("../../../img_zeus/icons_audit/Añadir_titulo_on.png"));

// Module
exports.push([module.id, ".items-li {\n  margin-left: 2%;\n}\n.bg-ul-item {\n  background-color: white;\n}\n.li-item {\n  border: 0;\n}\n.btn-group-item {\n  float: right;\n}\n.input-item {\n  border: none;\n  width: 85%;\n}\n.input-subitem {\n  width: 85%;\n}\n.select-item_type_standard {\n  width: 100px;\n}\n.btn-add-item {\n  background: black;\n}\n.container-number-flex {\n  flex: 0.1 1;\n}\n.content-add-item {\n  margin-bottom: 14px;\n}\n.number-item-content {\n  flex: 0 1;\n}\n.flex-calification {\n  flex: 0.2 1;\n}\n\n.dotted-li {\n  border-left: dotted;\n}\n.btn-add-item:hover {\n  color: white;\n}\n.number-item-content-new {\n  flex: 0.1 1;\n}\n.container-new-input-item {\n  padding: 9px;\n}\n.number-item-content > p {\n  padding: 12px;\n  margin: 0;\n}\n.icon-cancel-item {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n}\n.icon-acept-item {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n}\n.icon-acept-item:hover {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.icon-cancel-item:hover {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n.icon-add-item {\n  background-image: url(" + ___CSS_LOADER_URL___4___ + ");\n}\n.icon-add-title {\n  background-image: url(" + ___CSS_LOADER_URL___5___ + ");\n}\n.icon-add-item:hover {\n  background-image: url(" + ___CSS_LOADER_URL___6___ + ");\n}\n.icon-add-title:hover {\n  background-image: url(" + ___CSS_LOADER_URL___7___ + ");\n}\n.container-margin-actions-item-standard > div {\n  width: 25px;\n  height: 25px;\n  margin: auto;\n  cursor: pointer;\n}\n\n.li-item-standard {\n  cursor: -webkit-grab;\n}\n", ""]);

