import React, { useContext, useEffect, useState } from "react";
import {
  BillingContext,
  SERVICE_LIBRARY,
  SERVICE_CAPACITY,
  MODEL_COURSE,
} from "../../contexts/billing/BillingContext";

import {
  SecurityContext,
  STATUS_LOADED,
} from "../../contexts/security/SecurityContext";
import "./Cart.css";
import CartEmpty from "./CartEmpty";
import { useLocation, useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faComment,
  faTrash,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
const Cart = () => {
  let history = useHistory();
  const [
    { order, conditions, policies },
    { deletePackage, addPackagesOnPayment },
  ] = useContext(BillingContext);
  const [total, settotal] = useState(0);
  const [tax, settax] = useState(0);
  const [planDown, setplanDown] = useState(null);
  const [activeTerms, setactiveTerms] = useState(false);
  const [{ user }, { number_format }] = useContext(SecurityContext);
  const [terms, setTerms] = useState(true);
  const validatedeletePackage = (id, index) => {
    if (planDown.id === id) {
      setplanDown(null);
    }
    deletePackage(id);
  };
  useEffect(() => {
    if (order.status === STATUS_LOADED) {
      let ordersFilter = order.element.packages.filter((p) => p.package);
      if (ordersFilter.length > 0) {
        setplanDown(ordersFilter[0].id);
      }
      let value = 0;
      let tax = 0;
      for (let p = 0; p < order.element.packages.length; p++) {
        const packageFor = order.element.packages[p];
        if (packageFor) {
          value = value + parseInt(packageFor.value);
          tax =
            tax + (parseInt(packageFor.value) * parseInt(packageFor.tax)) / 100;
        }
      }
      settotal(value);
      settax(tax);
    }
  }, [order.status]);

  return (
    order.status === STATUS_LOADED && (
      <div
        className={`${
          order.element.packages.length === 0 ? "margin-auto" : ""
        }`}
      >
        {order.element.packages.length > 0 && (
          <>
            <div className="header-cart">
              <h1>Es el Momento de Iniciar Algo Estupendo</h1>
              <h2>Procesa tu Pedido</h2>
            </div>

            <div className="container">
              <div className="flex container-general-cart">
                <div className="flex-1">
                  <div className="plans-cart">
                    {order.element.packages &&
                      order.element.packages.map((car, index) => {
                        return (
                          <div
                            key={index}
                            onMouseOver={() => setplanDown(car)}
                            className={` cursor-action plan-cart product-cart`}
                            id="eCom"
                          >
                            {car.package &&
                              parseInt(car.package.value) === 0 && (
                                <span className="badge-cart free-cart-plan">
                                  Obsequio
                                </span>
                              )}
                            {car.is_promotion && (
                              <span className="badge-cart">Mas popular</span>
                            )}

                            <object
                              className={`${
                                planDown && car.id === planDown.id
                                  ? "plan-active-cart"
                                  : ""
                              } mobile-hidden `}
                            >
                              <img
                                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/270939/arrow-right.png"
                                alt=""
                              />
                            </object>

                            <div className="planDesc-cart">
                              {car.package && (
                                <h3>
                                  {car.package.module_package &&
                                    car.package.module_package.singular_name}
                                </h3>
                              )}
                              {car.product && (
                                <h3 title={car.product.title}>
                                  {car.product_type == MODEL_COURSE
                                    ? "Capacitación"
                                    : "Plantilla"}
                                </h3>
                              )}
                              <div className="flex">
                                {" "}
                                <p className="mobile-hidden">
                                  {car.package && (
                                    <FontAwesomeIcon
                                      className="icon-personal-company-cart"
                                      icon={
                                        car.package.is_persona
                                          ? faUser
                                          : faBuilding
                                      }
                                    />
                                  )}
                                  {car.product && (
                                    <FontAwesomeIcon
                                      className="icon-personal-company-cart"
                                      icon={
                                        car.is_persona ? faUser : faBuilding
                                      }
                                    />
                                  )}

                                  {car.is_persona
                                    ? "Lo mejor para ti"
                                    : car.owner_company &&
                                      car.owner_company.name}
                                </p>
                                <p className="hidden mobile-show">
                                  {car.package && (
                                    <FontAwesomeIcon
                                      className="icon-personal-company-cart"
                                      icon={
                                        car.package.is_persona
                                          ? faUser
                                          : faBuilding
                                      }
                                    />
                                  )}
                                  {car.product && (
                                    <FontAwesomeIcon
                                      className="icon-personal-company-cart"
                                      icon={
                                        car.is_persona ? faUser : faBuilding
                                      }
                                    />
                                  )}
                                </p>
                                &nbsp;
                                <span className="price-mobile mobile-show hidden">
                                  $
                                  {car.package &&
                                    number_format(
                                      parseFloat(car.package.value) +
                                        parseFloat(
                                          (car.package.value *
                                            car.package.tax) /
                                            100
                                        )
                                    )}
                                  {car.product &&
                                    number_format(
                                      parseFloat(car.value) +
                                        parseFloat((car.value * car.tax) / 100)
                                    )}
                                  /COP
                                </span>
                              </div>
                            </div>
                            <div className="planPrice-cart">
                              <span className="mobile-hidden">
                                <sup>$</sup>
                                {car.package &&
                                  number_format(
                                    parseFloat(car.package.value) +
                                      parseFloat(
                                        (car.package.value * car.package.tax) /
                                          100
                                      )
                                  )}
                                {car.product &&
                                  number_format(
                                    parseFloat(car.value) +
                                      parseFloat((car.value * car.tax) / 100)
                                  )}
                                <sub>/COP</sub>
                              </span>
                              <button
                                className="cursor-action"
                                title="Remover"
                                onClick={() =>
                                  validatedeletePackage(car.id, index)
                                }
                              >
                                <FontAwesomeIcon
                                  className="cursor-action"
                                  icon={faTrashAlt}
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <br />
                  <div className="total-cart-container">
                    <ul>
                      <li>Subtotal</li>
                      <li>Iva</li>
                      <li>
                        <strong>Total</strong>{" "}
                      </li>
                    </ul>
                    <ul>
                      <li>
                        {" "}
                        <span>
                          <sup>$</sup>
                          {number_format(total)}
                        </span>
                      </li>
                      <li>
                        {" "}
                        <sup>$</sup> {number_format(tax)}
                      </li>
                      <li>
                        {" "}
                        <strong>
                          <sup>$</sup>
                          {number_format(total + tax)}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mobile-hidden flex-1">
                  {planDown && (
                    <div className="details-cart">
                      <div className="detHeader-cart">
                        <div>
                          <h3 className="detTitle">
                            {planDown.is_persona ? "Personal" : "Empresarial"}
                            {planDown.is_promotion && <span>Mas popular</span>}

                            {planDown.package &&
                              parseInt(planDown.package.value) === 0 && (
                                <span className="free-cart-plan">
                                  Obsequio{" "}
                                </span>
                              )}
                          </h3>

                          <p>{planDown && planDown.description}</p>
                        </div>
                      </div>
                      <div className="detDetails-cart">
                        <ul className="features-cart">
                          <h5 className="title-cart">caracteristicas</h5>
                          {planDown.product && (
                            <li>
                              <p className="p-caracteristic-package-cart">
                                {planDown.product.description}
                              </p>
                            </li>
                          )}
                          {planDown.package &&
                            planDown.package.details.map((det, index) => {
                              return (
                                <li key={index}>
                                  <p className="p-caracteristic-package-cart">
                                    {det.quantity}{" "}
                                    {det.service.id == SERVICE_CAPACITY
                                      ? " MB de "
                                      : ""}{" "}
                                    {det.service.id == SERVICE_LIBRARY &&
                                    det.quantity == 1
                                      ? "Suscripción al módulo de "
                                      : ""}{" "}
                                    {det.service.id == SERVICE_LIBRARY &&
                                    det.quantity != 1
                                      ? "Suscripciones al módulo de "
                                      : ""}
                                    {parseInt(det.quantity) == 1
                                      ? det.service.singular_name
                                      : det.service.name}{" "}
                                    &nbsp;
                                  </p>
                                </li>
                              );
                            })}
                        </ul>

                        <ul className="benefits-cart">
                          <h5 className="title-cart">beneficios del paquete</h5>
                          {planDown.package &&
                            planDown.package.benefits.map((ben, index) => {
                              return (
                                <li key={index}>
                                  <p>{ben.note}</p>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <form
              id="formPaymentSuscription"
              method="post"
              action={
                process.env.REACT_APP_IS_PRODUCTION == true ||
                process.env.REACT_APP_IS_PRODUCTION == "true"
                  ? "https://checkout.payulatam.com/ppp-web-gateway-payu"
                  : "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu"
              }
            >
              <input
                name="merchantId"
                type="hidden"
                value={order.element.merchantId}
              />
              <input
                name="accountId"
                type="hidden"
                value={order.element.accountId}
              />
              
              <input
                name="description"
                type="hidden"
                value="compra de paquetes"
              />
              <input
                name="referenceCode"
                type="hidden"
                value={order.element.reference}
              />
              <input name="amount" type="hidden" value={order.element.value} />
              <input name="tax" type="hidden" value="0" />
              <input name="taxReturnBase" type="hidden" value="0" />
              <input
                name="currency"
                type="hidden"
                value={order.element.currency}
              />
              <input
                name="signature"
                type="hidden"
                value={order.element.signature}
              />
              <input
                name="test"
                type="hidden"
                value={
                  process.env.REACT_APP_IS_PRODUCTION == true ||
                  process.env.REACT_APP_IS_PRODUCTION == "true"
                    ? "0"
                    : "1"
                }
              ></input>
              <input name="buyerEmail" type="hidden" value={user.email} />
              <input
                name="responseUrl"
                type="hidden"
                value={`${process.env.REACT_APP_API_PUB_HOST}billing/paymentPayUResponse`}
              />
              <input
                name="confirmationUrl"
                type="hidden"
                value={`${process.env.REACT_APP_API_PUB_HOST}billing/paymentPayUConfirm`}
              />
              <div className="flex justify-center">
                <input
                  className={`${
                    terms ? "gray-scale" : "cursor-action"
                  } tipo-title  txt-center white bg-orange btn-process-payment`}
                  name="Submit"
                  onClick={() =>
                    !terms ? addPackagesOnPayment() : setactiveTerms(true)
                  }
                  type="button"
                  value="Procesar pedido"
                />
                &nbsp;
                <input
                  className="mobile-hidden cursor-action tipo-title  txt-center white bg-orange btn-process-payment"
                  name="Payment"
                  onClick={() =>
                    history.push(
                      "/app/library/topic/billing/suscription/library"
                    )
                  }
                  type="button"
                  value="Seguir comprando"
                />
              </div>
            </form>
            <div className="txt-center politic-privacity-payment">
              {terms && activeTerms && <span style={{ color: "red" }}>* </span>}
              <input
                onClick={() => setTerms(!terms)}
                className={`check-terms-conditions `}
                type="checkbox"
              />{" "}
              Acepto{" "}
              <Link
                to={`/app/library/document/${
                  conditions.elements && conditions.elements.id
                }`}
                target="_blank"
              >
                Términos y Condiciones
              </Link>{" "}
              y la{" "}
              <Link
                to={`/app/library/document/${
                  policies.elements && policies.elements.id
                }`}
                target="_blank"
              >
                Política de Privacidad y Tratamiento de Datos Personales.
              </Link>
              <br />
              {terms && activeTerms && (
                <span style={{ color: "red" }} className="danger">
                  Debe aceptar los Términos y Condiciones
                </span>
              )}
            </div>
          </>
        )}
        {order.element.packages.length === 0 && <CartEmpty />}
      </div>
    )
  );
};

export default Cart;
