import React, { useState, useEffect, useContext } from "react";
import "./Activities.css";
import { AuditPlanFormContext } from "../../../contexts/audit/AuditPlanFormContext";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_evaluation from "../../../img_zeus/icons_audit/evaluacion_1.png";
import btn_exec from "../../../img_zeus/icons_audit/ingresar-02.png";
import Swal from "sweetalert2";
import Select, { components } from "react-select";
import btn_show from "../../../img_zeus/icons_audit/ICONO-VER.png";
import CreateActivity from "../modals/CreateActivity";
import ViewActivity from "../modals/ViewActivity";
import moment from "moment";
import btn_desplegar from "../../../img_zeus/icons_audit/BOTON-DESPLEGAR-AUDITORIA.png";
import btn_evidence from "../../../img_zeus/icons_audit/Adjuntar-item.png";
import btn_generate from "../../../img_zeus/icons_audit/generate.png";

import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
import Draggable from "react-draggable";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import ReactSpeedometer from "react-d3-speedometer";
import timeGridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { useHistory } from "react-router-dom";
//import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;

const initialState = {
  toggle_modal: false,
  toggle_modal2: false,
  activity_id: null,
  toggle: {
    init: false,
    end: false,
  },
  toggleCalendar: {
    active: false,
    activity_id: null,
    activity: {},
  },
  toggleItems: {
    activity_id: null,
    active: false,
  },
  events: [],
};

const Activities = ({ activities, participants, audit }) => {
  const [stateActivity, setStateActivity] = useState(activities);
  const [state, setState] = useState(initialState);
  const [toggleCalendar, settoggleCalendar] = useState(
    initialState.toggleCalendar
  );
  const [processFilter, setprocessFilter] = useState([]);
  const [auditorFilter, setauditorFilter] = useState([]);
  const [
    {},
    {
      deleteActivity,
      filterInit,
      filterEnd,
      closeActivities,
      generatePlanAction,
    },
  ] = useContext(AuditPlanFormContext);
  let [{ user }, {}] = useContext(SecurityContext);
  let history = useHistory();
  useEffect(() => {
    if (toggleCalendar.activity_id != null) {
      let activity = activities.filter(
        (e) => e.id == toggleCalendar.activity_id
      )[0];
      settoggleCalendar({ ...toggleCalendar, activity: activity });
    }
  }, [toggleCalendar.activity_id]);
  const arrayColumn = (array, columnName) => {
    return array.map(function (value, index) {
      return value[columnName];
    });
  };
  const haveThisProcess = (activity) => {
    if (activity.is_evaluable === false) {
      return false;
    } else {
      let processSelect = processFilter.filter(
        (p) => p.id === activity.process_id
      );
      return processSelect.length > 0 ? true : false;
    }
  };
  const haveThisAuditor = (activity) => {
    let participantSelect = auditorFilter.filter((p) =>
      arrayColumn(activity.audit_participants, "id").includes(p.id)
    );
    return participantSelect.length > 0 ? true : false;
  };
  useEffect(() => {
    let newActivities = activities;

    if (processFilter != null && processFilter.length > 0) {
      newActivities = newActivities.filter((a) => haveThisProcess(a));
    }

    if (auditorFilter != null && auditorFilter.length > 0) {
      newActivities = newActivities.filter((a) => haveThisAuditor(a));
    }
    setStateActivity(newActivities);
  }, [processFilter, auditorFilter]);
  useEffect(() => {
    let data = [];

    for (let i = 0; i < activities.length; i++) {
      let object = {
        title:
          activities[i].name.charAt(0).toUpperCase() +
          activities[i].name.slice(1),
        start: activities[i].init_date,
        end: activities[i].end_date,
        id: activities[i].id,
        allDay: false,
      };
      data.push(object);
    }
    setStateActivity(activities);
    setState({ ...state, events: data });
  }, [activities]);

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const closeAllActivities = () => {
    let activitiesEvaluators = [];
    for (let a = 0; a < audit.activities.length; a++) {
      const activityFor = audit.activities[a];
      for (let p = 0; p < activityFor.audit_participants.length; p++) {
        const participant = activityFor.audit_participants[p];

        if (
          participant.participant_type.is_audit == true &&
          participant.person_id == user.id
        ) {
          activitiesEvaluators.push(activityFor);
        }
      }
    }
    checkCloseActivity(true, activitiesEvaluators);
  };
  const isEvaluator = (user_id, activity) => {
    let isMyActivity = false;
    for (let p = 0; p < activity.audit_participants.length; p++) {
      if (activity.audit_participants[p].pivot.is_evaluator == true) {
        isMyActivity = true;
      }
    }
    return isMyActivity;
  };
  const isAudited = (user_id, activity) => {
    let isAudited = false;
    for (let p = 0; p < activity.audit_participants.length; p++) {
      if (
        activity.audit_participants[p].pivot.is_evaluator == false &&
        user_id == activity.audit_participants[p].person_id
      ) {
        isAudited = true;
      }
    }
    return isAudited;
  };
  const filterInitDate = () => {
    filterInit();
  };
  const checkCloseActivity = (close, activities) => {
    let activitiesOpen = [];
    let activities_id = [];
    for (let a = 0; a < activities.length; a++) {
      const activity = activities[a];
      activities_id.push(activity.id);
      if (activity.is_closed == false && activity.is_evaluable == true) {
        let isComplete = true;

        const evaluation = activity.evaluations[0];
        if (recursiveItemsResult(true, evaluation.items) == false) {
          isComplete = false;
        }

        if (isComplete == false) {
          activitiesOpen.push(activity);
        }
      }
    }
    if (activitiesOpen.length == 0) {
      closeActivities(close, activities_id);
    } else {
      Swal.fire({
        title: "¡Upsss!,estas actividades tienen items sin calificar",
        text: activitiesOpen.map((activity) => {
          return activity.name;
        }),
        icon: "warning",
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        confirmButtonText: "¡Si, entendido!",
      });
    }
  };
  const recursiveItemsResult = (allResult, items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.auditable_item != null) {
        if (item.auditable_item.required == true) {
          if (item.results.length == 0) {
            allResult = false;
          }
        }
      }
      if (item.items.length > 0) {
        allResult = recursiveItemsResult(allResult, item.items);
      }
    }
    return allResult;
  };
  const filterEndDate = () => {
    filterEnd();
  };
  const onEventClick = (arg) => {
    // bind with an arrow function
    setModalCalendarId(parseInt(arg.event.id));
  };
  const options = {
    defaultDate: "2016-01-12",
    header: {
      left: "prev,next Hoy",
      center: "title",
      right: "month,agendaWeek,agendaDay",
    },
  };
  const setModalCalendarId = (activity_id) => {
    settoggleCalendar({ ...toggleCalendar, activity_id: activity_id });
  };
  const getParticipants = (participants) => {
    let participantsString = "";
    for (let p = 0; p < participants.length; p++) {
      if (participants[p].pivot.is_evaluator == false) {
        if (p != 0) {
          participantsString =
            participantsString +
            participants[p].person.first_name +
            " " +
            participants[p].person.last_name +
            "\n";
        }
      }
    }
    return participantsString;
  };
  let shortText = (desc, range) => {
    if (desc && desc.length > range) {
      return desc.substr(0, range) + "...";
    }
    return desc;
  };
  const canOpenCloseActivity = (activity) => {
    let is_participant = false;
    for (let p = 0; p < activity.audit_participants.length; p++) {
      const participant = activity.audit_participants[p];
      if (
        participant.participant_type.is_audit &&
        participant.person_id == user.id
      ) {
        is_participant = true;
      }
    }
    if (audit.lider_person_id === user.id) {
      is_participant = true;
    }
    if (activity.is_closed == false) {
      if (
        (audit.status_id == STATUS_INPROGRESS ||
          audit.status_id == STATUS_EXECUTED) &&
        is_participant
      ) {
        return true;
      }
    } else {
      if (
        (audit.lider_person_id == user.id &&
          audit.status_id == STATUS_INPROGRESS) ||
        (audit.company.contact.id == user.id &&
          activity.is_evaluable == false &&
          audit.status_id == STATUS_INPROGRESS)
      ) {
        return true;
      }
    }
    return false;
  };
  const validateExecActivity = (activity) => {
    let isEvaluator = false;
    let isEvaluable = true;
    if (activity.evaluations.length == 0) {
      isEvaluable = false;
    }
    if (!activity.is_evaluable) {
      isEvaluable = false;
    }
    for (let p = 0; p < activity.audit_participants.length; p++) {
      const participant = activity.audit_participants[p];
      if (
        participant.person_id == user.id &&
        participant.participant_type.is_audit == true
      ) {
        isEvaluator = true;
      }
    }
    return isEvaluable && isEvaluator ? true : false;
  };
  const imEvaluator = () => {
    let isEvaluator = false;
    for (let p = 0; p < audit.participants.length; p++) {
      const participant = audit.participants[p];
      if (
        participant.person_id == user.id &&
        participant.participant_type.is_audit
      ) {
        isEvaluator = true;
      }
    }
    return isEvaluator;
  };
  return (
    <div>
      {toggleCalendar.activity_id != null && (
        <Draggable>
          <div
            style={{ cursor: "grab" }}
            className="container-activity-calendar"
          >
            <div className="w-100 header-modal-create-evaluation">
              <div className="w-100 flex">
                <div
                  onClick={() =>
                    settoggleCalendar({ ...toggleCalendar, activity_id: null })
                  }
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="txt-center txt-title-new-folder">Actividad</p>
              </div>
            </div>
            <div className="bg-white content-modal-dragable-activity">
              <div className="margin-control">
                <div className="input-basic-white">
                  {toggleCalendar.activity.name}
                </div>
              </div>
              <div className="margin-control">
                <label>Descripción</label>
                <div className="input-basic-white">
                  {toggleCalendar.activity.description}
                </div>
              </div>
              <div className="margin-control">
                <label>Fecha de inicio</label>
                <div className="input-basic-white">
                  {toggleCalendar.activity.init_date}
                </div>
              </div>
              <div className="margin-control">
                <label>Fecha de finalización</label>
                <div className="input-basic-white">
                  {toggleCalendar.activity.end_date}
                </div>
              </div>

              <div className="margin-control">
                <label>Participantes</label>
                <div className="input-basic-white">
                  {toggleCalendar.activity.audit_participants &&
                    toggleCalendar.activity.audit_participants.map(
                      (participant) => {
                        return (
                          <span key={participant.person.id}>
                            {participant.person.first_name}{" "}
                            {participant.person.last_name},
                          </span>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {state.toggle_modal === true && (
        <div className="back-document">
          <div className="modal-activities">
            <div className="header-modal-activities">
              <div
                className="salir-program"
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal: false,
                    activity_id: null,
                  });
                }}
              ></div>
              {state.activity_id !== null && (
                <h3 className="title-form-modal-activity tipo-description">
                  Editar Actividad
                </h3>
              )}{" "}
              {state.activity_id === null && (
                <h3 className="title-form-modal-activity tipo-description">
                  Crear Actividad
                </h3>
              )}
            </div>
            <div>
              <CreateActivity
                state={state}
                setState={setState}
                activity_id={state.activity_id}
                participants={participants}
              ></CreateActivity>
            </div>
          </div>
        </div>
      )}
      {state.toggle_modal2 === true && (
        <div className="back-document">
          <div className="modal-view-activity">
            <div className="header-modal-view-activity">
              <div
                className="salir-program"
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal2: false,
                    activity_id: null,
                  });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                Información de actividad
              </h3>
            </div>
            <div>
              <ViewActivity activity_id={state.activity_id}></ViewActivity>
            </div>
          </div>
        </div>
      )}
      <div className="flex">
        <div className="flex flex-1">
          <FontAwesomeIcon
            title={`${
              toggleCalendar.active
                ? "Cambiar la visualización a tabla"
                : "Cambiar visualización a calendario"
            }`}
            onClick={() =>
              settoggleCalendar({
                ...toggleCalendar,
                active: !toggleCalendar.active,
              })
            }
            className={`${toggleCalendar.active ? "orange" : ""} cursor-action`}
            icon={faCalendarAlt}
          />
          &nbsp; &nbsp;
          <div className="form-group container-filter-proces">
            <Select
              value={processFilter}
              onChange={(e) => setprocessFilter(e)}
              className="tipo-description"
              placeholder={"Todos los procesos"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={audit.company.process}
              isClearable={true}
              isMulti={true}
            />
          </div>
          &nbsp; &nbsp;
          <div className="form-group container-filter-auditor">
            <Select
              value={auditorFilter}
              onChange={(e) => setauditorFilter(e)}
              className="tipo-description"
              placeholder={"Todos los participantes"}
              getOptionLabel={(option) => (
                <div className="flex">
                  <img
                    className={` btn-auditor-activity-filter  cursor-action background-simple `}
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (option.person.image && option.person.image.name)
                    }
                    onError={(e) => {
                      e.target.src = imgAduitorCheck;
                    }}
                  ></img>
                  &nbsp; {option.person.email}
                </div>
              )}
              getOptionValue={(option) => option.id}
              options={audit.participants}
              isClearable={true}
              isMulti={true}
            />
          </div>
        </div>
        {(audit.status_id == STATUS_EXECUTED ||
          audit.status_id == STATUS_INPROGRESS) && (
          <div
          title="generar Plan de acción"
            onClick={() => generatePlanAction()}
            className="btn-orange-default-short"
          >
           <img width="18" src={btn_generate}></img> G. Plan de acción
          </div>
        )}

        {(audit.status_id == STATUS_EXECUTED ||
          audit.status_id == STATUS_INPROGRESS) &&
          imEvaluator() && (
            <div className=" flex  justify-end">
              <div
                onClick={() => closeAllActivities()}
                className="btn-orange-default-short"
              >
                Cerrar mis actividades
              </div>
            </div>
          )}

        {(audit.status_id == STATUS_PLANNED ||
          audit.status_id == STATUS_CREATED) &&
          audit.lider_person_id == user.id && (
            <div className=" flex  justify-end">
              <div
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal: true,
                    activity_id: null,
                  });
                }}
                className="btn-orange-default-short"
              >
                Crear actividad
              </div>
            </div>
          )}
      </div>
      {/*  CALENDAR */}
      {toggleCalendar.active && (
        <FullCalendar
          events={state.events}
          options={options}
          editable={true}
          locale="es"
          axisFormat="h:mm tt - h:45 tt"
          timeFormat={{
            agenda: "h:mm{ - h:mm}",
          }}
          buttonText={{
            today: "Hoy",
            month: "mes",
            week: "semana",
            day: "dia",
            list: "lista",
          }}
          allDayText="todo el día"
          allDayDefault={false}
          header={{
            left: "prev,next today myCustomButton",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          displayEventTime={true}
          eventClick={onEventClick}
          eventColor="#df8c37"
          eventTextColor="#fff"
          defaultView="dayGridMonth"
          plugins={[dayGridPlugin, timeGridPlugin]}
        />
      )}

      {/* END CALENDAR */}
      {!toggleCalendar.active && (
        <table className="table txt-center table-hover">
          <thead className="thead-activities tipo-title">
            <tr>
              <th></th>
              <th scope="col">REQUISITO/ACTIVIDAD</th>
              <th
                className="cursor-action"
                scope="col"
                onClick={() => {
                  filterInitDate();
                  setState({ ...state, toggle: { init: !state.toggle.init } });
                }}
              >
                FECHA Y HORA EJECUCIÓN{" "}
                <img
                  src={btn_desplegar}
                  className={`${
                    state.toggle.init ? "rotate-180" : ""
                  } btn-desplegar`}
                />
              </th>
              <th
                className="cursor-action"
                scope="col"
                onClick={() => {
                  filterEndDate();
                  setState({ ...state, toggle: { end: !state.toggle.end } });
                }}
              >
                FECHA Y HORA FINALIZACIÓN{" "}
                <img
                  src={btn_desplegar}
                  className={`${
                    state.toggle.end ? "rotate-180" : ""
                  } btn-desplegar`}
                />
              </th>
              <th scope="col">EVALUADOR</th>
              <th scope="col">PARTICIPANTES</th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody>
            {stateActivity &&
              stateActivity.map((activity) => (
                <tr key={activity.id}>
                  <td>
                    <div
                      title={activity.is_evaluable ? "evaluación" : "acción"}
                      className={`background-simple icon-activity-type ${
                        activity.is_evaluable
                          ? "icon-activity-evaluable"
                          : "icon-activity-not-evaluable"
                      }`}
                    ></div>
                  </td>
                  <td
                    title={`${activity.name}`}
                    className="cursor-action tipo-description"
                  >
                    {shortText(activity.name, 39)}
                  </td>
                  <td className="tipo-description">{activity.init_date}</td>
                  <td className="tipo-description">{activity.end_date}</td>
                  <td className="tipo-description">
                    {activity.audit_participants
                      .filter((p) => p.pivot.is_evaluator)
                      .map((p, index) => {
                        return (
                          <div key={index}>
                            {index == 0 &&
                              p.person.first_name + " " + p.person.last_name}
                            {index != 0 &&
                              " | " +
                                p.person.first_name +
                                " " +
                                p.person.last_name}
                          </div>
                        );
                      })}
                  </td>
                  <td className="tipo-description">
                    {activity.audit_participants.filter(
                      (p) => p.pivot.is_evaluator == false
                    ).length > 0 && (
                      <>
                        <span>
                          {
                            activity.audit_participants.filter(
                              (p) => p.pivot.is_evaluator == false
                            )[0].person.first_name
                          }{" "}
                          {
                            activity.audit_participants.filter(
                              (p) => p.pivot.is_evaluator == false
                            )[0].person.last_name
                          }
                        </span>
                        {activity.audit_participants.filter(
                          (p) => p.pivot.is_evaluator == false
                        ).length > 1 && (
                          <span
                            title={`${getParticipants(
                              activity.audit_participants
                            )}`}
                            className="cursor-action"
                          >
                            {" "}
                            y{" "}
                            {activity.audit_participants.filter(
                              (p) => p.pivot.is_evaluator == false
                            ).length - 1}{" "}
                            más{" "}
                          </span>
                        )}
                      </>
                    )}
                  </td>

                  <td className="btn-action-program flex">
                    <img
                      className="cursor-action"
                      onClick={() => {
                        setState({
                          ...state,
                          toggle_modal2: true,
                          activity_id: activity.id,
                        });
                      }}
                      title="Ver actividad"
                      src={btn_show}
                    />
                    &nbsp;
                    {canOpenCloseActivity(activity) &&
                      activity.is_closed == false && (
                        <FontAwesomeIcon
                          title="cerrar actividad"
                          onClick={() => checkCloseActivity(true, [activity])}
                          className="cursor-action"
                          icon={faLockOpen}
                        />
                      )}
                    &nbsp;
                    {canOpenCloseActivity(activity) &&
                      activity.is_closed == true && (
                        <FontAwesomeIcon
                          title="abrir actividad"
                          onClick={() => closeActivities(false, [activity.id])}
                          className="cursor-action"
                          icon={faLock}
                        />
                      )}
                    &nbsp;
                    {(audit.status_id == STATUS_PLANNED ||
                      audit.status_id == STATUS_CREATED ||
                      audit.status_id == STATUS_INPROGRESS ||
                      audit.status_id == STATUS_APPROVED) &&
                      (audit.lider_person_id == user.id ||
                        audit.responsable_person_id == user.id) &&
                      !activity.is_closed && (
                        <img
                          className="cursor-action"
                          onClick={() => {
                            setState({
                              ...state,
                              toggle_modal: true,
                              activity_id: activity.id,
                            });
                          }}
                          title="Editar actividad"
                          src={btn_edit}
                        />
                      )}
                    &nbsp;
                    {(audit.status_id == STATUS_PLANNED ||
                      audit.status_id == STATUS_CREATED) &&
                      audit.lider_person_id == user.id && (
                        <img
                          className="cursor-action"
                          onClick={() => {
                            checkDelete(
                              "¿Realmente deseas eliminar esta Actividad?",
                              `deleteActivity(${activity.id})`
                            );
                          }}
                          title="Eliminar actividad"
                          src={btn_delete}
                        />
                      )}
                    &nbsp;
                    {
                      /* validateExecActivity(activity) &&
                      audit.status_id == STATUS_INPROGRESS && */
                      (audit.status_id == STATUS_INPROGRESS ||
                        audit.status_id == STATUS_APPROVED) &&
                        validateExecActivity(activity) &&
                        activity.is_evaluable &&
                        !activity.is_closed && (
                          <img
                            className="cursor-action icon-exec-evaluation-desktop"
                            onClick={() => {
                              history.push(
                                `../../app/audit/evaluation/${activity.evaluations[0].id}`
                              );
                            }}
                            title="Ejecutar actividad"
                            src={btn_exec}
                          />
                        )
                    }
                    &nbsp;
                    {isAudited(user.id, activity) &&
                      audit.status_id == STATUS_INPROGRESS &&
                      !activity.is_closed && (
                        <img
                          className="cursor-action icon-exec-evaluation-desktop"
                          onClick={() => {
                            history.push(
                              `../../app/audit/evaluation/${activity.evaluations[0].id}`
                            );
                          }}
                          title="Cargar evidencias"
                          src={btn_evidence}
                        />
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Activities;
