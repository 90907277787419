import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { AlertContext } from "../../contexts/alerts/AlertContext";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const ROLE_PROFILE_COURSE = "profile_course";

let initialState = {
  courses: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
};

export const CourseIndexContext = React.createContext([]);

export const CourseIndexContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, showErrors, alertError }] = useContext(AlertContext);
  const [courses, setcourses] = useState(initialState.courses);
  let [errors, setErrors] = useState(initialState.errors);
  useEffect(() => {
    if (courses.status === STATUS_NOT_LOADED) {
      client()
        .get(`course/getCoursesActive`, {
          params: {
            filter: [],
          },
        })
        .then(({ data }) => {
          if (data) {
            setcourses({
              ...courses,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los cursos");
          }
        });
    }
  }, [courses]);
  const changeImage = (event, course_id) => {
    let document = event.target.files[0];
    var formData = new FormData();
    formData.append("document", document);
    formData.append("is_private", false);
    formData.append("role", ROLE_PROFILE_COURSE);

    client()
      .post(`course/course/${course_id}/deleteAndAddDocument`, formData)
      .then(({ data }) => {
        setcourses({
          ...courses,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
      });
  };
  const addCourseToCart = (course_id) => {
    client()
      .get(`course/addCourseToCart/${course_id}`)
      .then(({ data }) => {
        if (data) {
          history.push("/app/billing/cart");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al poner el curso en el carrito");
        }
      });
  };
  return (
    <CourseIndexContext.Provider value={[{ courses }, { addCourseToCart, changeImage }]}>
      {children}
    </CourseIndexContext.Provider>
  );
};
