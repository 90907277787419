import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const STATUS_APPROVED = 7;
let initalState = {
  status: STATUS_NOT_LOADED,
  audit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  standard: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  company: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  suscriptionsAudit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filter: {
    init_date: false,
    end_date: false,
    standard_filter: false,
    company_filter: false,
  },
  errors: {
    error: [],
    status: false,
  },
};

export const AuditPlanIndexContext = React.createContext([]);

export const AuditPlanIndexContextProvider = ({ children }) => {
  let [audit, setAudit] = useState(initalState.audit);
  let [standard, setStandard] = useState(initalState.standard);
  let [company, setCompany] = useState(initalState.company);
  let [template, setTemplate] = useState(initalState.template);
  const [suscriptionsAudit, setsuscriptionsAudit] = useState(initalState.suscriptionsAudit);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initalState.errors);
  const [effectiveSelected, seteffectiveSelected] = useState(null);
  const [modalAuditAudit, setmodalAuditAudit] = useState(false);
  let [filter, setFilter] = useState(initalState.filter);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let history = useHistory();

  // componteDidMointed & componentDidUpdated
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (audit.status === STATUS_NOT_LOADED) {
      setAudit({ ...audit, status: STATUS_LOADING });
      client()
        .get("audit/getAudits")

        .then(({ data }) => {
          if (data) {
            setAudit({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [audit]);
  useEffect(() => {
    if (template.status === STATUS_NOT_LOADED) {
      client()
        .get(`security/person/${user.id}/templates`)
        .then(({ data }) => {
          if (data) {
            setTemplate({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantillas");
          }
        });
    }
  }, []);
  useEffect(() => {
    if (standard.status === STATUS_NOT_LOADED) {
      setStandard({ ...standard, status: STATUS_LOADING });
      client()
        .get("audit/standard", {
          params: {
            filters: {
              status_id: STATUS_APPROVED,
            },
          },
        })

        .then(({ data }) => {
          if (data) {
            setStandard({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [standard]);
  useEffect(() => {
    if (suscriptionsAudit.status === STATUS_NOT_LOADED) {
      setsuscriptionsAudit({ ...suscriptionsAudit, status: STATUS_LOADING });
      client()
        .get("audit/subscriptionAudit")

        .then(({ data }) => {
          if (data) {
            setsuscriptionsAudit({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [suscriptionsAudit]);
  useEffect(() => {
    if (company.status === STATUS_NOT_LOADED) {
      setCompany({ ...company, status: STATUS_LOADING });
      client()
        .get("general/company", {
          params: {
            with: ["contact"],
          },
        })

        .then(({ data }) => {
          if (data) {
            setCompany({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        });
    }
  }, [company]);

  const createAudit = ({ template_id, title, standard_id, init_date, end_date, company_id }) => {
    client()
      .post("audit/audit", {
        title: title,
        standard_id: standard_id.id,
        init_date: moment(init_date).format("YYYY-MM-DD HH:mm"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm"),
        lider_person_id: user.id,
        template_id: template_id.id,
        company_id: company_id.id,
        responsable_person_id: company_id.contact.id,
        effective_id: effectiveSelected,
      })
      .then(({ data }) => {
        if (data) {
          setmodalAuditAudit(false);
          alertSuccess("Auditoría creada satisfactoriamente");
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
          setsuscriptionsAudit({ ...suscriptionsAudit, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/library/topic/billing/suscription/audit");
          //history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la auditoria");
        }
      });
  };

  const updateAudit = ({ title, standard_id, init_date, end_date, company_id }, id) => {
    client()
      .post(`audit/audit/${id}`, {
        title: title,
        standard_id: standard_id.id,
        init_date: moment(init_date).format("YYYY-MM-DD HH:mm"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm"),
        company_id: company_id.id,
      })
      .then(() => {
        setmodalAuditAudit(false);
        alertSuccess("Auditoria actualizada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la auditoria");
        }
      });
  };

  const deleteAudit = (id) => {
    client()
      .delete(`audit/audit/${id}`)
      .then(({ data }) => {
        alertSuccess("Auditoria eliminado satisfactoriamente");
        let el = audit.elements.filter((e) => e.id !== id);
        setAudit({ ...audit, elements: el, status: STATUS_NOT_LOADED });
      });
  };

  const filterInit = () => {
    let filterInit = audit.elements.sort(function (a, b) {
      var c = new Date(a.init_date);
      var d = new Date(b.init_date);
      if (filter.init_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setAudit({ ...audit, elements: filterInit });
    setFilter({ ...filter, init_date: !filter.init_date });
  };

  const filterEnd = () => {
    let filterEnd = audit.elements.sort(function (a, b) {
      var c = new Date(a.end_date);
      var d = new Date(b.end_date);
      if (filter.end_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setAudit({ ...audit, elements: filterEnd });
    setFilter({ ...filter, end_date: !filter.end_date });
  };

  const filterStandard = () => {
    let filterStandard = universalSortString(
      filter.standard_filter,
      audit.elements,
      "standard.short_name"
    );

    setAudit({ ...audit, elements: filterStandard });
    setFilter({ ...filter, standard_filter: !filter.standard_filter });
  };

  const filterCompany = () => {
    let filterCompany = universalSortString(filter.company_filter, audit.elements, "company_name");

    setAudit({ ...audit, elements: filterCompany });
    setFilter({ ...filter, company_filter: !filter.company_filter });
  };

  const universalSortString = (toggle, sortArray, sortAttributeString) => {
    let filter = sortArray.sort(function (a, b) {
      let compare = toggle ? ">" : "<";
      let condition = eval(
        "a." + sortAttributeString + " " + compare + " b." + sortAttributeString
      );
      return condition ? -1 : 1;
    });
    return filter;
  };

  return (
    <AuditPlanIndexContext.Provider
      value={[
        { audit, standard, company, template, suscriptionsAudit, effectiveSelected,modalAuditAudit },
        {
          createAudit,
          updateAudit,
          deleteAudit,
          filterInit,
          filterEnd,
          filterStandard,
          filterCompany,
          setCompany,
          seteffectiveSelected,
          setmodalAuditAudit
        },
      ]}
    >
      {children}
    </AuditPlanIndexContext.Provider>
  );
};
