import React, { useEffect, useContext } from "react";
import Header from "../app/layout/Header";
import "./PaymentSuccess.css";
import { Link, useHistory } from "react-router-dom";
import {
  BillingContext,
  SERVICE_LIBRARY,
} from "../../contexts/billing/BillingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
const PaymentSuccess = ({ computedMatch }) => {
  const APPROVED = 7;
  const REJECTED = 8;
  const PENDING = 6;
  const MODULE_LIBRARY = "library";
  const MODULE_AUDIT = "audit";
  const MODULE_CAPACITY = "capacity";
  const MODULE_ACTION_PLAN = "plan";
  const MODULE_CONSULTING = "consulting";
  const AUDIT_COMPANY = "audit_company";
  const SERVICE_AUDIT = 1;
  const SERVICE_AUDITOR = 2;
  const SERVICE_CAPACITY = 3;
  const SERVICE_PLAN = 5;

  let { params, url, path } = computedMatch;
  let { statusPayu, signature } = params;
  let history = useHistory();
  const [{ bill }, { searchTransactionId }] = useContext(BillingContext);
  useEffect(() => {
    if (params) {
      searchTransactionId(params);
    }
  }, [params]);
  const printBill = () => {
    window.open(
      process.env.REACT_APP_API_HOST + "billing/showBill/" + bill.bill_number,
      "_blank"
    );
  };
  const stausPay = () => {
    let status = {
      class: "payment-pending-icon",
      title: "Proceso pendiente",
      description: "Esperando respuesta",
    };
    if (bill) {
      switch (statusPayu) {
        case "4":
          status.class = "payment-succes-icon";
          status.title = "Proceso Exitoso";
          status.description = "¡Gracias por tu compra!";

          break;
        case "6":
        case "5":
          status.class = "payment-error-icon";
          status.title = "Proceso rechazado";
          status.description = "¡Revisa tu método de pago!";
          break;
        case "7":
          status.title = "Proceso pendiente";
          status.description = "Esperando respuesta del banco";

          break;
      }
    }
    return status;
  };
  return (
    <div className="flex">
      <Header></Header>
      <div className=" shadow-tarjet content-tarjet-status-payment margin-auto">
        <div className="flex flex-wrap">
          <div className="w-100">
            <div
              className={`background-simple margin-auto icon-content-payment ${
                stausPay().class
              }`}
            ></div>
          </div>

          <div className="w-100 txt-center ">
            <p className="text-1-process-payment tipo-title">
              {stausPay().title}
            </p>
          </div>
          <div className="w-100 txt-center ">
            <p className="text-2-process-payment tipo-description">
              {stausPay().description}
            </p>
          </div>

          <div className="w-100 txt-center ">
            <p className="text-3-process-payment tipo-title">
              {bill && bill.status_id === APPROVED
                ? `ID de compra: ${bill && bill.bill_number}`
                : ""}
            </p>
          </div>

          <div className="w-100">
            {bill && bill.status_id === APPROVED && (
              <div
                onClick={() => printBill()}
                className="cursor-action txt-center tipo-title margin-auto btn-print-bill white bg-orange"
              >
                Imprimir comprobante de pago
              </div>
            )}
          </div>
          <div className="w-100">
            <div className="text-4-process-payment txt-center tipo-description ">
              {bill.id && (
                <>
                  {bill.packages.length > 0 && (
                    /*   <div className="cursor-action" onClick={()=>history.push(`../../../app/library/topics_subscribed/module/library`)}> Ver productos</div> */
                    <Link
                      to={`/app/library/topics_subscribed/module/${
                        bill.packages[0].module == SERVICE_AUDIT
                          ? MODULE_AUDIT
                          : bill.packages[0].module == SERVICE_PLAN
                          ? MODULE_ACTION_PLAN
                          : bill.packages[0].module == SERVICE_CAPACITY
                          ? MODULE_CAPACITY
                          : "library"
                      }`}
                    >
                      Ver productos
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
