import React from "react";
const TooltipModule = ({ services, setModule,module }) => {
  return (
    <div className="tooltip-select-payment tooltip-subscribe-module flex flex-column">
    <div>
      <div
        className={`icon-module-subscribe background-simple library-icon-subscription`}
      ></div>
    </div>
    <div>
      <p className="txt-center">ELIGE MÓDULO Y SUSCRIPCIÓN</p>
      
    </div>
    <div className="margin-auto txt-center txt-upper container-select-modules color-gray">
    {services.elements.filter(s=>!s.is_product).map((service,index) => {
              return (
                <div
                key={index}
                  onClick={() => setModule(service.id)}
                  className={`${service.id===module? "module-active-tooltip" : ""}`}
                >
                  
                  {service.name}
                </div>
              );
            })}
     
    </div>
  </div>
  );
};

export default TooltipModule;
