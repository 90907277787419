import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select, { components } from "react-select";
import ImageUploader from "react-images-upload";
import { useHistory } from "react-router-dom";
import img_plan from "../../../../img_zeus/icons_audit/generate.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";

import Toggle from "react-toggle";
import Pagination from "react-js-pagination";
import DateTimePicker from "react-datetime-picker";
import {
  CompanyFormContext,
  STATUS_LOADED,
  STATUS_ERASER,
  STATUS_CREATED,
  STATUS_IN_PROGRESS,
  STATUS_EXECUTED,
  STATUS_REJECTED,
  STATUS_NOT_LOADED,
} from "../../../../contexts/general/CompanyFormContext";
import btn_created from "../../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import deleteIcon from "../../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_asigned from "../../../../img_zeus/icons_audit/asigando.png";
import { analytics } from "firebase";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  sort: {
    updated_at: false,
    id: false,
    "responsable.first_name": true,
    "evaluation.status_id": true,
    affair: true,
    type: true,
    detail: true,
  },
};
const Index = () => {
  const [
    {
      company,
      evaluations,
      newAnalize,
      evaluationsMonths,
      metaRowPqrs,
      statusAnalyze,
      rowsPqrs,
    },
    {
      setnewAnalize,
      generateAnlyze,
      rejectedPqrs,
      exportListPqrs,
      setmetaRowPqrs,
    },
  ] = useContext(CompanyFormContext);
  let history = useHistory();
  const [sort, setsort] = useState(initialState.sort);
  const searchField = (evaluation, field) => {
    let items = evaluation.items.filter((i) => i.description == field);
    if (items.length > 0) {
      if (items[0].results.length > 0) {
        if (items[0].results[0].value === null) {
          return items[0].results[0].pivot.response;
        } else {
          return items[0].results[0].value;
        }
      }
    }
  };
  const setFieldMeta = (value, field) => {
    setmetaRowPqrs({ ...metaRowPqrs, [field]: value });
  };
  const shortText = (text, range) => {
    if (text) {
      return text.substring(0, range) + "...";
    }
  };
  const sortDate = (field, asc = true, month) => {
    let sortDates = evaluationsMonths[month].evaluations.sort(function (a, b) {
      var c = new Date(a[field]);
      var d = new Date(b[field]);
      if (asc) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setsort({ ...sort, [field]: asc });
  };
  const sortEvaluationItem = (field, asc = true, month) => {
    let sortDates = evaluationsMonths[month].evaluations.sort(function (a, b) {
      let itemc = a.items.filter((i) => i.description == field);
      let itemd = b.items.filter((i) => i.description == field);
      var c = "zz";
      var d = "zz";
      if (itemc.length > 0) {
        if (itemc[0].results.length > 0) {
          if (field == "type") {
            c = itemc[0].results[0].value;
          } else {
            c = itemc[0].results[0].pivot.response;
            console.log(c);
          }
        }
      }
      if (itemd.length > 0) {
        if (itemd[0].results.length > 0) {
          if (field == "type") {
            d = itemd[0].results[0].value;
          } else {
            d = itemd[0].results[0].pivot.response;
          }
        }
      }
      if (asc) {
        return c.toLowerCase().localeCompare(d.toLowerCase());
      } else {
        return d.toLowerCase().localeCompare(c.toLowerCase());
      }
    });
    setsort({ ...sort, [field]: asc });
  };
  const statusAnalyzeImg = (auditStatus, isPlan) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            {isPlan ? (
              <img
                src={btn_asigned}
                title="Asignada"
                className="img-status-analyze"
              />
            ) : (
              <img
                src={btn_created}
                title="Creada"
                className="img-status-analyze"
              />
            )}
          </>
        );
        break;

      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizado"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 8:
        return (
          <>
            <img
              src={deleteIcon}
              title="Rechazado"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      default:
        return (
          <img
            src={btn_created}
            title="Creado"
            className="img-status-analyze"
          />
        );
    }
  };
  const getAdvance = (actions) => {
    let closed = actions.filter((a) => a.is_open === false);
    let percentage = 0;
    if (actions.length > 0) {
      percentage = (closed.length * 100) / actions.length;
    }
    return (
      <div className="container-percentage-company-analyze">
        <div style={{ width: `${percentage}%` }}></div>
        <div>{percentage.toFixed(2)}%</div>
      </div>
    );
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowPqrs.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowPqrs({ ...metaRowPqrs, filtersLike: meta });
  };
  const getCountAnalyze = (status_id, month, inTwo) => {
    let text = "Creados";
    let src = btn_created;
    switch (status_id) {
      case STATUS_IN_PROGRESS:
        text = "En progreso";
        src = btn_inprogress;
        break;
      case STATUS_EXECUTED:
        text = "Finalizado";
        src = btn_executed;
        break;
      case STATUS_REJECTED:
        text = "Rechazado";
        src = deleteIcon;
        break;
    }
    let count = evaluationsMonths[month].evaluations.filter(
      (eva) =>
        eva.analyzes.length > 0 && eva.analyzes[0].status_id === status_id
    ).length;
    if (inTwo) {
      count = evaluationsMonths[month].evaluations.filter(
        (eva) => eva.status_id === status_id
      ).length;
    }
    return (
      <div>
        <img className="img-status-analyze" src={src} alt="" /> {text}: {count}
      </div>
    );
  };
  return (
    <div>
      {newAnalize.evaluation_id && (
        <div className="back-document">
          <div className="modal-staff">
            <div className="header-modal-staff">
              <div
                className="salir-company"
                onClick={() => {
                  setnewAnalize({ ...newAnalize, evaluation_id: null });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                {newAnalize.is_new ? "Generar analisis" : "Rechazar pqrs"}
              </h3>
            </div>
            <div className="w-100">
              <div className="search-person-staff tipo-description">
                <div className="col-md-12 ">
                  {newAnalize.is_new && (
                    <div className="flex">
                      <div className="col-md-6">
                        <label className="control-label">Proceso</label>
                        <Select
                          onChange={(e) =>
                            setnewAnalize({
                              ...newAnalize,
                              process_id: e ? e.id : null,
                            })
                          }
                          className="tipo-description f"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => option}
                          options={company.company.process}
                          isClearable={false}
                          isMulti={false}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Responsable</label>
                        <Select
                          onChange={(e) =>
                            setnewAnalize({
                              ...newAnalize,
                              responsable_id: e ? e.id : null,
                            })
                          }
                          className="tipo-description f"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.first_name}`}
                          getOptionValue={(option) => option}
                          options={company.company.partners}
                          isClearable={false}
                          isMulti={false}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-group col-md-12">
                    <label className="control-label">Notas</label>
                    <textarea
                      onChange={(e) =>
                        setnewAnalize({ ...newAnalize, note: e.target.value })
                      }
                      value={newAnalize.note}
                      className="form-control"
                    ></textarea>
                  </div>
                  {newAnalize.is_new && (
                    <div className="form-group col-md-12">
                      <label className="control-label">
                        El responsable puede decidir cual es la causa raiz del
                        problema?
                      </label>
                      <br />
                      <Toggle
                        checked={newAnalize.authorized}
                        name="authorized"
                        className="margin-auto mobile-hidden"
                        value="yes"
                        title={
                          "El responsable puede decidir cual es la causa raiz del problema?"
                        }
                        aria-label={"authorized"}
                        onChange={(e) => {
                          setnewAnalize({
                            ...newAnalize,
                            authorized: !newAnalize.authorized,
                          });
                        }}
                      />
                    </div>
                  )}

                  <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    buttonText="Seleccione imagenes"
                    className="image-pqrs"
                    imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                    withIcon={false}
                    onChange={(e) => setnewAnalize({ ...newAnalize, files: e })}
                    fileSizeError="Excede el peso permitido"
                    fileTypeError="Extensión no permitida"
                    label="Peso máximo 5mb,acepta:jpg,gif,png"
                  />
                  <div className="btn-add-staff">
                    <input
                      value={newAnalize.is_new ? "Generar" : "Rechazar"}
                      type="button"
                      onClick={() =>
                        newAnalize.is_new
                          ? generateAnlyze()
                          : rejectedPqrs({ status_id: STATUS_REJECTED })
                      }
                      className="btn tipo-boton bg-orange btn-search-staff"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-column">
        <div className="flex">
          <tr>
            <th>
              {" "}
              <div>
                <DateTimePicker
                  className="form-control tipo-description"
                  format="y-MM-dd"
                  onChange={(e) =>
                    setFieldFilter(
                      "updated_at",
                      e?moment(e).format("YYYY-MM-DD"):e,
                      false
                    )
                  }
                  value={
                    metaRowPqrs.filtersLike["updated_at"]
                      ? new Date(moment(metaRowPqrs.filtersLike["updated_at"]))
                      : ""
                  }
                />
              </div>
            </th>
            <th>
              <div>
                <input
                  className="input-filter-table"
                  placeholder="Responsable"
                  onChange={(e) =>
                    setFieldFilter("analyze.responsable:first_name", e)
                  }
                  value={
                    metaRowPqrs.filtersLike["analyze.responsable:first_name"]
                  }
                  type="text"
                />
              </div>
            </th>
            <th>
              {}
              <Select
                value={statusAnalyze.elements.filter(
                  (s) => s.id === metaRowPqrs.filtersLike["analyze:status_id"]
                )}
                onChange={(e) =>
                  setFieldFilter("analyze:status_id", e ? e.id : "", false)
                }
                className="tipo-description select-pqrs-filter-company"
                placeholder={"Estado del plan"}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.id}
                options={statusAnalyze.elements}
                isClearable={true}
              />
            </th>
          </tr>
          <div className="flex justify-end">
            <div
              onClick={() => exportListPqrs()}
              className="btn-orange-default"
            >
              Exportar a excell
            </div>
          </div>
        </div>

        {Object.keys(evaluationsMonths).map((month) => {
          return (
            <div key={month} className="panel-detail-analyze">
              <span className="txt-upper ">
                {moment(month).format("MMMM YYYY")}
              </span>
              <br />
              <table className="table table-hover">
                <thead className="thead-company">
                  <tr className="txt-center">
                    <th scope="col">
                      ID{" "}
                      <FontAwesomeIcon
                        onClick={() => sortDate("id", !sort.id, month)}
                        className="cursor-action"
                        icon={sort.id ? faChevronUp : faChevronDown}
                      />
                    </th>
                    <th scope="col">ID Plan</th>
                    <th scope="col">
                      Asunto
                      <FontAwesomeIcon
                        onClick={() =>
                          sortEvaluationItem("affair", !sort.affair, month)
                        }
                        className="cursor-action"
                        icon={sort.affair ? faChevronUp : faChevronDown}
                      />
                    </th>
                    <th scope="col">Estado PQRS </th>
                    <th scope="col">
                      Descripción{" "}
                      <FontAwesomeIcon
                        onClick={() =>
                          sortEvaluationItem("detail", !sort.detail, month)
                        }
                        className="cursor-action"
                        icon={sort.detail ? faChevronUp : faChevronDown}
                      />
                    </th>
                    <th scope="col">
                      Tipo
                      <FontAwesomeIcon
                        onClick={() =>
                          sortEvaluationItem("type", !sort.type, month)
                        }
                        className="cursor-action"
                        icon={sort.type ? faChevronUp : faChevronDown}
                      />
                    </th>
                    <th scope="col">
                      Fecha registro{" "}
                      <FontAwesomeIcon
                        onClick={() =>
                          sortDate("updated_at", !sort.updated_at, month)
                        }
                        className="cursor-action"
                        icon={sort.updated_at ? faChevronUp : faChevronDown}
                      />
                    </th>

                    <th scope="col">Estado Plan</th>
                    <th scope="col">Avance</th>
                    <th scope="col">Responsable</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="bg-white txt-center tipo-description">
                  {evaluationsMonths[month].evaluations.map((eva) => {
                    return (
                      <tr key={eva.id}>
                        <td>
                          {" "}
                          <div
                            className="cursor-action link-audit-index"
                            onClick={() => {
                              history.push(
                                `/app/inspection/evaluation/${eva.id}`
                              );
                            }}
                          >
                            {eva.number}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div
                            className="cursor-action link-audit-index"
                            onClick={() => {
                              history.push(
                                `/app/audit/analyze/${
                                  eva.analyzes.length > 0 && eva.analyzes[0].id
                                }`
                              );
                            }}
                          >
                            {eva.analyzes.length > 0 && eva.analyzes[0].number}
                          </div>
                        </td>

                        <td>{searchField(eva, "affair")}</td>
                        <td>{statusAnalyzeImg(eva.status_id)}</td>
                        <td title={searchField(eva, "detail")}>
                          {shortText(searchField(eva, "detail"), 10)}
                        </td>
                        <td>{searchField(eva, "type")}</td>
                        <td>{eva.updated_at}</td>

                        <td>
                          {eva.analyzes.length > 0 &&
                            statusAnalyzeImg(eva.analyzes[0].status_id, true)}
                          {eva.analyzes.length === 0 &&
                            eva.status_id !== STATUS_REJECTED && (
                              <div
                                title="generar Plan de acción"
                                onClick={() =>
                                  setnewAnalize({
                                    ...newAnalize,
                                    evaluation_id: eva.id,
                                    is_new: true,
                                  })
                                }
                                className="circle-generate-analyze-company-pqrs margin-auto"
                              >
                                <img src={img_plan} alt="" />
                              </div>
                            )}
                        </td>
                        <td>
                          {eva.analyzes.length > 0 &&
                            eva.analyzes[0].actions.length > 0 &&
                            getAdvance(eva.analyzes[0].actions)}
                        </td>
                        <td>
                          {eva.analyzes.length > 0 &&
                            eva.analyzes[0].responsable &&
                            eva.analyzes[0].responsable.first_name +
                              " " +
                              eva.analyzes[0].responsable.last_name}
                        </td>
                        <td>
                          {eva.status_id !== STATUS_REJECTED &&
                            eva.analyzes.length == 0 && (
                              <img
                                onClick={() => {
                                  setnewAnalize({
                                    ...newAnalize,
                                    evaluation_id: eva.id,
                                    is_new: false,
                                  });
                                }}
                                title="rechazar"
                                className="cursor-action img-status-analyze"
                                src={deleteIcon}
                                alt=""
                              />
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="flex justify-betwen footer-pqrs-company-list">
                <div>
                  Cantidad: {evaluationsMonths[month].evaluations.length}
                </div>
                <div className="flex">
                  <div
                    title="sin plan"
                    className=" circle-generate-analyze-company-pqrs margin-auto"
                  >
                    <img src={img_plan} alt="" />
                  </div>{" "}
                  Sin plan:{" "}
                  {
                    evaluationsMonths[month].evaluations.filter(
                      (eva) => eva.analyzes.length === 0
                    ).length
                  }
                </div>
                <div>{getCountAnalyze(STATUS_CREATED, month, false)}</div>
                <div>{getCountAnalyze(STATUS_IN_PROGRESS, month, false)}</div>
                <div>{getCountAnalyze(STATUS_EXECUTED, month, false)}</div>
                <div>{getCountAnalyze(STATUS_REJECTED, month, true)}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ margin: "10PX" }} className="justify-end flex">
        <Pagination
          activePage={metaRowPqrs.page}
          itemsCountPerPage={metaRowPqrs.limit}
          totalItemsCount={rowsPqrs}
          pageRangeDisplayed={5}
          itemClass="page-item item-page-zeus"
          linkClass="page-link page-link-zeus"
          onChange={(e) => setFieldMeta(e, "page")}
        />
      </div>
    </div>
  );
};

export default Index;
