import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Item from "../../audit/item/Form";
import Swal from "sweetalert2";
import "../standard/Form.css";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";

import {
  AuditContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import { StandardFormContext } from "../../../contexts/audit/StandardFormContext";

const initialState = {
  standard: {
    description: "",
    short_name: "",
    long_name: "",
    collaborators: [],
  },
};

const FormStandard = ({ computedMatch: { params, url } }) => {
  const [{ standard, userSearch }, actions] = useContext(StandardFormContext);
  const [{ user }, { isCollaboratorStandard }] = useContext(SecurityContext);
  const [editStandard, setEditStandard] = useState(initialState.standard);
  const [toggleModal, settoggleModal] = useState(false);
  const [searchEmail, setsearchEmail] = useState("");
  let history = useHistory();
  let { standard_id } = params;

  useEffect(() => {
    if (
      parseInt(standard_id) !== standard.standard_id &&
      standard.status === STATUS_NOT_LOADED
    ) {
      actions.setStandard({ ...standard, standard_id: standard_id });
    }
  }, [standard_id]);
  useEffect(() => {
    if (standard.status === STATUS_LOADED) {
      if (!isCollaboratorStandard(standard_id)) {
        history.push("../../unauthorized");
      }
      setEditStandard(standard.element);
    }
  }, [standard]);
  const checkDelete = (user_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar a este colaborador?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        actions.removeCollaborator(user_id, editStandard.id);
      } else {
      }
    });
  };
  const setFieldStandard = (field) => (e) => {
    setEditStandard({ ...editStandard, [field]: e.target.value });
  };
  const updateStandard = () => (e) => {
    if (e.key === "Enter") {
    actions.updateStandard(editStandard);
    }
  };

  return (
    <div>
      {toggleModal && (
        <div className="back-document ">
          <div className=" flex-wrap container-collaborators-standard">
            <div className="flex header-container-collaborators">
              <div
                onClick={() => settoggleModal(!toggleModal)}
                className="btn-close-modal-collaborators cursor-action"
              ></div>
              <div className="w-100">
                <p className="txt-center">Colaboradores</p>
              </div>
            </div>
            <div className="flex content-collaborators shadow-tarjet">
              <div className="container-intro-collaborators">
                <div className="flex">
                  <input
                    className="tipo-cursiva form-control"
                    value={searchEmail}
                    onChange={(e) => setsearchEmail(e.target.value)}
                    type="email"
                    placeholder="email"
                  />
                  <input
                    value="Buscar"
                    onClick={() => actions.getUser(searchEmail)}
                    type="button"
                  />
                </div>
                {userSearch.id && (
                  <div className="flex user-find-collaborator">
                    <div className="flex-1">
                      {userSearch.first_name} {userSearch.last_name}
                    </div>
                    <div className="flex justify-end">
                      <input
                        value="Añadir"
                        className="bg-orange white no-border "
                        onClick={() =>
                          actions.addCollaborator(
                            userSearch.id,
                            editStandard.id
                          )
                        }
                        type="button"
                      />
                    </div>
                  </div>
                )}
                {editStandard.collaborators.length > 0 && (
                  <table className="table table-collaborators-standard">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {editStandard.collaborators.map((person, index) => {
                        return (
                          <tr key={index}>
                            <td>{person.first_name}</td>
                            <td>{person.last_name}</td>
                            <td>{person.email}</td>
                            <td>
                              {" "}
                              <img
                                className="cursor-action btn-delete-collaborators"
                                onClick={() => {
                                  checkDelete(person.id);
                                }}
                                title="Eliminar colaborador"
                                src={btn_delete}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <nav
        aria-label="breadcrumb"
        className="flex-1 breadcrumb-show-standard cursor-action"
      >
        <ol className="breadcrumb black">
          <li className="breadcrumb-item">
            <Link to="/app/audit/standards">Normas</Link>
          </li>
          <li className="breadcrumb-item orange tipo-description">
            {editStandard.short_name}
          </li>
        </ol>
      </nav>
      <div className=" back-gray-content">
        <div className="flex">
          <h3 className="tipo-title title-standard-form">
            NORMA{" "}
            <input
              type="text"
              onKeyDown={updateStandard()}
              onChange={setFieldStandard("short_name")}
              value={editStandard.short_name}
              className="input-basic-white tipo-description"
            />
          </h3>
          {user.is_admin && (
            <div className="flex flex-1 justify-end">
              <div
                onClick={() => settoggleModal(!toggleModal)}
                className=" cursor-action  background-simple flex btn-add-collaborators"
              >
                <div className="number-collaborators-standard">
                  {editStandard.collaborators.length}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex">
          <div className="flex-wrap margin-control tipo-title">
            <label className="flex tipo-title">Nombre largo</label>
            <textarea
              onKeyDown={updateStandard()}
              onChange={setFieldStandard("long_name")}
              value={editStandard.long_name}
              className="input-basic-white tipo-description"
            />
          </div>
          <div className="flex-wrap flex-1 margin-control tipo-title">
            <label className="flex">Descripción</label>
            <textarea
              onKeyDown={updateStandard()}
              onChange={setFieldStandard("description")}
              className="w-100 input-basic-white tipo-description"
              value={editStandard.description}
            />
          </div>
        </div>

        <Item
          standard_id={editStandard && editStandard.id}
          standard={editStandard}
          items={editStandard && editStandard.auditable_items}
          first={true}
        />
      </div>
    </div>
  );
};

export default FormStandard;
