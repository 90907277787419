import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import imgAudit from "../../../../src/img_zeus/Auditorias.png";
import Toggle from "react-toggle";
import Select, { components } from "react-select";
import cloneDeep from "lodash/cloneDeep";
import TooltipModule from "../../billing/TooltipModule";
import PackageAssing from "./PackageAssing";

import TarjetPackage from "../../billing/TarjetPackage/TarjetPackage";
import "./Package.css";
import {
  AdminIndexContext,
  AUDIT_SERVICE,
  AUDITOR_SERVICE,
  LIBRARY_SERVICE,
  ACTION_PLAN_SERVICE,
  CONSULTING_SERVICE,
  CAPACITY_SERVICE,
  INSPECTION_SERVICE,
  STATUS_PACKAGE,
  MODULE_LIBRARY,
  MODULE_AUDIT,
  MODULE_ACTION_PLAN,
  MODULE_INSPECTION,
  MODULE_CAPACITY,
  MODULE_CONSULTING,
  STATUS_LOADED,
  ROLE_BENEFIT,
} from "../../../contexts/security/AdminIndexContext";
import { locale } from "moment";
const Package = () => {
  const [inNewBenefit, setinNewBenefit] = useState(false);
  const [
    { module, packages, services, packageInEdit, topics, packageAsing },
    {
      setmodule,
      number_format,
      setpackageInEdit,
      deleteDetail,
      deleteBenefit,
      addPackage,
      deletePackage,
      setpackageAsing,
    },
  ] = useContext(AdminIndexContext);
  const newPackage = {
    id: null,
    active: true,
    description: "",
    module: module,
    details: [],
    benefits: [],
    is_free: false,
    is_persona: true,
    is_promotion: false,
    status_id: 13,
    timeMonth: 1,
    value: 0,
    tax: 0,
  };
  useEffect(() => {
    setmodule(LIBRARY_SERVICE);
  }, []);
  const setFieldPackage = (value, field) => {
    setpackageInEdit({ ...packageInEdit, [field]: value });
  };
  const changeFieldDetailSelect = (value, index) => {
    let clonePackage = cloneDeep(packageInEdit);
    clonePackage.details[index].service = value;
    clonePackage.details[index].service_id = value.id;
    setpackageInEdit(clonePackage);
  };
  const changeFieldDetailSelectTopic = (value, index) => {
    let clonePackage = cloneDeep(packageInEdit);
    clonePackage.details[index].product = value;
    clonePackage.details[index].product_id = value.id;
    setpackageInEdit(clonePackage);
  };
  const setFieldPackageDetail = (index, value, field) => {
    let clonePackage = cloneDeep(packageInEdit);

    clonePackage.details[index][field] = value;
    setpackageInEdit(clonePackage);
  };
  const setFieldBenefit = (index, value, field) => {
    let clonePackage = cloneDeep(packageInEdit);

    clonePackage.benefits[index][field] = value;
    setpackageInEdit(clonePackage);
  };
  const checkDeletePackage = (packageDelete) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar a este paquete?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deletePackage(packageDelete.id);
      }
    });
  };
  const addNewDetail = () => {
    let packInEdit = cloneDeep(packageInEdit);
    let newDetail = {
      service_id: module,
      quantity: 1,
      product_id: null,
      service: services.elements.filter((s) => s.id == module)[0],
    };
    packInEdit.details.push(newDetail);
    setpackageInEdit(packInEdit);
  };
  const addNewBefit = () => {
    let packInEdit = cloneDeep(packageInEdit);

    let newNote = {
      note: "",
      role: ROLE_BENEFIT,
    };
    packInEdit.benefits.push(newNote);
    setinNewBenefit(true);
    setpackageInEdit(packInEdit);
  };
  var formatter = new Intl.NumberFormat(locale,{ style: 'currency', currency: 'COP' });
  useEffect(() => {
    if (
      packageInEdit &&
      packageInEdit.benefits.length > 0 &&
      inNewBenefit === true
    ) {
      let numerFinish = parseInt(packageInEdit.benefits.length) - 1;
      let newInput = document.getElementById(
        "benefit-edit-package-" + numerFinish
      );
      newInput.focus();
      setinNewBenefit(false);
    }
  }, [packageInEdit && packageInEdit.benefits, inNewBenefit]);
  return (
    <div>
      {packageAsing && <PackageAssing />}
      {packageInEdit && (
        <div className="back-document">
          <div className="modal-program">
            <div className="header-modal-program">
              <div
                onClick={() => setpackageInEdit(null)}
                className="salir-program"
              ></div>
              <h3 className="title-form-topic tipo-description">
                {packageInEdit.id ? "Editar" : "Crear"} paquete
              </h3>
            </div>

            <div>
              <form className="form-program">
                <div className="container-head-package-edit">
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Descripcion:</label>
                    <input
                      onChange={(e) =>
                        setFieldPackage(e.target.value, "description")
                      }
                      name="name"
                      type="text"
                      className="form-control tipo-description"
                      placeholder="Descripcion del paquete"
                      value={packageInEdit.description}
                    ></input>
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Meses:</label>
                    <input
                      onChange={(e) =>
                        setFieldPackage(e.target.value, "timeMonth")
                      }
                      name="timeMonth"
                      type="number"
                      min={1}
                      className="form-control tipo-description"
                      placeholder="Numero de meses"
                      value={packageInEdit.timeMonth}
                    ></input>
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Precio:</label>
                    <input
                      onChange={(e) => setFieldPackage(e.target.value, "value")}
                      name="value"
                      type="number"
                      className="form-control tipo-description"
                      placeholder="Precio"
                      value={packageInEdit.value}
                    ></input>
                   
                    <small className="tipo-cursiva">{formatter.format(packageInEdit.value)}</small>
                 
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">% Impuesto:</label>
                    <input
                      onChange={(e) => setFieldPackage(e.target.value, "tax")}
                      name="name"
                      type="number"
                      min={1}
                      className="form-control tipo-description"
                      placeholder="Impuesto"
                      value={packageInEdit.tax}
                    ></input>
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Gratuito al registrarse:</label>
                    <Toggle
                      checked={packageInEdit.is_free}
                      name="cause_root"
                      className="margin-auto mobile-hidden"
                      value="yes"
                      title={"Gratuito"}
                      aria-label={"raiz"}
                      onChange={(e) =>
                        setFieldPackage(!packageInEdit.is_free, "is_free")
                      }
                    />
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Visible al publico:</label>
                    <Toggle
                      checked={packageInEdit.active}
                      name="active"
                      className="margin-auto mobile-hidden"
                      value="yes"
                      title={"Gratuito"}
                      aria-label={"raiz"}
                      onChange={(e) =>
                        setFieldPackage(!packageInEdit.active, "active")
                      }
                    />
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Personal:</label>
                    <Toggle
                      checked={packageInEdit.is_persona}
                      name="active"
                      className="margin-auto mobile-hidden"
                      value="yes"
                      title={"Gratuito"}
                      aria-label={"raiz"}
                      onChange={(e) =>
                        setFieldPackage(!packageInEdit.is_persona, "is_persona")
                      }
                    />
                  </div>
                  <div className="form-group txtarea-form-program">
                    <label className="tipo-title">Promocion:</label>
                    <Toggle
                      checked={packageInEdit.is_promotion}
                      name="active"
                      className="margin-auto mobile-hidden"
                      value="yes"
                      title={"Promocion"}
                      aria-label={"raiz"}
                      onChange={(e) =>
                        setFieldPackage(
                          !packageInEdit.is_promotion,
                          "is_promotion"
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="">
                    <input
                      onClick={() => addNewDetail()}
                      className="btn-orange-default-short "
                      value="Nuevo servicio"
                      type="button"
                    />

                    <div className="flex flex-column">
                      {packageInEdit.details &&
                        packageInEdit.details.map((detail, index) => {
                          return (
                            <div key={index} className="flex">
                              <div className="form-group">
                                <label className="tipo-title"></label>
                                <FontAwesomeIcon
                                  className="color-module-audit "
                                  icon={faCheckCircle}
                                />
                              </div>

                              <div className="form-group ">
                                <label className="tipo-title">Cantidad:</label>
                                <input
                                  onChange={(e) =>
                                    setFieldPackageDetail(
                                      index,
                                      e.target.value,
                                      "quantity"
                                    )
                                  }
                                  min={1}
                                  name="name"
                                  type="number"
                                  className="form-control tipo-description"
                                  placeholder="Impuesto"
                                  value={detail.quantity}
                                ></input>
                              </div>
                              <div className="form-group margin-4">
                                <label className="tipo-title">Servicio:</label>
                                <Select
                                  value={detail.service}
                                  className="tipo-description select-package-edit"
                                  placeholder={"Seleccione una opción"}
                                  getOptionLabel={(option) => `${option.name}`}
                                  getOptionValue={(option) => option.id}
                                  options={services.elements}
                                  onChange={(e) =>
                                    changeFieldDetailSelect(e, index)
                                  }
                                  isClearable={false}
                                />
                              </div>
                              {detail.service_id === LIBRARY_SERVICE && (
                                <div className="form-group ">
                                  <label className="tipo-title">Producto:</label>
                                  <Select
                                    value={detail.product}
                                    className="tipo-description select-package-edit"
                                    placeholder={"Seleccione una opción"}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => option.id}
                                    options={topics.elements}
                                    onChange={(e) =>
                                      changeFieldDetailSelectTopic(e, index)
                                    }
                                    isClearable={false}
                                  />
                                </div>
                              )}

                              <span
                                onClick={() => deleteDetail(detail.id, index)}
                                className="cursor-action"
                              >
                                x
                              </span>
                            </div>
                          );
                        })}
                      <div>
                        <input
                          onClick={() => addNewBefit()}
                          className="btn-orange-default-short "
                          value="Nuevo beneficio"
                          type="button"
                        />
                        <div className="flex-column">
                          {packageInEdit.benefits &&
                            packageInEdit.benefits.map((benefit, index) => {
                              return (
                                <div key={index} className="flex">
                                  <div className="form-group">
                                    <label className="tipo-title"></label>
                                    <FontAwesomeIcon
                                      className="color-module-audit "
                                      icon={faCheckCircle}
                                    />
                                  </div>

                                  <div className="form-group ">
                                    <label className="tipo-title">Beneficio:</label>
                                    <input
                                      id={"benefit-edit-package-" + index}
                                      onChange={(e) =>
                                        setFieldBenefit(
                                          index,
                                          e.target.value,
                                          "note"
                                        )
                                      }
                                      onKeyPress={(e) => {
                                        e.key === "Enter" && addNewBefit();
                                      }}
                                      name="name"
                                      type="text"
                                      style={{ minWidth: "330px" }}
                                      className="form-control tipo-description"
                                      placeholder="Describa un beneficio del paquete"
                                      value={benefit.note}
                                    ></input>
                                  </div>

                                  <span
                                    onClick={() =>
                                      deleteBenefit(benefit.id, index)
                                    }
                                    className="cursor-action"
                                  >
                                    x
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btn-actions-form">
                  <input
                    onClick={() => addPackage(packageInEdit)}
                    type="button"
                    className="btn-orange-default"
                    value="Guardar"
                  ></input>
                  {packageInEdit.id && (
                    <input
                      onClick={() => checkDeletePackage(packageInEdit)}
                      type="button"
                      className="btn-orange-default"
                      value="Eliminar"
                    ></input>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <input
        value="Nuevo paquete"
        type="button"
        className="btn-orange-default-short"
        onClick={() => setpackageInEdit(newPackage)}
      />
      <div className="flex">
        <TarjetPackage
          packages={packages.elements.filter((p) => p.module == module)}
          actionButton={setpackageInEdit}
          nameButton={"Editar"}
          setAsing={setpackageAsing}
        />

        <TooltipModule
          services={services}
          module={module}
          setModule={setmodule}
        />
      </div>
    </div>
  );
};

export default Package;
