import React, { useContext, useState, useEffect } from "react";
import { CompanyFormContext } from "../../../contexts/general/CompanyFormContext";
import "../company/Form.css";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import CreateStaff from "./CreateStaff";
import CreateProcces from "./CreateProcces";
import ImageUploader from "react-images-upload";
import { Field, Form } from "react-final-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Embed from "react-embed";
import moment from "moment";
import PqrsList from "./pqrsf/index";
import imageProfile from "../../../img_zeus/default/PROFILE.jpeg";
import { STATUS_LOADED } from "../../../contexts/audit/AuditContext";
import imgProfileError from "../../../img_zeus/default/PROFILE.jpeg";
import { Link, useHistory } from "react-router-dom";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const initialState = {
  toggle_modal: false,
  toggle_modal2: false,
  company: {},
  email: "",
  newPersonStaff: {
    position: "",
    valid_date: new Date(),
    person_id: null,
    first_name: "",
    last_name: "",
  },
  procces_id: null,
  participants: [],
};

const Show = ({ computedMatch: { params, url } }) => {
  let [
    { company, usersSearch },
    {
      getUser,
      getUserResponsable,
      changeCompany,
      deleteStaff,
      deleteProcces,
      updateImageCompany,
      updateEvaluationPqrs,
      updateStaff,
    },
  ] = useContext(CompanyFormContext);
  const [{ user }, {}] = useContext(SecurityContext);
  let [state, setState] = useState(initialState);
  let [personstate, setPerson] = useState(initialState.newPersonStaff);
  let [emailState, setEmailState] = useState(initialState.email);
  let { company_id } = params;
  const [toggleResponsable, settoggleResponsable] = useState(false);
  let history = useHistory();
  useEffect(() => {
    setPerson({
      ...personstate,
      person_id: company.person.id,
      company_id: company_id,
      first_name: company.person.first_name,
      last_name: company.person.last_name,
    });
  }, [company.person]);

  useEffect(() => {
    changeCompany(company_id);
  }, [company_id]);
  let setImage = (field) => (e) => {
    let file = e[e.length - 1];
    updateImageCompany(file);
  };
  const styleContainerImage = {
    backgroundImage: `url(${
      company.company.image == null
        ? imageProfile
        : process.env.REACT_APP_API_HOST +
          "/documents/" +
          (company.company.image && company.company.image.name)
    }`,
  };
  const checkDeleteStaff = async (person) => {
    let responsablesPqrs = company.staff.filter(
      (p) => p.pivot.isPqrsResponsable
    ).length;
    let liderSatff = company.staff.filter(
      (p) => p.id === company.company.contact_person_id
    )[0];
    let text = "¡No podrás revertir esto!";
    console.log(responsablesPqrs);

    if (responsablesPqrs === 1 && person.pivot.isPqrsResponsable) {
      text =
        "¡Se transferira la responsabilidad del pqrs al lider de la empresa!";
    }
    Swal.fire({
      title: "¿Realmente deseas eliminar este integrante?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then(async (result) => {
      if (result.value) {
        if (responsablesPqrs === 1 && person.pivot.isPqrsResponsable) {
          await updateStaff({ isPqrsResponsable: true }, liderSatff.pivot.id);
        }

        deleteStaff(person.pivot.id);
      } else {
      }
    });
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  let shortText = (desc, range) => {
    if (desc && desc.length > range) {
      return desc.substr(0, range) + "...";
    }
    return desc;
  };
  const canViewPqrs = () => {
    let can = false;
    if (company.company.contact_person_id === user.id) {
      can = true;
    } else if (
      company.staff.filter((p) => p.id === user.id && p.pivot.isPqrsResponsable)
        .length > 0
    ) {
      can = true;
    }
    return can;
  };
  const required = (value) => (value ? undefined : "Requerido");
  const heighFrame = (obj) => {
    let iframe = obj.contentWindow.document.getElementById("root");
    //let heightDocument=obj.contentWindow.document.documentElement.scrollHeight + 'px';


    //   obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
  };
  return (
    <div style={{ fontSize: "16px" }}>
      {toggleResponsable && (
        <div className="back-document">
          <div className="flex container-change-responsable">
            <div className="header-modal-create-evaluation w-100">
              <div className="flex w-100">
                <div
                  onClick={() => settoggleResponsable(!toggleResponsable)}
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="no-margin txt-center txt-title-new-folder tipo-description">
                  Cambiar de responsable
                </p>
              </div>
              <div className="b-color-white-gray  content-change-responsable">
                <Form
                  onSubmit={(formObject) => {
                    getUserResponsable(formObject);
                    settoggleResponsable(!toggleResponsable);
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name="email" validate={required}>
                        {({ input, meta }) => (
                          <>
                            <input
                              className="txt-email-responsable"
                              type="email"
                              {...input}
                              placeholder="email"
                            />
                            {meta.touched && meta.error && (
                              <span className="tipo-cursiva orange">
                                {meta.error}
                              </span>
                            )}
                          </>
                        )}
                      </Field>

                      <input
                        type="submit"
                        className="bg-orange white no-border btn-asign-responsable"
                        value="Asignar propiedad"
                      ></input>
                    </form>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.toggle_modal2 === true && (
        <div className="back-document">
          <div className="">
            <div className="header-modal-procces">
              <div
                className="salir-company"
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal2: false,
                    procces_id: null,
                  });
                }}
              ></div>
              {state.procces_id !== null && (
                <h3 className="title-form-topic tipo-description">
                  Editar Proceso
                </h3>
              )}{" "}
              {state.procces_id === null && (
                <h3 className="title-form-topic tipo-description">
                  Nuevo Proceso
                </h3>
              )}
            </div>
            <div>
              <CreateProcces
                state={state}
                setState={setState}
                procces_id={state.procces_id}
              />
            </div>
          </div>
        </div>
      )}
      {state.toggle_modal === true && (
        <div className="back-document">
          <div className="modal-staff">
            <div className="header-modal-staff">
              <div
                className="salir-company"
                onClick={() => {
                  setState({ ...state, toggle_modal: false });
                  setPerson(initialState.newPersonStaff);
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                Nuevo integrante
              </h3>
            </div>
            <div className="w-100">
              <CreateStaff
                state={state}
                setState={setState}
                company_id={company_id}
                personstate={personstate}
                setPerson={setPerson}
                newPersonStaff={initialState.newPersonStaff}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex">
        <h2 className="tipo-title title-program flex-1">
          Compañia {company.company.name}
        </h2>
        {user.id === company.company.contact_person_id && (
          <div className="flex-1 flex justify-end">
            <div
              onClick={() => settoggleResponsable(!toggleResponsable)}
              className="flex btn-change-responsable background-simple cursor-action"
            ></div>
          </div>
        )}
      </div>
      <div className="content-general-info-program flex tipo-description">
        <div className="form-group input-company">
          <label className="tipo-title">Dirección</label>
          <div
            className="div-info-company cursor-action"
            title={
              company.status === STATUS_LOADED && company.address
                ? company.address
                : ""
            }
          >
            {company.status === STATUS_LOADED && company.company.address
              ? shortText(company.company.address, 25)
              : ""}
          </div>
        </div>
        <div className="form-group input-company">
          <label className="tipo-title">NIT</label>
          <div className="div-info-company">{company.company.nit}</div>
        </div>
        <div className="form-group input-company">
          <label className="tipo-title">Representante legal</label>
          <div className="div-info-company">
            {company.company.representative}
          </div>
        </div>

        <div className="form-group input-company">
          <label className="tipo-title">Correo</label>
          <div className="div-info-company">{company.company.email}</div>
        </div>
        <div className="form-group input-company">
          <label className="tipo-title">Teléfono</label>
          <div className="div-info-company">{company.company.phone}</div>
        </div>
        <div className="form-group input-company">
          <label className="tipo-title">Responsable</label>
          <div className="div-info-company">
            {company.company.contact && company.company.contact.first_name}{" "}
            {company.company.contact && company.company.contact.last_name}
          </div>
        </div>

        <div className="form-group input-company">
          <label className="tipo-title">Ciudad</label>
          <div className="div-info-company">
            {company.status === STATUS_LOADED && company.company.city
              ? company.company.city.name
              : "Sin asignar"}
          </div>
        </div>
        {user.id === company.company.contact_person_id && (
          <div className="form-group">
            <ImageUploader
              withIcon={true}
              className="container-image-company"
              buttonClassName="button-image-company shadow-tarjet"
              buttonText=""
              fileContainerStyle={styleContainerImage}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              withIcon={false}
              labelClass="hidden"
              withPreview={false}
              onChange={setImage("document")}
              fileSizeError="Excede el peso permitido"
              fileTypeError="Extensión no permitida"
              singleImage={true}
              label="Peso máximo 5mb,acepta:jpg,gif,png"
            />
          </div>
        )}
        <div className="container-header-capacity black">
          <Link to={`/app/effectives/documents/company/${company.company.id}`}>
            <FontAwesomeIcon className="black" icon={faCloud} />
          </Link>
          &nbsp; <small className="black"> Almacenamiento</small>
          <br />
          <small className="black">
            {company.company.capacity == 0
              ? `${company.company.capacity} MB`
              : `${company.company.cosumed} MB de ${company.company.capacity} MB usado`}
          </small>
        </div>
        <div className="w-100 tabas-container-company">
          <Tabs className="content-audit w-100">
            <TabList className="header-tab-audit">
              <Tab>
                <strong>Grupo de trabajo</strong>
              </Tab>

              <Tab disabled={user.id !== company.company.contact_person_id}>
                <strong>Procesos</strong>
              </Tab>

              <Tab disabled={!canViewPqrs()}>
                <strong>PQRS</strong>
              </Tab>
              <Tab disabled={!canViewPqrs()}>
                <strong>Listado de PQRS</strong>
              </Tab>
            </TabList>
            <div className="general-tab-audit">
              <>
                <TabPanel>
                  <div className="table-company-form">
                    <div className="flex title-content-company">
                      <h2 className="tipo-title">Grupo de trabajo</h2>
                      {company.company.contact_person_id === user.id && (
                        <div
                          onClick={() => {
                            setState({ ...state, toggle_modal: true });
                          }}
                          className="btn-orange-default-short"
                        >
                          Añadir integrante
                        </div>
                      )}
                    </div>

                    <table className="table table-hover">
                      <thead className="thead-company">
                        <tr className="txt-center">
                          <th scope="col">NOMBRE</th>
                          <th scope="col">CORREO</th>
                          <th scope="col">ROL</th>
                          <th scope="col">Responsable PQRS</th>
                          {company.company.contact_person_id === user.id && (
                            <th scope="col">ACCIÓN</th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white txt-center tipo-description">
                        {company.staff &&
                          company.staff.map((staff) => (
                            <tr key={staff.pivot.id}>
                              <td>
                                <div className="flex justify-center">
                                  <img
                                    className={` btn-auditor-activity-filter  cursor-action background-simple `}
                                    src={
                                      process.env.REACT_APP_API_HOST +
                                      "/documents/" +
                                      (staff.image && staff.image.name)
                                    }
                                    onError={(e) => {
                                      e.target.src = imgProfileError;
                                    }}
                                  ></img>
                                  &nbsp;{staff.first_name} {staff.last_name}
                                </div>
                              </td>
                              <td>{staff.email}</td>
                              <td>
                                {" "}
                                {company.company.contact.id ==
                                staff.pivot.person_id
                                  ? "Responsable SGC"
                                  : staff.pivot.position}
                              </td>
                              <td>
                                {staff.pivot.isPqrsResponsable ? "SI" : "NO"}
                              </td>
                              {/*  <td>
                      {moment(staff.pivot.valid_date).format("YYYY-MM-DD")}
                    </td> */}
                              {user.id ===
                                company.company.contact_person_id && (
                                <td className="btn-action-program">
                                  {company.company.contact.id !=
                                    staff.pivot.person_id && (
                                    <img
                                      className="cursor-action"
                                      onClick={() => {
                                        checkDeleteStaff(staff);
                                      }}
                                      title="Eliminar integrante"
                                      src={btn_delete}
                                    />
                                  )}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="table-company-form">
                    <div className="flex title-content-company">
                      <h2 className="tipo-title">Procesos</h2>
                      <div
                        onClick={() => {
                          setState({ ...state, toggle_modal2: true });
                        }}
                        className="btn-orange-default-short"
                      >
                        Añadir proceso
                      </div>
                    </div>
                    <table className="table table-hover">
                      <thead className="thead-company">
                        <tr className="txt-center">
                          <th scope="col">NOMBRE</th>
                          <th scope="col">ACCIÓN</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white txt-center tipo-description">
                        {company.company.process &&
                          company.company.process.map((process) => (
                            <tr key={process.id}>
                              <td>{process.name}</td>
                              <td className="btn-action-program">
                                <img
                                  className="cursor-action"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      toggle_modal2: true,
                                      procces_id: process.id,
                                    });
                                  }}
                                  title="Editar compañia"
                                  src={btn_edit}
                                />
                                &nbsp;
                                <img
                                  className="cursor-action"
                                  onClick={() => {
                                    checkDelete(
                                      "¿Realmente deseas eliminar este proceso?",
                                      `deleteProcces(${process.id})`
                                    );
                                  }}
                                  title="Eliminar proceso"
                                  src={btn_delete}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
                <TabPanel>
                  {company.status === STATUS_LOADED && (
                    <div className="flex">
                      <div
                        className="flex-column w-100"
                        style={{ height: "-webkit-fill-available" }}
                      >
                        <div className="flex justify-betwen">
                          {company.company.evaluation_pqrs.length > 0 && (
                            <div
                              onClick={() =>
                                history.push(
                                  `../../../app/inspection/evaluation/${company.company.evaluation_pqrs[0].evaluation_id}`
                                )
                              }
                              className="btn-orange-default"
                            >
                              Editar
                            </div>
                          )}
                          <div
                            onClick={() =>
                              history.push(
                                `../../../PQRSF/${new Buffer(
                                  new Buffer(
                                    company.company.id +
                                      "/" +
                                      company.company.created_at
                                  ).toString("base64")
                                ).toString("base64")}`
                              )
                            }
                            className="btn-orange-default"
                          >
                            Ir
                          </div>
                        </div>

                        <iframe
                          id="iframe-pqrs-company-form"
                          onLoad={(e) => heighFrame(e.target)}
                          width="100%"
                          style={{
                            pointerEvents: "none",
                          }}
                          src={`${
                            process.env.REACT_APP_GUI_HOST
                          }PQRSF/${new Buffer(
                            new Buffer(
                              company.company.id +
                                "/" +
                                company.company.created_at
                            ).toString("base64")
                          ).toString("base64")}`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <PqrsList />
                </TabPanel>
              </>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Show;
