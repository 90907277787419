import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
  STATUS_APPROVED,
  STATUS_ERASER,
  AuditContext,
} from "../../../contexts/audit/AuditContext";
import Swal from "sweetalert2";
import Toggle from "react-toggle";
import CreateStandard from "../../audit/modals/CreateStandard";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconShow from "../../../../src/img_zeus/icons_audit/ICONO-VER.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import "../standard/Index.css";
import "../standard/toogle.css";

// get our fontawesome imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

const Index = ({ computedMatch: { path } }) => {
  let history = useHistory();
  const [
    { standard, modalStandard },
    { deleteStandard, inEditModalStandard, setStandard, updateFormStandard ,settoogleItemType,setitemsTypeStandard},
  ] = useContext(AuditContext);
  const [{ user }, { isCollaboratorStandard }] = useContext(SecurityContext);
  let shortDescription = (desc) => {
    if (desc.length > 50) {
      return desc.substr(0, 50) + "...";
    }
    return desc;
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const changeStatusStandard = (standard_id, index) => (event) => {
    let newArray = [...standard.elements];
    let newStatus =
      newArray[index].status_id == STATUS_ERASER
        ? STATUS_APPROVED
        : STATUS_ERASER;
    newArray[index].status_id = newStatus;

    setStandard({ ...standard, elements: newArray });

    updateFormStandard(standard_id, { status_id: newStatus });
  };
  return (
    <div>
      {modalStandard.isOpen && (
        <div className="back-document">
          <CreateStandard />
        </div>
      )}
      <div className="flex">
        <div className="flex-1">
          <h2 className="title-evaluation tipo-title">NORMAS</h2>
        </div>
        {user.is_admin && (
          <div className="flex-1 flex justify-end">
            <div
              onClick={() => {
                inEditModalStandard(null);
                settoogleItemType(false);
                setitemsTypeStandard([])
              }}
              className="cursor-action bg-orange img-create-standard"
            ></div>
          </div>
        )}
      </div>
      <div className="general-content-index-evaluation back-gray-content">
        <table className="table table-hover">
          <thead className="header-table-index-evaluation">
            <tr className="txt-center">
              <th scope="col">NOMBRE</th>
              <th scope="col">NOMBRE LARGO</th>
              <th scope="col">CREACIÓN</th>
              <th scope="col">ÚLTIMA EDICIÓN</th>
              <th scope="col">ESTADO</th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {standard.elements.map((sta, index) => (
              <tr className="tr-table-standars txt-center" key={sta.id}>
                <td>{sta.short_name}</td>
                <td className="txt-left">{sta.long_name}</td>
                <td>{moment(sta.created_at).format("YYYY-MM-DD")}</td>

                <td>{sta.updated_at}</td>

                <td
                  title={`${
                    sta.status_id === STATUS_ERASER ? "Borrador" : "Publicado"
                  }`}
                >
                  {isCollaboratorStandard(sta.id) && (
                    <Toggle
                      checked={sta.status_id === STATUS_ERASER ? false : true}
                      name="status"
                      value="yes"
                      onChange={changeStatusStandard(sta.id, index)}
                      icons={{
                        unchecked: <FontAwesomeIcon icon={faAddressBook} />,
                      }}
                    />
                  )}
                  {!isCollaboratorStandard(sta.id) && "Activo"}
                </td>

                <td className="icons-standard-action">
                  {isCollaboratorStandard(sta.id) && (
                    <img
                      onClick={() => {
                        inEditModalStandard(sta.id);
                      }}
                      title="ver norma"
                      className="icon-actions-norma cursor-action"
                      src={iconEdit}
                    />
                  )}
                  {(user.is_admin || isCollaboratorStandard(sta.id))&& (
                    <img
                      onClick={() =>
                        history.push(`/app/audit/standard/${sta.id}`)
                      }
                      title="editar norma"
                      className="icon-actions-norma cursor-action"
                      src={iconShow}
                    />
                  )}

                  {/*     {user.is_admin && (
                    <img
                      onClick={() =>
                        checkDelete(
                          "¿Realmente deseas eliminar esta norma?",
                          `deleteStandard(${sta.id})`
                        )
                      }
                      title="eliminar norma"
                      className="icon-actions-norma cursor-action"
                      src={iconDelete}
                    />
                  )} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div />
      </div>
    </div>
  );
};
export default Index;
