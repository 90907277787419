import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";
import firebase from "../../../firebase";
import cloneDeep from "lodash/cloneDeep";
import { STATUS_ERASER } from "../../evaluation/InspectionFormContext";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const MAX_FILE_SIZE_FREE = 2;
const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLSX = "xlsx";
let initalState = {
  noteEdit: { id: null, note: "" },
  audit: {
    status: STATUS_NOT_LOADED,
    audit_id: null,
    element: {},
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  evaluation: {
    status: STATUS_NOT_LOADED,
    evaluation_id: null,
    element: {},
  },

  item: {
    status: STATUS_NOT_LOADED,
    item_id: null,
    element: {},
  },
  modal: {
    evaluation: {
      isOpen: false,
      evaluation_id: null,
    },
  },
  upload: {
    item_id: null,
    status: STATUS_LOADED,
  },
  uploadObservation: {
    evaluation_id: null,
    status: STATUS_LOADED,
  },
  errors: {
    error: [],
    status: false,
  },
  formats: ["png", "jpg", "jpeg", "doc", "docx", "pdf", "xlsx", "xls", "mp3", "mp4", "mov"],
};
const db = firebase.firestore();
export const EvaluationFormContext = React.createContext({});

export const EvaluationFormContextProvider = ({ children }) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertWarning, showErrors, alertError }] = useContext(AlertContext);
  let location = useLocation();
  let history = useHistory();
  let [audit, setAudit] = React.useState(initalState.audit);
  let [template, setTemplate] = React.useState(initalState.template);
  let [item, setItem] = React.useState(initalState.item);
  const [loadDocument, setloadDocument] = useState(initalState.upload);
  const [noteEdit, setnoteEdit] = useState(initalState.noteEdit);
  const [loadObservation, setloadObservation] = useState(initalState.uploadObservation);
  const [scroll, setscroll] = useState(0);
  let [evaluation, setEvaluation] = React.useState(initalState.evaluation);

  let [errors, setErrors] = useState(initalState.errors);
  const [modalEvaluation, setModalEvaluation] = useState(initalState.modal.evaluation);
  const [itemsRemote, setitemsRemote] = useState([]);
  const [inTransformation, setinTransformation] = useState(STATUS_LOADED);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (evaluation.status == STATUS_LOADED) {
      db.collection(
        `audits/${evaluation.element.audit[0].id}/evaluations/${evaluation.evaluation_id}/items`
      ).onSnapshot(function (data) {
        let items = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        let itemsFind = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.active) {
            let findItemRecursive = findItem(item.id, evaluation.element.items);
            if (findItemRecursive) {
              itemsFind.push(findItemRecursive);
            }
          }
        }
        setitemsRemote(itemsFind);
      });
    }
  }, [evaluation.status]);

  const findItem = (item_id, items) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id == item_id) {
        return item;
      }
      if (item.items.length > 0) {
        let itemFind = findItem(item_id, item.items);
        if (itemFind) {
          return itemFind;
        }
      }
    }
  };
  useEffect(() => {
    //templates en venta
    if (audit.audit_id != null && audit.status === STATUS_NOT_LOADED) {
      setAudit({ ...audit, status: STATUS_LOADING });
      client()
        .get(`audit/audit/${audit.audit_id}`, {
          params: {
            with: ["evaluations.personEvaluation", "responsablePerson", "standard"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setAudit({ ...audit, status: STATUS_LOADED, element: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../../app/unauthorized");
          } else {
            alertError("Error al buscar la auditoría");
          }
        });
    }
  }, [audit]);
  useEffect(() => {
    if (evaluation.status === STATUS_LOADED) {
      setTemplate({ ...template, status: STATUS_LOADING });
      client()
        .get(`security/person/${user.id}/templates`)
        .then(({ data }) => {
          if (data) {
            let el = data.filter((e) => e.standard_id === evaluation.element.audit[0].standard_id);
            setTemplate({
              status: STATUS_LOADED,
              elements: el,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantillas");
          }
        });
    }
  }, [evaluation.status]);
  useEffect(() => {
    if (evaluation.evaluation_id !== null && evaluation.status === STATUS_NOT_LOADED) {
      setEvaluation({ ...evaluation, status: STATUS_LOADING });
      client()
        .get(`audit/evaluation/${evaluation.evaluation_id}`, {
          params: {
            with: [
              "observations.documents",
              "observations.person",
              "personEvaluation",
              "audit.standard.item_types",
              "activity.auditParticipants",
              "items.options",
              "items.documents",
              "items.results",
              "items.notes",
              "items.auditable_item",
            ],
          },
        })
        .then(({ data }) => {
          if (data.id) {
            setEvaluation({ ...evaluation, status: STATUS_LOADED, element: data });
          } else {
            history.push("../../../app/unauthorized");
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("app/unauthorized");
          } else {
            alertError("Error al buscar la evaluación");
          }
        });
    }
  }, [evaluation]);
  //functions
  const getAudit = (audit_id) => {
    setAudit({ ...audit, audit_id: audit_id });
  };
  const changeEvaluation = (evaluation_id) => {
    setEvaluation({
      ...evaluation,
      element: {},
      evaluation_id: evaluation_id,
      status: STATUS_NOT_LOADED,
    });
  };

  const createEvaluation = ({ evaluation, title, description, application_date }) => {
    client()
      .post(`audit/evaluation`, {
        evaluation_id: evaluation.id,
        title: title,
        description: description,
        application_date: application_date,
        audit_id: audit.audit_id,
        person_id: user.id,
        is_template: false,
        on_sale: false,
      })
      .then(({ data }) => {
        if (data) {
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
          alertSuccess("Evaluación creada satisfactoriamente");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al buscar las plantillas");
        }
      });
  };
  useEffect(() => {
    if (item.status === STATUS_NOT_LOADED && item.item_id != null) {
      setItem({ ...item, status: STATUS_LOADING });
      client()
        .get(`evaluation/item/${item.item_id}`, {
          params: {
            with: [
              "items.options",
              "documents",
              "auditable_item",
              "options",
              "results",
              "notes",
              "evaluation.audit",
              "evaluation.audit.standard.item_types",
              "evaluation.items.auditable_item",
              "evaluation.activity.audit",
              "evaluation.activity.auditParticipants",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setItem({ ...item, status: STATUS_LOADED, element: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar el item");
          }
        });
    }
  }, [item]);
  const changeItem = (item_id) => {
    setItem({ ...item, item_id: item_id, status: STATUS_NOT_LOADED });
  };

  const deleteItem = (item_id) => {
    client()
      .delete(`evaluation/item/${item_id}`)
      .then(({ data }) => {
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el item");
        }
      });
  };
  const addItem = ({ id, item_type_id, title }) => {
    client()
      .post(`evaluation/item`, {
        item_type_id: 1,
        item_id: id,
        title: title,
        evaluation_id: evaluation.evaluation_id,
      })
      .then(({ data }) => {
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al crear item");
        }
      });
  };
  const validateFormat = (format) => {
    let isValid = false;

    for (let f = 0; f < initalState.formats.length; f++) {
      const ext = initalState.formats[f];
      if (ext == format.toLowerCase()) {
        isValid = true;
      }
    }
    return isValid;
  };
  const addDocumentItem = ({ item_id, image }) => {
    for (let d = 0; d < image.length; d++) {
      const document = image[d];

      var formData = new FormData();
      formData.append("document", document);
      let ext = document.name.split(".").pop();
      if (!validateFormat(ext)) {
        alertWarning("Formato inválido");
        return false;
      }
      if (ext === EXT_DOCX || ext === EXT_XLSX || ext === EXT_DOC) {
        formData.append("is_private", false);
      }

      setloadDocument({ item_id: item_id, status: STATUS_LOADING });
      client()
        .post(`evaluation/item/${item_id}/documented`, formData)
        .then(({ data }) => {
          alertSuccess("Documento añadido satisfactoriamente");
          setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
          setItem({ ...item, status: STATUS_NOT_LOADED });
          setloadDocument({ item_id: item_id, status: STATUS_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al añadir el documento");
          }
          setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
          setItem({ ...item, status: STATUS_NOT_LOADED });
          setloadDocument({ item_id: item_id, status: STATUS_LOADED });
        });
    }
  };
  const changeStatusResponseItemMobile = (item_id, option_id) => {
    if (item_id === item.element.id) {
      let option = item.element.options.filter((o) => o.id === option_id)[0];
      item.element.results = [option];
    } else {
      let itemFind = item.element.items.filter((i) => i.id === item_id)[0];
      let option = itemFind.options.filter((o) => o.id === option_id)[0];

      option.pivot = { item_id, option_id };
      itemFind.results = [option];
    }
  };
  const changeStatusResponseItemDesktop = (items, item_id, option_id) => {
    for (let i = 0; i < items.length; i++) {
      const itemFor = items[i];
      if (itemFor.id === item_id) {
        let option = itemFor.options.filter((o) => o.id === option_id)[0];
        itemFor.results = [option];
        option.pivot = { item_id, option_id };
      }
      if (itemFor.items.length > 0) {
        changeStatusResponseItemDesktop(itemFor.items, item_id, option_id);
      }
    }
  };
  const addResponseItem = ({ item_id, option_id }) => {
    if (item.status === STATUS_LOADED) {
      changeStatusResponseItemMobile(item_id, option_id);
      setItem({ ...item, item });
    } else {
      changeStatusResponseItemDesktop(evaluation.element.items, item_id, option_id);
      setEvaluation({ ...evaluation, evaluation });
    }

    var formData = new FormData();
    formData.append("option_id", option_id);

    client()
      .post(`evaluation/optionAttachResult/${item_id}`, formData)
      .then(({ data }) => {
        //setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la respuesta");
        }
      });
  };
  const addNoteItem = ({ item_id, note }) => {
    var formData = new FormData();
    formData.append("note", note);
    formData.append("person_id", user.id);

    client()
      .post(`evaluation/item/${item_id}/notificated`, formData)
      .then(({ data }) => {
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });

        alertSuccess("Nota añadida satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir el comentario");
        }
      });
  };
  const updateNoteItem = (item_id) => {
    var formData = new FormData();
    formData.append("note", noteEdit.note);
    client()
      .post(`evaluation/item/${item_id}/noteUpdate/${noteEdit.id}`, formData)
      .then(({ data }) => {
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
        setnoteEdit({ ...noteEdit, id: null });

        alertSuccess("Nota editada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la nota");
        }
      });
  };
  const addNoteEvaluation = ({ evaluation, note }) => {
    var formData = new FormData();
    formData.append("note", note);
    formData.append("person_id", user.id);
    client()
      .post(`audit/evaluation/${evaluation}/notificated`, formData)
      .then(({ data }) => {
        alertSuccess("Observación añadida satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al añadir la observación");
        }
      });
  };
  const deleteNote = (note_id, item_id) => {
    client()
      .delete(`evaluation/item/${item_id}/noteDelete/${note_id}`)
      .then(({ data }) => {
        alertSuccess("Comentario eliminado satisfactoriamente");
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el item");
        }
      });
  };
  const updateNote = ({ note_id, item_id, note }) => {
    client()
      .post(`evaluation/item/${item_id}/noteUpdate/${note_id}`, { note: note })
      .then(({ data }) => {
        alertSuccess("Comentario modificado satisfactoriamente");
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el comentario");
        }
      });
  };
  const deleteDocument = (document_id, item_id) => {
    client()
      .delete(`evaluation/item/${item_id}/documentDelete/${document_id}`)
      .then(({ data }) => {
        alertSuccess("Archivo eliminado satisfactoriamente");
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        setItem({ ...item, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar el archivo");
        }
      });
  };
  const deleteEvaluation = (evaluation_id) => {
    client()
      .delete(`audit/evaluation/${evaluation_id}`)
      .then(({ data }) => {
        alertSuccess("Evaluación eliminada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al eliminar la evaluación");
        }
      });
  };
  const editEvaluation = ({ title, description, application_date }) => {
    client()
      .post(`audit/evaluation/${modalEvaluation.evaluation_id}`, {
        title: title,
        description: description,
        application_date: application_date,
        audit_id: audit.element.id,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Evaluación editada satisfactoriamente");
          setAudit({
            ...audit,
            status: STATUS_NOT_LOADED,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar la evaluación");
        }
      });
  };
  const inEditModalEvaluation = (evaluation_id) => {
    setModalEvaluation({
      ...modalEvaluation,
      evaluation_id: evaluation_id,
      isOpen: !modalEvaluation.isOpen,
    });
  };
  const updateItem = ({ id, title }) => {
    client()
      .put(`evaluation/item/${id}`, { title })
      .then(({ data }) => {
        setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        alertSuccess("Item editado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../app/unauthorized");
        } else {
          alertError("Error al editar el item");
        }
      });
  };
  const addObservation = ({ note, document }, role) => {
    client()
      .post(`audit/evaluation/${evaluation.evaluation_id}/notificated`, {
        note: note,
        role: role,
        person_id: user.id,
      })
      .then(({ data }) => {
        if (data) {
          if (document.length > 0) {
            setloadObservation({ evaluation_id: evaluation.evaluation_id, status: STATUS_LOADING });
            for (let d = 0; d < document.length; d++) {
              const doc = document[d];

              const formData = new FormData();
              formData.append("document", doc);

              client()
                .post(`general/note/${data.id}/documented`, formData)
                .then(({ data }) => {
                  if (data) {
                    setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
                  }
                })
                .catch((e) => {
                  if (e.request.status === 401) {
                    logout();
                  } else if (e.request.status === 422) {
                    setErrors({ error: e.response.data, status: true });
                  } else if (e.request.status === 403) {
                    history.push("/app/unauthorized");
                  } else {
                    alertError("Problemas al adjuntar el documento");
                  }
                });
            }
            setloadObservation({ evaluation_id: null, status: STATUS_LOADED });
          } else {
            setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
          }
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar elmensaje");
        }
      });
  };

  const deleteObservation = (observation_id, evaluation_id) => {
    client()
      .delete(`audit/evaluation/${evaluation_id}/noteDelete/${observation_id}`)
      .then(({ data }) => {
        if (data) {
          alertSuccess("Observación eliminada satisfactoriamente");
          setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al eliminar la observación");
        }
      });
  };
  const activeItem = (enable, audit_id, evaluation_id, item_id) => {
    db.collection(`audits/${audit_id}/evaluations/${evaluation_id}/items`)
      .doc(`${item_id}`)
      .set({ active: enable });
  };
  const resetEvaluation = () => {
    setEvaluation({ ...evaluation, status: STATUS_NOT_LOADED });
  };

  const transformEvaluationToTemplate = ({ evaluation_id, template_id }) => {
    setinTransformation(STATUS_LOADING);
    client()
      .get(`audit/evaluation/${evaluation_id}/changeTemplate/${template_id}`)
      .then(({ data }) => {
        resetEvaluation();
        setinTransformation(STATUS_LOADED);
      })
      .catch((e) => {
        setinTransformation(STATUS_LOADED);

        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al transformar la evaluacion");
        }
      });
  };
  const generatePlanItem = (item) => {
    const formData = new FormData();

    formData.append("audit_id", evaluation.element.audit[0].id);
    formData.append("company_id", evaluation.element.audit[0].company_id);
    formData.append("description", item.title);
    formData.append("status_id", STATUS_ERASER);

    if (item.results.length > 0) {
      formData.append("init_date", item.results[0].created_at);
      formData.append("end_date", item.results[0].created_at);
    }
    alertSuccess("Generando analisis...");
    client()
      .post(`evaluation/item/${item.id}/addAnalize`, formData)
      .then(({ data }) => {
        client().post(`evaluation/processAttachAnalyze/${data.id}`, {
          process_id: evaluation.element.activity.process_id,
        });
        alertSuccess("Analisis generado");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al generar el analisis");
        }
      });
  };
  return (
    <EvaluationFormContext.Provider
      value={[
        {
          scroll,
          audit,
          template,
          evaluation,
          modalEvaluation,
          item,
          loadDocument,
          loadObservation,
          itemsRemote,
          noteEdit,
          inTransformation,
        },
        {
          setscroll,
          getAudit,
          createEvaluation,
          deleteEvaluation,
          editEvaluation,
          changeEvaluation,
          changeItem,
          deleteItem,
          addItem,
          addDocumentItem,
          addResponseItem,
          addNoteItem,
          addNoteEvaluation,
          deleteNote,
          updateNote,
          deleteDocument,
          inEditModalEvaluation,
          updateItem,
          addObservation,
          deleteObservation,
          activeItem,
          resetEvaluation,
          setnoteEdit,
          updateNoteItem,
          transformEvaluationToTemplate,
          generatePlanItem,
        },
      ]}
    >
      {children}
    </EvaluationFormContext.Provider>
  );
};
