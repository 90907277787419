import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AuditPlanFormContext } from "../../../contexts/audit/AuditPlanFormContext";
import {
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
const initialState = {
  columns: { status: STATUS_NOT_LOADED, columns: [] },
};
const PlanACtions = () => {
  const [{ audit, analyzes }, {}] = useContext(AuditPlanFormContext);
  let history = useHistory();
  const getPercentage = (actions) => {
    let closed = actions.filter((a) => a.is_open === false);
    if (closed.length === 0) {
      return "0%";
    }
    let result=((closed.length * 100) / actions.length).toFixed(2)
    return result+ "%";
  };
  const statusAnalyze = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <img
              src={btn_created}
              title="Creada"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;

      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizada"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      case 8:
        return (
          <>
            <img
              src={deleteIcon}
              title="Rechazada"
              className="img-status-analyze"
            />{" "}
          </>
        );
        break;
      default:
        return (
          <img
            src={btn_created}
            title="Creada"
            className="img-status-analyze"
          />
        );
    }
  };
  const [columns, setcolumns] = useState(initialState.columns);
  useEffect(() => {
    if (audit.status === STATUS_LOADED) {
      let columns = [
        {
          center: true,
          name: "ID",
          maxWidth: "50px",
          selector: "number",
          cell: (row) => (
            <div
              className="cursor-action link-audit-index"
              onClick={() => {
                history.push(`/app/audit/analyze/${row.id}`);
              }}
            >
              {row.number}
            </div>
          ),
          sortable: true,
        },
        {
          name: "Numeral",
          center: true,
          maxWidth: "50px",
          selector: "analyzable.auditable_item.number",
          grow: 3,
          cell: (row) =>
            row.analyzable &&
            row.analyzable.auditable_item &&
            row.analyzable.auditable_item.number,
          sortable: true,
        },
        {
          name: "Descripcion",
          center: true,

          selector: "description",
          grow: 3,
          cell: (row) => row.description,
          sortable: true,
        },
        {
          name: "Responsable",
          center: true,
          maxWidth: "150px",
          selector: "responsable.first_name",
          cell: (row) =>
            row.responsable &&
            row.responsable.first_name + " " + row.responsable.last_name,
          sortable: true,
        },
        {
          name: "Estado",
          center: true,
          maxWidth: "60px",
          selector: "status_id",
          grow: 3,
          cell: (row) => statusAnalyze(row.status_id),
          sortable: true,
        },
        {
          name: "Avance",
          center: true,
          maxWidth: "60px",
          selector: "description",
          grow: 3,
          cell: (row) => getPercentage(row.actions),
          sortable: true,
        },
        {
          name: "Fec. Cumplimiento",
          center: true,
          maxWidth: "60px",
          selector: "end_date",
          grow: 3,
          cell: (row) => row.end_date,
          sortable: true,
        },
      ];
      setcolumns({ status: STATUS_LOADED, columns });
    }
  }, [audit.status]);
  return (
    <div>
      {columns.status === STATUS_LOADED && (
        <DataTable
          sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          noDataComponent={"no hay datos"}
          striped={true}
          columns={columns.columns}
          responsive={true}
          className=" tipo-description"
          data={analyzes.elements}
          pagination={true}
          paginationComponentOptions={{
            rowsPerPageText: "Lineas:",
            rangeSeparatorText: "de",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Todas",
          }}
        />
      )}
    </div>
  );
};

export default PlanACtions;
