import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../topic/form.css";
import {
  LibraryContext,
  STATUS_LOADED,
  STATUS_ERASER,
  STATUS_APPROVED,
} from "../../../contexts/library/LibraryContext";
import { Field, Form } from "react-final-form";
import ImageUploader from "react-images-upload";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import Select, { components } from "react-select";
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  statusTopic: [
    { name: "Publicado", id: STATUS_APPROVED },

    { name: "Borrador", id: STATUS_ERASER },
  ],
  item: {
    id: "",
    name: "",
    mounth_cost: "",
    description: "",
    image: "",
  },
  originalItem: {},
};

const CreatTopic = ({ topic_id, setModalState, modalState }) => {
  let [{ topics }, { updateTopic, createTopic }] = useContext(LibraryContext);
  let [state, setState] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);
  let history = useHistory();

  useEffect(() => {
    if (topic_id && topics.status === STATUS_LOADED) {
      let el = topics.elements.filter((e) => e.id == topic_id)[0];
      setState({
        ...state,
        item: {
          id: el.id,
          name: el.name,
          mounth_cost: el.mounth_cost,
          description: el.description,
          image: el.image,
          status_id: initialState.statusTopic.filter(
            (s) => s.id == el.status_id
          )[0],
        },
        originalItem: el,
        status: STATUS_EDIT,
      });
    }
  }, [topic_id, topics]);

  let functionSubmit = (formObj) => {
    formObj.status_id = formObj.status_id.id;
    if (state.status === STATUS_EDIT) {
      updateTopic(formObj);
      setModalState({ ...modalState, modal: !modalState.modal });
    } else {
      createTopic(formObj, state.item.image);
      setModalState({ ...modalState, modal: !modalState.modal });
    }
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  return (
    <Form
      onSubmit={(formObj) => {
        functionSubmit(formObj);
      }}
      initialValues={state.item}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="form-topic"
          encType="multipart/form-data"
        >
          <div className="form-group div-form-topic">
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Nombre:</label>
                  <input
                    {...input}
                    className="form-control tipo-description"
                    type="text"
                    placeholder="Nombre del Tema"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group div-form-topic">
            <Field name="description" validate={required}>
              {({ input, meta }) => (
                <>
                  <label className="tipo-title">Descripción:</label>
                  <textarea
                    {...input}
                    className="form-control tipo-description"
                    rows="4"
                    placeholder="Descripción"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="base-form txt-center">
            <div className="form-group div-form-topic">
              <Field name="image" validate={required}>
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Imagen del tema:</label>
                    <ImageUploader
                      {...input}
                      className="upload-image-topic"
                      buttonClassName="btn-upload-evaluation"
                      buttonText=""
                      imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                      maxFileSize={5242880}
                      withIcon={false}
                      withPreview={true}
                      fileSizeError="Excede el peso permitido"
                      fileTypeError="Extensión no permitida"
                      singleImage={true}
                      name="Imagen del tema"
                      label="Peso máximo 2mb, 200x200 px."
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="flex-column">
              <div className="form-group">
                <div>
                  <strong className="tipo-title">VALOR BASE:</strong>
                </div>
                <Field name="mounth_cost" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <input
                        {...input}
                        className="form-control monthly tipo-description"
                        type="number"
                        placeholder="Valor base"
                        disabled={user.is_admin ? false : true}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
                {"  "}
                <div className="cop tipo-description">COP</div>
              </div>
              <div className="form-group">
                <div>
                  <strong className="tipo-title">ESTADO:</strong>
                </div>
                <Field name="status_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <Select
                        {...input}
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={initialState.statusTopic}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="btn-actions-form">
            <input
              type="submit"
              value="Guardar"
              className="btn-orange-default"
            />
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreatTopic;
