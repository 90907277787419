import React, { useEffect, useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "../folder/Form.css";
import Score from "../folder/Score";
import TarjetDocument from "../folder/TarjetDocument";
import img_edit from "../../../../img_zeus/icons_library/BOTON-EDITAR-GENERAL.png";
import btn_edit from "../../../../img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import btn_delete from "../../../../img_zeus/icons_library/BOTON-ELIMINAR.png";
import imgError from "../../../../default.jpg";
import imgList from "../../../../img_zeus/list.png";
import Filter from "../folder/Filter";
import CreateFolder from "../folder/modals/CreateFolder";
import CreateDocument from "../documentForm";
import { Animated } from "react-animated-css";
import cloneDeep from "lodash/cloneDeep";
import DataTable from "react-data-table-component";
import $ from "jquery";
import {
  LibraryContext,
  STATUS_ERASER,
} from "../../../../contexts/library/LibraryContext";
import { SecurityContext } from "../../../../contexts/security/SecurityContext";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCog,
  faUsersCog,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faListOl,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";

import AdminTopic from "./modals/AdminTopic";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
const STATUS_LOADING = "LOADING";
const STATUS_NOT_LOADED = "NOT_LOADED";
const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
const STATUS_LOGGED_IN = "LOGGED_IN";
const STATUS_LOADED = "STATUS_LOADED";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLS = "xls";

const EXT_XLSX = "xlsx";
const initialState = {
  columns: { status: STATUS_NOT_LOADED, columns: [] },
  folderEdit: null,
  modal: false,
  modalCreateFolder: false,
  modalAdminTopic: false,
  document_id: null,
  toggleReference: {
    status: false,
    document_id: null,
  },
  item: {
    mounth_cost: "",
  },
  originalItem: {},
  searchDocument: "",
};

const Folder = ({ computedMatch }) => {
  const [
    {
      breadCrumbFolder,
      folders,
      filters,
      documents,
      meta,
      showData,
      editFolder,
      documentsWithOutRecursive,
      listLibrary,
    },
    {
      goTo,
      deleteFolder,
      createFolder,
      updateFolder,
      deleteDocument,
      checkSubscription,
      filterGeneral,
      fixedDocument,
      seteditFolder,
      moveDocumentToFolder,
      setlistLibrary,
    },
  ] = useContext(LibraryContext);
  const [{ user }, { can, isAdminTopic, isAdminForum,downloadDocument }] = useContext(
    SecurityContext
  );
  const [{}, { alertSuccess }] = useContext(AlertContext);
  let { params, url, path } = computedMatch;
  let { topic_id, folder_id } = params;
  let [state, setState] = useState(initialState);
  let [modalState, setModalState] = useState(initialState.modal);
  let [referenceState, setReferenceState] = useState(
    initialState.toggleReference
  );
  const [columns, setcolumns] = useState(initialState.columns);
  const [modalCreateFolder, setModalCreateFolder] = useState(
    initialState.modalCreateFolder
  );
  const [modalAdmin, setmodalAdmin] = useState(initialState.modalAdminTopic);
  let [documentState, setDocuments] = useState(initialState.document_id);
  let [searchDocument, setSearchDocument] = useState(
    initialState.searchDocument
  );

  const [inEdit, setinEdit] = useState(false);
  const [inFilter, setinFilter] = useState(false);

  let history = useHistory();
  let location = useLocation();

  let setField = (field) => (e) => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };

  useEffect(() => {
    goTo({ folder_id, topic_id });
  }, [topic_id, folder_id]);
  useEffect(() => {
    let columnsCommon = [
      {
        center: true,
        name: "TITULO",
        selector: "title",
        cell: (row) => <div className="cursor-action">{row.title}</div>,
        sortable: true,
      },
      {
        center: true,
        name: "PROPIETARIOS",
        selector: "persons",
        cell: (row) => (
          <>
            {row.persons.map((person) => {
              return <div className="cursor-action">{person.first_name}</div>;
            })}
          </>
        ),
        sortable: true,
      },
      {
        name: "GRATUITO",
        center: true,
        maxWidth: "200px",
        selector: "is_free",
        grow: 3,
        cell: (row) => JSON.stringify(row.is_free),
        sortable: true,
      },
      {
        name: "RUTA",
        center: true,
        maxWidth: "200px",
        selector: "path",
        grow: 3,
        cell: (row) => row.path,
        sortable: true,
      },
      {
        name: "CREACION",
        center: true,
        maxWidth: "200px",
        selector: "created_at",
        grow: 3,
        sortable: true,
      },
    ];
    setcolumns({
      status: STATUS_LOADED,
      columns: columnsCommon,
    });
  }, [documentsWithOutRecursive]);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateFolder(e.target.value, state.folderEdit);
      setState({ ...state, folderEdit: null });
    }
  };

  let fontSize = (nameTopic) => {
    if (nameTopic && nameTopic.length <= 13) {
      return "font-short";
    } else if (nameTopic && nameTopic.length <= 26) {
      return "font-medium";
    } else if (nameTopic && nameTopic.length <= 39) {
      return "font-long";
    } else {
      return "font-more-long";
    }
  };

  const setFieldSearch = (field) => (e) => {
    let body = e.target.value;
    setSearchDocument(body);
    filterGeneral(body);
  };
  const toggleModalFolder = () => {
    setModalCreateFolder(!modalCreateFolder);
  };
  const toggleModalAdminTopic = () => {
    setmodalAdmin(!modalAdmin);
  };
  
  const decisionSubscription = (docs) => {
    let viewDocument = false;
    if (!docs.is_free) {
      if (isAdminTopic(meta.path.topic_id) || user.is_admin) {
        viewDocument = true;
      } else if (!checkSubscription()) {
        viewDocument = false;
      } else {
        viewDocument = true;
      }
    } else {
      viewDocument = true;
    }
    if (viewDocument) {
      if (
        docs.type === EXT_DOCX ||
        docs.type === EXT_DOC ||
        docs.type === EXT_XLSX ||
        docs.type === EXT_XLS
      ) {
        downloadDocument(
          process.env.REACT_APP_API_HOST + "documents/" + docs.name
        );

        Swal.fire({
          title: "Documento de descarga",
          text: "¿Deseas añadir este documento a favoritos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#df8c37",
          cancelButtonColor: "#171e27",
          cancelButtonText: "No, gracias",
          confirmButtonText: "Si, añadir",
        }).then((result) => {
          if (result.value) {
            fixedDocument(docs.id);
          } else {
          }
        });
      } else {
        history.push(`/app/library/document/${docs.id}`);
      }
    } else {
      history.push(`/app/library/topic/${topic_id}/payment`);
    }
  };
  const mouseTarjet = (document_id) => {
    if (checkPermissionTopicSubscription()) {
      setReferenceState({
        ...referenceState,
        document_id: document_id,
      });
    }
  };
  const checkPermissionTopicSubscription = () => {
    if (isAdminTopic(meta.path.topic_id)) {
      return true;
    } else if (user.is_admin) {
      return true;
    } else if (checkSubscription()) {
      return true;
    } else {
      return false;
    }
  };
  const checkPermissionForumSubscription = () => {
    if (isAdminForum(meta.path.topic_id)) {
      return true;
    } else if (user.is_admin) {
      return true;
    } else if (checkSubscription()) {
      return true;
    } else {
      return false;
    }
  };
  const getTextPartial = (text) => {
    var res = text.split(" ");
    let reference = "";
    let range = 25;
    for (let r = 0; r < res.length; r++) {
      const word = res[r];
      if (word.length > range) {
        reference = reference + " " + word.substr(0, range) + "...";
      } else {
        reference = reference + " " + word;
      }
    }

    return reference.substr(0, 160);
  };
  const onDragStart = (event, docs) => {
    event.dataTransfer.setData("docs", JSON.stringify(docs));
  };
  const onDragOver = (event, fold) => {
    event.preventDefault();
  };
  const onDrop = (event, fold) => {
    let itemDrag = JSON.parse(event.dataTransfer.getData("docs"));
    let itemDrop = fold;
    moveDocumentToFolder(itemDrag, itemDrop.id);
  };
  const onDropBread = (event, folder_id) => {
    let itemDrag = JSON.parse(event.dataTransfer.getData("docs"));
    moveDocumentToFolder(itemDrag, folder_id);
  };
  return (
    <>
      {modalCreateFolder && (
        <CreateFolder toggleModalFolder={toggleModalFolder} />
      )}

      {modalState && (
        <div className="back-document">
          <div className="modal-document">
            <div className="header-modal-document">
              <div
                className="salir-topic"
                onClick={() => {
                  setModalState(false);
                  setDocuments(null);
                }}
              ></div>
              {documentState !== null && (
                <h3 className="title-form-topic tipo-description">
                  Editar Documento
                </h3>
              )}{" "}
              {documentState === null && (
                <h3 className="title-form-topic tipo-description">
                  Crear Documento
                </h3>
              )}
            </div>

            <CreateDocument
              modalState={modalState}
              setModalState={setModalState}
              documentState={documentState}
            ></CreateDocument>
          </div>
        </div>
      )}
      <div className="general-documents-folder">
        <div className="w-100 flex container-breadcrumb-folder">
          <nav aria-label="breadcrumb" className="flex-1 cursor-action">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/app/library/topics" className="tipo-title">
                  Temas
                </Link>
              </li>

              {breadCrumbFolder.map((bread, index) => {
                return (
                  <li
                    key={index}
                    className="breadcrumb-item"
                    aria-current="page"
                  >
                    <Link
                      onDragOver={(event) => onDragOver(event, index)}
                      onDrop={(event) =>
                        onDropBread(
                          event,
                          bread.type == "Folder" ? bread.id : null
                        )
                      }
                      to={`${
                        bread.type == "Folder"
                          ? `/app/library/topic/${topic_id}/folder/${bread.id}`
                          : `/app/library/topic/${bread.id}`
                      }`}
                      className={`${
                        breadCrumbFolder.length == index + 1 ? "orange" : ""
                      } tipo-description`}
                    >
                      {bread.name}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </nav>
          {user.is_admin && (
            <div
              onClick={() => setlistLibrary(!listLibrary)}
              className="list-icon-library-container"
            >
              <img title="lista" width="23" src={imgList} alt="" />
            </div>
          )}
          {(isAdminTopic(meta.path.topic_id) || user.is_admin) && (
            <div className="h-100 justify-end flex content-create-folder">
              <div
                onClick={() => {
                  setModalState(true);
                }}
                title="añadir documento"
                className="cursor-action icon-new icon-new-document"
              ></div>
              <div
                onClick={() => toggleModalFolder()}
                title="añadir carpeta"
                className="cursor-action icon-new icon-new-folder"
              ></div>
            </div>
          )}
          <div></div>
        </div>
        <div className="flex-h container-content-folders">
          <div className="container-topic-flex w-100">
            <div className="content-title-filters">
              <div className="flex-1 flex-h justify-betwen">
                <div
                  className="container-title-topic-bread"
                  onDragOver={(event) => onDragOver(event, null)}
                  onDrop={(event) => onDropBread(event, null)}
                >
                  <img
                    className="img-topic-bread-documents"
                    src={
                      meta.topic.image &&
                      process.env.REACT_APP_API_HOST +
                        "documents/" +
                        meta.topic.image.name
                    }
                    onError={(e) => {
                      e.target.src = imgError;
                    }}
                  />
                  <p
                    className={`title-topic-folder ${fontSize(
                      meta.topic.name
                    )}`}
                  >
                    <strong>{meta.topic.name}</strong>
                  </p>
                </div>

                <Link
                  to={
                    !checkPermissionForumSubscription()
                      ? `/app/library/topic/${meta.topic.id}/payment`
                      : `/app/library/topic/${meta.topic.id}/forum`
                  }
                >
                  <div className="btn-forum cursor-action "></div>
                </Link>
              </div>
              <div className="container-general-search flex-h">
                {(user.is_admin || can("manage_library_administrators")) &&
                  !modalAdmin && (
                    <FontAwesomeIcon
                      onClick={() => {
                        toggleModalAdminTopic();
                      }}
                      className="cursor-action btn-admin-topic"
                      icon={faUsersCog}
                    />
                  )}
                {modalAdmin && (
                  <AdminTopic
                    topic={meta.topic && meta.topic}
                    toggleModalAdminTopic={toggleModalAdminTopic}
                  />
                )}
                {!inFilter && (
                  <div
                    onClick={() => {
                      setinFilter(!inFilter);
                    }}
                    className="btn-filters  cursor-action"
                  ></div>
                )}
                &nbsp;&nbsp;
                <div className="container-search-folder">
                  <input
                    value={searchDocument}
                    onChange={setFieldSearch()}
                    type="text"
                    placeholder="Buscar"
                  />
                </div>
              </div>
            </div>

            <div className="content-gray-documents ">
              <div className="flex justify-end ">
                {(isAdminTopic(meta.path.topic_id) || user.is_admin) && (
                  <img
                    onClick={() => setinEdit(!inEdit)}
                    src={img_edit}
                    className="btn-edit-content cursor-action flex "
                  />
                )}
              </div>
              {!listLibrary && (
                <>
                  <div className="flex flex-wrap ">
                    <>
                      {folders.status === STATUS_LOADED && (
                        <>
                          {folders.elements.map((fold) => {
                            return (
                              <div
                                className={`${
                                  !showData.folders ? "hidden" : ""
                                } container-folder `}
                                key={fold.id}
                              >
                                <div className="w-100">
                                  <div className="w-100" key={fold.id}>
                                    <Link
                                      to={
                                        !fold.is_free &&
                                        !checkPermissionTopicSubscription()
                                          ? `/app/library/topic/${topic_id}/payment`
                                          : `/app/library/topic/${topic_id}/folder/${fold.id}`
                                      }
                                      onClick={() => {
                                        if (
                                          checkPermissionTopicSubscription()
                                        ) {
                                          goTo({
                                            topic_id,
                                            folder_id: fold.id,
                                          });
                                        }
                                      }}
                                    >
                                      {" "}
                                      <div>
                                        <div
                                          onDragOver={(event) =>
                                            onDragOver(event, fold)
                                          }
                                          onDrop={(event) =>
                                            onDrop(event, fold)
                                          }
                                          className={`cursor-action img-fold  flex-1 ${
                                            !fold.is_free
                                              ? "img-fold-premium"
                                              : "img-fold-free"
                                          } ${
                                            fold.status_id === STATUS_ERASER
                                              ? "gray-scale"
                                              : ""
                                          }`}
                                        ></div>
                                      </div>
                                    </Link>
                                    <div className="w-100">
                                      <p className="txt-center folder-name">
                                        {fold.name}
                                      </p>

                                      {inEdit && (
                                        <div className="flex justify-center">
                                          {user.is_admin && (
                                            <div
                                              onClick={() => {
                                                checkDelete(
                                                  "¿Realmente deseas eliminar esta carpeta?",
                                                  `deleteFolder(${fold.id})`
                                                );
                                              }}
                                              className="cursor-action btns-icon-document btn-delete-document btn-delete-black background-simple"
                                            />
                                          )}
                                          &nbsp;
                                          {(isAdminTopic(meta.path.topic_id) ||
                                            user.is_admin) && (
                                            <div
                                              onClick={() => {
                                                seteditFolder({
                                                  id: cloneDeep(fold.id),
                                                  name: cloneDeep(fold.name),
                                                  statu_id: cloneDeep(
                                                    fold.status_id
                                                  ),
                                                  is_free: cloneDeep(
                                                    fold.is_free
                                                  ),
                                                });
                                                setModalCreateFolder(true);
                                              }}
                                              className="cursor-action btns-icon-document btn-edit-document btn-edit-black background-simple"
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {!folders.elements[0] && (
                            <div className="w-100 not-files-document txt-center">
                              {/*  NO HAY CARPETAS */}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>
                  <div className="container-general-documents w-100">
                    {folders.status === STATUS_LOADED && (
                      <>
                        {documents.elements.map((docs) => {
                          return (
                            <div
                              key={docs.id}
                              className={`${
                                !showData.documents ? "hidden" : ""
                              } container-document-tarjet`}
                            >
                              <div className="medida-tarjet-document">
                                <>
                                  <div
                                    onDragStart={(event) =>
                                      onDragStart(event, docs)
                                    }
                                    draggable
                                    onMouseEnter={() => mouseTarjet(docs.id)}
                                    onMouseLeave={() => mouseTarjet(null)}
                                    onClick={() => {
                                      decisionSubscription(docs);
                                    }}
                                    className={`${
                                      docs.status_id === STATUS_ERASER
                                        ? "gray-scale"
                                        : ""
                                    } bg-white  cursor-action h-100 w-100 container-margin-document flex-1`}
                                  >
                                    {!docs.is_free &&
                                      !checkPermissionTopicSubscription() && (
                                        <div className=" filter-no-free-no-subscribe">
                                          <div className="background-simple icon-premium-no-payment"></div>
                                        </div>
                                      )}
                                    {referenceState.document_id == docs.id && (
                                      <Animated
                                        onMouseEnter={() =>
                                          mouseTarjet(docs.id)
                                        }
                                        onMouseLeave={() => mouseTarjet(null)}
                                        animationInDuration={500}
                                        animationIn="fadeIn"
                                        animationOut="fadeOut"
                                        isVisible={true}
                                        className="tipo-description txt-center w-100 h-100 refrences-animate-document"
                                      >
                                        <p>{getTextPartial(docs.references)}</p>
                                      </Animated>
                                    )}

                                    <TarjetDocument docs={docs} />
                                  </div>
                                </>
                                <div className="content-button-edit-document w-100">
                                  {inEdit && (
                                    <div className="flex justify-center">
                                      {user.is_admin && (
                                        <div
                                          onClick={() => {
                                            checkDelete(
                                              "¿Realmente deseas eliminar  este documento?",
                                              `deleteDocument(${docs.id})`
                                            );
                                          }}
                                          className="cursor-action btns-icon-document btn-delete-black background-simple"
                                        />
                                      )}
                                      &nbsp;
                                      {(isAdminTopic(meta.path.topic_id) ||
                                        user.is_admin) && (
                                        <div
                                          onClick={() => {
                                            setModalState({
                                              ...modalState,
                                              modal: true,
                                            });
                                            setDocuments(docs.id);
                                          }}
                                          className="cursor-action btns-icon-document btn-edit-black background-simple"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {!documents.elements[0] && (
                          <div className="not-files-document">
                            {/*   NO HAY DOCUMENTOS */}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              {user.is_admin && listLibrary && (
                <>
                  {columns.status === STATUS_LOADED && (
                    <DataTable
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                      noDataComponent={"no hay datos"}
                      striped={true}
                      columns={columns.columns}
                      responsive={true}
                      className=" tipo-description"
                      data={documentsWithOutRecursive}
                      pagination={true}
                      paginationComponentOptions={{
                        rowsPerPageText: "Lineas:",
                        rangeSeparatorText: "de",
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "Todas",
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div
            className={`general-container-filter flex-1 ${
              inFilter ? "" : "hidden"
            }`}
          >
            <div className="header-filter">
              <p className="flex-1 tipo-boton">Filtros</p>
              <div className="flex-1">
                <div
                  onClick={() => {
                    setinFilter(!inFilter);
                  }}
                  className="close-filter-btn cursor-action"
                >
                  <span>x</span>
                </div>
              </div>
            </div>
            <Filter />
          </div>
        </div>
      </div>

      {/* ///////////////////////DEVICE MOBILE ///////////////////////////*/}

      <div className="container-general-documents-mobile hidden flex-column bg-white">
        <div className="flex container-filters-search-folders">
          <div className="flex-1 flex">
            <div
              onClick={() => {
                setinFilter(!inFilter);
              }}
              className=" bt-filters-mobile-library white"
            >
              <FontAwesomeIcon icon={inFilter ? faChevronUp : faChevronDown} />
              &nbsp; Filtros
            </div>
          </div>
          <div className="container-search-folder-library hidden flex-1">
            <input
              placeholder="Escribe aquí"
              className="tipo-cursiva felx-1"
              type="text"
              value={searchDocument}
              onChange={setFieldSearch()}
            />
            <div className="background-simple btn-searc-library"></div>
          </div>
        </div>
        <div>
          <nav
            aria-label="breadcrumb"
            className="flex-1 cursor-action nav-mobile-breadcrumb-folder"
          >
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <Link to="/app/library/topics" className="tipo-title">
                  Temas
                </Link>
              </li>
              {meta.path.folder_id == null && meta.path.document_id == null && (
                <li className="breadcrumb-item">
                  <Link
                    to={`/app/library/topic/${meta.path.topic_id}`}
                    className="orange"
                  >
                    {meta.topic.name}
                  </Link>
                </li>
              )}
              {breadCrumbFolder.map((bread, index) => {
                return (
                  <li
                    key={index}
                    className="breadcrumb-item"
                    aria-current="page"
                  >
                    <Link
                      to={`${
                        bread.type == "Folder"
                          ? `/app/library/topic/${topic_id}/folder/${bread.id}`
                          : `/app/library/topic/${bread.id}`
                      }`}
                      className={`${
                        breadCrumbFolder.length == index + 1 ? "orange" : ""
                      } tipo-description`}
                    >
                      {bread.name}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </nav>
        </div>
        <div className="flex container-foldes-mobile flex-column w-100">
          <div className="w-100 flex flex-1 container-folders-mobile-scroll">
            {folders.status === STATUS_LOADED && (
              <>
                {folders.elements.map((fold) => {
                  return (
                    <div
                      className={`${
                        !showData.folders ? "hidden" : ""
                      } container-folder `}
                      key={fold.id}
                    >
                      <div className="w-100" key={fold.id}>
                        <Link
                          to={
                            !fold.is_free && !checkPermissionTopicSubscription()
                              ? `/app/library/topic/${topic_id}/payment`
                              : `/app/library/topic/${topic_id}/folder/${fold.id}`
                          }
                          onClick={() => {
                            if (checkPermissionTopicSubscription()) {
                              goTo({ topic_id, folder_id: fold.id });
                            }
                          }}
                        >
                          {" "}
                          <div>
                            <div
                              className={`cursor-action img-fold  flex-1 ${
                                !fold.is_free
                                  ? "img-fold-premium"
                                  : "img-fold-free"
                              }`}
                            ></div>
                          </div>
                        </Link>
                        <div className="w-100">
                          <p className="txt-center folder-name">
                            {state.folderEdit !== fold.id && fold.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {!folders.elements[0] && (
                  <div className="w-100 not-files-document txt-center">
                    {/*   NO HAY CARPETAS */}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex">
            <div className="flex margin-auto container-points-slider-mobile">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div className="bg-white flex flex-column">
          {folders.status === STATUS_LOADED && (
            <>
              {documents.elements.map((docs) => {
                return (
                  <div
                    key={docs.id}
                    className={`${!showData.documents ? "hidden" : ""} ${
                      docs.status_id === STATUS_ERASER ? "gray-scale" : ""
                    } container-document-tarjet`}
                  >
                    <div className="medida-tarjet-document">
                      <>
                        <div
                          onMouseEnter={() => mouseTarjet(docs.id)}
                          onMouseLeave={() => mouseTarjet(null)}
                          onClick={() => {
                            decisionSubscription(docs);
                          }}
                          className={`bg-white  cursor-action h-100 w-100 container-margin-document flex-1`}
                        >
                          {!docs.is_free &&
                            !checkPermissionTopicSubscription() && (
                              <div className=" filter-no-free-no-subscribe">
                                <div className="background-simple icon-premium-no-payment"></div>
                              </div>
                            )}
                          {referenceState.document_id == docs.id && (
                            <Animated
                              onMouseEnter={() => mouseTarjet(docs.id)}
                              onMouseLeave={() => mouseTarjet(null)}
                              animationInDuration={500}
                              animationIn="fadeIn"
                              animationOut="fadeOut"
                              isVisible={true}
                              className="tipo-description txt-center w-100 h-100 refrences-animate-document"
                            >
                              <p>{getTextPartial(docs.references)}</p>
                            </Animated>
                          )}

                          <TarjetDocument docs={docs} />
                        </div>
                      </>
                    </div>
                  </div>
                );
              })}

              {!documents.elements[0] && (
                <div className="not-files-document">
                  {/* NO HAY DOCUMENTOS */}
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={`general-container-filter flex-1 ${
            inFilter ? "" : "hidden"
          }`}
        >
          <Filter />
        </div>
      </div>
    </>
  );
};

export default Folder;
