import React, { useContext } from "react";
import { lists } from "./listjson.json";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import "./List.css";
const List = ({ mobile, settoogleHelp, toogleHelp }) => {
  let history = useHistory();
  const getLinkContact = () => {
    if(!mobile){
      settoogleHelp(!toogleHelp);
    }
    history.push("/app/help/contact");
  };
  return (
    <div className="bg-white">
      <div className="flex">
        <div className="flex flex-1 container-search-help-general">
          <input
            className="flex tipo-cursiva"
            placeholder="Escribe aquí"
            type="text"
            name=""
            id=""
          />
          <div className="icon-search-help background-simple"></div>
        </div>
      </div>
      <div>
        <div className="background-simple icon-help-mobile-list"></div>

        <SubItems listsArray={lists} mobile={mobile} first={true} />
        <div className="flex">
          <div
            className="flex container-btn-contact"
            onClick={() => getLinkContact()}
          >
            <div className="background-simple img-contact-us"></div>{" "}
            &nbsp;Contáctenos
          </div>
        </div>
      </div>
    </div>
  );
};
const SubItems = ({ listsArray, first, mobile }) => {
  let history = useHistory();
  const [{ toogleHelp }, { settoogleHelp }] = useContext(AlertContext);
  const checkLink = (list) => {
    if (mobile && list.linkMobile != "") {
      history.push(list.linkMobile);
    }
    if (!mobile && list.linkDesktop != "") {
      console.log(list.linkDesktop);

      history.push(list.linkDesktop);
    }

    if (!mobile) {
      settoogleHelp(!toogleHelp);
    }
  };
  return (
    <div>
      {listsArray.map((list, key) => {
        return (
          <ol className="ol-help" key={key}>
            <span
              onClick={() => checkLink(list)}
              className={`cursor-action tipo-description ${
                (mobile && list.linkMobile) != ""
                  ? "orange"
                  : (!mobile && list.linkDesktop) != ""
                  ? "orange"
                  : ""
              }`}
            >
              {list.display}
            </span>
            {list.lists.length > 0 && (
              <li>
                <SubItems
                  mobile={mobile}
                  listsArray={list.lists}
                  first={false}
                />
              </li>
            )}
          </ol>
        );
      })}
    </div>
  );
};
export default List;
