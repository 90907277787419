import React, { useEffect, useState } from "react";
import "./General.css";
import CreateProgramAuditGeneral from "./general/CreateProgram";
import AuditProgramGeneral from "./general/AuditProgram";
import CreateAuditCompanyGeneral from "./general/CreateAuditCompany";
import PlanAuditGeneral from "./general/PlanAudit";
import ExecutedAuditGeneral from "./general/ExecutedAudit";
import EvidenceRemoteGeneral from "./general/EvidenceRemote";
import FinishAuditGeneral from "./general/FinishAudit";

const CREATE_PROGRAM = "create_program";
const AUDIT_PROGRAM = "audit_program";
const AUDIT_COMPANY = "audit_company";
const PLAN_AUDIT = "plan_audit";
const EXECUTED_AUDIT = "executed_audit";
const EVIDENCE_REMOTE = "evidence_remote";
const FINISH_AUDIT = "finish_audit";
const initialState = {
  modal: {
    title: "",
    route: "",
  },
};
const General = ({ computedMatch: { params } }) => {
  let { module } = params;
  const [modalModule, setmodalModule] = useState(null);

  useEffect(() => {
    if (
      module == CREATE_PROGRAM ||
      module == AUDIT_PROGRAM ||
      module == AUDIT_COMPANY ||
      module == PLAN_AUDIT ||
      module == EXECUTED_AUDIT ||
      module == EVIDENCE_REMOTE ||
      module == FINISH_AUDIT
    ) {
      setmodalModule(module);
    }
  }, [module]);
  return (
    <div className="flex w-100 container-general-help-desktop">
      {modalModule && (
        <div className="back-document">
          <div className="bg-white container-zoom-help-desktop shadow-tarjet flex-column">
            <div className="header-general-modal-help flex">
              <div>
                {" "}
                {modalModule == CREATE_PROGRAM &&
                  "Crear programa de auditorías"}
                {modalModule == AUDIT_PROGRAM && "Auditoría de programa"}
                {modalModule == AUDIT_COMPANY &&
                  "Auditorías Independiente de Empresa"}
                {modalModule == PLAN_AUDIT && "Planeación de Auditoría"}
                {modalModule == EXECUTED_AUDIT && "Ejecución de Auditoría"}
                {modalModule == EVIDENCE_REMOTE && "Cargue evidencias remotas"}
                {modalModule == FINISH_AUDIT && "Finalización de Auditoría"}
              </div>
              <span
                onClick={() => setmodalModule(null)}
                className="background-simple btn-action-minus-help-desktop"
              ></span>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="flex-column bg-white container-bubble-desktop">
                {modalModule == CREATE_PROGRAM && (
                  <>
                    <div className="txt-center bg-white container-required-help-dektop more-description-help">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Realice seguimiento a todas las auditorias programadas
                        en una vista:<br></br>
                        Estados, Avance, diagramas de Gantt, Auditores Líderes.
                        <br></br>
                        Requisito: la empresa debe contar con suscripción para
                        auditorias.
                      </p>
                    </div>
                    <CreateProgramAuditGeneral />
                  </>
                )}
                {modalModule == AUDIT_PROGRAM && (
                  <>
                    <div className="txt-center bg-white container-required-help-dektop more-description-help">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Gestionada y programada entre la empresa y el auditor
                        líder (interno o externo).<br></br> Asigne actividades
                        al equipo auditor y a los auditados.<br></br> Lleve toda
                        la trazabilidad de la auditoria desde la planeación,
                        aprobación, ejecución y reporte final, garantizando la
                        seguridad y confidencialidad de la información de la
                        auditoria entre las partes.
                      </p>
                    </div>

                    <AuditProgramGeneral />
                  </>
                )}
                {modalModule == AUDIT_COMPANY && (
                  <>
                    <div className="txt-center bg-white container-required-help-dektop more-description-help">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Gestione sus auditorías con trazabilidad y acorde a
                        normas de auditoria Incluya auditores de apoyo y
                        evaluados de la empresa a auditar.<br></br> Realice la
                        planeación detallada, ejecución y reporte final con las
                        funcionalidades de la plataforma y bajo ISO 19011:2018
                      </p>
                    </div>
                    <CreateAuditCompanyGeneral />
                  </>
                )}
                {modalModule == PLAN_AUDIT && (
                  <>
                    <div className="txt-center bg-white container-required-help-dektop">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Gestione y administre la auditoría incluyendo
                        generalidades, equipo de trabajo, actividades,
                        evaluados, procesos, numerales a auditar y genere su
                        plan de auditoria.<br></br>
                        Requisito: La empresa debe tener definidos sus procesos,
                        responsables y participantes.
                      </p>
                    </div>
                    <PlanAuditGeneral />
                  </>
                )}
                {modalModule == EXECUTED_AUDIT && (
                  <>
                    {" "}
                    <div className="txt-center bg-white container-required-help-dektop">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Ejecute la auditoría, utilice la lista de verificación
                        desde su celular o computador, capture evidencias, tome
                        notas y observaciones.
                      </p>
                    </div>
                    <ExecutedAuditGeneral />
                  </>
                )}
                {modalModule == EVIDENCE_REMOTE && (
                  <>
                    <div className="txt-center bg-white container-required-help-dektop">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Cargue evidencias directamente a su lista de
                        verificación.
                        <br></br>
                        Habilite al auditado la carga de evidencias remotas, en
                        los ítems a evaluar.
                      </p>
                    </div>
                    <EvidenceRemoteGeneral />
                  </>
                )}
                {modalModule == FINISH_AUDIT && (
                  <>
                    {" "}
                    <div className="txt-center bg-white container-required-help-dektop">
                      <p className="tipo-cursiva orange">Descripción:</p>
                      <p className="tipo-description">
                        Asegure el cumplimiento de evaluación de todos los
                        requisitos y las actividades programadas, ajuste y
                        entregue el reporte final de auditoría.
                      </p>
                    </div>
                    <FinishAuditGeneral />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex-1 mask-help-general">
        <div className="flex">
          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Realice seguimiento a todas las auditorias programadas en una
                vista:<br></br>
                Estados, Avance, diagramas de Gantt, Auditores Líderes.<br></br>
                Requisito: la empresa debe contar con suscripción para
                auditorias.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(CREATE_PROGRAM)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Crear programa de auditorías
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <CreateProgramAuditGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Gestionada y programada entre la empresa y el auditor líder
                (interno o externo).<br></br> Asigne actividades al equipo
                auditor y a los auditados.<br></br> Lleve toda la trazabilidad
                de la auditoria desde la planeación, aprobación, ejecución y
                reporte final, garantizando la seguridad y confidencialidad de
                la información de la auditoria entre las partes.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(AUDIT_PROGRAM)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Auditoría de programa
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <AuditProgramGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripcion:</p>
              <p className="tipo-description">
                Gestione sus auditorías con trazabilidad y acorde a normas de
                auditoria Incluya auditores de apoyo y evaluados de la empresa a
                auditar.<br></br> Realice la planeación detallada, ejecución y
                reporte final con las funcionalidades de la plataforma y bajo
                ISO 19011:2018
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(AUDIT_COMPANY)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop text-header-help-desktop-long">
                  Auditorías Independiente de Empresa
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <CreateAuditCompanyGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Gestione y administre la auditoría incluyendo generalidades,
                equipo de trabajo, actividades, evaluados, procesos, numerales a
                auditar y genere su plan de auditoria.<br></br>
                Requisito: La empresa debe tener definidos sus procesos,
                responsables y participantes.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(PLAN_AUDIT)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Planeación de auditoría
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <PlanAuditGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Ejecute la auditoría, utilice la lista de verificación desde su
                celular o computador, capture evidencias, tome notas y
                observaciones.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(EXECUTED_AUDIT)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Ejecución de Auditoría
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <ExecutedAuditGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Cargue evidencias directamente a su lista de verificación.
                <br></br>
                Habilite al auditado la carga de evidencias remotas, en los
                ítems a evaluar.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(EVIDENCE_REMOTE)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Cargue evidencias remotas
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <EvidenceRemoteGeneral />
              </div>
            </div>
          </div>

          <div className="container-tarjet-help-desktop flex-column">
            <div className="txt-center bg-white container-required-help-dektop">
              <p className="tipo-cursiva orange">Descripción:</p>
              <p className="tipo-description">
                Asegure el cumplimiento de evaluación de todos los requisitos y
                las actividades programadas, ajuste y entregue el reporte final
                de auditoría.
              </p>
            </div>
            <div className="tarjet-desktop-help shadow-tarjet">
              <div className="white txt-center header-tarjet-dektop-help">
                <div
                  onClick={() => setmodalModule(FINISH_AUDIT)}
                  className="background-simple btn-action-more-help-desktop"
                ></div>
                <div className="text-header-help-desktop">
                  Finalización de Auditoría
                </div>{" "}
              </div>

              <div className="flex-column bg-white container-bubble-desktop">
                <FinishAuditGeneral />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex">
        <div className="margin-auto background-simple img-left-help-desktop"></div>
      </div>
    </div>
  );
};

export default General;
