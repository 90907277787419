import React, { useState, useEffect, useContext } from "react";
import {
  AuditPlanIndexContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanIndexContext";
import {
  SecurityContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/SecurityContext";
import "../program/Index.css";
import "./CreateAuditAudit.css";
import moment from "moment";
import { Field, Form } from "react-final-form";
import Select, { components } from "react-select";
import DateTimePicker from "react-datetime-picker";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
registerLocale("es", es);

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    title: "",
    standard_id: "",
    template_id: "",
    company_id: "",
    init_date: new Date(),
    end_date: new Date(),
  },
  originalItem: {},
};

const CreateAudit = ({ state, setState, audit_id }) => {
  const [
    {
      standard,
      audit,
      company,
      template,
      suscriptionsAudit,
      effectiveSelected,
    },
    { updateAudit, createAudit, setCompany, seteffectiveSelected },
  ] = useContext(AuditPlanIndexContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);
  let history = useHistory();
  let [templates, settemplates] = useState([]);
  const [haveSuscriptions, sethaveSuscriptions] = useState(false);
  const [standardSelect, setstandardSelect] = useState({});
  const [templateSelect, settemplateSelect] = useState({ id: null });
  useEffect(() => {
    if (audit_id && audit.status === STATUS_LOADED) {
      let el = audit.elements.filter((e) => e.id === audit_id)[0];

      setStateCreate({
        ...stateCreate,
        item: {
          title: el.title,
          standard_id: el.standard,
          company_id: el.company,
          init_date: new Date(el.init_date),
          end_date: new Date(el.end_date),
        },
        originalItem: el,
        status: STATUS_EDIT,
      });
      settemplateSelect(el.template);
    }
  }, [audit_id, audit]);
  useEffect(() => {
    if (
      stateCreate.status !== STATUS_EDIT &&
      suscriptionsAudit.elements.filter((e) => e.is_personal).length === 1
    ) {
      seteffectiveSelected(
        suscriptionsAudit.elements.filter((e) => e.is_personal)[0].id
      );
    }
    if (suscriptionsAudit.elements.filter((e) => e.is_personal).length > 0) {
      sethaveSuscriptions(true);
    }
  }, [stateCreate]);
  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      updateAudit(formObj, stateCreate.originalItem.id);
    } else {
      createAudit(formObj);
    }
  };
  useEffect(() => {
    if (template.status === STATUS_LOADED && standardSelect.id) {
      let filters = template.elements.filter(
        (t) => t.standard_id == standardSelect.id
      );
      if (audit.status === STATUS_LOADED) {
        let el = audit.elements.filter((e) => e.id === audit_id)[0];
        if (el) {
          filters = template.elements.filter(
            (t) => t.standard_id == el.standard_id
          );
        }
      } else {
        settemplateSelect({});
      }
      settemplates(filters);
    }
  }, [standardSelect, template]);
  const changeStandard = (form) => {
    if (form.standard_id != "") {
      setstandardSelect(form.standard_id);
    }
  };
  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  return (
    <Form
      onSubmit={(formObj) => {
        if (formObj.init_date > formObj.end_date) {
          alertWarning("la fecha de inicio no debe ser menor a la fecha fin");
          return false;
        }
        formObj.template_id = templateSelect;
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="form-audit flex-column">
          <div className="flex">
            <div className="flex-1">
              {stateCreate.status !== STATUS_EDIT && !haveSuscriptions && (
                <div
                  class="alert alert-warning form-group txtarea-form-audit"
                  role="alert"
                >
                  Sin suscripciones activas
                  <br />
                  <Link
                    className="link-default"
                    title="Comprar suscripción personal para auditorías"
                    to={`/app/library/topic/billing/suscription/audit/true`}
                  >
                    Adquirir suscripción personal
                  </Link>
                </div>
              )}

              <div className="form-group txtarea-form-audit">
                <Field name="title" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Nombre:</label>
                      <input
                        {...input}
                        disabled={
                          stateCreate.status !== STATUS_EDIT &&
                          !haveSuscriptions
                        }
                        type="text"
                        className="form-control tipo-description"
                        placeholder="Nombre de la auditoría"
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="company_id" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <div className="flex">
                        <label className="tipo-title flex-1">Empresa:</label>

                        <div className="flex justify-end flex-1">
                          <div
                            onClick={() => window.open(`/app/companies`)}
                            className="cursor-action white bg-orange btn-add-company-on-audit"
                          >
                            {" "}
                            crear empresa
                          </div>
                        </div>
                      </div>

                      <Select
                        {...input}
                        isDisabled={
                          stateCreate.status !== STATUS_EDIT &&
                          !haveSuscriptions
                        }
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={company.elements.filter(
                          (e) => e.contact_person_id === user.id
                        )}
                      />
                      <div className="flex space-betwen">
                        <FontAwesomeIcon
                          onClick={() =>
                            setCompany({
                              ...company,
                              status: STATUS_NOT_LOADED,
                            })
                          }
                          title="Refrescar empresas"
                          className="orange cursor-action"
                          icon={faSyncAlt}
                        />
                        &nbsp;
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field
                  name="standard_id"
                  inputOnChange={changeStandard(values)}
                  validate={required}
                >
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Norma:</label>
                      {stateCreate.status === STATUS_EDIT && (
                        <Select
                          {...input}
                          className="tipo-description"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.short_name}`}
                          getOptionValue={(option) => option.id}
                          options={standard.elements}
                          isDisabled
                        />
                      )}
                      {stateCreate.status === STATUS_NEW && (
                        <Select
                          {...input}
                          isDisabled={!haveSuscriptions}
                          className="tipo-description"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.short_name}`}
                          getOptionValue={(option) => option.id}
                          options={standard.elements}
                        />
                      )}
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <label className="tipo-title">Plantilla:</label>

                <Select
                  isDisabled={
                    stateCreate.status !== STATUS_EDIT && !haveSuscriptions
                  }
                  value={templateSelect}
                  onChange={(e) => settemplateSelect(e ? e : { id: null })}
                  className="tipo-description"
                  placeholder={"Seleccione una opción"}
                  getOptionLabel={(option) => `${option.title}`}
                  getOptionValue={(option) => option.id}
                  options={templates}
                  isClearable={true}
                />
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="init_date" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Fecha Inicio:</label>
                      <DateTimePicker
                       disabled={
                        stateCreate.status !== STATUS_EDIT &&
                        !haveSuscriptions
                      }
                        className="form-control tipo-description"
                        format="y-MM-dd"
                        {...input}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group txtarea-form-audit">
                <Field name="end_date" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Fecha Fin:</label>
                      <DateTimePicker
                       disabled={
                        stateCreate.status !== STATUS_EDIT &&
                        !haveSuscriptions
                      }
                        className="form-control tipo-description"
                        format="y-MM-dd"
                        minDate={new Date(values.init_date)}
                        {...input}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
            </div>
            {stateCreate.status !== STATUS_EDIT &&
              suscriptionsAudit.elements.filter((e) => e.is_personal).length >
                0 && (
                <>
                  <div className="flex-1">
                    <label className="tipo-title">Selecccione un paquete</label>
                    {suscriptionsAudit.elements
                      .filter((e) => e.is_personal)
                      .map((effective) => {
                        return (
                          <div
                            className={`flex cursor-action g-module-audit form-group txtarea-form-audit effective-modal-audit ${
                              effective.id == effectiveSelected
                                ? "effective-active"
                                : ""
                            }`}
                            key={effective.id}
                            onClick={() => seteffectiveSelected(effective.id)}
                          >
                            <div className="flex-1">
                              {" "}
                              <div className="flex">
                                <label className="tipo-title txt-capitalize">
                                  {effective.service.name}
                                </label>
                                &nbsp;
                                <label htmlFor="">
                                  {effective.quantity}/
                                  {effective.consumers.length}
                                </label>
                              </div>
                              <div className="flex">
                                {effective.childs_effectives.map((child) => {
                                  return (
                                    <>
                                      <label className="tipo-title txt-capitalize">
                                        {child.service.name}
                                      </label>
                                      &nbsp;
                                      <label htmlFor="">{child.quantity}</label>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="flex-1">
                              <label>Fch. Venc.</label>
                              &nbsp;
                              <label className="tipo-cursiva">
                                {moment(effective.end_date).format(
                                  "YYYY-MM-DD"
                                )}
                              </label>{" "}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
          </div>
          <div>
            <div className="btn-actions-form">
              {stateCreate.status !== STATUS_EDIT ? (
                <>
                  {suscriptionsAudit.elements.filter((e) => e.is_personal)
                    .length > 0 && (
                    <input
                      type="submit"
                      className="btn-topic cursor-action tipo-boton"
                      value="Guardar"
                    />
                  )}
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    value="Guardar"
                    className="btn-orange-default"
                  />
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateAudit;
