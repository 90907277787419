import React, { useContext, useState } from "react";
import "./general.css";

import { SecurityContext } from "../../../contexts/security/SecurityContext";
import Header from "../layout/Header";
import Menu from "../layout/Menu";
import IdleTimer from "react-idle-timer";
import Swal from "sweetalert2";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
let initialState = {
  company: {
    id: "",
    name: "",
    nit: "",
  },
  fullContent: false,
  timer: null,
};

const GeneralLayout = ({ children }) => {
  const [time, settime] = useState(initialState.timer);
  const [{}, { logout }] = useContext(SecurityContext);
  const [{},{changeScroll}] = useContext(AlertContext)
  const onActive = () => {
    time.getRemainingTime();
  };
  const onAction = () => {};
  const onIdle = () => {
    let lastTime = time.getLastActiveTime();
    checkSession();
  };
 
  const checkSession = () => {
    logout();
    Swal.fire({
      title: "Inactividad",
      text: "¡Has excedido el tiempo de inactividad!",
      icon: "warning",
      confirmButtonColor: "#df8c37",
      confirmButtonText: "Iniciar sesión",
    }).then((result) => {});
  };
  return (
    <div className="flex flex-wrap" style={{ height: "100vh" }}>
      <IdleTimer
        ref={(ref) => {
          settime(ref);
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={1000 * 60 * process.env.REACT_APP_TIME_INACTIVITY}
      />

      <Header />

      <div className="flex-1 flex content-image-zeus-general">
        <Menu />
        <div
         
          id="content-wrapper"
          className="flex-1 flex scroll-view"
        >
          <div className="general-wrapper w-100 ">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default GeneralLayout;
