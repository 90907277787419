import React, { useContext, useState } from "react";
import {
  ActionPlanFormContext,
  STATUS_PENDING,
} from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import moment from "moment";
import { Field, Form } from "react-final-form";
import { STATUS_LOADED } from "../../../contexts/security/SecurityContext";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { STATUS_APPROVED } from "../../../contexts/evaluation/InspectionFormContext";

const initialState = {
  picker: {
    init_date: new Date(),
    end_date: new Date(),
  },

  stateCreate: {},
};
function formatoNumero(numero) {
  numero = numero.toString().replace(".", "");
  if (isNaN(numero)) {
    return 0;
  }

  var numberArray = numero
    .toString(20)
    .replace(/\D/g, "")
    .split("")
    .map(Number);

  let count = 0;
  let newNumber = "";
  for (let n = 0; n < numberArray.reverse().length; n++) {
    const element = numberArray.reverse()[n];
    if (count === 2) {
      newNumber = newNumber + "." + element;
      count = 0;
    } else {
      newNumber = newNumber + element;
      count = count + 1;
    }
  }
  return newNumber;
}

const Planification = () => {
  const [{ analyze }, { addAction }] = useContext(ActionPlanFormContext);

  return (
    <div>
      {analyze.element.causes.map((cause, index) => {
        return <CausesRender key={cause.id} index={index} cause={cause} />;
      })}
      <div className="container-total-actions-analyze">
        <span>total</span>
      </div>
    </div>
  );
};
const CausesRender = ({ cause }) => {
  const [picker, setpicker] = useState(initialState.picker);
  const [stateCreate, setstateCreate] = useState(initialState.stateCreate);
  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  const [
    { analyze },
    {
      addAction,
      isResponsablePqrs,
      haveResponsablePqrs,
      updateCause,
      checkUpdateStatusCause,
      isResponsable,
    },
  ] = useContext(ActionPlanFormContext);
  const sumCosts = (actions) => {
    let cost = 0;

    for (let a = 0; a < actions.length; a++) {
      cost = cost + parseInt(actions[a].cost);
    }

    return cost;
  };

  return (
    <>
      {cause.cause_root && (
        <div className="panel-detail-analyze cursor-drag">
          <div className="flex justify-betwen">
            <h5 className=" orange">{cause.name}</h5>
            {(cause.status_id === STATUS_APPROVED ||
              analyze.element.authorized) && (
              <div
                onClick={() => {
                  addAction(
                    {
                      analyze_id: analyze.analyze_id,
                      cause_id: cause.id,
                      init_date: analyze.element.created_at,
                    },
                    cause.id
                  );
                }}
                className="btn-orange-default-short"
              >
        
                Crear nueva actividad
              </div>
            )}

            {cause.status_id === STATUS_PENDING &&
              analyze.element.authorized === false &&
              isResponsablePqrs(analyze.element.company) &&
              haveResponsablePqrs(analyze.element.company) && (
                <div
                  onClick={() => {
                    checkUpdateStatusCause(cause);
                  }}
                  className="btn-orange-default-short"
                >
                  Aprobar
                </div>
              )}
            {cause.status_id === STATUS_PENDING &&
              analyze.element.authorized === false &&
              !haveResponsablePqrs(analyze.element.company) &&
              isResponsable() && (
                <div
                  onClick={() => {
                    checkUpdateStatusCause(cause);
                  }}
                  className="btn-orange-default-short"
                >
                  Aprobar
                </div>
              )}
            {cause.status_id === STATUS_PENDING &&
              isResponsable() &&
              haveResponsablePqrs(analyze.element.company) &&
              analyze.element.authorized === false && (
                <span className="tipo-cursiva">* Debe ser aprobado</span>
              )}
          </div>

          {analyze.element.actions.filter((a) => a.cause_id === cause.id)
            .length > 0 && (
            <div className="container-tables-analyze container-scroll-h">
              <table className="table table-correction-analyze">
                <tbody>
                  {analyze.element.actions
                    .filter((a) => a.cause_id === cause.id)
                    .map((action, index) => {
                      return (
                        <ActionRender
                          index={index}
                          first={true}
                          cause_id={cause.id}
                          action={action}
                        />
                      );
                    })}

                  <p className="total-table-planification-analyze txt-right">
                    total:{" "}
                    {formatoNumero(
                      sumCosts(
                        analyze.element.actions.filter(
                          (a) => a.cause_id === cause.id
                        )
                      )
                    )}
                  </p>
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      {cause.causes.map((subcause) => {
        return <CausesRender key={subcause.id} cause={subcause} />;
      })}
    </>
  );
};
const ActionRender = ({ cause_id, index, first, action }) => {
  const [
    { analyze, actionDeploy, stateNewSubAction },
    { addAction, setFieldActions, setDeployAction, newSubAction, deleteAction },
  ] = useContext(ActionPlanFormContext);
  return (
    <ol className="ol-analyze">
      <div className="flex justify-end">
        <div className="container-circle-item flex">
          {action.actions.length > 0 && (
            <div
              onClick={() => setDeployAction(action.id)}
              className={`${
                actionDeploy["action" + action.id]
                  ? "circle-item-down-gray"
                  : "circle-item-up-gray"
              } circle-number-items`}
            >
              {" "}
              {action.actions.length}
            </div>
          )}
          {action.actions.length === 0 && <div style={{ width: "28px" }}></div>}
        </div>
        <th style={{ width: "-webkit-fill-available" }}>
          <div className="flex-column">
            {first && index === 0 && <div>Actividad</div>}

            <div className="form-group-zeus">
              <input
                onChange={(e) =>
                  setFieldActions(
                    cause_id,
                    action.id,
                    "description",
                    e.target.value,
                    true
                  )
                }
                onBlur={(e) =>
                  setFieldActions(
                    cause_id,
                    action.id,
                    "description",
                    e.target.value,
                    false
                  )
                }
                value={action.description}
                type="text"
                placeholder="Actividad"
                name="text"
                autoComplete="off"
                required
              />
              <label htmlFor="text" className="label-name-zeus">
                <span className="content-name-zeus"></span>
              </label>
            </div>
          </div>
        </th>

        <td style={{ minWidth: "120px", width: "260px" }}>
          <div className="flex-column">
            {first && index === 0 && <div>Cuando</div>}
            <DatePicker
              selected={new Date(action.init_date)}
              onChange={(date) => {
                setFieldActions(cause_id, action.id, "init_date", date, false);
                setFieldActions(cause_id, action.id, "init_date", date, true);
              }}
              /* showTimeSelect */
              className="form-control tipo-description border-bottom-orange-zeus"
              /* timeFormat="HH:mm" */
              locale="es"
              /* timeIntervals={30} */
              dateFormat="y-MM-dd"
            />
          </div>
        </td>

        <td>
          <div className="flex-column">
            {first && index === 0 && <div>Responsable</div>}
            <Select
              value={action.responsable}
              onChange={(e) => {
                setFieldActions(
                  cause_id,
                  action.id,
                  "responsable_id",
                  e ? e.id : null,
                  false
                );
                setFieldActions(cause_id, action.id, "responsable", e, true);
              }}
              className="tipo-description select-min-analyze select-zeus-analyze"
              placeholder={"Seleccione"}
              getOptionLabel={(option) => (
                <div className="flex">
                  <img
                    className={` btn-auditor-activity-filter  cursor-action background-simple `}
                    src={
                      process.env.REACT_APP_API_HOST +
                      "/documents/" +
                      (option.image && option.image.name)
                    }
                    onError={(e) => {
                      e.target.src = imgAduitorCheck;
                    }}
                  ></img>
                  &nbsp; {option.first_name} {option.last_name}
                </div>
              )}
              getOptionValue={(option) => option.id}
              options={analyze.element.company.partners}
              isClearable={true}
              isMulti={false}
            />
          </div>
        </td>
        <td>
          <div style={{ width: "100px" }} className="flex-column">
            {first && index === 0 && <div>Costo</div>}
            {!first && <div></div>}
            {first && (
              <div className="form-group-zeus">
                <input
                  onChange={(e) =>
                    setFieldActions(
                      cause_id,
                      action.id,
                      "cost",
                      e.target.value,
                      true
                    )
                  }
                  onBlur={(e) =>
                    setFieldActions(
                      cause_id,
                      action.id,
                      "cost",
                      e.target.value,
                      false
                    )
                  }
                  className="form-control tipo-description txt-right"
                  /*  type="number" */
                  disabled={!first}
                  value={action.cost}
                  autoComplete="off"
                  required
                />
                <label htmlFor="text" className="label-name-zeus">
                  <span className="content-name-zeus"></span>
                </label>
              </div>
            )}
          </div>
        </td>
        <td>
          <div className="flex-column">
            {first && index === 0 && <div> Acciones</div>}

            <div>
              {" "}
              <div className=" flex">
                {" "}
                {action.id && (
                  <div
                    title="Añadir subactividad"
                    className="btn-more-black background-simple btn-more-cause-analyze"
                    onClick={() => {
                      addAction(
                        {
                          analyze_id: analyze.analyze_id,
                          action_plan_id: action.id,
                          init_date: action.init_date,
                        },
                        cause_id
                      );
                    }}
                  />
                )}
                &nbsp;
                {action.is_open && (
                  <img
                    title="eliminar"
                    className="cursor-action"
                    width="20"
                    height="20"
                    onClick={() => deleteAction(action.id, cause_id)}
                    src={deleteIcon}
                    alt="eliminar"
                  />
                )}
              </div>
            </div>
          </div>
        </td>
      </div>
      {actionDeploy["action" + action.id] && (
        <div className="form-group">
          {action.actions.map((SubAction) => {
            return (
              <ActionRender
                key={SubAction.id}
                first={false}
                cause_id={cause_id}
                index={index}
                action={SubAction}
              />
            );
          })}
        </div>
      )}
    </ol>
  );
};

export default Planification;
