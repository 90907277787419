import React, { useContext } from "react";
import "../audit/HeaderAudit.css";
import iconCreated from "../../img_zeus/icons_audit/ICONO_ESPERA.png";
import iconPlanned from "../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import iconInProgress from "../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import iconExecuted from "../../img_zeus/icons_audit/ICONO_TERMINADA.png";

const STATUS_CREATED = 1;
const STATUS_PLANNED = 2;
const STATUS_APPROVEDD = 7;
const STATUS_PROGRESS = 3;
const STATUS_EXECUTED = 4;

const HeaderAudit = ({ audit }) => {
  const { standard, responsable_person } = audit;

  const validateStatus = status => {
    let text = "Sin estado";
    let icon = iconCreated;
    switch (status) {
      case STATUS_CREATED:
        text = "Creada";
        icon = iconCreated;
        break;
      case STATUS_PLANNED:
        text = "Planeada";
        icon = iconPlanned;
        break;
      case STATUS_APPROVEDD:
        text = "Aprobada";
        icon = iconExecuted;
        break;
      case STATUS_PROGRESS:
        text = "En progreso";
        icon = iconInProgress;
        break;
      case STATUS_EXECUTED:
        text = "Finalizada";
        icon = iconExecuted;
        break;
    }
    return (
      <p className="tipo-title">
        <img className="icon-status-audit" src={icon} />
        &nbsp;{text}
      </p>
    );
  };
  return (
    <div className=" w-100">
      <h3 className="tipo-title">{audit && audit.title}</h3>
      <div className="w-100 flex">
        <div className="flex-1">
          <div className="form-group grupo-header-audit">
            <label className="label-audit-header tipo-title control-label">
              Norma
            </label>

            <div className="input-header-audit form-control">
              {standard && standard.short_name}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="form-group grupo-header-audit">
            <label className=" label-audit-header tipo-title control-label">
              Fecha de inicio
            </label>
            <div className="input-header-audit form-control">
              {standard && audit.init_date}
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 flex">
        <div className="flex-1">
          <div className="form-group grupo-header-audit">
            <label className="label-audit-header tipo-title control-label">
              Estado
            </label>
            <div className="input-header-audit form-control">
              {validateStatus(audit.status_id)}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="grupo-header-audit">
            <label className="label-audit-header tipo-title">Responsable</label>
            <div className="input-header-audit form-control">
              {responsable_person &&
                responsable_person.first_name +
                  "" +
                  responsable_person.last_name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAudit;
