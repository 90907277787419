import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import "moment/locale/es";
import "../program/Index.css";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_show from "../../../img_zeus/icons_audit/ICONO-VER.png";
import CreateProgram from "../modals/CreatePrograms";
import { ProgramIndexContext } from "../../../contexts/audit/ProgramIndexContext";
import { useHistory } from "react-router-dom";
import btn_create from "../../../img_zeus/icons_audit/BOTON_CREAR_PROGRAMA.png";
import btn_desplegar from "../../../img_zeus/icons_audit/BOTON-DESPLEGAR-AUDITORIA.png";
import { STATUS_LOADED } from "../../../contexts/audit/AuditPlanFormContext";

const initialState = {
  toggle_modal: false,
  toggle: {
    init: false,
    end: false,
  },
  program_id: null,
  searchProgram: "",
};

const Index = ({ computedMatch: { path } }) => {
  const [{ programs }, { deleteProgram, filterInit, filterEnd }] = useContext(
    ProgramIndexContext
  );
  let [
    { user },
    { refreshUser, viewGeneral },
  ] = useContext(SecurityContext);
  const [statePrograms, setstatePrograms] = useState([]);
  let [state, setState] = useState(initialState);
  const [toogleGantt, settoogleGantt] = useState(false);
  let [searchProgram, setSearchProgram] = useState(initialState.searchProgram);
  let history = useHistory();
  useEffect(() => {
    viewGeneral("program");
  }, []);
  useEffect(() => {
    if (programs.status == STATUS_LOADED) {
      /*   if (!haveBillAuditCompany()) {
        history.push("../unauthorized");
      } */
      setstatePrograms(programs.elements);
    }
  }, [programs]);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  const filterInitDate = () => {
    filterInit();
  };

  const filterEndDate = () => {
    filterEnd();
  };

  const setFieldSearch = (field) => (e) => {
    let body = e.target.value;
    setSearchProgram(body);
    if (body == "") {
      setstatePrograms(programs.elements);
    } else {
      if (programs.status == STATUS_LOADED) {
        let newPrograms = programs.elements.filter(function (el) {
          return (
            el.name.toLowerCase().indexOf(body.toLowerCase()) > -1 ||
            (el.company &&
              el.company.name.toLowerCase().indexOf(body.toLowerCase()) > -1) ||
            (el.company &&
              el.company.contact &&
              el.company.contact.first_name
                .toLowerCase()
                .indexOf(body.toLowerCase()) > -1) ||
            (el.company &&
              el.company.contact &&
              el.company.contact.last_name
                .toLowerCase()
                .indexOf(body.toLowerCase()) > -1)
          );
        });
        setstatePrograms(newPrograms);
      }
    }
  };

  return (
    <div>
      {state.toggle_modal == true && (
        <div className="back-document">
          <div className="modal-program">
            <div className="header-modal-program">
              <div
                className="salir-program"
                onClick={() => {
                  setState({ ...state, toggle_modal: false, program_id: null });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                {state.program_id !== null
                  ? "Editar Programa"
                  : "Nuevo Programa"}
              </h3>
            </div>
            <div>
              <CreateProgram
                state={state}
                setState={setState}
                program_id={state.program_id}
              ></CreateProgram>
            </div>
          </div>
        </div>
      )}
      <div className="flex content-title-programs">
        <h2 className="tipo-title title-programs">Programas</h2>
        <div className="flex">
          <input
            className="form-control input-search-program tipo-description"
            value={searchProgram}
            onChange={setFieldSearch()}
            type="text"
            placeholder="Buscar"
          />

          <img
            className="cursor-action create-program-index"
            onClick={() => {
              setState({ ...state, toggle_modal: true });
            }}
            src={btn_create}
            title="Crear Programa de auditorias"
          />
        </div>
      </div>
      <div className="table-programs">
        <table className="table table-hover">
          <thead className="thead-programs">
            <tr className="txt-center">
              <th scope="col">PROGRAMA</th>
              <th
                className="cursor-action"
                onClick={() => {
                  filterInitDate();
                  setState({ ...state, toggle: { init: !state.toggle.init } });
                }}
                scope="col"
              >
                FECHA INICIO &nbsp; &nbsp;
                <img
                  src={btn_desplegar}
                  className={`${
                    state.toggle.init ? "rotate-180" : ""
                  } btn-desplegar`}
                />
              </th>
              <th
                className="cursor-action"
                onClick={() => {
                  filterEndDate();
                  setState({ ...state, toggle: { end: !state.toggle.end } });
                }}
                scope="col"
              >
                FECHA FIN &nbsp; &nbsp;
                <img
                  src={btn_desplegar}
                  className={`${
                    state.toggle.end ? "rotate-180" : ""
                  } btn-desplegar`}
                />
              </th>
              <th scope="col">EMPRESA</th>
              <th scope="col">RESPONSABLE</th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {statePrograms.map(
              (pro) =>
                pro.company &&
                pro.company.contact_person_id == user.id && (
                  <tr className="txt-center tr-table-programs" key={pro.id}>
                    <td>{pro.name}</td>
                    <td>{moment(pro.init_date).format("YYYY-MM-DD")}</td>
                    <td>{moment(pro.end_date).format("YYYY-MM-DD")}</td>
                    <td>{pro.company.name}</td>
                    <td>
                      {pro.company.contact.first_name}{" "}
                      {pro.company.contact.last_name}
                    </td>
                    <td className="btn-action-program">
                      
                      <img
                        className="cursor-action"
                        onClick={() => {
                          history.push(`/app/audit/program/${pro.id}`);
                        }}
                        title="Ingresar al programa"
                        src={btn_show}
                      />
                      &nbsp;
                      <img
                        className="cursor-action"
                        onClick={() => {
                          setState({
                            ...state,
                            toggle_modal: true,
                            program_id: pro.id,
                          });
                        }}
                        title="Editar programa"
                        src={btn_edit}
                      />
                      &nbsp;
                      <img
                        className="cursor-action"
                        onClick={() => {
                          checkDelete(
                            "¿Realmente deseas eliminar a este programa?",
                            `deleteProgram(${pro.id})`
                          );
                        }}
                        title="Eliminar programa"
                        src={btn_delete}
                      />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
