import React, { useContext, useState, useEffect } from "react";
import { AuditContext } from "../../../contexts/audit/AuditContext";
import "../item/Form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import iconPlus from "../../../../src/img_zeus/icons_audit/ICONO-CREAR.png";
import iconDelete from "../../../../src/img_zeus/icons_library/BOTON-ELIMINAR.png";
import iconEdit from "../../../../src/img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import iconAcept from "../../../img_zeus/ICONO-ACEPTAR.png";
import iconCancel from "../../../img_zeus/ICONO-CANCELAR.png";
import cloneDeep from "lodash/cloneDeep";
import Swal from "sweetalert2";

import {
  faTrashAlt,
  faEdit,
  faWindowClose,
  faCheckSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { StandardFormContext } from "../../../contexts/audit/StandardFormContext";
const initialState = {
  item: {
    new_item: {
      title: "",
      number: "",
      item_type_id: null,
      standard_id: null,
      auditable_item_id: null,
    },
  },

  itemsDeploy: {},
};
const removeObjectFromArray = (arr, name) => {
  let arrayClone = cloneDeep(arr);
  for (let i = 0; i < arrayClone.length; i++) {
    const element = arrayClone[i];
    delete element[name];
  }
  return arrayClone;
};
const Item = ({ standard, first, items, standard_id }) => {
  const [
    { stateNewItem, stateNewSubItem },
    { setstateNewItem, createAuditableItem, setstateNewSubItem },
  ] = useContext(StandardFormContext);

  useEffect(() => {
    setstateNewItem({
      ...stateNewItem,
      standard_id: standard_id,
    });
    setstateNewSubItem({
      ...stateNewSubItem,
      standard_id: standard_id,
    });
  }, [items]);

  const enterAddItem = () => (e) => {
    if (e.key === "Enter") {
      createAuditableItem(stateNewItem);
    }
  };
  let setFieldNew_item = (field) => (e) => {
    setstateNewItem({
      ...stateNewItem,
      [field]: e.target.value,
    });
  };

  return (
    <div className="padding-control bg-white">
      <div className="flex content-add-item">
        <div className="flex-0 margin-control container-number-flex">
          <input
            onChange={setFieldNew_item("number")}
            value={stateNewItem.number}
            className="input-basci-white input-number-item form-control "
            type="text"
            placeholder="#"
          />
        </div>
        {standard.id && standard.item_types.length > 0 && (
          <div className="flex-0 margin-control">
            <Select
              onChange={(e) =>
                setstateNewItem({
                  ...stateNewItem,
                  item_type_id: e ? e.id : null,
                })
              }
              isClearable={true}
              className="tipo-description select-item_type_standard"
              placeholder={"Tipo"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={removeObjectFromArray(standard.item_types, "options")}
            />
          </div>
        )}
        <div className="flex-1 margin-control">
          <input
            onChange={setFieldNew_item("title")}
            onKeyDown={enterAddItem()}
            value={stateNewItem.title}
            className="input-basci-white form-control "
            type="text"
            placeholder="Descripción"
          />
        </div>
      </div>

      {items && (
        <div>
          <SubItems
            standard_id={standard_id}
            first={first}
            standard={standard}
            originalItems={items}
            items={items}
          />
        </div>
      )}
    </div>
  );
};

const SubItems = ({ standard, standard_id, first, items, originalItems }) => {
  const [
    { stateNewSubItem, stateItemEdit },
    {
      createAuditableItem,
      deleteAuditableItem,
      updateAuditableItem,
      setOrderItem,
      setstateNewSubItem,
      newSubItem,
      setstateItemEdit,
      newEdit,
    },
  ] = useContext(StandardFormContext);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [stateNewItem, setstateNewItem] = useState(initialState.item.new_item);

  const [itemDrag, setitemDrag] = useState();
  useEffect(() => {
    setstateNewItem({
      ...stateNewItem,
      standard_id: standard_id,
    });
    setstateNewSubItem({
      ...stateNewSubItem,
      standard_id: standard_id,
    });
  }, [items]);

  let setFieldItemEdit = (field) => (e) => {
    setstateItemEdit({
      ...stateItemEdit,
      [field]: e.target.value,
    });
  };

  let setFieldSubItemEdit = (field) => (e) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      [field]: e.target.value,
    });
  };

  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };
  const confirmDelete = (item_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar este item?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteAuditableItem(item_id);
      } else {
      }
    });
  };

  const onDragStart = (event, item) => {
    event.dataTransfer.setData("item", JSON.stringify(item));
    setitemDrag(item);
  };
  const onDragOver = (event, item) => {
    event.preventDefault();
  };

  const onDrop = (event, item) => {
    let itemDrag = JSON.parse(event.dataTransfer.getData("item"));
    let itemDrop = item;
    if (
      itemDrag.auditable_item_id === itemDrop.auditable_item_id &&
      itemDrag.id != itemDrop.id
    ) {
      let itemsChilds = findItem(itemDrag.auditable_item_id);
      let indexDrop = false;
      let count = 1;
      for (let i = 0; i < itemsChilds.length; i++) {
        const item = itemsChilds[i];
        if (item.id != itemDrag.id) {
          if (item.id == itemDrop.id) {
            item.order = count;

            count = count + 1;

            indexDrop = count;
          } else {
            item.order = count;
          }
          count = count + 1;
        }
      }
      for (let p = 0; p < itemsChilds.length; p++) {
        const it = itemsChilds[p];
        if (it.id == itemDrag.id) {
          it.order = indexDrop;
        }
      }
      let itemsOrder = itemsChilds.sort(function (a, b) {
        return a.order - b.order;
      });
      setOrderItem(itemsOrder);
    }
  };
  const findItem = (item_id) => {
    if (item_id) {
      return recursiveItems(originalItems, item_id);
    } else {
      return items;
    }
  };
  const recursiveItems = (items, item_id) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id == item_id) {
        return item.items;
      }

      if (item.items.length > 0) {
        recursiveItems(item.items, item_id);
      }
    }
  };
  const keyEnterSubitem = () => (e) => {
    if (e.key === "Enter") {
      createAuditableItem(stateNewSubItem);
    }
  };
  const keyEnterEditItem = () => (e) => {
    if (e.key === "Enter") {
      updateAuditableItem(stateItemEdit);
      
    }
  };
  return (
    items &&
    items.map((item, index) => {
      return (
        <ol key={item.id}>
          <li
            className={`li-item-standard ${
              itemDeploy["item" + item.id] ? "dotted-li" : ""
            }`}
          >
            <div
              onDragStart={(event) => onDragStart(event, item)}
              draggable
              onDragOver={(event) => onDragOver(event, item)}
              onDrop={(event) => onDrop(event, item)}
              className={`flex content-item-column ${
                first ? `content-item-orange white` : `bg-gray-2`
              } `}
            >
              <div className="container-circle-item flex">
                {item.items.length > 0 && (
                  <div
                    onClick={() => setDeploy(item.id)}
                    className={`${
                      itemDeploy["item" + item.id]
                        ? "circle-item-down-gray"
                        : "circle-item-up-gray"
                    } circle-number-items`}
                  >
                    {" "}
                    {item.items.length}
                  </div>
                )}
              </div>

              <div className="flex-1">
                <div className="flex">
                  {stateItemEdit.id !== item.id && (
                    <>
                      <div className="number-item-content flex-column">
                        <p className="">{item.number}</p>
                      </div>
                      <div className="flex-1">
                        <p className="p-item-description tipo-description">
                          {item.title}

                          {item.item_type && (
                            <strong className="">
                              <br />
                              <br />
                              Tipo item :"{item.item_type.name}"
                            </strong>
                          )}
                        </p>
                      </div>
                    </>
                  )}
                  {/* edit item */}
                  {stateItemEdit.id === item.id && (
                    <>
                      <div className="flex-1 number-item-content-new margin-control">
                        <input
                          onChange={setFieldItemEdit("number")}
                          value={stateItemEdit.number}
                          className="input-basci-white input-number-item form-control margin-control"
                          type="text"
                        />
                      </div>
                      {standard.id && standard.item_types.length > 0 && (
                        <div className="flex-0 container-new-input-item margin-control">
                          <Select
                            defaultValue={stateItemEdit.item_type_id}
                            onChange={(e) =>
                              setstateItemEdit({
                                ...stateItemEdit,
                                item_type_id: e ? e : null,
                              })
                            }
                            isClearable={true}
                            className="tipo-description select-item_type_standard"
                            placeholder={"Tipo"}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            options={removeObjectFromArray(
                              standard.item_types,
                              "options"
                            )}
                          />
                        </div>
                      )}
                      <div className="flex-1 container-new-input-item margin-control">
                        <textarea
                          onChange={setFieldItemEdit("title")}
                          onKeyDown={keyEnterEditItem()}
                          value={stateItemEdit.title}
                          className="input-basci-white form-control"
                          type="text"
                        ></textarea>
                      </div>
                    </>
                  )}
                  {/* end edit item */}
                </div>
              </div>
              <div className="container-actions-item flex">
                <div className="container-margin-actions-item flex">
                  {/* buttons edit */}
                  {stateItemEdit.id === item.id && (
                    <>
                      &nbsp; &nbsp;
                      <div
                        onClick={() => {
                          newEdit(null, "");
                        }}
                        className="cursor-action icon-cancel-item"
                      />
                      &nbsp;
                    </>
                  )}
                  {/* end buttons edit */}
                  {stateItemEdit.id !== item.id && (
                    <>
                      <div
                        className="btn-more-black background-simple"
                        onClick={() => {
                          newSubItem(item.id, "");
                        }}
                      />

                      <div
                        className="background-simple btn-edit-black"
                        onClick={() => {
                          newEdit(
                            item.id,
                            item.title,
                            item.number,
                            item.item_type
                          );
                        }}
                      />

                      <div
                        onClick={() => confirmDelete(item.id)}
                        className="background-simple btn-delete-black"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/*    new item */}
            {stateNewSubItem.id === item.id && (
              <ol>
                <li>
                  <div className={`flex content-item-column bg-green-success`}>
                    <div className="flex-1">
                      <div className="flex">
                        <div className="flex-1 number-item-content-new">
                          <input
                            onChange={setFieldSubItemEdit("number")}
                            value={stateNewSubItem.number}
                            className="input-basci-white input-number-item form-control margin-control"
                            type="text"
                            placeholder="#"
                          />
                        </div>
                        {standard.id && standard.item_types.length > 0 && (
                          <div
                            className="flex-0 margin-control"
                            style={{ marginLeft: "18px" }}
                          >
                            <Select
                              onChange={(e) =>
                                setstateNewSubItem({
                                  ...stateNewSubItem,
                                  item_type_id: e ? e.id : null,
                                })
                              }
                              isClearable={true}
                              className="tipo-description select-item_type_standard"
                              placeholder={"Tipo"}
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionValue={(option) => option.id}
                              options={removeObjectFromArray(
                                standard.item_types,
                                "options"
                              )}
                            />
                          </div>
                        )}
                        <div className="flex-1 container-new-input-item">
                          
                          <input
                            placeholder="Descripción"
                            onChange={setFieldSubItemEdit("title")}
                            onKeyDown={keyEnterSubitem()}
                            value={stateNewSubItem.title}
                            className="input-basci-white form-control"
                            type="text"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="container-actions-item flex">
                      <div className="container-margin-actions-item-standard flex flex-1">
                        <div
                          onClick={() => {
                            newSubItem(null, "");
                          }}
                          title="Cancelar"
                          className="flex icon-cancel-item"
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ol>
            )}
            {/* end new item */}

            {itemDeploy["item" + item.id] && (
              <SubItems
                standard_id={standard_id}
                standard={standard}
                first={false}
                items={item.items && item.items}
                originalItems={originalItems}
              />
            )}
          </li>
        </ol>
      );
    })
  );
};
export default Item;
