import React, { useContext } from "react";
import "./ListDropDown.css";
import { Link, useHistory } from "react-router-dom";
import img_subscription from "../../../img_zeus/icons_menu/ICONO-SUSCRIPCION.png";
import img_company from "../../../img_zeus/icons_menu/ICONO-EMPRESA.png";
import img_audit from "../../../img_zeus/icons_menu/ICONO-AUDITORIA.png";
import img_library from "../../../img_zeus/icons_menu/ICONO-BIBLIOTECA.png";
import img_consulta from "../../../img_zeus/icons_menu/ICONO-CONSULTA.png";
import img_calendar from "../../../img_zeus/icons_menu/ICONO-CALENDARIO.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img_plan from "../../../img_zeus/icons_audit/plan.png";
import {
  faCaretDown,
  faUserCog,
  faClipboardList,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import ListHelp from "../../general/help/List";
let tootgleMenu = (elementId) => {
  document.getElementById(elementId).classList.toggle("show");
};
const ListDropDown = () => {
  const [{ user }, {}] = useContext(SecurityContext);
  const [{ toogleHelp }, { settoogleHelp }] = useContext(AlertContext);
  let history = useHistory();

  return (
    <div className="menu-list">
      {toogleHelp && (
        <div className="back-document">
          <div className="bg-white container-help-desktop shadow-tarjet flex-column">
            <div className="tipo-cursiva flex txt-center justify-center container-title-modal-help">
              Ayuda recomendada
              <div
                onClick={() => settoogleHelp(!toogleHelp)}
                className="icon-close-help cursor-action  background-simple"
              ></div>
            </div>
            <ListHelp
              settoogleHelp={settoogleHelp}
              toogleHelp={toogleHelp}
              mobile={false}
            />
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <div
          onClick={() => settoogleHelp(!toogleHelp)}
          className="icon-help-menu background-simple cursor-action"
        ></div>
      </div>
      <ul className="list-ul-menu">
        <li className="dropdown">
          <Link to="/app/library/topics">
            <div
              title="Acceso a información normativa y actualizada"
              className="text-left-menu"
            >
              <span>
                <p>
                  <img className="img-standard-icon" src={img_library} />
                  &nbsp;&nbsp;
                  <strong>Actualidad</strong>
                </p>
              </span>
            </div>
          </Link>
        </li>

        <li
          className="dropdown"
          onClick={() => {
            tootgleMenu("down1");
          }}
        >
          <div className="text-left-menu">
            <span>
              <p>
                <img className="img-standard-icon" src={img_subscription} />
                &nbsp;&nbsp; <strong>Suscripción</strong>
                <FontAwesomeIcon
                  className="caret-down-list"
                  icon={faCaretDown}
                />
              </p>
            </span>
          </div>

          <div className="dropdown-content" id="down1">
            <Link to="/app/library/topic/billing/suscription/library">
              Adquirir suscripción
            </Link>
            <Link to="/app/library/topics_subscribed">Mis suscripciones</Link>
            <Link to="/app/billing/bills">Mis compras</Link>
          </div>
        </li>

        <li className="dropdown">
          <Link to="/app/companies">
            <div className="text-left-menu">
              <span>
                <p>
                  <img className="img-company-icon-list" src={img_company} />
                  &nbsp;&nbsp;
                  <strong>Empresas</strong>
                </p>
              </span>
            </div>
          </Link>
        </li>

        {/*  <li
            className="dropdown"
            onClick={() => {
              tootgleMenu("down2");
            }}
          >
            <div className="">
              <div className="text-left-menu">
                <span>
                  <p>
                    <img className="img-company-icon" src={img_company} />
                    &nbsp;&nbsp; <strong>Empresas</strong>
                    <FontAwesomeIcon
                      className="caret-down-list"
                      icon={faCaretDown}
                    />
                  </p>
                </span>
              </div>
            </div>

            <div className="dropdown-content" id="down2">
              <Link to="/app/companies">Empresas</Link>
            </div>
          </li>  */}

        <li
          className="dropdown"
          onClick={() => {
            tootgleMenu("down3");
          }}
        >
          <Link
            to="/app/audit/audits"
            className=""
          >
            <div className="text-left-menu">
              <span>
                <p>
                  <img className="img-standard-icon" src={img_audit} />
                  &nbsp;&nbsp; <strong>Auditorías</strong>
                  <FontAwesomeIcon
                    className="caret-down-list"
                    icon={faCaretDown}
                  />
                </p>
              </span>
            </div>
          </Link>

          <div className="dropdown-content" id="down3">
            <Link to="/app/audit/programs">Programas</Link>

            <Link to="/app/audit/audits">Auditorías</Link>
            <Link className="flex link-express" to="/app/audit_express/audits">
              {" "}
              <div className="background-simple icon-audit-express-deskopt"></div>
              &nbsp;Auditoría express
            </Link>
            <Link to="/app/audit/standards">Normas</Link>

            <Link to="/app/audit/templates">Mis plantillas</Link>

            {user.is_admin && (
              <Link to="/app/audit/specialties">Especialidades</Link>
            )}
          </div>
        </li>
        <li className="dropdown">
          <div className="">
            <div className="text-left-menu">
              <span>
                <p>
                  <img className=" img-standard-icon" src={img_plan} alt="" />

                  <Link className="white" to="/app/audit/actions_plans">
                    &nbsp;&nbsp; <strong>Plan de acción</strong>
                  </Link>
                </p>
              </span>
            </div>
          </div>
        </li>
        <li className="dropdown">
          <div className="">
            <div className="text-left-menu">
              <span>
                <p>
                  <FontAwesomeIcon
                    className="img-standard-icon orange"
                    icon={faClipboardList}
                  />
                  <Link className="white" to="/app/inspection/evaluations">
                    &nbsp;&nbsp; <strong>Inspecciones</strong>
                  </Link>
                </p>
              </span>
            </div>
          </div>
        </li>
        <li className="dropdown">
          <div className="">
            <div className="text-left-menu">
              <span>
                <p>
                  <FontAwesomeIcon
                    className="img-standard-icon orange"
                    icon={faChalkboardTeacher}
                  />
                  <Link className="white" to="/app/course/courses">
                    &nbsp;&nbsp; <strong>Capacitaciones</strong>
                  </Link>
                </p>
              </span>
            </div>
          </div>
        </li>

        {/* <li className="dropdown">
          <div className="">
            <div className="text-left-menu">
              <span>
                <p>
                  <img src={img_calendar} className="img-standard-icon" />
                  &nbsp;&nbsp; <strong>Calendario</strong>
                </p>
              </span>
            </div>
          </div>
        </li> */}

        {/* {<li className="dropdown">
          <div className="">
            <div className="text-left-menu">
              <span>
                <p>
                  <img className="img-standard-icon" src={img_consulta} />{" "}
                  &nbsp;&nbsp; <strong>Consulta</strong>
                </p>
              </span>
            </div>
          </div>
        </li>} */}

        {user.is_admin && (
          <>
            <li className="dropdown">
              <div className="">
                <div className="text-left-menu">
                  <span>
                    <p>
                      <FontAwesomeIcon
                        className="img-standard-icon"
                        icon={faUserCog}
                      />
                      <Link className="white" to="/app/security/admin/roles">
                        &nbsp;&nbsp; <strong>Administrador</strong>
                      </Link>
                    </p>
                  </span>
                </div>
              </div>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default ListDropDown;
