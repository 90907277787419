import React, { useContext, useEffect, useState } from "react";
import client from "../axios/axios-client";
import { useLocation, useHistory } from "react-router-dom";
import { AlertContext } from "../alerts/AlertContext";
import { SecurityContext } from "../security/SecurityContext";
import { SubscriptionFormContext } from "../library/SubscriptionFormContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_APPROVED_PAYMENT = 7;

let initialState = {
  allSusbcription:{
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  bill_detail: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  subscriptions: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  subscriptionsAudit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  services: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  dontSuscribed: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  dontSuscribedAudit: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
  formErrors: {},
};

export const SubscriptionContext = React.createContext([]);

export const SubscriptionContextProvider = ({ children }) => {
  let location = useLocation();
  let history = useHistory();

  const [{ user }, { logout,refreshUser }] = useContext(SecurityContext);
  let [meta, setMeta] = useState(initialState.meta);
  let [bill_detail, setBillDetail] = useState(initialState.bill_detail);
  let [errors, setErrors] = useState(initialState.errors);
  let [services, setServices] = useState(initialState.services);
  let [dontSuscribed, setDontSuscribed] = useState(initialState.dontSuscribed);
  let [dontSuscribedAudit, setDontSuscribedAudit] = useState(initialState.dontSuscribedAudit);
  let [subscriptions, setSubscription] = useState(initialState.subscriptions);
  const [allSusbcription, setallSusbcription] = useState(initialState.allSusbcription)
  let [subscriptionsAudit, setSubscriptionAudit] = useState(initialState.subscriptionsAudit);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
 /*  useEffect(() => {
    if (dontSuscribedAudit.status === STATUS_NOT_LOADED) {
      setDontSuscribedAudit({ ...dontSuscribedAudit, status: STATUS_LOADING });
      client()
        .get("library/detailsWithoutSubscriptionAudit")
        .then(({ data }) => {
          if (data) {
            setDontSuscribedAudit({ status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las compras");
          }
        });
    }
  }, [dontSuscribedAudit]); */

  useEffect(() => {
    if (allSusbcription.status === STATUS_NOT_LOADED) {
      setallSusbcription({ ...allSusbcription, status: STATUS_LOADING });
      client()
        .get("billing/allEffectives")
        .then(({ data }) => {
          if (data) {
            setallSusbcription({ allSusbcription: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
         if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los efectivos");
          }
        });
    }
  }, [allSusbcription])
  useEffect(() => {
    if (dontSuscribed.status === STATUS_NOT_LOADED) {
      setDontSuscribed({ ...dontSuscribed, status: STATUS_LOADING });
      client()
        .get("security/detailsWithoutSubscription")
        .then(({ data }) => {
          if (data) {
            setDontSuscribed({ status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las compras");
          }
        });
    }
  }, [subscriptions, dontSuscribed]);

/*   useEffect(() => {
    if (subscriptions.status === STATUS_NOT_LOADED) {
      setSubscription({ ...subscriptions, status: STATUS_LOADING });
      client()
        .get("library/subscriptionLibrary")
        .then(({ data }) => {
          if (data) {
            let filter = data.sort(function (a, b) {
              var c = new Date(a.end_date);
              var d = new Date(b.end_date);
              return d - c;
            });

            setSubscription({
              status: STATUS_LOADED,
              elements: filter,
            });
          }
        })
        .catch((e) => {
         if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las subscripciones");
          }
        });
    }
  }, [subscriptions, meta]); */

  /* useEffect(() => {
    if (subscriptionsAudit.status === STATUS_NOT_LOADED) {
      setSubscriptionAudit({ ...subscriptionsAudit, status: STATUS_LOADING });
      client()
        .get("audit/subscriptionAudit")
        .then(({ data }) => {
          if (data) {
            setSubscriptionAudit({
              status: STATUS_LOADED,
              elements: [],
            });
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las subscripciones");
          }
        });
    }
  }, [subscriptionsAudit]); */

  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  const refreshSubscriptions = () => {
    setSubscription({ ...subscriptions, status: STATUS_NOT_LOADED });
  };

  const refreshDontSuscribed = () => {
    setDontSuscribed({ ...dontSuscribed, status: STATUS_NOT_LOADED });
  };
  const addSuscription = (form) => {
    form.person_id = user.id;
    client()
      .post("library/subscription", form)
      .then(({ data }) => {
        if (data) {
          setSubscriptionAudit({ ...subscriptionsAudit, status: STATUS_NOT_LOADED });
          setDontSuscribedAudit({ ...dontSuscribedAudit, status: STATUS_NOT_LOADED });
          refreshUser();
        }
      })
      .catch((e) => {

        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar asignar la subscripción");
        }
      });
  };
  useEffect(() => {
    if(services.status===STATUS_NOT_LOADED){
      setServices({ ...services, status: STATUS_LOADING });
      client()
        .get(`billing/service`, {
          params: {
           filters: { active: true },
            orderBy:["order:ASC"]
          },
        })
        .then(({ data }) => {
          if (data) {
            setServices({
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los servicios.");
          }
        });
    }
    }, [services])
  const addCompanyDetail=(form)=>{
    client()
    .post(`billing/bill_detail/${form.bill_detail_id}`, {company_id:form.company_id})
    .then(({ data }) => {
      if (data) {
        history.replace(
          `/app/library/topics_subscribed/${form.bill_detail_id}`
        )
        refreshDontSuscribed();
      }
    })
    .catch((e) => {

      if (e.request.status === 401) {
        logout();
      } else if (e.request.status === 422) {
        setErrors({ error: e.response.data, status: true });
      } else if (e.request.status === 403) {
        history.push("/app/unauthorized");
      } else {
        alertError("Error al buscar asignar la subscripción");
      }
    });
  }
  return (
    <SubscriptionContext.Provider
      value={[
        { subscriptions, subscriptionsAudit,services, dontSuscribed, dontSuscribedAudit,allSusbcription },
        { refreshSubscriptions, refreshDontSuscribed, addSuscription,addCompanyDetail },
      ]}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
