import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  filters: {
    generalTemplate: null
  },
  templates: {
    status: STATUS_NOT_LOADED,
    elements: []
  },

  errors: {
    error: [],
    status: false
  }
};

export const TemplateOnSaleContext = React.createContext([]);

export const TemplateOnSaleContextProvider = ({ children }) => {
  let history = useHistory();

  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertError, alertSuccess, showErrors }] = useContext(AlertContext);
  const [templates, setTemplates] = useState(initialState.templates);
  const [errors, setErrors] = useState(initialState.errors);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  // componteDidMointed & componentDidUpdated
  useEffect(() => {
    if (templates.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/evaluation`, {
          params: {
              with:['evaluationStandard','valorable'],
            filters: {
              is_template:true,
              on_sale:true,
              is_public:true
            }
          }
        })
        .then(({ data }) => {
          if (data) {
            setTemplates({
              ...templates,
              status: STATUS_LOADED,
              elements: data
            });
          }
        })
        .catch(e => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar las plantillas");
          }
        });
    }
  }, [templates]);

  return (
    <TemplateOnSaleContext.Provider value={[{ templates }, {}]}>
      {children}
    </TemplateOnSaleContext.Provider>
  );
};
