import React, { useContext, useState, useEffect } from "react";
import Responses from "../topic/functionResponses";
import { EntryContext } from "../../../contexts/forum/EntryContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const initialState = {
  entryEdit: {
    id: null,
    title: "",
    body: ""
  },
  entryResponseEdit: {
    id: null,
    body: ""
  },
  item: {
    title: "",
    person_id: "",
    topic_id: "",
    body: "",
    status: "",
    entry_id: "",
    newEntryResponse: ""
  },
  newResponse: {
    id: null,
    body: ""
  },
  responses: [],
  originalItem: {}
};

const Index = ({ computedMatch }) => {
  const [{ entry, meta, flag }, actions] = useContext(EntryContext);
  let { addScore, valorable, classLike } = actions;
  let [{ user }, { can }] = useContext(SecurityContext);
  let { params, url } = computedMatch;
  let { topic_id, entry_id } = params;
  let [state, setState] = useState(initialState);
  let [stateEntry, setEntry] = useState();

  let setField = field => e => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };

  let setFieldNewResponse = field => e => {
    setState({
      ...state,
      newResponse: { ...state.newResponse, [field]: e.target.value }
    });
  };

  let setFieldEdit = field => e => {
    setState({
      ...state,
      entryEdit: { ...state.entryEdit, [field]: e.target.value }
    });
  };

  let setFieldResponseEdit = field => e => {
    setState({
      ...state,
      entryResponseEdit: { ...state.entryResponseEdit, [field]: e.target.value }
    });
  };

  useEffect(() => {
    actions.setEntryAndTopicId(entry_id, topic_id);
  }, [topic_id, entry_id]);

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      actions.updateEntry(state.entryEdit);
      setState({ ...state, entryEdit: { id: null } });
    }
  };

  const _handleKeyDown2 = e => {
    if (e.key === "Enter") {
      actions.updateEntryResponse(state.entryResponseEdit);
      setState({ ...state, entryResponseEdit: { id: null } });
    }
  };

  const deleteEntryResponse = entry_responses_id => {
    if (window.confirm("Do you want to delete this Entry?")) {
      actions.deleteEntryResponse(entry_responses_id);
    }
  };

  let functionSubmit = e => {
    e.preventDefault();
    actions.createEntryResponse(state.item.newEntryResponse);
  };

  let functionSubmit2 = e => {
    e.preventDefault();
    actions.createEntryResponse(state.newResponse.body, state.newResponse.id);
  };

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!"
    }).then(result => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  return (
    <div className="entry-general tipo-description">
      <div className="header-entry">
        {state.entryEdit.id != entry_id && (
          <div className="entry-body">
            <div>
              <h2 className="orange tipo-title">
                {meta.entry.title}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon
                  className="cursor-action"
                  onClick={() =>
                    setState({ ...state, entryEdit: { id: entry_id } })
                  }
                  icon={faPen}
                />
              </h2>
            </div>
            {meta.entry.body}
            <br />
            <br />
            <form onSubmit={functionSubmit}>
              <textarea
                className="form-control txt-response"
                onChange={setField("newEntryResponse")}
                value={state.item.newEntryResponse}
                type="text"
                required
                placeholder="Respuesta"
              />
              <br />
              <input
                className="create-entry-div tipo-boton"
                type="submit"
                value="Responder"
              />
            </form>
          </div>
        )}
        {state.entryEdit.id === entry_id && (
          <div className="entry-body">
            <input
              className="form-control"
              type="text"
              placeholder="Titulo"
              defaultValue={meta.entry.title}
              onChange={setFieldEdit("title")}
              onKeyDown={_handleKeyDown}
            />
            <textarea
              className="form-control"
              type="text"
              cols="75"
              rows="4"
              defaultValue={meta.entry.body}
              onChange={setFieldEdit("body")}
              placeholder="Cuerpo"
              onKeyDown={_handleKeyDown}
            />
            <div
              className="cancel-edit-entry tipo-boton"
              onClick={() => setState({ ...state, entryEdit: { id: null } })}
            >
              Cancelar
            </div>
            <div
              onClick={() => {
                actions.updateEntry(state.entryEdit);
                setState({ ...state, entryEdit: { id: null } });
              }}
              className="cancel-edit-entry tipo-boton"
            >
              Editar
            </div>
          </div>
        )}
      </div>
      {/* Respuestas */}
      {meta.entry.entry_responses &&
        meta.entry.entry_responses.map(resp => {
          return (
            <div className="entry-response" key={resp.id}>
              {state.entryResponseEdit.id != resp.id && (
                <>
                  <h3 className="tipo-title">
                    Respuesta &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon
                      className="cursor-action"
                      onClick={() =>
                        setState({
                          ...state,
                          entryResponseEdit: { id: resp.id }
                        })
                      }
                      icon={faPen}
                    />
                    {can("delete_entry_response") && (
                      <FontAwesomeIcon
                        className="cursor-action"
                        onClick={() => {
                          checkDelete(
                            "¿Realmente deseas eliminar a esta respuesta?",
                            `actions.deleteEntryResponse(${resp.id})`
                          );
                        }}
                        icon={faMinusCircle}
                      />
                    )}
                  </h3>
                  <div>{resp.body}</div>
                </>
              )}
              {state.entryResponseEdit.id === resp.id && (
                <>
                  <h3 className="tipo-title">Respuesta</h3>
                  <textarea
                    className="form-control"
                    type="text"
                    cols="100"
                    rows="5"
                    placeholder="Cuerpo"
                    defaultValue={resp.body}
                    onChange={setFieldResponseEdit("body")}
                    onKeyDown={_handleKeyDown2}
                  />
                </>
              )}
              <small>de: {resp.person_entry_response.first_name}</small>
              <div className="content-like">
                <div
                  className={`like_response ${
                    classLike(1, resp) ? "activeLikeResponse" : ""
                  }`}
                  onClick={() => {
                    flag.like === true && addScore(1, resp.id);
                  }}
                ></div>
                &nbsp;&nbsp;
                {valorable(1, resp)}
                &nbsp;&nbsp;&nbsp;
                <div
                  className={`dislike_response ${
                    classLike(0, resp) ? "activeDisLikeResponse" : ""
                  }`}
                  onClick={() => {
                    flag.like === true && addScore(0, resp.id);
                  }}
                ></div>
                &nbsp;&nbsp;
                {valorable(0, resp)}
              </div>
              <form onSubmit={functionSubmit2}>
                <input
                  className="form-control input-response"
                  onChange={setFieldNewResponse("body")}
                  value={
                    (state.newResponse.id === resp.id &&
                      state.newResponse.body) ||
                    ""
                  }
                  type="text"
                  placeholder="Respuesta"
                  onFocus={e => {
                    setState({
                      ...state,
                      newResponse: {
                        ...state.newResponse,
                        id: resp.id,
                        body: ""
                      }
                    });
                  }}
                />
                <input
                  className="create-entry-div tipo-boton"
                  type="submit"
                  value="Responder"
                />
              </form>
              <br />
              <Responses data={resp.responses && resp.responses} />
              <br />
            </div>
          );
        })}
    </div>
  );
};

export default Index;
