import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import clientImage from "../axios/axios-client-image";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import firebase from "../../firebase";
import moment from "moment";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_ERASER = 5;
export const STATUS_APPROVED = 7;
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_DOCX = "docx";
const EXT_DOC= "doc";
const EXT_XLS= "xls";

const EXT_XLSX = "xlsx";
const MODEL_FOLDER = "App\\Models\\Folder";
const MODEL_TOPIC = "App\\Models\\Topic";

const NOTIFICATION_ADD_DOCUMENT = "add-document";

const NOTIFICATION_MODULE_LIBRARY = "library";
const db = firebase.firestore();
let initalState = {
  documentsWithOutRecursive:[],
  editFolder: {
    id: null,
    name: "",
    is_free: true,
    status_id: null,
  },
  breadCrumbFolder: [],
  meta: {
    subscription: [],
    folder: {},
    topic: {},
    filterGeneral: null,
    path: {
      topic_id: null,
      folder_id: null,
      document_id: null,
      deep: [],
    },
  },
  folders: {
    status: STATUS_NOT_LOADED,
    elements: [],
    originalItem: [],
  },
  documents: {
    status: STATUS_NOT_LOADED,
    elements: [],
    originalItem: [],
  },
  topics: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  filters: {
    head: {
      statusViewOrder: false,
      statusViewView: false,
      statusViewLicense: false,
      statusViewType: false,
    },
  },
  errors: {
    error: [],
    status: false,
  },
  showData: {
    folders: true,
    documents: true,
  },
  userAdminSearch: {},
  formErrors: {},
};

export const LibraryContext = React.createContext([]);

export const LibraryContextProvider = ({ children }) => {
  const [{ user }, { logout }] = useContext(SecurityContext);
  let location = useLocation();
  let history = useHistory();
  const [editFolder, seteditFolder] = useState(initalState.editFolder);
  let [meta, setMeta] = useState(initalState.meta);
  let [folders, setFolders] = useState(initalState.folders);
  let [documents, setDocuments] = useState(initalState.documents);
  const [listLibrary, setlistLibrary] = useState(false);
  let [topics, setTopics] = useState(initalState.topics);
  let [userAdmin, setUserAdmin] = useState(initalState.userAdminSearch);
const [documentsWithOutRecursive, setdocumentsWithOutRecursive] = useState(initalState.documentsWithOutRecursive)
  let [filters, setFilters] = useState(initalState.filters.head);
  const [breadCrumbFolder, setbreadCrumbFolder] = useState(initalState.breadCrumbFolder);

  let [errors, setErrors] = useState(initalState.errors);
  const [showData, setshowData] = useState(initalState.showData);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  const [{}, { isAdminTopic }] = useContext(SecurityContext);
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
  if(meta.path.topic_id!=null){
    client()
    .get(`library/getDocumentsTopic/${meta.path.topic_id}`)
    .then(({ data }) => {
      if (data) {
        setdocumentsWithOutRecursive(data)
      }
    })
    .catch((e) => {
      if (e.request.status === 401) {
        logout();
      } else if (e.request.status === 422) {
        setErrors({ error: e.response.data, status: true });
      } else if (e.request.status === 403) {
        history.push("/app/unauthorized");
      } else {
        alertError("Error al buscar los documentos");
      }
    });
  }
  }, [meta.path.topic_id]);
  useEffect(() => {
    if (topics.status === STATUS_NOT_LOADED) {
      setTopics({ ...topics, status: STATUS_LOADING });
      client()
        .get("library/topic", {
          params: { with: ["entries", "adminsTopic", "adminsForum","effectives.consumers.consumable"] },
        })
        .then(({ data }) => {
          if (data) {
            let topicFilter = data.filter(
              (t) => isAdminTopic(t.id) || t.status_id === STATUS_APPROVED || user.is_admin
            );
            setTopics({
              status: STATUS_LOADED,
              elements: topicFilter,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los temas");
          }
        });
    }
  }, [topics, meta]);

  useEffect(() => {
    if (folders.status === STATUS_NOT_LOADED && meta.path.topic_id != null) {
      setFolders({ ...folders, status: STATUS_LOADING });
      client()
        .get("library/folder", {
          params: {
            filters: {
              topic_id: meta.path.topic_id,
              folder_id: meta.path.folder_id,
            },
            filtersLike: { name: meta.filterGeneral },
            with: ["documents"],
          },
        })
        .then(({ data }) => {
          if (data) {
            let folderFilter = data.filter(
              (f) =>
                isAdminTopic(meta.path.topic_id) || f.status_id === STATUS_APPROVED || user.is_admin
            );

            setFolders({
              status: STATUS_LOADED,
              elements: folderFilter,
              originalItem: folderFilter,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las carpetas");
          }
        });
    }
  }, [folders, meta]);

  useEffect(() => {
    if (meta.path.topic_id !== null) {
      if (meta.topic.status === STATUS_NOT_LOADED) {
        setMeta({ ...meta, topic: { status: STATUS_LOADING } });
        client()
          .get(`library/topic/${meta.path.topic_id}`, {
            params: { with: ["adminsTopic", "adminsForum","effectives.consumers.consumable"] },
          })
          .then(({ data }) => {
            setMeta({ ...meta, topic: { ...data, status: STATUS_LOADED } });
          })
          .catch((e) => {
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("/app/unauthorized");
            } else {
              alertError("Error al buscar el tema");
            }
          });
      }
    }
  }, [meta]);

  useEffect(() => {
    if (documents.status === STATUS_NOT_LOADED) {
      setDocuments({ ...documents, status: STATUS_LOADING });
      (() => {
        let path = "";
        if (meta.path.folder_id != null) {
          path = `library/folder/${meta.path.folder_id}/documents`;
        } else if (meta.path.topic_id != null) {
          path = `library/topic/${meta.path.topic_id}/documents`;
        } else {
          return new Promise((resolve) => []);
        }

        return client().get(path, {
          params: {
            with: ["valorable", "myFavorite"],
            filtersLike: {
              title: meta.filterGeneral,
              description: meta.filterGeneral,
              references: meta.filterGeneral,
            },
            filters: { role: null },
          },
        });
      })()
        .then(({ data }) => {
          if (data) {
            let documentsFilter = data.filter(
              (f) =>
                isAdminTopic(meta.path.topic_id) || f.status_id === STATUS_APPROVED || user.is_admin
            );
            setDocuments({
              status: STATUS_LOADED,
              elements: documentsFilter,
              originalItem: documentsFilter,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los documentos");
            history.push("/app/topics");
          }
        });
    }
  }, [documents, meta.path]);

  useEffect(() => {
    setFolders({ ...folders, status: STATUS_NOT_LOADED });
    setDocuments({ ...documents, status: STATUS_NOT_LOADED });
    getBreadCrumb();
  }, [meta.path]);

  //functions
  let goTo = ({ topic_id, folder_id }) => {
    let topic = topic_id != meta.path.topic_id ? initalState.meta.topic : meta.topic;
    addViewTopic(topic_id);
    setMeta({
      ...meta,
      filterGeneral: null,
      topic,
      path: { ...meta.path, topic_id, folder_id: folder_id || null, folders },
      topic: { status: STATUS_NOT_LOADED },
    });
  };
  const addViewTopic = (topic_id) => {
    client()
      .get(`library/topic/${topic_id}/viewed/by/${user.id}`, {})
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al registrar el ingreso");
          history.push("/app/topics");
        }
      });
  };
  const createFolder = (publish, { name, is_free }) => {
    client()
      .post("library/folder", {
        name: name,
        is_free: is_free,
        folder_id: meta.path.folder_id,
        topic_id: meta.path.topic_id,
        status_id: publish ? STATUS_APPROVED : STATUS_ERASER,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Carpeta creada satisfactoriamente");
          setFolders({ ...folders, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la carpeta");
        }
      });
  };
  const editFolderAction = (publish) => {
    client()
      .post(`library/folder/${editFolder.id}`, {
        name: editFolder.name,
        is_free: editFolder.is_free,
        status_id: publish ? STATUS_APPROVED : STATUS_ERASER,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Carpeta editada satisfactoriamente");
          setFolders({ ...folders, status: STATUS_NOT_LOADED });
          seteditFolder(initalState.editFolder);
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear la carpeta");
        }
      });
  };
  const deleteFolder = (id) => {
    client()
      .delete(`library/folder/${id}`)
      .then(({ data }) => {
        let el = folders.elements.filter((e) => e.id !== id);
        setFolders({
          ...folders,
          elements: el,
        });
      });
  };

  const deleteTopic = (id) => {
    client()
      .delete(`library/topic/${id}`)
      .then(({ data }) => {
        alertSuccess("Tema eliminado satisfactoriamente");
        let el = topics.elements.filter((e) => e.id !== id);
        setTopics({
          ...topics,
          elements: el,
        });
      });
  };

  const updateFolder = (name, folder_id) => {
    client()
      .put(`library/folder/${folder_id}`, { name })
      .then(() => {
        setFolders({ ...folders, status: STATUS_NOT_LOADED });
      });
  };

  const createTopic = ({ name, mounth_cost, description, image, status_id }) => {
    var formData = new FormData();
    formData.append("document", image[image.length - 1]);
    formData.append("name", name);
    formData.append("mounth_cost", mounth_cost);
    formData.append("description", description);
    formData.append("role", "main_image");
    formData.append("is_private", false);
    formData.append("status_id", status_id);

    client()
      .post("library/topic", formData)
      .then(({ data }) => {
        alertSuccess("Tema creado satisfactoriamente");
        setTopics({ ...topics, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el tema");
        }
      });
  };

  const createDocument = ({ status_id, title, description, references }, document, is_free) => {
    var formData = new FormData();

    if (document) {
      let ext = document.name.split(".").pop();
      if (ext === EXT_DOCX || ext === EXT_DOC || ext === EXT_XLSX || ext === EXT_XLS) {
        formData.append("is_private", false);
      }
      formData.append("document", document);
    }
    formData.append("title", title);
    formData.append("description", description);
    formData.append("topic_id", meta.path.topic_id);
    formData.append("is_free", is_free);
    formData.append("references", references);
    formData.append("status_id", status_id);

    let path = "";
    if (meta.path.folder_id == null) {
      path = "topic/" + meta.path.topic_id + "/documented";
    } else {
      path = "folder/" + meta.path.folder_id + "/documented";
    }
    clientImage()
      .post("library/" + path, formData)
      .then(({ data }) => {
        if (data) {
          sendNotificationDocument(data);
          alertSuccess("Documento creado satisfactoriamente");
          setDocuments({ ...documents, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear el documento");
        }
      });
  };
  const sendNotificationDocument = (document) => {
   
    for (let b = 0; b < meta.topic.effectives.length; b++) {
      const effective = meta.topic.effectives[b];
      let days = moment.duration(moment(effective.end_date).diff(moment())).asDays();
    
      if (days > 0) {
        for (let s = 0; s < effective.consumers.length; s++) {
       
          const subscription = effective.consumers[s];
         if(subscription.consumable){
          db.collection(`users/${subscription.consumable.firebase_id}/notifications`).add({
            viewed: false,
            touched: false,
            body: `ha creado un documento titulado  <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/library/document/${document.id}"> ${document.title}</a> `,
            owner_person_id: user.id,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            owner_person_name: user.first_name + " " + user.last_name,
            type: NOTIFICATION_ADD_DOCUMENT,
            module: NOTIFICATION_MODULE_LIBRARY,
          });
         }
          
        }
      }
    }
  };
  const updateDocument = ({ status_id, id, title, description, references }, document, is_free) => {
    var formData = new FormData();
    if (document) {
      formData.append("document", document);
    }

    formData.append("title", title);
    formData.append("description", description);
    formData.append("topic_id", meta.path.topic_id);
    formData.append("is_private", true);
    formData.append("is_free", is_free);
    formData.append("references", references);
    formData.append("status_id", status_id);

    clientImage()
      .post(`library/document/${id}`, formData)
      .then(({ data }) => {
        if (data) {
          alertSuccess("Documento editado satisfactoriamente");
          setDocuments({ ...documents, status: STATUS_NOT_LOADED });
        }
      })

      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el documento");
        }
      });
  };
const moveDocumentToFolder=(document,folder_id)=>{
  var formData = new FormData();

  formData.append("documentable_type", MODEL_FOLDER);
  formData.append("documentable_id", folder_id);
if(!folder_id){
  formData.append("documentable_type", MODEL_TOPIC);
  formData.append("documentable_id", meta.path.topic_id);
}
  client()
    .post(`library/document/${document.id}`, formData)
    .then(({ data }) => {
      if (data) {
        alertSuccess("Documento movido satisfactoriamente");
        setDocuments({ ...documents, status: STATUS_NOT_LOADED });
      }
    })

    .catch((e) => {
      if (e.request.status === 401) {
        logout();
      } else if (e.request.status === 422) {
        setErrors({ error: e.response.data, status: true });
      } else if (e.request.status === 403) {
        history.push("/app/unauthorized");
      } else {
        alertError("Error al mover el documento");
      }
    });
}
  const deleteDocument = (id) => {
    client()
      .delete(`library/document/${id}`)
      .then(({ data }) => {
        let el = documents.elements.filter((e) => e.id != id);
        setDocuments({
          ...documents,
          elements: el,
          status: STATUS_NOT_LOADED,
        });
      });
  };

  const updateTopic = ({ id, name, mounth_cost, description, image, status_id }) => {
    var formData = new FormData();
    formData.append("document", image[image.length - 1]);
    formData.append("name", name);
    formData.append("is_private", false);
    formData.append("mounth_cost", mounth_cost);
    formData.append("description", description);
    formData.append("status_id", status_id);

    client()
      .post(`library/topic/${id}`, formData)
      .then(() => {
        alertSuccess("Tema actualizado satisfactoriamente");
        setTopics({ ...topics, status: STATUS_NOT_LOADED });
        history.push(`/app/library/topics`);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el tema");
        }
      });
  };

  //filters
  const setStateHead = (field) => {
    setFilters({
      ...filters,
      [field]: !filters[field],
    });
  };
  const checkSubscription = () => {
    if (user.topicsActive.length > 0) {
      if (meta.path.topic_id != null) {
        let checkSub = user.topicsActive.filter(
          (t) => t.id == meta.path.topic_id
        );
        return checkSub.length > 0 ? true : false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const setStateData = (filters, order) => {
    if (documents.status === STATUS_LOADED && folders.status === STATUS_LOADED) {
      let originDocuments = documents.originalItem;
      let originFolders = folders.originalItem;
      let finalFolders = [];
      let dataFilter = originDocuments;
      let finalFilterIsFree = [];
      let finalFilterType = [];
      let showFolders = false;
      let showDocuments = false;
      let orderFixed = false;
      let orderBest = false;
      let orderRecent = false;

      let premium = dataFilter.filter((e) => e.is_free === false);
      let free = dataFilter.filter((e) => e.is_free === true);
      let premiumFolders = originFolders.filter((e) => e.is_free === false);
      let freeFolders = originFolders.filter((e) => e.is_free === true);
      switch (order) {
        case "fixed":
          orderFixed = true;
          break;
        case "recent":
          orderRecent = true;
          break;
        case "best":
          orderBest = true;
          break;
      }
      filters.map((filt) => {
        switch (filt) {
          case "folders":
            showFolders = true;
            break;
          case "documents":
            showDocuments = true;
            break;

          case "premium":
            for (let i = 0; i < premium.length; i++) {
              finalFilterIsFree.push(premium[i]);
            }
            for (let i = 0; i < premiumFolders.length; i++) {
              finalFolders.push(premiumFolders[i]);
            }

            break;
          case "free":
            for (let i = 0; i < free.length; i++) {
              finalFilterIsFree.push(free[i]);
            }
            for (let i = 0; i < freeFolders.length; i++) {
              finalFolders.push(freeFolders[i]);
            }
            break;
        }
      });
      filters.map((filt) => {
        switch (filt) {
          case "read":
            let filterRead = finalFilterIsFree.filter((e) => e.type == "pdf" || e.type == "PDF");

            for (let i = 0; i < filterRead.length; i++) {
              finalFilterType.push(filterRead[i]);
            }
            break;
          case "image":
            let filterImage = finalFilterIsFree.filter(
              (e) =>
                e.type == "png" ||
                e.type == "jpg" ||
                e.type == "jpeg" ||
                e.type == "PNG" ||
                e.type == "JPG" ||
                e.type == "JPEG"
            );

            for (let i = 0; i < filterImage.length; i++) {
              finalFilterType.push(filterImage[i]);
            }
            break;
          case "media":
            let filterMedia = finalFilterIsFree.filter((e) => e.type == "mp4" || e.type == "MP4");
            for (let i = 0; i < filterMedia.length; i++) {
              finalFilterType.push(filterMedia[i]);
            }
            break;
          case "download":
            let filterDownload = finalFilterIsFree.filter(
              (e) =>
                e.type == "docx" ||
                e.type == "docm" ||
                e.type == "dotx" ||
                e.type == "doc" ||
                e.type == "xlsx" ||
                e.type == "xls" ||
                e.type == "DOCX" ||
                e.type == "DOCM" ||
                e.type == "DOTX" ||
                e.type == "DOC" ||
                e.type == "XLSX" ||
                e.type == "XLS"
            );
            for (let i = 0; i < filterDownload.length; i++) {
              finalFilterType.push(filterDownload[i]);
            }

            break;
        }
      });

      let foldersData = showFolders ? finalFolders : [];
      let documentsData = showDocuments ? finalFilterType : [];

      //orden los documentos  fijos de primero
      if (orderFixed) {
        let orderFixedArray = [];
        //se sacan los documentos fijos
        for (let i = 0; i < documentsData.length; i++) {
          if (documentsData[i].my_favorite !== null) {
            orderFixedArray.push(documentsData[i]);
          }
        }
        //se sacan los documentos que no estan fijos en el final de l array
        for (let i = 0; i < documentsData.length; i++) {
          if (documentsData[i].my_favorite === null) {
            orderFixedArray.push(documentsData[i]);
          }
        }
        documentsData = orderFixedArray;
      } else if (orderBest) {
        for (let i = 0; i < documentsData.length; i++) {
          let score = 0;
          for (let v = 0; v < documentsData[i].valorable.length; v++) {
            score = score + parseInt(documentsData[i].valorable[v].value);
          }
          if (score > 0) {
            documentsData[i].score = score / documentsData[i].valorable.length;
          } else {
            documentsData[i].score = score;
          }
        }
        let newSort = documentsData.sort(function (a, b) {
          if (a.score < b.score) {
            return 1;
          }
          if (a.score > b.score) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        documentsData = newSort;
      } else if (orderRecent) {
        documentsData = documentsData.sort(function (a, b) {
          if (a.created_at < b.created_at) {
            return 1;
          }
          if (a.created_at > b.created_at) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }

      setDocuments({
        ...documents,
        elements: documentsData,
      });
      setFolders({
        ...folders,
        elements: foldersData,
      });
    }
  };
  const getBreadCrumb = () => {
    if (meta.path.folder_id != null) {
      client()
        .get(`library/breadCrumbFolder/${meta.path.folder_id}`)
        .then(({ data }) => {
          setbreadCrumbFolder(data);
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar el breadcrumb");
          }
        });
    } else {
      setbreadCrumbFolder([]);
    }
  };
  const filterGeneral = (param) => {
    let body = param;
    if (body == "") {
      body = null;
    }
    setMeta({ ...meta, filterGeneral: body });
    setFolders({ ...folders, status: STATUS_NOT_LOADED });
    setDocuments({ ...documents, status: STATUS_NOT_LOADED });
  };

  const deleteAdminTopic = (person_id, role) => {
    client()
      .post(`library/detachAuditorTopic`, {
        person_id: person_id,
        topic_id: meta.topic.id,
        role: role,
      })
      .then(({ data }) => {
        alertSuccess("Permiso removido satisfactoriamente");
        setMeta({ ...meta, topic: { ...data, status: STATUS_NOT_LOADED } });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al quitar el permiso");
        }
      });
  };
  const searchUserAdmin = (email) => {
    client()
      .get(`security/person`, {
        params: {
          filters: { email: email },
        },
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setUserAdmin(data[0]);
        } else {
          alertError("Persona no encontrada");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el usuario");
        }
      });
  };
  const addAdminTopic = (role, date) => {
    client()
      .post(`library/attachAuditorTopic`, {
        role: role,
        audit_time: date,
        person_id: userAdmin.id,
        topic_id: meta.topic.id,
      })
      .then(({ data }) => {
        setMeta({ ...meta, topic: { ...data, status: STATUS_NOT_LOADED } });
        alertSuccess("Permiso asignado satisfactoriamente");
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al asignar el permiso");
        }
      });
  };
  let fixedDocument = (document_id) => {
    let dataForm = {
      favorite: true,
      person_id: user.id,
    };

    client()
      .post(`library/document/${document_id}/updateView`, dataForm)
      .then(({ data }) => {
        if (data) {
          setDocuments({ ...documents, status: STATUS_NOT_LOADED });
          alertSuccess("Documento añadido a favoritos satisfactoriamente");
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir a favoritos");
        }
      });
  };
  return (
    <LibraryContext.Provider
      value={[
        {
          documentsWithOutRecursive,
          breadCrumbFolder,
          filters,
          meta,
          folders,
          documents,
          topics,
          showData,
          userAdmin,
          editFolder,
          listLibrary
        },
        {
          goTo,
          deleteTopic,
          createFolder,
          deleteFolder,
          updateFolder,
          createTopic,
          updateTopic,
          createDocument,
          updateDocument,
          deleteDocument,
          setStateHead,
          setStateData,
          checkSubscription,
          filterGeneral,
          setshowData,
          deleteAdminTopic,
          searchUserAdmin,
          addAdminTopic,
          fixedDocument,
          seteditFolder,
          editFolderAction,
          moveDocumentToFolder,
          setlistLibrary
        },
      ]}
    >
      {children}
    </LibraryContext.Provider>
  );
};
