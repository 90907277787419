import React, { useState, useEffect, useContext } from "react";
import Select, { components } from "react-select";
import {
  STATUS_LOADED,
  AuditPlanFormContext,
} from "../../../contexts/audit/AuditPlanFormContext";
import es from "date-fns/locale/es";
import moment from "moment";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../audit/AddAudit.css";
import "../modals/CreateActivity.css";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import "../audit/form.css";
import imgSearchButton from "../../../img_zeus/icons_audit/Lupa.png";
import { Field, Form } from "react-final-form";
import DateTimePicker from "react-datetime-picker";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";

import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
registerLocale("es", es);
export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    audit_id: "",
    name: "",
    description: "",
    location: "",
    audit_participants: [],
    is_evaluable: true,
    process_id: null,
    items: [],
  },
  picker: {
    init_date: new Date(),
    end_date: new Date(),
  },
  itemsDeploy: {},
};

const CreateActivity = ({ state, setState, activity_id, participants }) => {
  const [
    { audit, itemsSelectedActivity },
    { addActivity, updateActivity },
  ] = useContext(AuditPlanFormContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  const [picker, setpicker] = useState(initialState.picker);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  let [{ user }, {}] = useContext(SecurityContext);
  const [evaluable, setevaluable] = useState(true);
  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  let processCompany = audit.originalItem.company.process;
  useEffect(() => {
    if (activity_id && audit.status === STATUS_LOADED) {
      let el = audit.originalItem.activities.filter(
        (e) => e.id === activity_id
      )[0];
      let items = [];
      for (let i = 0; i < el.activity_items.length; i++) {
        items.push(el.activity_items[i].id);
      }

      let evaluators = el.audit_participants.filter(
        (e) =>
          e.participant_type.is_audit == true && e.pivot.is_evaluator == true
      );
      if (evaluators.length > 0) {
        if (el.is_evaluable) {
          evaluators = evaluators[0];
        } else {
          evaluators = evaluators;
        }
      }
      setStateCreate({
        ...stateCreate,
        item: {
          audit_id: el.audit_id,
          name: el.name,
          is_evaluable: el.is_evaluable,
          description: el.description,
          audit_participants: el.audit_participants.filter(
            (p) => p.pivot.is_evaluator == false
          ),
          evaluators: evaluators,
          location: el.location,
          items: items,
          process_id: el.process,
        },
        status: STATUS_EDIT,
      });

      setpicker({
        ...picker,
        init_date: new Date(el.init_date),
        end_date: new Date(el.end_date),
      });
      setevaluable(el.is_evaluable);
    }
    //creacion
    if (activity_id == null && audit.status === STATUS_LOADED) {
      setStateCreate({
        ...stateCreate,
        item: {
          audit_participants: [],
        },
      });
      let init_date = moment(audit.originalItem.init_date).format("YYYY-MM-DD");
      init_date = moment(init_date + " 07:00:00");

      if (audit.originalItem.activities.length > 0) {
        init_date = moment(
          audit.originalItem.activities[
            audit.originalItem.activities.length - 1
          ].end_date
        );
      }
      let end_date = moment(init_date).add(1, "hours");

      setpicker({
        ...picker,
        init_date: new Date(init_date),
        end_date: new Date(end_date),
      });
    }
  }, [activity_id, audit]);
  let isEnable = () => {
    let enable = true;
    if (
      audit.originalItem.status_id == STATUS_INPROGRESS ||
      audit.originalItem.status_id == STATUS_APPROVED
    ) {
      enable = false;
    }

    return enable;
  };
  let functionSubmit = (formObj) => {
    if (!formObj.evaluators && formObj.is_evaluable) {
      Swal.fire({
        title: "El evaluador es requerido",
        icon: "warning",
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        confirmButtonText: "Entendido",
      }).then((result) => {});
      return false;
    }
    if (formObj.audit_participants == null) {
      formObj.audit_participants = [];
    }
    if (stateCreate.status === STATUS_EDIT) {
      updateActivity(activity_id, formObj);
      setState({ ...state, toggle_modal: false });
    } else {
      if (formObj.is_evaluable && itemsSelectedActivity.length == 0) {
        Swal.fire({
          title: "Debe seleccionar items",
          icon: "warning",
          confirmButtonColor: "#df8c37",
          cancelButtonColor: "#171e27",
          confirmButtonText: "Entendido",
        }).then((result) => {});

        return false;
      } else {
        addActivity(formObj);
        setState({ ...state, toggle_modal: false });
      }
    }
  };
  /*   useEffect(() => {
    if (stateCreate.status !== STATUS_EDIT) {
      let endDate = moment(picker.init_date)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:mm");
      setpicker({
        ...picker,
        end_date: new Date(endDate),
      });
    }
  }, [picker.init_date]); */
  return (
    <div className="flex container-general-create-activities">
      <Form
        onSubmit={(formObj) => {
          formObj.is_evaluable = evaluable;
          formObj.init_date = picker.init_date;
          formObj.end_date = picker.end_date;
          if (formObj.init_date >= formObj.end_date) {
            alert("la fecha fin debe ser mayor a la fecha de inicio");
            return false;
          }
          functionSubmit(formObj);
        }}
        initialValues={stateCreate.item}
      >
        {({ handleSubmit, hasValidationErrors }) => (
          <form
            onSubmit={handleSubmit}
            className={`${
              evaluable ? "width-evaluable-create-activity" : ""
            } form-activity flex-1`}
          >
            <div className="content-activity">
            {isEnable() && (
                <div className="flex">
                  <div
                    onClick={() => setevaluable(false)}
                    className={`btn-evaluable-activity ${
                      evaluable == false
                        ? "btn-not-evaluable-active"
                        : "btn-not-evaluable-inactive"
                    }`}
                  ></div>
                  <div
                    onClick={() => setevaluable(true)}
                    className={`btn-evaluable-activity ${
                      evaluable
                        ? " btn-evaluable-active"
                        : "btn-evaluable-inactive"
                    }`}
                  ></div>
                </div>
              )}
              <div className="form-group div-form-activity">
                <Field name="name" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Actividad</label>

                      <input
                        {...input}
                        className="form-control tipo-description"
                        placeholder="Nombre de la actividad"
                        type="text"
                        disabled={!isEnable()}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              

              <div className="form-group div-form-activity">
                <Field name="description">
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Descripción</label>
                      <textarea
                        {...input}
                        className="form-control tipo-description"
                        placeholder="Escriba la descripción de la actividad"
                        rows="3"
                        disabled={!isEnable()}
                      ></textarea>
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group w-100">
                <label className="tipo-title">Fecha de inicio</label>
                <br />
                <DatePicker
                  selected={picker.init_date}
                  onChange={(date) => setpicker({ ...picker, init_date: date })}
                  showTimeSelect
                  className="form-control tipo-description"
                  minDate={new Date(audit.originalItem.init_date)}
                  maxDate={new Date(audit.originalItem.end_date)}
                  timeFormat="HH:mm"
                  locale="es"
                  disabled={!isEnable()}
                  timeIntervals={30}
                  dateFormat="y-MM-dd HH:mm"
                />
              </div>
              <div className="form-group w-100">
                <label className="tipo-title">Fecha de finalización</label>
                <br />

                <DatePicker
                  selected={picker.end_date}
                  onChange={(date) => setpicker({ ...picker, end_date: date })}
                  showTimeSelect
                  className="form-control tipo-description"
                  minDate={picker.init_date}
                  maxDate={new Date(audit.originalItem.end_date)}
                  timeFormat="HH:mm"
                  locale="es"
                  disabled={!isEnable()}
                  timeIntervals={30}
                  dateFormat="y-MM-dd HH:mm"
                />
              </div>
              <div className="form-group div-form-activity">
                <Field name="location">
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Ubicación</label>
                      <input
                        {...input}
                        className="form-control tipo-description"
                        placeholder="Ubicación"
                        type="text"
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group div-form-activity">
                <Field name="process_id">
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Proceso</label>

                      <Select
                        {...input}
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        options={processCompany}
                        isClearable={true}
                        isMulti={!evaluable}
                      />
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group div-form-activity">
                <Field name="audit_participants">
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Participantes</label>
                      <Select
                        {...input}
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) => (
                          <div className="flex">
                            <img
                              className={` btn-auditor-activity-filter  cursor-action background-simple `}
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (option.person.image &&
                                  option.person.image.name)
                              }
                              onError={(e) => {
                                e.target.src = imgAduitorCheck;
                              }}
                            ></img>
                            &nbsp;{option.person.first_name}{" "}
                            {option.person.email}
                          </div>
                        )}
                        getOptionValue={(option) => option.id}
                        options={participants.filter(
                          (e) => e.participant_type.is_audit == false
                        )}
                        isMulti
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>

                <Field name="evaluators">
                  {({ input, meta }) => (
                    <>
                      <label className="tipo-title">Evaluador</label>

                      <Select
                        {...input}
                        className="tipo-description"
                        placeholder={"Seleccione una opción"}
                        getOptionLabel={(option) =>(
                          <div className="flex">
                            <img
                              className={` btn-auditor-activity-filter  cursor-action background-simple `}
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (option.person.image &&
                                  option.person.image.name)
                              }
                              onError={(e) => {
                                e.target.src = imgAduitorCheck;
                              }}
                            ></img>
                            &nbsp;{option.person.first_name} {option.person.last_name}
                          </div>
                        )}
                        getOptionValue={(option) => option.id}
                        options={participants.filter(
                          (e) => e.participant_type.is_audit
                        )}
                        isClearable={true}
                        isMulti={evaluable ? false : true}
                        isDisabled={!isEnable()}
                      />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
                <div className="flex">
                  <div>
                    <input
                      type="submit"
                      className=" btn-orange-default"
                      value="Guardar"
                    />{" "}
                    {!activity_id && hasValidationErrors && (
                      <span className="orange">* Campos requeridos</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>

      <div
        className={`${
          evaluable ? "" : "hidden"
        } flex-1 container-items-select-activity`}
      >
        {stateCreate.status == STATUS_EDIT && (
          <AddItemsActivity
            isEnable={isEnable}
            itemsSelected={stateCreate.item.items}
          />
        )}
        {stateCreate.status == STATUS_NEW && (
          <AddItemsActivity
            isEnable={isEnable}
            itemsSelected={stateCreate.item.items}
          />
        )}
      </div>
    </div>
  );
};
const AddItemsActivity = ({ isEnable, itemsSelected }) => {
  const [
    { audit, itemsActivity, itemsIntemplate },
    { addItemsActivity, syncItemsSelectedActivity },
  ] = useContext(AuditPlanFormContext);

  const [items, setitems] = useState(
    audit.originalItem.standard.auditable_items
  );
  const [originalItems, setoriginalItems] = useState(
    audit.originalItem.standard.auditable_items
  );

  const [checkItems, setcheckItems] = useState({
    isSync: true,
    items: itemsSelected,
  });
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [searchItem, setsearchItem] = useState("");

  useEffect(() => {
    if (items.length > 0) {
      let newItemsCopy = [...items];
      let newItems = recursiveRefreshItems(newItemsCopy, checkItems.items);
      setitems(newItems);
    }
  }, [checkItems]);

  const recursiveRefreshItems = (itemsCopy, checks) => {
    for (let i = 0; i < itemsCopy.length; i++) {
      var newObject = new Object(itemsCopy[i]);
      newObject.isActive = false;
      newObject.hidden = false;
      for (let c = 0; c < checks.length; c++) {
        if (checks[c] == itemsCopy[i].id) {
          newObject.isActive = true;
        }
      }
      if (itemsCopy[i].items != "") {
        newObject.items = recursiveRefreshItems(itemsCopy[i].items, checks);
      }
      itemsCopy[i] = newObject;
    }

    return itemsCopy;
  };

  const searchItemRecursive = () => (e) => {
    let body = e.target.value;
    setsearchItem(body);
    if (body == "") {
      setitems(originalItems);
    } else {
      let item = searchNumberItem(originalItems, body);
      if (item) {
        setitems([item]);
      } else {
        setitems([]);
      }
    }
  };

  const searchIntoItemsSelected = (item_id) => {
    let hasParentSelected = false;
    for (let i = 0; i < checkItems.length; i++) {
      let item = findItem(items, checkItems[i]);
      if (item.items != "") {
        if (recursiveSearchItem(item.items, item_id)) {
          hasParentSelected = true;
        }
      }
    }
    return hasParentSelected;
  };
  const searchNumberItem = (items, number) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].number == number) {
        return items[i];
      } else {
        if (items[i].items != "") {
          let item = searchNumberItem(items[i].items, number);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  const recursiveSearchItem = (items, item_id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == item_id) {
        return true;
      } else {
        if (items[i].items != "") {
          return recursiveSearchItem(items[i].items, item_id);
        }
      }
    }
  };
  const findItem = (items, item_id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id == item_id) {
        return items[i];
      } else {
        if (items[i].items != "") {
          let item = findItem(items[i].items, item_id);
          if (item) {
            return item;
          }
        }
      }
    }
  };
  const removechildItem = (items, itemsDelete) => {
    for (let i = 0; i < items.length; i++) {
      let exist = checkItems.items.filter((e) => e == items[i].id)[0];
      if (exist) {
        itemsDelete.push(exist);
      }
      if (items[i].items != "") {
        removechildItem(items[i].items, itemsDelete);
      }
    }
    return itemsDelete;
  };
  const addItemsChilds = (items, newItems) => {
    for (let i = 0; i < items.length; i++) {
      newItems.push(items[i].id);
      if (items[i].items != "") {
        newItems = addItemsChilds(items[i].items, newItems);
      }
    }
    return newItems;
  };
  const addItems = (item_id) => {
    let item = findItem(items, item_id);
    let itemParent = findItem(items, item.auditable_item_id);

    let itemsParentChecked = [];
    if (itemParent) {
      itemsParentChecked = validateParents(itemsParentChecked, item_id);
    }

    let newItems = [item_id];

    if (item.items != "") {
      newItems = addItemsChilds(item.items, newItems);
    }
    for (let i = 0; i < checkItems.items.length; i++) {
      newItems.push(checkItems.items[i]);
    }

    for (let i = 0; i < itemsParentChecked.length; i++) {
      newItems.push(itemsParentChecked[i]);
    }
    setcheckItems({
      ...checkItems,
      items: newItems,
      isSync: true,
    });
  };
  //valida si los hijos estan checkeados para checkerase èl automaticamente
  const validateParents = (arrayParents, item_id) => {
    let item = findItem(items, item_id);
    let itemParent = findItem(items, item.auditable_item_id);
    let allSelect = true;
    if (itemParent) {
      for (let i = 0; i < itemParent.items.length; i++) {
        if (itemParent.items[i].id != item_id) {
          if (itemParent.items[i].isActive == false) {
            allSelect = false;
          }
        }
      }
      if (allSelect) {
        arrayParents.push(itemParent.id);
        //si todos los hijos estan checkeados seguimos validando hacia atrás
        let arrayNewParents = validateParents(arrayParents, itemParent.id);
        if (arrayNewParents) {
          arrayParents = arrayNewParents;
        }
      }

      return arrayParents;
    }
  };
  const removeItem = (item_id) => {
    let arrayParents = [];
    let arrayChilds = [];

    arrayParents = getParents(arrayParents, item_id);
    arrayChilds = getChilds(arrayChilds, item_id);
    for (let i = 0; i < arrayChilds.length; i++) {
      arrayParents.push(arrayChilds[i]);
    }
    arrayParents.push(item_id);

    let items = checkItems.items.filter(function (item) {
      ///remueve padres
      return !arrayParents.includes(item);
    });
    items = items.filter(function (item) {
      //remueve hijos
      return !arrayChilds.includes(item);
    });

    setcheckItems({ ...checkItems, items: items, isSync: true });
  };
  const getParents = (arrayParents, item_id) => {
    let item = findItem(items, item_id);
    if (item.auditable_item_id != null) {
      let parent = findItem(items, item.auditable_item_id);
      arrayParents.push(parent.id);
      arrayParents = getParents(arrayParents, parent.id);
    }
    return arrayParents;
  };

  const getChilds = (arrayChilds, item_id) => {
    let item = findItem(items, item_id);
    if (item.items != "") {
      for (let i = 0; i < item.items.length; i++) {
        arrayChilds.push(item.items[i].id);
        arrayChilds = getChilds(arrayChilds, item.items[i].id);
        arrayChilds = getParents(arrayChilds, item.items[i].id);
      }
    }
    return arrayChilds;
  };
  useEffect(() => {
    syncItemsSelectedActivity(checkItems.items);
  }, [checkItems]);
  const isChecked = (item_id) => {
    let item = checkItems.items.filter((e) => e == item_id)[0];
    if (item) {
      return true;
    } else {
      return false;
    }
  };
  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };
  const getRecursiveNumberCalificables = (first, number, item) => {
    item.items.map((item) => {
      number = number + 1;
      number = getRecursiveNumberCalificables(false, number, item);
    });
    return number;
  };
  const getChildsChecked = (item, number) => {
    if (isChecked(item.id)) {
      number = getRecursiveNumberCalificables(false, number + 1, item);
    } else {
      if (recursiveBackTrackingParent(item)) {
        number = number + 1;
      }
      if (item.items != "") {
        for (let index = 0; index < item.items.length; index++) {
          number = getChildsChecked(item.items[index], number);
        }
      }
    }

    return number;
  };
  const getSelectedNumber = (first, item) => {
    return isChecked(item.id)
      ? getRecursiveNumberCalificables(first, 0, item)
      : "";
  };
  const backTrackingParent = (item) => {
    return recursiveBackTrackingParent(item) ? true : false;
  };
  const recursiveBackTrackingParent = (item) => {
    /*   if (item.auditable_item_id != null) {
      let itemParent = findItem(originalItems, item.auditable_item_id);
      if (itemParent) {
        if (isChecked(itemParent.id)) {
          return true;
        } else {
          return recursiveBackTrackingParent(itemParent);
        }
      }
    } */
  };
  return (
    <div className="container-intro-items">
      <div className="w-100 flex container-search-item tipo-cursiva">
        <input
          placeholder="Buscar numerales"
          value={searchItem}
          onChange={searchItemRecursive()}
          type="text"
        ></input>
        <img className="btn-search-item-activity" src={imgSearchButton}></img>
      </div>

      <RecursiveItems
        removeItem={removeItem}
        isChecked={isChecked}
        checkItems={checkItems}
        items={items}
        itemsIntemplate={itemsIntemplate}
        audit={audit.originalItem}
        addItems={addItems}
        backTrackingParent={backTrackingParent}
        setDeploy={setDeploy}
        itemDeploy={itemDeploy}
        first={true}
        getChildsChecked={getChildsChecked}
        isEnable={isEnable}
        getSelectedNumber={getSelectedNumber}
        getRecursiveNumberCalificables={getRecursiveNumberCalificables}
      />
    </div>
  );
};
const RecursiveItems = ({
  isChecked,
  checkItems,
  items,
  addItems,
  removeItem,
  getSelectedNumber,
  setDeploy,
  getChildsChecked,
  itemDeploy,
  backTrackingParent,
  first,
  getRecursiveNumberCalificables,
  isEnable,
  itemsIntemplate,
  audit,
}) => {
  return items
    .filter((i) => (audit.template_id ? itemsIntemplate.includes(i.id) : true))
    .map((item) => {
      return (
        <ol key={item.id}>
          <li className={`${itemDeploy["item" + item.id] ? "dotted-li" : ""}`}>
            <div
              title={`${
                item.isActive
                  ? "Quitar selección de numeral"
                  : "Seleccione numeral"
              }`}
              className={` ${
                item.isActive ? "item-select-activity" : ""
              } ol-items-activity flex content-item-column cursor-action`}
            >
              <div className="container-circle-item flex">
                {item.items.length > 0 && (
                  <div
                    onClick={() => setDeploy(item.id)}
                    className={`${
                      itemDeploy["item" + item.id]
                        ? "circle-item-down-gray"
                        : "circle-item-up-gray"
                    } circle-number-items`}
                  >
                    {" "}
                    {item.items.length}
                  </div>
                )}
              </div>
              <div
                onClick={() => {
                  if (isEnable()) {
                    isChecked(item.id)
                      ? removeItem(item.id)
                      : addItems(item.id);
                  }
                }}
                className="flex-1"
              >
                <div>
                  <p
                    className={`${
                      item.required
                        ? "tipo-description"
                        : "evaluable-text-activity-item"
                    } text-item-activity`}
                  >
                    <strong>{item.number}</strong> {item.title}
                  </p>
                </div>
              </div>
              <div className="container-number-items-activity">
                <div className="flex container-intro-item-number">
                  {getChildsChecked(item, 0)}/
                  {getRecursiveNumberCalificables(first, 0, item) + 1}
                </div>
              </div>
            </div>
            {itemDeploy["item" + item.id] && (
              <>
                {item.items != "" && (
                  <RecursiveItems
                    removeItem={removeItem}
                    isChecked={isChecked}
                    checkItems={checkItems}
                    itemsIntemplate={itemsIntemplate}
                    audit={audit}
                    addItems={addItems}
                    items={item.items}
                    setDeploy={setDeploy}
                    itemDeploy={itemDeploy}
                    first={false}
                    isEnable={isEnable}
                    getChildsChecked={getChildsChecked}
                    backTrackingParent={backTrackingParent}
                    getSelectedNumber={getSelectedNumber}
                    getRecursiveNumberCalificables={
                      getRecursiveNumberCalificables
                    }
                  />
                )}
              </>
            )}
          </li>
        </ol>
      );
    });
};
export default CreateActivity;
