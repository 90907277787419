import React, { useContext, useState, useEffect } from "react";
import "./ViewActivities.css";
import moment from "moment";
import "moment/locale/es";

import ReactSpeedometer from "react-d3-speedometer";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import {
  AuditPlanFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanFormContext";
import imgError from "../../../img_zeus/icons_audit/user_error_activity.png";
import iconAccion from "../../../img_zeus/icons_audit/accion_1.png";
import iconEvaluation from "../../../img_zeus/icons_audit/evaluacion_1.png";
import execEvaluation from "../../../img_zeus/icons_audit/exec-evaluation.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { useHistory } from "react-router-dom";
const initialState = {
  mobile: {
    searchActivity: "",
    toogleAction: null,
  },
};
const ViewActivities = ({ computedMatch: { params, url } }) => {
  let { audit_id } = params;
  let history = useHistory();
  let [{ audit }, { getAudit, inProgressPlan }] = useContext(
    AuditPlanFormContext
  );
  const [{ user }, {}] = useContext(SecurityContext);
  const [mobile, setmobile] = useState(initialState.mobile);
  let [activities, setActivities] = useState([]);

  useEffect(() => {
    getAudit(audit_id);
  }, [audit_id]);
  useEffect(() => {
    if (audit.status === STATUS_LOADED) {
    }
  }, [audit]);
  /* FUNCTIONS MOBILE */
  const getDataItem = (object, item) => {
    if (item.auditable_item != null) {
      if (item.auditable_item.required) {
        object.numberCalificables = object.numberCalificables + 1;
        if (item.results.length > 0) {
          object.numberResult = object.numberResult + 1;
        }
      }
      else if (item.items != "") {
        for (let i = 0; i < item.items.length; i++) {
          object = getDataItem(object, item.items[i]);
        }
      }
    } 

    return object;
  };

  const recursiveAllEvaluations = (object, items) => {
    for (let i = 0; i < items.length; i++) {
      object = getDataItem(object, items[i]);
    }
    return object;
  };
  const getTotalNumber = (activity) => {
    let data = {
      numberCalificables: 0,
      numberResult: 0,
      percentage: 0,
    };

    for (let e = 0; e < activity.evaluations.length; e++) {
      recursiveAllEvaluations(data, activity.evaluations[e].items);
    }
    data.percentage =
      data.numberResult == 0 && data.numberCalificables == 0
        ? 0
        : Math.round((data.numberResult * 100) / data.numberCalificables);

    return data;
  };

  const getEvaluator = (is_evaluator, activity) => {
    let evaluator = {};
    let evaluated = [];
    for (let p = 0; p < activity.audit_participants.length; p++) {
      const eva = activity.audit_participants[p];
      if (eva.pivot.is_evaluator) {
        evaluator = eva.person;
      }
      if (eva.participant_type.is_audit == false) {
        evaluated.push(eva.person);
      }
    }
    return is_evaluator ? evaluator : evaluated;
  };
  const getIconStatus = (percentage) => {
    let icon = btn_inprogress;
    let text = "En progreso";
    if (percentage == 0) {
      icon = btn_created;
      text = "Creada";
    } else if (percentage == 100) {
      icon = btn_executed;
      text = "Finalizada";
    }
    return { icon: icon, text: text };
  };
  useEffect(() => {
    if (audit.status === STATUS_LOADED) {
      let newActivities = [...audit.originalItem.activities];
      if (mobile.toogleAction === true) {
        newActivities = newActivities.filter((e) => e.is_evaluable == false);
      } else if (mobile.toogleAction === false) {
        newActivities = newActivities.filter((e) => e.is_evaluable == true);
      }
      if (mobile.searchActivity != "") {
        newActivities = newActivities.filter(function (el) {
          return (
            el.name.toLowerCase().indexOf(mobile.searchActivity.toLowerCase()) >
            -1
          );
        });
      }
      setActivities(newActivities);
    }
  }, [mobile.searchActivity, mobile.toogleAction, audit]);
  const imParticipant = (activity) => {
    let isParticipant = false;
    for (let p = 0; p < activity.audit_participants.length; p++) {
      const participant = activity.audit_participants[p];
      if (participant.person_id == user.id) {
        isParticipant = true;
      }
    }
    return isParticipant;
  };
  let shortText = (text, range) => {
    if (text && text.length > range) {
      return text.substr(0, range) + "...";
    }
    return text;
  };
  return (
    <>
      {/*   ///////////////////  DEVICE MOBILE ////////////////////*/}
      <div className="general-activities-mobile hidden">
        <div className="flex bg-white container-header-mobile-activities">
          <div className=" flex margin-auto">
            <span
              onClick={() => setmobile({ ...mobile, toogleAction: null })}
              className={`color-span-mobile-activities ${
                mobile.toogleAction == null
                  ? "active-action-activities-mobile "
                  : ""
              }`}
            >
              Actividades
            </span>
          </div>
          <div className="flex-1 flex">
            <span
              onClick={() => setmobile({ ...mobile, toogleAction: true })}
              className={`flex color-span-mobile-activities ${
                mobile.toogleAction ? "active-action-activities-mobile" : ""
              }`}
            >
              <div className="icon-action-activity-mobile"></div> Acción
            </span>
            <span
              onClick={() => setmobile({ ...mobile, toogleAction: false })}
              className={`flex color-span-mobile-activities ${
                mobile.toogleAction === false
                  ? "active-action-activities-mobile"
                  : ""
              }`}
            >
              <div className="icon-evaluation-activity-mobile"></div>
              Evaluación
            </span>
          </div>
        </div>
        <div className="general-content-activities-mobile flex flex-column">
          {activities &&
            activities.map((activity, index) => {
              return (
                <div
                  className={`${
                    activities.length - 1 == index
                      ? "activities-end-mobile"
                      : ""
                  }`}
                  key={activity.id}
                  onClick={() =>
                    imParticipant(activity)
                      ? history.push(`/app/audit/activity/${activity.id}`)
                      : ""
                  }
                >
                  <div
                    className={`flex container-tarjet-activity-mobile ${
                      activity.is_evaluable
                        ? "bg-white"
                        : "container-not-evaluable-mobile"
                    } `}
                  >
                    {activity.is_evaluable && (
                      <>
                        <div>
                          {
                            <img
                              className="img-person-activity-mobile"
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (getEvaluator(true, activity).image &&
                                  getEvaluator(true, activity).image.name)
                              }
                              onError={(e) => {
                                e.target.src = imgError;
                              }}
                            />
                          }
                        </div>
                        <div className={`flex-1 tipo-description  `}>
                          <div className="content-name-mobile-activities">
                            <p>{shortText(activity.name, 21)}</p>

                            <>
                              <label>
                                Auditor:{" "}
                                <span className="txt-lower">
                                  {shortText(
                                    getEvaluator(true, activity).first_name +
                                      " " +
                                      getEvaluator(true, activity).last_name,
                                    22
                                  )}{" "}
                                </span>
                              </label>
                              <label>
                                Evaluados:{" "}
                                <span className="txt-lower">
                                  {getEvaluator(false, activity).length == 0
                                    ? "Sin asignar"
                                    : getEvaluator(false, activity).length > 1
                                    ? shortText(
                                        getEvaluator(false, activity)[0]
                                          .first_name,
                                        10
                                      ) +
                                      " " +
                                      getEvaluator(false, activity)[0]
                                        .last_name +
                                      " y " +
                                      getEvaluator(false, activity).length -
                                      1 +
                                      " más"
                                    : shortText(
                                        getEvaluator(false, activity)[0]
                                          .first_name +
                                          " " +
                                          getEvaluator(false, activity)[0]
                                            .last_name,
                                        25
                                      )}
                                </span>
                              </label>
                            </>
                          </div>

                          <div className="flex-1 flex  tipo-description container-progress-mobile-activities">
                            <div className="flex-1 flex container-items-activity-mobile">
                              <label>Items</label>
                              <div
                                className="content-meter-activity"
                                style={{ width: "80px", height: "45px" }}
                              >
                                <ReactSpeedometer
                                  ringWidth={12}
                                  needleHeightRatio={0.5}
                                  needleColor={"black"}
                                  forceRender={true}
                                  paddingHorizontal={0}
                                  paddingVertical={0}
                                  maxSegmentLabels={0}
                                  fluidWidth={true}
                                  minValue={0}
                                  maxValue={100}
                                  value={getTotalNumber(activity).percentage}
                                />
                              </div>
                            </div>

                            <div className="flex-1 flex txt-center status-text-activities-mobile ">
                              <div className="flex-1">
                                <img
                                  className="icon-status-activities-mobile"
                                  src={
                                    getIconStatus(
                                      getTotalNumber(activity).percentage
                                    ).icon
                                  }
                                />
                                <br />
                                {
                                  getIconStatus(
                                    getTotalNumber(activity).percentage
                                  ).text
                                }
                              </div>
                              <div className="flex-1">
                                <img
                                  className="icon-status-activities-mobile"
                                  src={
                                    activity.is_evaluable
                                      ? iconEvaluation
                                      : iconAccion
                                  }
                                />
                                <br />
                                {activity.is_evaluable
                                  ? "Evaluación"
                                  : "Acción"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {!activity.is_evaluable && (
                      <div className="flex flex-1 flex-column">
                        <div className="flex">
                          {" "}
                          <p className="tipo-description white">
                            {activity.name}
                          </p>
                        </div>
                        <div className="flex image-no-evaluable-mobile"></div>
                      </div>
                    )}
                    <div
                      className={`flex flex-column txt-center container-date-mobile-activity ${
                        activity.is_evaluable
                          ? ""
                          : "container-date-mobile-activity-no-evaluable"
                      }`}
                    >
                      <div
                        className={`tipo-titulo white ${
                          activity.is_evaluable ? "bg-orange" : "bg-green"
                        }`}
                      >
                        {moment(activity.init_date).format("MMMM YYYY")}
                      </div>
                      <div className="container-dates-activities-mobile">
                        <div>
                          {moment(activity.init_date)
                            .format("dddd")
                            .substr(0, 3)}
                        </div>
                        <div className="tipo-titulo">
                          {moment(activity.init_date).format("DD")}
                        </div>
                        <div className="tipo-description">
                          {moment(activity.init_date).format("hh:mm a")}
                        </div>
                        <div className="tipo-description">
                          {Math.round(
                            moment
                              .duration(
                                moment(activity.end_date).diff(
                                  moment(activity.init_date)
                                )
                              )
                              .asHours()
                          )}{" "}
                          hora(s)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="flex container-search-activity-mobile">
          <input
            placeholder="Escribe aquí"
            onChange={(e) =>
              setmobile({ ...mobile, searchActivity: e.target.value })
            }
            value={mobile.searchActivity}
            className="flex-1 tipo-cursiva"
            type="text"
          />
          <div className="btn-search-report background-simple"></div>
        </div>
      </div>
    </>
  );
};

export default ViewActivities;
