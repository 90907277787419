import React, { useEffect, useState, useContext } from "react";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";
import { useHistory } from "react-router-dom";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  status: STATUS_NOT_LOADED,
  specialties: {
    status: STATUS_NOT_LOADED,
    elements: []
  },
  errors: {
    error: [],
    status: false
  }
};

export const SpecialtiesIndexContext = React.createContext([]);

export const SpecialtiesIndexContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [specialties, setSpecialties] = useState(initialState.specialties);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();

  useEffect(() => {
    if (specialties.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/speciality`, {
          params: {
            with: []
          }
        })
        .then(({ data }) => {
          if (data) {
            setSpecialties({ ...specialties, status: STATUS_LOADED, elements: data });
          }
        })
        .catch(e => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar las especialidades");
          }
        });
    }
  }, [specialties]);

  const createSpecialty = ({ name, description }) => {
    client()
      .post("audit/speciality", {
        name: name,
        description: description
      })
      .then(({ data }) => {
        alertSuccess("Especialidad creada satisfactoriamente");
        setSpecialties({ ...specialties, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al crear especialidad");
        }
      });
  };

  const updateSpecialty = ({ name, description }, id) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    client()
      .post(`audit/speciality/${id}`, formData)
      .then(() => {
        alertSuccess("Especialidad actualizada satisfactoriamente");
        setSpecialties({ ...specialties, status: STATUS_NOT_LOADED });
        history.push(`/app/audit/specialties`);
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar especialidad");
        }
      });
  };

  const deleteSpecialty = id => {
    client()
      .delete(`audit/speciality/${id}`)
      .then(({ data }) => {
        alertSuccess("Especialidad eliminada satisfactoriamente");
        setSpecialties({ ...specialties, status: STATUS_NOT_LOADED });
      });
  };

  return (
    <SpecialtiesIndexContext.Provider
      value={[{ specialties }, { deleteSpecialty, createSpecialty, updateSpecialty }]}
    >
      {children}
    </SpecialtiesIndexContext.Provider>
  );
};
