exports = module.exports = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./../../../img_zeus/icons_audit/Boton_evaluacion.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./../../../img_zeus/icons_audit/Boton_evaluacion_1.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./../../../img_zeus/icons_audit/Boton_accion_2.png"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./../../../img_zeus/icons_audit/Boton_accion.png"));

// Module
exports.push([module.id, ".item-select-activity {\n  background-color: #ecae70 !important;\n  color: white !important;\n}\n.container-intro-items {\n  padding: 19px;\n}\n.container-number-items-activity {\n  margin: auto;\n}\n.container-intro-item-number {\n  background-color: #000;\n  border-radius: 5px;\n  font-size: 14px;\n  margin-right: 4px;\n  color: white;\n  padding: 4px;\n  padding-right: 6px;\n  padding-left: 6px;\n}\n\n.ol-items-activity {\n  padding: 0;\n}\n.container-search-item {\n  margin-bottom: 13px;\n  border-radius: 8px;\n  padding: 5px;\n  border: gray solid 1px;\n}\n.container-search-item > input {\n  flex: 2 1;\n  border: none;\n  font-size: 17px;\n  color: #adafb2;\n}\n.btn-evaluable-activity {\n  width: 129px;\n  margin-left: 13px;\n  margin-top: 19px;\n  height: 36px;\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.btn-evaluable-active {\n background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n.btn-evaluable-inactive {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n }\n.btn-not-evaluable-active {\n \n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.btn-not-evaluable-inactive{\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n\n.btn-create-activity {\n  margin-top: 13px;\n  border-radius: 12px;\n  margin-left: 35px;\n  background-color: #adafb2;\n}\n.btn-search-item-activity {\n  width: 36px;\n}\n.text-item-activity {\n  font-size: 15px;\n  line-height: 20px;\n  margin: 0;\n  padding: 3px;\n}\n.evaluable-text-activity-item {\n  font-family: SANS-SERIF;\n}\n.width-evaluable-create-activity {\n  flex: 0.6 1;\n}\n", ""]);

