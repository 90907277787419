import React, { useState, useContext, useEffect } from "react";
import {
  ActivityFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/activity/ActivityFormContext";
import "../../audit/activity/ShowActivity.css";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import imgError from "../../../img_zeus/icons_audit/user_error_activity.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import "moment/locale/es";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

const STATUS_EXECUTED = 4;

const initialState = {
  result: {
    numberCalificables: 0,
    numberResult: 0,
    percentage: 0,
    fulfill: 0,
    fails: 0,
    notApply: 0,
    documents: 0,
    notes: 0,
  },
  participants: {
    auditors: [],
    audited: [],
  },
};
const ShowActivity = ({ computedMatch: { params, url } }) => {
  let { activity_id } = params;
  const [{ activity }, { setActivityId }] = useContext(ActivityFormContext);
  const [result, setresult] = useState(initialState.result);
  const [{ user }, {}] = useContext(SecurityContext);
  const [participants, setparticipants] = useState(initialState.participants);
  const [canViewResult, setcanViewResult] = useState(true);
  useEffect(() => {
    setActivityId(activity_id);
  }, [activity_id]);
  useEffect(() => {
    if (activity.status == STATUS_LOADED) {
      if (
        (activity.element.audit.status_id != STATUS_EXECUTED &&
          activity.element.audit.company.contact.id == user.id &&
          activity.element.audit.lider_person.id != user.id) ||
        imEvaluated(activity.element)
      ) {
        setcanViewResult(false);
      }
      let participants = {
        auditors: [],
        audited: [],
      };

      for (let a = 0; a < activity.element.audit_participants.length; a++) {
        const participant = activity.element.audit_participants[a];
        if (participant.pivot.is_evaluator == true) {
          participants.auditors.push(participant.person);
        }
        if (participant.participant_type.is_audit == false) {
          participants.audited.push(participant.person);
        }
      }
      setparticipants(participants);

      let data = {
        numberCalificables: 0,
        numberResult: 0,
        percentage: 0,
        fulfill: 0,
        fails: 0,
        notApply: 0,
        documents: 0,
        notes: 0,
      };
      for (let e = 0; e < activity.element.evaluations.length; e++) {
        recursiveAllEvaluations(data, activity.element.evaluations[e].items);
      }
      data.percentage =
        data.numberResult == 0 && data.numberCalificables == 0
          ? 0
          : Math.round((data.numberResult * 100) / data.numberCalificables);
      setresult(data);
    }
  }, [activity]);
  const imEvaluated = (activity) => {
    let isEvaluated = false;
    for (let p = 0; p < activity.audit_participants.length; p++) {
      const participant = activity.audit_participants[p];
      if (
        participant.person.id == user.id &&
        participant.participant_type.is_audit == false
      ) {
        isEvaluated = true;
      }
    }
    return isEvaluated;
  };
  const getIconStatus = (percentage) => {
    let icon = btn_inprogress;
    let text = "En progreso";
    if (percentage == 0) {
      icon = btn_created;
      text = "Creada";
    } else if (percentage == 100) {
      icon = btn_executed;
      text = "Finalizada";
    }
    return { icon: icon, text: text };
  };
  const recursiveAllEvaluations = (object, items) => {
    for (let i = 0; i < items.length; i++) {
      object = getDataItem(object, items[i]);
    }

    return object;
  };
  const getDataItem = (object, item) => {
    if (item.auditable_item != null) {
      if (item.auditable_item.required) {
        object.numberCalificables = object.numberCalificables + 1;
        if (item.results.length > 0) {
          object.numberResult = object.numberResult + 1;
          if (item.results[0].is_valid == true) {
            object.fulfill = object.fulfill + 1;
          } else if (item.results[0].is_valid == false) {
            object.fails = object.fails + 1;
          } else {
            object.notApply = object.notApply + 1;
          }
        }
        object.documents = object.documents + item.documents.length;
        object.notes = object.notes + item.notes.length;
      }
    }
    if (item.items != "") {
      for (let i = 0; i < item.items.length; i++) {
        object = getDataItem(object, item.items[i]);
      }
    }

    return object;
  };
  const getDuration = (activity) => {
    let hours = moment
      .duration(moment(activity.end_date).diff(moment(activity.init_date)))
      .asHours();
    if (hours < 1) {
      return (
        Math.round(
          moment
            .duration(
              moment(activity.end_date).diff(moment(activity.init_date))
            )
            .asMinutes()
        ) + " minutos"
      );
    } else {
      return Math.round(hours) + " horas";
    }
  };
  const imEvaluator = () => {
    if (activity.status == STATUS_LOADED) {
      let isEvaluator = false;
      for (let p = 0; p < activity.element.audit_participants.length; p++) {
        const participant = activity.element.audit_participants[p];

        if (
          participant.person_id == user.id &&
          participant.pivot.is_evaluator
        ) {
          isEvaluator = true;
        }
      }
      return isEvaluator;
    }
  };
  return (
    <div className="flex container-general-show-activity-mobile hidden">
      <div className="flex container-title-activity-mobile-show">
        <div className="flex flex-1">
        <h6 className="flex-1 txt-center title-activity-mobile-show">{activity.element.name}</h6>
        </div>
        <div className="flex flex-0 margin-auto">
        <div
          className={` ${
            activity.element.is_evaluable
              ? "evaluable-activity-icon-mobile"
              : "action-activity-icon-mobile"
          }`}
        ></div>
        </div>
        
      
      </div>

      <div className="bg-orange txt-center date-show-activity-mobile white">
        <span>{moment(activity.element.init_date).format("dddd DD ")}</span>
        de
        <span>{moment(activity.element.init_date).format(" MMMM YYYY")}</span>
      </div>
      <div className="flex">
        <div className="flex">
          <img
            className="img-person-activity-mobile"
            src={
              process.env.REACT_APP_API_HOST +
              "/documents/" +
              (participants.auditors.length > 0 &&
                participants.auditors[0].image &&
                participants.auditors[0].image.name)
            }
            onError={(e) => {
              e.target.src = imgError;
            }}
          />
        </div>
        <div className="bg-white container-auditors-activity-mobile-show">
          <div className="flex container-auditors-ahow-activity-mobile">
            <div className="flex-1">
              <label className="tipo-title">AUDITOR</label>
              {participants.auditors.map((person, index) => {
                return (
                  <p className="tipo-description" key={index}>
                    {person.first_name} {person.last_name}
                  </p>
                );
              })}
              <label className="tipo-title">AUDITADOS</label>
              {participants.audited.map((person, index) => {
                return (
                  <p className="tipo-description" key={index}>
                    {person.first_name} {person.last_name}
                  </p>
                );
              })}
            </div>
            <div className="flex-1">
              <label className="tipo-title">INICIA</label>
              <p className="tipo-description">
                {moment(activity.element.init_date).format("hh:mm a")}
              </p>
              <label className="tipo-title">DURACIÓN</label>
              <p className="tipo-description">
                {getDuration(activity.element)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="white description-mobile-show-activity bg-orange">
        <div>
          <label>Ubicación</label>
          <p>{activity.element.location}</p>
        </div>
        {/*   <div>
          <label>Descripción</label>
          <p>{activity.element.description}</p>
        </div> */}
      </div>
      <div className="flex flex-column container-report-view-activity-mobile">
        <div className="flex">
          <div>
            <div className="icon-advance"></div>
          </div>
          <div>
            <div className="icon-percentage"></div>
          </div>
          <div>
            <div className="icon-like"></div>
          </div>
          <div>
            <div className="icon-dislike"></div>
          </div>
          <div>
            <div className="icon-notApply"></div>
          </div>
          <div>
            <div className="icon-images"></div>
          </div>
          <div>
            <div className="icon-notes"></div>
          </div>
        </div>
        <div className="flex result-show-mobile-container">
          <div>
            {result.numberResult}/{result.numberCalificables}
          </div>
          <div>{result.percentage}%</div>
          <div>{canViewResult ? result.fulfill : "-"}</div>
          <div>{canViewResult ? result.fails : "-"}</div>
          <div>{canViewResult ? result.notApply : "-"}</div>
          <div>{canViewResult ? result.documents : "-"}</div>
          <div>{canViewResult ? result.notes : "-"}</div>
        </div>
      </div>
      <div className="flex fixed-activity-mobile-evaluation">
        <div className="flex-1 flex container-status-activity-mobile justify-center">
          <p className="tipo-description">
            {getIconStatus(result.percentage).text}
          </p>
          &nbsp;
          <img
            className="icon-status-activity-mobile"
            src={getIconStatus(result.percentage).icon}
          />
        </div>
        {activity.status === STATUS_LOADED && (
          <>
            {(imEvaluator() || imEvaluated(activity.element)) && (
              <div className="flex flex-1">
                {activity.element.evaluations.length > 0 && (
                  <Link
                    to={`/app/audit/evaluation/${activity.element.evaluations[0].id}`}
                    className="flex  margin-auto background-simple btn-evaluation-activity-mobile"
                  ></Link>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShowActivity;
