import React, { useEffect, useState, useContext } from "react";
import client from "../../axios/axios-client";
import { SecurityContext } from "../../security/SecurityContext";
import { AlertContext } from "../../alerts/AlertContext";
import { useHistory } from "react-router-dom";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  status: STATUS_NOT_LOADED,
  activity: {
    activity_id: null,
    status: STATUS_NOT_LOADED,
    element: {},
  },
  errors: {
    error: [],
    status: false,
  },
};

export const ActivityFormContext = React.createContext([]);

export const ActivityFormContextProvider = ({ children }) => {
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [activity, setActivity] = useState(initialState.activity);

  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  let history = useHistory();

  useEffect(() => {
    if (activity.activity_id != null && activity.status === STATUS_NOT_LOADED) {
      setActivity({ ...activity, status: STATUS_LOADING });
      client()
        .get(`audit/audit_activity/${activity.activity_id}`, {
          params: {
            with: [
              "auditParticipants.person.image",
              "evaluations.items.auditable_item",
              "evaluations.items.results",
              "evaluations.items.documents",
              "evaluations.items.notes",
              "audit.company.contact",
              "audit.liderPerson",
             "auditParticipants.participantType",
            ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setActivity({ ...activity, status: STATUS_LOADED, element: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la actividad");
          }
        });
    }
  }, [activity.activity_id]);

  let setActivityId = (activity_id) => {
    setActivity({ ...activity, activity_id: activity_id, status: STATUS_NOT_LOADED });
  };

  return (
    <ActivityFormContext.Provider
      value={[
        { activity },
        {
          setActivityId,
        },
      ]}
    >
      {children}
    </ActivityFormContext.Provider>
  );
};
