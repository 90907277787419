import firebase from "firebase";
var firebaseConfig = {};
// Your web app's Firebase configuration

if (process.env.REACT_APP_IS_PRODUCTION === "false") {
  firebaseConfig = {
    apiKey: "AIzaSyA4PDx27aetBwJm87Htye3oJmA4S9mVwac",
    authDomain: "zeus-platform-dev.firebaseapp.com",
    databaseURL: "https://zeus-platform-dev.firebaseio.com",
    projectId: "zeus-platform-dev",
    storageBucket: "zeus-platform-dev.appspot.com",
    messagingSenderId: "990497716523",
    appId: "1:990497716523:web:7396b23b7fde0f2406ed6e",
    measurementId: "G-VEMBPZ9X0G",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBjinjgpT6ce45Mq6Jm5Hqrxkk_bcy-NoA",
    authDomain: "zeus-platform-266718.firebaseapp.com",
    databaseURL: "https://zeus-platform-266718.firebaseio.com",
    projectId: "zeus-platform-266718",
    storageBucket: "zeus-platform-266718.appspot.com",
    messagingSenderId: "98180334814",
    appId: "1:98180334814:web:fd3b878f7076b299b3ae4f",
    measurementId: "G-8DE64462ZL",
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
