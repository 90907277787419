import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import imgErrorDefault from "../../../img_zeus/circle-image.png";
import "./Document.css";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_MOV = "mov";

const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";

const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLS = "xls";
const EXT_XLSX = "xlsx";
const Document = ({ document }) => {
  const [openImage, setopenImage] = useState(null);
  const [documentSrc, setdocumentSrc] = useState("");
  const [{}, { getSrcDocumentWithBearer }] = useContext(SecurityContext);
  let history = useHistory();
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  useEffect(() => {
    if (openImage) {
      async function getSrc(document) {
        let doc = await getSrcDocumentWithBearer(document);
        setdocumentSrc(doc);
      }

      getSrc(openImage);
    }else{
      setdocumentSrc('');
    }
  }, [openImage]);
  return (
    <div title={document.title} className="">
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <div className="container-img-item-analyze flex " key={document.id}>
          <img
            className="cursor-action img-radius-analyze "
            onClick={() =>
              history.push(`../../../../app/library/document/${document.id}`)
            }
            onMouseEnter={() => setopenImage(document)}
            onMouseLeave={() => setopenImage(null)}
            src={documentSrc}
            onError={(e) => {
              e.target.src = imgErrorDefault;
            }}
            alt=""
          />
        </div>
      )}

      {document.type == EXT_PDF && (
        <div className="container-img-item-analyze flex " key={document.id}>
          <div
            className="cursor-action btn-pdf btn-files-observations"
            onClick={() =>
              history.push(`../../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}
      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div className="container-img-item-mobile flex " key={document.id}>
          <div
            className="cursor-action btn-word btn-files-observations"
            onClick={() =>
              downloadDocument(
                process.env.REACT_APP_API_HOST + "documents/" + document.name
              )
            }
          ></div>
        </div>
      )}
      {(document.type == EXT_XLSX || document.type == EXT_XLS) && (
        <div className="container-img-item-mobile flex " key={document.id}>
          <div
            className="cursor-action btn-excell btn-files-observations"
            onClick={() =>
              downloadDocument(
                process.env.REACT_APP_API_HOST + "documents/" + document.name
              )
            }
          ></div>
        </div>
      )}
      {document.type == EXT_MP3 && (
        <div className="container-img-item-mobile flex " key={document.id}>
          <div
            className="cursor-action btn-mp3-observation btn-files-observations"
            onClick={() =>
              history.push(`../../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}
      {(document.type == EXT_MP4 || document.type == EXT_MOV) && (
        <div className="container-img-item-mobile flex " key={document.id}>
          <div
            className="cursor-action btn-mp4-observation btn-files-observations"
            onClick={() =>
              history.push(`../../../../app/library/document/${document.id}`)
            }
          ></div>
        </div>
      )}
    </div>
  );
};

export default Document;
