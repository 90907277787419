import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ExecutedAuditGeneral from "./general/ExecutedAudit";
const ExecutedAudit = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">Ejecución de Auditoría</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Ejecute la auditoría, utilice la lista de verificación desde su
              celular o computador, capture evidencias, tome notas y
              observaciones.
            </p>
          </div>
        </div>
      </div>
 
     <ExecutedAuditGeneral/>
    </div>
  );
};

export default ExecutedAudit;
