import React, { useContext, useEffect, useState } from "react";

import HeaderAudit from "../HeaderAudit";
import "../evaluation/Index.css";
import CreateEvaluation from "../modals/CreateEvaluation";
import { EvaluationFormContext } from "../../../contexts/audit/evaluation/EvaluationFormContext";
import { Link, useHistory } from "react-router-dom";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconExecEvaluation from "../../../../src/img_zeus/icons_audit/evaluacion_1.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import btnExecuted from "../../../../src/img_zeus/icons_audit/BOTON-EJECUTAR.png";

import Swal from "sweetalert2";
const STATUS_CREATED = 1;
const STATUS_PLANNED = 2;
const STATUS_INPROGRESS = 3;

const initialState = {
  toggleCreateEvaluation: false,
};
const EvaluationIndex = ({ computedMatch }) => {
  const [
    { audit, template, modalEvaluation },
    { getAudit, deleteEvaluation, inEditModalEvaluation },
  ] = useContext(EvaluationFormContext);
  const { params, url, path } = computedMatch;
  let { audit_id } = params;
  let history = useHistory();
  audit_id = parseInt(audit_id);
  const { evaluations } = audit.element;
  const [evaluation, setevaluation] = useState(initialState);
  useEffect(() => {}, [audit]);

  useEffect(() => {
    getAudit(audit_id);
  }, [audit_id]);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  return (
    <div>
      {modalEvaluation.isOpen && (
        <div className="back-document">
          <CreateEvaluation />
        </div>
      )}

      <div className="flex">
        <div className="flex-1">
          <h2 className="title-evaluation tipo-title">EVALUACIONES</h2>
        </div>
        <div className="flex-1 flex justify-end">
          {/*         {(audit.element.status == STATUS_PLANNED || audit.element.status == STATUS_CREATED) && (
            <div
              onClick={() => {
                inEditModalEvaluation(null);
              }}
              className="cursor-action bg-orange img-create-evaluation"
            ></div>
          )} */}
        </div>
      </div>

      <div className="general-content-index-evaluation back-gray-content">
        <HeaderAudit audit={audit.element} />

        <table className="table table-evaluations">
          <thead>
            <tr className="header-table-index-evaluation">
              <th scope="col">TÍTULO</th>
              <th scope="col">DESCRIPCIÓN</th>
              <th scope="col">RESPONSABLE</th>
              <th scope="col">FECHA DE APLICACIÓN</th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {evaluations && evaluations.length == 0 && (
              <tr>
                <td colSpan="5" className="txt-center">
                  No hay evaluaciones{" "}
                </td>
              </tr>
            )}
            {evaluations &&
              evaluations.map((eva) => {
                return (
                  <tr className="tr-table-standars" key={eva.id}>
                    <td>{eva.title}</td>
                    <td>{eva.description}</td>
                    <td>
                      {eva.person_evaluation.first_name +
                        " " +
                        eva.person_evaluation.last_name}
                    </td>
                    <td>{eva.pivot.application_date}</td>
                    <td className="icons-standard-action">
                      {STATUS_INPROGRESS == audit.element.status && (
                        <img
                          onClick={() =>
                            history.push(`/app/audit/evaluation/${eva.id}`)
                          }
                          className="btn-executed-evaluation"
                          src={btnExecuted}
                        />
                      )}
                      {STATUS_INPROGRESS !== audit.element.status && (
                        <img
                          onClick={() =>
                            history.push(`/app/audit/evaluation/${eva.id}`)
                          }
                          title="ejecutar evaluación"
                          className="icon-actions cursor-action"
                          src={iconExecEvaluation}
                        />
                      )}
                      {(STATUS_CREATED == audit.element.status || STATUS_PLANNED==audit.element.status)&& (
                        <>
                          <img
                            onClick={() => {
                              inEditModalEvaluation(eva.id);
                            }}
                            title="editar evaluación"
                            className="icon-actions cursor-action"
                            src={iconEdit}
                          />
                          <img
                            onClick={() =>
                              checkDelete(
                                "¿Realmente deseas eliminar esta evaluación?",
                                `deleteEvaluation(${eva.id})`
                              )
                            }
                            title="eliminar evaluación"
                            className="icon-actions cursor-action"
                            src={iconDelete}
                          />
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EvaluationIndex;
