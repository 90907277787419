import React from "react";
import "./CreateProgram.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import EvidenceRemoteGeneral from "./general/EvidenceRemote";
const EvidenceRemote = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">Cargue evidencias remotas</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
            Cargue evidencias directamente a su lista de verificación.<br></br>

Habilite al auditado la carga de evidencias remotas, en los ítems a evaluar.
            </p>
          </div>
        </div>
      </div>
   
  <EvidenceRemoteGeneral/>
      

    </div>
  );
};

export default EvidenceRemote;
