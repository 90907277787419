import React from "react";
const EvidenceRemote = () => {
  return (
    <div className="flex flex-column">
  
 
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditor</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-orange white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Ejecute sus actividades y lista de verificación
desde su celular o computador.
          </div>
        </div>

        <div className="flex bg-white border-orange-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Habilite carga de evidencias remota a su lista
de verificación, por ítem evaluado.
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Reciba las evidencias en tiempo real,
evalúelas y deje sus anotaciones.
          </div>
        </div>
     
       
      </div>
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditado</p>
      </div>
      <div className="flex-column">
        <div className="border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Acceda desde su celular o computador a los
ítems habilitados para carga remota
          </div>
        </div>
        <div className="flex bg-orange white  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Cargue las evidencias, PDFs, imágenes, videos en
tiempo real etc. a la plataforma
          </div>
        </div>
        <div className="margin-help-bottom border-orange-help flex bg-white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Reciba retroalimentación del Auditor.
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default EvidenceRemote;
