import React from "react";
import GeneralLayout from "./layout/General";
import { Switch, Route, Router } from "react-router-dom";
import Topic from "../library/topic/topic";
import FolderForm from "../library/topic/folder/Form";
import ShowDocument from "../library/topic/folder/Show";
import ForumTopic from "../library/topic/forum";
import Subscribed from "../library/topic/topic_subscribed";
import SubDescription from "../library/topic/Subscription_description";
import Entry from "../library/topic/entry";
import Profile from "../security/profile/Profile";
import Unathorized from "./layout/Unauthorized";
import NoFound from "./layout/NoFound";
import EntryForm from "../library/topic/entryForm";
import AuditForm from "../audit/audit/form";
import AuditExpressForm from "../audit/audit_express/Form";
import { useHistory } from "react-router-dom";
import Activities from "../audit/audit/Activities";
import ViewActivities from "../audit/audit/ViewActivities";
import AuditIndex from "../audit/audit/index";
import AuditExpressIndex from "../audit/audit_express/Index";
import StandardIndex from "../audit/standard/index";
import StandardForm from "../audit/standard/Form";
import CompanyIndex from "../general/company/Index";
import CompanyForm from "../general/company/Form";
import CreateDocument from "../library/topic/documentForm";
import Admin from "../security/admin/Admin";
import Payment from "../billing/Payment";
import PaymentConfirm from "../billing/ConfirmPayment";
import Suscription from "../billing/Suscriptions";
import Bills from "../billing/ListBill";
import BillShow from "../billing/BillShow";
import Cart from "../billing/Cart";
import EvaluationIndex from "../audit/evaluation/Index";
import EvaluationForm from "../audit/evaluation/Form";
import ProgramIndex from "../audit/program/Index";
import ProgramForm from "../audit/program/Form";
import Dashboard from "../security/admin/Dashboard";
import Package from "../security/admin/Package";
import IndexTemplate from "../audit/template/Index";
import FormTemplate from "../audit/template/Form";
import PaymentTemplate from "../audit/template/Bill/Index";
import SpecialtiesIndex from "../audit/specialties/Index";
import InspectionForm from "../evaluation/inspection/Form";
import InspectionFormChilds from "../evaluation/inspection/FormChilds";

import InspectionIndex from "../evaluation/inspection/Index";
import IndexPointTopic from "../library/topicPoint/Index";
import AnalyzeIndex from "../audit/analyze/Index";
import AnalyzeForm from "../audit/analyze/Form";
import CourseIndex from "../general/course/Index";
import EffectiveForm from "../billing/effective/Form";
import EffectiveIndex from "../billing/effective/Index";
import EffectivePackage from "../billing/effective/Description";
import Swal from "sweetalert2";
import Home from "../app/Home";
/* PROVIDERS */
import { AuditPlanIndexContextProvider } from "../../contexts/audit/AuditPlanIndexContext";
import { SubscriptionFormContextProvider } from "../../contexts/library/SubscriptionFormContext";
import { CompanyIndexContextProvider } from "../../contexts/general/CompanyIndexContext";
import { CompanyFormContextProvider } from "../../contexts/general/CompanyFormContext";
import { HelpContextProvider } from "../../contexts/general/HelpContext";
import { SubscriptionContextProvider } from "../../contexts/library/SubscriptionContext";
import { AuditPlanFormContextProvider } from "../../contexts/audit/AuditPlanFormContext";
import { ActionPlanFormContextProvider } from "../../contexts/audit/action_plan/ActionPlanFormContext";
import { ActionPlanIndexContextProvider } from "../../contexts/audit/action_plan/ActionPlanIndexContext";

import { AuditContextProvider } from "../../contexts/audit/AuditContext";
import { LibraryContextProvider } from "../../contexts/library/LibraryContext";
import { EntryIndexContextProvider } from "../../contexts/forum/EntryIndexContext";
import { EntryContextProvider } from "../../contexts/forum/EntryContext";
import { DocumentFormContextProvider } from "../../contexts/library/DocumentFormContext";
import { AdminIndexContextProvider } from "../../contexts/security/AdminIndexContext";
import { BillingContextProvider } from "../../contexts/billing/BillingContext";
import { EffectiveFormContextProvider } from "../../contexts/billing/EffectiveFormContext";
import { ProgramIndexContextProvider } from "../../contexts/audit/ProgramIndexContext";
import { ProgramFormContextProvider } from "../../contexts/audit/ProgramFormContext";
import { TemplateIndexContextProvider } from "../../contexts/audit/TemplateContext/TemplateIndexContext";
import { TemplateFormContextProvider } from "../../contexts/audit/TemplateContext/TemplateFormContext";
import { EvaluationFormContextProvider } from "../../contexts/audit/evaluation/EvaluationFormContext";
import { TemplateBillContextProvider } from "../../contexts/billing/TemplateBillContext";
import { GeneralContextProvider } from "../../contexts/general/GeneralContext";
import { SpecialtiesIndexContextProvider } from "../../contexts/audit/specialties/SpecialtiesIndexContext";
import { ActivityFormContextProvider } from "../../contexts/audit/activity/ActivityFormContext";
import { StandardFormContextProvider } from "../../contexts/audit/StandardFormContext";
import { AuditExpressIndexContextProvider } from "../../contexts/audit/AuditExpressIndexContext";

import { InspectionFormContextProvider } from "../../contexts/evaluation/InspectionFormContext";
import ShowActivity from "../audit/activity/ShowActivity";
import Item from "../audit/evaluation/Item";
import { TemplateOnSaleContextProvider } from "../../contexts/audit/TemplateContext/TemplateOnSaleIndex";
import TemplatesOnSale from "../audit/template/TemplatesOnSale";
import CreateProgram from "../general/help/CreateProgram";
import CreateCompany from "../general/help/CreateCompany";
import ExecAudit from "../general/help/ExecAudit";
import AuditProgram from "../general/help/AuditProgram";
import CreateAuditCompany from "../general/help/CreateAuditCompany";

import HelpListMobile from "../general/help/ListMobile";
import General from "../general/help/General";
import Contact from "../general/help/Contact";
import PlanAudit from "../general/help/PlanAudit";
import ExecutedAudit from "../general/help/ExecutedAudit";
import FinishAudit from "../general/help/FinishAudit";
import EvidenceRemote from "../general/help/EvidenceRemote";
import ViewPdfPlan from "../audit/audit/ViewPdfPlan";
import { AuditExpressFormContextProvider } from "../../contexts/audit/AuditExpressFormContext";
import { InspectionIndexContextProvider } from "../../contexts/evaluation/InspectionIndexContext";
import { TopicPointIndexContextProvider } from "../../contexts/library/TopicPointIndexContext";
import { EffectiveIndexContextProvider } from "../../contexts/billing/EffectiveIndexContext";
import { CourseIndexContextProvider } from "../../contexts/general/CourseIndexContext";
import AnalyticForm from "../evaluation/analyticForm";

const App = ({ match }) => {
  let history = useHistory();
  return (
    <GeneralLayout>
      <Switch>
        {/* GENERAL */}

        <Route exact path={match.path} render={() => <Home />} />

        <Route
          exact
          path={`${match.path}/help`}
          render={() => <HelpListMobile />}
        />

        <HelpContextProvider exac path={`${match.path}/help`}>
          <Switch>
            <AuditProgram exact path={`${match.path}/help/audit_program`} />
            <CreateCompany exact path={`${match.path}/help/create_company`} />
            <CreateProgram exact path={`${match.path}/help/create_program`} />
            <ExecAudit exact path={`${match.path}/help/exec_audit`} />
            <Contact exact path={`${match.path}/help/contact`} />
            <CreateAuditCompany
              exact
              path={`${match.path}/help/audit_company`}
            />
            <PlanAudit exact path={`${match.path}/help/plan_audit`} />
            <ExecutedAudit exact path={`${match.path}/help/executed_audit`} />
            <FinishAudit exact path={`${match.path}/help/finish_audit`} />
            <EvidenceRemote exact path={`${match.path}/help/evidence_remote`} />

            <General exact path={`${match.path}/help/general/:module`} />
          </Switch>
        </HelpContextProvider>

        
        <Unathorized exact path={`${match.path}/unauthorized`} />
        <GeneralContextProvider exac path={`${match.path}/profile`}>
          <Switch>
            <Profile exact path={`${match.path}/profile`} />
          </Switch>
        </GeneralContextProvider>
        <Unathorized exact path={`${match.path}/unauthorized`} />

        <CompanyIndexContextProvider exact path={`${match.path}/companies`}>
          <Switch>
            <CompanyIndex exact path={`${match.path}/companies`} />
          </Switch>
        </CompanyIndexContextProvider>
        <CompanyFormContextProvider exac path={`${match.path}/companies`}>
          <Switch>
            <CompanyForm exact path={`${match.path}/companies/:company_id`} />
          </Switch>
        </CompanyFormContextProvider>
        {/* CURSO */}
        <Switch path={`${match.path}/course`}>
          <CourseIndexContextProvider path={`${match.path}/course`}>
            <Switch>
              <CourseIndex exact path={`${match.path}/course/courses`} />
            </Switch>
          </CourseIndexContextProvider>
        </Switch>
        {/* CURSO */}
        {/* SECURITY */}
        <Switch path={`${match.path}/security`}>
          <AdminIndexContextProvider path={`${match.path}/security/admin`}>
            <Switch>
              <Admin exact path={`${match.path}/security/admin/roles`} />
              <Dashboard
                exact
                path={`${match.path}/security/admin/dashboard`}
              />
              <Package exact path={`${match.path}/security/admin/packages`} />
            </Switch>
          </AdminIndexContextProvider>
        </Switch>
        {/* AUDIT */}
        <Switch path={`${match.path}/audit`}>
          <TemplateBillContextProvider
            path={`${match.path}/audit/paymentTemplate`}
          >
            <Switch>
              <PaymentTemplate
                exact
                path={`${match.path}/audit/paymentTemplate`}
              />
            </Switch>
          </TemplateBillContextProvider>
          <ActionPlanIndexContextProvider
            path={`${match.path}/audit/actions_plans`}
          >
            <Switch>
              <AnalyzeIndex exact path={`${match.path}/audit/actions_plans`} />
            </Switch>
          </ActionPlanIndexContextProvider>
          <ProgramIndexContextProvider path={`${match.path}/audit/programs`}>
            <Switch>
              <ProgramIndex exact path={`${match.path}/audit/programs`} />
            </Switch>
          </ProgramIndexContextProvider>
          <SpecialtiesIndexContextProvider
            path={`${match.path}/audit/specialties`}
          >
            <Switch>
              <SpecialtiesIndex
                exact
                path={`${match.path}/audit/specialties`}
              />
            </Switch>
          </SpecialtiesIndexContextProvider>
          <ActionPlanFormContextProvider path={`${match.path}/audit/analyze`}>
            <Switch>
              <AnalyzeForm
                exact
                path={`${match.path}/audit/analyze/:analyze_id`}
              />
            </Switch>
          </ActionPlanFormContextProvider>
          <ProgramFormContextProvider path={`${match.path}/audit/program`}>
            <Switch>
              <ProgramForm
                exact
                path={`${match.path}/audit/program/:program_id`}
              />
            </Switch>
          </ProgramFormContextProvider>

          <ActivityFormContextProvider path={`${match.path}/audit/activity`}>
            <Switch>
              <ShowActivity
                exact
                path={`${match.path}/audit/activity/:activity_id`}
              />
            </Switch>
          </ActivityFormContextProvider>

          <AuditContextProvider path={`${match.path}/audit/standards`}>
            <Switch>
              <StandardIndex exact path={`${match.path}/audit/standards`} />
            </Switch>
          </AuditContextProvider>

          <AuditContextProvider path={`${match.path}/audit/standards`}>
            <Switch>
              <StandardIndex exact path={`${match.path}/audit/standards`} />
            </Switch>
          </AuditContextProvider>

          <StandardFormContextProvider path={`${match.path}/audit/standard`}>
            <Switch>
              <StandardForm
                exact
                path={`${match.path}/audit/standard/:standard_id`}
              />
            </Switch>
          </StandardFormContextProvider>

          <AuditPlanIndexContextProvider path={`${match.path}/audit/audits`}>
            <Switch>
              <AuditIndex exact path={`${match.path}/audit/audits`} />
            </Switch>
          </AuditPlanIndexContextProvider>

          <TemplateOnSaleContextProvider
            path={`${match.path}/audit/templates/payment`}
          >
            <Switch>
              <TemplatesOnSale
                exact
                path={`${match.path}/audit/templates/payment`}
              />
            </Switch>
          </TemplateOnSaleContextProvider>
          <TemplateIndexContextProvider path={`${match.path}/audit/templates`}>
            <Switch>
              <IndexTemplate exact path={`${match.path}/audit/templates`} />
            </Switch>
          </TemplateIndexContextProvider>
          <TemplateFormContextProvider path={`${match.path}/audit/template`}>
            <Switch>
              <FormTemplate
                exact
                path={`${match.path}/audit/template/:template_id`}
              />
            </Switch>
          </TemplateFormContextProvider>
          <EvaluationFormContextProvider
            path={`${match.path}/audit/evaluation/item`}
          >
            <Switch>
              <Item
                exact
                path={`${match.path}/audit/evaluation/item/:item_id`}
              />
            </Switch>
          </EvaluationFormContextProvider>
          <EvaluationFormContextProvider
            path={`${match.path}/audit/evaluation`}
          >
            <Switch>
              <EvaluationForm
                exact
                path={`${match.path}/audit/evaluation/:evaluation_id`}
              />
            </Switch>
          </EvaluationFormContextProvider>

          <EvaluationFormContextProvider
            path={`${match.path}/audit/:audit_id/evaluations`}
          >
            <Switch>
              <EvaluationIndex
                exact
                path={`${match.path}/audit/:audit_id/evaluations`}
              />
            </Switch>
          </EvaluationFormContextProvider>

          <AuditPlanFormContextProvider path={`${match.path}/audit/:audit_id`}>
            <Switch>
              <AuditForm exact path={`${match.path}/audit/:audit_id`} />
              <ViewActivities
                exact
                path={`${match.path}/audit/:audit_id/activities`}
              />
              <ViewPdfPlan
                exact
                path={`${match.path}/audit/:audit_id/pdf/:module`}
              />
            </Switch>
          </AuditPlanFormContextProvider>
        </Switch>
        {/* INSPECTION LIST  */}
        <Switch path={`${match.path}/inspection`}>
        <InspectionFormContextProvider
            path={`${match.path}/inspection/evaluation/generate/:long_id`}
          >
            <Switch>
              <InspectionForm
                exact
                path={`${match.path}/inspection/evaluation/generate/:long_id`}
              />
               
            </Switch>
          </InspectionFormContextProvider>
          <InspectionFormContextProvider
            path={`${match.path}/inspection/evaluation/:evaluation_id`}
          >
            <Switch>
              <InspectionForm
                exact
                path={`${match.path}/inspection/evaluation/:evaluation_id`}
              />
                <InspectionFormChilds
                exact
                path={`${match.path}/inspection/evaluation/:evaluation_id/childs`}
              />
              <AnalyticForm
                exact
                path={`${match.path}/inspection/evaluation/:evaluation_id/analytic`}
              />
            </Switch>
          </InspectionFormContextProvider>
          <InspectionIndexContextProvider
            path={`${match.path}/inspection/evaluations`}
          >
            <Switch>
              <InspectionIndex
                exact
                path={`${match.path}/inspection/evaluations`}
              />
               
            </Switch>
          </InspectionIndexContextProvider>
        </Switch>
        {/* AUDIT EXPRESS */}
        <Switch path={`${match.path}/audit_express`}>
          <AuditExpressFormContextProvider
            path={`${match.path}/audit_express/audit/:audit_id`}
          >
            <Switch>
              <AuditExpressForm
                exact
                path={`${match.path}/audit_express/audit/:audit_id`}
              />
            </Switch>
          </AuditExpressFormContextProvider>
          <AuditExpressIndexContextProvider
            path={`${match.path}/audit_express/audits`}
          >
            <Switch>
              <AuditExpressIndex
                exact
                path={`${match.path}/audit_express/audits`}
              />
            </Switch>
          </AuditExpressIndexContextProvider>
        </Switch>
        {/* BILLING */}
        <Switch path={`${match.path}/billing`}>
          <BillingContextProvider path={`${match.path}/billing`}>
            <Switch>
              {/*  <Route exact path={`${match.path}/billing/suscriptions`} render={() => {
                Swal.fire({
                  title: "¡Próximamente activaremos esta funcionalidad! ",
                  
                  icon: "warning",
                  confirmButtonColor: "#df8c37",
                  confirmButtonText: "¡Entendido!",
                }).then((result) => {
                  history.goBack();
                });
            }} /> */}
              <Suscription exact path={`${match.path}/billing/suscriptions`} />
              <Bills exact path={`${match.path}/billing/bills`} />
              <Cart exact path={`${match.path}/billing/cart`} />
              <BillShow exact path={`${match.path}/billing/:bill_id`} />
            </Switch>
          </BillingContextProvider>
        </Switch>
        <Switch path={`${match.path}/effective`}>
          <EffectiveFormContextProvider
            path={`${match.path}/effective/documents`}
          >
            <Switch>
              <EffectiveForm
                exact
                path={`${match.path}/effective/documents/:effective_id`}
              />
            </Switch>
          </EffectiveFormContextProvider>
          <EffectiveFormContextProvider
            path={`${match.path}/effective/:effective_id`}
          >
            <Switch>
              <EffectivePackage
                exact
                path={`${match.path}/effective/:effective_id/description`}
              />
            </Switch>
          </EffectiveFormContextProvider>
        </Switch>
        <Switch path={`${match.path}/effectives`}>
          <EffectiveIndexContextProvider
            path={`${match.path}/effectives/documents`}
          >
            <Switch>
              <EffectiveIndex
                exact
                path={`${match.path}/effectives/documents/:model/:model_id`}
              />
            </Switch>
          </EffectiveIndexContextProvider>
        </Switch>
        {/* LIBRARY */}
        <Switch path={`${match.path}/library`}>
          <SubscriptionContextProvider
            path={`${match.path}/library/topics_subscribed`}
          >
            <Switch>
              <Subscribed
                exact
                path={`${match.path}/library/topics_subscribed`}
              />
              <Subscribed
                exact
                path={`${match.path}/library/topics_subscribed/module/:module_suscription`}
              />
              <SubscriptionFormContextProvider
                path={`${match.path}/library/topics_subscribed/:billDetail_id`}
              >
                <Switch>
                  <SubDescription
                    exact
                    path={`${match.path}/library/topics_subscribed/:billDetail_id`}
                  />
                </Switch>
              </SubscriptionFormContextProvider>
            </Switch>
          </SubscriptionContextProvider>
          <DocumentFormContextProvider path={`${match.path}/library/document`}>
            <Switch>
              <ShowDocument
                exact
                path={`${match.path}/library/document/:document_id`}
              />
            </Switch>
          </DocumentFormContextProvider>
          <TopicPointIndexContextProvider
            path={`${match.path}/library/pointsTopic`}
          >
            <Switch>
              <IndexPointTopic
                exact
                path={`${match.path}/library/pointsTopic`}
              />
            </Switch>
          </TopicPointIndexContextProvider>
          <LibraryContextProvider path={`${match.path}/library/topics`}>
            <Switch>
              <Topic exact path={`${match.path}/library/topics`} />
            </Switch>
          </LibraryContextProvider>
          <LibraryContextProvider path={`${match.path}/library/topic`}>
            <Switch>
              <BillingContextProvider
                path={`${match.path}/library/topic/paymentConfirm`}
              >
                <Switch>
                  <PaymentConfirm
                    exact
                    path={`${match.path}/library/topic/paymentConfirm`}
                  />
                </Switch>
              </BillingContextProvider>

              <BillingContextProvider
                path={`${match.path}/library/topic/billing/suscription/:moduleSelect`}
              >
                <Switch>
                  {/*    <Route exact path={`${match.path}/library/topic/billing/suscription/:moduleSelect`} render={() => {
                Swal.fire({
                  title: "¡Próximamente activaremos esta funcionalidad! ",
                  
                  icon: "warning",
                  confirmButtonColor: "#df8c37",
                  confirmButtonText: "¡Entendido!",
                }).then((result) => {
                  history.goBack();
                });
            }} /> */}
                  <Payment
                    exact
                    path={`${match.path}/library/topic/billing/suscription/:moduleSelect/:personal`}
                  />
                  <Payment
                    exact
                    path={`${match.path}/library/topic/billing/suscription/:moduleSelect`}
                  />
                </Switch>
              </BillingContextProvider>

              <FolderForm
                exact
                path={`${match.path}/library/topic/:topic_id`}
              />
              <FolderForm
                exact
                path={`${match.path}/library/topic/:topic_id/folder/:folder_id`}
              />
              <BillingContextProvider
                path={`${match.path}/library/topic/:topic_id/payment`}
              >
                <Switch>
                  <Payment
                    exact
                    path={`${match.path}/library/topic/:topic_id/payment`}
                  />
                </Switch>
              </BillingContextProvider>

              <CreateDocument
                exact
                path={`${match.path}/library/topic/:topic_id/create_document`}
              />
              <CreateDocument
                edit
                path={`${match.path}/library/topic/:topic_id/document/:document_id/edit`}
              />
              <EntryIndexContextProvider
                path={`${match.path}/library/topic/:topic_id/forum`}
              >
                <Switch>
                  <ForumTopic
                    exact
                    path={`${match.path}/library/topic/:topic_id/forum`}
                  />
                  <EntryForm
                    exact
                    path={`${match.path}/library/topic/:topic_id/forum/create`}
                  />
                  <EntryContextProvider>
                    <Switch>
                      <Entry
                        exact
                        path={`${match.path}/topic/:topic_id/forum/entry/:entry_id`}
                      />
                    </Switch>
                  </EntryContextProvider>
                </Switch>
              </EntryIndexContextProvider>
            </Switch>
          </LibraryContextProvider>
        </Switch>
        <NoFound />
      </Switch>
    </GeneralLayout>
  );
};

export default App;
