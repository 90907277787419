exports = module.exports = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../img_zeus/icons_audit/upItem.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../img_zeus/icons_audit/downItem.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../img_zeus//icons_audit/BOTON-CREAR-PLANTILLA.png"));

// Module
exports.push([module.id, "\n\n\n.table-template-index>tr>th{\n  border: none !important;\n\n}\nol{\n  list-style-type: none;\n}\n.content-item-column{\n  border-radius: 9px;\n  margin-bottom: 12px;\n  background-color: #E2E2E4;\n}\n.content-item-column>p{\n  padding: 8px;\n  font-size: 17px;\n  margin-left: 16px;\n}\n.circle-number-items{\n  width: 28px;\n  cursor: pointer;\n  text-align: center;\n  font-size: 12px;\n  margin: auto;\n  height: 30px;\n  border-radius: 11px;\n}\n.circle-item-down-gray{\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  color: white;\n  padding-top: 6px;\n}\n.circle-item-up-gray{\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n  color: white;\n  padding-top: 12px;\n\n}\n.content-item-orange{\n  background-color: #ffffff;\n  min-height: 53px;\n  border: #ECAE70 solid 3px;\n  color: #51555b;\n}\n.container-circle-item{\n  width: 28px;\n  position: relative;\n  left: -15px;\n}\n.p-item-description{\n  padding: 12px;\n  margin: auto;\n  line-height: 20px;\n}\n.container-actions-item{\n  width: 105px;\n}\n.container-margin-actions-item{\n  margin: auto;\n}\n.container-margin-actions-item>div{\n  width: 25px;\n  height:  25px;\n\n  margin-right: 6px;\n cursor: pointer;\n}\n.content-items-template{\n  margin-top: 15px;\n}\n.content-white-items-template{\n  padding: 8px;\n}\n.content-items-template>ol{\n  padding-left: 8px;\n}\n.img-create-template{\n  width: 187px;\n    height: 31px;\n    margin-top: 15px;\n    background-size: 100% 100%;\n    background-repeat: no-repeat;\n    margin-right: 15px;\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.table-template-index{\n  font-family: Avenir-Book;\n    color: #7c8085;\n}\n.content-stair-template{\n  width: 161px;\n}\n.title-template-form{\n  margin-right: 15px;\n}", ""]);

