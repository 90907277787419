import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { EntryIndexContext } from "./EntryIndexContext";
import { useHistory } from "react-router-dom";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";

let initialState = {
  meta: {
    entry: {},
    entryResponse: {},
    path: {
      entry_id: null,
      topic_id: null,
      entry_response_id: null
    },
    status: STATUS_NOT_LOADED
  },
  flag: {
    like: true
  },
  errors: {
    error: [],
    status: false
  },
  formErrors: {}
};

export const EntryContext = React.createContext([]);

export const EntryContextProvider = props => {
  let history = useHistory();
  let [entryResponse] = useState(initialState.entryResponse);
  let [meta, setMeta] = useState(initialState.meta);
  let [flag, setFlag] = useState(initialState.flag);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  const [{}, { refreshEntries }] = useContext(EntryIndexContext);

  useEffect(() => {
    if (
      meta.path.entry_id !== null &&
      meta.path.topic_id !== null &&
      meta.status === STATUS_NOT_LOADED
    ) {
      setMeta({ ...meta, status: STATUS_LOADING });
      client()
        .get(`library/entry/${meta.path.entry_id}`, {
          params: {
            with: ["personEntry", "entry_responses.personEntryResponse", "valorable"]
          }
        })
        .then(({ data }) => {
          setMeta({
            ...meta,
            status: STATUS_LOADED,
            entry: data,
            entryResponse: data.entry_responses
          });
        })
        .catch(e => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Problemas al cargar las respuestas");
          }
        });
    }
  }, [meta]);

  let addScore = (isLike, entry_response_id) => {
    setFlag({ ...flag, like: false });
    client()
      .post(`library/entry_response/${entry_response_id}/dataValorable`, {
        person_id: user.id,
        value: isLike
      })
      .then(({ data }) => {
        alertSuccess("Calificación agregada satisfactoriamente");
        if (data) {
          setFlag({ ...flag, like: true });
          setMeta({ ...meta, status: STATUS_NOT_LOADED });
        }
      });
  };

  let valorable = (like, resp) => {
    let el = resp.valorable && resp.valorable.filter(e => e.value == like);
    return el && el.length;
  };

  let classLike = (like, resp) => {
    let el =
      resp.valorable && resp.valorable.filter(e => e.person_id == user.id && e.value == like);
    return el.length > 0 ? true : false;
  };

  let setEntryAndTopicId = (entry_id, topic_id) => {
    if (entry_id !== meta.path.entry_id || topic_id !== meta.path.topic_id) {
      setMeta({
        ...meta,
        path: { ...initialState.meta.path, topic_id: topic_id, entry_id: entry_id }
      });
    }
  };

  const deleteEntryResponse = id => {
    client()
      .delete(`library/entry_response/${id}`)
      .then(({ data }) => {
        alertSuccess("Respuesta eliminada satisfactoriamente");
        setMeta({
          ...meta,
          status: STATUS_NOT_LOADED
        });
        refreshEntries();
      });
  };

  const createEntryResponse = (body, id) => {
    client()
      .post("library/entry_response", {
        body: body,
        person_id: user.id,
        entry_id: meta.path.entry_id,
        topic_id: meta.path.topic_id,
        entry_response_id: id
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Respuesta creada satisfactoriamente");
          setMeta({ ...meta, status: STATUS_NOT_LOADED });
          refreshEntries();
        }
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al cargar la respuesta");
        }
      });
  };

  const updateEntry = ({ body, title, id }) => {
    client()
      .put(`library/entry/${id}`, { body, title })
      .then(() => {
        alertSuccess("Pregunta actualizada satisfactoriamente");
        setMeta({ ...meta, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la respuesta");
        }
      });
  };

  const updateEntryResponse = ({ body, id }) => {
    client()
      .put(`library/entry_response/${id}`, { body })
      .then(() => {
        alertSuccess("Respuesta actualizada satisfactoriamente");
        setMeta({ ...meta, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la respuesta");
        }
      });
  };

  return (
    <EntryContext.Provider
      value={[
        { meta, entryResponse, flag },
        {
          setEntryAndTopicId,
          deleteEntryResponse,
          createEntryResponse,
          classLike,
          updateEntry,
          updateEntryResponse,
          valorable,
          addScore
        }
      ]}
    >
      {props.children}
    </EntryContext.Provider>
  );
};
