import React, { useState, useEffect, useContext } from "react";
import CreateParticipantAuditor from "../modals/CreateParticipantAuditor";
import CreateParticipantAudited from "../modals/CreateParticipantAudited";
import { AuditPlanFormContext } from "../../../contexts/audit/AuditPlanFormContext";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import Swal from "sweetalert2";
import imgError2 from "../../../img_zeus/default/PROFILE.jpeg";
import { SecurityContext } from "../../../contexts/security/SecurityContext";

export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;

const initialState = {
  toggle_modal: false,
  toggle_modal2: false,
  participant: null,
};

const Participants = ({ staff, participants, procces, audit }) => {
  const [
    { userAuditor },
    { deleteParticipantAuditor, setUserAuditor },
  ] = useContext(AuditPlanFormContext);
  const [teamCompany, setTeamCompany] = useState(staff);
  const [state, setState] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const hasActivities = (person_id) => {
    let hasActivities = false;
    for (let a = 0; a < audit.activities.length; a++) {
      const activity = audit.activities[a];
      const particpants = activity.audit_participants.filter(
        (p) => p.person_id == person_id
      );
      if (particpants.length > 0) {
        hasActivities = true;
      }
    }

    return hasActivities;
  };
  const checkHasActivity = (participant) => {
    if (!hasActivities(participant.person_id)) {
      checkDelete(
        "¿Realmente deseas eliminar este auditor?",
        `deleteParticipantAuditor(${participant.id})`
      );
    } else {
      Swal.fire({
        title: "Este participante tiene actividades asignadas",
        text:
          "reasigne las actividades de este participante para poder eliminarlo",
        icon: "warning",
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Entendido",
      });
    }
  };
  return (
    <div className="tipo-description">
      {state.toggle_modal === true && (
        <div className="back-document">
          <div className="modal-participants">
            <div className="header-modal-participants">
              <div
                className="salir-program"
                onClick={() => {
                  setState({
                    ...state,
                    toggle_modal: false,
                    participant: null,
                  });
                  setUserAuditor({});
                }}
              ></div>
              {state.participant !== null && (
                <h3 className="title-form-topic tipo-description">
                  Editar Auditor
                </h3>
              )}{" "}
              {state.participant === null && (
                <h3 className="title-form-topic tipo-description">
                  Agregar Auditor
                </h3>
              )}
            </div>
            <div>
              <CreateParticipantAuditor
                participants={participants}
                state={state}
                setState={setState}
                participant={state.participant}
                procces={procces}
              ></CreateParticipantAuditor>
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="header-participant-audit">
          <h5 className="d-inline-block title-team-auditor tipo-title">
            EQUIPO AUDITOR
          </h5>
          {(audit.status_id == STATUS_PLANNED ||
            audit.status_id == STATUS_CREATED) &&
            audit.lider_person_id == user.id && (
              <div
                onClick={() => {
                  setState({ ...state, toggle_modal: true });
                }}
                className="btn-orange-default-short"
              >
                Añadir auditor
              </div>
            )}
        </div>
        <table className="table table-bordered table-hover txt-center table-team-auditor">
          <thead className="tipo-title color-black-gray">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
              <th scope="col">Rol</th>
              {audit.lider_person_id == user.id && (
                <th scope="col">Acciones</th>
              )}
            </tr>
          </thead>
          <tbody>
            {participants.map(
              (par) =>
                par.participant_type.is_audit == true && (
                  <tr key={par.id} className="">
                    <td>
                      <div className="flex txt-left">
                        <img
                          className="img-profile-participant"
                          src={
                            process.env.REACT_APP_API_HOST +
                            "/documents/" +
                            (par.person.image && par.person.image.name)
                          }
                          onError={(e) => {
                            e.target.src = imgError2;
                          }}
                        ></img>
                        {par.person.first_name} {par.person.last_name}
                      </div>
                    </td>
                    <td className="txt-left">{par.person.email}</td>
                    <td>{par.participant_type.name}</td>
                    {audit.lider_person_id == user.id && (
                      <td className="btn-action-program">
                        {par.person_id != user.id && (
                          <>
                            <img
                              className="cursor-action"
                              onClick={() => {
                                setState({
                                  ...state,
                                  toggle_modal: true,
                                  participant: par,
                                });
                              }}
                              title="Editar auditor"
                              src={btn_edit}
                            />
                            &nbsp;
                            <img
                              className="cursor-action"
                              onClick={() => {
                                checkHasActivity(par);
                                setState({ ...state, participant: null });
                              }}
                              title="Eliminar auditor"
                              src={btn_delete}
                            />
                          </>
                        )}
                      </td>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <br />
      <div>
        {state.toggle_modal2 === true && (
          <div className="back-document">
            <div className="modal-participants">
              <div className="header-modal-participants-audited">
                <div
                  className="salir-program"
                  onClick={() => {
                    setState({
                      ...state,
                      toggle_modal2: false,
                      participant: null,
                    });
                    setUserAuditor({});
                  }}
                ></div>
                {state.participant !== null && (
                  <h3 className="title-form-topic tipo-description">
                    Editar Evaluado
                  </h3>
                )}{" "}
                {state.participant === null && (
                  <h3 className="title-form-topic tipo-description">
                    Agregar Evaluado
                  </h3>
                )}
              </div>
              <div>
                <CreateParticipantAudited
                  participants={participants}
                  state={state}
                  setState={setState}
                  participant={state.participant}
                  procces={procces}
                  staff={staff}
                ></CreateParticipantAudited>
              </div>
            </div>
          </div>
        )}
        <div className="header-participant-audit">
          <h5 className="d-inline-block title-team-audited tipo-title">
            EQUIPO AUDITADO
          </h5>
          {(audit.lider_person_id == user.id ||
            audit.responsable_person_id == user.id) && (
            <div
              onClick={() => {
                setState({ ...state, toggle_modal2: true });
              }}
              className="btn-orange-default-short"
            >
              Añadir participante
            </div>
          )}
        </div>
        <table className="table table-hover table-bordered txt-center table-team-audited">
          <thead className="tipo-title color-black-gray">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Correo</th>
              <th scope="col">Rol</th>
              <th scope="col">Proceso</th>
              {(audit.lider_person_id == user.id ||
                audit.responsable_person_id == user.id) && (
                <th scope="col">Acciones</th>
              )}
            </tr>
          </thead>
          <tbody>
            {participants.map(
              (par) =>
                par.participant_type.is_audit == false && (
                  <tr key={par.id}>
                    <td>
                      <div className="flex txt-left">
                        <img
                          className="img-profile-participant"
                          src={
                            process.env.REACT_APP_API_HOST +
                            "/documents/" +
                            (par.person.image && par.person.image.name)
                          }
                          onError={(e) => {
                            e.target.src = imgError2;
                          }}
                        ></img>
                        {par.person.first_name} {par.person.last_name}
                      </div>
                    </td>
                    <td className="txt-left">{par.person.email}</td>
                    <td>{par.participant_type.name}</td>
                    <td>
                      {par.company_procces.map((pro, key) => {
                        return (
                          <span key={pro.id}>
                            {key != 0 ? "|" : ""}
                            &nbsp;
                            {pro.name}&nbsp;
                          </span>
                        );
                      })}
                    </td>
                    {(audit.lider_person_id == user.id ||
                      audit.responsable_person_id == user.id) && (
                      <td className="btn-action-program">
                        <img
                          className="cursor-action"
                          onClick={() => {
                            setState({
                              ...state,
                              toggle_modal2: true,
                              participant: par,
                            });
                          }}
                          title="Editar auditor"
                          src={btn_edit}
                        />
                        &nbsp;
                        <img
                          className="cursor-action"
                          onClick={() => {
                            checkHasActivity(par);
                          }}
                          title="Eliminar auditor"
                          src={btn_delete}
                        />
                      </td>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Participants;
