import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "../topic/entryForm.css";
import { EntryIndexContext } from "../../../contexts/forum/EntryIndexContext";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  item: {
    title: "",
    person_id: "",
    topic_id: "",
    body: "",
    status: ""
  },
  originalItem: {}
};

const CreatEntry = ({ modalState, setModalState, topic_id }) => {
  const [{ entry }, actions] = useContext(EntryIndexContext);
  let [state, setState] = useState(initialState);
  let history = useHistory();

  let setField = field => e => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };
  let functionSubmit = e => {
    e.preventDefault();
    actions.createEntry(state.item);
    setModalState({ ...modalState, modal: !modalState.modal });
  };

  return (
    <form
      onSubmit={functionSubmit}
      className="form-entry"
      encType="multipart/form-data"
    >
      {state.status === STATUS_NEW && (
        <>
          <div className="form-group">
            <label className="tipo-title">Titulo:</label>
            <input
              required
              className="form-control tipo-description"
              onChange={setField("title")}
              value={state.item.title}
              type="text"
              placeholder="Escribe un Titulo"
            />
          </div>
          <div className="form-group">
            <label className="tipo-title">Comentario:</label>
            <textarea
              required
              className="form-control tipo-description"
              rows="3"
              value={state.item.body}
              onChange={setField("body")}
              placeholder="Dinos que piensas..."
            />
          </div>
          <div className="btn-actions-form-entry">
            <input
              type="submit"
              className="btn-topic tipo-boton"
              value="Enviar"
            />
          </div>
        </>
      )}
    </form>
  );
};

export default CreatEntry;
