import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import client from "../axios/axios-client";
import { SecurityContext } from "./SecurityContext";
import Swal from "sweetalert2";
import clientImage from "../axios/axios-client-image";
import { AlertContext } from "../alerts/AlertContext";
import cloneDeep from "lodash/cloneDeep";
import { STATUS_APPROVED } from "../../components/audit/modals/CreateActivity";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const MODULE_LIBRARY = "library";
export const MODULE_AUDIT = "audit";
export const MODULE_ACTION_PLAN = "plan";
export const MODULE_CONSULTING = "consulting";
export const MODULE_CAPACITY = "capacity";
export const MODULE_INSPECTION = "inspection";
export const LIBRARY_SERVICE = 4;
export const AUDIT_SERVICE = 1;
export const AUDITOR_SERVICE = 1;
export const ACTION_PLAN_SERVICE = 5;
export const CONSULTING_SERVICE = "consulting";
export const CAPACITY_SERVICE = 3;
export const INSPECTION_SERVICE = 6;
export const STATUS_PACKAGE = 13;
export const ROLE_BENEFIT = "benefit";
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
let initalState = {
  roles: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  users: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  permissions: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  topics: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  documents: {
    status: STATUS_NOT_LOADED,
    elements: [],
    originalItem: [],
  },
  packages: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },

  services: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  errors: {
    error: [],
    status: false,
  },
};

export const AdminIndexContext = React.createContext([]);

export const AdminIndexContextProvider = (params) => {
  let { children } = params;
  let location = useLocation();
  let history = useHistory();
  let [documents, setDocuments] = useState(initalState.documents);
  let [roles, setRoles] = useState(initalState.roles);
  let [users, setUsers] = useState(initalState.users);
  let [permissions, setPermissions] = useState(initalState.permissions);
  let [errors, setErrors] = useState(initalState.errors);
  const [userFind, setuserFind] = useState({});
  const [packages, setpackages] = useState(initalState.packages);
  const [services, setservices] = useState(initalState.services);
  const [topics, settopics] = useState(initalState.topics);
  const [packageInEdit, setpackageInEdit] = useState(null);
  const [assingSelect, setassingSelect] = useState(null);
  const [packageAsing, setpackageAsing] = useState(null);
  const [textSearchAssing, settextSearchAssing] = useState("");
  const [assignablesFind, setassignablesFind] = useState([]);
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [module, setmodule] = useState(MODULE_AUDIT);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  //manejo de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  useEffect(() => {
    if (roles.status === STATUS_NOT_LOADED) {
      setRoles({ ...roles, status: STATUS_LOADING });
      client()
        .get(`security/profile`, {
          params: { with: ["permissions"] },
        })
        .then(({ data }) => {
          if (data) {
            setRoles({
              ...roles,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("../../app/unauthorized");
          } else {
            alertError("Error al buscar los roles");
          }
        });
    }
  }, [roles]);
  useEffect(() => {
    if (users.status === STATUS_NOT_LOADED) {
      setUsers({ ...users, status: STATUS_LOADING });
      client()
        .get(`security/person`, {
          params: { with: ["profiles"] },
        })
        .then(({ data }) => {
          if (data) {
            setUsers({
              ...users,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los usuarios");
          }
        });
    }
  }, [users]);
  useEffect(() => {
    if (services.status === STATUS_NOT_LOADED) {
      setservices({ ...services, status: STATUS_LOADING });
      client()
        .get(`billing/service`, {
          params: {
            filters: { active: true },
            orderBy: ["order:ASC"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setservices({
              ...services,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los servicios");
          }
        });
    }
  }, [services]);
  useEffect(() => {
    if (topics.status === STATUS_NOT_LOADED) {
      settopics({ ...topics, status: STATUS_LOADING });
      client()
        .get(`library/topic`, {
          params: {
            filters: { status_id: STATUS_APPROVED },
          },
        })
        .then(({ data }) => {
          if (data) {
            settopics({
              ...topics,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los temas");
          }
        });
    }
  }, [topics]);
  useEffect(() => {
    if (packages.status === STATUS_NOT_LOADED) {
      setpackages({ ...packages, status: STATUS_LOADING });
      client()
        .get(`billing/package`, {
          params: {
            with: ["details.service", "details.product", "benefits"],
            filters: { status_id: STATUS_PACKAGE },
            orderBy: ["active:desc","created_at:asc" ],
          },
        })
        .then(({ data }) => {
          if (data) {
            setpackages({
              ...packages,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los paquetes");
          }
        });
    }
  }, [packages]);
  useEffect(() => {
    if (permissions.status === STATUS_NOT_LOADED) {
      setPermissions({ ...permissions, status: STATUS_LOADING });
      client()
        .get(`security/permission`)
        .then(({ data }) => {
          if (data) {
            setPermissions({
              ...permissions,
              status: STATUS_LOADED,
              elements: data,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los permisos");
          }
        });
    }
  }, [permissions]);
  const register = ({ first_name, last_name, email, password }) => {
    client()
      .post("security/person", { first_name, last_name, email, password })
      .then(({ data }) => {
        alertSuccess("Usuario creado satisfactoriamente");
        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el usuario");
        }
      });
  };
  const addPackage = (packageAdd) => {
    client()
      .post("billing/package", packageAdd)
      .then(({ data }) => {
        if (packageAdd.id) {
          alertSuccess("Paquete editado satisfactoriamente");
        } else {
          alertSuccess("Paquete creado satisfactoriamente");
        }
        setpackageInEdit(null);
        setpackages({
          ...packages,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el paquete");
        }
      });
  };

  const createRole = ({ name }) => {
    client()
      .post("security/profile", { name })
      .then(({ data }) => {
        alertSuccess("Rol creado satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el rol");
        }
      });
  };
  const number_format = (amount, decimals) => {
    amount += ""; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, "")); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = "" + amount.toFixed(decimals);

    var amount_parts = amount.split("."),
      regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "." + "$2");

    return amount_parts.join(".");
  };
  const createPermission = ({ name, description }) => {
    client()
      .post("security/permission", { name, description })
      .then(({ data }) => {
        alertSuccess("Permiso creado satisfactoriamente");
        setPermissions({
          ...permissions,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear el permiso");
        }
      });
  };
  const updatePerson = ({ first_name, last_name, id }) => {
    var formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);

    client()
      .post("security/person/" + id, formData)
      .then(({ data }) => {
        alertSuccess("Usuario modificado satisfactoriamente");

        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el usuario");
        }
      });
  };
  const updateRole = ({ name, id }) => {
    var formData = new FormData();
    formData.append("name", name);

    client()
      .post("security/profile/" + id, formData)
      .then(({ data }) => {
        alertSuccess("Rol modificado satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el rol");
        }
      });
  };
  const updatePermission = ({ name, description, id }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    client()
      .post("security/permission/" + id, formData)
      .then(({ data }) => {
        alertSuccess("Permiso modificado satisfactoriamente");
        setPermissions({
          ...permissions,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar el permiso");
        }
      });
  };
  const deleteRole = (id) => {
    client()
      .delete("security/profile/" + id)
      .then(({ data }) => {
        alertSuccess("Rol eliminado satisfactoriamente");
        let el = roles.elements.filter((e) => e.id != id);
        setRoles({
          ...roles,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el rol");
        }
      });
  };
  const deletePermission = (id) => {
    client()
      .delete("security/permission/" + id)
      .then(({ data }) => {
        alertSuccess("Permiso eliminado satisfactoriamente");
        let el = permissions.elements.filter((e) => e.id != id);
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
        setPermissions({
          ...permissions,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el permiso");
        }
      });
  };
  const deletePackage = (id) => {
    client()
      .delete("billing/package/" + id)
      .then(({ data }) => {
        alertSuccess("Paquete eliminado satisfactoriamente");
        let el = packages.elements.filter((e) => e.id != id);
        setpackageInEdit(null);
        setpackages({
          ...packages,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el paquete");
        }
      });
  };
  const deletePerson = (id) => {
    client()
      .delete("security/person/" + id)
      .then(({ data }) => {
        alertSuccess("Usuario eliminado satisfactoriamente");
        let el = users.elements.filter((e) => e.id != id);
        setUsers({
          ...users,
          elements: el,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al eliminar el usuario");
        }
      });
  };
  const deleteBenefit = (id, index) => {
    if (id) {
      client()
        .delete(`billing/package/${packageInEdit.id}/noteDelete/${id}`)
        .then(({ data }) => {
          alertSuccess("Beneficio eliminado satisfactoriamente");
          let el = packageInEdit.benefits.filter((e) => e.id != id);
          let packEditClone = cloneDeep(packageInEdit);
          packEditClone.benefits = el;
          setpackageInEdit(packEditClone);
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            setErrors({ error: e.response.data, status: true });
          } else {
            alertError("Error al eliminar el beneficio");
          }
        });
    } else {
      let el = packageInEdit.benefits.filter((e, inde) => inde != index);
      let packEditClone = cloneDeep(packageInEdit);
      packEditClone.benefits = el;
      setpackageInEdit(packEditClone);
    }
  };
  const deleteDetail = (id, index) => {
    if (id) {
      client()
        .delete("billing/package_detail/" + id)
        .then(({ data }) => {
          alertSuccess("Detalle eliminado satisfactoriamente");
          let el = packageInEdit.details.filter((e) => e.id != id);
          let packEditClone = cloneDeep(packageInEdit);
          packEditClone.details = el;
          setpackageInEdit(packEditClone);
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            setErrors({ error: e.response.data, status: true });
          } else {
            alertError("Error al eliminar el servicio");
          }
        });
    } else {
      let el = packageInEdit.details.filter((e, inde) => inde != index);
      let packEditClone = cloneDeep(packageInEdit);
      packEditClone.details = el;
      setpackageInEdit(packEditClone);
    }
  };
  const attachPermission = (permissions, role_id) => {
    var formData = new FormData();
    formData.append("permissions", Array(permissions));

    client()
      .post("security/profileAttachPermission/" + role_id, formData)
      .then(({ data }) => {
        alertSuccess("Permisos añadidos satisfactoriamente");
        setRoles({
          ...roles,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir los permisos");
        }
      });
  };
  const attachRole = (roles, user_id) => {
    var formData = new FormData();
    formData.append("roles", Array(roles));

    client()
      .post("security/personAttachProfile/" + user_id, formData)
      .then(({ data }) => {
        alertSuccess("Roles añadidos satisfactoriamente");
        setUsers({
          ...users,
          status: STATUS_NOT_LOADED,
        });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir los roles");
        }
      });
  };
  const filterUser = (param) => {
    client()
      .get(
        `security/person`,

        {
          params: { filtersLike: { first_name: param, email: param }, with: ["profiles"] },
        }
      )
      .then(({ data }) => {
        if (data) {
          setUsers({
            ...users,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los usuarios");
        }
      });
  };
  const filterRole = (param) => {
    client()
      .get(`security/profile`, {
        params: { filtersLike: { name: param } },
      })
      .then(({ data }) => {
        if (data) {
          setRoles({
            ...roles,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los roles");
        }
      });
  };
  const filterPermission = (param) => {
    client()
      .get(`security/permission`, {
        params: { filtersLike: { name: param } },
      })
      .then(({ data }) => {
        if (data) {
          setPermissions({
            ...permissions,
            elements: data,
          });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar los permisos");
        }
      });
  };
  const addToCart = () => {
    let form = packageAsing;
    form.buyer_id = assingSelect.id;
    let route = `security/person/${assingSelect.id}/addToCart`;
    if (packageAsing.is_persona === false) {
      form.buyer_id = assingSelect.contact.id;
      route = `general/company/${assingSelect.id}/addToCart`;
    }
    client()
      .post(route, packageAsing)
      .then(({ data }) => {
        alertSuccess("Paquete añadido al carrito");
        setpackageAsing(null);
        setassignablesFind([]);
        setassingSelect(null);
        settextSearchAssing("");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir al carrito");
        }
      });
  };
  const giftPackage = () => {
    let form = packageAsing;
    form.buyer_id = assingSelect.id;
    form.assignable = assingSelect.id;
    let route = `billing/giftPackage`;
    if (packageAsing.is_persona === false) {
      form.buyer_id = assingSelect.contact.id;
    }
    client()
      .post(route, packageAsing)
      .then(({ data }) => {
        alertSuccess("Paquete asignado");
        /*      setpackageAsing(null);
      setassignablesFind([]);
      setassingSelect(null);
      settextSearchAssing("")  */
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir al carrito");
        }
      });
  };
  const assingSearch = (text) => {
    let route = `security/searchUsers/${text}`;
    let filtersLike = {};
    let withRelations = [];
    if (packageAsing.is_persona === false) {
      route = `general/company`;
      filtersLike = {
        email: text,
        name: text,
      };
      withRelations = ["contact"];
    }
    client()
      .get(route, {
        params: {
          filtersLike: filtersLike,
          with: withRelations,
        },
      })
      .then(({ data }) => {
        if (data) {
          setassignablesFind(data);
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar los usuarios");
        }
      });
  };
  const refreshUsers = () => {
    setUsers({
      ...users,
      status: STATUS_NOT_LOADED,
    });
  };
  const refreshRoles = () => {
    setRoles({
      ...roles,
      status: STATUS_NOT_LOADED,
    });
  };
  const refreshPermissions = () => {
    setPermissions({
      ...permissions,
      status: STATUS_NOT_LOADED,
    });
  };

  const createDocument = ({ document, role }) => {
    var formData = new FormData();

    formData.append("document", document);
    formData.append("is_free", true);
    formData.append("role", role);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    clientImage()
      .post(`security/person/${user.id}/deleteAndAddDocument`, formData)
      .then(({ data }) => {
        if (data) {
          alertSuccess("Documento creado satisfactoriamente");
          setDocuments({ ...documents, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          setDocuments({
            ...documents,
            status: STATUS_NOT_LOGGED_IN,
            formErrors: { errorDocuments: "Nombre Requerido" },
          });
        }
        if (e.request.status === 422) {
        } else {
          setDocuments({
            ...documents,
            status: STATUS_NOT_LOGGED_IN,
            formErrors: { errorDocuments: "Internal error." },
          });
        }
      });
  };

  return (
    <AdminIndexContext.Provider
      value={[
        {
          roles,
          users,
          permissions,
          errors,
          documents,
          userFind,
          packages,
          module,
          services,
          packageInEdit,
          topics,
          packageAsing,
          assignablesFind,
          assingSelect,
          textSearchAssing,
        },
        {
          register,
          createDocument,
          updatePerson,
          deletePerson,
          createRole,
          deleteRole,
          updateRole,
          createPermission,
          deletePermission,
          updatePermission,
          setErrors,
          attachPermission,
          attachRole,
          filterUser,
          filterRole,
          filterPermission,
          refreshUsers,
          refreshRoles,
          refreshPermissions,
          setuserFind,
          setmodule,
          number_format,
          setpackageInEdit,
          deleteDetail,
          settopics,
          addPackage,
          deleteBenefit,
          deletePackage,
          setpackageAsing,
          assingSearch,
          setassignablesFind,
          setassingSelect,
          settextSearchAssing,
          addToCart,
          giftPackage,
        },
      ]}
    >
      {children}
    </AdminIndexContext.Provider>
  );
};
