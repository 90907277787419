import React, { useEffect, useContext } from "react";
import {
  InspectionFormContext,
  ITEM_DATE,
  ITEM_DATETIME,
  ITEM_INTEGER,
  ITEM_NUMBER,
  ITEM_SMMR,
  ITEM_QS,
  ITEM_MFILE,
  ITEM_UFILE,
} from "../../contexts/evaluation/InspectionFormContext";
const AnalyticForm = ({ computedMatch: { params, url } }) => {
  let { evaluation_id } = params;
  const [{ analytic }, { setanalytic }] = useContext(InspectionFormContext);
  useEffect(() => {
    if (analytic.evaluation_id != evaluation_id) {
      setanalytic({ ...analytic, evaluation_id: evaluation_id });
    }
  }, [evaluation_id]);
  return (
    <div className="w-100 general-content-inspection-form">
      <div className="content-inspection flex">
        <div className="container-tarjets-items flex-1">
          <div className="title-tarjet-inspection flex-column">
            <div className="border-top-tarjet-inspection bg-orange"></div>
            <div className="content-title-inspect">
              <p className="tipo-title">{analytic.element.title}</p>
              <p className="tipo-description">{analytic.element.description}</p>
            </div>
          </div>
          <div>
            {analytic.element && analytic.element.items && (
              <Item items={analytic.element.items} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const makeOption = (option, item) => {
  if (item.item_type.code === ITEM_SMMR) {
    return (
      <div key={option.id}>
        <li className="tipo-description">
          ({option.numberResult})&nbsp;
          {(
            (option.numberResult * 100) /
            parseInt(item.countResult + item.numberNoResponse)
          ).toFixed(1)}{" "}
          % : {option.description}
        </li>
      </div>
    );
  } else {
    return (
      <div key={option.id}>
        <table>
          <tr>
            <th style={{ width: "140px" }}></th>
            <th style={{ width: "40px" }}> </th>
            <th></th>
          </tr>
          <tr className="tipo-description">
            <td>
              {" "}
              {item.item_type.code === ITEM_INTEGER ||
              item.item_type.code === ITEM_NUMBER ||
              item.item_type.code === ITEM_DATE ||
              item.item_type.code === ITEM_DATETIME ||
              item.item_type.code === ITEM_QS ||
              item.item_type.code === ITEM_MFILE ||
              item.item_type.code === ITEM_UFILE
                ? "Respondieron"
                : option.description}
            </td>
            <td>{option.numberResult}</td>
            <td> {parseFloat(option.percentageTotal).toFixed(1)} %</td>
          </tr>
        </table>
      </div>
    );
  }
};
const Item = ({ items }) => {
  return (
    <>
      {items.map((item) => {
        return (
          <ol key={item.id} className="panel-cause-analyze">
            <li>{item.title}</li>
            <ol>
              <div className="flex justify-betwen">
                <div className="flex-column">
                  {item.options.map((option) => {
                    return makeOption(option, item);
                  })}
                  <div>
                    <tr>
                      <th style={{ width: "140px" }}></th>
                      <th style={{ width: "40px" }}> </th>
                      <th></th>
                    </tr>
                    <tr className="tipo-description">
                      <td>Sin responder</td>

                      <td> {item.numberNoResponse}</td>
                      <td>
                        {" "}
                        {item.item_type.code === ITEM_SMMR
                          ? (
                              (item.numberNoResponse * 100) /
                              (item.numberNoResponse + item.countResult)
                            ).toFixed(1)
                          : parseFloat(item.percentageNoResponse).toFixed(
                              2
                            )}{" "}
                        %
                      </td>
                    </tr>
                  </div>
                  <div>
                    <tr>
                      <th style={{ width: "140px" }}></th>
                      <th style={{ width: "40px" }}> </th>
                      <th></th>
                    </tr>
                    <tr className="tipo-description">
                      <td>
                        <strong>Total</strong>{" "}
                      </td>
                      <td>{item.sumResponses + item.numberNoResponse}</td>
                      <td>100%</td>
                    </tr>
                  </div>
                  {(item.item_type.code === ITEM_INTEGER ||
                    item.item_type.code === ITEM_NUMBER) && (
                    <div>
                      <hr />
                      <tr>
                        <th style={{ width: "140px" }}></th>
                        <th style={{ width: "40px" }}> </th>
                        <th></th>
                      </tr>

                      <tr className="tipo-description">
                        <td>Valor mínimo </td>
                        <td>{item.optionsResponse.minValue}</td>
                      </tr>
                      <tr className="tipo-description">
                        <td>Valor máximo </td>
                        <td>{item.optionsResponse.maxValue}</td>
                      </tr>
                      <tr className="tipo-description">
                        <td>Media </td>
                        <td>{item.media.media.toFixed(2)}</td>
                      </tr>
                      <tr className="tipo-description">
                        <td>Desviación </td>
                        <td>{item.dispersion.toFixed(2)}</td>
                      </tr>
                    </div>
                  )}
                </div>
                {(item.item_type.code === ITEM_INTEGER ||
                  item.item_type.code === ITEM_NUMBER) && (
                  <ul>
                    <strong>Respuestas</strong>
                    <tr>
                      <th style={{ width: "140px" }}></th>
                      <th style={{ width: "40px" }}> </th>
                      <th></th>
                    </tr>
                    {item.optionsResponse.arrayResponses.map(
                      (response, index) => {
                        return (
                          <tr className="tipo-description">
                            <td>{response.value}</td>
                            <td>{response.count}</td>
                            <td>
                              {(
                                (response.count * 100) /
                                item.optionsResponse.sumResponses
                              ).toFixed(1)}
                              %
                            </td>
                          </tr>
                        );
                      }
                    )}
                   {/*  <li>({item.optionsResponse.sumResponses}) 100% : Total</li> */}
                  </ul>
                )}
                <div>{}</div>
              </div>
            </ol>
            {item.items.length > 0 && <Item items={item.items} />}
          </ol>
        );
      })}
    </>
  );
};
export default AnalyticForm;
