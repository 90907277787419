import React, { useContext, useState, useEffect } from "react";
import {
  AdminIndexContext,
  STATUS_NOT_LOADED,
} from "../../../contexts/security/AdminIndexContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Dashboard.css";
import CheckboxGroup from "react-checkbox-group";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { STATUS_LOADED } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCaretDown } from "@fortawesome/free-solid-svg-icons";
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";
const AUDIT_COMPANY_SERVICE = "audit_company";
const AUDIT_PERSON_SERVICE = "audit_person";
const STATUS_APPROVED = 7;


const initialState = {
  status: STATUS_NEW,
  item: {
    document: "",
    role: "terms_conditions",
  },
  tableUsers: {
    status: STATUS_NOT_LOADED,
    columns: [],
  },
  originalItem: {},
};

const Dashborad = () => {
  const [
    { documents, users, userFind },
    { createDocument, setuserFind },
  ] = useContext(AdminIndexContext);
  let [state, setState] = useState(initialState);
  const [tableUsers, settableUsers] = useState(initialState.tableUsers);

  let setField = (field) => (e) => {
    setState({ ...state, item: { ...state.item, [field]: e.target.value } });
  };

  useEffect(() => {
    if (users.status === STATUS_LOADED) {
      let columnsTable = [
        {
          name: "ID",
          grow: 1,
          center: true,
          selector: "id",
          cell: (row) => (
            <div
              className="cursor-action"
              onClick={() => {
                setuserFind(row);
              }}
            >
              {row.id}
            </div>
          ),
          sortable: true,
        },
        {
          name: "NOMBRE",
          grow: 3,
          center: true,
          selector: "first_name",
          sortable: true,
        },
        {
          name: "APELLIDO",
          grow: 3,
          center: true,
          selector: "last_name",
          sortable: true,
        },
        {
          name: "CORREO",
          grow: 3,
          center: true,
          selector: "email",
          sortable: true,
        },
        {
          name: "FECHA DE CREACION",
          grow: 3,
          center: true,
          selector: "created_at",
          sortable: true,
        },
        {
          name: "STATUS",
          grow: 3,
          center: true,
          selector: "status",
          sortable: true,
        },
        {
          name: "NACIMIENTO",
          grow: 3,
          center: true,
          selector: "birth_date",
          sortable: true,
        },
        {
          name: "IDENTIFICACION",
          grow: 3,
          center: true,
          selector: "id_number",
          sortable: true,
        },
        {
          name: "TELEFONO",
          grow: 3,
          center: true,
          selector: "phone",
          sortable: true,
        },
        {
          name: "CIUDAD",
          grow: 3,
          center: true,
          selector: "region",
          sortable: true,
        },
        {
          name: "DEPARTAMENTO",
          grow: 3,
          center: true,
          selector: "locality",
          sortable: true,
        },
      ];

      settableUsers({ status: STATUS_LOADED, columns: columnsTable });
    }
  }, [users]);

  let setDocument = (field) => (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setState({
        ...state,
        item: { ...state.item, [field]: file, imagePreviewUrl: reader.result },
      });
    };
    reader.readAsDataURL(file);
  };

  let functionSubmit = (e) => {
    e.preventDefault();
    createDocument(state.item);
  };
  const getModule = (suscription) => {
    let module = "Sin verificar";
    switch (suscription.bill_detail.service_id) {
      case AUDIT_COMPANY_SERVICE:
        module = "Auditorias empresariales";
        break;
      case AUDIT_PERSON_SERVICE:
        module = "Auditorias personales";

        break;
    }
    return module;
  };

  return (
    <>
      {/* modal */}
      {userFind.id && (
        <div className="back-document">
          <div className="container-userFind-dasboard flex-column">
            <div className="header-dasboard-admin">
              <div
                onClick={() => setuserFind({})}
                className="btn-close-modal"
              ></div>
            </div>
            <div className="container-userfind-dashboaard bg-white">
              {console.log(userFind)}
              SUSCRIPCIONES
              {userFind.subscriptions.filter(s=>s.bill_detail.bills.status===STATUS_APPROVED).map((s, index) => {
                return (
                  <div key={index} className="shadow-tarjet flex-column">
                    <div htmlFor="">Modulo:{getModule(s)}</div>
                    <div htmlFor="">
                      Compradas:{s.bill_detail.service_number}
                    </div>
                    <div htmlFor="">Usadas:{s.consumer_number}</div>
                    <div htmlFor="">
                      Origen:
                      {parseInt(s.bill_detail.value) === 0
                        ? "Gratuito"
                        : "Compra"}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {tableUsers.status === STATUS_LOADED && (
        <DataTable
          sortIcon={<FontAwesomeIcon icon={faCaretDown} />}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          noDataComponent={"no hay datos"}
          striped={true}
          columns={tableUsers.columns}
          responsive={true}
          className=" tipo-description"
          data={users.elements}
          pagination={true}
          paginationComponentOptions={{
            rowsPerPageText: "Lineas:",
            rangeSeparatorText: "de",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Todas",
          }}
        />
      )}

      <div className="component-form-policys">
        <form
          onSubmit={functionSubmit}
          encType="multipart/form-data"
          className="form-policys"
        >
          <>
            <div className="base-form-document">
              <div className="form-group">
                <input
                  type="file"
                  accept=".pdf"
                  className="form-control  tipo-description"
                  required
                  onChange={setDocument("document")}
                />
              </div>
              <div className="references-group">
                <div className="form-group">
                  <strong className="tipo-title">Rol</strong>
                  <br />
                  <div className="content-input-document">
                    <input
                      id="premium"
                      required
                      defaultChecked
                      onChange={setField("role")}
                      name="free"
                      className="input-premium"
                      value="terms_conditions"
                      type="radio"
                    />
                    <label htmlFor="premium" className="tipo-description">
                      Terminos y condiciones
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      id="free"
                      required
                      onChange={setField("role")}
                      className="input-free"
                      name="free"
                      value="privacy_policies"
                      type="radio"
                    />
                    <label htmlFor="free" className="tipo-description">
                      Politicas de privacidad
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-actions-form">
              <div className="form-group">
                <input
                  type="submit"
                  className="btn-topic tipo-boton"
                  value="Crear"
                />
              </div>
            </div>
          </>
        </form>
      </div>
    </>
  );
};

export default Dashborad;
