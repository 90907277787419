import React, { useState, useContext, useEffect } from "react";
import "./Index.css";
import {
  STATUS_NOT_LOADED,
  STATUS_LOADED,
  AuditExpressIndexContext,
} from "../../../contexts/audit/AuditExpressIndexContext";
import { Field, Form } from "react-final-form";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import btn_create from "../../../img_zeus/icons_audit/BOTON_CREAR_AUDITORIA.png";
import btn_exec from "../../../img_zeus/icons_audit/ingresar-02.png";
import btn_download from "../../../img_zeus/icons_library/ICONO-ARCHIVO-DESCARGA2.png";
import btn_send from "../../../img_zeus/icons_audit/Enviar_mensaje.png";
import { Link } from "react-router-dom";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";

const initialState = {
  columns: { status: STATUS_NOT_LOADED, columns: [] },
};
const Index = () => {
  let history = useHistory();
  const [
    {
      audit,
      standard,
      toggleModal,
      template,
      effectiveSelected,
      suscriptionsAuditExpress,
    },
    {
      settoggleModal,
      createAudit,
      getViewDownload,
      sendEmailExpress,
      seteffectiveSelected,
    },
  ] = useContext(AuditExpressIndexContext);
  const [{ user }, { viewGeneral }] = useContext(SecurityContext);
  const [selectTemplate, setselectTemplate] = useState(null);
  const [standardSelect, setstandardSelect] = useState(null);
  const [columns, setcolumns] = useState(initialState.columns);

  useEffect(() => {
    viewGeneral("express");
  }, []);
  useEffect(() => {
    if (audit.status === STATUS_LOADED) {
      let columns = [
        {
          center: true,
          name: "ID",
          width: "60px",
          selector: "number",
          cell: (row) => <div className="cursor-action">{row.number}</div>,
          sortable: true,
        },
        {
          name: "NOMBRE",
          grow: 3,
          cell: (row) => (
            <div
              title={row.program ? "Empresarial" : "Personal"}
              className="flex w-100 "
            >
              <div className="flex">
                {row.consumer && (
                  <div className="flex margin-auto flex-0">
                    <FontAwesomeIcon
                      className="icon-personal-company-cart "
                      icon={
                        row.consumer.effective.is_personal
                          ? faUser
                          : faBuilding
                      }
                    />
                    &nbsp;
                  </div>
                )}

                <div className="flex-1 flex margin-auto">
                  <span>{row.title}</span>
                </div>
              </div>
            </div>
          ),
          selector: "title",
          sortable: true,
        },
        {
          name: "FECHA DE CREACIÓN",
          grow: 3,
          center: true,
          selector: "created_at",
          sortable: true,
        },
        {
          name: "NORMA",
          center: true,
          selector: "standard.short_name",
          sortable: true,
        },

        {
          name: "ESTADO",
          center: true,
          width: "60px",
          cell: (row) => (
            <div className=" cursor-action btn-action-program">
              {statusAudit(row.status_id)}
            </div>
          ),
          sortable: false,
        },
        {
          name: "ACCIONES",
          center: false,
          cell: (row) => (
            <>
              {row.evaluations.length > 0 && (
                <>
                  <img
                    style={{ width: "20px" }}
                    className="cursor-action"
                    onClick={() => {
                      history.push(
                        `/app/audit/evaluation/${row.evaluations[0].id}`
                      );
                    }}
                    title="Ingresar a la evaluación"
                    src={btn_exec}
                  />
                  &nbsp;
                  <img
                    style={{ width: "20px" }}
                    className="cursor-action"
                    onClick={() =>
                      getViewDownload(
                        `${process.env.REACT_APP_API_HOST}audit/showAuditExpressView/${row.id}`,
                        `${process.env.REACT_APP_API_HOST}audit/showAuditExpressDownload/${row.id}`
                      )
                    }
                    title="Descargar"
                    src={btn_download}
                  />
                  &nbsp;
                  <img
                    style={{ width: "20px" }}
                    className="cursor-action"
                    onClick={() => sendEmailExpress(row.id)}
                    title="Enviar al correo"
                    src={btn_send}
                  />
                </>
              )}
            </>
          ),

          sortable: false,
        },
      ];

      setcolumns({
        status: STATUS_LOADED,
        columns: columns,
      });
    }
  }, [audit]);
  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  const statusAudit = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return <img src={btn_created} title="Creada" />;
        break;
      case 2:
        return <img src={btn_planned} title="Planeado" />;
        break;
      case 3:
        return <img src={btn_inprogress} title="En progreso" />;
        break;
      case 4:
        return <img src={btn_executed} title="Finalizada" />;
        break;
      case 6:
        return <img src={btn_created} title="Pendiente" />;
        break;
      case 7:
        return <img src={btn_approved} title="Aprobada" />;
        break;
      default:
        return <img src={btn_created} title="Creada" />;
    }
  };
  let shortText = (text, range) => {
    if (text && text.length > range) {
      return text.substr(0, range) + "...";
    }
    return text;
  };
  useEffect(() => {
    let templatesFind = template.elements.filter(
      (s) => s.standard_id == standardSelect
    );
    if (templatesFind.length === 0) {
      setselectTemplate(null);
    }
  }, [standardSelect]);
  return (
    <div>
      {toggleModal && (
        <div className="back-document">
          <div className="content-modal-express flexflex-column">
            <div className="header-modal-create-audit-express">
              <div
                onClick={() => settoggleModal(!toggleModal)}
                className="salir-program "
              ></div>
              <div className="flex-1">Crear auditoría</div>
            </div>
            <div className="container-form-audit-express">
              <Form
                onSubmit={(formObj) => {
                  if (!standardSelect) {
                    alert("Norma es requerido");
                    return false;
                  }
                  formObj.standard_id = { id: standardSelect };
                  formObj.template_id = selectTemplate;

                  createAudit(formObj);
                }}
              >
                {({ handleSubmit, values }) => (
                  <form
                    className="form-add-audit-express"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex">
                      <div className="flex-1">
                        {suscriptionsAuditExpress.elements.length == 0 && (
                          <div
                            class="alert alert-warning form-group txtarea-form-audit"
                            role="alert"
                          >
                            Sin suscripciones activas!
                            <br />
                            <Link
                              className="link-default"
                              title="Comprar suscripción personal para auditorías"
                              to={`/app/library/topic/billing/suscription/audit_express`}
                            >
                              Adquirir suscripción
                            </Link>
                          </div>
                        )}
                        <div className="form-group div-form-activity">
                          <Field name="title" validate={required}>
                            {({ input, meta }) => (
                              <>
                                <label className="tipo-title">Título</label>

                                <input
                                  {...input}
                                  disabled={
                                    suscriptionsAuditExpress.elements.length ===
                                    0
                                  }
                                  className="form-control tipo-description"
                                  placeholder="Nombre de la auditoría"
                                  type="text"
                                />
                                {meta.touched && meta.error && (
                                  <span className="tipo-cursiva orange">
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="form-group txtarea-form-audit">
                          <label className="tipo-title">Norma:</label>

                          <Select
                            isDisabled={
                              suscriptionsAuditExpress.elements.length === 0
                            }
                            onChange={(e) => setstandardSelect(e.id)}
                            className="tipo-description select-audit-express"
                            placeholder={"Seleccione la norma"}
                            getOptionLabel={(option) => `${option.short_name}`}
                            getOptionValue={(option) => option.id}
                            options={standard.elements}
                          />
                        </div>

                        <div className="form-group txtarea-form-audit">
                          <label className="tipo-title">Plantilla:</label>

                          <Select
                            isDisabled={
                              suscriptionsAuditExpress.elements.length === 0
                            }
                            onChange={(e) => setselectTemplate(e ? e.id : null)}
                            className="tipo-description select-audit-express"
                            placeholder={"Seleccione la plantilla"}
                            isClearable={true}
                            getOptionLabel={(option) => `${option.title}`}
                            getOptionValue={(option) => option.id}
                            options={template.elements.filter(
                              (s) => s.standard_id == standardSelect
                            )}
                          />
                        </div>
                        <div className="form-group txtarea-form-audit">
                          <Field name="company_id" validate={required}>
                            {({ input, meta }) => (
                              <>
                                <label className="tipo-title">Empresa:</label>
                                <Select
                                  {...input}
                                  isDisabled={
                                    suscriptionsAuditExpress.elements.length ===
                                    0
                                  }
                                  className="tipo-description select-audit-express"
                                  placeholder={"Seleccione una opción"}
                                  getOptionLabel={(option) => `${option.name}`}
                                  getOptionValue={(option) => option.id}
                                  options={user.contact_companies}
                                />
                                {meta.touched && meta.error && (
                                  <span className="tipo-cursiva orange">
                                    {meta.error}
                                  </span>
                                )}
                              </>
                            )}
                          </Field>
                        </div>
                      </div>
                      {suscriptionsAuditExpress.elements.length > 0 && (
                        <>
                          <div className="flex-1">
                            <label className="tipo-title">
                              Selecccione un paquete
                            </label>
                            {suscriptionsAuditExpress.elements.map(
                              (effective) => {
                                return (
                                  <div
                                    className={`cursor-action g-module-audit form-group txtarea-form-audit effective-modal-audit ${
                                      effective.id == effectiveSelected
                                        ? "effective-active"
                                        : ""
                                    }`}
                                    key={effective.id}
                                    onClick={() =>
                                      seteffectiveSelected(effective.id)
                                    }
                                  >
                                    <label className="tipo-title txt-capitalize">
                                      {effective.service.name}
                                    </label>
                                    &nbsp;
                                    <label htmlFor="">
                                      {effective.quantity}/
                                      {effective.consumers.length}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div></div>

                    <div className="flex justify-end">
                      <input
                        type="submit"
                        value="Guardar"
                        className="btn-orange-default"
                      />
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      )}

      <div className="table-audits container-desktop-audit-express">
        <div className="flex justify-betwen">
          <h3>Auditorías express</h3>

          <img
            className="cursor-action btn-create-audit-index"
            onClick={() => settoggleModal(!toggleModal)}
            src={btn_create}
          />
        </div>

        {columns.status === STATUS_LOADED && (
          <DataTable
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            noDataComponent={"no hay datos"}
            striped={true}
            columns={columns.columns}
            responsive={true}
            className=" tipo-description"
            data={audit.elements}
            pagination={true}
            paginationComponentOptions={{
              rowsPerPageText: "Lineas:",
              rangeSeparatorText: "de",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Todas",
            }}
          />
        )}
      </div>

      <div className="flex flex-column container-index-mobile hidden">
        <div className="flex justify-end">
          <div
            onClick={() => settoggleModal(!toggleModal)}
            className="flex  btn-create-express-mobile bg-orange flex"
          >
            <div className="bg-white background-simple img-express-btn"></div>
            &nbsp; crear auditoría
          </div>
        </div>
        <div className="container-audits-express-mobile">
          {audit.elements.map((aud, index) => (
            <div
              key={aud.id}
              onClick={() => history.push(`../audit_express/audit/${aud.id}`)}
              className="tarjet-mobile-audits-express"
            >
              <div className="tipo-description flex">
                <p className="container-mobile-tarjte-express flex-1">
                  <strong>ID {aud.number} </strong> {shortText(aud.title, 50)}{" "}
                  {aud.standard.short_name}
                </p>
                <span className="icon-status-audit-mobile">
                  {statusAudit(aud.status_id)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
