import React from "react";
import FinishAuditGnereal from "./general/FinishAudit";
import { Link, useLocation, useHistory } from "react-router-dom";
const FinishAudit = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">Finalización de Auditoría</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Asegure el cumplimiento de evaluación de todos los requisitos y
              las actividades programadas, ajuste y entregue el reporte final de
              auditoría.
            </p>
          </div>
        </div>
      </div>
    <FinishAuditGnereal/>
    </div>
  );
};

export default FinishAudit;
