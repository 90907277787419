import React from "react";
const PlanAudit = () => {
  return (
    <div className="flex flex-column">

 
      <div className="flex container-profile-help">
        <div className="flex background-simple image-user-help"></div>
        <p className="text-user-help">Auditor Líder</p>
      </div>
      <div className="flex-column">
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Adicione el equipo auditor y asigne roles
          </div>
        </div>

        <div className="flex bg-white border-green-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Asigne los participantes y responsables de los procesos (pueden ser
            adicionados por el responsable del S.G. de la empresa).
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Detalle plan de auditoria: Actividades con fecha y hora, requisitos,
            ubicación, participantes y evaluador.
          </div>
        </div>

        <div className="flex bg-white border-green-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
          Genere el documento de Plan de auditoría y
obtenga su aprobación directamente en el sistema
          </div>
        </div>
        <div className="flex bg-green white container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Todos los participantes tienen acceso a sus actividades, según su
            rol y permisos.
          </div>
        </div>

        <div className="margin-help-bottom flex bg-white border-green-help  container-bubble-help">
          <div className="help-max-conatiner tipo-description">
            Acceda al detalle del plan de auditoría desde su computador o
            celular
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanAudit;
