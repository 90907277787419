import React, { useState, useEffect, useContext } from "react";
import "../audit/Report.css";
import Select, { components } from "react-select";
import moment from "moment";
import imgDown from "../../../img_zeus/icons_audit/icons_report/Icono_Avance_Auditores.png";
import {
  SecurityContext,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import { Link, useHistory } from "react-router-dom";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import imgLoading from "../../../img_zeus/loading.gif";
import imgErrorDefault from "../../../img_zeus/circle-image.png";
import imgAduitorCheck from "../../../img_zeus/icons_audit/icons_report/Icono_Auditor.png";
import cloneDeep from "lodash/cloneDeep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faEye,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";
export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;

const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";
const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const EXT_XLSX = "xlsx";
const EXT_XLS = "xls";
const NUMBER_CARACTER = 3;
const initialState = {
  report: {
    activities: [],
    participants: [],
    searchParticipant: "",
    process: [],
    checkAuditors: [],
    activitiesGroup: {},
    weeksActivities: [],
    observations: [],
  },

  itemsDeploy: {},
  weeksDeploy: {},
  daysDeploy: {},
};
const pluck = (array, key) => {
  return array.map((o) => o[key]);
};

const getDataItem = (object, item) => {
  if (item.auditable_item != null) {
    if (item.auditable_item.required) {
      object.numberCalificables = object.numberCalificables + 1;
      if (item.results != "") {
        object.numberResult = object.numberResult + 1;
        if (item.results[0].is_valid == true) {
          object.fulfill = object.fulfill + 1;
        } else if (item.results[0].is_valid == false) {
          object.fails = object.fails + 1;
        } else {
          object.notApply = object.notApply + 1;
        }
      }
      object.documents = object.documents + item.documents.length;
      object.notes = object.notes + item.notes.length;
    } else if (item.items != "") {
      for (let i = 0; i < item.items.length; i++) {
        object = getDataItem(object, item.items[i]);
      }
    }
  }

  return object;
};

const Report = ({ audit, dataItem, statusAdvance, getTotalAdvanceFilter }) => {
  const [tooltipActive, settooltipActive] = useState(null);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [weekDeploy, setweekDeploy] = useState(initialState.weeksDeploy);
  const [daysDeploy, setdaysDeploy] = useState(initialState.daysDeploy);

  const [report, setReport] = useState(initialState.report);
  const [processSelect, setprocessSelect] = useState([]);
  const [canViewResult, setcanViewResult] = useState(true);
  const [searchItem, setsearchItem] = useState("");
  const [viewActivity, setViewActivity] = useState(false);
  const [toogleObservations, settoogleObservations] = useState(false);
  const [toogleNotes, settoogleNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [{ user }, {}] = useContext(SecurityContext);

  let history = useHistory();
  useEffect(() => {
    let process = [...audit.company.process];
    let participants = [...audit.participants].filter(
      (e) => e.participant_type.is_audit
    );
    let observations = [];
    for (let e = 0; e < audit.evaluations.length; e++) {
      for (let o = 0; o < audit.evaluations[e].observations.length; o++) {
        observations.push(audit.evaluations[e].observations[o]);
      }
    }
    let particpantsChecked = pluck(participants, "id");
    if (
      imAuditor() &&
      audit.company.contact.id != user.id &&
      audit.lider_person.id != user.id &&
      user.is_admin !== true
    ) {
      particpantsChecked = [
        audit.participants.filter((p) => p.person_id === user.id)[0].id,
      ];
      participants = participants.filter((p) => p.person_id === user.id);
    }
    setReport({
      ...report,
      activities: audit.activities,
      participants: participants,
      process: process,
      checkAuditors: particpantsChecked,
      observations: observations,
    });

    if (
      audit.status_id != STATUS_EXECUTED &&
      audit.company.contact.id == user.id &&
      audit.lider_person.id != user.id
    ) {
      setcanViewResult(false);
    }
  }, [audit]);
  const isAudited = () => {
    let isAudited = false;
    if (user.is_admin) {
      return false;
    }
    for (let p = 0; p < audit.participants.length; p++) {
      const participant = audit.participants[p];
      if (
        participant.person.id == user.id &&
        participant.participant_type.is_audit == false
      ) {
        isAudited = true;
      }
    }

    return isAudited;
  };
  useEffect(() => {
    if (report.participants.length > 0) {
      let activitiesProcess = cloneDeep(audit.activities);
      if (processSelect.length > 0) {
        activitiesProcess = audit.activities.filter((e) =>
          processSelect.find((p) => p.id == e.process_id)
        );
      }

      let newActivities = activitiesProcess.filter((e) =>
        validateAuditParticipants(e)
      );
      getTotalAdvanceFilter(processSelect, report.checkAuditors);
      if (searchItem != "") {
        if (viewActivity) {
          newActivities = newActivities.filter(function (el) {
            return el.name.toLowerCase().indexOf(searchItem.toLowerCase()) > -1;
          });
        } else {
          for (let a = 0; a < newActivities.length; a++) {
            let itemsParent = [];
            const activityFor = newActivities[a];
            if (activityFor.evaluations.length > 0) {
              const evaluation = activityFor.evaluations[0];
              if (searchItem.length >= NUMBER_CARACTER) {
                recursiveSearchItem(itemsParent, evaluation.items, searchItem);
              }
              for (let p = 0; p < itemsParent.length; p++) {
                const itemParent = itemsParent[p];
                recursiveParentItemsSearch(
                  itemParent,
                  evaluation.items,
                  evaluation.items
                );
              }
            }
          }
        }
      }
      setReport({
        ...report,
        activities: newActivities,
      });
    }
  }, [report.checkAuditors, processSelect, searchItem, setViewActivity]);
  const recursiveParentItemsSearch = (itemSearch, items, originItems) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.id == itemSearch) {
        item.deploy = true;
      } else if (item.items.length > 0) {
        recursiveParentItemsSearch(itemSearch, item.items, originItems);
      }
    }
  };
  const recursiveSearchItem = (itemsParent, items, searchItem) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.search = false;
      let isSearch = false;
      if (item.auditable_item) {
        isSearch =
          item.title.toLowerCase().indexOf(searchItem.toLowerCase()) > -1 ||
          item.auditable_item.number
            .toLowerCase()
            .indexOf(searchItem.toLowerCase()) > -1;
      } else {
        isSearch =
          item.title.toLowerCase().indexOf(searchItem.toLowerCase()) > -1;
      }

      if (isSearch) {
        item.search = true;
        highlightText(item, searchItem);

        if (item.item_id) {
          itemsParent.push(item.item_id);
        }
      }
      if (item.items.length > 0) {
        recursiveSearchItem(itemsParent, item.items, searchItem);
      }
    }
  };
  const highlightText = (item, searchItem) => {
    if (item.auditable_item) {
      var index = item.auditable_item.number.toLowerCase().indexOf(searchItem);
      if (index >= 0) {
        item.auditable_item.number =
          item.auditable_item.number.substring(0, index) +
          "<span class='highlightItem'>" +
          item.auditable_item.number.substring(
            index,
            index + searchItem.length
          ) +
          "</span>" +
          item.auditable_item.number.substring(index + searchItem.length);
      }
    }

    var indexTitle = item.title.toLowerCase().indexOf(searchItem);
    if (indexTitle >= 0) {
      item.title =
        item.title.substring(0, indexTitle) +
        "<span class='highlightItem'>" +
        item.title.substring(indexTitle, indexTitle + searchItem.length) +
        "</span>" +
        item.title.substring(indexTitle + searchItem.length);
    }
  };
  const existDay = (days, day) => {
    let exist = false;
    for (let i = 0; i < days.length; i++) {
      if (days[i] == day) {
        exist = true;
      }
    }
    return exist;
  };
  const existWeek = (weeks, week) => {
    let existWeek = false;
    for (let i = 0; i < weeks.length; i++) {
      if (weeks[i].week == week) {
        existWeek = true;
      }
    }
    return existWeek;
  };

  useEffect(() => {
    let groupsWeek = [];
    if (viewActivity) {
      var groups = report.activities.reduce(function (acc, activity) {
        let date = moment(activity.init_date, "YYYY/MM/DD");

        var yearWeek = moment(date).year() + "-" + moment(date).week();
        var day = date.format("D");
        // check if the week number exists
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        if (!acc[yearWeek][day]) {
          acc[yearWeek][day] = [];
        }

        acc[yearWeek][day].push(activity);
        if (!existWeek(groupsWeek, yearWeek)) {
          groupsWeek.push({ week: yearWeek, days: [] });
        }
        for (let i = 0; i < groupsWeek.length; i++) {
          if (groupsWeek[i].week == yearWeek) {
            if (!existDay(groupsWeek[i].days, day)) {
              groupsWeek[i].days.push(day);
            }
          }
        }

        return acc;
      }, {});

      setReport({
        ...report,
        activitiesGroup: groups,
        weeksActivities: groupsWeek,
      });
    }
  }, [viewActivity, report.activities]);
  const validateAuditParticipants = (activity) => {
    let existParticipant = false;
    let activityFind = activity.audit_participants.filter(
      (e) => report.checkAuditors.includes(e.id) && e.participant_type.is_audit
    );
    if (activityFind.length > 0) {
      existParticipant = true;
    }
    return existParticipant;
  };
  const changeSelectProcess = (processSelect) => {
    if (processSelect == null) {
      processSelect = [];
    }

    setprocessSelect(processSelect);
  };
  const setMouse = (participant_id) => {
    settooltipActive(participant_id);
  };
  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };
  const setWeek = (week) => {
    if (weekDeploy[week]) {
      setweekDeploy({
        ...weekDeploy,
        [week]: !weekDeploy[week],
      });
    } else {
      setweekDeploy({ ...weekDeploy, [week]: true });
    }
  };
  const setDay = (day) => {
    if (daysDeploy[day]) {
      setdaysDeploy({
        ...daysDeploy,
        [day]: !daysDeploy[day],
      });
    } else {
      setdaysDeploy({ ...daysDeploy, [day]: true });
    }
  };
  const setSearchParticipant = () => (e) => {
    let body = e.target.value;
    let newParticipants = audit.participants.filter(function (el) {
      return (
        el.person.first_name.toLowerCase().indexOf(body.toLowerCase()) > -1 &&
        el.participant_type.is_audit === true
      );
    });
    setReport({
      ...report,
      searchParticipant: body,
      participants: newParticipants,
    });
  };

  const getNumberActivity = (activity) => {
    let data = {
      numberCalificables: 0,
      numberResult: 0,
      percentege: 0,
      fulfill: 0,
      fails: 0,
      notApply: 0,
      documents: 0,
      notes: 0,
    };

    if (activity.is_evaluable) {
      for (let e = 0; e < activity.evaluations.length; e++) {
        for (let i = 0; i < activity.evaluations[e].items.length; i++) {
          data = getDataItem(data, activity.evaluations[e].items[i]);
        }
      }
    }
    data.percentage =
      data.numberResult == 0 && data.numberCalificables == 0
        ? 0
        : Math.round((data.numberResult * 100) / data.numberCalificables);

    return data;
  };

  const participantIsChecked = (participant_id) => {
    let participant = report.checkAuditors.filter((e) => e == participant_id);
    return participant.length > 0 ? true : false;
  };
  const allParticipantChecked = () => {
    let allChecked = true;
    for (let i = 0; i < report.participants.length; i++) {
      if (!participantIsChecked(report.participants[i].id)) {
        allChecked = false;
      }
    }
    return allChecked;
  };
  const addOrRemoveParticipant = (participant_id) => {
    let newChecks = [...report.checkAuditors];
    if (participantIsChecked(participant_id)) {
      newChecks = report.checkAuditors.filter((e) => e != participant_id);
    } else {
      newChecks.push(participant_id);
    }
    setReport({
      ...report,
      checkAuditors: newChecks,
    });
  };
  const stylesProcess = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
    }),
    multiValue: (provided, state) => ({
      ...provided,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
    }),
  };
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  const imAuditor = () => {
    let imAuditorFor = false;
    for (let p = 0; p < audit.participants.length; p++) {
      const participant = audit.participants[p];
      if (
        participant.participant_type.is_audit &&
        participant.person.id == user.id
      ) {
        imAuditorFor = true;
      }
    }
    return imAuditorFor;
  };

  return (
    <div className="w-100 flex-wrap general-content-report">
      {/* modal observations */}
      {toogleObservations && (
        <div className="back-document">
          <div className="flex  container-general-observations">
            <div className="flex header-modal-observations">
              <div
                onClick={() => settoogleObservations(!toogleObservations)}
                className="salir-company"
              ></div>
              <div className="w-100 txt-center">
                <h3 className="tipo-description ">Observaciones</h3>
              </div>
            </div>
            <div className="container-content-modal-observation">
              <div className="content-modal-observations margin-control">
                <div className="container-observations-modal">
                  {report.observations.map((observation, index) => {
                    return (
                      <div key={index}>
                        {(audit.lider_person.id == user.id ||
                          observation.person.id == user.id) && (
                          <div className="content-observation-modal tipo-description">
                            <div className="flex flex-column">
                              <div className="flex"> {observation.note}</div>
                              <div className="flex">
                                {observation.documents.map((document) => {
                                  return (
                                    <div
                                      title={document.title}
                                      key={document.id}
                                    >
                                      <Document document={document} />
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="flex container-date-desktop-observation">
                                <p className="tipo-cursiva date-observation-evaluation">
                                  {moment(observation.created_at).format(
                                    "YYYY-MM-DD HH:mm a"
                                  )}
                                </p>
                                <p className="date-observation-evaluation">
                                  {observation.person.first_name}&nbsp;
                                  {observation.person.last_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end modal observations */}
      {/* modal notes */}
      {toogleNotes && (
        <div className="back-document">
          <div className="flex  container-general-observations">
            <div className="flex header-modal-observations">
              <div
                onClick={() => settoogleNotes(!toogleNotes)}
                className="salir-company"
              ></div>
              <div className="w-100 txt-center">
                <h3 className="tipo-description ">Notas</h3>
              </div>
            </div>
            <div className="container-content-modal-observation">
              <div className="content-modal-observations margin-control">
                <div className="container-observations-modal">
                  {notes.map((observation, index) => {
                    return (
                      <div
                        className="content-observation-modal tipo-description"
                        key={index}
                      >
                        {observation.note}
                        <div className="flex container-date-desktop-observation">
                          <p className="tipo-cursiva date-observation-evaluation">
                            {moment(observation.created_at).format(
                              "YYYY-MM-DD hh:mm a"
                            )}
                          </p>
                          <p className="date-observation-evaluation">
                            {observation.person &&
                              observation.person.first_name}
                            &nbsp;
                            {observation.person && observation.person.last_name}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* end modal notes */}

      <div className="flex w-100 header-report">
        {(audit.company.contact.id === user.id ||
          audit.lider_person.id === user.id ||
          user.is_admin === true) && (
          <div className="container-filters-auditors flex flex-column">
            <div className="flex-wrap container-auditors-report shadow-tarjet margin-control">
              <div className="flex container-into-auditors-report">
                <div
                  onMouseLeave={() => setMouse(null)}
                  onMouseEnter={() => setMouse("all")}
                  onClick={() => {
                    if (!allParticipantChecked()) {
                      setReport({
                        ...report,
                        checkAuditors: pluck(
                          [
                            ...audit.participants.filter(
                              (p) => p.participant_type.is_audit
                            ),
                          ],
                          "id"
                        ),
                      });
                    } else {
                      setReport({ ...report, checkAuditors: [] });
                    }
                  }}
                  className={`${
                    allParticipantChecked() ? "" : "btn-auditor-report-gray"
                  } btn-all-auditor background-simple cursor-action btn-general-auditor-report`}
                >
                  {tooltipActive == "all" && (
                    <div
                      className={`white tipo-description background-simple tooltip-auditor-all tooltip-auditor`}
                    >
                      Todos los auditores
                    </div>
                  )}
                </div>
                {report.participants.map((participant, index) => {
                  return (
                    <div key={index} className="btn-general-auditor-report">
                      <img
                        key={index}
                        onMouseLeave={() => setMouse(null)}
                        onMouseEnter={() => setMouse(participant.id)}
                        onClick={() => addOrRemoveParticipant(participant.id)}
                        className={`${
                          participantIsChecked(participant.id)
                            ? ""
                            : "gray-scale"
                        } btn-auditor-report  cursor-action background-simple `}
                        src={
                          process.env.REACT_APP_API_HOST +
                          "/documents/" +
                          (participant.person.image &&
                            participant.person.image.name)
                        }
                        onError={(e) => {
                          e.target.src = participantIsChecked(participant.id)
                            ? imgAduitorCheck
                            : imgError;
                        }}
                      ></img>
                      {tooltipActive == participant.id && (
                        <div
                          className={`white tipo-description background-simple tooltip-auditor`}
                        >
                          {participant.person.first_name}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="">
                <input
                  onChange={setSearchParticipant()}
                  value={report.searchParticipant}
                  className="w-100 tipo-description no-border txt-search-audit-report"
                  type="text"
                  placeholder="Nombre auditor"
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex-1">
          <div className="margin-control">
            <Select
              className="tipo-description"
              placeholder={"Seleccionar procesos"}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              options={report.process}
              value={processSelect}
              onChange={(e) => changeSelectProcess(e)}
              label={imgDown}
              styles={stylesProcess}
              isMulti
            />
          </div>
        </div>
        {imAuditor() && (
          <div className="flex-0">
            <div
              title="observaciones"
              onClick={() => settoogleObservations(!toogleObservations)}
              className="cursor-action icon-observation-report background-simple"
            >
              <div className="observation-lenght-audit-report">
                {report.observations.length}
              </div>
            </div>
          </div>
        )}

        <div className="flex-input-search-report margin-control">
          <div className=" flex ">
            <div className="flex margin-search-report w-100">
              <input
                placeholder="Escribe aquí"
                className="flex-1 gray tipo-description input-search-item-report"
                type="text"
                onChange={(e) => {
                  setsearchItem(e.target.value);
                }}
                value={searchItem}
              />
              <div className="btn-search-report background-simple"></div>
            </div>
          </div>
          <label className="tipo-cursiva" htmlFor="">
            Búsqueda apartir de {NUMBER_CARACTER} caracteres
          </label>
        </div>
      </div>
      <div className="flex">
        <div className="margin-control flex ">
          {!viewActivity && (
            <div
              onClick={() => setViewActivity(!viewActivity)}
              className="cursor-action btn-view-activities-report background-simple"
            ></div>
          )}
          {viewActivity && (
            <div
              onClick={() => setViewActivity(!viewActivity)}
              className="cursor-action bg-green-success white tipo-description btn-view-numeral-report"
            >
              &nbsp;<FontAwesomeIcon icon={faEye}></FontAwesomeIcon> &nbsp;
              Vista por numerales
            </div>
          )}
        </div>
      </div>
      <div className="content-general-items-report w-100 flex">
        {/* viewItems */}
        {!viewActivity && (
          <div className="flex-items-container flex-wrap">
            <div className="flex-wrap container-total-report">
              {/* header result */}
              <IconsReport />
              <div className="flex">
                {" "}
                <div className="flex-1">
                  <p>Items evaluados</p>
                </div>
                <div className="flex container-result-flex-report">
                  <div className="flex container-border-result-report">
                    <div className="content-result flex">
                      <span
                        className={`margin-auto ${
                          dataItem.numberCalificables.length > 3 &&
                          dataItem.numberResult.length > 2
                            ? "size-four-report"
                            : ""
                        }`}
                      >
                        {dataItem.numberResult +
                          "/" +
                          dataItem.numberCalificables}
                      </span>
                    </div>
                    <div className="content-result flex border-result-report">
                      <span>{dataItem.percentage}%</span>
                    </div>
                    <div className=" content-result  flex border-result-report">
                      <span>{canViewResult ? dataItem.fulfill : "-"}</span>
                    </div>
                    <div className="  content-result flex border-result-report">
                      <span>{canViewResult ? dataItem.fails : "-"}</span>
                    </div>
                    <div className=" content-result flex border-result-report">
                      <span>{canViewResult ? dataItem.notApply : "-"}</span>
                    </div>
                    <div className=" content-result flex border-result-report">
                      <span>{canViewResult ? dataItem.documents : "-"}</span>
                    </div>
                    <div className="content-result  flex border-result-report">
                      <span>{canViewResult ? dataItem.notes : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {statusAdvance === STATUS_LOADING && (
                <div className="flex">
                  <div className="flex-column margin-auto">
                    <div>Cargando...</div>
                    <img style={{ width: "75px" }} src={imgLoading} alt="" />
                  </div>
                </div>
              )}
              {report.activities.map((activity) => {
                return (
                  <div key={activity.id}>
                    {activity.is_evaluable && (
                      <>
                        {activity.evaluations.map((evaluation) => {
                          return (
                            <div key={evaluation.id}>
                              {evaluation.items.map((item) => {
                                return (
                                  <ol className="ol-report-audit" key={item.id}>
                                    <li
                                      className={`${
                                        itemDeploy["item" + item.id]
                                          ? "dotted-li"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        className={`ol-items-activity flex content-item-column-report `}
                                      >
                                        <div
                                          className={`container-into-item-report ${
                                            getNumber(item).percentage == 100
                                              ? "bg-green-success white"
                                              : "bg-gray-2 white "
                                          } flex flex-1`}
                                        >
                                          <div className="container-circle-item flex">
                                            {item.items.length > 0 && (
                                              <div
                                                onClick={() =>
                                                  setDeploy(item.id)
                                                }
                                                className={`${
                                                  itemDeploy["item" + item.id]
                                                    ? "circle-item-down-gray"
                                                    : "circle-item-up-gray"
                                                } circle-number-items`}
                                              >
                                                {" "}
                                                {item.items.length}
                                              </div>
                                            )}
                                          </div>
                                          <div className="flex flex-column margin-auto flex-1">
                                            <div className="">
                                              <strong>
                                                {item.auditable_item &&
                                                !item.search
                                                  ? item.auditable_item.number
                                                  : ""}
                                                {item.auditable_item &&
                                                  item.search && (
                                                    <>
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            item.auditable_item
                                                              .number,
                                                        }}
                                                      />
                                                    </>
                                                  )}
                                              </strong>{" "}
                                              {item.search && (
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                />
                                              )}
                                              {!item.search && item.title}
                                            </div>
                                            {canViewResult &&
                                              item.documents.length > 0 && (
                                                <div className="flex flex-1">
                                                  {item.documents &&
                                                    item.documents.map(
                                                      (document, index) => {
                                                        return (
                                                          <div key={index}>
                                                            {(document.type ==
                                                              EXT_JPG ||
                                                              document.type ==
                                                                EXT_JPEG ||
                                                              document.type ==
                                                                EXT_PNG) && (
                                                              <div className="flex container-image-report">
                                                                <img
                                                                  onClick={() =>
                                                                    history.push(
                                                                      `../../app/library/document/${document.id}`
                                                                    )
                                                                  }
                                                                  className="image-desktop-item"
                                                                  key={
                                                                    document.id
                                                                  }
                                                                  src={
                                                                    process.env
                                                                      .REACT_APP_API_HOST +
                                                                    "/documents/" +
                                                                    document.name
                                                                  }
                                                                  title={
                                                                    document.title
                                                                  }
                                                                  onError={(
                                                                    e
                                                                  ) => {
                                                                    e.target.src = imgErrorDefault;
                                                                  }}
                                                                  alt=""
                                                                />
                                                              </div>
                                                            )}
                                                            {document.type ==
                                                              EXT_PDF && (
                                                              <div
                                                                className="container-img-item-mobile flex "
                                                                key={
                                                                  document.id
                                                                }
                                                              >
                                                                <div
                                                                  title={
                                                                    document.title
                                                                  }
                                                                  className="cursor-action btn-pdf-observation btn-files-observations"
                                                                  onClick={() =>
                                                                    history.push(
                                                                      `../../app/library/document/${document.id}`
                                                                    )
                                                                  }
                                                                ></div>
                                                              </div>
                                                            )}
                                                            {(document.type ==
                                                              EXT_DOCX ||
                                                              document.type ==
                                                                EXT_DOC) && (
                                                              <div
                                                                className="container-img-item-mobile flex "
                                                                key={
                                                                  document.id
                                                                }
                                                              >
                                                                <div
                                                                  className="cursor-action btn-word-observation btn-files-observations"
                                                                  title={
                                                                    document.title
                                                                  }
                                                                  onClick={() =>
                                                                    downloadDocument(
                                                                      process
                                                                        .env
                                                                        .REACT_APP_API_HOST +
                                                                        "documents/" +
                                                                        document.name
                                                                    )
                                                                  }
                                                                ></div>
                                                              </div>
                                                            )}
                                                            {document.type ==
                                                              EXT_MP3 && (
                                                              <div
                                                                className="container-img-item-mobile flex "
                                                                key={
                                                                  document.id
                                                                }
                                                              >
                                                                <div
                                                                  title={
                                                                    document.title
                                                                  }
                                                                  className="cursor-action btn-mp3-observation btn-files-observations"
                                                                  onClick={() =>
                                                                    history.push(
                                                                      `../../app/library/document/${document.id}`
                                                                    )
                                                                  }
                                                                ></div>
                                                              </div>
                                                            )}
                                                            {document.type ==
                                                              EXT_MP4 && (
                                                              <div
                                                                className="container-img-item-mobile flex "
                                                                key={
                                                                  document.id
                                                                }
                                                              >
                                                                <div
                                                                  title={
                                                                    document.title
                                                                  }
                                                                  className="cursor-action btn-mp4-observation btn-files-observations"
                                                                  onClick={() =>
                                                                    history.push(
                                                                      `../../app/library/document/${document.id}`
                                                                    )
                                                                  }
                                                                ></div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              )}
                                            {item.search && (
                                              <div className="flex flex-1 justify-end">
                                                <div className="search-icon-item-desktop background-simple"></div>
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        <div className="flex container-result-flex-report">
                                          <div className="flex container-border-result-report">
                                            <Results
                                              canViewResult={canViewResult}
                                              item={item}
                                              setNotes={setNotes}
                                              settoogleNotes={settoogleNotes}
                                              toogleNotes={toogleNotes}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {(itemDeploy["item" + item.id] ||
                                        item.deploy) && (
                                        <>
                                          {canViewResult &&
                                            item.items != "" && (
                                              <SubItems
                                                canViewResult={canViewResult}
                                                setDeploy={setDeploy}
                                                itemDeploy={itemDeploy}
                                                items={item.items}
                                                setNotes={setNotes}
                                                settoogleNotes={settoogleNotes}
                                                toogleNotes={toogleNotes}
                                              />
                                            )}
                                        </>
                                      )}
                                    </li>
                                  </ol>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* end view items */}
        {/* view activity */}
        {viewActivity && (
          <div className="flex-items-container flex-wrap">
            <div className="flex-wrap container-total-report">
              {/* header result */}

              <IconsReport />
              <div className="flex">
                {" "}
                <div className="flex-1">
                  <p>Items evaluados</p>
                </div>
                <div className="flex container-result-flex-report">
                  <div className="flex container-border-result-report">
                    <div className="content-result flex-1 flex">
                      <span
                        className={`margin-auto ${
                          dataItem.numberCalificables.length > 3 &&
                          dataItem.numberResult.length > 2
                            ? "size-four-report"
                            : ""
                        }`}
                      >
                        {dataItem.numberResult +
                          "/" +
                          dataItem.numberCalificables}
                      </span>
                    </div>
                    <div className="content-result  flex border-result-report">
                      <span>{dataItem.percentage}%</span>
                    </div>
                    <div className=" content-result  flex border-result-report">
                      <span>{dataItem.fulfill}</span>
                    </div>
                    <div className="  content-result  flex border-result-report">
                      <span>{dataItem.fails}</span>
                    </div>
                    <div className=" content-result flex border-result-report">
                      <span>{dataItem.notApply}</span>
                    </div>
                    <div className=" content-result  flex border-result-report">
                      <span>{dataItem.documents}</span>
                    </div>
                    <div className="content-result flex border-result-report">
                      <span>{dataItem.notes}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-wrap flex">
              {report.weeksActivities.map((week, index) => {
                return (
                  <div key={index} className="flex w-100">
                    <div className="flex-1 ">
                      <div className="b-color-black-gray white content-activity-report flex">
                        {week.days.length > 0 && (
                          <div className="container-circle-item flex">
                            <div
                              onClick={() => setWeek(week.week)}
                              className={`${
                                weekDeploy[week.week]
                                  ? "circle-item-down-gray"
                                  : "circle-item-up-gray"
                              } circle-number-items`}
                            >
                              {" "}
                              {week.days.length}
                            </div>
                          </div>
                        )}
                        Semana{" "}
                        {moment(
                          report.activitiesGroup[week.week][week.days[0]][0]
                            .init_date
                        ).week()}{" "}
                        del año{" "}
                        {moment(
                          report.activitiesGroup[week.week][week.days[0]][0]
                            .init_date
                        ).year()}{" "}
                        (
                        {moment(
                          report.activitiesGroup[week.week][week.days[0]][0]
                            .init_date
                        ).format("MMMM")}
                        )
                      </div>

                      {weekDeploy[week.week] &&
                        week.days.map((day, index) => {
                          return (
                            <div key={index}>
                              <ol>
                                <div className="b-color-black-gray flex white container-day-activity-report">
                                  {report.activitiesGroup[week.week][day]
                                    .length > 0 && (
                                    <div className="container-circle-item flex">
                                      <div
                                        onClick={() => setDay(week.week + day)}
                                        className={`${
                                          daysDeploy[week.week + day]
                                            ? "circle-item-down-gray"
                                            : "circle-item-up-gray"
                                        } circle-number-items`}
                                      >
                                        {" "}
                                        {
                                          report.activitiesGroup[week.week][day]
                                            .length
                                        }
                                      </div>
                                    </div>
                                  )}
                                  {day} de{" "}
                                  {moment(
                                    report.activitiesGroup[week.week][day][0]
                                      .init_date
                                  ).format("MMMM")}
                                </div>
                                {daysDeploy[week.week + day] &&
                                  report.activitiesGroup[week.week][day].map(
                                    (activity) => {
                                      return (
                                        <div
                                          key={activity.id}
                                          className={`container-day-report flex flex-1  `}
                                        >
                                          <ol
                                            className={`flex-1 flex white bg-gray-2 ol-activity-view ${
                                              getNumberActivity(activity)
                                                .percentage === 100
                                                ? "bg-green-success"
                                                : ""
                                            }`}
                                          >
                                            <div>
                                              {moment(
                                                activity.init_date
                                              ).format("hh:mm a")}
                                              &nbsp;
                                            </div>
                                            <ul className="flex-1">
                                              {activity.name}
                                            </ul>
                                          </ol>
                                          <div className="content-result-activity-report">
                                            <div className="flex container-border-result-report">
                                              <>
                                                <div className="content-result flex">
                                                  <span>
                                                    {getNumberActivity(activity)
                                                      .numberResult +
                                                      "/" +
                                                      getNumberActivity(
                                                        activity
                                                      ).numberCalificables}
                                                  </span>
                                                </div>
                                                <div className="content-result flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).percentage
                                                    }
                                                    %
                                                  </span>
                                                </div>
                                                <div className=" content-result  flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).fulfill
                                                    }
                                                  </span>
                                                </div>
                                                <div className="  content-result  flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).fails
                                                    }
                                                  </span>
                                                </div>
                                                <div className=" content-result  flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).notApply
                                                    }
                                                  </span>
                                                </div>
                                                <div className=" content-result  flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).documents
                                                    }
                                                  </span>
                                                </div>
                                                <div className="content-result  flex border-result-report">
                                                  <span>
                                                    {
                                                      getNumberActivity(
                                                        activity
                                                      ).notes
                                                    }
                                                  </span>
                                                </div>
                                              </>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </ol>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* end view activity */}
      </div>
    </div>
  );
};
const SubItems = ({
  canViewResult,
  items,
  itemDeploy,
  setDeploy,
  setNotes,
  settoogleNotes,
  toogleNotes,
}) => {
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  let history = useHistory();
  return (
    <>
      {items.map((item) => {
        return (
          <ol className="ol-report-audit" key={item.id}>
            <li
              className={`${itemDeploy["item" + item.id] ? "dotted-li" : ""}`}
            >
              <div
                className={`ol-items-activity flex content-item-column-report `}
              >
                <div
                  className={`container-into-item-report ${
                    getNumber(item).percentage == 100
                      ? "bg-green-success white"
                      : "bg-gray-2 white"
                  } flex flex-1`}
                >
                  <div className="container-circle-item flex">
                    {item.items.length > 0 && (
                      <div
                        onClick={() => setDeploy(item.id)}
                        className={`${
                          itemDeploy["item" + item.id]
                            ? "circle-item-down-gray"
                            : "circle-item-up-gray"
                        } circle-number-items`}
                      >
                        {" "}
                        {item.items.length}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-column margin-auto flex-1">
                    <div className="">
                      <strong>
                        {item.auditable_item && !item.search
                          ? item.auditable_item.number
                          : ""}
                        {item.auditable_item && item.search && (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.auditable_item.number,
                              }}
                            />
                          </>
                        )}
                      </strong>{" "}
                      {item.search && (
                        <div dangerouslySetInnerHTML={{ __html: item.title }} />
                      )}
                      {!item.search && item.title}
                    </div>
                    <div className="flex flex-1">
                      {item.documents &&
                        item.documents.map((document) => {
                          return (
                            <div key={document.id}>
                              {(document.type == EXT_JPG ||
                                document.type == EXT_JPEG ||
                                document.type == EXT_PNG) && (
                                <div
                                  key={document.id}
                                  className="flex container-image-report"
                                >
                                  <img
                                    onClick={() =>
                                      history.push(
                                        `../../app/library/document/${document.id}`
                                      )
                                    }
                                    className="image-desktop-item"
                                    src={
                                      process.env.REACT_APP_API_HOST +
                                      "/documents/" +
                                      document.name
                                    }
                                    title={document.title}
                                    onError={(e) => {
                                      e.target.src = imgErrorDefault;
                                    }}
                                    alt=""
                                  />
                                </div>
                              )}
                              {document.type == EXT_PDF && (
                                <div
                                  className="container-img-item-mobile flex "
                                  key={document.id}
                                >
                                  <div
                                    title={document.title}
                                    className="cursor-action btn-pdf-observation btn-files-observations"
                                    onClick={() =>
                                      history.push(
                                        `../../app/library/document/${document.id}`
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                              {(document.type == EXT_DOCX ||
                                document.type == EXT_DOC) && (
                                <div
                                  className="container-img-item-mobile flex "
                                  key={document.id}
                                >
                                  <div
                                    title={document.title}
                                    className="cursor-action btn-word-observation btn-files-observations"
                                    onClick={() =>
                                      downloadDocument(
                                        process.env.REACT_APP_API_HOST +
                                          "documents/" +
                                          document.name
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                              {(document.type == EXT_XLSX ||
                                document.type == EXT_XLS) && (
                                <div
                                  className="container-img-item-mobile flex "
                                  key={document.id}
                                >
                                  <div
                                    title={document.title}
                                    className="cursor-action btn-excell-observation btn-files-observations"
                                    onClick={() =>
                                      downloadDocument(
                                        process.env.REACT_APP_API_HOST +
                                          "documents/" +
                                          document.name
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                              {document.type == EXT_MP3 && (
                                <div
                                  className="container-img-item-mobile flex "
                                  key={document.id}
                                >
                                  <div
                                    title={document.title}
                                    className="cursor-action btn-mp3-observation btn-files-observations"
                                    onClick={() =>
                                      history.push(
                                        `../../app/library/document/${document.id}`
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                              {document.type == EXT_MP4 && (
                                <div
                                  className="container-img-item-mobile flex "
                                  key={document.id}
                                >
                                  <div
                                    title={document.title}
                                    className="cursor-action btn-mp4-observation btn-files-observations"
                                    onClick={() =>
                                      history.push(
                                        `../../app/library/document/${document.id}`
                                      )
                                    }
                                  ></div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    {item.search && (
                      <div className="flex flex-1 justify-end">
                        <div className="search-icon-item-desktop background-simple"></div>
                      </div>
                    )}
                  </div>
                </div>

                <div className={`flex container-result-flex-report`}>
                  <div className="flex container-border-result-report">
                    <Results
                      canViewResult={canViewResult}
                      item={item}
                      setNotes={setNotes}
                      settoogleNotes={settoogleNotes}
                      toogleNotes={toogleNotes}
                    />
                  </div>
                </div>
              </div>
              {(itemDeploy["item" + item.id] || item.deploy) && (
                <>
                  {item.items != "" && (
                    <SubItems
                      canViewResult={canViewResult}
                      setDeploy={setDeploy}
                      itemDeploy={itemDeploy}
                      items={item.items}
                      canViewResult={canViewResult}
                      setNotes={setNotes}
                      settoogleNotes={settoogleNotes}
                      toogleNotes={toogleNotes}
                    />
                  )}
                </>
              )}
            </li>
          </ol>
        );
      })}
    </>
  );
};
const getNumber = (item) => {
  let data = {
    numberCalificables: 0,
    numberResult: 0,
    percentege: 0,
    fulfill: 0,
    fails: 0,
    notApply: 0,
    documents: 0,
    notes: 0,
  };
  data = getDataItem(data, item);

  let percentage =
    data.numberResult == 0 && data.numberCalificables == 0
      ? 0
      : (data.numberResult * 100) / data.numberCalificables;
  data.percentage = percentage === 100 ? percentage : percentage.toFixed(1);

  return data;
};
const Results = ({
  item,
  settoogleNotes,
  toogleNotes,
  setNotes,
  canViewResult,
}) => {
  const setNotesResult = (notes) => {
    setNotes(notes);
    settoogleNotes(!toogleNotes);
  };
  return (
    <>
      {item.auditable_item && (
        <>
          <div className="content-result  flex-1 flex">
            <span className="margin-auto">
              {getNumber(item).numberResult +
                "/" +
                getNumber(item).numberCalificables}
            </span>
          </div>
          <div className="content-result flex-1 flex border-result-report">
            <span>{getNumber(item).percentage}%</span>
          </div>

          <div className=" content-result flex-1 flex border-result-report">
            <span>{canViewResult ? getNumber(item).fulfill : "-"}</span>
          </div>
          <div className="  content-result flex-1 flex border-result-report">
            <span>{canViewResult ? getNumber(item).fails : "-"}</span>
          </div>
          <div className=" content-result flex-1 flex border-result-report">
            <span>{canViewResult ? getNumber(item).notApply : "-"}</span>
          </div>
          <div className=" content-result flex-1 flex border-result-report">
            <span>{canViewResult ? getNumber(item).documents : "-"}</span>
          </div>
          <div className="content-result flex-1 flex border-result-report">
            <span
              className={`${
                item.notes.length > 0 ? "link-notes-report cursor-action" : ""
              }`}
              onClick={() =>
                item.notes.length > 0 ? setNotesResult(item.notes) : ""
              }
            >
              {canViewResult ? getNumber(item).notes : "-"}
            </span>
          </div>
        </>
      )}
    </>
  );
};
const IconsReport = () => {
  return (
    <div className="flex">
      <div className="flex-1"></div>
      <div className="flex container-icons-report">
        <div className="icon-advance"></div>
        <div className="icon-percentage"></div>
        <div className="icon-like"></div>
        <div className="icon-dislike"></div>
        <div className="icon-notApply"></div>
        <div className="icon-images"></div>
        <div className="icon-notes"></div>
      </div>
    </div>
  );
};
const Document = ({ document }) => {
  let history = useHistory();
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  return (
    <>
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <img
          className="cursor-action img-attached-final-report"
          src={
            process.env.REACT_APP_API_HOST +
            "/documents/" +
            (document && document.name)
          }
          onError={(e) => {
            e.target.src = imgError;
          }}
        />
      )}
      {document.type == EXT_PDF && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-pdf-observation btn-files-observations"
        ></div>
      )}
      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-word-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP3 && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp3-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP4 && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp4-observation btn-files-observations"
        ></div>
      )}
    </>
  );
};
export default Report;
