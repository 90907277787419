import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import CreateAuditCompanyGeneral from "./general/CreateAuditCompany";
const CreateAuditCompany = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">
          Auditorías Independiente de Empresa
        </div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Gestione sus auditorías con trazabilidad y acorde a normas de
              auditoria Incluya auditores de apoyo y evaluados de la empresa a
              auditar.<br></br> Realice la planeación detallada, ejecución y reporte
              final con las funcionalidades de la plataforma y bajo ISO
              19011:2018
            </p>
          </div>
        </div>
      </div>
  
     
    <CreateAuditCompanyGeneral/>
      
    </div>
  );
};

export default CreateAuditCompany;
