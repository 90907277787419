import React, { useEffect, useState, useContext } from "react";
import client from "../axios/axios-client";

import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import Activities, { STATUS_EXECUTED } from "../../components/audit/audit/Activities";
import firebase from "../../firebase";
import moment from "moment";
import $ from "jquery";

import Swal from "sweetalert2";
import { STATUS_APPROVED } from "../../components/audit/modals/CreateActivity";
import { STATUS_INPROGRESS } from "../../components/audit/audit";
export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
export const STATUS_CREATED = 1;

const STATUS_NEW = "NEW";
const STATUS_SHOW = "SHOW";
const STATUS_EDIT = "EDIT";
const NOTIFICATION_FINAL_REPORT_APPROVEDD = "final-approvedd";
const NOTIFICATION_OPEN_CLOSE_ACTIVITY = "open-close-Activity";
const NOTIFICATION_APPROVED_PLAN = "approved-plan";
const ROLE_FINAL_DOCUMENT = "final_document";
const MAX_FILE_SIZE_FREE = 2;

const AUDIT_COMPANY = "audit_company";
const NOTIFICATION_MODULE_AUDIT = "audit";
const db = firebase.firestore();
let initialState = {
  pdfAudit: null,
  statusAdvance: STATUS_NOT_LOADED,
  dataItem: {
    numberCalificables: 0,
    numberResult: 0,
    percentage: 0,
    fulfill: 0,
    fails: 0,
    notApply: 0,
    documents: 0,
    notes: 0,
  },
  generateEvaluationsStatus: STATUS_LOADED,
  notification: {
    viewed: false,
    touched: false,
    body: "",
    owner_person_id: null,
    owner_person_name: "",
    created_at: new Date().getTime(),
    type: "",
    module: "",
  },
  folder: {
    folder_id: null,
    recursive: [],
    status: STATUS_NOT_LOADED,
    elements: {},
  },
  toogleModalReportProcess: false,
  toogleModalChecklistProcess: false,
  toogleModalVerificationProcess: false,

  uploadMasive: STATUS_LOADED,
  processReport: [],
  processChecklist: [],
  processVerification: [],

  audit: {
    statusForm: STATUS_SHOW,
    status: STATUS_NOT_LOADED,
    elements: {},
    originalItem: {},

    audit_id: null,
  },
  analyze: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  template: {
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  participantType: {
    status: STATUS_NOT_LOADED,
    elements: {},
  },
  filter: {
    init_date: false,
    end_date: false,
  },
  itemsActivity: [],
  userAuditorSearch: {},
  errors: {
    error: [],
    status: false,
  },
};

export const AuditPlanFormContext = React.createContext([]);

export const AuditPlanFormContextProvider = ({ children }) => {
  const [audit, setAudit] = useState(initialState.audit);
  const [participantType, setParticipantType] = useState(initialState.participantType);
  const [{}, { alertSuccess, alertError, showErrors, alertWarning }] = useContext(AlertContext);
  const [{ user }, { logout }] = useContext(SecurityContext);
  let [filter, setFilter] = useState(initialState.filter);
  let [errors, setErrors] = useState(initialState.errors);
  const [folder, setfolder] = useState(initialState.folder);
  const [uploadMasive, setuploadMasive] = useState(initialState.uploadMasive);
  const [template, setTemplate] = useState(initialState.template);
  const [itemsSelectedActivity, setitemsSelectedActivity] = useState([]);
  const [itemsActivity, setItemsActivity] = useState(initialState.itemsActivity);
  let [userAuditor, setUserAuditor] = useState(initialState.userAuditorSearch);
  const [statusAdvance, setstatusAdvance] = useState(initialState.statusAdvance);
  const [auditPdf, setauditPdf] = useState(null);
  const [processReport, setprocessReport] = useState(initialState.processReport);
  const [toogleModalProcessReport, settoogleModalProcessReport] = useState(
    initialState.toogleModalReportProcess
  );
  const [dataItem, setdataItem] = useState(initialState.dataItem);
  const [processChecklist, setprocessChecklist] = useState(initialState.processChecklist);
  const [processVerification, setprocessVerification] = useState(initialState.processVerification);
  const [toogleModalChecklistProcess, settoogleModalChecklistProcess] = useState(
    initialState.toogleModalChecklistProcess
  );
  const [itemsIntemplate, setitemsIntemplate] = useState([]);
  const [toogleModalVerificationProcess, settoogleModalVerificationProcess] = useState(
    initialState.toogleModalVerificationProcess
  );
  const [usersSearch, setusersSearch] = useState([]);
  const [generateEvaluationsStatus, setgenerateEvaluationsStatus] = useState(
    initialState.generateEvaluationsStatus
  );
  const [statusauditpdf, setstatusauditpdf] = useState(STATUS_NOT_LOADED);
  const [analyzes, setanalyzes] = useState(initialState.analyze);
  const [modulePdf, setmodulePdf] = useState("");
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);

  // componteDidMointed & componentDidUpdated
  useEffect(() => {
    if (audit.status == STATUS_LOADED) {
      db.collection(`audits/${audit.audit_id}/evaluations`).onSnapshot(function (data) {
        let evaluationsFire = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        /*    let evaluationsFinds = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.active) {
            itemsFind.push(findItem(item.id,evaluation.element.items));
          }
        }
        setitemsRemote(itemsFind); */
      });
    }
  }, [audit.status]);

  useEffect(() => {
    if (audit.audit_id != null && audit.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/getAudit/${audit.audit_id}`)
        .then(({ data }) => {
          if (data) {
            if (data.audit.template_id && data.audit.status_id === STATUS_CREATED) {
              selectItemsOnTemplate(data.audit.template_id);
            }
            setdataItem(data.result);
            setAudit({
              ...audit,
              status: STATUS_LOADED,
              originalItem: data.audit,
            });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar la auditoría");
          }
        });
    }
  }, [audit]);
  useEffect(() => {
    if (
      audit.audit_id != null &&
      statusAdvance === STATUS_NOT_LOADED &&
      audit.status === STATUS_LOADED
    ) {
      setstatusAdvance(STATUS_LOADING);
      client()
        .get(`audit/getAuditActivities/${audit.audit_id}`)
        .then(({ data }) => {
          let auditActivities = data;
          client()
            .get(`audit/getAuditEvaluations/${audit.audit_id}`)
            .then(({ data }) => {
              let auditEvaluations = data;
              setstatusAdvance(STATUS_LOADED);
              let copyAudit = cloneDeep(audit.originalItem);
              copyAudit.activities = auditActivities.activities;
              copyAudit.evaluations = auditEvaluations.evaluations;
              setAudit({
                ...audit,
                originalItem: copyAudit,
              });
            });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los items de la auditoría");
          }
        });
    }
  }, [statusAdvance, audit]);
  useEffect(() => {
    if (audit.status === STATUS_LOADED) {
      client()
        .get(`audit/getTamplatesAudit/${audit.originalItem.id}`)
        .then(({ data }) => {
          setTemplate({
            ...template,
            status: STATUS_LOADED,
            elements: data,
          });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer las plantillas");
          }
        });
    }
  }, [audit.status]);

  useEffect(() => {
    if (folder.folder_id != null && folder.status == STATUS_NOT_LOADED) {
      setfolder({
        ...folder,
        status: STATUS_LOADING,
      });
      client()
        .get(`audit/audit_folder/${folder.folder_id}`)
        .then(({ data }) => {
          let auditFolders = data;
          client()
            .get(`audit/getRecursiveFolder/${folder.folder_id}`)
            .then(({ data }) => {
              if (data.length == 0) {
                data = [];
              }

              setfolder({
                ...folder,
                status: STATUS_LOADED,
                elements: auditFolders,
                recursive: data.reverse(),
              });
            });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al traer los documentos");
          }
        });
    }
  }, [folder.status, folder.folder_id]);
  useEffect(() => {
    if (userAuditor != "" && participantType.status === STATUS_NOT_LOADED) {
      client()
        .get(`audit/participant_type`)
        .then(({ data }) => {
          if (data) {
            setParticipantType({ ...participantType, status: STATUS_LOADED, elements: data });
          }
        });
    }
  }, [participantType]);
  useEffect(() => {
    if (audit.status === STATUS_LOADED && analyzes.status === STATUS_NOT_LOADED) {
      setanalyzes({ ...analyzes, status: STATUS_LOADING });
      client()
        .get(`audit/analyze`, {
          params: {
            filters: {
              audit_id: audit.audit_id,
            },
            with: ["actions", "analyzable.auditable_item", "responsable"],
          },
        })
        .then(({ data }) => {
          if (data) {
            setanalyzes({ status: STATUS_LOADED, elements: data });
          }
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al buscar los planes de accion");
          }
        });
    }
  }, [analyzes.status, audit.status]);

  //functions

  const filterInit = () => {
    let filterInit = audit.originalItem.activities.sort(function (a, b) {
      var c = new Date(a.init_date);
      var d = new Date(b.init_date);
      if (filter.init_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setAudit({ ...audit, originalItem: { ...audit.originalItem, activities: filterInit } });
    setFilter({ ...filter, init_date: !filter.init_date });
  };

  const filterEnd = () => {
    let filterEnd = audit.originalItem.activities.sort(function (a, b) {
      var c = new Date(a.end_date);
      var d = new Date(b.end_date);
      if (filter.end_date === false) {
        return c - d;
      } else {
        return d - c;
      }
    });
    setAudit({ ...audit, originalItem: { ...audit.originalItem, activities: filterEnd } });
    setFilter({ ...filter, end_date: !filter.end_date });
  };

  let getAudit = (audit_id) => {
    setAudit({
      ...audit,
      audit_id: audit_id,
    });
  };

  const updateAudit = ({
    id,
    criterial,
    methodology,
    exclusions,
    resources,
    responsability_leader,
    responsability_team,
    requirements,
    scope,
    objetives,
    conclusion,
    risks,
  }) => {
    var formData = new FormData();
    formData.append("scope", scope.toString("html"));
    formData.append("criterial", criterial.toString("html"));
    formData.append("methodology", methodology.toString("html"));
    formData.append("objetives", objetives.toString("html"));
    formData.append("exclusions", exclusions.toString("html"));
    formData.append("resources", resources.toString("html"));
    formData.append("responsability_leader", responsability_leader.toString("html"));
    formData.append("responsability_team", responsability_team.toString("html"));
    formData.append("requirements", requirements.toString("html"));
    formData.append("conclusion", conclusion.toString("html"));
    formData.append("risks", risks.toString("html"));

    client()
      .post(`audit/audit/${id}`, formData)
      .then(() => {
        alertSuccess("Auditoria actualizada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la auditoria");
        }
      });
  };
  const changeTemplateAudit = (template_id) => {
    client()
      .post(`audit/audit/${audit.audit_id}`, { template_id })
      .then(() => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar el usuario");
        }
      });
  };

  const searchUsers = (text) => {
    client()
      .get(`security/searchUsers/${text}`)
      .then(({ data }) => {
        if (data) {
          setusersSearch(data);
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar los usuarios");
        }
      });
  };
  const addStaffParticipant = (person_id) => {
    const partnerts = audit.originalItem.company.partners.filter((p) => p.id == person_id);
    if (partnerts.length == 0) {
      let valid_date = moment();
      client()
        .post("general/staff", {
          position: "Auditor",
          valid_date,
          person_id,
          first_name: userAuditor.first_name,
          last_name: userAuditor.last_name,
          company_id: audit.originalItem.company_id,
        })
        .then(({ data }) => {
          alertSuccess("Integrante agregado a la empresa");
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al agregar integrante al staff");
          }
        });
    }
  };
  const addAuditor = (participant_type_id, person_id, company_process_id) => {
    client()
      .post(`audit/audit_participant`, {
        audit_id: audit.audit_id,
        person_id: person_id,
        participant_type_id: participant_type_id,
        company_process_id: company_process_id,
      })
      .then(({ data }) => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setUserAuditor({});
        setusersSearch([]);
        // addStaffParticipant(person_id);
        alertSuccess("Participante agregado satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al agregar participante");
        }
      });
  };

  const addActivity = ({
    is_evaluable,
    location,
    name,
    description,
    init_date,
    end_date,
    audit_participants,
    process_id,
    evaluators,
  }) => {
    for (let p = 0; p < audit_participants.length; p++) {
      const participant = audit_participants[p];
      participant.is_evaluator = false;
    }
    if (evaluators) {
      if (is_evaluable) {
        evaluators.is_evaluator = true;
        audit_participants.push(evaluators);
      } else {
        for (let p = 0; p < evaluators.length; p++) {
          const evaluator = evaluators[p];
          evaluator.is_evaluator = true;
          audit_participants.push(evaluator);
        }
      }
    }

    client()
      .post(`audit/audit_activity`, {
        audit_id: audit.audit_id,
        name: name,
        description: description,
        init_date: moment(init_date).format("YYYY-MM-DD HH:mm"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm"),
        audit_participants: audit_participants,
        location: location,
        is_evaluable: is_evaluable,
        items: itemsSelectedActivity,
        process_id: process_id ? process_id.id : null,
      })
      .then(({ data }) => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        alertSuccess("Actividad agregada satisfactoriamente");
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al agregar actividad");
        }
      });
  };

  const updateActivity = (
    activity_id,
    {
      name,
      description,
      init_date,
      end_date,
      audit_participants,
      location,
      is_evaluable,
      evaluators,
      process_id,
    }
  ) => {
    for (let p = 0; p < audit_participants.length; p++) {
      const participant = audit_participants[p];
      participant.is_evaluator = false;
    }

    if (evaluators) {
      if (is_evaluable) {
        evaluators.is_evaluator = true;
        audit_participants.push(evaluators);
      } else {
        for (let p = 0; p < evaluators.length; p++) {
          const evaluator = evaluators[p];
          evaluator.is_evaluator = true;
          audit_participants.push(evaluator);
        }
      }
    }

    client()
      .post(`audit/audit_activity/${activity_id}`, {
        name,
        description,
        init_date: moment(init_date).format("YYYY-MM-DD HH:mm"),
        end_date: moment(end_date).format("YYYY-MM-DD HH:mm"),
        is_evaluable: is_evaluable,
        audit_participants: audit_participants,
        location,
        items: itemsSelectedActivity,
        process_id: process_id != null ? process_id.id : null,
      })
      .then(() => {
        alertSuccess("Actividad actualizada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la actividad");
        }
      });
  };

  const deleteActivity = (id) => {
    client()
      .delete(`audit/audit_activity/${id}`)
      .then(({ data }) => {
        alertSuccess("Actividad eliminada satisfactoriamente");
        let el = audit.originalItem.activities.filter((e) => e.id !== id);
        setAudit({
          ...audit,
          elements: el,
          status: STATUS_NOT_LOADED,
        });
      });
  };

  const updateAuditor = (id, participant_type_id, company_process_id) => {
    client()
      .post(`audit/audit_participant/${id}`, {
        participant_type_id: participant_type_id,
        company_process_id: company_process_id,
      })
      .then(() => {
        alertSuccess("Participante actualizado satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setUserAuditor({});
        /* history.push(`/app/audit/programs`); */
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar el participante");
        }
      });
  };

  const deleteParticipantAuditor = (id) => {
    client()
      .delete(`audit/audit_participant/${id}`)
      .then(({ data }) => {
        alertSuccess("Participante eliminado satisfactoriamente");
        let el = participantType.elements.filter((e) => e.id !== id);
        setAudit({
          ...audit,
          elements: el,
          status: STATUS_NOT_LOADED,
        });
      });
  };
  const addItemsActivity = (items) => {
    setItemsActivity(items);
  };
  const syncItemsSelectedActivity = (itemsCheck) => {
    setitemsSelectedActivity(itemsCheck);
  };
  const selectItemsOnTemplate = (template_id) => {
    client()
      .get(`audit/evaluation/${template_id}`, {
        params: {
          with: ["items.auditable_item"],
        },
      })
      .then(({ data }) => {
        if (data) {
          let itemsIdOnTemplate = recursiveSelectItemTemplate(data.items, []);
          setitemsIntemplate(itemsIdOnTemplate);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al buscar la plantilla seleccionada");
        }
      });
  };
  const recursiveSelectItemTemplate = (itemsFor, arraySelect) => {
    for (let i = 0; i < itemsFor.length; i++) {
      const item = itemsFor[i];
      if (item.auditable_item_id) {
        arraySelect.push(item.auditable_item_id);
      }
      if (item.items.length > 0) {
        arraySelect = recursiveSelectItemTemplate(item.items, arraySelect);
      }
    }
    return arraySelect;
  };
  const approvedPlan = (status_id) => {
    setgenerateEvaluationsStatus(STATUS_LOADING);
    setAudit({ ...audit, status: STATUS_LOADING });
    client()
      .get(`audit/generateEvaluationsAudit/${audit.audit_id}`)
      .then(() => {
        setgenerateEvaluationsStatus(STATUS_LOADED);

        var formData = new FormData();
        formData.append("status_id", status_id);
        client()
          .post(`audit/audit/${audit.audit_id}`, formData)
          .then(() => {
            setgenerateEvaluationsStatus(STATUS_LOADED);
            alertSuccess("Auditoria aprobada satisfactoriamente");
            sendNotificatinosApprovedPlan();
            setAudit({ ...audit, status: STATUS_NOT_LOADED });
          });
      })
      .catch((e) => {
        setgenerateEvaluationsStatus(STATUS_LOADED);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al aprobar la auditoria y generar las evaluaciones");
        }
      });
  };
  const updateDateRequestFinalReport = () => {
    if (
      user.id == audit.originalItem.lider_person.id &&
      audit.originalItem.status_id != STATUS_EXECUTED
    ) {
      var formData = new FormData();
      formData.append("final_report_approved_request_date", moment().format("YYYY-MM-DD HH:mm"));
      client()
        .post(`audit/audit/${audit.audit_id}`, formData)
        .then(() => {
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
        })
        .catch((e) => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else if (e.request.status === 403) {
            history.push("/app/unauthorized");
          } else {
            alertError("Error al planear la auditoria");
          }
        });
    }
  };
  const plannedPlan = (status_id) => {
    var formData = new FormData();
    formData.append("status_id", status_id);
    client()
      .post(`audit/audit/${audit.audit_id}`, formData)
      .then(() => {
        alertSuccess("Auditoria planeada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al planear la auditoria");
        }
      });
  };

  const inProgressPlan = (status_id) => {
    var formData = new FormData();
    formData.append("status_id", status_id);
    client()
      .post(`audit/audit/${audit.audit_id}`, formData)
      .then(() => {
        alertSuccess("Estado de auditoría actualizado satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al cambiar estado la auditoria");
        }
      });
  };

  const sendEmail = (email, note) => {
    client()
      .post("audit/send_request", {
        email: email,
        note: note,
        audit_id: audit.audit_id,
      })
      .then(({ data }) => {
        if (data) {
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar la solicitud");
        }
      });
  };

  const sendEmailApproved = (email, note) => {
    client()
      .post("audit/send_approved", {
        email: email,
        note: note,
        audit_id: audit.audit_id,
      })
      .then(({ data }) => {
        if (data) {
          alertSuccess("Se envió correo a los auditores");
          setAudit({ ...audit, status: STATUS_NOT_LOADED });
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar la aprobación");
        }
      });
  };
  const sendEmailLiderFinalReport = (audit_id) => {
    client()
      .get(`audit/sendEmailFinalReport/${audit_id}`)
      .then(({ data }) => {
        if (data) {
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar el correo");
        }
      });
  };
  const sendMessageFinalReport = ({ note, document, role, is_private }) => {
    client()
      .post(`audit/audit/${audit.audit_id}/notificated`, {
        note: note,
        role: role,
        person_id: user.id,
      })
      .then(({ data }) => {
        updateDateRequestFinalReport();
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        if (data) {
          if (document != null) {
            const formData = new FormData();
            formData.append("document", document);
            formData.append("is_private", is_private);

            client()
              .post(`general/note/${data.id}/documented`, formData)
              .then(({ data }) => {
                if (data) {
                  setAudit({ ...audit, status: STATUS_NOT_LOADED });
                }
              })
              .catch((e) => {
                if (e.request.status === 401) {
                  logout();
                } else if (e.request.status === 422) {
                  setErrors({ error: e.response.data, status: true });
                } else if (e.request.status === 403) {
                  history.push("/app/unauthorized");
                } else {
                  alertError("Problemas al adjuntar el documento");
                }
              });
          }
        }
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al enviar elmensaje");
        }
      });
  };

  const closeActivities = (close, activities) => {
    client()
      .post(`audit/closeActivities`, { is_closed: close, activities })
      .then(({ data }) => {
        if (close) {
          alertSuccess("Actividades cerradas satisfactoriamente");
          for (let a = 0; a < activities.length; a++) {
            const actvityIdFor = activities[a];
            let activityFor = audit.originalItem.activities.filter((a) => a.id == actvityIdFor)[0];
            if (activityFor.is_evaluable == true) {
              db.collection(
                `audits/${audit.audit_id}/evaluations/${activityFor.evaluations[0].id}/items`
              )
                .where("active", "==", true)
                .get()
                .then(function (querySnapshot) {
                  querySnapshot.forEach(function (doc) {
                    doc.ref.update({
                      active: false,
                    });
                  });
                });
            }
          }
        } else {
          alertSuccess("Actividades abiertas satisfactoriamente");
        }
        notificationOpenCloseActivity(close, activities);
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al modificar las actividades");
        }
      });
  };
  const sendNotificationResponsableSolicitudeApprovedPlan = () => {
    db.collection(`users/${audit.originalItem.responsable_person.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `ha solicitado la aprobación de la auditoría  <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${audit.originalItem.id}"> ${audit.originalItem.title}</a> `,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_APPROVED_PLAN,
      module: NOTIFICATION_MODULE_AUDIT,
    });
  };
  const sendNotificatinosApprovedPlan = () => {
    let numActivities = 0;
    for (let p = 0; p < audit.originalItem.participants.length; p++) {
      const participant = audit.originalItem.participants[p];
      for (let a = 0; a < audit.originalItem.activities.length; a++) {
        const activity = audit.originalItem.activities[a];
        for (let pa = 0; pa < activity.audit_participants.length; pa++) {
          const participant_activity = activity.audit_participants[pa];
          if (participant_activity.person.id == participant.person.id) {
            numActivities = numActivities + 1;
          }
        }
      }

      if (
        participant.participant_type.is_audit &&
        participant.person.id != audit.originalItem.lider_person.id
      ) {
        db.collection(`users/${participant.person.firebase_id}/notifications`).add({
          viewed: false,
          touched: false,
          body: `ha aprobado la auditoría  <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${audit.originalItem.id}"> ${audit.originalItem.title}</a> ,tienes ${numActivities} actividades`,
          owner_person_id: user.id,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          owner_person_name: user.first_name + " " + user.last_name,
          type: NOTIFICATION_APPROVED_PLAN,
          module: NOTIFICATION_MODULE_AUDIT,
        });
      }

      if (
        participant.participant_type.is_audit &&
        participant.person.id == audit.originalItem.lider_person.id
      ) {
        db.collection(`users/${participant.person.firebase_id}/notifications`).add({
          viewed: false,
          touched: false,
          body: `ha aprobado la auditoría  <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${audit.originalItem.id}"> ${audit.originalItem.title}</a> `,
          owner_person_id: user.id,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          owner_person_name: user.first_name + " " + user.last_name,
          type: NOTIFICATION_APPROVED_PLAN,
          module: NOTIFICATION_MODULE_AUDIT,
        });
      }
    }
  };
  const notificationOpenCloseActivity = (close, activities) => {
    for (let a = 0; a < activities.length; a++) {
      const activity_id = activities[a];
      const activity = audit.originalItem.activities.filter((a) => a.id == activity_id)[0];
      if (audit.originalItem.lider_person.id != user.id) {
        db.collection(`users/${audit.originalItem.lider_person.firebase_id}/notifications`).add({
          viewed: false,
          touched: false,
          body: `ha ${close ? "abierto" : "cerrado"} la actividad  <a class="link-default" href="${
            process.env.REACT_APP_GUI_HOST
          }app/audit/${audit.originalItem.id}"> ${activity.name}</a>`,
          owner_person_id: user.id,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          owner_person_name: user.first_name + " " + user.last_name,
          type: NOTIFICATION_OPEN_CLOSE_ACTIVITY,
          module: NOTIFICATION_MODULE_AUDIT,
        });
      }
      for (let p = 0; p < activity.audit_participants.length; p++) {
        const participant = activity.audit_participants[p];

        if (participant.participant_type.is_audit && participant.person.id != user.id) {
          db.collection(`users/${participant.person.firebase_id}/notifications`).add({
            viewed: false,
            touched: false,
            body: `ha ${
              close ? "abierto" : "cerrado"
            } la actividad  <a class="link-default" href="${
              process.env.REACT_APP_GUI_HOST
            }app/audit/${audit.originalItem.id}"> ${activity.name}</a>`,
            owner_person_id: user.id,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            owner_person_name: user.first_name + " " + user.last_name,
            type: NOTIFICATION_OPEN_CLOSE_ACTIVITY,
            module: NOTIFICATION_MODULE_AUDIT,
          });
        }
      }
    }
  };
  const addNotification = () => {
    const responsable = audit.originalItem.responsable_person;
    db.collection(`users/${audit.originalItem.lider_person.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `ha aprobado el informe final de la auditoría <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${audit.originalItem.id}"> ${audit.originalItem.title}</a>`,
      owner_person_id: responsable.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: responsable.first_name + " " + responsable.last_name,
      type: NOTIFICATION_FINAL_REPORT_APPROVEDD,
      module: NOTIFICATION_MODULE_AUDIT,
    });

    if (audit.originalItem.lider_person.id != responsable.id) {
      db.collection(`users/${responsable.firebase_id}/notifications`).add({
        viewed: false,
        touched: false,
        body: `ha aprobado el informe final de la auditoría <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/audit/${audit.originalItem.id}"> ${audit.originalItem.title}</a>`,
        owner_person_id: responsable.id,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        owner_person_name: responsable.first_name + " " + responsable.last_name,
        type: NOTIFICATION_FINAL_REPORT_APPROVEDD,
        module: NOTIFICATION_MODULE_AUDIT,
      });
    }
  };

  const closeApproveddPlan = async () => {
    client()
      .post(`audit/audit/${audit.originalItem.id}`, { status_id: STATUS_EXECUTED })
      .then(() => {
        alertSuccess("Auditoria finalizada satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        sendEmailLiderFinalReport(audit.originalItem.id);
        addNotification();
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la auditoria");
        }
      });
  };
  const addNote = (note) => {
    var formData = new FormData();
    formData.append("note", note);
    formData.append("person_id", user.id);
    client()
      .post(`audit/audit/${audit.audit_id}/notificated`, formData)
      .then(({ data }) => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir el comentario");
        }
      });
  };
  const addDocumentFinalReport = (document) => {
    var basename = document.name.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");
    var typeFile = basename.slice(pos + 1).toLowerCase();
    let typesValidate = ["pdf"];
    let isTypeOk = false;
    for (let v = 0; v < typesValidate.length; v++) {
      const type = typesValidate[v];
      if (type == typeFile) {
        isTypeOk = true;
      }
    }
    if (!isTypeOk) {
      alertWarning("Solo se permiten extensiones pdf");
      return false;
    }
    var formData = new FormData();
    formData.append("document", document);
    formData.append("is_private", true);
    formData.append("role", ROLE_FINAL_DOCUMENT);

    client()
      .post(`audit/audit/${audit.audit_id}/documented`, formData)
      .then(({ data }) => {
        alertSuccess("Documento cargado satisfactoriamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
      });
  };

  const addDocumentAudit = (document) => {
    var basename = document.name.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");
    var name = basename.slice(0, pos);
    if (!isValidFormatFileAudit(document)) {
      alertWarning("Solo se permiten extensiones validas");
      return false;
    }
    var formData = new FormData();
    formData.append("title", name);
    formData.append("document", document);
    formData.append("is_private", true);
    let ref = `audit/audit/${audit.audit_id}/documented`;
    if (folder.folder_id) {
      ref = `audit/audit_folder/${folder.folder_id}/documented`;
    }
    setuploadMasive(STATUS_LOADING);
    client()
      .post(ref, formData)
      .then(({ data }) => {
        alertSuccess("Documento cargado exitosamente");
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setfolder({ ...folder, status: STATUS_NOT_LOADED });
        setuploadMasive(STATUS_LOADED);
      })
      .catch((e) => {
        setuploadMasive(STATUS_LOADED);

        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al añadir el documento");
        }
      });
  };
  const getAuditPdf = (module, id) => {
    setstatusauditpdf(STATUS_LOADING);
    client()
      .get(`audit/${module}/${id}`)
      .then(({ data }) => {
        setauditPdf(data);
        setstatusauditpdf(STATUS_LOADED);
      })
      .catch((e) => {
        setstatusauditpdf(STATUS_NOT_LOADED);

        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al traer el documento");
        }
      });
  };
  const downloadDocument = (ref) => {
    var element = document.createElement("a");
    element.setAttribute("href", ref);
    element.setAttribute("download", "");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
    return false;
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    var params = {
      Authorization: token,
    };

    var url = [ref, $.param(params)].join("?");

    window.open(url, "_blank");
  };
  const preparePdfAudit = (audit_id) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "bottom-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Preparando descarga ,esto puede tardar unos minutos...",
    });
    client()
      .get(`audit/preparePdfAudit/${audit_id}`)
      .then(({ data }) => {
        Toast.close();
        let token = "";
        if (localStorage) {
          token = localStorage.getItem("token") || "";
        }
        downloadDocument(
          process.env.REACT_APP_API_HOST +
            "audit/downLoad" +
            modulePdf +
            "/" +
            audit_id +
            "?Authorization=" +
            token
        );
      })
      .catch((e) => {
        Toast.close();
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al preparar el documento");
        }
      });
  };
  const deleteDocument = (id) => {
    client()
      .delete(`library/document/${id}`)
      .then(({ data }) => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setfolder({ ...folder, status: STATUS_NOT_LOADED });
        alertSuccess("Documento eliminado satisfactoriamente");
      });
  };
  const deleteFolder = (id) => {
    client()
      .delete(`audit/audit_folder/${id}`)
      .then(({ data }) => {
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setfolder({ ...folder, status: STATUS_NOT_LOADED });
        alertSuccess("Carpeta eliminada satisfactoriamente");
      });
  };
  const isValidFormatFileAudit = (document) => {
    var basename = document.name.split(/[\\/]/).pop();
    var pos = basename.lastIndexOf(".");
    var typeFile = basename.slice(pos + 1).toLowerCase();
    let typesValidate = ["html","xlsx","xls","docx","pdf", "png", "jpg", "jpeg"];
    let isTypeOk = false;
    for (let v = 0; v < typesValidate.length; v++) {
      const type = typesValidate[v];
      if (type == typeFile) {
        isTypeOk = true;
      }
    }
    return isTypeOk ? true : false;
  };
  const masiveUploadDocument = (files) => {
    const filesMaxSize = [];
    let liFormat = "";
    //valdiate format
    for (let f = 0; f < files.length; f++) {
      const file = files[f];
      if (!isValidFormatFileAudit(file)) {
        liFormat = liFormat + "<li>" + file.name + "</li>";
        file.isValid = false;
      } else {
        file.isValid = true;
      }
    }

    let htmlValidate = "";

    if (liFormat != "") {
      htmlValidate = htmlValidate + `<h4>Solo se permiten formatos pdf y de imagen</h4>` + liFormat;
    }
    files = files.filter((f) => f.isValid);
    if (liFormat != "") {
      Swal.fire({
        html: htmlValidate,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Subir los validos",
      }).then((result) => {
        if (result.value) {
          uploadMasiveDocument(files);
        } else {
          return false;
        }
      });
    } else {
      uploadMasiveDocument(files);
    }
  };
  const uploadMasiveDocument = (files) => {
    setuploadMasive(STATUS_LOADING);
    var formData = new FormData();
    formData.append(`folder_id`, folder.folder_id);
    files.forEach((file, key) => {
      formData.append(`file-${key}`, file);
      formData.append(`path${key}`, file.path);
    });
    client()
      .post(`audit/masiveDocument/${audit.audit_id}`, formData)
      .then(({ data }) => {
        alertSuccess("Documentos cargados exitosamente");
        setuploadMasive(STATUS_LOADED);
        setAudit({ ...audit, status: STATUS_NOT_LOADED });
        setfolder({ ...folder, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al guardar los documentos");
        }
        setuploadMasive(STATUS_LOADED);
      });
  };
  const getTotalAdvanceFilter = (procesos, participants) => {
    client()
      .post(`audit/filterAdvance/${audit.audit_id}`, { procesos, participants })
      .then(({ data }) => {
        setdataItem(data);
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al filtrar el total");
        }
      });
  };
  const generateReportProcess = () => {
    let proces = processReport.length === 0 ? audit.originalItem.company.process : processReport;
    let urls = [];
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    for (let p = 0; p < proces.length; p++) {
      const pro = proces[p];
      urls.push(
        `${process.env.REACT_APP_API_HOST}audit/reportProcess/${audit.audit_id}/${pro.id}/${token}`
      );
    }
    var link = document.createElement("a");
    link.setAttribute("download", null);
    link.style.display = "none";

    document.body.appendChild(link);

    for (var i = 0; i < urls.length; i++) {
      link.setAttribute("href", urls[i]);
      link.click();
    }

    document.body.removeChild(link);
    settoogleModalProcessReport(!toogleModalProcessReport);
    /* return false;
    let ref = `${process.env.REACT_APP_API_HOST}audit/reportProcess/${audit.audit_id}`;
    let proces = processReport.length === 0 ? audit.originalItem.company.process : processReport;
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    for (let p = 0; p < proces.length; p++) {
      const pro = proces[p];
      console.log(pro);
      var params = {
        Authorization: token,
        process: pro,
      };
      var url = [ref, $.param(params)].join("?");

      window.open(url, "_blank");
    }

      client()
      .post(`audit/reportProcess/${audit.audit_id}`, { process })
      .then(({ data }) => {})
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al guardar los documentos");
        }
      });  */
  };
  const generateChecklistProcess = () => {
    let proces =
      processChecklist.length === 0 ? audit.originalItem.company.process : processChecklist;
    let urls = [];
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    for (let p = 0; p < proces.length; p++) {
      const pro = proces[p];
      getViewDownload(
        `${process.env.REACT_APP_API_HOST}audit/checkListProcessView/${audit.audit_id}/${pro.id}`,
        `${process.env.REACT_APP_API_HOST}audit/checkListProcess/${audit.audit_id}/${pro.id}`
      );
    }

    settoogleModalChecklistProcess(!toogleModalChecklistProcess);
  };
  const generateVerificationProcess = () => {
    let proces =
      processVerification.length === 0 ? audit.originalItem.company.process : processVerification;
    let urls = [];
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    for (let p = 0; p < proces.length; p++) {
      const pro = proces[p];
      getViewDownload(
        `${process.env.REACT_APP_API_HOST}audit/verificationProcessView/${audit.audit_id}/${pro.id}`,
        `${process.env.REACT_APP_API_HOST}audit/verificationProcess/${audit.audit_id}/${pro.id}`
      );
    }

    settoogleModalVerificationProcess(!toogleModalVerificationProcess);
  };
  const getViewDownload = (routeView, routeDownload) => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando descarga...",
    });

    client()
      .get(routeView)
      .then(({ data }) => {
        let token = "";
        let name = "NewFile";

        if (localStorage) {
          token = localStorage.getItem("token") || "";
        }

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", routeDownload);
        //form.setAttribute("target", name);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "view";
        input.value = data;
        form.appendChild(input);
        var input2 = document.createElement("input");
        input2.type = "hidden";
        input2.name = "Authorization";
        input2.value = token;
        form.appendChild(input2);
        const Toast2 = Swal.mixin({
          toast: true,
          background: "#ffffff",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });

        //note I am using a post.htm page since I did not want to make double request to the page
        //it might have some Page_Load call which might screw things up.
        // window.open("post.htm", name);
        document.body.appendChild(form);
        form.submit();

        document.body.removeChild(form);
        setTimeout(() => {
          Toast.close();

          const Toast2 = Swal.mixin({
            toast: true,
            background: "#ffffff",
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });

          Toast2.fire({
            icon: "success",
            title: "Descarga terminada",
          });
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar el documento");
        }
      });
  };
  const generatePlanAction = () => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#ffffff",
      position: "top-end",
      showConfirmButton: false,
    });

    Toast.fire({
      icon: "info",
      title: "Generando planes de accion...",
    });

    client()
      .get(`evaluation/generateAnalyzes/${audit.audit_id}`)
      .then(({ data }) => {
        Toast.close();
        let result = parseInt(data.after) - parseInt(data.before);
        if (result > 0) {
          alertSuccess(result + " planes de accion generados");
        } else {
          alertWarning("No se encontraron planes para generar");
        }

        setanalyzes({ ...analyzes, status: STATUS_NOT_LOADED });
      })
      .catch((e) => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
          setanalyzes({ ...analyzes, status: STATUS_NOT_LOADED });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al generar los analisis");
        }
      });
  };
  return (
    <AuditPlanFormContext.Provider
      value={[
        {
          dataItem,
          folder,
          auditPdf,
          audit,
          userAuditor,
          participantType,
          itemsActivity,
          template,
          itemsSelectedActivity,
          uploadMasive,
          generateEvaluationsStatus,
          toogleModalProcessReport,
          toogleModalChecklistProcess,
          toogleModalVerificationProcess,
          processReport,
          processChecklist,
          processVerification,
          statusAdvance,
          itemsIntemplate,
          usersSearch,
          analyzes,
          statusauditpdf,
        },
        {
          closeActivities,
          getAudit,
          updateAudit,
          addAuditor,
          updateAuditor,
          deleteParticipantAuditor,
          setUserAuditor,
          addActivity,
          deleteDocument,
          deleteActivity,
          updateActivity,
          filterInit,
          filterEnd,
          addItemsActivity,
          syncItemsSelectedActivity,
          addNote,
          approvedPlan,
          changeTemplateAudit,
          plannedPlan,
          sendEmail,
          inProgressPlan,
          sendEmailApproved,
          sendMessageFinalReport,
          closeApproveddPlan,
          addDocumentAudit,
          addDocumentFinalReport,
          sendNotificationResponsableSolicitudeApprovedPlan,
          getAuditPdf,
          masiveUploadDocument,
          setfolder,
          settoogleModalProcessReport,
          setprocessReport,
          generateReportProcess,
          settoogleModalChecklistProcess,
          settoogleModalVerificationProcess,
          setprocessChecklist,
          setprocessVerification,
          generateChecklistProcess,
          generateVerificationProcess,
          getTotalAdvanceFilter,
          getViewDownload,
          searchUsers,
          setusersSearch,
          generatePlanAction,
          deleteFolder,
          preparePdfAudit,
          setmodulePdf,
        },
      ]}
    >
      {children}
    </AuditPlanFormContext.Provider>
  );
};
