import React from "react";
import "./CreateProgram.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import AuditProgramGeneral from "./general/AuditProgram";
const CreateProgram = () => {
  let history = useHistory();
  return (
    <div className="flex flex-column">
      <div className="flex background-simple header-image-help-audit">
        <div className="flex-1">
          <div
            onClick={() => history.push(`/app/help`)}
            className="icon-help-audit background-simple"
          ></div>
        </div>
        <div className="flex-1 title-help-audit">Auditoría de programa</div>
      </div>
      <div className="flex">
        <div className="container-required-help">
          <div className="conainer-text-require-help">
            <p className="orange tipo-cursiva">Descripción:</p>
            <p className="text-long-required-help tipo-description">
              Gestionada y programada entre la empresa y el auditor líder
              (interno o externo).<br></br> Asigne actividades al equipo auditor
              y a los auditados.<br></br> Lleve toda la trazabilidad de la
              auditoria desde la planeación, aprobación, ejecución y reporte
              final, garantizando la seguridad y confidencialidad de la
              información de la auditoria entre las partes.
            </p>
          </div>
        </div>
      </div>
     
     <AuditProgramGeneral/>
     
   
    </div>
  );
};

export default CreateProgram;
