import React, { useContext } from "react";
import { Field, Form } from "react-final-form";

import "./Contact.css";
import { HelpContext } from "../../../contexts/general/HelpContext";
import { STATUS_LOADED, STATUS_LOADING } from "../../../contexts/security/SecurityContext";
const Contact = () => {
  const [{ contact }, { sendContact }] = useContext(HelpContext);
  const required = (value) => {
    return value ? undefined : "Requerido";
  };
  return (
    <div className="flex h-100 general-container-contact">
      <div className="margin-auto flex flex-column container-contact shadow-tarjet">
        <h3 className="tipo-title txt-center">Contáctenos</h3>
        <Form
          onSubmit={(formObj) => {
            sendContact(formObj);
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <Field name="affair" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="control-label">Asunto</label>
                      <input {...input} type="text" className="form-control" />
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div className="form-group">
                <Field name="body" validate={required}>
                  {({ input, meta }) => (
                    <>
                      <label className="control-label">Mensaje</label>
                      <textarea
                        {...input}
                        className="form-control"
                        cols="30"
                        rows="10"
                      ></textarea>
                      {meta.touched && meta.error && (
                        <span className="tipo-cursiva orange">
                          {meta.error}
                        </span>
                      )}
                    </>
                  )}
                </Field>
              </div>
              {contact.status == STATUS_LOADED && (
                <input
                  className="tipo-title  btn-send-contact white bg-orange"
                  type="submit"
                  value="Enviar"
                />
              )}
               {contact.status == STATUS_LOADING && (
                <label className="txt-center" htmlFor="">Enviando...</label>
              )}
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default Contact;
