import React, { useState, useEffect, useContext } from "react";
import {
  DocumentFormContext,
  STATUS_NOT_LOADED,
} from "../../../../contexts/library/DocumentFormContext";
import "../folder/Show.css";
import imgError from "../../../../img_zeus/default/PROFILE.jpeg";
import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faChevronLeft,
  faSearchPlus,
  faSearchMinus,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { Document, Page, pdfjs } from "react-pdf";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../../contexts/security/SecurityContext";
import { AlertContext } from "../../../../contexts/alerts/AlertContext";
import $ from "jquery";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let initialState = {
  inNote: false,
  stair: 0,
  fixed: false,
  newNote: {
    note: "",
    percentage: "",
  },
  editNote: {
    id: null,
    note: "",
  },
};
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MOV = "mov";
const EXT_DOCX = "docx";
const EXT_XLSX = "xlsx";
const shortText = (description, range) => {
  if (description && description.length > range) {
    return description.slice(-5);
  }
  return description;
};
const Show = ({ computedMatch }) => {
  let { params, url, path } = computedMatch;
  let { document_id } = params;
  let history = useHistory();
  const [
    { breadCrumbDocument, document, notes, noteItem, evaluations },
    {
      changeDocument,
      createNote,
      updateNote,
      deleteNote,
      addScore,
      fixedDocument,
      setnoteItem,
      setevaluations,
      addNoteToItems,
    },
  ] = useContext(DocumentFormContext);
  const [{ user }, { getSrcDocumentWithBearer }] = useContext(SecurityContext);
  const [inNote, setinNote] = useState(initialState.inNote);
  const [stair, setstair] = useState(initialState.stair);
  const [fixed, setfixed] = useState(initialState.fixed);
  const [newNote, setNewNote] = useState(initialState.newNote);
  const [editNote, setEditNote] = useState(initialState.editNote);
  const [dataFile, setdataFile] = useState(initialState);
  const [documentImg, setdocumentImg] = useState(null);
  const [documentVideo, setdocumentVideo] = useState(null);
  const [itemDeploy, setItemDeploy] = useState({});
  const [state, setstate] = useState({
    status: STATUS_NOT_LOADED,
    numPages: null,
    pageNumber: 1,
    scale: 1.5,
  });
  const setDeploy = (item_id, name = "item") => {
    if (itemDeploy[name + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        [name + item_id]: !itemDeploy[name + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, [name + item_id]: true });
    }
  };
  let confirmDeleteNote = (note_id) => {
    deleteNote(note_id);
  };
  let onDocumentLoadSuccess = ({ numPages }) => {
    setstate({ ...state, status: STATUS_LOADED, numPages: numPages });
  };
  useEffect(() => {
    changeDocument(document_id);
  }, [document_id]);
  useEffect(() => {
    setstair(document.score);
    setfixed(document.fixed);
    let newEvaluations = [];
    if (document.status == STATUS_LOADED) {
      if (document.element.audit) {
        if (document.element.audit.evaluations.length > 0) {
          newEvaluations = document.element.audit.evaluations;
        }
      } else if (document.element.audit_folder) {
        if (document.element.audit_folder.audit.evaluations.length > 0) {
          newEvaluations = document.element.audit_folder.audit.evaluations;
        }
      }
      let urlFile =process.env.REACT_APP_API_HOST + "documents/" + document.element.name;
      if(document.element.type==EXT_PDF){
        urlFile=urlFile+"/1";//pagina 1
      }
      setdataFile({
        url: urlFile,
        httpHeaders: { Authorization: localStorage.getItem("token") },
      });
      decisionTypeImage(document.element);
      decisionTypeVideo(document.element);
      setevaluations(newEvaluations.filter((eva) => imEvaluator(eva)));
    }
  }, [document]);
  const imEvaluator = (evaluation) => {
    let isEvaluator = false;
    for (let p = 0; p < evaluation.activity.audit_participants.length; p++) {
      const participant = evaluation.activity.audit_participants[p];

      if (
        participant.pivot.is_evaluator == true &&
        participant.person_id == user.id
      ) {
        isEvaluator = true;
      }
    }

    return isEvaluator;
  };
  let mouseEnterStair = (stair) => {
    setstair(stair);
  };
  let mouseLeaveStair = () => {
    setstair(0);
  };
  let setFieldNote = (field) => (e) => {
    setNewNote({ ...newNote, [field]: e.target.value });
  };
  let setFieldEditNote = (field) => (e) => {
    setEditNote({ ...editNote, [field]: e.target.value });
  };
  const createNoteKey = (e) => {
    if (e.key === "Enter") {
      createNote(newNote, state.pageNumber);
      setNewNote({ ...newNote, note: "" });
    }
  };
  const editNoteKey = (e) => {
    if (e.key === "Enter") {
      updateNote(editNote, state.pageNumber);
      setEditNote({ ...editNote, note: "", id: null });
    }
  };
  const decisionTypeImage = (document) => {
    if (
      document.type === EXT_JPG ||
      document.type === EXT_JPEG ||
      document.type === EXT_PNG
    ) {
      async function fetchData() {
        let src = await getSrcDocumentWithBearer(document);

        let imgHtml = (
          <img
            className="container"
            src={src}
            onError={"Error cargando el documento"}
          />
        );
        setdocumentImg(imgHtml);
      }
      if (document) {
        fetchData();
      }
    }
  };
  const decisionTypeVideo = (document) => {
    if (document.type === EXT_MP4 || document.type === EXT_MOV) {
      async function fetchData() {
        let src = await getSrcDocumentWithBearer(document);
        let videoRead = (
          <ReactPlayer
            width="100%"
            height="80vh"
            url={src}
            controls
            onError={() => {
              alert("No se puede cargar el video");
            }}
          />
        );
        setdocumentVideo(videoRead);
      }
      if (document) {
        fetchData();
      }
    }
  };
  const setNumberPage = (more) => {
    let number = state.pageNumber;
    number = more ? number + 1 : number - 1;

    setstate({ ...state, pageNumber: number });
    let urlFile =process.env.REACT_APP_API_HOST + "documents/" + document.element.name;
    if(document.element.type==EXT_PDF){
      urlFile=urlFile+"/"+number;//pagina 
    }
    setdataFile({
      url: urlFile,
      httpHeaders: { Authorization: localStorage.getItem("token") },
    });
    
  };
  const setScalePage = (more) => {
    let newNumber = state.scale;
    if (more) {
      newNumber = newNumber + 0.3;
    } else {
      newNumber = newNumber - 0.3;
    }
    if (newNumber < 3 && newNumber > 0) {
      setstate({ ...state, scale: newNumber });
    }
  };
  const isLibrary = () => {
    let isLibrary = false;
    if (breadCrumbDocument.length == 2) {
      for (let d = 0; d < breadCrumbDocument.length; d++) {
        const bread = breadCrumbDocument[d];
        if (bread.type == "Topic") {
          isLibrary = true;
        }
      }
    }
    return isLibrary;
  };
  const haveAuditEvaluation = () => {
    let have = false;
    if (document.element.audit) {
      if (document.element.audit.evaluations.length > 0) {
        have = true;
      }
    } else if (document.element.audit_folder) {
      if (document.element.audit_folder.audit) {
        if (document.element.audit_folder.audit.evaluations.length > 0) {
          have = true;
        }
      }
    }
    return have;
  };
  const addNoteItems = (note) => {
    setnoteItem({
      ...noteItem,
      note_id: note.id,
      note: note.note,
    });
  };
  return (
    <>
      {noteItem.note_id && (
        <div className="back-document">
          <div className="container-note-item-document flex-column">
            <div className="flex header-modal-note-item-document bg-gray">
              <div className="flex-1 txt-center">
                <h6 className="white txt-center">
                  SELECCIONE ITEMS PARA ENVIAR LA NOTA
                </h6>
              </div>
              <div
                onClick={() => {
                  setnoteItem({
                    ...noteItem,
                    note_id: null,
                    note: "",
                    items_id: [],
                  });
                }}
                className="cursor-action background-simple btn-close-modal-note-item-document"
              ></div>
            </div>
            <div className="content-modal-item-note-document">
              <div className="container-note-in-document">
                <p className="txt-center">{noteItem.note}</p>
              </div>

              <div className=" flex-column container-items-in-note-over">
                {evaluations.map((eva) => {
                  return (
                    <>
                      <div className="container-parent-note-document   flex flex-column">
                        <div
                          onClick={() => setDeploy(eva.id, "eva")}
                          className="flex container-evaluation-document cursor-action"
                        >
                          <div className="flex flex-1 txt-center ">
                            <p className="tipo-description margin-auto">
                              {eva.title}
                            </p>
                          </div>
                          {eva.items.length > 0 && (
                            <div className="flex">
                              <FontAwesomeIcon
                                className="orange arrow-evaluations-note-document margin-auto"
                                icon={faChevronDown}
                              />
                            </div>
                          )}
                        </div>
                        {itemDeploy["eva" + eva.id] && eva.items.length > 0 && (
                          <Items
                            itemDeploy={itemDeploy}
                            setDeploy={setDeploy}
                            items={eva.items}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div>
                <div className="flex">
                  <span>
                    Items seleccionados: &nbsp;
                    {noteItem.items_id.map((item_select, key) => (
                      <label key={item_select.id}>
                        &nbsp;
                        {item_select.auditable_item &&
                          item_select.auditable_item.number}
                        &nbsp;{noteItem.items_id.length - 1 != key ? "," : ""}
                      </label>
                    ))}
                  </span>
                </div>
                <br />
                <div className="flex">
                  <div
                    onClick={() =>
                      noteItem.items_id.length > 0 ? addNoteToItems() : ""
                    }
                    className={`margin-auto ${
                      noteItem.items_id.length > 0
                        ? "btn-orange-default"
                        : "b-color-black-gray btn-add-note-document-item"
                    }  white cursor-action`}
                  >
                    Ingresar nota a items
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!inNote &&
        document.element.type !== EXT_MP4 &&
        document.element.role === null && (
          <>
            <div
              onClick={() => {
                setinNote(!inNote);
              }}
              className="cursor-action btn-open-note"
            ></div>
          </>
        )}
      {document.element.type === EXT_PDF && (
        <div className="flex fixed-paginate-pdf">
          <div className="container-pagination-pdf">
            <p className="margin-auto">
              {state.pageNumber > 1 && (
                <FontAwesomeIcon
                  onClick={() => {
                    setNumberPage(false);
                  }}
                  className="icon-arrow-zoom white cursor-action"
                  icon={faChevronLeft}
                />
              )}
              &nbsp; &nbsp; Pág {state.pageNumber} / {document.pages}
              &nbsp; &nbsp;
              {state.pageNumber < document.pages && (
                <FontAwesomeIcon
                  onClick={() => {
                    setNumberPage(true);
                  }}
                  className="icon-arrow-zoom white cursor-action"
                  icon={faChevronRight}
                />
              )}
            </p>
            <div className="content-zoom-document">
              <FontAwesomeIcon
                onClick={() => {
                  setScalePage(false);
                }}
                className="icon-zoom-document white cursor-action"
                icon={faSearchMinus}
              />
              &nbsp; &nbsp; &nbsp; &nbsp;
              <FontAwesomeIcon
                onClick={() => {
                  setScalePage(true);
                }}
                className="icon-zoom-document white cursor-action"
                icon={faSearchPlus}
              />
            </div>
          </div>
        </div>
      )}
      <div className="w-100  overflow-document">
        {isLibrary() && (
          <nav aria-label="breadcrumb" className="flex-1 cursor-action">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/app/library/topics" className="tipo-title">
                  Temas
                </Link>
              </li>
              {breadCrumbDocument.map((bread, index) => {
                return (
                  <li
                    key={index}
                    className="breadcrumb-item"
                    aria-current="page"
                  >
                    <Link
                      to={`${
                        bread.type == "Folder"
                          ? `/app/library/topic/${breadCrumbDocument[0].id}/folder/${bread.id}`
                          : bread.type == "Document"
                          ? `/app/library/document/${bread.id}`
                          : `/app/library/topic/${bread.id}`
                      }`}
                      className={`${
                        breadCrumbDocument.length == index + 1 ? "orange" : ""
                      } tipo-description`}
                    >
                      {breadCrumbDocument.length == index + 1
                        ? "..." + shortText(bread.name, 4)
                        : bread.name}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </nav>
        )}
        {!isLibrary() && (
          <span
            onClick={() => history.goBack()}
            className="cursor-action  tipo-description"
          >
            <FontAwesomeIcon
              className="history-left-mobile"
              icon={faChevronLeft}
            />
            Atrás
          </span>
        )}
        <div className="container w-100 flex content-general-document-show">
          {documentVideo && documentVideo}
          {document.element.type === EXT_XLSX && (
            <div className="container ">
              <FileViewer
                fileType={document.element.type}
                filePath={
                  process.env.REACT_APP_API_HOST +
                  "documents/" +
                  document.element.name
                }
                onError={"Error cargando el documento"}
              />
            </div>
          )}
          {document.element.type === EXT_DOCX && (
            <div className="container ">
              <FileViewer
                fileType={document.element.type}
                filePath={
                  process.env.REACT_APP_API_HOST +
                  "documents/" +
                  document.element.name
                }
                onError={"Error cargando el documento"}
              />
            </div>
          )}
          {/*        //////////IMAGENES/////////// */}
          {documentImg && documentImg}
          {document.element.type === EXT_PDF && (
            <>
           
              <Document
                noData="No se ha encontrado un pdf"
                className="pdf-document"
                file={dataFile}
                onLoadSuccess={(e) => onDocumentLoadSuccess(e)}
              >
                <Page
                  noData="No se ha especificado una página"
                  scale={state.scale}
                  onLoadProgress="Cargando página"
                  onLoadSuccess="Página cargada"
                  loading="Cargando Página..."
                  onContextMenu={() => {
                    return false;
                  }}
                  pageNumber={1}
                />
              </Document>
            </>
          )}
          {inNote && (
            <div className="container-note">
              <div className="container-general-note-wraper flex flex-column">
                <div className="container-score-fixed flex">
                  {document.element.audit == null &&
                    document.element.audit_folder == null && (
                      <div className="flex flex-0">
                        <div
                          onClick={() => {
                            fixedDocument();
                          }}
                          className={`btn-fixed-note  cursor-action ${
                            fixed === false ? "gray-scale" : ""
                          }
                  `}
                        ></div>
                      </div>
                    )}
                  {document.element.audit == null &&
                    document.element.audit_folder == null && (
                      <div className="flex-1">
                        <p className="align-center txt-center">¡CALIFÍCA!</p>
                        <div className="container-stairs-note ">
                          <div className="content-stair-note flex">
                            {Array(5)
                              .fill(1)
                              .map((value, key) => {
                                return (
                                  <span
                                    key={key}
                                    onMouseEnter={() => {
                                      document.score === null &&
                                        mouseEnterStair(key + 1);
                                    }}
                                    onMouseLeave={() => {
                                      document.score === null &&
                                        mouseLeaveStair();
                                    }}
                                    onClick={() => addScore(key + 1)}
                                    className={`flex-1 note-stair cursor-action  ${
                                      key + 1 < stair || key + 1 == stair
                                        ? "valorated"
                                        : "no-valorated"
                                    }`}
                                  ></span>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="flex flex-0">
                    <div
                      onClick={() => {
                        setinNote(!inNote);
                      }}
                      className="margin-auto btn-close-note cursor-action "
                    ></div>
                  </div>
                </div>

                <div className="title-notes-document">
                  <h4>Notas</h4>
                  <textarea
                    onChange={setFieldNote("note")}
                    onKeyDown={createNoteKey}
                    value={newNote.note}
                    placeholder="añadir nota en esta visualización"
                    className="note-tarjet-document tipo-description color-black-gray area-document-text-init"
                  ></textarea>
                  {notes.elements &&
                    notes.elements.map((note) => {
                      return (
                        note.percentage === state.pageNumber && (
                          <div key={note.id}>
                            <div className="flex">
                              {editNote.id !== note.id && (
                                <div
                                  onClick={() => {
                                    setEditNote({
                                      ...editNote,
                                      id: note.id,
                                      note: note.note,
                                    });
                                  }}
                                  className="icon-option-note cursor-action icon-edit-note"
                                ></div>
                              )}
                              &nbsp;
                              <div
                                onClick={() => {
                                  confirmDeleteNote(note.id);
                                }}
                                className="icon-option-note cursor-action icon-delete-note"
                              ></div>
                            </div>
                            {editNote.id === note.id && (
                              <textarea
                                onChange={setFieldEditNote("note")}
                                onKeyDown={editNoteKey}
                                value={editNote.note}
                                placeholder="añadir nota en esta visualización"
                                className="note-tarjet-document"
                              ></textarea>
                            )}
                            {editNote.id !== note.id && (
                              <div className="note-tarjet-document">
                                <p className="tipo-description">{note.note}</p>
                                <span className="tipo-cursiva date-note-document">
                                  {note.created_at}
                                  {haveAuditEvaluation() && (
                                    <div
                                      className="flex container-icon-send-note-items cursor-action"
                                      onClick={() => addNoteItems(note)}
                                    >
                                      <label className="cursor-action">
                                        Enviar nota a evaluación
                                      </label>{" "}
                                      &nbsp;
                                      <div className=" background-simple"></div>
                                    </div>
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const Items = ({ items, itemDeploy, setDeploy }) => {
  const [{ noteItem }, { setnoteItem }] = useContext(DocumentFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);

  const addItemNote = (item) => {
    if (!isActive(item)) {
      if (item.auditable_item) {
        if (item.auditable_item.required) {
          setnoteItem({ ...noteItem, items_id: [...noteItem.items_id, item] });
        }
      }
    } else {
      let newItems = noteItem.items_id.filter((i) => i.id != item.id);
      setnoteItem({ ...noteItem, items_id: newItems });
    }
  };

  const isActive = (item) => {
    let findItem = noteItem.items_id.filter((i) => i.id == item.id);
    return findItem.length > 0 ? true : false;
  };

  return (
    <>
      {items.map((item) => {
        return (
          <ol key={item.id}>
            <li
              className={`${itemDeploy["item" + item.id] ? "dotted-li" : ""} `}
            >
              <div
                title={`${
                  isActive(item)
                    ? "Quitar selección de numeral"
                    : "Seleccione numeral"
                }`}
                className={` ${
                  isActive(item) ? "item-select-activity" : ""
                } ol-items-activity  flex content-item-column cursor-action`}
              >
                <div className="container-circle-item flex">
                  {item.items.length > 0 && (
                    <div
                      onClick={() => setDeploy(item.id)}
                      className={`${
                        itemDeploy["item" + item.id]
                          ? "circle-item-down-gray"
                          : "circle-item-up-gray"
                      } circle-number-items`}
                    >
                      {" "}
                      {item.items.length}
                    </div>
                  )}
                </div>

                <div
                  onClick={() => {
                    item.auditable_item && item.auditable_item.required
                      ? addItemNote(item)
                      : alertWarning("Item no evaluable");
                  }}
                  className="flex-1"
                >
                  <div>
                    <p
                      className={`${
                        item.required
                          ? "tipo-description"
                          : "evaluable-text-activity-item"
                      } text-item-activity`}
                    >
                      <strong>
                        {item.auditable_item && item.auditable_item.number}
                      </strong>{" "}
                      {item.title}
                    </p>
                  </div>
                </div>
              </div>
              {itemDeploy["item" + item.id] && item.items.length > 0 && (
                <Items
                  items={item.items}
                  itemDeploy={itemDeploy}
                  setDeploy={setDeploy}
                />
              )}
            </li>
          </ol>
        );
      })}
    </>
  );
};
export default Show;
