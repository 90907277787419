import React, { useState, useEffect, useContext } from "react";
import { AuditPlanFormContext } from "../../../contexts/audit/AuditPlanFormContext";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import Swal from "sweetalert2";
import imgErrorAuditor from "../../../img_zeus/icons_chat_audit/OnErrorAuditor.png";
import imgErrorResponsable from "../../../img_zeus/icons_chat_audit/OnErrorResponsable.png";
import btn_approved from "../../../img_zeus/icons_audit/BOTON-APROBAR-PLAN.png";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import {
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import Select, { components } from "react-select";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import "./AuditApproval.css";
import moment from "moment";
import portada_template from "../../../img_zeus/icons_audit/Portada_Plantilla.png";
import cloneDeep from "lodash/cloneDeep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  toggle_modal: false,
  item: {
    audit_id: null,
    note: "",
  },
  modalTemplate: false,
  notes: [],
  notes_approved: [],
  newResponse: {
    response: {
      audit_id: null,
      option_id: "",
    },
    note: {
      note: "",
      item_id: null,
    },
  },
  click_approval: false,
};

const AuditApproval = ({ status, audit }) => {
  let history = useHistory();
  const [state, setState] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);
  const [noteApproved, setnoteApproved] = useState("");
  const [
    { template },
    {
      addNote,
      approvedPlan,
      plannedPlan,
      sendEmailApproved,
      sendNotificationResponsableSolicitudeApprovedPlan,
      sendEmail,
    },
  ] = useContext(AuditPlanFormContext);

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  let functionSubmit = () => {
    if (state.click_approval == true) {
      checkApproved(STATUS_APPROVED, cloneDeep(noteApproved));
      setState({ ...state, notes_approved: cloneDeep(noteApproved) });
    } else {
      addNote(cloneDeep(noteApproved));
    }
    setnoteApproved("");
  };

  const validateField = (field) => {
    return audit[field] == "" || audit[field] == null ? false : true;
  };

  let requestApproval = () => {
    let arrayField = Object.keys(audit);
    let fieldsEmpty = [];
    let Approval = true;
    let fieldsValidate = [
      {
        scope: "Alcance",
        criterial: "Criterios",
        methodology: "Metodologia",
        objetives: "Objetivos",
        exclusions: "Exclusiones",
        responsability_leader: "Responsabilidades del auditor lider",
        responsability_team: "Responsabilidades del grupo auditor",
        resources: "Recursos",
        requirements: "Requisitos",
        activities: "Actividades",
      },
    ];
    let fieldsValidateArray = Object.keys(fieldsValidate[0]);

    for (let i = 0; i < arrayField.length; i++) {
      let field = arrayField[i];
      if (!validateField(field)) {
        fieldsEmpty.push(field);
      }
    }
    let li = "";
    for (let i = 0; i < fieldsValidateArray.length; i++) {
      if (fieldsEmpty.includes(fieldsValidateArray[i])) {
        Approval = false;
        if (Approval === false) {
          li =
            li + "<li>" + fieldsValidate[0][fieldsValidateArray[i]] + "</li>";
        }
      }
    }
    if (Approval === false) {
      Swal.fire({
        title: "Falta completar los siguientes campos",
        html: li,
        icon: "warning",
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        confirmButtonText: "Entendido",
      }).then((result) => {});
    }

    if (Approval === true) {
      setState({
        ...state,
        notes: cloneDeep(noteApproved),
      });

      plannedPlan(STATUS_PLANNED);
      addNote(cloneDeep(noteApproved));
      sendEmail(audit.responsable_person.email, cloneDeep(noteApproved));
      sendNotificationResponsableSolicitudeApprovedPlan();
      setnoteApproved("");
    }
  };

  const checkApproved = (status_id, formObj) => {
    let count = 0;
    for (let a = 0; a < audit.activities.length; a++) {
      const activity = audit.activities[a];

      if (activity.is_evaluable) {
        let haveParticipantNotAssigned = true;
        for (let p = 0; p < activity.audit_participants.length; p++) {
          const participant = activity.audit_participants[p];
          if (!participant.participant_type.is_audit) {
            haveParticipantNotAssigned = false;
          }
        }
        if (haveParticipantNotAssigned) {
          count = count + 1;
        }
      }
    }
    let title = "¿Confirmas que deseas aprobar este plan?";
    let button = "Si, aceptar!";
    let text = "¡No podrás revertir esta acción!";
    if (count > 0) {
      title = `Falta asignar participantes a ${count} actividades`;
      button = "Aprobar";
      text = "Esta seguro que desea aprobar el plan?";
    }
    Swal.fire({
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: button,
      title: title,
    }).then((result) => {
      if (result.value) {
        approvedPlan(status_id);
        addNote(formObj);
        sendEmailApproved(audit.lider_person.email, formObj);
      }
    });
  };

  return (
    <div className="tipo-description w-100 content-main-approved-plan">
      {state.modalTemplate && (
        <ModalSelectTemplate
          setnoteApproved={setnoteApproved}
          setState={setState}
          state={state}
          audit={audit}
        />
      )}
      <div className="flex">
        <div
          onClick={() => history.push(`../audit/${audit.id}/pdf/showAudit`)}
          className="btn-orange-default pdf-plan"
        >
           <FontAwesomeIcon icon={faFilePdf}/>Ver plan
        </div>
      </div>
      <div className="flex flex-column main-chat">
        {audit.notes &&
          audit.notes.map((note, key) => (
            <div
              className={`${
                note.person_id ==user.id
                  ? "chat-globo"
                  : "chat-globo2"
              }`}
              key={note.id}
            >
              <div
                className={`${
                  note.person_id ==user.id
                    ? "chat-responsable "
                    : "chat-auditor"
                }`}
              >
                <div>
                  <p
                    className={`${
                      note.person_id == audit.responsable_person_id
                        ? "chat-responsable-margin"
                        : "chat-auditor-margin"
                    }`}
                  >
                    {note.note}
                  </p>
                </div>
                <div
                  className={`${
                    note.person_id == audit.lider_person_id
                      ? "flex justify-end div-content-created-chat"
                      : "flex div-content-created-chat"
                  }`}
                >
                  <small
                    className={`${
                      note.person_id == audit.responsable_person_id
                        ? "chat-responsable-margin"
                        : "chat-auditor-margin"
                    }`}
                  >
                    {moment(note.created_at).format("YYYY-MM-DD HH:mm")}
                  </small>
                </div>
              </div>
              {key == 0 && (
                <img
                  className="img-profile-approved"
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/documents/" +
                    (note.my_note.image && note.my_note.image.name)
                  }
                  onError={(e) => {
                    e.target.src = imgErrorAuditor;
                  }}
                />
              )}
              {key > 0 && audit.notes[key - 1].person_id != note.person_id && (
                <img
                  className="img-profile-approved"
                  src={
                    process.env.REACT_APP_API_HOST +
                    "/documents/" +
                    (note.my_note.image && note.my_note.image.name)
                  }
                  onError={(e) => {
                    e.target.src =
                      note.person_id == audit.responsable_person_id
                        ? imgErrorResponsable
                        : imgErrorAuditor;
                  }}
                />
              )}
            </div>
          ))}
      </div>
      <div
        title={`${
          audit.status_id == STATUS_APPROVED ||
          audit.status_id == STATUS_INPROGRESS ||
          audit.status_id == STATUS_EXECUTED
            ? "Conversación finalizada, plan aprobado"
            : ""
        }`}
        className={`${
          audit.status_id == STATUS_APPROVED ||
          audit.status_id == STATUS_INPROGRESS ||
          audit.status_id == STATUS_EXECUTED
            ? "content-form-approved-chat-disable"
            : "content-form-approved-chat"
        }`}
      >
        <Form
          onSubmit={(formObj) => {
            if (
              audit.status_id == STATUS_CREATED &&
              audit.lider_person_id == user.id
            ) {
              requestApproval();
            } else {
              functionSubmit();
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form-approved-chat">
              <div className="form-group div-form-approved-plan">
                <textarea
                  value={noteApproved}
                  onChange={(e) => setnoteApproved(e.target.value)}
                  className="form-control tipo-description txt-send-message"
                  placeholder="Escriba comentario"
                  rows="1"
                  disabled={
                    audit.status_id == STATUS_APPROVED ||
                    audit.status_id == STATUS_INPROGRESS ||
                    audit.status_id == STATUS_EXECUTED
                      ? true
                      : false
                  }
                ></textarea>
              </div>
              <div className="form-group flex align-items-center">
                <input
                  type="submit"
                  className="btn-orange-default-short"
                  value={
                    audit.status_id == STATUS_CREATED &&
                    audit.lider_person_id == user.id
                      ? "Solicitar aprobación"
                      : "Enviar comentario"
                  }
                  disabled={
                    audit.status_id == STATUS_APPROVED ||
                    audit.status_id == STATUS_INPROGRESS ||
                    audit.status_id == STATUS_EXECUTED
                      ? true
                      : false
                  }
                />
                {audit.status_id == STATUS_PLANNED &&
                  user.id == audit.company.contact.id &&
                  status == STATUS_LOADED && (
                    <input
                      onClick={() => {
                        setState({ ...state, click_approval: true });
                      }}
                      type="submit"
                      className="cursor-action img-approved"
                      value=""
                    />
                  )}
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
const ModalSelectTemplate = ({ setnoteApproved, state, setState, audit }) => {
  const [
    { template },
    {
      changeTemplateAudit,
      plannedPlan,
      addNote,
      sendEmail,
      sendNotificationResponsableSolicitudeApprovedPlan,
    },
  ] = useContext(AuditPlanFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);

  const [selectTemplate, setselectTemplate] = useState();
  const setTemplate = (value) => {
    setselectTemplate(value);
  };
  const checkSelectTemplate = () => {
    if (selectTemplate) {
      changeTemplateAudit(selectTemplate.id);
    }
    plannedPlan(STATUS_PLANNED);
    addNote(state.notes);
    setState({ ...state, modalTemplate: !state.modalTemplate });
    sendEmail(audit.responsable_person.email, state.notes);
    sendNotificationResponsableSolicitudeApprovedPlan();
    setnoteApproved("");
  };
  return (
    <div className="back-document">
      <div className="content-modal-select-template">
        <div className="header-request-approval">
          <h3 className="title-modal-template">
            Solicitar aprobación del plan
          </h3>
        </div>
        <div className="padding-control flex flex-column align-items-center">
          <p>1. Se creara la lista de chequeo según la norma</p>
          <p>
            2 . Para crear lista de chequeo con plantilla personalizada,
            seleccione aquí
          </p>
          <Select
            className="tipo-description w-100 txt-center"
            placeholder={"Seleccione una opción"}
            getOptionLabel={(option) => `${option.title}`}
            getOptionValue={(option) => option.id}
            options={template.elements.filter(
              (t) => t.standard_id == audit.standard_id
            )}
            noOptionsMessage={() => "No hay plantillas creadas"}
            isMulti={false}
            isClearable={true}
            onChange={setTemplate}
          />
          <br />
          <div
            className="img-template-modal txt-center flex justify-center"
            title="Plantilla a utilizar"
          >
            <p className="standard-title-template-modal">
              <strong>
                {selectTemplate
                  ? selectTemplate.title
                  : audit.standard.short_name}
              </strong>
            </p>
          </div>
          <div className="btn-template">
            <div
              title="Solicitar aprobación y crear lista de chequeo"
              className="cursor-action cancel-modal-template tipo-boton"
              onClick={checkSelectTemplate}
            >
              Solicitar aprobación
            </div>
            <div
              title="Cancelar"
              className="cursor-action cancel-modal-template tipo-boton"
              onClick={() =>
                setState({ ...state, modalTemplate: !state.modalTemplate })
              }
            >
              Cancelar
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditApproval;
