import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  STATUS_ERASER,
  STATUS_APPROVED,
  InspectionFormContext,
} from "../../../contexts/evaluation/InspectionFormContext";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconShow from "../../../../src/img_zeus/icons_audit/ICONO-VER.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import iconExecuted from "../../../img_zeus/icons_audit/ingresar-02.png";
import CreateInspection from "../../audit/modals/CreateInspection";
import ShareInspection from "../../audit/modals/ShareInspection";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import Swal from "sweetalert2";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "react-js-pagination";
import Select, { components } from "react-select";
import {
  faPlusCircle,
  faAddressBook,
  faUser,
  faBuilding,
  faShareAltSquare,
  faShareSquare,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { STATUS_LOADED } from "../../../contexts/audit/TemplateContext/TemplateOnSaleIndex";
const FormChilds = ({ computedMatch: { params, url } }) => {
  let { evaluation_id } = params;
  let history = useHistory();
  const [
    { childsEvaluation, metaRowChilds, rowsChilds, statusInspection },
    { setchildsEvaluation, deleteInspection, setmetaRowChilds },
  ] = useContext(InspectionFormContext);
  const [{ user }, {}] = useContext(SecurityContext);
  useEffect(() => {
    if (childsEvaluation.evaluation_id != evaluation_id) {
      setchildsEvaluation({
        ...childsEvaluation,
        evaluation_id: evaluation_id,
      });
      setmetaRowChilds({
        ...metaRowChilds,
        filters: {
          ...metaRowChilds.filters,
          parent_evaluation_id: evaluation_id,
        },
      });
    }
  }, [evaluation_id]);
  const checkDelete = (evaluation_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta lista?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteInspection(evaluation_id);
      } else {
      }
    });
  };
  const setFieldFilter = (field, e, withTarget = true) => {
    let meta = metaRowChilds.filtersLike;
    if (withTarget) {
      meta[field] = e.target.value;
    } else {
      meta[field] = e;
    }

    setmetaRowChilds({ ...metaRowChilds, filtersLike: meta });
  };
  const setFieldOrder = (value) => {
    setmetaRowChilds({ ...metaRowChilds, orderBy: [value] });
  };
  const setFieldMeta = (value, field) => {
    setmetaRowChilds({ ...metaRowChilds, [field]: value });
  };
  const getstatus = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <img
              src={btn_created}
              title="Creada"
              className="img-status-audit"
            />{" "}
            Creada
          </>
        );
        break;
      case 2:
        return (
          <>
            <img
              src={btn_planned}
              title="Planeado"
              className="img-status-audit"
            />{" "}
            Planeada
          </>
        );
        break;
      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-audit"
            />{" "}
            En progreso
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizada"
              className="img-status-audit"
            />{" "}
            Finalizada
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-audit"
            />{" "}
            Pendiente
          </>
        );
        break;
      case 7:
        return (
          <>
            <img
              src={btn_approved}
              title="Aprobada"
              className="img-status-audit"
            />{" "}
            Aprobada
          </>
        );
        break;
      default:
        return (
          <img src={btn_created} title="Creada" className="img-status-audit" />
        );
    }
  };
  const customStyles = {
    control: (_, { selectProps: { width, height } }) => ({
      width: width,
      display: "flex",
      height: height,
      border: "1px solid #aaa",
      borderRadius: "3px",
    }),
  };
  return (
    <div>
      <br />
      <nav aria-label="breadcrumb" className="flex-1 cursor-action">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/app/inspection/evaluations">Inspecciones</Link>
          </li>

          <li className="breadcrumb-item ">
            <Link
              className="orange"
              to={`/app/inspection/evaluation/${childsEvaluation.evaluation_id}`}
            >
              Evaluacion
            </Link>
          </li>
        </ol>
      </nav>
      <div className="general-content-index-evaluation back-gray-content">
        <table className="table table-hover">
          <thead className="header-table-index-evaluation">
            <tr className="txt-center">
              <th style={{ minWidth: "300px" }} scope="col">
                EVALUADOR
              </th>
              <th style={{ minWidth: "300px" }} scope="col">
                NOMBRE
              </th>
              <th scope="col">DESCRIPCIÓN</th>
              <th style={{ minWidth: "87px" }} scope="col">
                CREACIÓN{" "}
                <FontAwesomeIcon
                  onClick={() =>
                    setFieldOrder(
                      metaRowChilds.orderBy.includes("created_at:asc")
                        ? "created_at:desc"
                        : "created_at:asc"
                    )
                  }
                  icon={metaRowChilds.orderBy.includes("created_at:asc")?faChevronUp:faChevronDown}
                />
              </th>
              <th style={{ minWidth: "87px" }} scope="col">
                ÚLTIMA EDICIÓN
              </th>
              <th scope="col">ESTADO {" "}
                <FontAwesomeIcon
                  onClick={() =>
                    setFieldOrder(
                      metaRowChilds.orderBy.includes("status_id:asc")
                        ? "status_id:desc"
                        : "status_id:asc"
                    )
                  }
                  icon={metaRowChilds.orderBy.includes("created_at:asc")?faChevronUp:faChevronDown}
                /></th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr>
              <th>
                {" "}
                <div>
                  <input
                    className="input-filter-table filter-table-inspectionChilds"
                    placeholder="filtrar "
                    onChange={(e) =>
                      setFieldFilter("owners.ownerPerson:first_name", e)
                    }
                    value={
                      metaRowChilds.filtersLike["owners.ownerPerson:first_name"]
                    }
                    type="text"
                  />
                </div>
              </th>
              <th>
                <div>
                  <input
                    className="input-filter-table filter-table-inspectionChilds"
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("title", e)}
                    value={metaRowChilds.filtersLike["title"]}
                    type="text"
                  />
                </div>
              </th>
              <th>
                {" "}
                <div>
                  <input
                    className="input-filter-table filter-table-inspectionChilds "
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("description", e)}
                    value={metaRowChilds.filtersLike["description"]}
                    type="text"
                  />
                </div>
              </th>
              <th>
                {" "}
                <div>
                  <input
                    className="input-filter-table filter-table-inspectionChilds"
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("created_at", e)}
                    value={metaRowChilds.filtersLike["created_at"]}
                    type="text"
                  />
                </div>
              </th>
              <th>
                {" "}
                <div>
                  <input
                    className="input-filter-table filter-table-inspectionChilds"
                    placeholder="filtrar"
                    onChange={(e) => setFieldFilter("updated_at", e)}
                    value={metaRowChilds.filtersLike["updated_at"]}
                    type="text"
                  />
                </div>
              </th>
              <th>
                <Select
                  value={statusInspection.elements.filter(
                    (s) => s.id === metaRowChilds.filtersLike["status_id"]
                  )}
                  onChange={(e) =>
                    setFieldFilter("status_id", e ? e.id : null, false)
                  }
                  styles={customStyles}
                  className="tipo-description"
                  placeholder={"Estado"}
                  width="126px"
                  height="26px"
                  display="flex"
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={statusInspection.elements}
                  isClearable={true}
                />
              </th>

              <th></th>
            </tr>

            {childsEvaluation.status === STATUS_LOADED &&
              childsEvaluation.elements.map((eva, index) => (
                <tr className="tr-table-standars txt-center" key={eva.id}>
                  <td>
                    <div className="flex-1 flex margin-auto">
                      {eva.owners.map((p) => {
                        return (
                          <div key={p.id} className="flex">
                            <img
                              className="img-chils-index-inspection"
                              src={
                                process.env.REACT_APP_API_HOST +
                                "/documents/" +
                                (p.owner.image && p.owner.image.name)
                              }
                              onError={(e) => {
                                e.target.src = imgError;
                              }}
                            ></img>
                            <span>
                              {p.owner.first_name} {p.owner.last_name}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </td>

                  <td>
                    <div className="flex">
                      {eva.consumer && (
                        <div className="flex margin-auto flex-0">
                          <FontAwesomeIcon
                            className="icon-personal-company-cart "
                            icon={
                              eva.consumer.effective.is_personal
                                ? faUser
                                : faBuilding
                            }
                          />
                          &nbsp;
                        </div>
                      )}

                      <div className="flex-1 flex margin-auto">
                        <span>{eva.title}</span>
                      </div>
                    </div>
                  </td>
                  <td>{eva.description}</td>

                  <td title={eva.created_at}>
                    {" "}
                    {moment(eva.created_at).format("YYYY-MM-DD")}
                  </td>

                  <td title={eva.updated_at}>
                    {moment(eva.updated_at).format("YYYY-MM-DD")}
                  </td>

                  <td title={`${eva.status.name}`}>
                    {getstatus(eva.status_id)}
                  </td>

                  <td className="icons-standard-action">
                    <img
                      onClick={() =>
                        history.push(`/app/inspection/evaluation/${eva.id}`)
                      }
                      title="ver lista"
                      className="icon-actions-norma cursor-action"
                      src={iconShow}
                    />

                    {user.is_admin && (
                      <img
                        onClick={() => checkDelete(eva.id)}
                        title="eliminar lista"
                        className="icon-actions-norma cursor-action"
                        src={iconDelete}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div />
        <div style={{ margin: "10PX" }} className="justify-end flex">
          <Pagination
            activePage={metaRowChilds.page}
            itemsCountPerPage={metaRowChilds.limit}
            totalItemsCount={rowsChilds}
            pageRangeDisplayed={5}
            itemClass="page-item item-page-zeus"
            linkClass="page-link page-link-zeus"
            onChange={(e) => setFieldMeta(e, "page")}
          />
        </div>
      </div>
    </div>
  );
};

export default FormChilds;
