import React, { useContext, useState, useEffect } from "react";
import { Form, Field } from "react-final-form";

import {
  STATUS_ERASER,
  InspectionIndexContext,
} from "../../../contexts/evaluation/InspectionIndexContext";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
const STATUS_LOADING = "LOADING";
const STATUS_NOT_LOADED = "NOT_LOADED";
const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
const STATUS_LOGGED_IN = "LOGGED_IN";
const STATUS_LOADED = "STATUS_LOADED";
const initialState = {
  newInspection: {
    is_public: false,
    is_template: false,
    is_self_audit: true,
    on_sale: false,
    title: "",
    description: "",
    status_id: STATUS_ERASER,
  },
};

const CreateInspection = () => {
  const [newInspection, setNewInspection] = useState(
    initialState.newInspection
  );
  const [haveSuscriptions, sethaveSuscriptions] = useState(false);
  const [
    { modalInspection, inspection, suscriptionsInspection, effectiveSelected },
    {
      setmodalInspection,
      createInspection,
      updateInspection,
      seteffectiveSelected,
    },
  ] = useContext(InspectionIndexContext);

  useEffect(() => {
    if (
      inspection.status === STATUS_LOADED &&
      modalInspection.evaluation_id !== null
    ) {
      //cuando se va editar una lista
      let filter = inspection.elements.filter(
        (e) => e.id === modalInspection.evaluation_id
      )[0];

      setNewInspection({
        ...newInspection,
        title: filter.title,
        description: filter.description,
        status_id: filter.status_id,
        response_for_user: filter.response_for_user,
        max_use: filter.max_use,
        

      });
    }
  }, [inspection, modalInspection]);
  useEffect(() => {
    if (suscriptionsInspection.status === STATUS_LOADED) {
      if (
        suscriptionsInspection.elements.length > 0
      ) {
        sethaveSuscriptions(true);
      }
    }
  }, [suscriptionsInspection]);
  const required = (value) => (value ? undefined : "Requerido");

  return (
    <Form
      onSubmit={(formObject) => {
        if (modalInspection.evaluation_id !== null) {
          updateInspection(modalInspection.evaluation_id, formObject);
        } else {
          createInspection(formObject);
        }
      }}
      initialValues={newInspection}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{ marginTop: "80px" }}
            className="modal-create-standard shadow-tarjet"
          >
            <div className="w-100 header-modal-create-evaluation">
              <div className="w-100 flex">
                <div
                  onClick={() => {
                    setmodalInspection({
                      ...modalInspection,
                      evaluation_id: null,
                      open: false,
                    });
                  }}
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="txt-center txt-title-new-folder tipo-description">
                  {modalInspection.evaluation_id !== null
                    ? "Editar lista"
                    : "Nueva lista"}
                </p>
              </div>
            </div>
            <div className="bg-white content-modal-create-standard">
              <div className="">
                <div className="flex flex-column">
                  {modalInspection.evaluation_id == null && !haveSuscriptions && (
                    <div
                    style={{margin:"10px"}}
                      class="alert alert-warning form-group "
                      role="alert"
                    >
                      Sin suscripciones activas
                      <br />
                      <Link
                        className="link-default"
                        title="Comprar suscripción personal para auditorías"
                        to={`/app/library/topic/billing/suscription/inspection`}
                      >
                        Adquirir suscripción
                      </Link>
                    </div>
                  )}
                  <div className="flex">
                    <div className="flex flex-column flex-1">
                      <div className="flex flex-column margin-control">
                        <Field name="title" validate={required}>
                          {({ input, meta }) => (
                            <>
                              <label className="control-label">
                                Título corto:
                              </label>

                              <input
                                placeholder="Título de la lista"
                                className="form-control tipo-description"
                                type="text"
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span className="tipo-cursiva orange">
                                  {meta.error}
                                </span>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="flex flex-column margin-control">
                        <Field name="description" validate={required}>
                          {({ input, meta }) => (
                            <>
                              <label className="control-label">
                                Descripción:
                              </label>
                              <textarea
                                placeholder="Descripción de la lista"
                                className="form-control tipo-description"
                                rows="1"
                                style={{ minHeight: "90px" }}
                                {...input}
                                type="text"
                              />
                              {meta.touched && meta.error && (
                                <span className="orange tipo-cursiva">
                                  {meta.error}
                                </span>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="flex">
                      <div className="flex flex-column margin-control">
                        <Field name="response_for_user">
                          {({ input, meta }) => (
                            <>
                              <label className="control-label">
                                Respuestas por usuario:
                              </label>
                              <input
                                placeholder="Respuestas por usuario"
                                className="form-control tipo-description"
                                type="number"
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span className="orange tipo-cursiva">
                                  {meta.error}
                                </span>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                      <div className="flex flex-column margin-control">
                        <Field name="max_use" >
                          {({ input, meta }) => (
                            <>
                              <label className="control-label">
                                Límite de uso:
                              </label>
                              <input
                                placeholder="Límite de uso"
                                className="form-control tipo-description"
                                type="number"
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span className="orange tipo-cursiva">
                                  {meta.error}
                                </span>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                      </div>
                      
                    </div>
                    {modalInspection.evaluation_id === null &&
                      suscriptionsInspection.elements.length > 0 && (
                        <div className="flex flex-1">
                          <div className="flex-1">
                            <label className="tipo-title">
                              Selecccione un paquete
                            </label>
                            {suscriptionsInspection.elements.map(
                              (effective) => {
                                return (
                                  <div
                                    className={`flex cursor-action g-module-audit form-group txtarea-form-audit effective-modal-audit ${
                                      effective.id == effectiveSelected
                                        ? "effective-active"
                                        : ""
                                    }`}
                                    key={effective.id}
                                    onClick={() =>
                                      seteffectiveSelected(effective.id)
                                    }
                                  >
                                    <div className="flex flex-column w-100">
                                      <div className="flex justify-betwen">
                                        {" "}
                                        <div className="flex-1">
                                          {" "}
                                          <div className="flex">
                                            <label className="tipo-title txt-capitalize">
                                              {effective.service.name}
                                            </label>
                                            &nbsp;
                                            <label htmlFor="">
                                              {effective.quantity}/
                                              {effective.consumers.length}
                                            </label>
                                          </div>
                                          <div className="flex">
                                            {effective.childs_effectives.map(
                                              (child) => {
                                                return (
                                                  <>
                                                    <label className="tipo-title txt-capitalize">
                                                      {child.service.name}
                                                    </label>
                                                    &nbsp;
                                                    <label htmlFor="">
                                                      {child.quantity}
                                                    </label>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="flex-1">
                                          <label>Fch. Venc.</label>
                                          &nbsp;
                                          <label className="tipo-cursiva">
                                            {moment(effective.end_date).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </label>{" "}
                                        </div>
                                      </div>
                                      <div className="flex">
                                        <div>
                                          <FontAwesomeIcon
                                            icon={
                                              effective.is_personal
                                                ? faUser
                                                : faBuilding
                                            }
                                          />
                                          &nbsp;
                                          <label className="tipo-cursiva">
                                            {effective.assignable.first_name}
                                            &nbsp;
                                            {effective.assignable.last_name}
                                            {effective.assignable.name}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end w-100">
              <button className="btn-orange-default" type="submit">
                Guardar
              </button>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateInspection;
