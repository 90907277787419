import React, { useState, useEffect, useContext } from "react";
import "../audit/form.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Field, Form } from "react-final-form";
import {
  SecurityContext,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import RichTextEditor from "react-rte";
import ReactSpeedometer from "react-d3-speedometer";
import "react-tabs/style/react-tabs.css";
import Activities from "./Activities";
import Participants from "./Participants";
import Select, { components } from "react-select";

import Documents from "./Documents";
import PlanActions from "./PlanActions";

import Swal from "sweetalert2";
import AuditApproval from "./AuditApproval";
import Report from "./Report";
import FinalReport from "./FinalReport";
import imgLoading from "../../../img_zeus/loading.gif";
import Loading from "./../../app/Loading";
import {
  AuditPlanFormContext,
  STATUS_LOADED,
} from "../../../contexts/audit/AuditPlanFormContext";
import moment from "moment";
import "moment/locale/es";
import { useHistory } from "react-router-dom";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import btn_ejecutar from "../../../img_zeus/icons_audit/BOTON-EJECUTAR.png";
import btn_planned from "../../../img_zeus/icons_audit/ICONO_PLANEADA.png";
import btn_approved from "../../../img_zeus/icons_audit/ICONO_Aprobado.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import WebRTCClient from "iotcomms-react-webrtc";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faFilePdf, faUser } from "@fortawesome/free-solid-svg-icons";
export const STATUS_CREATED = 1;
export const STATUS_PLANNED = 2;
export const STATUS_APPROVED = 7;
export const STATUS_INPROGRESS = 3;
export const STATUS_EXECUTED = 4;
export const STATUS_PENDING = 6;
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";
const EXT_DOCX = "docx";
const EXT_DOC = "doc";
const STATUS_NEW = "NEW";
const STATUS_SHOW = "SHOW";
const STATUS_EDIT = "EDIT";
const STATUS_NOT_LOADED = "NOT_LOADED";

const initialState = {
  item: {
    scope: "",
    objetives: "",
    criterial: "",
    methodology: "",
    exclusions: "",
    resources: "",
    responsability_leader: "",
    responsability_team: "",
    conclusion: "",
    risks: "",
    requirements: "",
  },
  richText: {
    scope: RichTextEditor.createEmptyValue(),
    objetives: RichTextEditor.createEmptyValue(),
    criterial: RichTextEditor.createEmptyValue(),
    methodology: RichTextEditor.createEmptyValue(),
    exclusions: RichTextEditor.createEmptyValue(),
    resources: RichTextEditor.createEmptyValue(),
    responsability_leader: RichTextEditor.createEmptyValue(),
    responsability_team: RichTextEditor.createEmptyValue(),
    conclusion: RichTextEditor.createEmptyValue(),
    risks: RichTextEditor.createEmptyValue(),
    requirements: RichTextEditor.createEmptyValue(),
  },
  result: {
    numberCalificables: 0,
    numberResult: 0,
    percentage: 0,
    fulfill: 0,
    fails: 0,
    notApply: 0,
    documents: 0,
    notes: 0,
  },
};

const Audit = ({ computedMatch: { params, url } }) => {
  let { audit_id } = params;
  let [
    {
      audit,
      generateEvaluationsStatus,
      dataItem,
      statusAdvance,
      toogleModalChecklistProcess,
      processChecklist,
    },
    {
      getAudit,
      inProgressPlan,
      approvedPlan,
      getTotalAdvanceFilter,
      settoogleModalChecklistProcess,
      setprocessChecklist,
      generateChecklistProcess,
    },
  ] = useContext(AuditPlanFormContext);
  const [result, setresult] = useState(initialState.result);
  const [toogleObservations, settoogleObservations] = useState(false);
  const [observationsMobile, setobservationsMobile] = useState([]);
  const [canViewResult, setcanViewResult] = useState(true);
  let [state, setState] = useState(audit);
  let [{ user }, {}] = useContext(SecurityContext);
  let history = useHistory();
  const [{ tabAudit }, { settabAudit, alertWarning }] =
    useContext(AlertContext);
  useEffect(() => {
    getAudit(audit_id);
    setState(audit);
    if (audit.status == STATUS_LOADED) {
      if (
        (audit.originalItem.status_id != STATUS_EXECUTED &&
          audit.originalItem.company.contact.id == user.id &&
          audit.originalItem.lider_person.id != user.id) ||
        isAudited(audit.originalItem)
      ) {
        setcanViewResult(false);
      }
    }
  }, [audit_id, audit.originalItem]);
  const isAudited = (audit) => {
    let imAudited = false;
    for (let p = 0; p < audit.participants.length; p++) {
      const participant = audit.participants[p];
      if (
        participant.person_id == user.id &&
        participant.participant_type.is_audit == false
      ) {
        imAudited = true;
      }
    }
    return imAudited;
  };
  const imEvaluator = () => {
    if (state.status === STATUS_LOADED) {
      let isEvaluator = false;

      for (let p = 0; p < state.originalItem.participants.length; p++) {
        const participant = state.originalItem.participants[p];
        if (
          participant.person_id == user.id &&
          participant.participant_type.is_audit
        ) {
          isEvaluator = true;
        }
      }
      return isEvaluator;
    }
  };
  let setField = (field) => (e) => {
    setState({
      ...state,
      item: { ...state.originalItem, [field]: e.target.value },
    });
  };

  const statusAudit = (auditStatus) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <img
              src={btn_created}
              title="Creada"
              className="img-status-audit"
            />{" "}
            Creada
          </>
        );
        break;
      case 2:
        return (
          <>
            <img
              src={btn_planned}
              title="Planeado"
              className="img-status-audit"
            />{" "}
            Planeada
          </>
        );
        break;
      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-audit"
            />{" "}
            En progreso
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizada"
              className="img-status-audit"
            />{" "}
            Finalizada
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-audit"
            />{" "}
            Pendiente
          </>
        );
        break;
      case 7:
        return (
          <>
            <img
              src={btn_approved}
              title="Aprobada"
              className="img-status-audit"
            />{" "}
            Aprobada
          </>
        );
        break;
      default:
        return (
          <img src={btn_created} title="Creada" className="img-status-audit" />
        );
    }
  };

  const validateField = (field) => {
    return audit[field] == "" || audit[field] == null ? false : true;
  };
  const isLiderOrResponsable = () => {
    return user.is_admin ||
      audit.originalItem.responsable_person_id == user.id ||
      audit.originalItem.lider_person_id == user.id
      ? true
      : false;
  };

  const isLiderOrResponsableOrAudit = () => {
    let isAudit = false;
    if (audit.status == STATUS_LOADED) {
      for (let p = 0; p < audit.originalItem.participants.length; p++) {
        const participant = audit.originalItem.participants[p];
        if (
          participant.participant_type.is_audit &&
          user.id == participant.person_id
        ) {
          isAudit = true;
        }
      }
      if (isAudit || isLiderOrResponsable()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

    /*  if(isLiderOrResponsable){

    } */
  };

  const checkApproved = () => {
    let auditstate = audit.originalItem;
    let countActivitiesEvaluable = auditstate.activities.filter(
      (a) => a.is_evaluable === true
    ).length;

    if (countActivitiesEvaluable === 0) {
      alertWarning("La auditoría no tiene actividades evaluables");
      return false;
    }
    if (auditstate.standard.item_types.length == 0) {
      let activities = auditstate.activities;
      let fieldsEmpty = [];

      for (let i = 0; i < activities.length; i++) {
        let activity = activities[i];

        if (activity.is_evaluable == true) {
          let haveParticipants = false;

          for (let i = 0; i < activity.audit_participants.length; i++) {
            let element = activity.audit_participants[i];

            if (element.participant_type.is_audit == false) {
              haveParticipants = true;
            }
          }
          if (!haveParticipants) {
            fieldsEmpty.push(activity);
          }
        }
      }
      if (fieldsEmpty.length > 0) {
        let li = "";
        for (let i = 0; i < fieldsEmpty.length; i++) {
          const element = fieldsEmpty[i];

          li = li + "<li>" + element.name + "</li>";
        }
        Swal.fire({
          title: "Falta asignar participantes a las siguientes actividades:",
          html: li,
          icon: "warning",

          confirmButtonColor: "#df8c37",
          cancelButtonColor: "#171e27",
          confirmButtonText: "Entendido",
        }).then((result) => {});
      } else {
        okApprovedPlan();
      }
    } else {
      okApprovedPlan();
    }
  };

  const okApprovedPlan = () => {
    Swal.fire({
      text: "¡No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, aceptar!",
      title: "¿Confirmas que deseas aprobar este plan?",
    }).then((result) => {
      if (result.value) {
        approvedPlan(STATUS_APPROVED);
      }
    });
  };
  let requestInProgress = () => {
    let countActivitiesEvaluable = audit.originalItem.activities.filter(
      (a) => a.is_evaluable === true
    ).length;

    if (
      audit.originalItem.status_id != STATUS_APPROVED &&
      audit.originalItem.program
    ) {
      alertWarning("La auditoría debe estar aprobada");
      return false;
    }
    if (countActivitiesEvaluable === 0) {
      alertWarning("La auditoría no tiene actividades evaluables");
      return false;
    }

    inProgressPlan(STATUS_INPROGRESS);
  };
  //se ingresa a una auditoría si soy un participante de ella
  useEffect(() => {
    if (audit.status == STATUS_LOADED) {
      getObservations();
      if (isLiderOrResponsable() || isParticipant(user.id)) {
      } else {
        history.push("/app/unauthorized");
      }
    }
  }, [audit.status]);
  const isParticipant = (user_id) => {
    let isParticipant = false;
    let participant = audit.originalItem.participants.filter(
      (p) => p.person_id == user_id
    );

    if (audit.originalItem.company) {
      if (audit.originalItem.company.contact) {
        if (audit.originalItem.company.contact.id == user.id) {
          isParticipant = true;
        }
      }
    }
    if (participant.length > 0) {
      isParticipant = true;
    }

    return isParticipant;
  };
  const imAudited = () => {
    if (audit.status == STATUS_LOADED) {
      let imAuditedFor = false;
      for (let p = 0; p < audit.originalItem.participants.length; p++) {
        const participant = audit.originalItem.participants[p];
        if (
          !participant.participant_type.is_audit &&
          participant.person.id == user.id
        ) {
          imAuditedFor = true;
        }
      }
      if (
        audit.originalItem.lider_person.id == user.id ||
        audit.originalItem.company.contact.id == user.id
      ) {
        imAuditedFor = false;
      }
      return imAuditedFor;
    }
  };
  /* FUNCTIONS MOBILE */
  const getDataItem = (object, item) => {
    if (item.auditable_item != null) {
      if (item.auditable_item.required) {
        object.numberCalificables = object.numberCalificables + 1;
        if (item.results.length > 0) {
          object.numberResult = object.numberResult + 1;
          if (item.results[0].is_valid == true) {
            object.fulfill = object.fulfill + 1;
          } else if (item.results[0].is_valid == false) {
            object.fails = object.fails + 1;
          } else {
            object.notApply = object.notApply + 1;
          }
        }
        object.documents = object.documents + item.documents.length;
        object.notes = object.notes + item.notes.length;
      } else if (item.items != "") {
        for (let i = 0; i < item.items.length; i++) {
          object = getDataItem(object, item.items[i]);
        }
      }
    }

    return object;
  };

  const recursiveAllEvaluations = (object, items) => {
    for (let i = 0; i < items.length; i++) {
      object = getDataItem(object, items[i]);
    }
    return object;
  };
  useEffect(() => {
    if (state.status == STATUS_LOADED) {
      let data = {
        numberCalificables: 0,
        numberResult: 0,
        percentage: 0,
        fulfill: 0,
        fails: 0,
        notApply: 0,
        documents: 0,
        notes: 0,
      };

      let evaluations = [];
      for (let a = 0; a < state.originalItem.activities.length; a++) {
        const activity = state.originalItem.activities[a];
        for (let e = 0; e < activity.evaluations.length; e++) {
          const evaluation = activity.evaluations[e];
          evaluations.push(evaluation);
        }
      }
      for (let e = 0; e < evaluations.length; e++) {
        recursiveAllEvaluations(data, evaluations[e].items);
      }
      data.percentage =
        data.numberResult == 0 && data.numberCalificables == 0
          ? 0
          : Math.round((data.numberResult * 100) / data.numberCalificables);
      setresult(data);
    }
  }, [state]);
  const getObservations = () => {
    let observations = [];
    for (let o = 0; o < audit.originalItem.evaluations.length; o++) {
      const evaluationFor = audit.originalItem.evaluations[o];
      for (let p = 0; p < evaluationFor.observations.length; p++) {
        const observation = evaluationFor.observations[p];
        observations.push(observation);
      }
    }
    setobservationsMobile(observations);
  };
  const getTotalActivityEnd = () => {
    let object = {
      totalActivity: 0,
      totalFinished: 0,
    };

    for (let a = 0; a < state.originalItem.activities.length; a++) {
      const activity = state.originalItem.activities[a];
      if (activity.is_evaluable) {
        object.totalActivity = object.totalActivity + 1;

        let evaluationsComplete = 0;
        for (let e = 0; e < activity.evaluations.length; e++) {
          const evaluation = activity.evaluations[e];
          let data = {
            numberCalificables: 0,
            numberResult: 0,
            documents: 0,
            notes: 0,
          };

          for (let i = 0; i < evaluation.items.length; i++) {
            const item = evaluation.items[i];
            data = getDataItem(data, item);
          }
          if (data.numberCalificables == data.numberResult) {
            evaluationsComplete = evaluationsComplete + 1;
          }
        }

        if (evaluationsComplete == activity.evaluations.length) {
          object.totalFinished = object.totalFinished + 1;
        }
      }
    }
    let percentage =
      object.totalFinished == 0 && object.totalActivity == 0
        ? 0
        : Math.round((object.totalFinished * 100) / object.totalActivity);
    if (
      audit.originalItem.status_id == STATUS_CREATED ||
      audit.originalItem.status_id == STATUS_PLANNED
    ) {
      percentage = 0;
    }
    return percentage;
  };
  const imAuditor = () => {
    if (audit.status == STATUS_LOADED) {
      let imAuditorFor = false;
      for (let p = 0; p < audit.originalItem.participants.length; p++) {
        const participant = audit.originalItem.participants[p];
        if (
          participant.participant_type.is_audit &&
          participant.person.id == user.id
        ) {
          imAuditorFor = true;
        }
      }
      return imAuditorFor;
    }
  };

  /* END FUNCTIONS MOBILE */
  return (
    <>
      {toogleModalChecklistProcess && (
        <div className="back-document">
          <div style={{minWidth:"270px"}} className="modal-audit flex-column flex">
            <div className="header-modal-audit flex">
              <div
                className="salir-audit"
                onClick={() =>
                  settoogleModalChecklistProcess(!toogleModalChecklistProcess)
                }
              ></div>
              <h3 className="title-form-topic tipo-description">
                Seleccione procesos
              </h3>
            </div>
            <div className="container-process-report-modal flex-column">
              <div className="form-group">
                <Select
                  value={processChecklist}
                  onChange={(e) => setprocessChecklist(e)}
                  className="tipo-description"
                  placeholder={"Todos los procesos"}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => option.id}
                  options={state.originalItem.company.process}
                  isClearable={true}
                  isMulti={true}
                />
              </div>
              <div className="flex">
                <div
                  onClick={() => generateChecklistProcess()}
                  className="btn-generate-report-process bg-orange white"
                >
                  Generar reportes
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
      {generateEvaluationsStatus === STATUS_LOADING && (
        <div className="flex back-document">
          <div className="margin-auto comtainer-loading-evaluation-audit">
            <div className="flex-column">
              <div className="margin-auto background-simple img-loading-evaluations"></div>
              Generando evaluaciones...
            </div>
          </div>
        </div>
      )}
      {state.status != STATUS_LOADED && <Loading />}

      <div
        className={`${
          state.status != STATUS_LOADED ? "hidden" : ""
        } general-container-audit color-black-gray`}
      >
        <nav aria-label="breadcrumb" className="flex-1 cursor-action">
          <ol className="breadcrumb">
            {state.originalItem.program && (
              <>
                <li className="breadcrumb-item">
                  <Link to="/app/audit/programs">Programas</Link>
                </li>
                <li className="breadcrumb-item ">
                  <Link
                    to={`/app/audit/program/${state.originalItem.program.id}`}
                  >
                    {state.originalItem.program.name}
                  </Link>
                </li>
              </>
            )}
            {!state.originalItem.program && (
              <li className="breadcrumb-item">
                <Link to="/app/audit/audits">Auditorias</Link>
              </li>
            )}

            <li className="breadcrumb-item ">
              <Link
                className="orange"
                to={`/app/audit/${state.originalItem.id}`}
              >
                {state.originalItem.title}
              </Link>
            </li>
          </ol>
        </nav>
        <div className="flex justify-betwen">
          <h2>Planeación de Auditoría &nbsp; {state.originalItem.number}</h2>
          <div className="flex">
            {" "}
            {state.originalItem.lider_person_id == user.id &&
              (state.originalItem.status_id == STATUS_APPROVED ||
                state.originalItem.status_id == STATUS_EXECUTED ||
                state.originalItem.status_id == STATUS_INPROGRESS) && (
                <div
                  id="btn-check-list-process-form-audit"
                  onClick={() =>
                    settoogleModalChecklistProcess(!toogleModalChecklistProcess)
                  }
                  className="btn-orange-default-short flex"
                >
                  <span className="background-simple icon-word-span-audit-form"></span>{" "}
                  Lista de chequeo por proceso
                </div>
              )}
            {imEvaluator() &&
              (state.originalItem.status_id == STATUS_APPROVED ||
                state.originalItem.status_id == STATUS_EXECUTED ||
                state.originalItem.status_id == STATUS_INPROGRESS) && (
                <div
                  id="btn-check-list-form-audit"
                  onClick={() =>
                    history.push(
                      `../audit/${state.originalItem.id}/pdf/listCheckUserEmpty`
                    )
                  }
                  className="btn-orange-default-short flex"
                >
                  <span className="background-simple icon-word-span-audit-form"></span>{" "}
                  <span className="background-simple icon-pdf-span-audit-form"></span>{" "}
                  &nbsp; Lista de chequeo
                </div>
              )}
          </div>
        </div>
        <div className="header-data-audit">
          <div className="form-group data-audit">
            <label className="tipo-title">Nombre</label>
            <div className="info-data-audit tipo-description txt-center">
              {state.originalItem.title}
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Lider</label>
            <div className="info-data-audit tipo-description txt-center">
              {state.originalItem.lider_person &&
                state.originalItem.lider_person.first_name}{" "}
              {state.originalItem.lider_person &&
                state.originalItem.lider_person.last_name}
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Norma</label>
            <div className="info-data-audit tipo-description txt-center">
              {state.originalItem.standard &&
                state.originalItem.standard.short_name}
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Compañia</label>
            <div className="info-data-audit tipo-description txt-center">
              {state.originalItem.company == null &&
                state.originalItem.company_name}
              {state.originalItem.company != null &&(
                 <Link
                 className="color-gray"
                 to={`/app/companies/${ state.originalItem.company.id}`}
               >
               {state.originalItem.company.name}
               </Link>
              )
               }
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Fecha Inicio</label>
            <div className="info-data-audit tipo-description txt-center">
              {moment(state.originalItem.init_date).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Fecha Final</label>
            <div className="info-data-audit tipo-description txt-center">
              {moment(state.originalItem.end_date).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
          <div className="form-group data-audit">
            <label className="tipo-title">Estado</label>
            <div className="info-data-audit tipo-description txt-center">
              {statusAudit(state.originalItem.status_id)}
            </div>
          </div>
          {state.originalItem.real_init_date != null && (
            <div className="form-group data-audit">
              <label className="tipo-title">Fecha Inicio Real</label>
              <div className="info-data-audit tipo-description txt-center">
                {moment(state.originalItem.real_init_date).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </div>
            </div>
          )}
          {state.originalItem.real_end_date != null && (
            <div className="form-group data-audit">
              <label className="tipo-title">Fecha Fin Real</label>
              <div className="info-data-audit tipo-description txt-center">
                {moment(state.originalItem.real_end_date).format(
                  "YYYY-MMMM-DD HH:mm"
                )}
              </div>
            </div>
          )}
          <div className="form-group data-audit">
            <label className="tipo-title">Categoria</label>
            <div className="info-data-audit tipo-description txt-center">
              <FontAwesomeIcon
                className="icon-personal-company-cart"
                icon={!state.originalItem.program ? faUser : faBuilding}
              />
              &nbsp;{state.originalItem.program ? "Empresarial" : "Personal"}
            </div>
          </div>
          <div classNam="form-group  flex justify-center align-items-end">
          {state.originalItem.lider_person_id == user.id &&
            state.status == STATUS_LOADED &&(
             
<div className="btn-orange-default-short"
              onClick={() =>
                history.push(`/app/audit/${audit.originalItem.id}/pdf/showAudit`)
              }
            >
             
              Ver plan
            </div>
              
            )}
          {state.originalItem.lider_person_id == user.id &&
            state.status == STATUS_LOADED &&
            state.originalItem.status_id != STATUS_INPROGRESS &&
            state.originalItem.status_id != STATUS_EXECUTED && (
              <>
                {state.originalItem.status_id === STATUS_APPROVED && (
                  <div
                    onClick={() => requestInProgress()}
                    className="btn-orange-default-short"
                  >
                    Iniciar auditoría
                  </div>
                )}
                {state.originalItem.status_id === STATUS_CREATED &&
                  !audit.originalItem.program && (
                    <>
                   
                      <div
                        onClick={() => checkApproved()}
                        className="btn-orange-default-short bg-orange"
                      >
                        Aprobar Auditoría
                      </div>
                      </>
                 
                  )}
                      
          </>
            )}

        </div>
        </div>

        <Tabs
          className="content-audit w-100"
          selectedIndex={
            audit.status == STATUS_LOADED && audit.originalItem.program
              ? tabAudit.company
              : tabAudit.personal
          }
          onSelect={(tabIndex) => {
            if (audit.status == STATUS_LOADED && audit.originalItem.program) {
              settabAudit({ ...tabAudit, company: tabIndex });
            } else {
              settabAudit({ ...tabAudit, personal: tabIndex });
            }
          }}
        >
          <TabList className="header-tab-audit">
            <Tab>
              <strong>Documentación para auditoría</strong>
            </Tab>

            {isLiderOrResponsable() && (
              <>
                <Tab>
                  <strong>Generalidades</strong>
                </Tab>
                <Tab>
                  <strong>Participantes</strong>
                </Tab>
              </>
            )}

            <Tab>
              <strong>Actividades</strong>
            </Tab>
            {isLiderOrResponsable() &&
              audit.status == STATUS_LOADED &&
              audit.originalItem.program && (
                <Tab>
                  <strong>Aprobación</strong>
                </Tab>
              )}
            {!imAudited() && (
              <Tab
                disabled={
                  audit.status == STATUS_LOADED &&
                  (audit.originalItem.status_id == STATUS_INPROGRESS ||
                    audit.originalItem.status_id == STATUS_EXECUTED)
                    ? false
                    : true
                }
              >
                <strong>Avance</strong>
              </Tab>
            )}

            {isLiderOrResponsable() && (
              <Tab
                disabled={
                  audit.status == STATUS_LOADED &&
                  (audit.originalItem.status_id == STATUS_INPROGRESS ||
                    audit.originalItem.status_id == STATUS_EXECUTED)
                    ? false
                    : true
                }
              >
                <strong>Reporte final</strong>
              </Tab>
            )}
            {isLiderOrResponsable() && (
              <Tab
                disabled={
                  audit.status == STATUS_LOADED &&
                  (audit.originalItem.status_id == STATUS_INPROGRESS ||
                    audit.originalItem.status_id == STATUS_EXECUTED)
                    ? false
                    : true
                }
              >
                <strong>Plan de acción</strong>
              </Tab>
            )}
          </TabList>
          <div className="general-tab-audit">
            <>
              <TabPanel
                className={`${isLiderOrResponsableOrAudit() ? "" : "hidden"}`}
              >
                <Documents />
              </TabPanel>
              {isLiderOrResponsable() && (
                <>
                  <TabPanel>
                    <Generalities data={state} />
                  </TabPanel>

                  <TabPanel>
                    {audit.status == STATUS_LOADED &&
                      state.status == STATUS_LOADED && (
                        <Participants
                          audit={state.originalItem}
                          staff={
                            state.originalItem.company &&
                            state.originalItem.company.partners
                          }
                          participants={state.originalItem.participants}
                          procces={
                            state.originalItem.company &&
                            state.originalItem.company.process
                          }
                        />
                      )}
                  </TabPanel>
                </>
              )}

              <TabPanel>
                {audit.status == STATUS_LOADED &&
                  state.status == STATUS_LOADED && (
                    <Activities
                      audit={state.originalItem}
                      participants={state.originalItem.participants}
                      activities={state.originalItem.activities}
                    />
                  )}
              </TabPanel>
              {isLiderOrResponsable() &&
                audit.status == STATUS_LOADED &&
                audit.originalItem.program && (
                  <TabPanel>
                    <AuditApproval
                      status={state.status}
                      audit={state.originalItem}
                    />
                  </TabPanel>
                )}
              <TabPanel>
                {audit.status == STATUS_LOADED &&
                  state.status == STATUS_LOADED && (
                    <Report
                      audit={state.originalItem}
                      statusAdvance={statusAdvance}
                      dataItem={dataItem}
                      getTotalAdvanceFilter={getTotalAdvanceFilter}
                    />
                  )}
              </TabPanel>
              {isLiderOrResponsable() && (
                <TabPanel>
                  {audit.status == STATUS_LOADED &&
                    state.status == STATUS_LOADED && <FinalReport />}
                </TabPanel>
              )}
              {isLiderOrResponsable() && (
                <TabPanel>
                  {audit.status == STATUS_LOADED &&
                    state.status == STATUS_LOADED && <PlanActions />}
                </TabPanel>
              )}
            </>
          </div>
        </Tabs>
      </div>

      {/* //////////////////////////////////// DEVICE MOBILE ////////////////////////////////////// */}

      <div className="general-container-audit-mobile hidden">
        {/* modal observations */}
        {toogleObservations && (
          <div className="back-document">
            <div className="flex container-general-observations">
              <div className="flex header-modal-observations">
                <div
                  onClick={() => settoogleObservations(!toogleObservations)}
                  className="salir-company"
                ></div>
                <div className="w-100 txt-center">
                  <h3 className="tipo-description ">Observaciones</h3>
                </div>
              </div>
              <div className="container-content-modal-observation">
                <div className="content-modal-observations margin-control">
                  <div className="container-observations-modal">
                    {observationsMobile.map((observation, index) => {
                      return (
                        <div key={index}>
                          {(audit.originalItem.lider_person.id == user.id ||
                            observation.person.id == user.id) && (
                            <div className="content-observation-modal tipo-description">
                              <div className="flex flex-column">
                                <p>{observation.note}</p>
                                <div className="flex">
                                  {observation.documents.map((document) => {
                                    return (
                                      <div className="flex">
                                        <Document
                                          key={document.id}
                                          document={document}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>

                              <div className="flex container-date-desktop-observation">
                                <p className="tipo-cursiva date-observation-evaluation">
                                  {moment(observation.created_at).format(
                                    "YYYY-MM-DD HH:mm a"
                                  )}
                                </p>
                                <p className="date-observation-evaluation">
                                  {observation.person.first_name}&nbsp;
                                  {observation.person.last_name}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* end modal observations */}

        <div className="flex bg-white container-headers-audit-mobile">
          <div className="flex-1 txt-center">
            <p className="p-header-mobile-audits-active">
              Auditoría {state.originalItem.number}
            </p>
          </div>
        </div>
        <div className="container-dates-auidit-mobile flex">
          <div>
            <p className="tipo-description label-date-mobile-audit">Inicia</p>
            <div className="calendar-audit-mobile">
              <div className="bg-orange">
                <p className="white tipo-description ">
                  {moment(state.originalItem.init_date).format("MMMM")}
                </p>
              </div>
              <div className="date-mobile-audit txt-center flex">
                <label>
                  {" "}
                  {moment(state.originalItem.init_date)
                    .format("ddd")
                    .substr(0, 3)}
                </label>
                <label>
                  {moment(state.originalItem.init_date).format("DD")}
                </label>
                <label>
                  {moment(state.originalItem.init_date).format("YYYY")}
                </label>
              </div>
            </div>
          </div>
          <div>
            <p className="tipo-description label-date-mobile-audit">Finaliza</p>
            <div className="calendar-audit-mobile">
              <div className="bg-orange">
                <p className="white tipo-description ">
                  {moment(state.originalItem.end_date).format("MMMM")}
                </p>
              </div>
              <div className="date-mobile-audit txt-center flex">
                <label>
                  {" "}
                  {moment(state.originalItem.end_date)
                    .format("ddd")
                    .substr(0, 3)}
                </label>
                <label>
                  {moment(state.originalItem.end_date).format("DD")}
                </label>
                <label>
                  {moment(state.originalItem.end_date).format("YYYY")}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="container-title-audit-mobile">
          <div>{state.originalItem.title}</div>
          <div>
            {state.status === STATUS_LOADED &&
              state.originalItem.standard.short_name}
          </div>
        </div>
        <hr className="no-margin" />
        <div className="container-title-audit-mobile">
          <label className="tipo-title">Empresa</label>
          <div>
            {state.status === STATUS_LOADED && state.originalItem.company.name}
          </div>
        </div>
        <hr className="no-margin" />
        <div className="flex container-first-progress-mobile-audit">
          <div className="flex-1">
            Progreso{" "}
            <label className="percentage-mobile-audit tipo-description">
              {dataItem.percentage}%
            </label>
          </div>
          <div className="flex-1"></div>
          <label className="percentage-mobile-audit tipo-description">
            {state.status === STATUS_LOADED && getTotalActivityEnd()}%
          </label>
        </div>
        <div className="flex container-progress-audit-mobile">
          <div className="flex-1  tipo-description">
            Items{""}
            <div style={{ width: "140px", height: "80px" }}>
              <ReactSpeedometer
                ringWidth={25}
                needleHeightRatio={0.7}
                needleColor={"black"}
                forceRender={true}
                paddingHorizontal={0}
                paddingVertical={0}
                maxSegmentLabels={0}
                fluidWidth={true}
                minValue={0}
                maxValue={100}
                value={parseInt(dataItem.percentage)}
              />
            </div>
          </div>
          <div className="flex-1  tipo-description">
            Actividades{" "}
            <div style={{ width: "140px", height: "80px" }}>
              {statusAdvance === STATUS_LOADING && (
                <div className="flex margin-auto">
                  <img
                    style={{ width: "75px", margin: "auto" }}
                    src={imgLoading}
                    alt=""
                  />
                </div>
              )}
              {statusAdvance === STATUS_LOADED && (
                <ReactSpeedometer
                  ringWidth={25}
                  needleHeightRatio={0.7}
                  needleColor={"black"}
                  forceRender={true}
                  paddingHorizontal={0}
                  paddingVertical={0}
                  maxSegmentLabels={0}
                  fluidWidth={true}
                  minValue={0}
                  maxValue={100}
                  value={
                    state.status === STATUS_LOADED ? getTotalActivityEnd() : 0
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-column container-result-mobile-view-audit container-report-view-activity-mobile">
          <div className="flex">
            <div>
              <div className="icon-advance"></div>
            </div>
            <div>
              <div className="icon-percentage"></div>
            </div>
            <div>
              <div className="icon-like"></div>
            </div>
            <div>
              <div className="icon-dislike"></div>
            </div>
            <div>
              <div className="icon-notApply"></div>
            </div>
            <div>
              <div className="icon-images"></div>
            </div>
            <div>
              <div className="icon-notes"></div>
            </div>
          </div>
          <div className="flex result-show-mobile-container">
            <div
              className={`margin-auto ${
                dataItem.numberCalificables.length > 3 ? "size-four-report" : ""
              }`}
            >
              {dataItem.numberResult}/{dataItem.numberCalificables}
            </div>
            <div>{dataItem.percentage}%</div>
            <div>{canViewResult ? dataItem.fulfill : "-"}</div>
            <div>{canViewResult ? dataItem.fails : "-"}</div>
            <div>{canViewResult ? dataItem.notApply : "-"}</div>
            <div>{canViewResult ? dataItem.documents : "-"}</div>
            <div>{canViewResult ? dataItem.notes : "-"}</div>
          </div>
        </div>
        {audit.status == STATUS_LOADED &&
          audit.originalItem.lider_person_id === user.id &&
          audit.originalItem.status_id != STATUS_INPROGRESS &&
          audit.originalItem.status_id != STATUS_EXECUTED && (
            <div className="flex container-start-audit-mobile">
              <div
                onClick={() => {
                  requestInProgress();
                }}
                className="bg-orange margin-auto  white tipo-title"
              >
                Iniciar auditoría
              </div>
            </div>
          )}

        <div className="container-activities-audit-mobile flex tipo-description">
          {imAuditor() && audit.status == STATUS_LOADED && (
            <div className="flex-0 flex">
              <div
                onClick={() => settoogleObservations(!toogleObservations)}
                className="background-simple image-observations-mobile-audit"
              >
                <div className="number-observation-mobile-audit">
                  {
                    observationsMobile.filter(
                      (observation) =>
                        audit.originalItem.lider_person.id == user.id ||
                        observation.person.id == user.id
                    ).length
                  }
                </div>
              </div>
            </div>
          )}

          <div className="flex-1 txt-center">
            Estado<br></br>{" "}
            <span>{statusAudit(state.originalItem.status_id)}</span>
          </div>
          <div className="flex-1 flex">
            {state.status == STATUS_LOADED && (
              <Link
                className="margin-auto"
                to={`/app/audit/${state.originalItem.id}/activities`}
              >
                <div className="background-simple btn-activities-audit-mobile "></div>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* //////////////////END DEVICEMOBILE////////////////// */}
    </>
  );
};

const Generalities = (data) => {
  let [{ audit }, { updateAudit }] = React.useContext(AuditPlanFormContext);
  let [state, setState] = React.useState(data.data);
  let [stateCreate, setStateCreate] = useState(initialState);
  const [editorState, setEditorState] = React.useState(initialState.richText);
  let [{ user }, {}] = useContext(SecurityContext);

  const setHtml = (value) => {
    value = value == null ? "" : value;
    return RichTextEditor.createValueFromString(value, "html");
  };

  useEffect(() => {
    setState(data.data);
    let audit = data.data.originalItem;
    setEditorState({
      ...editorState,
      objetives: setHtml(audit.objetives),
      scope: setHtml(audit.scope),
      criterial: setHtml(audit.criterial),
      methodology: setHtml(audit.methodology),
      exclusions: setHtml(audit.exclusions),
      resources: setHtml(audit.resources),
      responsability_leader: setHtml(audit.responsability_leader),
      responsability_team: setHtml(audit.responsability_team),
      conclusion: setHtml(audit.conclusion),
      risks: setHtml(audit.risks && audit.risks[0].description),
      requirements: setHtml(audit.requirements),
      id: audit.id,
    });
  }, [data]);

  const setEditor = (field) => (value) => {
    setEditorState({ ...editorState, [field]: value });
  };
  // The toolbarConfig object allows you to specify custom buttons, reorder buttons and to add custom css classes.
  // Supported inline styles: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Inline-Styles.md
  // Supported block types: https://github.com/facebook/draft-js/blob/master/docs/Advanced-Topics-Custom-Block-Render.md#draft-default-block-render-map
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      // "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  const isDisabled = () => {
    if (
      state.originalItem.lider_person_id != user.id ||
      state.originalItem.status_id == STATUS_APPROVED ||
      state.originalItem.status_id == STATUS_INPROGRESS ||
      state.originalItem.status_id == STATUS_EXECUTED
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Form
        onSubmit={(formObj) => {
          updateAudit(editorState);
        }}
        initialValues={state.originalItem}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="form-generalities">
            <div className="form-group generalities-form">
              <Field name="scope">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Alcance</label>
                    <RichTextEditor
                      value={editorState.scope}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("scope")}
                      placeholder="Alcance"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="criterial">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Criterios</label>
                    <RichTextEditor
                      value={editorState.criterial}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("criterial")}
                      placeholder="criterial"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="methodology">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Metodologías</label>
                    <RichTextEditor
                      value={editorState.methodology}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("methodology")}
                      placeholder="Metodologías"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="objetives">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Objetivos</label>
                    <RichTextEditor
                      value={editorState.objetives}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("objetives")}
                      placeholder="Objetivos"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="exclusions">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Exclusiones</label>
                    <RichTextEditor
                      value={editorState.exclusions}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("exclusions")}
                      placeholder="Exclusiones"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="resources">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">Recursos</label>
                    <RichTextEditor
                      value={editorState.resources}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("resources")}
                      placeholder="Recursos"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="responsability_leader">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">
                      Responsabilidades lider auditor
                    </label>
                    <RichTextEditor
                      value={editorState.responsability_leader}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("responsability_leader")}
                      placeholder="Responsabilidades"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="responsability_team">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">
                      Responsabilidades equipo auditor
                    </label>
                    <RichTextEditor
                      value={editorState.responsability_team}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("responsability_team")}
                      placeholder="Responsabilidades"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>

            <div className="form-group generalities-form">
              <Field name="risks">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">
                      Riesgos de la auditoría
                    </label>
                    <RichTextEditor
                      value={editorState.risks}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("risks")}
                      placeholder="Riesgos"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className="form-group generalities-form">
              <Field name="requirements">
                {({ input, meta }) => (
                  <>
                    <label className="tipo-title">
                      Requerimientos de la auditoría
                    </label>
                    <RichTextEditor
                      value={editorState.requirements}
                      toolbarConfig={toolbarConfig}
                      onChange={setEditor("requirements")}
                      placeholder="Requerimientos"
                      disabled={isDisabled()}
                    />
                    {meta.touched && meta.error && (
                      <span className="tipo-cursiva orange">{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            {state.originalItem.lider_person_id == user.id && (
              <div className="btn-form-generalities">
                {state.originalItem.status_id == STATUS_APPROVED ||
                state.originalItem.status_id == STATUS_INPROGRESS ||
                state.originalItem.status_id == STATUS_EXECUTED ? (
                  ""
                ) : (
                  <input
                    type="submit"
                    className="btn-orange-default"
                    value="Guardar"
                  />
                )}
              </div>
            )}
          </form>
        )}
      </Form>
    </>
  );
};
const Document = ({ document }) => {
  let history = useHistory();
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  return (
    <>
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <img
          className="cursor-action img-attached-final-report"
          src={
            process.env.REACT_APP_API_HOST +
            "/documents/" +
            (document && document.name)
          }
          onError={(e) => {
            e.target.src = imgError;
          }}
        />
      )}
      {document.type == EXT_PDF && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-pdf-observation btn-files-observations"
        ></div>
      )}
      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-word-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP3 && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp3-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP4 && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp4-observation btn-files-observations"
        ></div>
      )}
    </>
  );
};
export default Audit;
