import React, { useEffect, useState, useContext } from "react";

import {
  SecurityContext,
  STATUS_LOADED,
} from "../../contexts/security/SecurityContext";
import "./ValidateAutenticate.css";
const ValidateAutenticate = ({ match: { params } }) => {
  let { aes } = params;
  const [{}, { searchStaff }, { companyStaffValidate }] =
    useContext(SecurityContext);
  useEffect(() => {
    searchStaff(aes);
  }, [aes]);

  return (
    <div className="general-container-validate-access">
      {companyStaffValidate.status === STATUS_LOADED &&
        companyStaffValidate.staff && (
          <div className="card-validate-access">
            <div className="circle-validate-autenticate"
            >
              <i className="checkmark checkmark-validate-access">✓</i>
            </div>
           
            <h1>{companyStaffValidate.staff.company.name}</h1>
            <p className="tipo-cursiva">
            {companyStaffValidate.staff.person.first_name} {companyStaffValidate.staff.person.last_name}
              <br /> pertenece a esta empresa!
            </p>
          </div>
        )}
  
      {companyStaffValidate.status === STATUS_LOADED &&
        !companyStaffValidate.staff && (
          <div className="card-validate-access">
            <div className="circle-validate-autenticate circle-validate-autenticate-error"
            >
              <i className="checkmark checkmark-validate-access checkmark-validate-access-error">x </i>
            </div>
           
            <h1></h1>
            <p className="tipo-cursiva">
           Esta persona no esta <br /> entre los miembros de la empresa
            </p>
          </div>
        )}
    </div>
  );
};
export default ValidateAutenticate;
