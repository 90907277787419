import React, { useEffect, useSate, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Index.css";
import {
  InspectionIndexContext,
  STATUS_ERASER,
  STATUS_APPROVED,
} from "../../../contexts/evaluation/InspectionIndexContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import iconDelete from "../../../../src/img_zeus/icons_audit/ICONO-ELIMINAR.png";
import iconShow from "../../../../src/img_zeus/icons_audit/ICONO-VER.png";
import iconEdit from "../../../../src/img_zeus/icons_audit/ICONO-EDITAR.png";
import iconExecuted from "../../../img_zeus/icons_audit/ingresar-02.png";
import CreateInspection from "../../audit/modals/CreateInspection";
import ShareInspection from "../../audit/modals/ShareInspection";

import Swal from "sweetalert2";
import Toggle from "react-toggle";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faAddressBook,
  faUser,
  faBuilding,
  faShareAltSquare,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
const Index = () => {
  let history = useHistory();
  const [
    { inspection, modalInspection, modalshare },
    {
      setmodalInspection,
      deleteInspection,
      updateInspection,
      setinspection,
      generateChildInspection,
      setmodalshare,
    },
  ] = useContext(InspectionIndexContext);
  const [{ user }, { viewGeneral }] = useContext(SecurityContext);
  useEffect(() => {
    viewGeneral("inspection");
  }, []);
  const checkDelete = (evaluation_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta lista?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteInspection(evaluation_id);
      } else {
      }
    });
  };
  const validateEvaluated = (evaluation_id) => {
    Swal.fire({
      title: "¿Realmente deseas ejecutar esta lista?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, ejecutar!",
    }).then((result) => {
      if (result.value) {
        generateChildInspection(evaluation_id);
      } else {
      }
    });
  };
  const changeStatusInspection = (evaluation_id, index) => (event) => {
    let newArray = [...inspection.elements];
    let newStatus =
      newArray[index].status_id == STATUS_ERASER
        ? STATUS_APPROVED
        : STATUS_ERASER;
    newArray[index].status_id = newStatus;

    setinspection({ ...inspection, elements: newArray });
    updateInspection(evaluation_id, { status_id: newStatus });
  };
  const shortText = (text, range) => {
    if (text.length > range) {
      return text.substr(0, range) + "...";
    } else {
      return text;
    }
  };
  return (
    <div>
      {modalInspection.open && (
        <div className="back-document">{<CreateInspection />}</div>
      )}
      {modalshare.open && (
        <div className="back-document">{<ShareInspection />}</div>
      )}
      <div className="flex">
        <div className="flex-1">
          <h2 className="title-evaluation tipo-title">Listas de inspección</h2>
        </div>
        <div className="flex-1 flex justify-end">
          <div
            onClick={() =>
              setmodalInspection({ ...modalInspection, open: !inspection.open })
            }
            className="cursor-action bg-orange white btn-create-list-inspection"
          >
            Crear lista
          </div>
        </div>
      </div>
      <div className="general-content-index-evaluation back-gray-content">
        <table className="table table-hover">
          <thead className="header-table-index-evaluation">
            <tr className="txt-center">
              <th style={{ minWidth: "300px" }} scope="col">
                NOMBRE
              </th>
              <th scope="col">DESCRIPCIÓN</th>
              <th scope="col">No USOS</th>
              <th style={{ minWidth: "87px" }} scope="col">
                CREACIÓN
              </th>
              <th style={{ minWidth: "87px" }} scope="col">
                ÚLTIMA EDICIÓN
              </th>
              <th scope="col">ESTADO</th>
              <th style={{ width: "130px" }} scope="col">
                ACCIONES
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {inspection.elements.map((eva, index) => (
              <tr className="tr-table-standars txt-center" key={eva.id}>
                <td>
                  <div className="flex">
                    {eva.consumer && (
                      <div className="flex margin-auto flex-0">
                        <FontAwesomeIcon
                          className="icon-personal-company-cart "
                          icon={
                            eva.consumer.effective.is_personal
                              ? faUser
                              : faBuilding
                          }
                        />
                        &nbsp;
                      </div>
                    )}

                    <div className="flex-1 flex margin-auto">
                      <span title={eva.title}>{shortText(eva.title, 40)}</span>
                    </div>
                  </div>
                </td>
                <td title={eva.description}>
                  {shortText(eva.description, 60)}
                </td>
                <td
                className="cursor-action"
                  onClick={() =>
                    history.push(`/app/inspection/evaluation/${eva.id}/childs`)
                  }
                >
                  {eva.childs_evaluation_count}
                </td>

                <td title={eva.created_at}>
                  {" "}
                  {moment(eva.created_at).format("YYYY-MM-DD")}
                </td>

                <td title={eva.updated_at}>
                  {moment(eva.updated_at).format("YYYY-MM-DD")}
                </td>

                <td
                  title={`${
                    eva.status_id === STATUS_ERASER ? "Borrador" : "Publicado"
                  }`}
                >
                  <Toggle
                    checked={eva.status_id === STATUS_ERASER ? false : true}
                    name="status"
                    value="yes"
                    onChange={changeStatusInspection(eva.id, index)}
                  />
                </td>

                <td className="icons-standard-action">
                  <img
                    onClick={() =>
                      history.push(`/app/inspection/evaluation/${eva.id}`)
                    }
                    title="ver lista"
                    className="icon-actions-norma cursor-action"
                    src={iconShow}
                  />
                  
                {/*   <img
                    onClick={() => {
                      setmodalInspection({
                        ...modalInspection,
                        open: true,
                        evaluation_id: eva.id,
                      });
                    }}
                    title="editar lista"
                    className="icon-actions-norma cursor-action"
                    src={iconEdit}
                  />  */}
                  {eva.status_id === STATUS_APPROVED && (
                    <>
                      <img
                        onClick={() => {
                          validateEvaluated(eva.id);
                        }}
                        title="Ejecutar lista"
                        className="icon-actions-norma cursor-action"
                        src={iconExecuted}
                      />
                      <FontAwesomeIcon
                        title="Compartir"
                        onClick={() =>
                          setmodalshare({
                            ...modalshare,
                            open: true,
                            evaluation_id: eva.id,
                          })
                        }
                        className="cursor-action"
                        icon={faShareSquare}
                      />
                      &nbsp;
                    </>
                  )}

                  {user.is_admin && (
                    <img
                      onClick={() => checkDelete(eva.id)}
                      title="eliminar lista"
                      className="icon-actions-norma cursor-action"
                      src={iconDelete}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div />
      </div>
    </div>
  );
};

export default Index;
