import React, { useEffect, useState, createContext, useContext } from "react";
import client from "../axios/axios-client";
import Swal from "sweetalert2";
import { AlertContext } from "../alerts/AlertContext";
import {
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
  STATUS_LOADED,
} from "../security/SecurityContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

let initialState = {
  effectives: {
    model_id: null,
    model: null,
    status: STATUS_NOT_LOADED,
    elements: [],
  },
  metaRow: {
    limit: 10,
    offset: 0,
    page: 1,
    filters: { "effective.service_id": 3 },
    filtersLike: {},
    orderBy: ["document.size_mb:desc"],
  },
  errors: {
    error: [],
    status: false,
  },
};
export const EffectiveIndexContext = createContext([]);

export const EffectiveIndexContextProvider = (props) => {
  let { children } = props;

  const [{}, { alertWarning, alertSuccess, alertError, showErrors }] = useContext(AlertContext);
  let [errors, setErrors] = useState(initialState.errors);
  const [effectives, seteffectives] = useState(initialState.effectives);
  const [metaRow, setmetaRow] = useState(initialState.metaRow);
  const [rows, setrows] = useState(0);
  const [{ user }, { logout, fetchUser, refreshUser }] = useContext(SecurityContext);
  let history = useHistory();
  //control de errores
  useEffect(() => {
    if (errors.status) {
      showErrors(errors.error);
      setErrors({ ...errors, status: false });
    }
  }, [errors]);
  useEffect(() => {
    if (effectives.model && effectives.model_id) {
      setmetaRow({
        ...metaRow,
        filters: {
          ...metaRow.filters,
          "effective.assignable_type": "App\\Models\\" + capitalize(effectives.model),
          "effective.assignable_id": effectives.model_id,
        },
      });
    }
  }, [effectives.model, effectives.model_id]);
  useEffect(() => {
    async function fetchData() {
      if (metaRow.filters["effective.assignable_id"]) {
        let newRows = await getCountConsumers();
         setrows(newRows);
        let newPages = Math.ceil(newRows / metaRow.limit);

        if (metaRow.page > newPages && newPages !== 0) {
          setmetaRow({ ...metaRow, page: 1 });

          return false;
        }

        seteffectives({ ...effectives, status: STATUS_LOADING });

        client()
          .get("billing/getDocumetsConsumers", {
            params: {
              with: ["documentable"],
              orderBy: metaRow.orderBy,
              filters: metaRow.filters,
              filtersLike: metaRow.filtersLike,
              limit: metaRow.limit,
              offset: metaRow.limit * (metaRow.page - 1),
            },
          })
          .then(({ data }) => {
            if (data) {
              seteffectives({
                ...effectives,
                status: STATUS_LOADED,
                elements: data,
              });
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.request.status === 401) {
              logout();
            } else if (e.request.status === 422) {
              setErrors({ error: e.response.data, status: true });
            } else if (e.request.status === 403) {
              history.push("../../app/unauthorized");
            } else {
              alertError("Error al buscar el producto");
            }
          });
      }
    }
    fetchData();
  }, [metaRow]);
  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1);
  }
  const getCountConsumers = async () => {
    if (metaRow.filters.effective_id !== null) {
      return client()
        .get(`billing/countEffective`, {
          params: {
            filters: metaRow.filters,
            filtersLike: metaRow.filtersLike,
          },
        })
        .then(({ data }) => {
          return data;
        });
    }
  };
  return (
    <EffectiveIndexContext.Provider
      value={[
        { effectives, metaRow, rows },
        { seteffectives, setmetaRow },
      ]}
    >
      {children}
    </EffectiveIndexContext.Provider>
  );
};
