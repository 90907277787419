// @flow

import React, { useContext, useState, useEffect } from "react";
import "../topic/topic.css";
import imgError from "../../../default.jpg";
import imgCrear from "../../../img_zeus/icons_library/ICONO-CREAR.png";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import CrearTopic from "../topic/Form";
import {
  LibraryContext,
  STATUS_ERASER,
} from "../../../contexts/library/LibraryContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import moment from "moment";
import { Link } from "react-router-dom";
const STATUS_LOADED = "STATUS_LOADED";
const initialState = {
  modal: false,
  topic_id: null,
  toggle_edit: true,
  topics: [],
};

const Topic = ({ computedMatch: { path } }) => {
  const [{ topics }, { deleteTopic }] = useContext(LibraryContext);
  let [modalState, setModalState] = useState(initialState);
  let [{ user }, { can, isAdminTopic, viewGeneral }] = useContext(
    SecurityContext
  );
  const [searchTopic, setsearchTopic] = useState("");
  const [elements, setelements] = useState(initialState.topics);
  let history = useHistory();
  let shortDescription = (desc) => {
    if (desc && desc.length > 95) {
      return desc.substr(0, 95) + "...";
    }
    return desc;
  };
  useEffect(() => {
    viewGeneral("topic");
  }, []);
  useEffect(() => {
    if (topics.status == STATUS_LOADED) {
      setelements(topics.elements);
    }
  }, [topics]);
  useEffect(() => {
    let newTopics = [];
    if (searchTopic == "") {
      newTopics = [...topics.elements];
    } else {
      newTopics = topics.elements.filter(function (el) {
        return (
          el.name.toLowerCase().indexOf(searchTopic.toLowerCase()) > -1 ||
          el.description.toLowerCase().indexOf(searchTopic.toLowerCase()) > -1
        );
      });
    }
    setelements(newTopics);
  }, [searchTopic]);
  let fontSize = (nameTopic) => {
    if (nameTopic && nameTopic.length <= 13) {
      return "font-size-short";
    } else if (nameTopic && nameTopic.length <= 26) {
      return "font-size-medium";
    } else if (nameTopic && nameTopic.length <= 39) {
      return "font-size-long";
    } else {
      return "font-size-more-long";
    }
  };

  const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

  const validSubscription = (topic_id) => {
    {
     
      let el = user.topicsActive.filter((t) => t.id == topic_id);
      return el.length > 0 ? true : false;
    }
  };

  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

  return (
    <div className="content-topic">
      <div className="header-topic">
        <div className=" container-search-topic-library  flex">
          <input
            placeholder="Escribe aquí"
            value={searchTopic}
            onChange={(e) => setsearchTopic(e.target.value)}
            className="tipo-cursiva felx-1"
            type="text"
          />
          <div className="background-simple btn-searc-library"></div>
        </div>
        {modalState.modal && (
          <div className="back-document">
            <div className="modal">
              <div className="header-modal">
                <div
                  className="salir-topic cursor-action"
                  onClick={() => {
                    setModalState({
                      ...modalState,
                      modal: !modalState.modal,
                      topic_id: null,
                    });
                  }}
                ></div>
                {modalState.topic_id !== null && (
                  <h3 className="title-form-topic tipo-description">
                    Editar Tema
                  </h3>
                )}{" "}
                {modalState.topic_id === null && (
                  <h3 className="title-form-topic tipo-description">
                    Nuevo Tema
                  </h3>
                )}
              </div>
              <div className="create-topic-component">
                <CrearTopic
                  modalState={modalState}
                  setModalState={setModalState}
                  topic_id={modalState.topic_id}
                ></CrearTopic>
              </div>
            </div>
          </div>
        )}
        <br />
        {(can("create_topic") || user.is_admin) && (
          <>
            <div
              className="btn-orange-default mobile-hidden"
              onClick={() => {
                setModalState({ ...modalState, modal: true, topic_id: null });
              }}
            >
              Crear
            </div>
          </>
        )}
        {(user.topics_admin.length > 0 || user.is_admin) && (
          <div
            className="btn-orange-default mobile-hidden"
            onClick={() => history.push(`../../../app/library/pointsTopic`)}
          >
            {" "}
            Puntos
          </div>
        )}
      </div>
      <div className="temporalDiv">
        <br />
        {elements.map((top) => (
          <div key={top.id} className="part-tarjet-topic">
            <Link className="link-topic" to={`/app/library/topic/${top.id}`}>
              <div
                className={`tarjet-general-topic ${
                  top.status_id == STATUS_ERASER ? "gray-scale" : ""
                }`}
              >
                <img
                  className="img-topic"
                  src={
                    top.image &&
                    process.env.REACT_APP_API_HOST +
                      "documents/" +
                      top.image.name
                  }
                  onError={(e) => {
                    e.target.src = imgError;
                  }}
                />

                <div className="content-general-topic">
                  <div className="subscription-topic">
                    {validSubscription(top.id) == false && (
                      <div className="subscription-tarjet-topic">
                        <small>
                          <strong className="tipo-boton">Suscribirme</strong>
                        </small>
                      </div>
                    )}
                    {validSubscription(top.id) == true && (
                      <div className="subscription-tarjet-topic-true">
                        <small className="tipo-boton">Activo</small>
                      </div>
                    )}
                  </div>

                  <h6 className="tipo-description txt-categoria-topic">
                    Categoría
                  </h6>
                  <h2 className={`tipo-title ${fontSize(top.name)}`}>
                    {top.name}
                  </h2>
                  <h6 className="orange tipo-cursiva">¿Que encontrarás?</h6>
                  <p className="tipo-description txt-description-topic">
                    {shortDescription(top.description)}
                  </p>
                </div>
              </div>
            </Link>
            <div className="btn-actions-topic">
              {user.is_admin && (
                <div
                  title="Eliminar"
                  onClick={() => {
                    checkDelete(
                      "¿Realmente deseas eliminar a esta tema?",
                      `deleteTopic(${top.id})`
                    );
                  }}
                  className="btn-delete-topic cursor-action btn-delete-black"
                ></div>
              )}
              &nbsp;&nbsp;
              {(isAdminTopic(top.id) || user.is_admin) && (
                <div
                  title="Modificar"
                  className="btn-edit-topic cursor-action btn-edit-black"
                  onClick={() => {
                    setModalState({
                      ...modalState,
                      modal: true,
                      topic_id: top.id,
                    });
                  }}
                ></div>
              )}
            </div>
          </div>
        ))}
        {elements && elements.length == 0 && (
          <div className="w-100 txt-center">Sin temas registrados</div>
        )}
      </div>
    </div>
  );
};

export default Topic;
