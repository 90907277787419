import React, { useState, useEffect, useContext } from "react";
import Select, { components } from "react-select";
import {
  ProgramFormContext,
  STATUS_LOADED,
  AuditPlanFormContext,
} from "../../../contexts/audit/AuditPlanFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import "../audit/form.css";
import moment from "moment";
import { Field, Form } from "react-final-form";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import DataTable from "react-data-table-component";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";
const NUMBER_CARACTER = 5;
const initialState = {
  status: STATUS_NEW,
  item: {
    participant_type_id: "",
  },
  company_procces_id: {},
  searchUser: {
    email: "",
    name: "",
  },
};

const CreateParticipantAuditor = ({
  participants,
  state,
  setState,
  participant,
  procces,
}) => {
  const [
    { userAuditor, participantType, usersSearch },
    { addAuditor, updateAuditor, setUserAuditor, searchUsers, setusersSearch },
  ] = useContext(AuditPlanFormContext);
  let [stateCreate, setStateCreate] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [person, setperson] = useState(initialState.searchUser);
  const [columnsTable, setcolumnsTable] = useState([]);
  useEffect(() => {
    if (participant && participantType.status === STATUS_LOADED) {
      let el = participantType.elements.filter(
        (e) => e.id === participant.participant_type_id
      )[0];
      setStateCreate({
        ...stateCreate,
        item: el,
        originalItem: el,
        status: STATUS_EDIT,
      });
    }
  }, [participant, participantType]);
  useEffect(() => {
    if (person.email.length >= NUMBER_CARACTER) {
      searchUsers(person.email);
    } else {
      setusersSearch([]);
    }
  }, [person]);
  useEffect(() => {
    setcolumnsTable([
      {
        name: "EMAIL",
        center: true,
        selector: "email",
        sortable: true,
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setUserAuditor(row);
            }}
          >
            {row.email}
          </div>
        ),
      },
      {
        name: "NOMBRE",
        center: true,
        selector: "first_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setUserAuditor(row);
            }}
          >
            {row.first_name}
          </div>
        ),
      },
      {
        name: "APELLIDO",
        center: true,
        selector: "last_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              setUserAuditor(row);
            }}
          >
            {row.last_name}
          </div>
        ),
      },
    ]);
  }, [usersSearch]);
  useEffect(() => {
    if (userAuditor.id) {
      let el = participants.filter(
        (e) => e.person_id == userAuditor.id && e.participant_type.is_audit
      );
      if (el.length > 0) {
        alertWarning("Este usuario ya está en la lista de evaluadores");
        setUserAuditor({});
      }
    }
  }, [userAuditor]);
  const setFieldPerson = (field) => (e) => {
    setperson({ ...person, [field]: e.target.value });
  };

  const required = (value) => {
    return value ? undefined : "Requerido";
  };

  const addParticipantAuditor = (participant_type_id) => {
    addAuditor(
      participant_type_id,
      userAuditor.id,
      stateCreate.company_procces_id
    );
    setState({ ...state, toggle_modal: false });
  };

  const updateParticipantAuditor = (participant_type_id) => {
    updateAuditor(participant.id, participant_type_id);
    setState({ ...state, toggle_modal: false, participant: null });
  };

  let functionSubmit = (formObj) => {
    if (stateCreate.status === STATUS_EDIT) {
      updateParticipantAuditor(formObj.participant_type_id.id);
    } else {
      addParticipantAuditor(formObj.participant_type_id.id);
    }
  };

  return (
    <div className="form-participants">
      {stateCreate.status == STATUS_NEW && (
        <div className="form-group margin-control content-search-participant flex-column flex">
          <input
            type="text"
            className="form-control inp-email-participant"
            onChange={setFieldPerson("email")}
            value={person.email}
            placeholder="Correo Electronico"
          />

          <label className="tipo-cursiva" htmlFor="">
            Búsqueda apartir de {NUMBER_CARACTER} caracteres
          </label>

          <DataTable
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10]}
            noDataComponent={"no hay datos"}
            striped={true}
            columns={columnsTable}
            responsive={true}
            className=" tipo-description"
            data={usersSearch}
            pagination={true}
            paginationComponentOptions={{
              rowsPerPageText: "Lineas:",
              rangeSeparatorText: "de",
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: "Todas",
            }}
          />
        </div>
      )}

      <Form
        onSubmit={(formObj) => {
          functionSubmit(formObj);
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {userAuditor.id && (
              <div className="content-find-participant">
                <div className="form-group div-form-participant">
                  <label className="tipo-title">Nombre</label>
                  <div>
                    {userAuditor.first_name + " " + userAuditor.last_name}
                  </div>
                </div>
                <div className="form-group div-form-participant">
                  <Field name="participant_type_id" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <label className="tipo-title">Tipo de auditor</label>
                        <Select
                          className="select-auditor"
                          {...input}
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => option.id}
                          options={participantType.elements.filter(
                            (e) => e.is_audit && e.id != 1
                          )}
                        />
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="form-group div-form-participant">
                  <input
                    type="submit"
                    className="form-control btn bg-orange white tipo-boton"
                    value="Añadir"
                  />
                </div>
              </div>
            )}
            {stateCreate.status === STATUS_EDIT && (
              <div className="content-edit-participant margin-control">
                <div className="form-group div-form-participant">
                  <label className="tipo-title">Nombre</label>
                  <div>
                    {participant.person.first_name +
                      " " +
                      participant.person.last_name}
                  </div>
                </div>
                <div className="form-group div-form-participant">
                  <Field name="participant_type_id" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <label className="tipo-title">Tipo de auditor</label>
                        <Select
                          {...input}
                          className="select-auditor"
                          placeholder={"Seleccione una opción"}
                          getOptionLabel={(option) => `${option.name}`}
                          getOptionValue={(option) => option.id}
                          options={participantType.elements.filter(
                            (e) => e.is_audit
                          )}
                        />
                        {meta.touched && meta.error && (
                          <span className="tipo-cursiva orange">
                            {meta.error}
                          </span>
                        )}
                      </>
                    )}
                  </Field>
                </div>
                <div className="form-group div-form-participant">
                  <input
                    type="submit"
                    className="form-control btn bg-orange white tipo-boton"
                    value="Guardar"
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default CreateParticipantAuditor;
