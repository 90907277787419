import React, { useContext } from "react";
import { ActionPlanFormContext } from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faAddressBook,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
const Cause = () => {
  const [
    { analyze, textFirstCause },
    { addCause, settextFirstCause },
  ] = useContext(ActionPlanFormContext);
  return (
    <div>
      <div className="form-group-zeus">
        <input
          /*   onBlur={(e) => {
            addCause({
              id: 0,
              name: e.target.value,
              analyze_id: analyze.analyze_id,
              cause_methos_id: null,
              cause_root: false,
            });
          }} */
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addCause({
                id: 0,
                name: e.target.value,
                analyze_id: analyze.analyze_id,
                cause_methos_id: null,
                cause_root: false,
              });
            }
          }}
          value={textFirstCause}
          onChange={(e) => settextFirstCause(e.target.value)}
          type="text"
          name="text"
          autoComplete="off"
          required
        />
        <label className="label-name-zeus">
          <span className="content-name-zeus">Nueva causa</span>
        </label>
      </div>
      <br />
      {analyze.element.causes.map((cause, index) => {
        return (
          <CauseRender
            index={index}
            key={cause.id}
            first={true}
            cause={cause}
          />
        );
      })}
    </div>
  );
};

const CauseRender = ({ index = null, first, cause }) => {
  const [
    { analyze, itemDeploy, stateNewSubCause, causeDrag },
    {
      addCause,
      setFieldCause,
      setDeploy,
      updateCause,
      newSubCause,
      deleteCause,
      setcauseDrag,
      inviertOrderCause,
    },
  ] = useContext(ActionPlanFormContext);

  const onDragStart = (event, cause, first, indexStart) => {
    cause.index = indexStart;
    event.dataTransfer.setData("cause", JSON.stringify(cause));
    setcauseDrag(cause);
  };
  const onDragOver = (event, cause) => {
    if (cause.id !== causeDrag.id) {
      if (event.target.classList.contains("panel-cause-analyze")) {
        event.target.classList.add("ondragOver-div-client-cause-analyze");
        event.preventDefault();
        return false;
      }
    }
  };
  const onDragLeave = (event, cause) => {
    if (cause.id !== causeDrag.id) {
      if (event.target.classList.contains("panel-cause-analyze")) {
        event.target.classList.remove("ondragOver-div-client-cause-analyze");
      }
    }
  };
  const onDrop = (event, cause, indexDrop) => {
    cause.index = indexDrop;
    
    event.target.classList.remove("ondragOver-div-client-cause-analyze");
    let causeDrag = JSON.parse(event.dataTransfer.getData("cause"));
  
    let elementCords = event.target.getBoundingClientRect();
    let percentage =
      ((event.clientY - elementCords.top) * 100) / elementCords.height;
    inviertOrderCause(causeDrag, cause, percentage);
  };
  return (
    <div
      onDrop={(event) => onDrop(event, cause, index)}
      onDragStart={(event) => onDragStart(event, cause, first, index)}
      onDragLeave={(event) => onDragLeave(event, cause, first)}
      draggable
      onDragOver={(event) => onDragOver(event, cause, first)}
    >
      <ol className={first ? "panel-cause-analyze" : ""}>
        <li>
          <div className="flex">
            <div className="container-circle-item flex">
              {cause.causes.length > 0 && (
                <div
                  onClick={() => setDeploy(cause.id)}
                  className={`${
                    itemDeploy["cause" + cause.id]
                      ? "circle-item-down-gray"
                      : "circle-item-up-gray"
                  } circle-number-items`}
                >
                  {" "}
                  {cause.causes.length}
                </div>
              )}
            </div>
            <div className="form-group-zeus">
              <input
                onBlur={(e) =>
                  updateCause({
                    name: e.target.value,
                    id: cause.id,
                    analyze_id: cause.analyze_id,
                    cause_method_id: cause.cause_method_id,
                    cause_root: cause.cause_root,
                  })
                }
                onChange={(e) =>
                  setFieldCause(cause.id, e.target.value, "name")
                }
                value={cause.name}
                type="text"
                name="text"
                autoComplete="off"
                required
              />
              <label className="label-name-zeus">
                <span className="content-name-zeus">
                  Ingrese la causa del problema
                </span>
              </label>
            </div>

            <div className="flex">
              <div className="flex">
                
                <Toggle
                  checked={cause.cause_root}
                  name="cause_root"
                  className="margin-auto mobile-hidden"
                  value="yes"
                  title={"Causa raiz"}
                  aria-label={"raiz"}
                  onChange={(e) => {
                    updateCause({
                      name: cause.name,
                      id: cause.id,
                      cause_root: !cause.cause_root,
                    });
                    setFieldCause(cause.id, !cause.cause_root, "cause_root");
                  }}
                  icons={{
                    unchecked: <FontAwesomeIcon icon={faAddressBook} />,
                  }}
                />

                <div
                  onClick={() => {
                    updateCause({
                      name: cause.name,
                      id: cause.id,
                      cause_root: !cause.cause_root,
                    });
                    setFieldCause(cause.id, !cause.cause_root, "cause_root");
                  }}
                  className=" hidden mobile-show toogle-mobile-cause-analyze"
                >
                  {cause.cause_root ? (
                    <FontAwesomeIcon
                      className="icon-root-cause-analyze orange"
                      icon={faCheckCircle}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="icon-root-cause-analyze"
                      icon={faCheckCircle}
                    />
                  )}
                </div>
              </div>
              <div
                className="btn-more-black background-simple btn-more-cause-analyze"
                onClick={() => {
                  addCause({
                    name: "",
                    analyze_id: cause.analyze_id,
                    cause_method_id: cause.id,
                  });
                }}
              />
              &nbsp;
              <img
                title="eliminar"
                className="cursor-action margin-auto"
                width="20"
                height="20"
                onClick={() => deleteCause(cause.id)}
                src={deleteIcon}
                alt="eliminar"
              />
            </div>
          </div>

          {itemDeploy["cause" + cause.id] && (
            <div className="form-group">
              {cause.causes.map((SubCause) => {
                return (
                  <CauseRender
                    key={SubCause.id}
                    first={false}
                    cause={SubCause}
                  />
                );
              })}
            </div>
          )}
        </li>
      </ol>
    </div>
  );
};
export default Cause;
