import React, { useEffect, useState, useContext } from "react";

import "../folder/Score.css";
const Score = ({ score }) => {
  const [valoration, setvaloration] = useState(0);

  useEffect(() => {
    if (score.length > 0) {
      let sumValue = 0;
      score.map(value => {
        sumValue = sumValue + parseInt(value.value);
      });
      setvaloration(sumValue / score.length);
    }
  }, [score]);
  return (
    <div className="content-stair ">
      {Array(5)
        .fill(1)
        .map((value, key) => {
          return (
            <span
              key={key}
              className={`stair-score   ${
                valoration > key ? "valorated" : "no-valorated"
              }`}
            ></span>
          );
        })}
    </div>
  );
};

export default Score;
