import React, { useState, useEffect, useContext } from "react";
import { EvaluationFormContext } from "../../../contexts/audit/evaluation/EvaluationFormContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconPlus from "../../../../src/img_zeus/icons_audit/ICONO-CREAR.png";
import iconDelete from "../../../../src/img_zeus/icons_library/BOTON-ELIMINAR.png";
import iconEdit from "../../../../src/img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import ImageUploader from "react-images-upload";
import iconAcept from "../../../img_zeus/ICONO-ACEPTAR.png";
import iconCancel from "../../../img_zeus/ICONO-CANCELAR.png";
import loadingImage from "../../../img_zeus/loading.gif";
import imgErrorDefault from "../../../img_zeus/circle-image.png";
import btn_generate from "../../../img_zeus/icons_audit/generate.png";
import moment from "moment";
import $ from "jquery";
import Select, { components } from "react-select";
import "../evaluation/Form.css";
import Draggable from "react-draggable";
import {
  faChevronUp,
  faTimesCircle,
  faChevronDown,
  faMinusCircle,
  faEdit,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { STATUS_LOADED } from "../../../contexts/audit/AuditContext";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import {
  STATUS_LOADING,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";

const STATUS_CREATED = 1;
const STATUS_PLANNED = 2;
const STATUS_INPROGRESS = 3;
const STATUS_APPROVED = 7;
const STATUS_EXECUTED = 4;
const ROLE_OBSERVATION = "evaluation_observation";
const MAX_FILE_SIZE = 80;
const EXT_PDF = "pdf";
const EXT_MP4 = "mp4";
const EXT_MOV = "mov";
const EXT_JPG = "jpg";
const EXT_JPEG = "jpeg";
const EXT_PNG = "png";
const EXT_MP3 = "mp3";

const EXT_DOCX = "docx";
const EXT_DOC = "doc";

const EXT_XLSX = "xlsx";
const EXT_XLS = "xls";

const initialState = {
  message: {
    document: [],
    note: "",
  },
  item: {
    new_item: {
      title: "",
      standard_id: null,
      auditable_item_id: null,
    },
    item_edit: {
      id: null,
      title: "",
      auditable_item_id: null,
    },
    new_subItem: {
      id: null,
      title: "",
      auditable_item_id: null,
    },
  },
  itemEdit: {
    item_id: null,
    item_type_id: null,
    title: "",
  },
  notes: [],
  newResponse: {
    response: {
      item_id: null,
      option_id: "",
    },
    picture: {
      image: [],
      item_id: null,
    },
    note: {
      note: "",
      item_id: null,
    },
    commentEdit: {
      note_id: null,
      note: "",
      item_id: null,
    },
  },
  showNote: "",
  itemsDeploy: {},
};
const downloadDocument = (ref) => {
  window.open(ref, "_blank");
};
const getPercentage = (item) => {
  let data = {
    calificables: 0,
    calificados: 0,
    documents: 0,
    notes: 0,
    fullfill: 0,
    not_fullfill: 0,
    not_apply: 0,
    percentage: 0,
  };
  if (item.auditable_item) {
    if (item.auditable_item.required) {
      data.calificables = data.calificables + 1;
      if (item.results.length > 0) {
        if (item.results[0].is_valid == true) {
          data.fullfill = data.fullfill + 1;
        }
        if (item.results[0].is_valid == false) {
          data.not_fullfill = data.not_fullfill + 1;
        }
        if (item.results[0].is_valid == null) {
          data.not_apply = data.not_apply + 1;
        }

        data.calificados = data.calificados + 1;
      }
      data.notes = data.notes + item.notes.length;
      data.documents = data.documents + item.documents.length;
    }
  }
  if (item.items.length > 0) {
    for (let i = 0; i < item.items.length; i++) {
      recursivePercentage(item.items[i], data);
    }
  }
  data.percentage =
    data.calificados == 0 && data.calificables == 0
      ? 0
      : Math.round((data.calificados * 100) / data.calificables);

  return data;
};
const recursivePercentage = (item, data) => {
  if (item.auditable_item && item.auditable_item.required) {
    data.calificables = data.calificables + 1;
    if (item.results.length > 0) {
      if (item.results[0].is_valid == true) {
        data.fullfill = data.fullfill + 1;
      }
      if (item.results[0].is_valid == false) {
        data.not_fullfill = data.not_fullfill + 1;
      }
      if (item.results[0].is_valid == null) {
        data.not_apply = data.not_apply + 1;
      }
      data.calificados = data.calificados + 1;
    }
    data.notes = data.notes + item.notes.length;
    data.documents = data.documents + item.documents.length;
  }
  if (item.items.length > 0) {
    for (let i = 0; i < item.items.length; i++) {
      data = recursivePercentage(item.items[i], data);
    }
  }
  return data;
};

const Form = ({ computedMatch }) => {
  const [
    {
      evaluation,
      itemsRemote,
      loadDocument,
      loadObservation,
      scroll,
      template,
    },
    {
      changeEvaluation,
      addObservation,
      addDocumentItem,
      deleteObservation,
      setscroll,
      transformEvaluationToTemplate,
    },
  ] = useContext(EvaluationFormContext);
  const [{ user }, {}] = useContext(SecurityContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [itemDeploy, setItemDeploy] = useState(initialState.itemsDeploy);
  const [editItems, setEditItems] = useState(false);
  const [toogleObservations, settoogleObservations] = useState(false);
  const [observation, setobservation] = useState(initialState.message);
  const [toggleObservationMobile, settoggleObservationMobile] = useState(false);
  const [picture, setPicture] = useState(initialState.newResponse.picture);
  const [selectTemplate, setselectTemplate] = useState(null);
  const { params, url, path } = computedMatch;
  let { evaluation_id } = params;
  let { items } = evaluation.element;
  const [isExpress, setisExpress] = useState(false);

  let history = useHistory();
  evaluation_id = parseInt(evaluation_id);
  useEffect(() => {
    changeEvaluation(evaluation_id);
  }, [evaluation_id]);
  useEffect(() => {
    if (evaluation.status == STATUS_LOADED) {
      $("#content-wrapper").scrollTop(scroll);
      if (evaluation.element.audit[0].is_express) {
        setisExpress(true);
      } else {
        let isEvaluatorOrEvaluated = false;
        for (
          let p = 0;
          p < evaluation.element.activity.audit_participants.length;
          p++
        ) {
          const participant = evaluation.element.activity.audit_participants[p];
          if (participant.person_id == user.id) {
            isEvaluatorOrEvaluated = true;
          }
        }

        if (!isEvaluatorOrEvaluated || evaluation.element.activity.is_closed) {
          history.push("/app/unauthorized");
        }
      }
    }
  }, [evaluation]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);
  useEffect(() => {
    if (picture.item_id != null && picture.image.length > 0) {
      addDocumentItem(picture);
      setPicture(initialState.newResponse.picture);
    }
  }, [picture]);
  const hasResults = () => {
    if (evaluation.status === STATUS_LOADED) {
      let decision = recuriveItemsResults(false, evaluation.element.items);
      return decision;
    }
  };
  const handleScroll = () => {
    let scroll = $("#content-wrapper").scrollTop();
    if (scroll != 0) {
      setscroll(scroll);
    }
  };
  const setDeploy = (item_id) => {
    if (itemDeploy["item" + item_id]) {
      setItemDeploy({
        ...itemDeploy,
        ["item" + item_id]: !itemDeploy["item" + item_id],
      });
    } else {
      setItemDeploy({ ...itemDeploy, ["item" + item_id]: true });
    }
  };

  const recuriveItemsResults = (decision, items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].results.length > 0) {
        decision = true;
      }
      if (items[i].items != "") {
        if (recuriveItemsResults(decision, items[i].items)) {
          decision = true;
        }
      }

      return decision;
    }
    return decision;
  };
  const addObservationToEvaluation = () => {
    addObservation(observation, ROLE_OBSERVATION);
    setobservation(initialState.message);
  };
  const uploadDocumentObservation = (e) => {
    let file = e.target.files[0];
    if (file) {
      let mb = Math.round(file.size / 1024 / 1024);
      if (mb > MAX_FILE_SIZE) {
        alertWarning("El tamaño excede la capacidad");
        return false;
      }
      var basename = file.name.split(/[\\/]/).pop();
      var pos = basename.lastIndexOf(".");
      var typeFile = basename.slice(pos + 1).toLowerCase();
      file.ext_gui = typeFile;
      setobservation({
        ...observation,
        document: [...observation.document, file],
      });
    }
  };
  const isAudited = (user_id, activity) => {
    if (!evaluation.element.audit[0].is_express) {
      let isAudited = false;
      for (let p = 0; p < activity.audit_participants.length; p++) {
        if (
          activity.audit_participants[p].pivot.is_evaluator == false &&
          user_id == activity.audit_participants[p].person_id
        ) {
          isAudited = true;
        }
      }
      return isAudited;
    }
  };
  const imEvaluator = (activity) => {
    if (!evaluation.element.audit[0].is_express) {
      let isEvaluator = false;
      for (let p = 0; p < activity.audit_participants.length; p++) {
        if (
          activity.audit_participants[p].pivot.is_evaluator == true &&
          user.id == activity.audit_participants[p].person_id
        ) {
          isEvaluator = true;
        }
      }
      return isEvaluator;
    }
  };
  const uploadImage = (item_id) => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_FILE_SIZE) {
      alertWarning("El tamaño excede la capacidad");
      return false;
    }

    setPicture({
      ...picture,
      image: [...picture.image, file],
      item_id: item_id,
    });
  };
  const validateDeleteObservation = (observation_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta observación?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteObservation(observation_id, evaluation.evaluation_id);
      } else {
      }
    });
  };

  return (
    <>
      <div className="general-content-evaluation-show">
        {toogleObservations && (
          <div className="back-document">
            <div className="flex  container-general-observations">
              <div className="flex header-modal-observations">
                <div
                  onClick={() => settoogleObservations(!toogleObservations)}
                  className="salir-company"
                ></div>
                <div className="w-100 txt-center">
                  <h3 className="tipo-description ">Observaciones</h3>
                </div>
              </div>
              <div className="container-content-modal-observation">
                <div className="content-modal-observations margin-control">
                  <div className="container-notes-evaluations-mobile flex-column w-100">
                    <div className="flex-1 flex container-input-mobile-observation">
                      <div className="flex-column flex flex-1">
                        <textarea
                          className="textarea-no-border  tipo-cursiva flex flex-1"
                          value={observation.note}
                          onChange={(e) =>
                            setobservation({
                              ...observation,
                              note: e.target.value,
                            })
                          }
                          type="text"
                        />
                        <div className="flex container-documents-desktop-observations-evaluation">
                          {observation.document.map((document, key) => {
                            return (
                              <div title={document.name} key={key}>
                                {(document.ext_gui == EXT_JPG ||
                                  document.ext_gui == EXT_JPEG ||
                                  document.ext_gui == EXT_PNG) && (
                                  <div className="cursor-action btn-img-observation btn-files-observations"></div>
                                )}
                                {document.ext_gui == EXT_PDF && (
                                  <div className="cursor-action btn-pdf-observation btn-files-observations"></div>
                                )}
                                {(document.ext_gui == EXT_DOCX ||
                                  document.ext_gui == EXT_DOC) && (
                                  <div className="cursor-action btn-word-observation btn-files-observations"></div>
                                )}
                                {document.ext_gui == EXT_MP3 && (
                                  <div className="cursor-action btn-mp3-observation btn-files-observations"></div>
                                )}
                                {(document.ext_gui == EXT_MP4 ||
                                  document.ext_gui == EXT_MOV) && (
                                  <div className="cursor-action btn-mp4-observation btn-files-observations"></div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {loadObservation.status == STATUS_LOADED && (
                        <div className="flex">
                          <input
                            onChange={uploadDocumentObservation}
                            type="file"
                            name="file"
                            id="file"
                            className="inputfile-final-report"
                          />
                          <label
                            className="background-simple label-file-observation-mobile"
                            htmlFor="file"
                          ></label>
                        </div>
                      )}

                      {loadObservation.evaluation_id ==
                        evaluation.evaluation_id &&
                        loadObservation.status == STATUS_LOADING && (
                          <img
                            src={loadingImage}
                            className="image-loading-item-desktop"
                          />
                        )}
                    </div>
                    <div
                      className="btn-send-observation-mobile cursor-action background-simple"
                      onClick={() => addObservationToEvaluation()}
                    ></div>
                  </div>
                  <div className="container-observations-modal">
                    {evaluation.status === STATUS_LOADED &&
                      evaluation.element.observations.map(
                        (observation, index) => {
                          return (
                            <div
                              className="content-observation-modal tipo-description flex flex-1"
                              key={index}
                            >
                              <div className="flex flex-1 flex-column">
                                {evaluation.status == STATUS_LOADED && (
                                  <>
                                    {user.id == observation.person.id &&
                                      evaluation.element.audit[0].status_id !=
                                        STATUS_EXECUTED && (
                                        <div className="flex justify-end">
                                          {" "}
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              validateDeleteObservation(
                                                observation.id
                                              )
                                            }
                                            className="flex cursor-action"
                                            icon={faMinusCircle}
                                          />
                                        </div>
                                      )}
                                  </>
                                )}

                                <p>{observation.note}</p>
                                <div className="flex container-documents-desktop-observations-evaluation">
                                  {observation.documents.map((document) => {
                                    return (
                                      <div key={document.id}>
                                        <Document
                                          key={document.id}
                                          document={document}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <div className="flex container-date-desktop-observation">
                                  <p className="tipo-cursiva date-observation-evaluation">
                                    {moment(observation.created_at).format(
                                      "YYYY-MM-DD HH:mm a"
                                    )}
                                  </p>
                                  <p className="date-observation-evaluation">
                                    {observation.person.first_name}&nbsp;
                                    {observation.person.last_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <nav
          aria-label="breadcrumb"
          className="flex-1 breadcrumb-show-standard cursor-action"
        >
          <ol className="breadcrumb black">
            <li className="breadcrumb-item">
              {evaluation.status === STATUS_LOADED && (
                <>
                  {evaluation.element.audit[0].is_express && (
                    <Link to={`/app/audit_express/audits`}>
                      {evaluation.element.audit[0].title}
                    </Link>
                  )}
                  {!evaluation.element.audit[0].is_express && (
                    <Link to={`/app/audit/${evaluation.element.audit[0].id}`}>
                      {evaluation.element.audit[0].title}
                    </Link>
                  )}
                </>
              )}
            </li>
            <li className="breadcrumb-item orange tipo-description">
              <strong className="bread-evaluation-form-desktop">
                Evaluación: {evaluation.element.title}
              </strong>
            </li>
          </ol>
        </nav>
        <div className=" back-gray-content">
          <h3 className="tipo-title title-evaluation-header-form ">
            EVALUACIÓN{" "}
          </h3>
          <div className="bg-white">
            <div className="txt-center bg-orange white title-show-evaluation">
              {evaluation.status === STATUS_LOADED &&
                isAudited(user.id, evaluation.element.activity) &&
                "Carga de documentos"}
              {evaluation.status === STATUS_LOADED &&
                !isAudited(user.id, evaluation.element.activity) &&
                evaluation.element.title}
            </div>

            {evaluation.status === STATUS_LOADED &&
              (!isAudited(user.id, evaluation.element.activity) ||
                imEvaluator(evaluation.element.activity)) && (
                <div className="content-items-evaluation">
                  <div className="flex">
                    <div className="flex  margin-control">
                      <label className="">Fecha de creación</label>
                      &nbsp;&nbsp;
                      <div className=" input-basic-white">
                        {evaluation.element.created_at}
                      </div>
                    </div>

                    <div className="flex  margin-control">
                      <label className="">Responsable</label>&nbsp;&nbsp;
                      <div className="w-100 input-basic-white">
                        {evaluation.element.person_evaluation &&
                          evaluation.element.person_evaluation.first_name}{" "}
                        {evaluation.element.person_evaluation &&
                          evaluation.element.person_evaluation.last_name}
                      </div>
                    </div>
                    <div className="flex flex-1 margin-control">
                      <label>Descripción</label>&nbsp;&nbsp;
                      <div className="w-100 input-basic-white">
                        {evaluation.element.description}
                      </div>
                    </div>
                  </div>
                  <div className="content-header-evaluation flex">
                    <div className="flex-1 flex container-note-evaluation">
                      <div className="flex-1 flex justify-end">
                        {evaluation.status === STATUS_LOADED &&
                          evaluation.element.audit[0].status_id ===
                            STATUS_INPROGRESS && (
                            <div
                              onClick={() =>
                                settoogleObservations(!toogleObservations)
                              }
                              className="btn-note-evaluation"
                            >
                              <div>
                                {evaluation.status === STATUS_LOADED
                                  ? evaluation.element.observations.length
                                  : 0}
                              </div>
                            </div>
                          )}
                      </div>
                      {user.is_admin && (
                        <div className="flex-1 flex">
                          {!hasResults() && (
                            <>
                              <div
                                onClick={() => setEditItems(!editItems)}
                                className="btn-orange-default"
                              >
                                <span>
                                  {editItems
                                    ? "Modo evaluación"
                                    : "Modo edición"}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {template.elements.length > 0 && (
                        <div className="container-select-template-evaluation flex-1">
                          <Select
                            className="tipo-description"
                            placeholder={"Seleccione plantilla"}
                            getOptionLabel={(option) => `${option.title}`}
                            getOptionValue={(option) => option.id}
                            options={template.elements}
                            value={selectTemplate}
                            onChange={(e) => setselectTemplate(e)}
                          />
                          {selectTemplate !== null && (
                            <div
                              className="btn-orange-default"
                              onClick={() =>
                                transformEvaluationToTemplate({
                                  evaluation_id: evaluation.element.id,
                                  template_id: selectTemplate.id,
                                })
                              }
                            >
                              tranformar
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="content-items-evaluation-margin">
                    {items && items.length > 0 && (
                      <>
                        {items[0].items.length == 0 && (
                          <ol>
                            <div className="flex">
                              <div className="flex-1"></div>
                              <div className="container-icons-circles  flex justify-end">
                                <div className="flex-1">
                                  <div className="icon-calificables-title icon-cumple-evaluation background-simple"></div>
                                </div>
                                <div className="flex-1">
                                  <div className="icon-calificables-title icon-no-cumple-evaluation background-simple"></div>
                                </div>
                                <div className="flex-1">
                                  <div className="icon-calificables-title icon-no-aplica-evaluation  background-simple"></div>
                                </div>
                                <div className="flex-2"></div>
                                <div className="flex-2"></div>
                              </div>
                            </div>
                          </ol>
                        )}
                      </>
                    )}
                    {items && (
                      <SubItems
                        first={true}
                        itemDeploy={itemDeploy}
                        setDeploy={setDeploy}
                        setEditItems={setEditItems}
                        editItems={editItems}
                        items={items}
                        hasResults={hasResults}
                      />
                    )}
                  </div>
                </div>
              )}

            {/*  ////////////////AUDITED REMOTE/////// */}

            {evaluation.status === STATUS_LOADED &&
              isAudited(user.id, evaluation.element.activity) &&
              !imEvaluator(evaluation.element.activity) && (
                <div className="content-items-evaluation">
                  <div className="flex">
                    {evaluation.status === STATUS_LOADED &&
                      !isAudited(user.id, evaluation.element.activity) && (
                        <div className="flex  margin-control">
                          <label className="">Fecha de creación</label>
                          &nbsp;&nbsp;
                          <div className=" input-basic-white">
                            {evaluation.element.created_at}
                          </div>
                        </div>
                      )}
                    <div className="flex  margin-control">
                      <label className="">
                        {" "}
                        {evaluation.status === STATUS_LOADED &&
                        !isAudited(user.id, evaluation.element.activity)
                          ? "Responsable"
                          : "Auditor"}
                      </label>
                      &nbsp;&nbsp;
                      <div className="w-100 input-basic-white">
                        {evaluation.element.person_evaluation &&
                          evaluation.element.person_evaluation.first_name}{" "}
                        {evaluation.element.person_evaluation &&
                          evaluation.element.person_evaluation.last_name}
                      </div>
                    </div>
                    <div className="flex flex-1 margin-control">
                      <label>Descripción</label>&nbsp;&nbsp;
                      <div className="w-100 input-basic-white">
                        {evaluation.element.description}
                      </div>
                    </div>
                  </div>
                  <div className="content-header-evaluation flex"></div>
                  <div className="content-items-evaluation-margin">
                    {itemsRemote.map((item) => (
                      <div
                        key={item.id}
                        className={`flex content-item-column  `}
                      >
                        <div className="container-circle-item flex"></div>

                        <div className="flex-1">
                          <div className="flex">
                            <div className="number-item-content">
                              {item.auditable_item && (
                                <p className="">{item.auditable_item.number}</p>
                              )}
                            </div>{" "}
                            <div className="flex-1 flex">
                              <div className="flex-1">
                                <p className="p-item-description">
                                  Cargar evidencia
                                </p>
                                <div className="flex">
                                  {item.documents.map((document, index) => {
                                    return (
                                      <div key={document.id}>
                                        {(document.type == EXT_JPG ||
                                          document.type == EXT_JPEG ||
                                          document.type == EXT_PNG) && (
                                          <div
                                            className="container-img-item-desktop flex "
                                            key={document.id}
                                          >
                                            <img
                                              onClick={() =>
                                                history.push(
                                                  `../../../app/library/document/${document.id}`
                                                )
                                              }
                                              src={
                                                process.env.REACT_APP_API_HOST +
                                                "/documents/" +
                                                document.name
                                              }
                                              onError={(e) => {
                                                e.target.src = imgErrorDefault;
                                              }}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                        {document.type == EXT_PDF && (
                                          <div
                                            className="container-img-item-desktop flex "
                                            key={document.id}
                                          >
                                            <div
                                              className="cursor-action btn-pdf-observation btn-files-observations"
                                              onClick={() =>
                                                history.push(
                                                  `../../../app/library/document/${document.id}`
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}

                                        {(document.type == EXT_DOCX ||
                                          document.type == EXT_DOC) && (
                                          <div
                                            className="container-img-item-desktop flex "
                                            key={document.id}
                                          >
                                            <div
                                              className="cursor-action btn-word-observation btn-files-observations"
                                              onClick={() =>
                                                downloadDocument(
                                                  process.env
                                                    .REACT_APP_API_HOST +
                                                    "documents/" +
                                                    document.name
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}

                                        {(document.type == EXT_XLSX ||
                                          document.type == EXT_XLS) && (
                                          <div
                                            className="container-img-item-desktop flex "
                                            key={document.id}
                                          >
                                            <div
                                              className="cursor-action btn-excell-observation btn-files-observations"
                                              onClick={() =>
                                                downloadDocument(
                                                  process.env
                                                    .REACT_APP_API_HOST +
                                                    "documents/" +
                                                    document.name
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}
                                        {document.type == EXT_MP3 && (
                                          <div
                                            className="container-img-item-desktop flex "
                                            key={document.id}
                                          >
                                            <div
                                              className="cursor-action btn-mp3-observation btn-files-observations"
                                              onClick={() =>
                                                history.push(
                                                  `../../../app/library/document/${document.id}`
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}
                                        {(document.type == EXT_MP4 ||
                                          document.type == EXT_MOV) && (
                                          <div
                                            className="container-img-item-desktop   flex "
                                            key={document.id}
                                          >
                                            <div
                                              className="cursor-action btn-mp4-observation btn-files-observations"
                                              onClick={() =>
                                                history.push(
                                                  `../../../app/library/document/${document.id}`
                                                )
                                              }
                                            ></div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="container-calificable flex justify-end">
                              <div className="flex-document-column-evaluation container-calificable-item-evaluation-desktop">
                                {loadDocument.status == STATUS_LOADED && (
                                  <div className="">
                                    <label
                                      title="Cargar evidencia"
                                      className="label-input-file-evaluation-report-desktop"
                                      htmlFor="upload"
                                    >
                                      <div className="orange container-number-documents-evaluation tipo-cursiva">
                                        {item.documents.length}
                                      </div>
                                    </label>
                                    <input
                                      onChange={uploadImage(item.id)}
                                      type="file"
                                      name="file"
                                      id="file"
                                      title="Cargar evidencia"
                                      className="input-file-evaluation-report-desktop cursor-action"
                                    />
                                  </div>
                                )}
                                {loadDocument.item_id == item.id &&
                                  loadDocument.status == STATUS_LOADING && (
                                    <img
                                      src={loadingImage}
                                      className="image-loading-item-desktop"
                                    />
                                  )}
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      {/* ////////////////////////DEVICE MOBILE//////////////////// */}

      {toggleObservationMobile && (
        <div className="back-document flex">
          <div className="modal-observation-evaluation-mobile flex flex-1 flex-column">
            <div className="header-modal-observation-evaluation-mobile w-100 flex ">
              <div
                onClick={() =>
                  settoggleObservationMobile(!toggleObservationMobile)
                }
                className="btn-close-modal-observation-mobile flex margin-auto"
              ></div>
              <p className="flex flex-1 justify-center">
                Observaciones generales
              </p>
            </div>
            <div className="content-modal-observation-evaluation-mobile bg-white flex flex-column">
              <div className="flex flex-column content-into-notes-evaluation-mobile">
                {evaluation.status === STATUS_LOADED &&
                  evaluation.element.observations.map((observation, index) => {
                    return (
                      <div
                        className="content-observation-modal tipo-description flex flex-1"
                        key={index}
                      >
                        <div className="flex flex-1 flex-column">
                          <p>{observation.note}</p>
                          <p className="tipo-cursiva date-observation-evaluation">
                            {moment(observation.created_at).format(
                              "YYYY-MM-DD HH:mm a"
                            )}
                          </p>
                        </div>
                        <div className="flex">
                          {observation.documents.map((document) => {
                            return (
                              <Document key={document.id} document={document} />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="container-notes-evaluations-mobile flex-volumn w-100">
                <div className="flex-1 flex container-input-mobile-observation">
                  <input
                    className="tipo-cursiva flex flex-1"
                    value={observation.note}
                    onChange={(e) =>
                      setobservation({ ...observation, note: e.target.value })
                    }
                    type="text"
                  />
                  {loadObservation.status == STATUS_LOADED && (
                    <div>
                      <input
                        onChange={uploadDocumentObservation}
                        type="file"
                        name="file"
                        id="file"
                        className="input-file-observation-mobile"
                      />
                      <label
                        className="background-simple label-file-observation-mobile"
                        htmlFor="file"
                      ></label>
                    </div>
                  )}
                </div>
                {loadObservation.evaluation_id == evaluation.evaluation_id &&
                  loadObservation.status == STATUS_LOADING && (
                    <div className="flex-1 flex container-input-mobile-observation">
                      <img
                        src={loadingImage}
                        className="image-loading-item-desktop"
                      />
                    </div>
                  )}

                <div
                  className="btn-send-observation-mobile background-simple"
                  onClick={() => addObservationToEvaluation()}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white general-content-evaluation-show-mobile hidden">
        <div className="flex ">
          <p className="flex-1 tipo-titulo">{evaluation.element.title}</p>
          {evaluation.status === STATUS_LOADED &&
            imEvaluator(evaluation.element.activity) && (
              <div
                onClick={() =>
                  settoggleObservationMobile(!toggleObservationMobile)
                }
                className="background-simple btn-observation-show-evaluation-mobile icon-notes"
              ></div>
            )}
        </div>
        {evaluation.status === STATUS_LOADED &&
          (!isAudited(user.id, evaluation.element.activity) ||
            imEvaluator(evaluation.element.activity)) && (
            <div className="container-items-evaluation-mobile">
              {items && (
                <SubItemsMobile
                  itemDeploy={itemDeploy}
                  first={true}
                  items={items}
                  setDeploy={setDeploy}
                  hasResults={hasResults}
                />
              )}
            </div>
          )}

        {/*        ///////AUDIT REMOTE MOBILE///////////////// */}
        {evaluation.status === STATUS_LOADED &&
          isAudited(user.id, evaluation.element.activity) && (
            <div className="container-items-evaluation-mobile">
              {items && (
                <SubItemsMobile
                  itemDeploy={itemDeploy}
                  first={true}
                  items={itemsRemote}
                  setDeploy={setDeploy}
                  hasResults={hasResults}
                />
              )}
            </div>
          )}
      </div>
    </>
  );
};
const SubItems = ({
  setDeploy,
  itemDeploy,
  first,
  items,
  editItems,
  setEditItems,
  hasResults,
}) => {
  let history = useHistory();
  const [
    { evaluation, loadDocument, itemsRemote, noteEdit, inTransformation },
    {
      addItem,
      updateItem,
      addDocumentItem,
      addResponseItem,
      deleteItem,
      addNoteItem,
      deleteNote,
      updateNote,
      deleteDocument,
      activeItem,
      resetEvaluation,
      setnoteEdit,
      updateNoteItem,
      generatePlanItem,
    },
  ] = useContext(EvaluationFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [editItem, seteditItem] = useState(initialState.itemEdit);
  const [response, setResponse] = useState(initialState.newResponse.response);
  const [picture, setPicture] = useState(initialState.newResponse.picture);
  const [note, setNote] = useState(initialState.newResponse.note);
  const [notes, setNotes] = useState(initialState.notes);
  const [showNote, setShowNote] = useState(initialState.showNote);
  const [stateItemEdit, setstateItemEdit] = useState(
    initialState.item.item_edit
  );
  const [newComment, setNewComment] = useState(
    initialState.newResponse.commentEdit
  );
  const [stateNewSubItem, setstateNewSubItem] = useState(
    initialState.item.new_subItem
  );

  const inEditComment = (note_id, note, item_id) => {
    setNewComment({
      newComment,
      note_id: note_id,
      item_id: item_id,
      note: note,
    });
  };
  let newSubItem = (item_id, title) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      id: item_id,
      title: title,
      auditable_item_id: item_id,
    });
  };

  let newEdit = (item_id, title, number) => {
    setstateItemEdit({
      ...stateItemEdit,
      id: item_id,
      title: title,
    });
  };
  const resetComment = () => {
    setNewComment(initialState.newResponse.commentEdit);
  };
  const checkEditComment = () => {
    updateNote(newComment);
    resetComment();
  };
  const validateId = (item) => {
    alert(item);
  };
  const uploadImage = (item_id) => (e) => {
    let file = e.target.files[0];
    let mb = Math.round(file.size / 1024 / 1024);
    if (mb > MAX_FILE_SIZE) {
      alertWarning("El tamaño excede la capacidad");
      return false;
    }

    setPicture({
      ...picture,
      image: [...picture.image, file],
      item_id: item_id,
    });
  };
  const changeOption = (item_id, option_id) => {
    setResponse({ ...response, option_id: option_id, item_id: item_id });
  };
  const setNewNote = (item_id) => (e) => {
    setNote({ ...note, note: e.target.value, item_id: item_id });
  };
  const resetNewNote = () => {
    setNote(initialState.newResponse.note);
  };
  const checkAddItem = () => {
    addItem(editItem);
    resetInput();
  };
  const resetInput = () => {
    seteditItem({ ...editItem, title: "", item_id: null });
  };
  const shortText = (description, range) => {
    if (description && description.length > range) {
      return description.substr(0, range) + "...";
    }
    return description;
  };
  useEffect(() => {
    if (picture.item_id != null && picture.image.length > 0) {
      addDocumentItem(picture);
      setPicture(initialState.newResponse.picture);
    }
  }, [picture]);
  useEffect(() => {
    if (response.item_id != null && response.option_id != "") {
      addResponseItem(response);
    }
  }, [response]);
  useEffect(() => {
    if (note.item_id !== null) {
      let filter = items.filter((e) => e.id == note.item_id)[0];
      if (filter) {
        setNotes(filter.notes);
      }
    }
  }, [note.item_id, items]);
  let setFieldItemEdit = (field) => (e) => {
    setstateItemEdit({
      ...stateItemEdit,
      [field]: e.target.value,
    });
  };
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };
  const defaultValueSelect = (result) => {
    return result.length > 0 ? result[0].id : "";
  };
  const validateCalificable = (results, option) => {
    if (results.length > 0) {
      if (results[0].is_valid == true && option.is_valid == true) {
        return "icon-cumple-evaluation";
      } else if (results[0].is_valid == false && option.is_valid == false) {
        return "icon-no-cumple-evaluation";
      } else if (results[0].is_valid == null && option.is_valid == null) {
        return "icon-no-aplica-evaluation";
      } else {
        return "icon-no-calificable-evaluation";
      }
    } else {
      return "icon-no-calificable-evaluation";
    }
  };
  const validateItemRequired = (item) => {
    if (item.auditable_item_id === null) {
      return false;
    } else {
      if (item.auditable_item) {
        return item.auditable_item.required;
      } else {
        return true;
      }
    }
  };
  const checkAddNote = () => {
    addNoteItem(note);
  };
  let setFieldSubItemEdit = (field) => (e) => {
    setstateNewSubItem({
      ...stateNewSubItem,
      [field]: e.target.value,
    });
  };
  const getStatusNumberItems = (item) => {
    let calificables = getRecursiveNumberCalificables(true, 0, item);
    let calificados = getRecurciveNumberResult(true, 0, item);
    return calificados + "/" + calificables;
  };
  const getRecursiveNumberCalificables = (first, number, item) => {
    if (item.auditable_item_id != null && item.auditable_item != "") {
      if (item.auditable_item.required && first == true) {
        number = number + 1;
      }
    }
    item.items.map((item) => {
      if (item.auditable_item_id !== null && item.auditable_item != "") {
        if (item.auditable_item.required) {
          number = number + 1;
          number = getRecursiveNumberCalificables(false, number, item);
        }
      }
    });
    return number;
  };
  const getRecurciveNumberResult = (first, number, item) => {
    if (item.results != "" && first) {
      number = number + 1;
    }
    item.items.map((item) => {
      if (item.auditable_item_id !== null && item.auditable_item != "") {
        if (item.auditable_item.required) {
          if (item.results != "") {
            number = number + 1;
          }
        }
      }

      number = getRecurciveNumberResult(false, number, item);
    });
    return number;
  };
  const enableRemoteItem = (enable, item_id_enable) => {
    activeItem(
      enable,
      evaluation.element.audit[0].id,
      evaluation.element.id,
      item_id_enable
    );
  };
  const itemActive = (item_id) => {
    let isActive = false;
    for (let i = 0; i < itemsRemote.length; i++) {
      const item = itemsRemote[i];
      if (item.id == item_id) {
        isActive = true;
      }
    }

    return isActive;
  };
  const validateDelete = (note_id) => {
    Swal.fire({
      title: "¿Realmente deseas eliminar esta nota?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        deleteNote(note_id, note.item_id);
      } else {
      }
    });
  };
  const checkEnableItem = (item_id) => {
    let isEnable = itemActive(item_id);
    enableRemoteItem(!isEnable, item_id);
    if (isEnable) {
      resetEvaluation();
    }
  };
  return (
    <>
      {inTransformation === STATUS_LOADING && (
        <div className="flex back-document">
          <div className="margin-auto comtainer-loading-evaluation-audit">
            <div className="flex-column">
              <div className="margin-auto background-simple img-loading-evaluations"></div>
              Transformando evaluación...
            </div>
          </div>
        </div>
      )}
      <div className="general-content-evaluation-show">
        {note.item_id !== null && (
          <Draggable handle=".handle">
            <div
              className={`shadow-tarjet ${
                showNote !== "" ? "bg-gray-note-show" : ""
              }container-observer-evaluation-desktop container-observer-evaluation flex`}
            >
              <div className="flex-wrap w-100">
                {/* ver nota */}
                {showNote !== "" && (
                  <div className="flex-1 flex">
                    <div
                      onClick={() => setShowNote("")}
                      className="icon-back-note"
                    ></div>
                    <p
                      onClick={() => setShowNote("")}
                      className="tipo-description cursor-action"
                    >
                      {" "}
                      Atrás
                    </p>
                  </div>
                )}

                <div className="show-note-text-item flex-1 tipo-description">
                  {showNote}
                </div>
                {/* ver notas */}
                {showNote === "" && (
                  <>
                    <div className="w-100 container-notes-observer">
                      <div className="handle container-close-note flex justify-end">
                        <span
                          onClick={() => resetNewNote()}
                          className="cursor-action"
                        >
                          x
                        </span>{" "}
                      </div>
                      <div className="content-notes-evaluation">
                        {notes && (
                          <>
                            {notes &&
                              notes.map((note) => {
                                return (
                                  <div
                                    /*  onClick={() => setShowNote(note.note)} */
                                    className="bg-white  tipo-description note-evaluation-item flex flex-column"
                                    key={note.id}
                                  >
                                    <div className="flex tipo-cursiva date-note-desktop-item">
                                      <span>
                                        {moment(note.created_at).format(
                                          "YYYY-MM-DD HH:mm a"
                                        )}
                                      </span>

                                      {evaluation.status == STATUS_LOADED &&
                                        !evaluation.element.activity
                                          .is_closed && (
                                          <div className="flex">
                                            {noteEdit.id != note.id && (
                                              <FontAwesomeIcon
                                                onClick={() =>
                                                  validateDelete(note.id)
                                                }
                                                className="flex"
                                                title="Eliminar"
                                                icon={faMinusCircle}
                                              />
                                            )}
                                            &nbsp;&nbsp;
                                            {noteEdit.id != note.id && (
                                              <FontAwesomeIcon
                                                onClick={() =>
                                                  setnoteEdit({
                                                    note: note.note,
                                                    id: note.id,
                                                  })
                                                }
                                                className="flex"
                                                title="Editar"
                                                icon={faEdit}
                                              />
                                            )}
                                          </div>
                                        )}
                                    </div>
                                    {noteEdit.id == note.id && (
                                      <textarea
                                        value={noteEdit.note}
                                        onChange={(e) =>
                                          setnoteEdit({
                                            ...noteEdit,
                                            note: e.target.value,
                                          })
                                        }
                                      ></textarea>
                                    )}
                                    {noteEdit.id == note.id && (
                                      <div
                                        onClick={() =>
                                          updateNoteItem(note.item_id)
                                        }
                                        className="btn-add-note-evaluation cursor-action background-simple"
                                      ></div>
                                    )}
                                    {noteEdit.id != note.id && note.note}
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>

                      {!notes && <div className="txt-center">No hay notas</div>}
                    </div>
                    <div className="flex-1  container-border-note-evaluation">
                      <div className="flex ">
                        <label htmlFor="">Notas</label>
                        <div className="flex">
                          <div
                            onClick={() => checkAddNote()}
                            className="btn-add-note-evaluation cursor-action background-simple"
                          ></div>
                        </div>
                      </div>

                      <textarea
                        onChange={(e) =>
                          setNote({ ...note, note: e.target.value })
                        }
                        className="w-100 input-new-note-evaluation tipo-cursiva"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Draggable>
        )}

        {items.map((item) => {
          return (
            <ol key={item.id}>
              <li
                className={`${
                  itemDeploy["item" + item.id] && item.items != ""
                    ? "dotted-li"
                    : ""
                }`}
              >
                <div
                  className={`flex content-item-column ${
                    first && !validateItemRequired(item)
                      ? `content-item-orange white`
                      : `bg-gray-2`
                  } `}
                >
                  <div className="container-circle-item flex">
                    {item.items.length > 0 && (
                      <div
                        onClick={() => setDeploy(item.id)}
                        className={`${
                          itemDeploy["item" + item.id]
                            ? "circle-item-down-gray"
                            : "circle-item-up-gray"
                        } circle-number-items`}
                      >
                        {" "}
                        {item.items.length}
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <div className="flex">
                      {stateItemEdit.id !== item.id && (
                        <>
                          <div className="number-item-content">
                            {item.auditable_item && (
                              <p className="">
                                {item.auditable_item.number}
                                <br />
                                {item.auditable_item.required && (
                                  <div
                                    className="btn-analyze-item-evaluation cursor-action"
                                    onClick={() => generatePlanItem(item)}
                                    title="generar analisis"
                                  >
                                    {" "}
                                    <img width="23" src={btn_generate}></img>
                                  </div>
                                )}
                             
                                {evaluation.status === STATUS_LOADED &&
                                  evaluation.element.audit[0].status ===
                                    STATUS_INPROGRESS && (
                                    <>
                                      {item.items != "" && (
                                        <div
                                          className={`white ${
                                            getRecursiveNumberCalificables(
                                              true,
                                              0,
                                              item
                                            ) ===
                                            getRecurciveNumberResult(
                                              true,
                                              0,
                                              item
                                            )
                                              ? "bg-success"
                                              : "bg-danger"
                                          }  content-number-auditable-evaluation`}
                                        >
                                          {getStatusNumberItems(item)}
                                        </div>
                                      )}
                                    </>
                                  )}{" "}
                              </p>
                            )}
                          </div>
                        </>
                      )}

                      <div className="flex-1 flex">
                        {stateItemEdit.id !== item.id && (
                          <div className="flex-1">
                            <p
                              className={`${
                                !item.auditable_item ? "tipo-description" : ""
                              } p-item-description`}
                            >
                              {item.title}
                            </p>
                            <div className="flex">
                              {item.documents.map((document, index) => {
                                return (
                                  <div key={document.id}>
                                    {(document.type == EXT_JPG ||
                                      document.type == EXT_JPEG ||
                                      document.type == EXT_PNG) && (
                                      <div
                                        className="container-img-item-desktop flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}

                                        <img
                                          className="cursor-action"
                                          onClick={() =>
                                            history.push(
                                              `../../../app/library/document/${document.id}`
                                            )
                                          }
                                          src={
                                            process.env.REACT_APP_API_HOST +
                                            "/documents/" +
                                            document.name
                                          }
                                          onError={(e) => {
                                            e.target.src = imgErrorDefault;
                                          }}
                                          alt=""
                                        />
                                      </div>
                                    )}
                                    {document.type == EXT_PDF && (
                                      <div
                                        className="container-img-item-desktop flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}

                                        <div
                                          className="cursor-action btn-pdf-observation btn-files-observations"
                                          onClick={() =>
                                            history.push(
                                              `../../../app/library/document/${document.id}`
                                            )
                                          }
                                        ></div>
                                      </div>
                                    )}

                                    {(document.type == EXT_DOCX ||
                                      document.type == EXT_DOC) && (
                                      <div
                                        className="container-img-item-desktop flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}
                                        <div
                                          className="cursor-action btn-word-observation btn-files-observations"
                                          onClick={() =>
                                            downloadDocument(
                                              process.env.REACT_APP_API_HOST +
                                                "documents/" +
                                                document.name
                                            )
                                          }
                                        ></div>
                                      </div>
                                    )}
                                    {(document.type == EXT_XLSX ||
                                      document.type == EXT_XLS) && (
                                      <div
                                        className="container-img-item-desktop flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}
                                        <div
                                          className="cursor-action btn-excell-observation btn-files-observations"
                                          onClick={() =>
                                            downloadDocument(
                                              process.env.REACT_APP_API_HOST +
                                                "documents/" +
                                                document.name
                                            )
                                          }
                                        ></div>
                                      </div>
                                    )}
                                    {document.type == EXT_MP3 && (
                                      <div
                                        className="container-img-item-desktop flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}
                                        <div
                                          className="cursor-action btn-mp3-observation btn-files-observations"
                                          onClick={() =>
                                            history.push(
                                              `../../../app/library/document/${document.id}`
                                            )
                                          }
                                        ></div>
                                      </div>
                                    )}
                                    {(document.type == EXT_MP4 ||
                                      document.type == EXT_MOV) && (
                                      <div
                                        className="container-img-item-desktop   flex "
                                        key={document.id}
                                      >
                                        {!evaluation.element.audit[0]
                                          .is_express &&
                                          !evaluation.element.activity
                                            .is_closed && (
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                checkDelete(
                                                  "¿Realmente deseas eliminar este archivo?",
                                                  `deleteDocument(${document.id},${item.id})`
                                                )
                                              }
                                              className="cursor-action"
                                              icon={faTimesCircle}
                                            />
                                          )}
                                        {evaluation.element.audit[0]
                                          .is_express && (
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              checkDelete(
                                                "¿Realmente deseas eliminar este archivo?",
                                                `deleteDocument(${document.id},${item.id})`
                                              )
                                            }
                                            className="cursor-action"
                                            icon={faTimesCircle}
                                          />
                                        )}

                                        <div
                                          className="cursor-action btn-mp4-observation btn-files-observations"
                                          onClick={() =>
                                            history.push(
                                              `../../../app/library/document/${document.id}`
                                            )
                                          }
                                        ></div>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {/* edit item */}
                        {stateItemEdit.id === item.id && (
                          <div className="flex-1 container-new-input-item">
                            <input
                              onChange={setFieldItemEdit("title")}
                              value={stateItemEdit.title}
                              className="input-basci-white form-control"
                              type="text"
                            ></input>
                          </div>
                        )}
                        {/* end edit item */}
                      </div>
                    </div>
                    {first && !validateItemRequired(item) && (
                      <div className="flex progress-container-item-desktop">
                        <div
                          style={{
                            width: getPercentage(item).percentage + "%",
                          }}
                          className="progress-data-item-desktop bg-orange"
                        ></div>
                      </div>
                    )}
                    {!first &&
                      item.auditable_item &&
                      !item.auditable_item.required && (
                        <div className="flex progress-container-item-desktop bg-white">
                          <div
                            style={{
                              width: getPercentage(item).percentage + "%",
                            }}
                            className="progress-data-item-desktop-sub"
                          ></div>
                        </div>
                      )}
                  </div>
                  {/* {icons title} */}
                  {evaluation.status === STATUS_LOADED && !editItems && (
                    <>
                      {!validateItemRequired(item) && item.auditable_item && (
                        <div className="container-icons-circles  flex justify-end">
                          <div className="flex-1 flex">
                            <div
                              className={`icon-calificables-title icon-cumple-evaluation background-simple`}
                            >
                              <div className="circle-number-evaluation border-color-apply">
                                {getPercentage(item).fullfill}
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 flex">
                            <div
                              className={`icon-calificables-title icon-no-cumple-evaluation background-simple`}
                            >
                              <div className="circle-number-evaluation border-color-not-cumple">
                                {getPercentage(item).not_fullfill}
                              </div>
                            </div>
                          </div>
                          <div className="flex-1 flex">
                            <div
                              className={`icon-calificables-title icon-no-aplica-evaluation  background-simple`}
                            >
                              <div className="circle-number-evaluation border-color-not-apply">
                                {getPercentage(item).not_apply}
                              </div>
                            </div>
                          </div>
                          <div className="flex-2 flex">
                            <div
                              className={`icon-calificables-title icon-observation-evaluation  background-simple`}
                            >
                              {" "}
                              <div className="circle-number-evaluation border-color-not-apply">
                                {getPercentage(item).notes}
                              </div>
                            </div>
                          </div>
                          <div className="flex-2 flex">
                            <div
                              className={`icon-calificables-title icon-evidence-evaluation  background-simple`}
                            >
                              {" "}
                              <div className="circle-number-evaluation border-color-orange">
                                {getPercentage(item).documents}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {/* {end icons title} */}

                  {/* EDIT EVALUATION */}
                  {hasResults && editItems && (
                    <>
                      <div className="container-actions-item flex">
                        <div className="container-margin-actions-item">
                          {/* buttons edit */}
                          {stateItemEdit.id === item.id && (
                            <>
                              &nbsp; &nbsp;
                              <img
                                onClick={() => {
                                  newEdit(null, "");
                                }}
                                className="cursor-action"
                                src={iconCancel}
                              />
                              &nbsp; &nbsp;
                              <img
                                onClick={() => {
                                  updateItem(stateItemEdit);
                                  newEdit(null, "");
                                }}
                                className="cursor-action"
                                src={iconAcept}
                              />
                            </>
                          )}
                          {/* end buttons edit */}
                          {stateItemEdit.id !== item.id && (
                            <>
                              {((item.auditable_item &&
                                item.auditable_item.required) ||
                                item.auditable_item_id === null) && (
                                <img
                                  onClick={() => {
                                    newSubItem(item.id, "");
                                  }}
                                  src={iconPlus}
                                />
                              )}

                              <img
                                onClick={() => {
                                  newEdit(item.id, item.title, item.number);
                                }}
                                src={iconEdit}
                              />
                              {!item.auditable_item && (
                                <img
                                  onClick={() =>
                                    checkDelete(
                                      "¿Realmente deseas eliminar este item?",
                                      `deleteItem(${item.id})`
                                    )
                                  }
                                  src={iconDelete}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {evaluation.status === STATUS_LOADED &&
                    evaluation.element.audit[0].status_id ===
                      STATUS_INPROGRESS &&
                    validateItemRequired(item) &&
                    item.auditable_item && (
                      <div className="flex">
                        <div
                          onClick={() => checkEnableItem(item.id)}
                          title={`${
                            itemActive(item.id)
                              ? "Deshabilitar carga de evidencia remota"
                              : "Habilitar carga de evidencia remota"
                          }`}
                          className={`icon-calificables ${
                            itemActive(item.id)
                              ? "icon-active-documents"
                              : "icon-inactive-documents"
                          } `}
                        ></div>
                      </div>
                    )}

                  {/* END EDIT EVALUATION */}

                  {evaluation.status === STATUS_LOADED &&
                    !editItems &&
                    validateItemRequired(item) && (
                      <div className="container-calificable  flex justify-end">
                        {evaluation.element.audit[0].status_id ===
                          STATUS_INPROGRESS && (
                          <>
                            {item.options &&
                              item.options.map((opt) => {
                                return (
                                  <div key={opt.id} className="flex-1 flex">
                                    <div
                                      onClick={() =>
                                        changeOption(item.id, opt.id)
                                      }
                                      className={`icon-calificables ${validateCalificable(
                                        item.results,
                                        opt
                                      )} `}
                                    ></div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                        {(evaluation.element.audit[0].status_id ===
                          STATUS_INPROGRESS ||
                          evaluation.element.audit[0].status_id ===
                            STATUS_APPROVED) && (
                          <div className="flex-2 flex">
                            <div
                              onClick={() =>
                                setNote({ ...note, item_id: item.id })
                              }
                              className="icon-note-evaluation"
                            >
                              <div className="orange container-number-notes-evaluation tipo-cursiva">
                                {item.notes.length}
                              </div>
                            </div>
                          </div>
                        )}
                        {evaluation.element.audit[0].status_id ===
                          STATUS_INPROGRESS && (
                          <div className="flex-document-column-evaluation container-calificable-item-evaluation-desktop">
                            {loadDocument.status == STATUS_LOADED &&
                              item.auditable_item && (
                                <div className="">
                                  <label
                                    className="label-input-file-evaluation-report-desktop"
                                    htmlFor="upload"
                                  >
                                    <div className="orange container-number-documents-evaluation tipo-cursiva">
                                      {item.documents.length}
                                    </div>
                                  </label>
                                  <input
                                    onChange={uploadImage(item.id)}
                                    type="file"
                                    name="file"
                                    id="file"
                                    title={item.id}
                                    className="input-file-evaluation-report-desktop"
                                  />
                                </div>
                              )}
                            {loadDocument.item_id == item.id &&
                              loadDocument.status == STATUS_LOADING && (
                                <img
                                  src={loadingImage}
                                  className="image-loading-item-desktop"
                                />
                              )}
                          </div>
                        )}
                      </div>
                    )}
                </div>
                {/*    new item */}
                {stateNewSubItem.id === item.id && (
                  <ol>
                    <li>
                      <div
                        className={`flex content-item-column  content-item-new-column`}
                      >
                        <div className="flex-1">
                          <div className="flex">
                            <div className="flex-1 container-new-input-item">
                              <input
                                onChange={setFieldSubItemEdit("title")}
                                value={stateNewSubItem.title}
                                className="input-basci-white form-control"
                                type="text"
                              ></input>
                            </div>
                          </div>
                        </div>

                        <div className="container-actions-item flex">
                          <div className="container-margin-actions-item-standard flex flex-1">
                            <div
                              className="cursor-action flex icon-cancel-item"
                              onClick={() => {
                                newSubItem(null, "");
                              }}
                            />

                            <div
                              onClick={() => {
                                addItem(stateNewSubItem);
                                newSubItem(null, "");
                              }}
                              className="cursor-action flex icon-acept-item"
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                )}
                {/* end new item */}
                {itemDeploy["item" + item.id] && item.items != "" && (
                  <SubItems
                    first={false}
                    editItems={editItems}
                    items={item.items}
                    itemDeploy={itemDeploy}
                    setDeploy={setDeploy}
                    hasResults={hasResults}
                  />
                )}
              </li>
            </ol>
          );
        })}
      </div>
    </>
  );
};
{
  /*  ///////////////////////////// DEVICE MOBILE ///////////////////////*/
}

const SubItemsMobile = ({
  itemDeploy,
  setDeploy,
  first,
  hasResults,
  items,
}) => {
  let history = useHistory();
  const validateClassOption = (item) => {
    if (item.results.length > 0) {
      if (item.results[0].is_valid == true) {
        return "icon-cumple-evaluation-mobile";
      } else if (item.results[0].is_valid == false) {
        return "icon-no-cumple-evaluation-mobile";
      } else if (item.results[0].is_valid == null) {
        return "icon-no-aplica-evaluation-mobile";
      }
    } else {
      return "";
    }
  };
  return (
    <>
      {items.map((item) => {
        return (
          <ol className="recursive-ol-evaluation-item-mobile" key={item.id}>
            <li>
              <div
                onClick={() =>
                  item.items != "" &&
                  item.auditable_item &&
                  item.auditable_item.required == false
                    ? setDeploy(item.id)
                    : history.push(`/app/audit/evaluation/item/${item.id}`)
                }
                className={`flex flex-column ${
                  first
                    ? "first-container-evaluation-item-mobile "
                    : "sub-container-evaluation-item-mobile b-color-white-gray"
                } container-item-evaluation-mobile`}
              >
                <div className="flex">
                  {/*   {item.auditable_item && ( */}
                  <div className="flex flex-column">
                    <span className="tipo-titulo">
                      {item.auditable_item && item.auditable_item.number}.{" "}
                    </span>
                    <div className="flex">
                      <span
                        className={`icon-calificable-mobile-item-evaluation ${validateClassOption(
                          item
                        )}`}
                      ></span>
                      {item.documents.length > 0 && (
                        <span className="background-simple icon-document-evaluation-mobile">
                          <div className="flex number-images-items-mobile">
                            {item.documents.length}
                          </div>
                        </span>
                      )}
                    </div>
                  </div>
                  {/*      )} */}
                  <div className="flex flex-1">
                    <p className="tipo-description title-item-mobile-evaluation">
                      {item.title}
                    </p>
                  </div>
                  {item.items != "" &&
                    item.auditable_item &&
                    item.auditable_item.required == false && (
                      <FontAwesomeIcon
                        className={`margin-auto ${
                          first ? "orange" : ""
                        } icon-deploy-evaluation-mobile`}
                        icon={
                          itemDeploy["item" + item.id]
                            ? faChevronUp
                            : faChevronDown
                        }
                      />
                    )}
                </div>

                {first && (
                  <div className="flex progress-container-item-mobile">
                    <div
                      style={{ width: getPercentage(item).percentage + "%" }}
                      className="progress-data-item-mobile bg-orange"
                    ></div>
                  </div>
                )}
                {!first &&
                  item.auditable_item &&
                  !item.auditable_item.required && (
                    <div className="flex progress-container-item-mobile bg-white">
                      <div
                        style={{ width: getPercentage(item).percentage + "%" }}
                        className="progress-data-item-mobile-sub"
                      ></div>
                    </div>
                  )}
              </div>

              {itemDeploy["item" + item.id] && item.items != "" && (
                <SubItemsMobile
                  itemDeploy={itemDeploy}
                  first={false}
                  items={item.items}
                  setDeploy={setDeploy}
                  hasResults={hasResults}
                />
              )}
            </li>
          </ol>
        );
      })}
    </>
  );
};
const Document = ({ document }) => {
  let history = useHistory();
  const downloadDocument = (ref) => {
    window.open(ref, "_blank");
  };
  return (
    <>
      {(document.type == EXT_JPG ||
        document.type == EXT_JPEG ||
        document.type == EXT_PNG) && (
        <img
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action img-attached-final-report"
          src={
            process.env.REACT_APP_API_HOST +
            "/documents/" +
            (document && document.name)
          }
          onError={(e) => {
            e.target.src = imgErrorDefault;
          }}
        />
      )}
      {document.type == EXT_PDF && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-pdf-observation btn-files-observations"
        ></div>
      )}
      {(document.type == EXT_DOCX || document.type == EXT_DOC) && (
        <div
          onClick={() =>
            downloadDocument(
              process.env.REACT_APP_API_HOST + "documents/" + document.name
            )
          }
          className="cursor-action btn-word-observation btn-files-observations"
        ></div>
      )}
      {document.type == EXT_MP3 && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp3-observation btn-files-observations"
        ></div>
      )}
      {(document.type == EXT_MP4 || document.type == EXT_MOV) && (
        <div
          onClick={() =>
            history.push(`../../../app/library/document/${document.id}`)
          }
          className="cursor-action btn-mp4-observation btn-files-observations"
        ></div>
      )}
    </>
  );
};
export default Form;
