import React, { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import imgAudit from "../../../../src/img_zeus/Auditorias.png";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
const LIBRARY_SERVICE = 4;
const AUDIT_SERVICE = 1;
const AUDITOR_SERVICE = 1;
const ACTION_PLAN_SERVICE = 5;
const CONSULTING_SERVICE = "consulting";
const CAPACITY_SERVICE = 3;
const INSPECTION_SERVICE = 6;

const TarjetPackage = ({ packages, actionButton, nameButton,setAsing }) => {
  const [{}, { number_format }] = useContext(SecurityContext);
  return (
    <div className="flex-1 justify-center">
      {packages.map((pack) => {
        return (
          <div key={pack.id} className={`${!pack.active?"gray-scale":""} bg-white  container-tarjet-service`}>
            <div
              className={`bg-module-library   tipo-title txt-center service-title flex`}
            >
              <div
                className={
                  pack.is_persona
                    ? "icon-paymnet-individual"
                    : "icon-paymnet-company"
                }
              ></div>
              <h5>
                {pack.is_persona ? "PLAN INDIVIDUAL" : "PLAN EMPRESARIAL"}
              </h5>
              {
                pack.is_free&&(
                  <span className="style-free-package">Al registrarse</span>
                )

              }
             
            </div>
            <div
              className={`flex-wrap content-check-subscription ${
                module == AUDIT_SERVICE ? "content-texts-payment-audit" : ""
              }`}
            >
              <div className="txt-center tipo-titulo">
                {pack.timeMonth} meses
              </div>
              <div className="txt-center tipo-titulo">
                {pack.description} 
              </div>
              {pack.details &&
                pack.details.map((detail,index) => {
                  return (
                    <div key={index}>
                      <FontAwesomeIcon
                        className="color-module-audit"
                        icon={faCheckCircle}
                      />
                      <span className="color-gray tipo-description">
                        {detail.quantity}{" "}
                        {detail.service.id==CAPACITY_SERVICE&&"MB de "}
                        {(detail.service.id==LIBRARY_SERVICE && parseInt(detail.quantity)==1)&&"Suscripción al módulo "}
                        {(detail.service.id==LIBRARY_SERVICE && parseInt(detail.quantity)!=1)&&"Suscripciones al módulo "}
                        {parseInt(detail.quantity)==1?detail.service.singular_name: detail.service.name} &nbsp;
                        {detail.product && detail.product.name}
                      </span>
                    </div>
                  );
                })}

              {pack.benefits &&
                pack.benefits.map((ben,index) => {
                  return (
                    <div key={index}>
                    <FontAwesomeIcon
                      className="color-module-audit"
                      icon={faCheckCircle}
                    />
                    <span className="color-gray tipo-description">
                      {ben.note}{" "}
                    </span>
                  </div>
                  );
                })}
              <div className="tipo-title txt-center total-payment-desktop flex-column">
                {/*   {module == MODULE_LIBRARY &&
                  services.status === STATUS_LOADED && (
                    <img
                      className="img-payment-select-module"
                      src={
                        process.env.REACT_APP_API_HOST +
                        "/documents/" +
                        (services.topic.image &&
                          services.topic.image.name)
                      }
                      onError={(e) => {
                        e.target.src = imgAudit;
                      }}
                    ></img>
                  )} */}
                {module == AUDIT_SERVICE && (
                  <img
                    className="img-payment-select-module"
                    src={imgAudit}
                  ></img>
                )}
                <br />
                <strong>$ {number_format(pack.value, 0)}</strong> COP
              </div>
              <div className="flex">
              <div
                onClick={() => {
                  actionButton(pack);
                }}
                className={`flex-1 tipo-title cursor-action txt-center  justify-center btn-cart-payment-package`}
              >
                {nameButton}
              </div>
              {" "}
              <div  onClick={()=>setAsing(pack)} className="flex-1 tipo-title cursor-action txt-center  justify-center btn-cart-payment-package">Asignar</div>
              </div>
             
            
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TarjetPackage;
