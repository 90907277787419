import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { BillingContext } from "../../contexts/billing/BillingContext";
import client from "../../contexts/axios/axios-client";
import "../billing/ConfirmPayment.css";
import { SecurityContext } from "../../contexts/security/SecurityContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import imgAudit from "../../../src/img_zeus/Auditorias.png";
const AUDIT_COMPANY = "audit_company";
const AUDIT_PERSON = "audit_person";

const ConfirmPayment = () => {
  let history = useHistory();
  const [
    { bill, meta, conditions, policies },
    { number_format, paymentPayU, form, filterConditions },
  ] = useContext(BillingContext);
  const [{ user }, { viewGeneral }] = useContext(SecurityContext);
  const [terms, setTerms] = useState(true);

  useEffect(() => {
    viewGeneral("buy");
    if (!bill.id || bill == undefined) {
      history.replace(`/app/library/topic/billing/suscription/library`);
    }
  }, []);
  const getNamePayment = () => {
    let name = "Plataforma ZEUS";

    if (bill.id) {
      if (bill.billdetails.length > 0) {
        if (bill.billdetails[0].topic) {
          name = name + " Actualidad (" + bill.billdetails[0].topic.name + ")";
        } else {
          switch (bill.billdetails[0].service_id) {
            case AUDIT_COMPANY:
              name = name + " Auditoría empresarial";

              break;
            case AUDIT_PERSON:
              name = name + " Auditoría personal";

              break;
          }
        }
      }
    }

    return name;
  };
  return (
    <div className="bg-white shadow-tarjet  tarjet-payment-confirm">
      <div className="image-paymnet-confirm background-simple">
        <div className="container-text-banner-confirm">
          <p>Resumen de</p>
          <p className="tipo-description">la compra</p>
        </div>
      </div>
      <div className="content-description-payment flex">
        <div className="flex margin-auto general-container-description-green-payment">
          <div>
           
          <img
            className="img-payment-module"
            src={
              process.env.REACT_APP_API_HOST +
              "/documents/" +
              (bill.id && bill.billdetails[0].topic&&bill.billdetails[0].topic.image.name)
            }
            onError={(e) => {
              e.target.src = imgAudit;
            }}
          ></img>
          </div>
          <div className="green-description-payment">
            {bill.id &&
              bill.billdetails &&
              bill.billdetails.map((detail) => (
                <div key={detail.id}>
                  {" "}
                  <span>
                    <span className="tipo-description">Plan </span>
                    {detail.topic_id
                      ? `ACTUALIDAD Módulo ${detail.topic.name}`
                      : detail.service_id == "audit_person"
                      ? "AUDITORÍAS  Auditor INDEPENDIENTE"
                      : detail.service_id == "audit_company"
                      ? "AUDITORÍAS EMPRESARIALES"
                      : ""}
                    <br></br>
                  <span className="tipo-description">{detail.service_number}</span>  
                    {detail.service_number > 1
                      ? <span className="tipo-description">{
                        detail.service_id == "audit_company" ||
                        detail.service_id == "audit_person"
                          ? " Auditorías"
                          : " Usuarios"
                      }</span>
                      : <span className="tipo-description">{
                        detail.service_id == "audit_person"
                          ? " Auditoría"
                          : " Usuario"
                      }</span>}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="container-total-confirm-payment">
        <div>
          <span className="tipo-title">TOTAL:</span>
          &nbsp;&nbsp;
          <span className="tipo-description">
            $ {bill && number_format(bill.total, 0)} &nbsp;&nbsp;
            {bill && bill.currency}{" "}
          </span>
        </div>
      </div>
      <div className="flex-wrap w-100">
        <div className="flex-wrap justify-center flex w-100">
          <form
            method="post"
            action={
              process.env.REACT_APP_IS_PRODUCTION == true
                ? "https://checkout.payulatam.com/ppp-web-gateway-payu"
                : "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu"
            }
          >
            <input
              name="merchantId"
              type="hidden"
              value={bill && bill.merchantId}
            />
            <input
              name="accountId"
              type="hidden"
              value={bill && bill.accountId}
            />

            <input
              name="description"
              type="hidden"
              value={bill && getNamePayment()}
            />
            <input name="referenceCode" type="hidden" value={bill && bill.id} />
            <input name="amount" type="hidden" value={bill && bill.total} />
            <input name="tax" type="hidden" value="0" />
            <input name="taxReturnBase" type="hidden" value="0" />
            <input
              name="currency"
              type="hidden"
              value={bill && bill.currency}
            />
            <input
              name="signature"
              type="hidden"
              value={bill && bill.signature}
            />
            <input
              name="test"
              type="hidden"
              value={process.env.REACT_APP_IS_PRODUCTION == true ? "0" : "1"}
            ></input>
            <input name="buyerEmail" type="hidden" value={user.email} />
            <input
              name="responseUrl"
              type="hidden"
              value={`${process.env.REACT_APP_API_PUB_HOST}billing/paymentPayUResponse`}
            />
            <input
              name="confirmationUrl"
              type="hidden"
              value={`${process.env.REACT_APP_API_PUB_HOST}billing/paymentPayUConfirm`}
            />
            <input
              className={`${
                terms ? "gray-scale" : "cursor-action"
              } tipo-title  txt-center white bg-orange btn-process-payment`}
              name="Submit"
              type="submit"
              disabled={terms}
              value="Procesar pedido"
            />
          </form>

          <div className="txt-center politic-privacity-payment">
            <input
              onClick={() => setTerms(!terms)}
              className="check-terms-conditions"
              type="checkbox"
            />{" "}
            Acepto{" "}
            <Link
              to={`/app/library/document/${
                conditions.elements && conditions.elements.id
              }`}
              target="_blank"
            >
              Términos y Condiciones
            </Link>{" "}
            y la{" "}
            <Link
              to={`/app/library/document/${
                policies.elements && policies.elements.id
              }`}
              target="_blank"
            >
              Política de Privacidad y Tratamiento de Datos Personales.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPayment;
