import React, { useEffect, useContext, useState } from "react";
import {
  ActionPlanFormContext,
  STATUS_INPROGRESS,
  STATUS_CREATED,
  STATUS_EXECUTED,
} from "../../../contexts/audit/action_plan/ActionPlanFormContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Swal from "sweetalert2";
import Details from "./Details";
import $ from "jquery";
import {
  STATUS_LOADED,
  STATUS_NOT_LOADED,
  SecurityContext,
} from "../../../contexts/security/SecurityContext";
import Corrections from "./Corrections";
import Planification from "./Planification";
import Cause from "./Cause";
import btn_created from "../../../img_zeus/icons_audit/ICONO_ESPERA.png";
import btn_inprogress from "../../../img_zeus/icons_audit/ICONO_EJECUCION.png";
import btn_executed from "../../../img_zeus/icons_audit/ICONO_TERMINADA.png";
import deleteIcon from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import Implement from "./Implement";
import "./Form.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import Effective from "./Effective";

const Analyze = ({ computedMatch: { params, url } }) => {
  let { analyze_id } = params;
  const [
    { analyze, scroll },
    {
      setanalyze,
      updateAnalyze,
      isResponsable,
      haveActions,
      haveCorrections,
      sendEmailAnalyze,
      setscroll,
    },
  ] = useContext(ActionPlanFormContext);
  const [{ tabAnalyze }, { settabAnalyze, alertWarning }] =
    useContext(AlertContext);
  const [{ user }] = useContext(SecurityContext);
  useEffect(() => {
    setanalyze({ ...analyze, analyze_id: analyze_id });
  }, [analyze_id]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);
  const handleScroll = () => {
    let scroll = $("#content-wrapper").scrollTop();
    if (scroll != 0) {
      setscroll(scroll);
    }
  };
  useEffect(() => {
    $("#content-wrapper").scrollTop(scroll);
  }, [analyze]);
  const checkUpdateAnalyze = async () => {
    if (!analyze.element.responsable) {
      alertWarning("Campo responsable es obligatorio");
      return false;
    }

    if (analyze.element.is_risk) {
      Swal.fire({
        title: "Recuerde actualizar su matriz de riesgo",
        text: "",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#df8c37",
        cancelButtonColor: "#171e27",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Entendido!",
      }).then(async (result) => {
        await updateAnalyze({ status_id: STATUS_INPROGRESS }).then(
          ({ data }) => {
            setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
          }
        );
      });
    } else {
      await updateAnalyze({ status_id: STATUS_INPROGRESS }).then(({ data }) => {
        setanalyze({ ...analyze, status: STATUS_NOT_LOADED });
      });
    }
  };
  const getAffair = (eva) => {
    let items = eva.items.filter((i) => i.description == "affair");
    if (items.length > 0) {
      if (items[0].results.length > 0) {
        return items[0].results[0].pivot.response;
      }
    }
  };
  const statusAnalyze = (auditStatus, withText) => {
    switch (auditStatus) {
      case 1:
        return (
          <>
            <img
              src={btn_created}
              title="Creada"
              className="img-status-analyze"
            />{" "}
            {withText && "Creado"}
          </>
        );
        break;

      case 3:
        return (
          <>
            <img
              src={btn_inprogress}
              title="En progreso"
              className="img-status-analyze"
            />{" "}
            {withText && "En progreso"}
          </>
        );
        break;
      case 4:
        return (
          <>
            <img
              src={btn_executed}
              title="Finalizada"
              className="img-status-analyze"
            />{" "}
            {withText && "Finalizado"}
          </>
        );
        break;
      case 6:
        return (
          <>
            <img
              src={btn_created}
              title="Pendiente"
              className="img-status-analyze"
            />{" "}
            {withText && "Pendiente"}
          </>
        );
        break;
      case 8:
        return (
          <>
            <img
              src={deleteIcon}
              title="Rechazada"
              className="img-status-analyze"
            />{" "}
            {withText && "Rechazado"}
          </>
        );
        break;
      default:
        return (
          <img
            src={btn_created}
            title="Creada"
            className="img-status-analyze"
          />
        );
    }
  };
  const panelMobile = (number, text) => {
    return (
      <div className="hidden mobile-show ">
        <div className="flex">
          <div className=" circle-tab-analyze-form-mobile hidden white bg-orange">
            <span>{number}</span>
          </div>
          &nbsp;
          <strong>{text}</strong>
        </div>
      </div>
    );
  };
  const getPositionEnable = () => {
    if (haveCorrections()) {
      return 1;
    }
    if (haveActions()) {
      return 4;
    }
    if (analyze.element.evaluation) {
      let count = analyze.element.company.partners.filter(
        (p) => p.pivot.person_id == user.id && p.pivot.isPqrsResponsable == true
      ).length;
      if (count > 0) {
        //responable pqrs
        return 2;
      }
    }
  };
  return (
    <div className="general-container-analyze color-black-gray">
      <nav
        aria-label="breadcrumb"
        className="flex-1 cursor-action mobile-hidden"
      >
        {analyze.element.item && (
          <ol className="breadcrumb">
            <li className="breadcrumb-item ">
              <Link
                className="orange"
                to={`/app/audit/${analyze.element.audit_id}`}
              >
                {analyze.element.item.evaluation.audit[0].title}
              </Link>
            </li>
            <li className="breadcrumb-item ">
              <Link
                className="orange"
                to={`/app/audit/evaluation/${analyze.element.item.evaluation.id}`}
              >
                {analyze.element.item.evaluation.title}
              </Link>
            </li>
          </ol>
        )}
        {analyze.element.evaluation && (
          <ol className="breadcrumb">
            <li className="breadcrumb-item ">
              <Link
                className="orange"
                to={`/app/inspection/evaluation/${analyze.element.evaluation.id}`}
              >
                {analyze.element.evaluation.id}
              </Link>
            </li>
          </ol>
        )}
      </nav>
      <div className="flex mobile-hidden">
        <div className="flex-1 flex">
          <h2>Plan de acción :&nbsp; </h2>
          <p style={{ fontSize: "25px" }} className="tipo-description">
            {analyze.element.number}
          </p>
        </div>
      </div>

      <div className="container-header-analyze mobile-hidden">
        <div
          className="flex justify-betwen
        "
        >
          <div className="form-group ">
            <label className="tipo-title">Origen</label>
            <div className="tipo-description color-gray">
              {analyze.element.item &&
                analyze.element.item.evaluation.audit[0].title}
              {analyze.element.evaluation && "PQRS"}
            </div>
          </div>
          <div className="form-group ">
            <label className="tipo-title">Empresa</label>
            <div className="tipo-description color-gray">
              {analyze.element.company && (
                <Link
                  className="color-gray"
                  to={`/app/companies/${analyze.element.company.id}`}
                >
                  {analyze.element.company.name}
                </Link>
              )}
            </div>
          </div>

          <div className="form-group ">
            <label className="tipo-title">Fecha de identificación</label>
            <div className="tipo-description color-gray">
              {analyze.element.init_date}
            </div>
          </div>
          <div className="form-group ">
            <label className="tipo-title">Estado</label>
            <div className="tipo-description color-gray">
              <span>{statusAnalyze(analyze.element.status_id, true)}</span>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex">
            <div className="form-group container-criterio-analyze">
              <label className="tipo-title">
                {analyze.element.item ? "Criterio" : "Hallazgo"}
              </label>
              <div className="tipo-description color-gray">
                {analyze.element.item &&
                  analyze.element.item.auditable_item &&
                  analyze.element.item.auditable_item.number}
                {analyze.element.evaluation &&
                  getAffair(analyze.element.evaluation)}
              </div>
            </div>
          </div>
          <br />
          <div className="flex">
            <div className="form-group ">
              <div className="tipo-description color-gray">
                {analyze.element.description}
              </div>
            </div>
          </div>
        </div>

        {analyze.element.status_id === STATUS_CREATED &&
          analyze.element.responsable_id === user.id && (
            <div
              onClick={() => checkUpdateAnalyze()}
              style={{ width: "209px" }}
              className="btn-orange-default-short"
            >
              iniciar plan de acción
            </div>
          )}
      </div>
      <div className="hidden mobile-show">
        <div className="flex justify-betwen">
          <div>
            <span className="orange">ID accion : </span>
            <strong>{analyze.element.number}</strong>
          </div>
          <div>{statusAnalyze(analyze.element.status_id, false)}</div>
          <div>
            <span className="orange">
              {" "}
              {analyze.element.item ? "Criterio" : "Hallazgo"}
              {" : "}
            </span>
            <strong>
              {" "}
              {analyze.element.item &&
                analyze.element.item.auditable_item &&
                analyze.element.item.auditable_item.number}
              {analyze.element.evaluation &&
                getAffair(analyze.element.evaluation)}
            </strong>
          </div>
        </div>
        <div>
          <span className="orange">Descripcion : </span>{" "}
          {analyze.element.description}
        </div>
      </div>
      <div className="tabas-container-analyze">
        {analyze.status === STATUS_LOADED && (
          <Tabs
            selectedIndex={
              tabAnalyze
                ? tabAnalyze
                : isResponsable()
                ? 0
                : getPositionEnable()
            }
            onSelect={(tabIndex) => {
              settabAnalyze(tabIndex);
            }}
            className="content-audit w-100"
          >
            <TabList className="header-tab-analyze-form">
              <Tab disabled={!isResponsable()}>
                <>
                  {tabAnalyze !== 0 && (
                    <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                      <span>1</span>
                    </div>
                  )}
                  {tabAnalyze === 0 && panelMobile(1, "Detalles")}
                  <strong className="mobile-hidden">Detalles</strong>
                </>
              </Tab>

              <Tab
                disabled={
                  analyze.element.status_id === STATUS_CREATED ||
                  !haveCorrections()
                }
              >
                <>
                  {tabAnalyze !== 1 && (
                    <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                      <span>2</span>
                    </div>
                  )}
                  {tabAnalyze === 1 && panelMobile(2, "Correcciones")}
                  <strong className="mobile-hidden">Correcciones</strong>
                </>
              </Tab>
              <Tab
                disabled={
                  analyze.element.status_id === STATUS_CREATED ||
                  !isResponsable()
                }
              >
                <>
                  {tabAnalyze !== 2 && (
                    <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                      <span>3</span>
                    </div>
                  )}
                  {tabAnalyze === 2 && panelMobile(3, "Analisis de causa")}
                  <strong className="mobile-hidden">Analisis de causa</strong>
                </>
              </Tab>

              <Tab
                disabled={
                  analyze.element.status_id === STATUS_CREATED ||
                  !isResponsable()
                }
              >
                <>
                  {tabAnalyze !== 3 && (
                    <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                      <span>4</span>
                    </div>
                  )}
                  {tabAnalyze === 3 && panelMobile(4, "Plan de acción")}
                  <strong className="mobile-hidden">Plan de acción</strong>
                </>
              </Tab>

              <Tab
                disabled={
                  analyze.element.status_id === STATUS_CREATED || !haveActions()
                }
              >
                <>
                  {tabAnalyze !== 4 && (
                    <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                      <span>5</span>
                    </div>
                  )}
                  {tabAnalyze === 4 && panelMobile(5, "Implementar")}
                  <strong className="mobile-hidden">Implementar</strong>
                </>
              </Tab>
              <Tab
                disabled={
                  analyze.element.status_id === STATUS_CREATED ||
                  !isResponsable()
                }
              >
                {tabAnalyze !== 5 && (
                  <div className="circle-tab-analyze-form-mobile bg-white orange hidden">
                    <span>6</span>
                  </div>
                )}
                {tabAnalyze === 5 && panelMobile(6, "Eficacia")}
                <strong className="mobile-hidden">Eficacia</strong>
              </Tab>
            </TabList>
            <div
              className={`${
                analyze.element.status_id === STATUS_EXECUTED
                  ? "noPointerAnalyze"
                  : ""
              } general-tab-audit`}
            >
              <>
                <TabPanel>
                  <Details />
                </TabPanel>
                <TabPanel>
                  <Corrections />
                </TabPanel>
                <TabPanel>
                  <Cause />
                </TabPanel>
                <TabPanel>
                  <Planification />
                </TabPanel>
                <TabPanel>
                  <Implement />
                </TabPanel>
                <TabPanel>
                  <Effective />
                </TabPanel>
              </>
            </div>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default Analyze;
