import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import clientImage from "../axios/axios-client-image";
import firebase from "../../firebase";
import client from "../axios/axios-client";
import { SecurityContext } from "../security/SecurityContext";
import { AlertContext } from "../alerts/AlertContext";
import { LibraryContext } from "../library/LibraryContext";

export const STATUS_LOADING = "LOADING";
export const STATUS_NOT_LOADED = "NOT_LOADED";
export const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
export const STATUS_LOGGED_IN = "LOGGED_IN";
export const STATUS_LOADED = "STATUS_LOADED";
const NOTIFICATION_FORUM = "forum-score";
const NOTIFICATION_MODULE_FORUM = "forum";
let initalState = {
  meta: {
    status: STATUS_NOT_LOADED,
    entry: {},
    entryResponse: {},
    path: {
      entry_id: null,
      topic_id: null,
      entryResponse_id: null
    }
  },
  entry: {
    status: STATUS_NOT_LOADED,
    elements: []
  },
  entries: {
    status: STATUS_NOT_LOADED,
    score: null,
    elements: []
  },
  topic: {},
  entryResponse: {
    status: STATUS_NOT_LOADED,
    elements: []
  },
  flag: {
    like: true
  },
  documents: {
    status: STATUS_NOT_LOADED,
    elements: [],
    originalItem: []
  },
  errors: {
    error: [],
    status: false
  },
  formErrors: {}
};

export const EntryIndexContext = React.createContext([]);

export const EntryIndexContextProvider = props => {
  let [entry, setEntry] = useState(initalState.entry);
  let [entries, setEntries] = useState(initalState.entries);
  let [entryResponse, setEntryResponse] = useState(initalState.entryResponse);
  let [meta, setMeta] = useState(initalState.meta);
  let [flag, setFlag] = useState(initalState.flag);
  let [documents, setDocuments] = useState(initalState.documents);
  let [topic, setTopic] = useState(initalState.topic);
  let [errors, setErrors] = useState(initalState.errors);
  const [{ user }, { logout }] = useContext(SecurityContext);
  const [{}, { alertSuccess, alertError, showErrors }] = useContext(AlertContext);

  let history = useHistory();
  let getEntry = id => {};
  let { topic_id } = props.computedMatch.params;

  useEffect(() => {
    if (entries.status === STATUS_NOT_LOADED) {
      addViewForum(topic_id);
      setEntries({ ...entries, status: STATUS_LOADING });
      client()
        .get(`library/topic/${topic_id}`, {
          params: {
            with: [
              "entries.personEntry",
              "entries.entry_responses.personEntryResponse",
              "entries.entryTopic",
              "entries.valorable",
              "effectives.consumers"
            ]
          }
        })
        .then(({ data }) => {
          if (data) {
            setEntries({ status: STATUS_LOADED, elements: data.entries });
            setTopic(data);
          }
        })
        .catch(e => {
          if (e.request.status === 401) {
            logout();
          } else if (e.request.status === 422) {
            setErrors({ error: e.response.data, status: true });
          } else {
            alertError("Error al buscar las preguntas");
          }
        });
    }
  }, [props.computedMatch, entries]);

  useEffect(() => {
    if (meta.path.entry_id !== null) {
      if (!meta.entry.status) {
        setMeta({ ...meta, entry: { status: STATUS_LOADING } });
        client()
          .get(`library/entry/${meta.path.entry_id}`)
          .then(({ data }) => {
            setMeta({ ...meta, entry: { ...data, status: STATUS_LOADED } });
          });
      }
    }
  }, [meta]);

  let setEntryAndTopicId = (entry_id, topic_id) => {
    if (entry_id !== meta.path.entry_id || topic_id !== meta.path.topic_id) {
      setMeta({
        ...meta,
        path: { ...initalState.meta.path, topic_id: topic_id, entry_id: entry_id },
        entry: { status: STATUS_NOT_LOADED }
      });
    }
  };

  let setTopicId = topic_id => {
    if (topic_id !== meta.path.topic_id) {
      setMeta({
        ...meta,
        path: { ...initalState.meta.path, topic_id: topic_id },
        topic: { status: STATUS_NOT_LOADED }
      });
    }
  };

  const createEntry = ({ title, body }) => {
    client()
      .post("library/entry", {
        title: title,
        body: body,
        person_id: user.id,
        topic_id: topic_id
      })
      .then(({ data }) => {
        alertSuccess("Pregunta creada satisfactoriamente");
        if (data) {
          setEntries({ ...entries, status: STATUS_NOT_LOADED });
        }
        history.push("/app/library/topic/" + topic_id + "/forum");
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else {
          alertError("Problemas al crear pregunta");
        }
      });
  };

  const deleteEntry = id => {
    client()
      .delete(`library/entry/${id}`)
      .then(({ data }) => {
        alertSuccess("Pregunta eliminada satisfactoriamente");
        let el = entries.elements.filter(e => e.id !== id);
        setEntries({
          ...entries,
          elements: el,
          status: STATUS_NOT_LOADED
        });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("../../../app/unauthorized");
        } else {
          alertError("Error al buscar los roles");
        }
      });
  };

  const updateEntry = ({ body, title, id }) => {
    client()
      .put(`library/entry/${id}`, { body, title })
      .then(() => {
        alertSuccess("Pregunta actualizada satisfactoriamente");
        setEntries({ ...entries, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la respuesta");
        }
      });
  };
  const addNotificationLike = entry => {
    const responsable = entry.person_entry_response
      ? entry.person_entry_response
      : entry.person_entry;

    const db = firebase.firestore();
    return db.collection(`users/${responsable.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `le ha gustado tu publicación <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/library/topic/${meta.path.topic_id}/forum"> ${topic.name}</a>`,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_FORUM,
      module: NOTIFICATION_MODULE_FORUM
    });
  };
  let addScore = async (isLike, entry) => {
    setFlag({ ...flag, like: false });
    client()
      .post(`library/entry/${entry.id}/dataValorable`, {
        person_id: user.id,
        value: isLike
      })
      .then(({ data }) => {
        if (isLike) {
          addNotificationLike(entry);
        }
        alertSuccess("Calificación agregada satisfactoriamente");
        if (data) {
          setFlag({ ...flag, like: true });
          setEntries({ ...entries, status: STATUS_NOT_LOADED });
        }
      });
  };

  let addScore2 = async (isLike, entry_response) => {
    setFlag({ ...flag, like: false });
    client()
      .post(`library/entry_response/${entry_response.id}/dataValorable`, {
        person_id: user.id,
        value: isLike
      })
      .then(({ data }) => {
        if (isLike) {
          addNotificationLike(entry_response);
        }
        alertSuccess("Calificación agregada satisfactoriamente");
        if (data) {
          setFlag({ ...flag, like: true });
          setEntries({ ...entries, status: STATUS_NOT_LOADED });
        }
      });
  };
  const addNotificationEntryResponse = entry => {
   const responsable = entry.person_entry_response
      ? entry.person_entry_response
      : entry.person_entry;

    const db = firebase.firestore();
    return db.collection(`users/${responsable.firebase_id}/notifications`).add({
      viewed: false,
      touched: false,
      body: `ha comentado tu publicación <a class="link-default" href="${process.env.REACT_APP_GUI_HOST}app/library/topic/${meta.path.topic_id}/forum"> ${topic.name}</a>`,
      owner_person_id: user.id,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      owner_person_name: user.first_name + " " + user.last_name,
      type: NOTIFICATION_FORUM,
      module: NOTIFICATION_MODULE_FORUM
    }); 
  };
  const createEntryResponse = (body, entry_id, response_id) => {
    client()
      .post("library/entry_response", {
        body: body,
        person_id: user.id,
        entry_id: entry_id,
        topic_id: meta.path.topic_id,
        entry_response_id: response_id
      })
      .then(({ data }) => {
        if (data) {
          if (response_id) {
            client()
              .get(`library/entry_response/${response_id}`)
              .then(({ data }) => {
                addNotificationEntryResponse(data);
              });
          } else {
            client()
              .get(`library/entry/${entry_id}`, {
                params: {
                  with: ["personEntry"]
                }
              })
              .then(({ data }) => {
                addNotificationEntryResponse(data);
              });
          }

          alertSuccess("Respuesta creada satisfactoriamente");
          setEntries({ ...entries, status: STATUS_NOT_LOADED });
          refreshEntries();
        }
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al cargar la respuesta");
        }
      });
  };

  const deleteEntryResponse = id => {
    client()
      .delete(`library/entry_response/${id}`)
      .then(({ data }) => {
        alertSuccess("Respuesta eliminada satisfactoriamente");
        setMeta({
          ...meta,
          status: STATUS_NOT_LOADED
        });
        refreshEntries();
      });
  };

  let refreshEntries = () => {
    setEntries({ ...entries, status: STATUS_NOT_LOADED });
  };

  let valorable = (like, entry) => {
    let el = entry.valorable && entry.valorable.filter(e => e.value == like);
    return el && el.length;
  };

  let valorable2 = (like, resp) => {
    let el = resp.valorable && resp.valorable.filter(e => e.value == like);
    return el && el.length;
  };

  let classLike = (like, entry) => {
    let el =
      entry.valorable && entry.valorable.filter(e => e.person_id == user.id && e.value == like);
    return el.length > 0 ? true : false;
  };

  const createImgForum = image => {
    var formData = new FormData();
    formData.append("document", image);
    formData.append("role", "forum_image");
    formData.append("topic_id", meta.path.topic_id);
    formData.append("is_private", false);

    let path = "topic/" + meta.path.topic_id + "/documented";
    clientImage()
      .post("library/" + path, formData)
      .then(({ data }) => {
        alertSuccess("Imagen creado satisfactoriamente");
        setMeta({ ...meta, status: STATUS_NOT_LOADED });
        setEntries({ ...entries, status: STATUS_NOT_LOADED });
        setDocuments({ ...documents, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Problemas al crear la imagen");
        }
      });
  };

  const addViewForum = topic_id => {
    client()
      .post(`library/topic/${topic_id}/viewed/by/${user.id}`, {
        role: "forum"
      })
      .then(({ data }) => {})
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al registrar el ingreso");
        }
      });
  };

  const updateEntryResponse = ({ body, id }) => {
    client()
      .put(`library/entry_response/${id}`, { body })
      .then(() => {
        alertSuccess("Respuesta actualizada satisfactoriamente");
        setEntries({ ...entries, status: STATUS_NOT_LOADED });
      })
      .catch(e => {
        if (e.request.status === 401) {
          logout();
        } else if (e.request.status === 422) {
          setErrors({ error: e.response.data, status: true });
        } else if (e.request.status === 403) {
          history.push("/app/unauthorized");
        } else {
          alertError("Error al editar la respuesta");
        }
      });
  };

  return (
    <EntryIndexContext.Provider
      value={[
        { entry, entries, entryResponse, meta, flag, topic },
        {
          getEntry,
          createEntry,
          setEntryAndTopicId,
          deleteEntry,
          setTopicId,
          addScore,
          addScore2,
          valorable,
          valorable2,
          classLike,
          createImgForum,
          refreshEntries,
          updateEntry,
          createEntryResponse,
          updateEntryResponse,
          deleteEntryResponse
        }
      ]}
    >
      {props.children}
    </EntryIndexContext.Provider>
  );
};
