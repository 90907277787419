import React, { useContext, useState } from "react";
import "../modals/CreateFolder.css";
import {
  LibraryContext,
  STATUS_ERASER,
  STATUS_APPROVED,
} from "../../../../../contexts/library/LibraryContext";
import Select, { components } from "react-select";

const initialState = {
  statusData: [
    { name: "Publicado", id: STATUS_APPROVED },

    { name: "Borrador", id: STATUS_ERASER },
  ],
  newFolder: {
    name: "",
    is_free: true,
  },
};
const CreateFolder = ({ toggleModalFolder }) => {
  const [
    { editFolder },
    { createFolder, seteditFolder, editFolderAction },
  ] = useContext(LibraryContext);
  const [newFolder, setNewFolder] = useState(initialState.newFolder);

  const setField = (field) => (e) => {
    setNewFolder({ ...newFolder, [field]: e.target.value });
  };
  const setFree = (param) => {
    setNewFolder({ ...newFolder, is_free: !newFolder.is_free });
  };

  return (
    <div className="back-document">
      <div className="shadow-tarjet flex-wrap flex flex-start content-modal-create-folder">
        <div className="header-modal-create-folder">
          <div className="w-100 flex">
            <div
              onClick={() => {
                toggleModalFolder();
              }}
              className="background-simple icon-close-modal-create-folder cursor-action"
            ></div>
            <p className="txt-center txt-title-new-folder">
              {editFolder.id != null ? "Editar Carpeta" : "Nueva Carpeta"}{" "}
            </p>
          </div>
        </div>
        <div className="content-white-create-folder bg-white">
          <div className="flex justify-center">
            <div
              className={`${
                newFolder.is_free ? "img-fold-free" : "img-fold-premium"
              } background-simple img-fold-new-folder`}
            ></div>
          </div>
          <div className="flex justify-center">
            {editFolder.id != null && (
              <input
                type="text"
                onChange={(e) =>
                  seteditFolder({ ...editFolder, name: e.target.value })
                }
                value={editFolder.name}
                className="txt-center inp-new-folder-modal"
                placeholder="Editar Carpeta"
              />
            )}
            {editFolder.id == null && (
              <input
                type="text"
                onChange={setField("name")}
                value={newFolder.name}
                className="txt-center inp-new-folder-modal"
                placeholder="Nueva Carpeta"
              />
            )}
          </div>
          <div className="flex justify-center content-ckeck-new-folder">
            <div className="background-simple icon-license-create-folder"></div>
            &nbsp;<span>Licencia de uso</span>
            &nbsp; &nbsp;
            {editFolder.id != null && (
              <>
                <input
                  className="checkbox-new-folder"
                  onChange={() =>
                    seteditFolder({
                      ...editFolder,
                      is_free: !editFolder.is_free,
                    })
                  }
                  checked={!editFolder.is_free}
                  type="checkbox"
                />
                &nbsp; <span>Premium</span>
                &nbsp;
                <input
                  className="checkbox-new-folder"
                  onChange={() =>
                    seteditFolder({
                      ...editFolder,
                      is_free: !editFolder.is_free,
                    })
                  }
                  checked={editFolder.is_free}
                  type="checkbox"
                />
              </>
            )}
            {editFolder.id == null && (
              <>
                <input
                  className="checkbox-new-folder"
                  onChange={() => setFree()}
                  checked={!newFolder.is_free}
                  type="checkbox"
                />
                &nbsp; <span>Premium</span>
                &nbsp;
                <input
                  className="checkbox-new-folder"
                  onChange={() => setFree()}
                  checked={newFolder.is_free}
                  type="checkbox"
                />
              </>
            )}
            &nbsp;<span>Gratuito </span>
          </div>
        </div>
        <div className="flex justify-end w-100">
          <div
            onClick={() => {
              editFolder.id != null
                ? editFolderAction(true)
                : createFolder(true, newFolder);
              toggleModalFolder();
            }}
            className="cursor-action white bg-orange tipo-boton btn-create-new-folder-modal"
          >
            {editFolder.id != null ? "Editar y publicar" : "Crear y publicar"}
          </div>
          <div
            onClick={() => {
              editFolder.id != null
                ? editFolderAction(false)
                : createFolder(false, newFolder);
              toggleModalFolder();
            }}
            className="cursor-action white bg-orange tipo-boton btn-create-new-folder-modal"
          >
            {editFolder.id != null
              ? "Editar como borrador"
              : "Crear como borrador"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolder;
