import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { EntryIndexContext } from "../../../contexts/forum/EntryIndexContext";
import btn_edit from "../../../img_zeus/icons_library/BOTON-EDITAR-DOC.png";
import btn_delete from "../../../img_zeus/icons_library/BOTON-ELIMINAR.png";
import imgError2 from "../../../img_zeus/default/PROFILE.jpeg";

const initialState = {
  showInputResponse: false,
  response_id: null,
  show_responses: false,
  entryResponseEdit: {
    id: null,
    body: ""
  },
  newResponse: {
    id: null,
    body: ""
  }
};

const Responses = ({ data, topic_id }) => {
  let [state, setState] = useState(initialState);
  let [{ user }, { can, isAdminForum }] = useContext(SecurityContext);
  let [{ entries, entryResponse, flag, topic }, actions] = useContext(
    EntryIndexContext
  );

  let {
    addScore,
    valorable,
    classLike,
    refreshEntries,
    addScore2,
    valorable2,
    createEntryResponse,
    updateEntryResponse,
    deleteEntryResponse
  } = actions;

  let setFieldEdit = field => e => {
    setState({
      ...state,
      entryResponseEdit: { ...state.entryResponseEdit, [field]: e.target.value }
    });
  };

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      actions.updateEntryResponse(state.entryResponseEdit);
      setState({ ...state, entryResponseEdit: { id: null } });
    }
  };

  let setFieldNewResponse = field => e => {
    setState({
      ...state,
      newResponse: { ...state.newResponse, [field]: e.target.value }
    });
  };
  const formatDate = dateTime => {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Augosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    var date = new Date(dateTime);
    var year = date.getFullYear();
    var day = date.getDate();
    var shorMonth = monthNames[date.getMonth()].substr(0, 3);

    return day + " / " + shorMonth + " / " + year;
  };

  const _handleKeyDown5 = e => {
    if (e.key === "Enter") {
      setState({
        ...state,
        showInputResponse: false
      });
      console.log(data);
      
      actions.createEntryResponse(
        state.newResponse.body,
        data[0].entry_id,
        state.newResponse.id
      );
    }
  };

  let functionSubmit3 = e => {
    e.preventDefault();

    actions.createEntryResponse(
      state.newResponse.body,
      data[0].entry_id,
      state.newResponse.id
    );
    setState({
      ...state,
      showInputResponse: false
    });
  };

  const showResponseInput3 = response_id => {
    if (response_id == state.response_id) {
      setState({
        ...state,
        showInputResponse: !state.showInputResponse
      });
    } else {
      setState({
        ...state,
        response_id: response_id,
        showInputResponse: true
      });
    }
  };

  const showResponse3 = response_id => {
    if (response_id == state.response_id) {
      setState({
        ...state,
        show_responses: !state.show_responses
      });
    } else {
      setState({
        ...state,
        response_id: response_id,
        show_responses: true
      });
    }
  };

  return data.map(respo => {
    return (
      <div key={respo.id} className="response-function tipo-description">
        <img
          className="img-profile-foro-response"
          src={
            process.env.REACT_APP_API_HOST +
            "/documents/" +
            (respo.person_entry_response &&
              respo.person_entry_response.image &&
              respo.person_entry_response.image.name)
          }
          onError={e => {
            e.target.src = imgError2;
          }}
        ></img>
        {state.entryResponseEdit.id !== respo.id && (
          <div className="flex response-function-general">
            <div className="response-function-body">
              <div className="asd">
                <h6 className="tipo-title">
                  <small>{respo.person_entry_response.first_name}</small>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <small className="color-gray tipo-description">
                    {formatDate(respo.created_at)}
                  </small>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {(isAdminForum(topic_id) || user.is_admin) && (
                    <img
                      className="cursor-action btn-edit-action-entry"
                      onClick={() => actions.deleteEntryResponse(respo.id)}
                      src={btn_delete}
                      title="eliminar respuesta"
                    />
                  )}
                  &nbsp;
                  <img
                    className="cursor-action btn-edit-action-entry"
                    onClick={() =>
                      setState({
                        ...state,
                        entryResponseEdit: { id: respo.id }
                      })
                    }
                    title="editar respuesta"
                    src={btn_edit}
                  />
                </h6>
                <div style={{ marginTop: "-7px" }}>{respo.body}</div>
              </div>
            </div>
          </div>
        )}
        {state.entryResponseEdit.id === respo.id && (
          <div className="flex response-function-general">
            <div className="response-function-body">
              <div className="asd">
                <h6 className="tipo-title">
                  <small>{respo.person_entry_response.first_name}</small>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <small className="color-gray tipo-description">
                    {formatDate(respo.created_at)}
                  </small>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </h6>
                <textarea
                  className="form-control"
                  type="text"
                  cols="100"
                  rows="3"
                  placeholder="Cuerpo"
                  defaultValue={respo.body}
                  onChange={setFieldEdit("body")}
                  onKeyDown={_handleKeyDown}
                />
                <div className="flex">
                  <div
                    className="cursor-action cancel-edit-response tipo-boton"
                    onClick={() =>
                      setState({
                        ...state,
                        entryResponseEdit: { id: null }
                      })
                    }
                  >
                    Cancelar
                  </div>
                  &nbsp;
                  <div
                    className="cursor-action cancel-edit-response tipo-boton"
                    onClick={() => {
                      actions.updateEntryResponse(state.entryResponseEdit);
                      setState({ ...state, entryResponseEdit: { id: null } });
                    }}
                  >
                    Aceptar
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="content-responder-response flex color-gray">
          <div
            className="cursor-action"
            onClick={() => {
              showResponseInput3(respo.id);
            }}
          >
            <strong>Responder</strong>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div
            className="cursor-action"
            onClick={() => {
              showResponse3(respo.id);
            }}
          >
            {respo.responses.length} Comentarios
          </div>
        </div>
        {respo.id == state.response_id && state.showInputResponse == true && (
          <form onSubmit={functionSubmit3}>
            <textarea
              className="form-control txtarea-response"
              onChange={setFieldNewResponse("body")}
              value={
                (state.newResponse.id === respo.id && state.newResponse.body) ||
                ""
              }
              onFocus={e => {
                setState({
                  ...state,
                  newResponse: {
                    ...state.newResponse,
                    id: respo.id,
                    body: ""
                  }
                });
              }}
              type="text"
              required
              placeholder="Respuesta"
              onKeyDown={_handleKeyDown5}
            />
            <div className="flex btn-new-response2">
              <div
                onClick={() => {
                  setState({
                    ...state,
                    showInputResponse: false,
                    newResponse: { body: "" }
                  });
                }}
                className="cancel-edit-entry tipo-boton"
              >
                Cancelar
              </div>
              <input
                className="create-entry-div tipo-boton"
                type="submit"
                value="Responder"
              />
            </div>
          </form>
        )}
        {respo.id == state.response_id && state.show_responses == true && (
          <Responses
            topic_id={topic_id}
            data={respo.responses && respo.responses}
          />
        )}
      </div>
    );
  });
};

export default Responses;
