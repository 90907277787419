import React, { useEffect, useContext, useState } from "react";
import { AuditPlanFormContext } from "../../../contexts/audit/AuditPlanFormContext";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import LoadingDoc from "../../app/animation/GenerateDoc"
import $ from "jquery";
import {
  faPlus,
  faMinus,
  faChevronLeft,
  faFileDownload,
  faSearchPlus,
  faChevronRight,
  faSearchMinus,
  faFilePdf,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { STATUS_LOADING } from "../../../contexts/security/SecurityContext";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPdfPlan = ({ computedMatch: { params, url } }) => {
  let { audit_id, module } = params;
  let history = useHistory();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [file, setFile] = useState("");

  const [{ auditPdf, statusauditpdf }, { getAuditPdf,preparePdfAudit,setmodulePdf }] =
    useContext(AuditPlanFormContext);
  useEffect(() => {
    setFile(`${process.env.REACT_APP_API_HOST}audit/${module}/${audit_id}`);
    getAuditPdf(module, audit_id);
    setmodulePdf(module);
  }, [module, audit_id]);
  let onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const setNumberPage = (more) => {
    let number = pageNumber;
    number = more ? number + 1 : number - 1;

    setPageNumber(number);
  };
  const setScalePage = (more) => {
    let newNumber = scale;
    if (more) {
      newNumber = newNumber + 0.3;
    } else {
      newNumber = newNumber - 0.3;
    }
    if (newNumber < 3 && newNumber > 0) {
      setScale(newNumber);
    }
  };
  const downloadDocument = (ref) => {
    let token = "";
    if (localStorage) {
      token = localStorage.getItem("token") || "";
    }
    var params = {
      Authorization: token,
    };

    var url = [ref, $.param(params)].join("?");

    window.open(url, "_blank");
  };
  return (
    <div className="container">
      {statusauditpdf == STATUS_LOADING && (
      <LoadingDoc/>
      )}
      {auditPdf && (
        <>
          <span
            onClick={() => history.goBack()}
            className="cursor-action  tipo-description"
          >
            <FontAwesomeIcon
              className="history-left-mobile"
              icon={faChevronLeft}
            />
            Atrás
          </span>
          <Document
            noData="No se ha encontrado un pdf"
            className="pdf-document"
            file={`data:application/pdf;base64,${auditPdf}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={console.log("cargando")}
            onDocumentLoadSuccess={console.log("WHATSIS")}
          >
            <Page
              noData="No se ha especificado una página"
              scale={scale}
              onLoadProgress="Cargando página"
              onLoadSuccess="Página cargada"
              loading="Cargando Página..."
              onContextMenu={() => {
                return false;
              }}
              pageNumber={pageNumber}
            />
          </Document>
        </>
      )}
      <div className="flex fixed-paginate-pdf">
        <div className="container-pagination-pdf">
          <p className="margin-auto">
            {pageNumber != 1 && (
              <FontAwesomeIcon
                onClick={() => {
                  setNumberPage(false);
                }}
                className="icon-arrow-zoom white cursor-action"
                icon={faChevronLeft}
              />
            )}
            &nbsp; &nbsp; Pág {pageNumber} / {numPages}
            &nbsp; &nbsp;
            {pageNumber < numPages && (
              <FontAwesomeIcon
                onClick={() => {
                  setNumberPage(true);
                }}
                className="icon-arrow-zoom white cursor-action"
                icon={faChevronRight}
              />
            )}
          </p>
          <div className="content-zoom-document">
            <FontAwesomeIcon
              onClick={() => {
                setScalePage(false);
              }}
              className="icon-zoom-document white cursor-action"
              icon={faSearchMinus}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <FontAwesomeIcon
              onClick={() => {
                setScalePage(true);
              }}
              className="icon-zoom-document white cursor-action"
              icon={faSearchPlus}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <FontAwesomeIcon
              onClick={() => {
                preparePdfAudit(audit_id);
             
              }}
              title="descargar pdf"
              className="white cursor-action"
              icon={faFilePdf}
            />
            &nbsp; &nbsp;
            {(module == "listCheckUserEmpty" || module == "showReport") && (
              <FontAwesomeIcon
                onClick={() => {
                  downloadDocument(
                    process.env.REACT_APP_API_HOST +
                      "audit/downLoad" +
                      module +
                      "/" +
                      audit_id +
                      "/word"
                  );
                }}
                title="descargar word"
                className="white cursor-action"
                icon={faFile}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPdfPlan;
