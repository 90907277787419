import React, { useContext, useEffect, useState } from "react";
import {
  SecurityContext,
  STATUS_LOADED,
  STATUS_LOADING,
} from "../../../contexts/security/SecurityContext";
import { GeneralContext } from "../../../contexts/general/GeneralContext";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import "../../security/profile/Profile.css";
import ImageUploader from "react-images-upload";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import imageProfile from "../../../img_zeus/default/PROFILE.jpeg";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

import DatePicker from "react-datepicker";

registerLocale("es", es);

let initialState = {
  user: {
    first_name: "",
    last_name: "",
    document: null,
    id_number: "",
    region: "",
    location: "",
    place_id: "",
    country_code: null,
    birth_date: new Date(),
    address: "",
    phone: "",
    city_id: null,
  },
};
const Profile = () => {
  let [
    { user, cities },
    { updatePerson, updateImagePerson, setcities, getCities },
  ] = React.useContext(SecurityContext);
  const [{ countries }, {}] = useContext(GeneralContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  const [stateUser, setstate] = useState(initialState.user);
  const [defaultCity, setdefaultCity] = useState({});
  const [address, setAddress] = React.useState("");
  let history = useHistory();
  useEffect(() => {
    setstate({
      ...stateUser,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      id_number: user.id_number,
      phone: user.phone,
      city_id: user.city_id,

      birth_date: user.birth_date
        ? moment(user.birth_date, "YYYY-MM-DD").toDate()
        : moment(new Date(), "YYYY-MM-DD").toDate(),
      address: user.address,
      place_id: user.place_id,
      country_code: user.country_code,
      locality: user.locality,
      region: user.region,
    });
    setAddress(user.address);
  }, [user]);

  let setField = (field) => (e) => {
    setstate({ ...stateUser, [field]: e.target.value });
  };
  let setImage = (field) => (e) => {
    let file = e[e.length - 1];
    updateImagePerson(file);
  };
  let updateUserForm = (e) => {
    e.preventDefault();
    updatePerson(stateUser);
  };

  const styleContainerImage = {
    backgroundImage: `url(${
      user.image == null
        ? imageProfile
        : process.env.REACT_APP_API_HOST +
          "/documents/" +
          (user.image && user.image.name)
    }`,
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#ECAE70" : "white",
      cursor: state.isDisabled ? "not-allowed" : "default",
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  const getCitiesCheck = (city) => {
    if (city.length > 2 && cities) {
      getCities(city);
    }
  };
  const handleSelect = async (value) => {
    try {
      setAddress(value);
      let results = await geocodeByAddress(value);
      let latLng = await getLatLng(results[0]);
      let place = getCountryRegion(results[0].address_components);
      setstate({
        ...stateUser,
        country_code: place.country_code,
        locality: place.locality,
        address: value + " (" + place.route + "," + place.street + ")",
        region: place.locality,
        place_id: results[0].place_id,
      });
    } catch (error) {
      setAddress("");
      alertWarning("Ingrese una dirección válida");
    }
  };
  const getCountryRegion = (places) => {
    let place = {
      locality: "",
      country_code: "",
      region: "",
      street: "",
      route: "",
    };
    let country = places.filter((e) => e.types.includes("country"));
    let region = places.filter((e) =>
      e.types.includes("administrative_area_level_1")
    );
    let locality = places.filter((e) => e.types.includes("locality"));
    let street = places.filter((e) => e.types.includes("street_number"));
    let route = places.filter((e) => e.types.includes("route"));
    if (country.length > 0) {
      place.country_code = country[0].short_name;
    }
    if (locality.length > 0) {
      place.locality = locality[0].short_name;
    }
    if (region.length > 0) {
      place.locality = region[0].short_name;
    }
    if (street.length > 0) {
      place.street = street[0].short_name;
    }
    if (route.length > 0) {
      place.route = route[0].short_name;
    }
    return place;
  };
  return (
    <div className="content-edit-profile bg-white shadow-tarjet margin-auto">
      <form
        className="flex-wrap"
        encType="multipart/form-data"
        onSubmit={updateUserForm}
      >
        <div className="flex">
          <div className="flex flex-0">
            <div
              onClick={() => history.goBack()}
              className="btn-close-profile background-simple"
            ></div>
          </div>

          <div className="flex-1">
            <ImageUploader
              withIcon={true}
              className="container-image-profile"
              buttonClassName="button-image-profile shadow-tarjet"
              buttonText=""
              fileContainerStyle={styleContainerImage}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              withIcon={false}
              labelClass="hidden"
              withPreview={false}
              onChange={setImage("document")}
              fileSizeError="Excede el peso permitido"
              fileTypeError="Extensión no permitida"
              singleImage={true}
              label="Peso máximo 5mb,acepta:jpg,gif,png"
            />
          </div>
          <div className="flex-1 flex">
            <p className="margin-auto profile-user-name">
              Perfil:
              {user.is_admin
                ? "Administrador"
                : user.is_auditor
                ? "Auditor"
                : "Usuario"}
            </p>
          </div>
        </div>
        <div className="flex justify-betwen">
          <div className="form-group flex-1 ">
            <label className="control-label">Nombre</label>
            <input
              value={stateUser.first_name}
              onChange={setField("first_name")}
              className="form-control"
              type="text"
            />
          </div>
          &nbsp;
          <div className="form-group flex-1 ">
            <label className="control-label">Apellido</label>
            <input
              value={stateUser.last_name}
              onChange={setField("last_name")}
              className="form-control"
              type="text"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label">Correo</label>
          <div className="input-basic-white input-email-profile">
            {user.email}
          </div>
        </div>
        <div className="flex justify-btewn">
          <div className="form-group flex-1 ">
            <label className="control-label">Número de identificación</label>
            <input
              value={stateUser.id_number}
              onChange={setField("id_number")}
              className="form-control"
              type="text"
            />
          </div>
          &nbsp;
          <div className="form-group flex-1 ">
            <label className="control-label">Fecha de nacimiento</label>

            <DatePicker
              selected={stateUser.birth_date}
              onChange={(date) => setstate({ ...stateUser, birth_date: date })}
              className="form-control tipo-description"
              locale="es"
              dateFormat="y-MM-dd"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label">Teléfono</label>
          <input
            value={stateUser.phone}
            className="form-control"
            type="text"
            onChange={setField("phone")}
          />
        </div>
        <div className="flex justify-btewn">
          <div className="form-group flex-1 ">
            <label className="control-label">Pais</label>
            {countries.status === STATUS_LOADED && (
              <Select
                value={
                  user.city
                    ? countries.elements.filter(
                        (c) => c.cc_iso == user.city.iso
                      )[0]
                    : cities.iso
                    ? countries.elements.filter(
                        (c) => c.cc_iso == cities.iso
                      )[0]
                    : null
                }
                onChange={(e) => setcities("iso", e?e.cc_iso:null)}
                className="tipo-description"
                placeholder={"Seleccione un pais"}
                getOptionLabel={(option) => `${option.country_name}`}
                getOptionValue={(option) => option.cc_iso}
                options={countries.elements}
                isClearable={true}
              />
            )}
          </div>
          &nbsp;
          <div className="form-group flex-1 ">
            <label className="control-label">Ciudad</label>
            
            <Select
              value={cities.status===STATUS_LOADED?stateUser.city_id
                ? cities.elements.filter(
                    (c) => c.id == stateUser.city_id
                  )[0]
                : null:null}
              onChange={(e) => setstate({ ...stateUser, city_id:e? e.id:null })}
              onInputChange={(e) => getCitiesCheck(e)}
              className="tipo-description"
              placeholder={"Escriba su ciudad "}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.iso}
              options={cities.status===STATUS_LOADED ? cities.elements:[]}
              isClearable={true}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label">Dirección de residencia</label>
          <input
            value={stateUser.locality}
            className="form-control"
            type="text"
            onChange={setField("locality")}
          />
          {/*  <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Dirección de la compañia",
                    className: "form-control tipo-description",
                  })}
                />

                <div>
                  {loading ? <div>...Buscando</div> : null}

                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#df8c37" : "#fff",
                      color: suggestion.active ? "#fff" : "#000",
                    };

                    return (
                      <div {...getSuggestionItemProps(suggestion, { style })}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete> */}
        </div>
        <div className="flex">
          <input
            className="btn-update-profile bg-orange white tipo-boton  margin-auto"
            value="Actualizar"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;
