import React, { useContext } from "react";
import "../layout/Profile.css";
import {
  STATUS_LOGGED_IN,
  SecurityContext,
  STATUS_LOADED,
} from "../../../contexts/security/SecurityContext";
import imgError from "../../../img_zeus/default/PROFILE.jpeg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

const Profile = () => {
  let [{ status, user, company, capacity }, { changeCompany }] = useContext(
    SecurityContext
  );

  let [state, setState] = React.useState(company);

  let setCompany = (event) => {
    let el = user.companies.filter((e) => e.id == event.target.value)[0];
    setState(el);
    changeCompany(el);
  };

  let shortDescription = (desc, range) => {
    if (desc && desc.length > range) {
      return desc.substr(0, range) + "...";
    }
    return desc;
  };
  const getcolor = (percentage) => {
    if (percentage > 79) {
      return "bg-danger";
    } else {
      return "bg-info";
    }
  };
  const getCapacity = () => {
    let consumed = user.cosumed;
    let capacity = user.capacity;
    let consumedSize = "MB";
    let capacitySize = "MB";
    if (user.cosumed > 1000) {
      consumed = (parseFloat(user.consumed) * 1) / 1000;
      consumedSize = "GB";
    }
    if (user.capacity > 1000) {
      capacity = (parseFloat(user.capacity) * 1) / 1000;
      capacitySize = "GB";
    } 
    return `${consumed} ${consumedSize} de ${capacity} ${capacitySize} usado`;
  };

  return (
    <div className="profile-menu bg-orange tipo-description">
      <div className="flex justify-center ">
        <div className="profile-role-name  white tipo-description txt-center">
          <p className="d-inline-block tipo-title">Perfil:</p>

          {status === STATUS_LOGGED_IN && (
            <>{user.is_admin ? "Administrador" : user.profiles[0].name}</>
          )}
        </div>
      </div>
      <div className="flex content-image-description">
        
        <Link to={`/app/profile`}>
          <img
            className="img-profile"
            src={
              process.env.REACT_APP_API_HOST +
              "/documents/" +
              (user.image && user.image.name)
            }
            onError={(e) => {
              e.target.src = imgError;
            }}
          ></img>
        </Link>

        <div className="person-profile-info">
          <p>
            <strong className="name-user-profile">
              {user.first_name} {user.last_name}
            </strong>
          </p>

          {/*     {user.companies != "" && (
            <select onChange={setCompany} value={state.id}>
              {user.companies &&
                user.companies.map(company => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
            </select>
          )} */}
        </div>
      </div>
      <div className="txt-center">
        <small className="tipo-title white">
          {shortDescription(user.email, 30)}
        </small>
        <br />
        <br />
      </div>
      <div className="container-header-capacity">
        <Link to={`/app/effectives/documents/person/${user.id}`}>
          <FontAwesomeIcon className="white" icon={faCloud} />
        </Link>
        &nbsp; <small className="white"> Almacenamiento</small>
        {user.capacity > 0 && (
          <div className="progress progress-capacity-profile">
            <div
              className={`progress-bar ${getcolor(
                (user.cosumed * 100) / user.capacity
              )}`}
              role="progressbar"
              style={{ width: (user.cosumed * 100) / user.capacity + "%" }}
            ></div>
          </div>
        )}
        <div>
        <small className="white">
          {user.capacity == 0 ? `${user.capacity} MB` : getCapacity()}
        </small>
        </div>
        
      </div>
    </div>
  );
};

export default Profile;
