import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "../company/index.css";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import {
  CompanyIndexContext,
  STATUS_LOADED,
} from "../../../contexts/general/CompanyIndexContext";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import btn_edit from "../../../img_zeus/icons_audit/ICONO-EDITAR.png";
import btn_delete from "../../../img_zeus/icons_audit/ICONO-ELIMINAR.png";
import btn_show from "../../../img_zeus/icons_audit/ICONO-VER.png";
import Swal from "sweetalert2";
import CreateCompany from "./CreateCompany";

import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';


const initialState = {
  toggle_modal: false,
  toggle: {
    init: false,
    end: false,
  },
  company_id: null,
};
const Company = () => {
  const [{ company, companyStaff }, { deleteCompany }] = useContext(
    CompanyIndexContext
  );
  let [state, setState] = useState(initialState);
  let [{ user }, { viewGeneral }] = useContext(SecurityContext);
  let history = useHistory();
  useEffect(() => {
    viewGeneral("company");
  }, []);
  const checkDelete = (title, functionYes, functionNot) => {
    Swal.fire({
      title: title,
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df8c37",
      cancelButtonColor: "#171e27",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.value) {
        eval(functionYes);
      } else {
        eval(functionNot);
      }
    });
  };

 
  return (
    <div>
      {state.toggle_modal === true && (
        <div className="back-document">
          <div className="modal-company">
            <div className="header-modal-company">
              <div
                className="salir-company"
                onClick={() => {
                  setState({ ...state, toggle_modal: false, company_id: null });
                }}
              ></div>
              <h3 className="title-form-topic tipo-description">
                {state.company_id !== null ? "Editar Empresa" : "Nueva Empresa"}
              </h3>
            </div>
            <div>
              <CreateCompany
                state={state}
                setState={setState}
                company_id={state.company_id}
              />
            </div>
          </div>
        </div>
      )}
      <div className="flex content-title-programs">
        <h2 className="tipo-title title-programs">Mis empresas </h2>
        <div
          onClick={() => {
            setState({ ...state, toggle_modal: true });
          }}
          className="btn-orange-default create-company-index"
        >
          Crear Empresa
        </div>
      </div>
      <div className="table-company">
        <table className="table table-hover">
          <thead className="thead-company">
            <tr className="txt-center">
              <th scope="col">Nombre</th>
              <th scope="col">Nit</th>
              <th scope="col">Dirección</th>
              <th scope="col">Responsable</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody className="bg-white txt-center tipo-description">
            {company.elements && company.elements.length == 0 && (
              <tr>
                <td colSpan="6" className="txt-center">
                  No tiene empresas registradas
                </td>
              </tr>
            )}
            {company.elements &&
              company.elements.map(
                (company) =>
                  company.contact_person_id == user.id && (
                    <tr key={company.id}>
                      <td>{company.name}</td>
                      <td>{company.nit}</td>
                      <td>
                        {company.location == null
                          ? "sin asignar"
                          : company.location.address}
                      </td>

                      <td>
                        {company.contact &&
                          company.contact.first_name +
                            " " +
                            company.contact.last_name}
                      </td>
                      <td className="btn-action-program">
                        <img
                          className="cursor-action"
                          onClick={() => {
                            history.push(`/app/companies/${company.id}`);
                          }}
                          title="Ingresar a la compañia"
                          src={btn_show}
                        />
                        &nbsp;
                        <img
                          className="cursor-action"
                          onClick={() => {
                            setState({
                              ...state,
                              toggle_modal: true,
                              company_id: company.id,
                            });
                          }}
                          title="Editar compañia"
                          src={btn_edit}
                        />
                        &nbsp;
                        {company.audits.length === 0 && (
                          <img
                            className="cursor-action"
                            onClick={() => {
                              checkDelete(
                                "¿Realmente deseas eliminar a esta compañia?",
                                `deleteCompany(${company.id})`
                              );
                            }}
                            title="Eliminar compañia"
                            src={btn_delete}
                          />
                        )}
                      </td>
                    </tr>
                  )
              )}
          </tbody>
        </table>
      </div>
      <div className="flex content-title-programs">
        <h2 className="tipo-title title-programs">
          Empresas a las que pertenezco
        </h2>
      </div>
      <div className="table-company">
        <table className="table table-hover">
          <thead className="thead-company">
            <tr className="txt-center">
              <th scope="col">Nombre</th>
              <th scope="col">Cargo</th>
              <th scope="col">Nit</th>
              <th scope="col">Dirección</th>
              <th scope="col">Responsable</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody className="bg-white txt-center tipo-description">
            {company.elements && company.elements.length == 0 && (
              <tr>
                <td colSpan="6" className="txt-center">
                  No tiene empresas registradas
                </td>
              </tr>
            )}
            {companyStaff.elements &&
              companyStaff.elements
                .filter((staff) => staff.company)
                .map((staff) => (
                  <tr key={staff.id}>
                    <td>{staff.company.name}</td>
                    <td>{staff.position}</td>
                    <td>{staff.company.nit}</td>
                    <td>
                      {staff.company.location == null
                        ? "sin asignar"
                        : staff.company.location.address}
                    </td>

                    <td>
                      {staff.company.contact &&
                        staff.company.contact.first_name +
                          " " +
                          staff.company.contact.last_name}
                    </td>
                    <td className="btn-action-program">
                      <img
                        className="cursor-action"
                        onClick={() => {
                          history.push(`/app/companies/${staff.company.id}`);
                        }}
                        title="Ingresar a la compañia"
                        src={btn_show}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Company;
