import React from "react";
import icon from "../../../img_zeus/ICONO-ALERTA.png";
import { Link } from "react-router-dom";
import "../layout/Unauthorized.css";

const unathorized = () => {
  return (
    <div className="content-general-unauthorized">
      <div className="content-unauthorized shadow">
        <div className="divider bg-orange"></div>
        <img alt="Alerta" className="img-unauthorized" src={icon} />
        <h2 className="title-unauthorized">¡No estás autorizado!</h2>
        <Link to="/app">
          <div className="ok-unauthorized bg-orange cursor-action">
            <p className="asd">
              <strong>Ok</strong>
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default unathorized;
