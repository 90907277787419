import React, { useEffect } from "react";
import "./app.css";
import client from "../src/contexts/axios/axios-client";

import Login from "./components/security/login/Login";
import Invited from "./components/security/login/Invited";
import Register from "./components/security/register/Register";
import Pqrs from "./components/general/company/Pqrs";
import ValidateAutenticate from "./components/general/ValidateAutenticate"
import AppRouter from "./components/app/router";
import ResetPassword from "./components/security/login/ResetPassword";
import PaymentSucess from "./components/billing/PaymentSuccess";
import { BillingContextProvider } from "./contexts/billing/BillingContext";
import { CompanyFormContextProvider } from "./contexts/general/CompanyFormContext";

import {
  SecurityContextProvider,
  SecurityContext,
  STATUS_LOADING,
  STATUS_NOT_LOADED,
} from "./contexts/security/SecurityContext";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AlertContextProvider } from "./contexts/alerts/AlertContext";
import EmailPassword from "./components/security/login/EmailPassword";

const AppRender = ({ match }) => {
  let [security] = React.useContext(SecurityContext);

  if (
    security.status === STATUS_LOADING ||
    security.status === STATUS_NOT_LOADED
  ) {
    return (
      <div className="flex container-loading">
        <div className="margin-auto">
          Cargando...
          <div className="background-simple gif-loading"></div>
        </div>
      </div>
    );
  }

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <BillingContextProvider path="/responseSuccessPayment">
        <Switch>
          <PaymentSucess
            exact
            path="/responseSuccessPayment/:statusPayu/:signature/:id_bill"
          />
        </Switch>
      </BillingContextProvider>

      <Route path="/activeAccount/:token" component={Login} />
      <Route path="/activeAccountInvited/:token" component={Invited} />
      <Route path="/resetPassword/:token" component={ResetPassword} />
      <Route path="/validateAutenticate/:aes" component={ValidateAutenticate} />
      <Route path="/register/:token" component={Register} />
      <CompanyFormContextProvider path="/PQRSF">
        <Switch>
          <Route path="/PQRSF/:token" component={Pqrs} />
        </Switch>
      </CompanyFormContextProvider>
      <Route path="/register" component={Register} />
      <Route path="/mailResetPassword" component={EmailPassword} />

      <Route path="/app" component={AppRouter} />
      <Redirect to="/app" />
    </Switch>
  );
};

const App = () => {
  return (
    <Router>
      <AlertContextProvider>
        <SecurityContextProvider>
          <AppRender />
        </SecurityContextProvider>
      </AlertContextProvider>
    </Router>
  );
};

export default App;
