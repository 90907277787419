import React, { useState, useEffect, useContext } from "react";
import "../folder/Filter.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { LibraryContext } from "../../../../contexts/library/LibraryContext";
import CheckboxGroup from "react-checkbox-group";
import { RadioGroup, Radio } from "react-radio-group";
const initialState = {
  showData: [
    "folders",
    "documents",
    "free",
    "premium",
    "read",
    "image",
    "media",
    "download"
  ],
  orderFilter: "recent"
};
const Filter = () => {
  const [{ filters }, { setStateHead, setStateData }] = useContext(
    LibraryContext
  );

  const [showData, setShowData] = useState(initialState.showData);
  const [order, setOrder] = useState(initialState.orderFilter);
  useEffect(() => {
    setStateData(showData, order);
  }, [showData, order]);
  return (
    <CheckboxGroup name="filters" value={showData} onChange={setShowData}>
      {Checkbox => (
        <div className="w-100">
          <div className="w-100  container-type-filter ">
            <div
              onClick={() => {
                setStateHead("statusViewOrder");
              }}
              className="flex cursor-action title-filter"
            >
              <div className="icon-order-by icon-filter-type"></div>
              <p className="flex-1 title-filter-text tipo-title">Ordenar por</p>

              <FontAwesomeIcon
                className="justify-end flex icon-down-filter "
                icon={faChevronDown}
              />
            </div>
            {filters.statusViewOrder && (
              <>
                <RadioGroup
                  name="order"
                  selectedValue={order}
                  onChange={setOrder}
                >
                  <div className="flex">
                    <Radio className="check-filter" value="fixed" />
                    <p className="flex-1 option-text-filter">Fijadas</p>
                  </div>
                  <div className="flex">
                    <Radio className="check-filter" value="best" />
                    <p className="flex-1 option-text-filter">
                      Mejor calificación
                    </p>
                  </div>
                  <div className="flex">
                    <Radio className="check-filter" value="recent" />
                    <p className="flex-1 option-text-filter">Recientes</p>
                  </div>
                </RadioGroup>
              </>
            )}
          </div>
          <div className="w-100  container-type-filter ">
            <div
              onClick={() => {
                setStateHead("statusViewView");
              }}
              className="flex cursor-action title-filter"
            >
              <div className="icon-filter-view icon-filter-type"></div>
              <p className="flex-1 title-filter-text tipo-title">
                Opciones de visualización
              </p>

              <FontAwesomeIcon
                className="justify-end flex icon-down-filter "
                icon={faChevronDown}
              />
            </div>
            {filters.statusViewView && (
              <>
                <div className="flex">
                  <Checkbox value="folders" className="check-filter" />
                  <p className="flex-1 option-text-filter">Carpetas</p>
                </div>
                <div className="flex">
                  <Checkbox value="documents" className="check-filter" />
                  <p className="flex-1 option-text-filter">Documentos</p>
                </div>
              </>
            )}
          </div>
          <div className="w-100  container-type-filter ">
            <div
              onClick={() => {
                setStateHead("statusViewLicense");
              }}
              className="flex cursor-action title-filter"
            >
              <div className="icon-filter-license icon-filter-type"></div>
              <p className="flex-1 title-filter-text tipo-title">
                Licencia de uso
              </p>

              <FontAwesomeIcon
                className="justify-end flex   icon-down-filter"
                icon={faChevronDown}
              />
            </div>
            {filters.statusViewLicense && (
              <>
                <div className="flex">
                  <Checkbox value="free" className="check-filter" />
                  <p className="flex-1 option-text-filter">Gratuito</p>
                </div>
                <div className="flex">
                  <Checkbox value="premium" className="check-filter" />
                  <p className="flex-1 option-text-filter">Premium</p>
                </div>
              </>
            )}
          </div>
          <div className="w-100  container-type-filter ">
            <div
              onClick={() => {
                setStateHead("statusViewType");
              }}
              className="flex cursor-action title-filter"
            >
              <div className="icon-filter-type-document icon-filter-type-document"></div>
              <p className="flex-1 title-filter-text tipo-title">
                Tipo de documento
              </p>

              <FontAwesomeIcon
                className="justify-end flex  icon-down-filter"
                icon={faChevronDown}
              />
            </div>
            {filters.statusViewType && (
              <>
                <div className="flex">
                  <Checkbox className="check-filter" value="read" />
                  <p className="flex-1 option-text-filter">Lectura</p>
                </div>
                <div className="flex">
                  <Checkbox value="image" className="check-filter" />
                  <p className="flex-1 option-text-filter">Foto</p>
                </div>
                <div className="flex">
                  <Checkbox value="media" className="check-filter" />
                  <p className="flex-1 option-text-filter">Video</p>
                </div>{" "}
                <div className="flex">
                  <Checkbox value="download" className="check-filter" />
                  <p className="flex-1 option-text-filter">Descargables</p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </CheckboxGroup>
  );
};

export default Filter;
