import React, { useState, useEffect, useContext } from "react";
import {
  CompanyFormContext,
  STATUS_LOADED
} from "../../../contexts/general/CompanyFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import { Field, Form } from "react-final-form";
import "../company/index.css";

const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";

const initialState = {
  status: STATUS_NEW,
  procces_id: null,
  item: {
    id: "",
    name: ""
  },
  originalItem: {}
};

const CreateProcces = ({ state, setState, procces_id }) => {
  const [{ company }, { createProcces, updateProcces }] = useContext(
    CompanyFormContext
  );
  let [stateCreate, setStateCreate] = useState(initialState);
  let [{ user }, {}] = useContext(SecurityContext);

  useEffect(() => {
    if (procces_id && company.status === STATUS_LOADED) {
      let el = company.company.process.filter(e => e.id === procces_id)[0];
      setStateCreate({
        ...stateCreate,
        item: {
          id: el.id,
          name: el.name
        },
        procces_id: procces_id,
        status: STATUS_EDIT
      });
    }
  }, [procces_id, company]);

  let functionSubmit = formObj => {
    if (stateCreate.status === STATUS_EDIT) {
      updateProcces(formObj, stateCreate.procces_id);
      setState({ ...state, toggle_modal2: false, procces_id: null });
    } else {
      createProcces(formObj);
      setState({ ...state, toggle_modal2: false });
    }
  };

  const required = value => {
    return value ? undefined : "Requerido";
  };

  return (
    <Form
      onSubmit={formObj => {
        functionSubmit(formObj);
      }}
      initialValues={stateCreate.item}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form-procces">
          <div className="form-group">
            <Field name="name" validate={required}>
              {({ input, meta }) => (
                <>
                  <label>Nombre del proceso</label>
                  <input
                    {...input}
                    autoFocus
                    type="text"
                    className="form-control tipo-description"
                    placeholder="Nombre del proceso"
                  />
                  {meta.touched && meta.error && (
                    <span className="tipo-cursiva orange">{meta.error}</span>
                  )}
                </>
              )}
            </Field>
          </div>
          <div className="form-group">
            {stateCreate.status === STATUS_EDIT ? (
              <>
                <input
                  type="submit"
                  className="btn-topic cursor-action tipo-boton"
                  value="Editar"
                />
              </>
            ) : (
              <>
                <input
                  type="submit"
                  value="Crear"
                  className="btn-topic cursor-action tipo-boton"
                />
              </>
            )}
          </div>
        </form>
      )}
    </Form>
  );
};
export default CreateProcces;
