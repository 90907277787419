import React, { useState, useEffect, useContext } from "react";
import {
  CompanyFormContext,
  STATUS_LOADED,
  STATUS_NOT_LOADED,
} from "../../../contexts/general/CompanyFormContext";
import { SecurityContext } from "../../../contexts/security/SecurityContext";
import "../company/index.css";
import DateTimePicker from "react-datetime-picker";
import DataTable from "react-data-table-component";
import { AlertContext } from "../../../contexts/alerts/AlertContext";
import Toggle from "react-toggle";
const STATUS_NEW = "NEW";
const STATUS_EDIT = "EDIT";
const NUMBER_CARACTER = 5;

const initialState = {
  status: STATUS_NEW,
  email: "",
  originalItem: {},
};

const CreateStaff = ({
  state,
  setState,
  company_id,
  personstate,
  setPerson,
  newPersonStaff,
}) => {
  let [
    { company, usersSearch },
    {
      getUser,
      changeCompany,
      addStaff,
      searchUsers,
      setcompany,
      setusersSearch,
    },
  ] = useContext(CompanyFormContext);
  const [{}, { alertWarning }] = useContext(AlertContext);
  let [emailState, setEmailState] = useState(initialState.email);
  const [columnsTable, setcolumnsTable] = useState([]);
  useEffect(() => {
    if (emailState.length >= NUMBER_CARACTER) {
      searchUsers(emailState);
    } else {
      setusersSearch([]);
    }
  }, [emailState]);
  const validatePersonInStaff = (person) => {
    let findPerson = company.company.partners.filter((p) => p.id === person.id);
    if (findPerson.length > 0) {
      alertWarning("Este integrante ya pertenece a esta empresa");
      return false;
    }
    setcompany({
      ...company,
      person: person,
      status: STATUS_NOT_LOADED,
    });
  };
  useEffect(() => {
    setcolumnsTable([
      {
        name: "EMAIL",
        center: true,
        selector: "email",
        sortable: true,
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              validatePersonInStaff(row);
            }}
          >
            {row.email}
          </div>
        ),
      },
      {
        name: "NOMBRE",
        center: true,
        selector: "first_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              validatePersonInStaff(row);
            }}
          >
            {row.first_name}
          </div>
        ),
      },
      {
        name: "APELLIDO",
        center: true,
        selector: "last_name",
        cell: (row) => (
          <div
            className="cursor-action"
            onClick={() => {
              validatePersonInStaff(row);
            }}
          >
            {row.last_name}
          </div>
        ),
      },
    ]);
  }, [usersSearch]);
  let searchUser = () => {
    getUser(emailState);
  };

  let setFIeld = (field) => (e) => {
    setPerson({ ...personstate, [field]: e.target.value });
  };
  let setDate = (date) => {
    setPerson({ ...personstate, valid_date: date });
  };
  return (
    <div className="search-person-staff tipo-description">
      <div className="flex-column">
        <div className="form-inline">
          <div className="form-group">
            <input
              onChange={(e) => {
                setEmailState(e.target.value);
              }}
              value={emailState}
              type="email"
              className="form-control div-search-person-staff"
              placeholder="email"
            />
          </div>
          {usersSearch.length === 0 && emailState.length >= NUMBER_CARACTER && (
            <button
              onClick={() => {
                searchUser();
              }}
              type="submit"
              className="btn bg-orange mb-2 tipo-boton btn-search-staff"
            >
              Invitar
            </button>
          )}
        </div>
        <label className="tipo-cursiva" htmlFor="">
          Búsqueda apartir de {NUMBER_CARACTER} caracteres
        </label>
      </div>
      {emailState.length >= NUMBER_CARACTER && (
        <DataTable
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10]}
          noDataComponent={"no hay usuarios"}
          striped={true}
          columns={columnsTable}
          responsive={true}
          className=" tipo-description"
          data={usersSearch}
          pagination={true}
          paginationComponentOptions={{
            rowsPerPageText: "Lineas:",
            rangeSeparatorText: "de",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "Todas",
          }}
        />
      )}

      {personstate.person_id && (
        <div className="container-form-company flex">
          <div className="div-company-form">
            <label className="tipo-title">Nombre:</label>
            <div className="form-control div-disable">
              {personstate.first_name}
            </div>
          </div>
          <div className="div-company-form">
            <label className="tipo-title">Apellido:</label>
            <div className="form-control div-disable">
              {personstate.last_name}
            </div>
          </div>
          <div className="div-company-form">
            <label className="tipo-title">Cargo:</label>
            <input
              type="text"
              onChange={setFIeld("position")}
              value={personstate.position}
              className="form-control"
              placeholder="Cargo"
              required
            />
          </div>
          <div className="div-company-form">
          <label className="tipo-title">Responsable Pqrs :</label>
          <br/>
            <Toggle
              name="isPqrsResponsable"
              className="margin-auto mobile-hidden"
              checked={personstate.isPqrsResponsable}
              title={"Responsable de psqrs"}
              aria-label={"responsable"}
              onChange={(e) =>
                setPerson({
                  ...personstate,
                  isPqrsResponsable:
                    personstate.isPqrsResponsable === false ? true : false,
                })
              }
            />
          </div>
          {/* <div className="div-company-form">
            <label className="tipo-title">Contratado hasta:</label>
            <DateTimePicker
              required
              value={personstate.valid_date}
              className="form-control tipo-description"
              format="y-MM-dd HH:mm"
              onChange={setDate}
            />
          </div> */}
          <div className="btn-add-staff">
            <input
              onClick={() => {
                addStaff(personstate);
                setState({ ...state, toggle_modal: false });
                setPerson(newPersonStaff);
              }}
              value="Añadir"
              type="button"
              className="btn tipo-boton bg-orange btn-search-staff"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateStaff;
