import React from "react";
import icon from "../../../img_zeus/icons_alerts/BOTON-PAGINA-NO-ENCONTRADA.png";
import { Link } from "react-router-dom";
import "../layout/NoFound.css";

const NoFound = () => {
  return (
    <div className="content-general-nofound">
      <div className="content-nofound">
        <img src={icon} className="img-nofound" />
        <div className="tipo-title title-nofound">Página no encontrada</div>
        <div className="tipo-description description-nofound">
          Intenta buscar lo que necesitas en el menú
        </div>
        <Link to="/app" className="btn-nofound">
          <div className="tipo-boton cursor-action">Atrás</div>
        </Link>
      </div>
    </div>
  );
};

export default NoFound;
