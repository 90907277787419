import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BillingContext } from "../../contexts/billing/BillingContext";
import TooltipModule from "./TooltipModule";
const MODULE_LIBRARY = "library";
const SERVICE_LIBRARY = 4;
const MODULE_AUDIT = "audit";
const SERVICE_AUDIT = 1;
const MODULE_AUDIT_EXPRESS = "audit_express";
const SERVICE_AUDIT_EXPRESS = 7;
const MODULE_ACTION_PLAN = "plan";
const MODULE_CONSULTING = "consulting";
const MODULE_CAPACITY = "capacity";
const SERVICE_CAPACITY = 3;
const MODULE_INSPECTION = "inspection";
const initialState = {
  module: "",
};
const Suscriptions = () => {
  let history = useHistory();
  const [{ services }] = useContext(BillingContext);
  const checkModule = (moduleClick) => {
    let newModule=MODULE_LIBRARY;
    if (moduleClick != "") {
      switch (moduleClick) {
        case SERVICE_AUDIT:
          newModule = MODULE_AUDIT;
          break;
        case SERVICE_CAPACITY:
          newModule =MODULE_CAPACITY;
          break;
        case SERVICE_AUDIT_EXPRESS:
          newModule = MODULE_AUDIT_EXPRESS;
          break;
      }
    }
    history.push(`../library/topic/billing/suscription/${newModule}`);
  };
  return (
    <div className="tooltip-select-payment tooltip-subscribe-module flex flex-column">
      <div>
        <div
          className={`icon-module-subscribe background-simple library-icon-subscription`}
        ></div>
      </div>
      <div>
        <p className="txt-center">ELIGE MÓDULO Y SUSCRIPCIÓN</p>
      </div>
      <div className="margin-auto txt-center txt-upper container-select-modules color-gray">
        {services.elements
          .filter((s) => !s.is_product)
          .map((service, index) => {
            return (
              <div
                key={index}
                onClick={() => checkModule(service.id)}
                className={`${
                  service.id === module ? "module-active-tooltip" : ""
                }`}
              >
                {service.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Suscriptions;
