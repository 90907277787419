import React, { useContext, useState, useEffect } from "react";
import { AuditContext } from "../../../contexts/audit/AuditContext";
import { Form, Field } from "react-final-form";
import "./CreateStandard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from "lodash/cloneDeep";
import iconPlus from "../../../../src/img_zeus/icons_audit/ICONO-CREAR.png";
import iconDelete from "../../../../src/img_zeus/icons_library/BOTON-ELIMINAR.png";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const STATUS_LOADING = "LOADING";
const STATUS_NOT_LOADED = "NOT_LOADED";
const STATUS_NOT_LOGGED_IN = "NOT_LOGGED_IN";
const STATUS_LOGGED_IN = "LOGGED_IN";
const STATUS_LOADED = "STATUS_LOADED";
const ITEM_TYPE_DEBE = 1;
const initialState = {
  newStandard: {
    short_name: "",
    long_name: "",
    description: "",
    min_range: 0,
    max_range: 0,
  },
  newItemType: {
    name: "",
    item_type_id: ITEM_TYPE_DEBE,
    options: [
      {
        description: "cumple",
        order: 1,
        is_valid: true,
        value: 0,
      },
      {
        description: "no cumple",
        order: 1,
        is_valid: false,
        value: 0,
      },
      {
        description: "no aplica",
        order: 1,
        is_valid: null,
        value: 0,
      },
    ],
  },
};

const CreateStandard = () => {
  const [
    { standard, modalStandard, itemsTypeStandard, toogleItemType },
    {
      createStandard,
      updateStandard,
      inEditModalStandard,
      setitemsTypeStandard,
      settoogleItemType,
      deleteItemType,
    },
  ] = useContext(AuditContext);

  const [newStandard, setNewStandard] = useState(initialState.newStandard);

  useEffect(() => {
    if (
      standard.status === STATUS_LOADED &&
      modalStandard.standard_id !== null
    ) {
      //cuando se va editar una norma
      let filter = standard.elements.filter(
        (e) => e.id === modalStandard.standard_id
      )[0];

      setNewStandard({
        ...newStandard,
        short_name: filter.short_name,
        long_name: filter.long_name,
        description: filter.description,
        min_range: filter.min_range,
        max_range: filter.max_range,
      });
      if (filter.item_types.length > 0) {
        settoogleItemType(true);
      } else {
        settoogleItemType(false);
      }
      setitemsTypeStandard(filter.item_types);
    }
  }, [standard, modalStandard]);

  const required = (value) => (value ? undefined : "Requerido");
  const addNewItemEmpty = () => {
    let itemNew = cloneDeep(initialState.newItemType);

    setitemsTypeStandard([...itemsTypeStandard, itemNew]);
  };
  const setFieldItem = (field, index, indexOption) => (e) => {
    let value = e.target.value;
    let newStandard = cloneDeep(itemsTypeStandard);

    if (field === "name") {
      newStandard[index].name = value;
    } else {
      newStandard[index].options[indexOption][field] = value;
    }
    setitemsTypeStandard(newStandard);
  };
  const checkToogleItemType = () => {
    settoogleItemType(!toogleItemType);
    if (itemsTypeStandard.length === 0) {
      addNewItemEmpty();
    }
  };
  const checkDeleteItemType = (index) => {
    let findItem = itemsTypeStandard.filter(
      (i, position) => position === index
    )[0];
    let newItems = itemsTypeStandard.filter(
      (i, position) => position !== index
    );
    if (newItems.length === 0) {
      settoogleItemType(false);
    }
    setitemsTypeStandard(newItems);
    if (modalStandard.standard_id != null && findItem.id) {
      deleteItemType(findItem.id);
    }
  };
  return (
    <Form
      onSubmit={(formObject) => {
        if (modalStandard.standard_id !== null) {
          updateStandard(formObject);
        } else {
          createStandard(formObject);
        }
        inEditModalStandard(null);
      }}
      initialValues={newStandard}
    >
      {({ handleSubmit,values }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{ marginTop: "80px" }}
            className="modal-create-standard shadow-tarjet"
          >
            <div className="w-100 header-modal-create-evaluation">
              <div className="w-100 flex">
                <div
                  onClick={() => {
                    inEditModalStandard(null);
                  }}
                  className="background-simple icon-close-modal-create-folder cursor-action"
                ></div>
                <p className="txt-center txt-title-new-folder tipo-description">
                  {modalStandard.standard_id !== null
                    ? "Editar norma"
                    : "Nueva norma"}
                </p>
              </div>
            </div>
            <div className="bg-white content-modal-create-standard">
              <div className="">
                <div className="flex">
                  <div className="flex-1 margin-control">
                    <Field name="short_name" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Título corto:</label>

                          <input
                            placeholder="Título de la norma"
                            className="form-control tipo-description"
                            type="text"
                            {...input}
                          />
                          {meta.touched && meta.error && (
                            <span className="tipo-cursiva orange">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                  <div className="flex-1 margin-control">
                    <Field name="long_name" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Nombre largo:</label>
                          <textarea
                            placeholder="Nombre largo de la norma"
                            className="form-control tipo-description"
                            rows="1"
                            {...input}
                            type="text"
                          />
                          {meta.touched && meta.error && (
                            <span className="orange tipo-cursiva">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 margin-control">
                    <Field name="description" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <label className="control-label">Descripción:</label>
                          <textarea
                            placeholder="Descripción de la norma"
                            className="form-control tipo-description"
                            {...input}
                            type="text"
                          />
                          {meta.touched && meta.error && (
                            <span className="orange tipo-cursiva">
                              {meta.error}
                            </span>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                {toogleItemType && (
                  <div className="flex">
                    <div className="flex-1 margin-control">
                      <Field name="min_range" validate={required}>
                        {({ input, meta }) => (
                          <>
                            <label className="control-label">
                              Porcentage mínimo de cumplimiento:
                            </label>

                            <input
                              placeholder="Porcentage mínimo de cumplimiento"
                              className="form-control tipo-description"
                              type="number"
                              min="0" max="100"
                              maxLength={100}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span className="tipo-cursiva orange">
                                {meta.error}
                              </span>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="flex-1 margin-control">
                      <Field name="max_range" validate={required}>
                        {({ input, meta }) => (
                          <>
                            <label className="control-label">
                              Porcentage intermedio de cumplimiento :
                            </label>
                            <input
                              min={values.min_range} max="100"
                              placeholder="Porcentage intermedio de cumplimiento"
                              className="form-control tipo-description"
                              type="number"
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span className="orange tipo-cursiva">
                                {meta.error}
                              </span>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                )}
                <div className="flex-column">
                  {modalStandard.standard_id === null && (
                    <div className="flex">
                      ¿Items calificables con puntaje?&nbsp;&nbsp;
                      <div
                        onClick={() => checkToogleItemType()}
                        className="container-check-items-type-standard"
                      >
                        {toogleItemType && <span>x</span>}
                      </div>
                    </div>
                  )}
                  {toogleItemType && (
                    <div className="flex-1 margin-control">
                      {itemsTypeStandard.map((i, index) => (
                        <div key={index} className="flex-column form-group">
                          <div className="flex">
                            <div className="flex-column">
                              <label>Nombre</label>
                              <input
                                className="form-control"
                                value={i.name}
                                type="text"
                                onChange={setFieldItem("name", index, null)}
                                placeholder="nombre del tipo"
                              />
                            </div>

                            {i.options.map((o, index_option) => (
                              <div key={index_option} className="flex-column">
                                <label>{o.description}</label>
                                <input
                                  className="form-control"
                                  onChange={setFieldItem(
                                    "value",
                                    index,
                                    index_option
                                  )}
                                  value={o.value}
                                  type="number"
                                  placeholder="valor"
                                />
                              </div>
                            ))}
                            {modalStandard.standard_id === null && (
                              <div className="flex">
                                <div
                                  onClick={() => checkDeleteItemType(index)}
                                  className="btn-delete-item-type cursor-action btn-delete-black background-simple"
                                  
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div>
                        <div
                          className="cursor-action background-simple btn-more-black"
                          onClick={() => addNewItemEmpty()}
                          style={{ width: "23px",height:"23px" }}
                         
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-end w-100">
              
                <button
                  className="btn-orange-default"
                  type="submit"
                >
                  Guardar
                </button>
              
             
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

export default CreateStandard;
